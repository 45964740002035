import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';
import { all_ColorModes, all_DeviceTypes, all_PlatformTypes, all_subPlatforms, ColorMode, DeviceType, PlatformType, ContentType, SocialMedia_Platform, apt_ContentTypes, SubPlatform, create_AssetForInfo } from '../../Models/EnumsAndPointers';
import { randomAlphabets } from '../../utilities/Utilities';
import { AiFillApi } from 'react-icons/ai';



// ImCheckboxUnchecked
// ImCheckboxChecked

// CgCloseO

// {showForSorter ? <Asset_For_Selector title="Sort By" optionSelected={forSelectedOption} selectedOption={optionIsSelected} close={clearSelections} /> : null}


const AssetForSelector = (props) => {


    const [assetFor, setAssetFor] = useState(props.for ?? create_AssetForInfo())

        // PLATFORM
    // Platform type - Social Media, Print, Website/App,  Any, Other
    // const [platform, setPlatform] = useState(props.for.platform ?? PlatformType.any)
    // const [platform_Other, setPlatform_Other] = useState(props.for.platform_Other)

        // SUBPLATFORM
    // Social Media Platform - FB, Insta... all, any, other
    // const [subPlatform, setSubPlatform] = useState(props.for.subPlatform ?? SubPlatform.any)
    // const [subPlatform_Other, setSubPlatform_Other] = useState(props.for.subPlatform_Other)

        // CONTENT TYPE
    // Post Type -  All, Any, Other
    // const [contentType, setContentType] = useState(props.for.contentType ?? ContentType.any)
    // const [contentType_Other, setContentType_Other] = useState(props.for.contentType_Other)

        // DEVICE TYPE
    // Device type -
    // const [deviceType, setDeviceType] = useState(props.for.deviceType ?? DeviceType.any)
    // const [deviceType_Other, setDeviceType_Other] = useState(props.for.deviceType_Other)

        // COLOR MODE TYPE
    // Light or Dark Mode - ColorMode
    // const [colorMode, setColorMode] = useState(props.for.colorMode ?? ColorMode.any)
    // const [colorMode_Other, setColorMode_Other] = useState(props.for.colorMode_Other)



    const selectAndClose = () => {
        if (props.selectionMade){
            props.selectionMade(assetFor)
        }else if (props.close){
            props.close()
        }
    }



    
    const selection_for = (label, options, selected, onSelection, otherVal) => {

        return <Form.Field key={label} className="m-0 p-0 mb-4  has-background-transparent"   >
            <Form.Label className=' is-size-7'> {label} </Form.Label>
            <Form.Control className='minW140'>
                <Columns gap={0}>
                    <Columns.Column size={4} >
                        <Form.Select size="small is-fullwidth "
                            // disabled={saving}
                            value={selected}
                            className=' '
                            onChange={(e) => { if (onSelection) { onSelection(e.target.value) } }}
                        >
                            {
                                options.map((o) => {
                                    console.log("O is for Label " + label)
                                    console.log(o)


                                    let d = null
                                    d = <option key={randomAlphabets(5)} value={o}>{o}</option>
                                    // if (o.toLowerCase() === "any") {
                                    //     d = <optgroup>
                                    //         {d}
                                    //         {/* <option className='z-35' /> */}
                                    //     </optgroup>
                                    // }
                                    // if (o.toLowerCase() === "all" || o.toLowerCase() === "other") {
                                    //     d = <optgroup>
                                    //         {/* <option className='z-35' /> */}
                                    //         {d}
                                    //     </optgroup>
                                    // }
                                    return d
                                })
                            }
                        </Form.Select>
                    </Columns.Column>
                    <Columns.Column>
                        {
                            selected === "Other" ?
                                <Form.Input size="small"
                                    // disabled={saving}
                                    value={otherVal}
                                    placeholder='Mention Other...'
                                    className=' is-radiusless'
                                    onChange={(e) => { if (onSelection) { onSelection(e.target.value, true) } }}
                                />
                                : null
                        }

                    </Columns.Column>
                </Columns>


            </Form.Control>
        </Form.Field>


    }


    const dropdown_for = (label, options, selected, onSelection) => {
        return <Dropdown
            className='is-fullwidth is-small my-1 '
            onChange={(val) => { if (onSelection) { onSelection(val) } }}
            label={selected ?? label}
        >
            {
                options.map((o) => {
                    console.log("O is for Label " + label)
                    console.log(o)


                    let d = null
                    d = <Dropdown.Item className='z-50' renderAs='a' key={randomAlphabets(5)} value={o}>{o}</Dropdown.Item>
                    if (o.toLowerCase() === "any") {
                        d = <>
                            {d}
                            <Dropdown.Divider className='z-35' />
                        </>
                    }
                    if (o.toLowerCase() === "all" || o.toLowerCase() === "other") {
                        d = <>
                            <Dropdown.Divider className='z-35' />
                            {d}
                        </>
                    }
                    return d
                })
            }
        </Dropdown>
    }


    // const row = (opt) => {
    //     return <tr key={opt.name} className=" my-0  " onClick={() => { optSelectionToggled(opt) }} >
    //         <td align="left" className="  " >\
    //             <p className="ml-2 mt-2">{opt.name}</p>
    //         </td>
    //         <td align="right " >
    //             <Button className=" has-background-transparent is-borderless" onClick={() => { optSelectionToggled(opt) }} >
    //                 {
    //                     selectedOption ?
    //                         <> {
    //                             opt.name === (selectedOption.name ?? "") ?
    //                                 <RiCheckboxCircleFill className="mt-1 is-size-4 " />
    //                                 :
    //                                 <RiCheckboxBlankCircleLine className="mt-1 is-size-4 " />
    //                         }
    //                         </>
    //                         :
    //                         <RiCheckboxBlankCircleLine className="mt-1 is-size-4 " />

    //                 }
    //             </Button>
    //         </td>
    //     </tr>
    // }

    // const buttonRow = (opt) => {
    //     return <tr key={opt.name} className=" my-0  " onClick={() => { selectAndClose(opt) }} >
    //         <td align="center" className=" no-border has-text-centered " >
    //             <Button fullwidth color={opt.color ?? "light"} >
    //                 {opt.name}
    //             </Button>
    //         </td>
    //     </tr>
    // }


    const field = () => {

    }

    const buttonRow = (opt) => {
        return <tr key={opt.name} className=" my-0  " onClick={() => { selectAndClose(opt) }} >
            <td align="center" className=" no-border has-text-centered " >
                For
            </td>
            <td>

            </td>
        </tr>
    }


    const platformTypeChanged = (val, isOtherVal) => {
        console.log("Platform type changed")
        let af = {...assetFor}
        if (isOtherVal) {
            af.platform_Other = val
        }else{
            af = create_AssetForInfo() // reseting all subvalues 
            af.platform = val
        }
        setAssetFor(af)

    }



    const subPlatformChanged = (val, isOtherVal) => {
        let af = {...assetFor}
        af.contentType = ContentType.any
        af.contentType_Other = ""
        if (isOtherVal) {
            af.subPlatform_Other = val
        }else{
            af.subPlatform = val
        }
        setAssetFor(af)
    }

    const contentTypeChanged = (val, isOtherVal) => {
        let af = {...assetFor}
        if (isOtherVal) {
            af.contentType_Other = val
        }else{
            af.contentType = val
        }
        setAssetFor(af)
    }

    const deviceTypeChanged = (val, isOtherVal) => {
        let af = {...assetFor}
        if (isOtherVal) {
            af.deviceType_Other = val
        }else{
            af.deviceType = val
        }
        setAssetFor(af)
    }

    const colorModeChanged = (val, isOtherVal) => {
        let af = {...assetFor}
        if (isOtherVal) {
            af.colorMode_Other = val
        }else{
            af.colorMode = val
        }
        setAssetFor(af)
    }


    const provideSelection = () => {


        let sl = []

        // choose platform type 
        sl.push(selection_for("Platform Type", all_PlatformTypes, assetFor.platform, platformTypeChanged, assetFor.platform_Other))

        // if social media 
        if (assetFor.platform === PlatformType.socialMedia) {
            sl.push(selection_for("Social Media Platform", all_subPlatforms, assetFor.subPlatform, subPlatformChanged, assetFor.subPlatform_Other))

            // Get Post types as per selected Social Media
            let apt_contTypes = apt_ContentTypes(assetFor.subPlatform)
            sl.push(selection_for("Content Type", apt_contTypes, assetFor.contentType, contentTypeChanged, assetFor.contentType_Other))

            //TODO: 
            // Get Available Device type as per selected post type
            //  {selection_for("Device Type", all_DeviceTypes, null, null)}
            sl.push(selection_for("Device Type", all_DeviceTypes, assetFor.deviceType, deviceTypeChanged, assetFor.deviceType_Other))


            // Get Available ColorMode as per selected post & Device Type
            //  {selection_for("Color Mode", all_ColorModes, null, null)}
            sl.push(selection_for("Color Mode", all_ColorModes, assetFor.colorMode, colorModeChanged, assetFor.colorMode_Other))


        }




        return <Box shadowless radiusless className='has-background-transparent  my-0 py-4 px-5'>
            {/* <p className='has-text-centered has-text-grey-light is-size-7 mt-2 mb-3'>Type above OR choose selections</p> */}
            {sl.map(s => s)}
        </Box>


    }

    const determine = (val) => {

        // This method finds tags in the text to populate selections automatically. And vice-versa


        // text to selections
        let lcase = val.toLowerCase()

        // keywords

        // to depict social media 
        let sm_determiner = [
            "social media", "sm",
            "facebook", "fb",
            "instagram", "insta",
            "linkedin", "ld", "li",
            "twitter", "tw",
            "youtube", "yt"
        ]

        // determine platform 
        let sm_platform_determiner = [
            "facebook", "fb",
            "instagram", "insta",
            "linkedin", "ld", "li",
            "twitter", "tw",
            "youtube", "yt"
        ]

        // determine content type 
        let sm_contentType_determiner = [
            "post",
            "story",
            "reel",
            "video",
            "thumbnail", "video thumbnail",
            "dp", "default pic", "default image",
            "profile pic", "profile image", "event icon", "event pic", "event profile pic", "event image", "event profile image",
            "cover pic", "cover image", "event cover", "event cover pic", "event", "event profile cover"
        ]

        // determine device type 
        // mobile, desktop, laptop, ipad, tv, watch, monitor

        // determine color mode
        // darkmode, deafultmode, lightmode

        // selections to text 

    }


    const chooser = () => {
        return <Box shadowless radiusless className='m-0 p-0 has-background-transparent'>

            {/* <Box shadowless radiusless className='has-background-grey-lightest m-0 p-2 py-0' >
                <Form.Field className="m-0 p-0  has-background-transparent">
                    <Form.Control>
                        <Form.Textarea
                            readOnly={false}
                            fixedSize
                            placeholder="What is this asset for..."
                            maxLength={124}
                            textSize={6}
                            value={null}
                            onChange={(e) => { forTextChanged(e) }}
                            className=" has-background-transparent is-shadowless is-borderless "
                        />
                    </Form.Control>
                </Form.Field>
            </Box> */}




            {
                provideSelection()
            }




        </Box>

    }


    // const up = () => {
    //     return <div>

    //         <Panel className="m-0 is-shadowless ">
    //             {/* <Panel.Block backgroundColor="light" className="minW300 stick-to-top has-background-light z-35 ">
    //                 <Form.Control className=" mx-2" >
    //                     <Columns className="  is-mobile is-narrow" gap="gapless">
    //                         <Columns.Column >
    //                             <Form.Input color="black" textWeight="semibold" size="small" type="text" placeholder="search" className="py-4" value={filterString} onChange={(e) => { searchStringChanged(e.target.value) }} />
    //                         </Columns.Column>
    //                         <Columns.Column size={2}>
    //                             <Button fullwidth size="small" color="light" className="mt-0 mb-0 mr-1 p-0 is-fullHeight ml-1 " onClick={() => { clearSearch() }} > Clear </Button>
    //                         </Columns.Column>
    //                     </Columns>
    //                 </Form.Control>
    //             </Panel.Block> */}
    //             <Table className="minW300 is-fullwidth" hoverable={false} striped={false} bordered={false} >
    //                 <tbody  >
    //                     {
    //                         options.map((opt) => {
    //                             if (props.showButtons) {
    //                                 return buttonRow(opt)
    //                             }
    //                             return row(opt)
    //                         })
    //                     }
    //                 </tbody>
    //             </Table>
    //         </Panel>
    //     </div>
    // }

    const modal = () => {
        return <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className=" almostNoModalBg backblurOnly lightDeleteButton animate-bottom " >
            <Modal.Card className=" maxW450 min90vh ">
                {
                    props.noHeader ?
                        null
                        :
                        <Modal.Card.Header showClose={false} >
                            <Modal.Card.Title className="has-text-grey-light" textSize={6} >Asset is for</Modal.Card.Title>
                            <Button rounded className='is-pulled-right  ' size="small" color={"black"} onClick={selectAndClose}>Done</Button>

                        </Modal.Card.Header>
                }

                <Modal.Card.Body className="p-0">
                    <Box shadowless radiusless className={props.showButtons ? " py-5 is-fullwidth z-1000 has-text-left has-background-white p-0 " : " is-fullwidth z-1000 has-text-left has-background-white p-0 "}  >
                        {/* {up()} */}
                        {chooser()}
                    </Box>

                </Modal.Card.Body>
                {/* {
                    props.noClear ?
                        null :
                        <Modal.Card.Footer renderAs={Button.Group} align="center" className="pt-2" >
                            <Button className="is-size-7 has-text-centered has-text-grey m-0 is-borderless has-background-transparent " onClick={() => { beforeClose("") }} > CLEAR </Button>
                        </Modal.Card.Footer>
                } */}

            </Modal.Card>
        </Modal >

    }


    return modal()

}

export default AssetForSelector