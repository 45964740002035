import Parse from 'parse'
import { isCurrentUser, currentUserInfo,getCurrentWorkspaceId, getOwnedWorkspacesIds, getCurrentWorkspace } from '../utilities/Login';
import { appMeta, SubscriptionStatus, userPointer, UserRole, workspacePointer } from './EnumsAndPointers';

import { Workspace } from './Workspace';

const InvitationClass = Parse.Object.extend("Invitation");


export class Invitation {
    constructor(id, workspace, workspaceId, workspaceInfo, email, pass, role, byUser, byUserInfo, createdAt, toEmail, emailed, emailsInfo, joined, active, projectIdsToJoin, taskIdsToJoin, eventIdsToJoin, appMeta) {
        this.id = id
        this.workspace = workspace 
        this.workspaceId = workspaceId ?? getCurrentWorkspaceId()
        this.workspaceInfo = workspaceInfo ?? Workspace.copyFrom(getCurrentWorkspace()).info()
        this.email = email
        this.pass = pass
        this.role = role ?? UserRole.member
        this.byUser = byUser
        this.byUserInfo = byUserInfo
        this.createdAt = createdAt
        this.toEmail = toEmail ?? true
        this.emailed = emailed ?? false 
        this.emailsInfo = emailsInfo ?? []
        this.joined = joined ?? false
        this.active = active ?? true
        this.projectIdsToJoin = projectIdsToJoin ?? []
        this.taskIdsToJoin = taskIdsToJoin ?? []
        this.eventIdsToJoin = eventIdsToJoin ?? []
        this.appMeta = appMeta
    }


    static copyFrom = (obj) => {
        let np = new Invitation()
        np.id           = obj.id           
        np.workspace    = obj.workspace    
        np.workspaceId  = obj.workspaceId  
        np.workspaceInfo = obj.workspaceInfo
        np.email        = obj.email        
        np.pass         = obj.pass         
        np.role         = obj.role         
        np.byUser       = obj.byUser       
        np.byUserInfo   = obj.byUserInfo   
        np.createdAt    = obj.createdAt    
        np.toEmail      = obj.toEmail      
        np.emailed      = obj.emailed      
        np.emailsInfo   = obj.emailsInfo   
        np.joined       = obj.joined       
        np.active       = obj.active
        np.projectIdsToJoin = obj.projectIdsToJoin
        np.taskIdsToJoin = obj.taskIdsToJoin 
        np.eventIdsToJoin = obj.eventIdsToJoin 
        np.appMeta      = obj.appMeta      
        return np
    }

    static initFromPFObject = (obj) => {
        let np = new Invitation()

        if (!obj.id) {
            return null
        }

        np.id = obj.id

        np.email = obj.get("email")
        np.pass = obj.get("pass")
        np.role = obj.get("role")

        np.createdAt = obj.createdAt
        np.toEmail = obj.get("toEmail")
        np.emailed = obj.get("emailed")
        np.emailsInfo = obj.get("emailsInfo")
        np.joined = obj.get("joined")
        np.active = obj.get("active")

        let bu = obj.get("byUser")
        np.byUser = bu.id ?? ""
        np.byUserInfo = obj.get("byUserInfo")


        let wk = obj.get("workspace")
        np.workspace = wk

        let wkInfo = obj.get("workspaceInfo")
        np.workspaceInfo = wkInfo

        np.workspaceId = obj.get("workspaceId")

        np.projectIdsToJoin = obj.get("projectIdsToJoin") ?? []
        np.taskIdsToJoin = obj.get("taskIdsToJoin") ?? []
        np.eventIdsToJoin = obj.get("eventIdsToJoin") ?? []

        np.appMeta = obj.get("appMeta")

        return np
    }





    add = (callback) => {

        let ob = new InvitationClass()
        if (!this.email) {
            callback(false, "Email is essential")
            return
        }

        // make workspace neceesary
        let wkId = getCurrentWorkspaceId()
        ob.set("workspace", workspacePointer(wkId))
        ob.set("workspaceId", wkId)
        ob.set("workspaceInfo", this.workspaceInfo)

        ob.set("email", this.email)
        ob.set("pass", this.pass)
        ob.set("role", this.role)

        const cu = isCurrentUser()
        ob.set("byUser", userPointer(cu.id))
        // ob.set("byUser", cu)
        ob.set("byUserInfo", currentUserInfo())
        ob.set("toEmail", this.toEmail)
        ob.set("emailed", this.emailed)
        ob.set("emailsInfo", this.emailsInfo)
        ob.set("joined", this.joined)
        ob.set("active", this.active)
        
        ob.set("projectIdsToJoin", this.projectIdsToJoin)
        ob.set("taskIdsToJoin", this.taskIdsToJoin)
        ob.set("eventIdsToJoin", this.eventIdsToJoin)

        ob.set("appMeta", appMeta)

        ob.save()
            .then((evnt) => {
                this.id = evnt.id
                console.log('New Invitation Created')
                callback(true, this, '')
            }, (error) => {
                console.log('Invitation could not be sent: ' + error.message);
                callback(false, null, error.message)
            });

    }

    updateInvitationAsJoined = (errCallback) => {
        if (this.id == null) {
            errCallback("Error : No object id to update Invitation")
            return
        }

        var query = new Parse.Query(InvitationClass);
        query.get(this.id)
            .then((ob) => {
                ob.set("joined", true)
                ob.save()
            }, (error) => {
                errCallback('Error. Invitaion could not be updated as joined =' + error.message)
            });
    }

    static sendMultiple = (invites, callback) => {

    }


    static getMatching = (pass, callback) => {
        var query = new Parse.Query(InvitationClass);

        console.log("GETTING WORKSPACE FOR PASS");

        query.equalTo("pass", pass)

        // double checking that this pass is for this current user and not fraudulent 
        let cu = isCurrentUser()
        let em = cu.get("email")
        query.equalTo("email", em)

        query.include("workspace")


        // Apply sort
        query.descending('createdAt')

        query.find().then((objects) => {
            // console.log('All Projects =')
            // console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Invitation.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Invitations matching pass found  ')
            }

        }, (error) => {
            callback(false, [], error.message)
        })
    }


    static getAll = (callback) => {
        var query = new Parse.Query(InvitationClass);

        

        // Must have rules
        // query.equalTo("project", projectPointer(forProjectId))

        query.equalTo("workspace", workspacePointer(getCurrentWorkspaceId()))


        // Apply sort
        query.descending('createdAt')


        query.find().then((objects) => {
            // console.log('All Projects =')
            // console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Invitation.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Invitations found  ')
            }

        }, (error) => {
            callback(false, [], error.message)
        })
    }



    remove = (callback) => {

    }


}