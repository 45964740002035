import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';

import { AiOutlineAppstoreAdd, AiFillFacebook, AiOutlineFacebook, AiFillInstagram, AiOutlineInstagram, AiFillLinkedin, AiOutlineLinkedin, AiOutlineTwitter, AiFillMediumSquare, AiFillYoutube, AiOutlineYoutube } from 'react-icons/ai';
import { RiFileUploadLine, RiMoreFill, RiImageAddLine, RiTwitterLine, RiMediumLine, RiSpotifyLine, RiSnapchatLine } from 'react-icons/ri';
import { FaSpotify, FaPinterest, FaSnapchatGhost } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { TiDocumentAdd } from 'react-icons/ti';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { GrFormClose } from 'react-icons/gr';
import { IoMdPersonAdd, IoMdAddCircleOutline, IoMdAddCircle } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import moment from 'moment'

import { MdSchedule } from 'react-icons/md';

// IoMdPersonAdd
import ReactTooltip from 'react-tooltip';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Allowed_SocialMedia_Platforms, all_TaskFilter_status, all_TaskFilter_type, SocialMedia_Platform, socialMediaChannels_HexColors, TaskStatus, UserPickerFor } from '../../Models/EnumsAndPointers';
import UserAvatar from '../Calendar/UserAvatar';
import UserPicker from '../Calendar/UserPicker';
import { getTimeAfterMins, randomCode } from '../../utilities/Utilities';
import { Event } from '../../Models/Event';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { TaskFilter } from '../../Models/Task';


// AiFillFacebook, AiOutlineFacebook
// AiFillInstagram, AiOutlineInstagram
// AiFillLinkedin, AiOutlineLinkedin
// AiOutlineTwitter, RiTwitterLine
// FaPinterest, RiPinterestLine
// AiFillMediumSquare, RiMediumLine
// FaSpotify, RiSpotifyLine
// FaSnapchatGhost, RiSnapchatLine, 




const TasksFilter = (props) => {


    const [showUserPickerFor, setShowUserPickerFor] = useState(false)


    const getSelectedUsers = () => {
        let usrs = []
        if (props.filter) {
            if (showUserPickerFor === UserPickerFor.taskAddedBy) {
                usrs = props.filter.addedBy
            }

            if (showUserPickerFor === UserPickerFor.taskAssign) {
                usrs = props.filter.assignedTo
            }
        }
        return usrs
    }


    const usersAreSelected = (arr) => {
        console.log("Got these Task user filters")
        console.log(arr);

        let filterCopy = TaskFilter.copyFrom(props.filter)
        if (showUserPickerFor === UserPickerFor.taskAddedBy) {
            filterCopy.addedBy = arr
        }
        if (showUserPickerFor === UserPickerFor.taskAssign) {
            filterCopy.assignedTo = arr
        }
        props.filterChanged(filterCopy)
    }

    const userRemovePressed = (usr, forPicker) => {
        let filterCopy = TaskFilter.copyFrom(props.filter)
        if (forPicker === UserPickerFor.taskAddedBy) {
            let ussrs = [...filterCopy.addedBy].filter((u) => {
                return u.id !== usr.id
            })
            filterCopy.addedBy = ussrs
        }


        if (forPicker === UserPickerFor.taskAssign) {
            let ussrs = [...filterCopy.assignedTo].filter((u) => {
                return u.id !== usr.id
            })
            filterCopy.assignedTo = ussrs
        }
        props.filterChanged(filterCopy)
    }

    const clearFilter = () => {
        let newF = new TaskFilter()
        props.filterChanged(newF)
    }

    const clearSelections = () => {
        // let newF = new ProjectFilter()
        // props.filterChanged(newF)

        setShowUserPickerFor(null)
    }

    const taskTypeChanged = (tp) => {
        console.log("GOT TYPE CHANGED");
        console.log(tp);
        let filterCopy = TaskFilter.copyFrom(props.filter)
        if (tp === TaskStatus.all) {
            filterCopy.type = null
        } else {
            filterCopy.type = tp
        }
        props.filterChanged(filterCopy)
    }

    const taskStatusChanged = (st) => {
        console.log("GOT STATUS CHANGED");
        console.log(st);
        let filterCopy = TaskFilter.copyFrom(props.filter)
        if (st === TaskStatus.all) {
            filterCopy.status = null
        } else {
            filterCopy.status = st
        }
        props.filterChanged(filterCopy)
    }


    const onDateChange = (name, value, isCleared) => {
        console.log("On date change called")
        console.log(`Name is ${name}`)
        console.log(value)

        let tv = TaskFilter.copyFrom(props.filter)


        if (name === "createdAfter") {
            if (isCleared) {
                tv.createdAfter = null
            } else {
                tv.createdAfter = value
            }
        }

        if (name === "createdBefore") {
            if (isCleared) {
                tv.createdBefore = null
            } else {
                tv.createdBefore = value
            }
        }

        if (name === "deadlineAfter") {
            if (isCleared) {
                tv.deadlineAfter = null
            } else {
                tv.deadlineAfter = value
            }
        }

        if (name === "deadlineBefore") {
            if (isCleared) {
                tv.deadlineBefore = null
            } else {
                tv.deadlineBefore = value
            }
        }

        props.filterChanged(tv)
    }


    const platformToggled = (pl) => {
        let tvs = TaskFilter.copyFrom(props.filter)
        let chls = [...tvs.channels]
        if (chls.includes(pl)) {
            chls = chls.filter((p) => {
                return p !== pl
            })
        } else {
            chls.push(pl)
        }
        tvs.channels = chls
        props.filterChanged(tvs)
    }


    const tagForPlatform = (pl, selected) => {
        let plat = null

        switch (pl) {

            case SocialMedia_Platform.linkedIn: plat = <AiFillLinkedin style={selected ? { color: socialMediaChannels_HexColors.linkedIn } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.facebook: plat = <AiFillFacebook style={selected ? { color: socialMediaChannels_HexColors.facebook } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.instagram: plat = <AiFillInstagram style={selected ? { color: socialMediaChannels_HexColors.instagram } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.twitter: plat = <AiOutlineTwitter style={selected ? { color: socialMediaChannels_HexColors.twitter } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.youtube: plat = <AiFillYoutube style={selected ? { color: socialMediaChannels_HexColors.youtube } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.tiktok: plat = <SiTiktok style={selected ? { color: socialMediaChannels_HexColors.tiktok } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.pinterest: plat = <FaPinterest style={selected ? { color: socialMediaChannels_HexColors.pinterest } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.medium: plat = <AiFillMediumSquare style={selected ? { color: socialMediaChannels_HexColors.medium } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            default: break;
        }
        return <Tag className=" is-size-5 ml-minus-10 mr-1 has-background-white  " onClick={() => { platformToggled(pl) }} > {plat} </Tag>
    }

    const allPlatformTags = (plats) => {

        // console.log("PLATS ARE")
        // console.log(plats)

        let tags = []

        Allowed_SocialMedia_Platforms.sort((p) => {
            return !plats.includes(p)
        })
        Allowed_SocialMedia_Platforms.map((p) => {
            tags.push(tagForPlatform(p, plats.includes(p)))
            return null
        })


        return tags
    }



    const modal = () => {

        let filter = props.filter


        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light height100vc lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" height100vc  m-0 p-0" radiusless>
                    <Modal.Card.Header showClose className="    ">
                        <Modal.Card.Title className="has-text-grey-light" textSize={6} >Filter By</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0 m-0">

                        {showUserPickerFor ? <UserPicker for={showUserPickerFor} selectedUsers={getSelectedUsers()} usersAreSelected={usersAreSelected} close={clearSelections} /> : null}

                        <Box shadowless className="  " >
                            <div>
                                <Form.Label className="is-size-7 mt-3 mb-0 ml-2 "  >Created</Form.Label>
                                <Columns>
                                    <Columns.Column gap={0}>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >After</Form.Label>
                                            <Form.Control>
                                                {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                                <DatePicker
                                                    name="createdAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    // selected={f}
                                                    showTimeSelect={false}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    dateFormat="dd/MMM/yyyy"

                                                    selected={filter.createdAfter}
                                                    onChange={(value) => {
                                                        onDateChange("createdAfter", value);
                                                    }}
                                                    // onBlur={() => {
                                                    //     onBlur(name, true);
                                                    // }}
                                                    onFocus={e => e.target.blur()}
                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                    <Columns.Column>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >Before</Form.Label>
                                            <Form.Control>
                                                {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                                <DatePicker
                                                    name="createdAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    // selected={f}
                                                    showTimeSelect={false}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    dateFormat="dd/MMM/yyyy"
                                                    selected={filter.createdBefore}
                                                    onChange={(value) => {
                                                        onDateChange("createdBefore", value);
                                                    }}
                                                    // onBlur={() => {
                                                    //     onBlur(name, true);
                                                    // }}
                                                    onFocus={e => e.target.blur()}

                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                </Columns>
                                <br />
                            </div>

                            <div>
                                <Form.Label className="is-size-7 mt-3 mb-0 ml-2 "  >Deadline</Form.Label>
                                <Columns>
                                    <Columns.Column gap={0}>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >After</Form.Label>
                                            <Form.Control>
                                                {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                                <DatePicker
                                                    name="deadlineAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    // selected={f}
                                                    showTimeSelect={false}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    dateFormat="dd/MMM/yyyy"

                                                    selected={filter.deadlineAfter}
                                                    onChange={(value) => {
                                                        onDateChange("deadlineAfter", value);
                                                    }}
                                                    // onBlur={() => {
                                                    //     onBlur(name, true);
                                                    // }}
                                                    onFocus={e => e.target.blur()}
                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                    <Columns.Column>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >Before</Form.Label>
                                            <Form.Control>
                                                {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                                <DatePicker
                                                    name="deadlineAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    // selected={f}
                                                    showTimeSelect={false}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    dateFormat="dd/MMM/yyyy"
                                                    selected={filter.deadlineBefore}
                                                    onChange={(value) => {
                                                        onDateChange("deadlineBefore", value);
                                                    }}
                                                    // onBlur={() => {
                                                    //     onBlur(name, true);
                                                    // }}
                                                    onFocus={e => e.target.blur()}

                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                </Columns>
                                <br />
                            </div>


                            {/* <Form.Field>
                                        <Form.Label className="is-size-7 mt-2 mb-0 ml-2 "  > In Projects
                                            <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPicker(true) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                        </Form.Label>

                                        <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                            <Tag.Group className="mt-0">
                                                {
                                                    selectedUsers.map((su) => {
                                                        return <UserAvatar isRemovable={true} removePressed={userRemovePressed} infoObj={su} nameWrap={true} />
                                                    })
                                                }
                                            </Tag.Group>
                                        </Box>
                                    </Form.Field> */}


                            <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Added By
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.taskAddedBy) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            filter.addedBy.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(su, UserPickerFor.taskAddedBy)} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>

                            <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Assigned To
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.taskAssign) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            filter.assignedTo.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(su, UserPickerFor.taskAssign)} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>

                            <Form.Field>
                                {/* <Form.Label className="is-size-7 mt-4 mb-0 ml-2 " textWeight="semibold" textColor="grey-light" >Publish on {event.channels.length > 0 ? (event.channels.length > 1 ? `- ${event.channels.length} channels` : `- ${event.channels.length} platform`) : '(Select platform)'} </Form.Label> */}
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 "  >For Channels </Form.Label>

                                <Box shadowless className=" px-0 pt-2 " >
                                    {
                                        allPlatformTags(filter.channels)
                                    }
                                </Box>
                            </Form.Field>


                            <Form.Field>
                                <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2  mt-5" >Type</Form.Label>
                                <Form.Control>
                                    <Form.Select
                                        size='small'
                                        className=" mt-2 ml-2 "
                                        value={filter.type}
                                        onChange={(e) => {
                                            taskTypeChanged(e.target.value)
                                        }}
                                    >
                                        {
                                            all_TaskFilter_type.map((m) => <option value={m}>{m}</option>)
                                        }
                                    </Form.Select>
                                    {/* <Icon align="left">
                                                <MdSchedule className="is-size-5 mt-4 ml-4" />
                                            </Icon> */}
                                </Form.Control>
                                <br />
                            </Form.Field>

                            <Form.Field>
                                <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2  mt-5"  >Status</Form.Label>
                                <Form.Control>
                                    <Form.Select
                                        size='small'
                                        className=" mt-2 ml-2 "
                                        value={filter.status}
                                        onChange={(e) => {
                                            taskStatusChanged(e.target.value)
                                        }}
                                    >
                                        {
                                            all_TaskFilter_status.map((m) => <option value={m}>{m}</option>)
                                        }
                                    </Form.Select>
                                    {/* <Icon align="left">
                                                <MdSchedule className="is-size-5 mt-4 ml-4" />
                                            </Icon> */}
                                </Form.Control>
                                <br />
                            </Form.Field>


                        </Box>
                        <br />



                    </Modal.Card.Body>
                    <Modal.Card.Footer renderAs={Button.Group} align="center" className="pt-2" >
                        <Button className="is-size-7 has-text-centered has-text-grey m-0 is-borderless has-background-transparent " onClick={clearFilter}  > CLEAR </Button>
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }


    return modal()



}

export default TasksFilter