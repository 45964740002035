import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon, Message } from 'react-bulma-components';

import { AiOutlineAppstoreAdd, AiFillFacebook, AiOutlineFacebook, AiFillInstagram, AiOutlineInstagram, AiFillLinkedin, AiOutlineLinkedin, AiOutlineTwitter, AiFillMediumSquare, AiFillYoutube, AiOutlineYoutube } from 'react-icons/ai';
import { RiFileUploadLine, RiMoreFill, RiImageAddLine, RiTwitterLine, RiMediumLine, RiSpotifyLine, RiSnapchatLine } from 'react-icons/ri';
import { FaSpotify, FaPinterest, FaSnapchatGhost } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { TiDocumentAdd } from 'react-icons/ti';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { GrFormClose } from 'react-icons/gr';
import { IoMdPersonAdd, IoMdAddCircleOutline, IoMdAddCircle } from 'react-icons/io';
import { IoAddCircleOutline, IoCheckmarkDoneOutline, IoCloseOutline } from 'react-icons/io5';
import moment from 'moment'

import { MdSchedule } from 'react-icons/md';



// IoMdPersonAdd
import ReactTooltip from 'react-tooltip';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Allowed_SocialMedia_Platforms, CRUDAction, CRUDEvent, EntityLogType, EntityType, EventCompletionStatus, Event_Dynamic_Changeables, ProjectFolderLevel, SelectableEntity, SocialMedia_Platform, socialMediaChannels_HexColors, UserPickerFor, userPointer } from '../../Models/EnumsAndPointers';
import UserAvatar from './UserAvatar';
import UserPicker from './UserPicker';
import { completeDateTime, confirmAction, getTimeAfterMins, isPrivate, privateTag, randomCode } from '../../utilities/Utilities';
import { Event } from '../../Models/Event';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import ModalExplorer from '../Common/ModalExplorer';
import TextEditor from '../Tasks/TextEditor';
import TimelineExplorer from '../Common/TimelineExplorer';
import { Timeline } from '../../Models/Timeline';


// AiFillFacebook, AiOutlineFacebook
// AiFillInstagram, AiOutlineInstagram
// AiFillLinkedin, AiOutlineLinkedin
// AiOutlineTwitter, RiTwitterLine
// FaPinterest, RiPinterestLine
// AiFillMediumSquare, RiMediumLine
// FaSpotify, RiSpotifyLine
// FaSnapchatGhost, RiSnapchatLine, 




const EventModal = (props) => {

    let newEvent = () => {
        let ev = new Event()
        ev.byUser = isCurrentUser().id
        ev.byUserInfo = currentUserInfo()
        ev.createdAt = new Date()
        return ev
    }


    const sampleUsers = [
        {
            name: "Beth Castillo",
            imageUrl: "https://randomuser.me/api/portraits/women/24.jpg",
            id: randomCode(6),
            isSelected: true,
        },
        {
            name: "Avery Schmidt",
            imageUrl: "https://randomuser.me/api/portraits/women/35.jpg",
            id: randomCode(6),
            isSelected: true,
        },
        {
            name: "Maxine Gray",
            imageUrl: "https://randomuser.me/api/portraits/women/81.jpg",
            id: randomCode(6),
            isSelected: true,

        },
        {
            name: "Brooklyn Nelson",
            imageUrl: "https://randomuser.me/api/portraits/women/34.jpg",
            id: randomCode(6),
            isSelected: true,
        },
    ]



    const [event, setEvent] = useState(props.event ?? newEvent())
    const [lastUploadedEvent, setLastUploaadedEvent] = useState(event)
    const [changesMade, setChangesMade] = useState([])

    const [showUserPickerFor, setShowUserPickerFor] = useState(null)
    const [showTimeline, setShowTimeline] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState(sampleUsers)
    const [showAssetsPicker, setShowAssetsPicker] = useState(false)

    const [updatedAttr, setUpdatedAttr] = useState([])

    const [addOrDeleteNote, setAddOrDeleteNote] = useState(null)


    const eventAssetsOnLoad = (tk) => {
        const { projects, assets, comments } = tk
        return {
            projects: projects ?? [],
            assets: assets ?? [],
            comments: comments ?? []
        }
    }

    const [selectedAssets, setSelectedAssets] = useState(eventAssetsOnLoad(event))

    const [isSaving, setIsSaving] = useState(false)


    const contentChanged = (val) => {
        let ev = { ...event }
        ev.content = val
        console.log("CONTENT IS CHANGED")
        setEvent(ev)


        // entityChanged("content", "AddedContent", " " + val, new Date())
    }

    const contentChangedBlur = (val) => {
        let valDesc = val
        if (val.length > 50) {
            valDesc = val.substring(0, 49) + "..."
        }
        entityChanged("content", "AddedContent", CRUDEvent.updated, val, valDesc, new Date())
    }

    const saveEvent = () => {
        if (event.id) {
            //update event
            updateEvent()
        } else if (props.isNewEvent) {
            // add event
            addEvent()
        }
    }


    const addEvent = () => {
        if (!event.starts) {
            alert("There is no date set")
            return
        }
        setIsSaving(true)
        let ev = Event.copyFrom(event)
        // ev.ends = getTimeAfterMins(date, 31)
        ev.ends = moment(ev.starts).add(30, "minutes").toDate()

        let cuInfo = currentUserInfo()
        ev.members = [cuInfo.id]

        // console.log("Date starts")
        // console.log(ev.starts)
        // console.log("Date ends")
        // console.log(ev.ends)

        ev.byUser = isCurrentUser()
        ev.byUserInfo = cuInfo.id

        ev.add((succ, result, errMsg) => {
            if (succ) {
                console.log("Event is added")
                console.log(result)
                setEvent(result)
                setLastUploaadedEvent(result)
                props.newEventAdded(result)
                console.log("AFTER ADDED")

                Timeline.updateTimline(EntityType.event, result.id, result, EntityLogType.created, "Created", null)

            } else {
                console.log(errMsg)
            }
            setIsSaving(false)
        })
        setChangesMade([])

    }

    const updateEvent = (psdEvent) => {
        if (!event.starts) {
            alert("There is no date set")
            return
        }
        setIsSaving(true)
        let ev = Event.copyFrom(psdEvent ?? event)
        // ev.ends = getTimeAfterMins(date, 31)
        ev.ends = moment(ev.starts).add(30, "minutes").toDate()

        // console.log("Date starts")
        // console.log(ev.starts)
        // console.log("Date ends")
        // console.log(ev.ends)

        let description = changesMade.map((c) => c.attrDesc + " " + c.operation + " : " + c.value).join(" >n>· ")

        console.log("DESCRIPTION BEFORE UPDATE IS")
        console.log(description)

        // check if changes are only dynamic .. if yes.. only Timeline needs to be updated. Nothinh else.
        let dynChangedAttrs = [
            Event.attributeForChangeable(Event_Dynamic_Changeables.AssignedToUsers),
            Event.attributeForChangeable(Event_Dynamic_Changeables.SharedWithUsers),
            Event.attributeForChangeable(Event_Dynamic_Changeables.Note),
            Event.attributeForChangeable(Event_Dynamic_Changeables.AssetsAndContents)
        ]

        // console.log("ALL CHANGES")
        // console.log(changesMade)

        let nonDynamicChanges = changesMade.filter((c) => {
            return !dynChangedAttrs.includes(c.attribute)
        })

        // console.log("NON DYNAMIC CHANGES ARE")
        // console.log(nonDynamicChanges)

        if (nonDynamicChanges.length) {
            ev.update((succ, result, errMsg) => {
                if (succ) {
                    // console.log("Event is updated")
                    // console.log(result)
                    setEvent(result)
                    setLastUploaadedEvent(result)
                    if (props.eventIsUpdated) {
                        props.eventIsUpdated(result)
                    }
                    // console.log("AFTER UPDTAED")

                    Timeline.updateTimline(EntityType.event, result.id, result, EntityLogType.updated, "Updated", description)
                    setChangesMade([])

                } else {
                    console.log(errMsg)
                }
                setIsSaving(false)
            })
        } else {

            setIsSaving(true)
            // if (window.confirm("Upload only timeline")) {
            //     console.log("YES")
            // }else{
            //     console.log("NO")
            // }

            Timeline.updateTimline(EntityType.event, ev.id, ev, EntityLogType.updated, "Updated", description)
            setTimeout(() => {
                setIsSaving(old => false)
            }, 1000)
            setChangesMade([])

        }




    }




    // const usersAreSelected = (arr) => {
    //     setSelectedUsers(arr)
    //     clearSelections()
    // }


    const usersAreSelected = (arr) => {
        // setSelectedUsers(arr)



        console.log("Users are selected")
        console.log(arr)
        // For Assigned to tasks
        let ev = Event.copyFrom(event)
        if (showUserPickerFor === UserPickerFor.eventAssign) {
            ev.assignedToInfo = arr
            // let userNames = arr.map(u=> u.name)
            // console.log("USERNAMES ARE")
            // console.log(userNames)

            // entityChanged( Event.attributeForChangeable(Event_Dynamic_Changeables.AssignedToUsers), Event_Dynamic_Changeables.AssignedToUsers, CRUDEvent.updated, arr, userNames.join(","), new Date())
            ev.addAssignTo_multiple(arr.map(a => a.id), arr, (err) => {
                if (err) {
                    console.log("Users could not be added for Event AssignTo ")
                    console.log(err)
                }
            })

        }
        if (showUserPickerFor === UserPickerFor.eventSharedWith) {
            ev.sharedWithInfo = arr
            // let userNames = arr.map(u=> u.name )
            // console.log("CHANGING SHARED WITH USERS TO")
            // console.log("changed to " + userNames.join(","))

            // entityChanged( Event.attributeForChangeable(Event_Dynamic_Changeables.SharedWithUsers), Event_Dynamic_Changeables.SharedWithUsers, CRUDEvent.updated, arr, userNames.join(","), new Date())
            ev.addSharedWith_multiple(arr.map(a => a.id), arr, (err) => {
                if (err) {
                    console.log("Users could not be added for Task shared with ")
                    console.log(err)
                }
            })

        }
        setEvent(t => ev)
        // console.log("Setting new task")
        // console.log(tk)

        // clearSelections()








        // update_decider = (originalObj, changedAttributes, callback) => {



    }

    // entityChanged( Event_Dynamic_Changeables.AssignedToUsers, "changed to " + userNames.join(","), new Date())

    // Details Needed >  attribute, attributeDesc, operationType, valueObj, valueDescription, time, by

    const entityChanged = (attr, attributeDesc, opr, valObj, val, time) => {

        // console.log("NEW ENTITY CHANGED ENTRY")
        console.log(`ATTR = ${attr}, VAL = ${val}, TIME = ${time}`)

        let changes = [...changesMade]
        let matchingChangeIndex = changes.findIndex((c) => c.attribute === attr)

        // Event_Dynamic_Changeables



        console.log("COMPARING LASTUPLOADEVENT")
        console.log(lastUploadedEvent)
        console.log("WITH NEW")
        console.log(val)


        // DOES NOT WORK FOR NOTE.. AS A PERSON CAN ADD MULTIPLE NOTES IN ONE GO



        // if (lastUploadedEvent[attrLiteral] !== val){
        if (matchingChangeIndex >= 0) {
            let matchingChange = changes[matchingChangeIndex]

            if (attr === Event_Dynamic_Changeables.note) {
                if (matchingChange.value !== val) {
                    changes.push({ attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() })
                }
            } else {

                if (moment(time).isAfter(moment(matchingChange.time))) {
                    console.log("CHANGE REPLACED")
                    changes[matchingChangeIndex] = { attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() }
                }
                // console.log("The time of the change ")
                // console.log(`ATTR-${attr} VAL-${val} TIME-${time}`)
                // console.log("COMPARED WITH EARLIER")
                // console.log(matchingChange)
            }


        } else {
            console.log("CHANGE ADDED")
            changes.push({ attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() })
        }

        // }else{
        //     //If this attribute was changed earlier but now is being reversed back without saving
        //     if (matchingChangeIndex >= 0){
        //         console.log("CHANGE REMOVED")
        //         delete changes[matchingChangeIndex]

        //     }
        // }




        setChangesMade((old) => {
            console.log("NOW THE CHNAGE ARE")
            console.log(changes)
            return changes
        })
    }


    const userRemovePressed = (from, usr) => {
        // let ussrs = [...selectedUsers].filter((u) => {
        //     return u.id !== usr.id
        // })
        // setSelectedUsers(ussrs)

        // console.log("REMOVE FROM ")
        // console.log(from)
        // console.log(usr)


        confirmAction("Are you sure you want to remove " + usr.name, () => {
            let tk = Event.copyFrom(event)

            let arr = []
            if (from === UserPickerFor.eventAssign) {
                arr = [...event.assignedToInfo]
            }
            if (from === UserPickerFor.eventSharedWith) {
                console.log("ARR TAKEN FROM EVENT SHARED WITH")
                arr = [...event.sharedWithInfo]
            }

            let ussrs = arr.filter((u) => {
                return u.id !== usr.id
            })

            if (from === UserPickerFor.eventAssign) {
                tk.assignedToInfo = ussrs
                tk.removeAssignTo(usr.id, usr, (err) => {
                    if (err) {
                        alert("Users could not be removed for AssignTo ")
                        console.log(err)
                    }
                })
            }
            if (from === UserPickerFor.eventSharedWith) {
                // console.log("ARR SHARED WITH UPDATED")
                tk.sharedWithInfo = ussrs
                tk.removeSharedWith(usr.id, usr, (err) => {
                    if (err) {
                        alert("Users could not be removed for SharedWith ")
                        console.log(err)
                    }
                })
                // let userNames = ussrs.map((u)=> u.name)
                // entityChanged("SharedWithUsers", "changed to " + userNames.join(","))
            }


            if (from === UserPickerFor.eventAssign) {
                let userNames = tk.assignedToInfo.map(u => u.name)
                console.log("CHANGING SHARED WITH USERS TO")
                console.log("changed to " + userNames.join(","))

                entityChanged(Event.attributeForChangeable(Event_Dynamic_Changeables.AssignedToUsers), Event_Dynamic_Changeables.AssignedToUsers, CRUDEvent.updated, arr, userNames.join(","), new Date())

            }

            if (from === UserPickerFor.eventSharedWith) {
                let userNames = tk.sharedWithInfo.map(u => u.name)
                console.log("CHANGING SHARED WITH USERS TO")
                console.log("changed to " + userNames.join(","))

                entityChanged(Event.attributeForChangeable(Event_Dynamic_Changeables.SharedWithUsers), Event_Dynamic_Changeables.SharedWithUsers, CRUDEvent.updated, arr, userNames.join(","), new Date())
            }


            setEvent(t => tk)
        }, null)



    }

    // title
    // description

    // asset
    // content


    const clearSelections = () => {

        if (showUserPickerFor === UserPickerFor.eventAssign) {
            let ev = Event.copyFrom(event)
            let userNames = ev.assignedToInfo.map(u => u.name)
            console.log("CHANGING SHARED WITH USERS TO")
            console.log("changed to " + userNames.join(","))
            entityChanged(Event.attributeForChangeable(Event_Dynamic_Changeables.AssignedToUsers), Event_Dynamic_Changeables.AssignedToUsers, CRUDEvent.updated, ev.assignedToInfo, userNames.join(","), new Date())
        }

        if (showUserPickerFor === UserPickerFor.eventSharedWith) {
            let ev = Event.copyFrom(event)
            let userNames = ev.sharedWithInfo.map(u => u.name)
            console.log("CHANGING SHARED WITH USERS TO")
            console.log("changed to " + userNames.join(","))
            entityChanged(Event.attributeForChangeable(Event_Dynamic_Changeables.SharedWithUsers), Event_Dynamic_Changeables.SharedWithUsers, CRUDEvent.updated, ev.sharedWithInfo, userNames.join(","), new Date())
        }

        setShowUserPickerFor(null)
        setShowAssetsPicker(false)
    }


    const onDateChange = (name, value, isCleared) => {
        console.log("On date change called")
        console.log(`Name is ${name}`)
        console.log(value)

        let crudType = CRUDEvent.updated

        if (name === "on") {
            // if (isCleared){
            //     setDate(null)
            // }else{
            let ev = Event.copyFrom(event)
            if (isCleared) {
                ev.starts = null
                crudType = CRUDEvent.deleted

            } else {

                // was intially null, but now added
                if (ev.starts === null) {
                    crudType = CRUDEvent.added
                }

                ev.starts = value
            }
            setEvent(ev)

            entityChanged("starts", "Date", crudType, value, completeDateTime(value), new Date())

            // }
        }
    }


    const platformToggled = (pl) => {
        let evs = Event.copyFrom(event)
        let chls = [...evs.channels]
        let crudType = CRUDEvent.added
        if (chls.length) {
            crudType = CRUDEvent.updated
        }

        if (chls.includes(pl)) {
            chls = chls.filter((p) => {
                return p !== pl
            })
        } else {
            chls.push(pl)
        }
        evs.channels = chls
        setEvent(evs)

        entityChanged("channels", "Platforms", crudType, chls, chls.join(","), new Date())

    }


    const tagForPlatform = (pl, selected) => {
        let plat = null

        switch (pl) {

            case SocialMedia_Platform.linkedIn: plat = <AiFillLinkedin style={selected ? { color: socialMediaChannels_HexColors.linkedIn } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.facebook: plat = <AiFillFacebook style={selected ? { color: socialMediaChannels_HexColors.facebook } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.instagram: plat = <AiFillInstagram style={selected ? { color: socialMediaChannels_HexColors.instagram } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.twitter: plat = <AiOutlineTwitter style={selected ? { color: socialMediaChannels_HexColors.twitter } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.youtube: plat = <AiFillYoutube style={selected ? { color: socialMediaChannels_HexColors.youtube } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.tiktok: plat = <SiTiktok style={selected ? { color: socialMediaChannels_HexColors.tiktok } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.pinterest: plat = <FaPinterest style={selected ? { color: socialMediaChannels_HexColors.pinterest } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.medium: plat = <AiFillMediumSquare style={selected ? { color: socialMediaChannels_HexColors.medium } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            default: break;
        }
        return <Tag className=" is-size-5 ml-minus-10 mr-1 has-background-white  " onClick={() => { platformToggled(pl) }} > {plat} </Tag>
    }

    const allPlatformTags = (plats) => {

        // console.log("PLATS ARE")
        // console.log(plats)

        let tags = []

        Allowed_SocialMedia_Platforms.sort((p) => {
            return !plats.includes(p)
        })
        Allowed_SocialMedia_Platforms.map((p) => {
            tags.push(tagForPlatform(p, plats.includes(p)))
            return null
        })


        return tags
    }


    const selectionsChanged = (attcts) => {
        console.log("ATTACHMENTS ARE")
        console.log(attcts)
        // let tk = {...task}
        // tk.asse
        setSelectedAssets(attcts)


        let tk = Event.copyFrom(event)
        let { projects, comments, assets } = attcts
        // let { assets } = attcts

        tk.projects = projects ?? []
        tk.projectsIds = tk.projects.map(p => p.id)

        tk.comments = comments ?? []
        tk.commentsIds = tk.comments.map(c => c.id)

        tk.assets = assets ?? []
        tk.assetsIds = tk.assets.map(a => a.id)
        let imgUrls = []
        tk.assets.forEach(ast => {
            if (ast.type === 'image/png' && ast.url) {
                imgUrls.push(ast.url)
            }
        });
        tk.thumbImageUrls = imgUrls

        setEvent(tk)


        entityChanged(Event.attributeForChangeable(Event_Dynamic_Changeables.AssetsAndContents), Event_Dynamic_Changeables.AssetsAndContents, CRUDEvent.updated, assets, tk.assets.length + " Assets", new Date())
        clearSelections()



        // alert("ADD TASK ATTACHEMENTS")

    }


    const pickFile = (val) => {

        if (val === "pickAsset") {
            setShowAssetsPicker(true)
        }
        if (val === "pickUpload") {

        }
    }


    const listAssets = (asts) => {
        if (asts.length) {
            return asts.map(ast => <Box className='m-1 p-2'>

                {/* <p className='is-size-9 has-text-grey-light'>ASSET</p> */}
                <Columns>
                    <Columns.Column narrow>
                        <Image size={128} src={ast.url ?? "https://images.unsplash.com/photo-1627482965115-e8e29bbcd451?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"} className=" ml-0 mt-1 overflow-hidden is-bordered border-radius-6 " />
                    </Columns.Column>
                    {ast.caption ?
                        <Columns.Column>
                            <p className=' is-size-8 mt-1 has-text-grey-light' > {ast.captionType} </p>
                            <p className=' is-size-7  has-text-grey' > {ast.caption} </p>
                        </Columns.Column>
                        : null
                    }
                </Columns>
            </Box>
            )
        }
    }


    const listAttachments = (allAttachments, toCount) => {
        const { projects, assets, comments } = allAttachments

        if (toCount) {
            // just checking if any attachments exist
            if (projects.length > 0 || assets.length > 0 || comments.length > 0) {
                return projects.length + assets.length + comments.length
            }
            return false
        }

        return <>
            {listAssets(assets)}
        </>

    }

    const addNotePressed = () => {
        let blankNote = Event.copyFrom(event).createNote(currentUserInfo(true), "")
        setAddOrDeleteNote({ note: blankNote, crud: CRUDAction.create })
        // alert("Add Note pressed")
    }

    const textChangeDone =  (val, payload, crud) => {

        if (!val || crud !== CRUDAction.create) {
            setAddOrDeleteNote(null)
            return
        }

        let tk = Event.copyFrom(event)
        let nts = [...tk.notes]

        // if (id){
        //     let thisNoteIndex = nts.findIndex((n) => n.id === id)
        //     if (thisNoteIndex >= 0){
        //         let thisTk = {...nts[thisNoteIndex]}
        //         thisTk.note = val
        //         nts[thisNoteIndex] = thisTk
        //     }
        // }else{

        let nt = tk.createNote(currentUserInfo(), val)
        if (tk.id) {
            // saved Task
            tk.addNote(nt, (err) => {
                if (err) {
                    alert("Note could not be added" + err)
                }
            })
            if (nt !== "") {
                let valDesc = val
                if (val.length > 50) {
                    valDesc = val.substring(0, 49) + "..."
                }
                entityChanged(Event.attributeForChangeable(Event_Dynamic_Changeables.Note), Event_Dynamic_Changeables.Note, CRUDEvent.added, val, valDesc, new Date())
            }

        } else {
            console.log("ADDING TASK TO NOTE")
            console.log()
        }

        nts.push(nt)
        tk.notes = nts
        console.log("TASK AFTER NEW NOTE ADDED")
        console.log(tk)

        // }
        setEvent(tk)
        setAddOrDeleteNote(null)
    }

    const noteDeletePressed = () => {

        confirmAction("Are you sure to delete this note. This cannot be undone.", () => {
            let note = { ...addOrDeleteNote }
            let noteByUserId = note.byUserInfo.id ?? ""
            let cu = currentUserInfo()

            console.log(`noteByUserId = ${noteByUserId}`)
            console.log(`CU.ID = ${cu.id}`)

            if (noteByUserId === cu.id) {
                console.log("Deleting note")

                console.log(note)


                let tk = Event.copyFrom(event)
                if (note.id) {
                    console.log("Deleting note now")
                    // saved Task
                    tk.removeNote(note, (err) => {
                        if (err) {
                            alert("Note could not be removed" + err)
                        }
                    })

                    let valDesc = note.note
                    if (note.note.length > 50) {
                        valDesc = note.note.substring(0, 49) + "..."
                    }
                    entityChanged(Event.attributeForChangeable(Event_Dynamic_Changeables.Note), Event_Dynamic_Changeables.Note, CRUDEvent.deleted, note, valDesc, new Date())


                } else {
                    console.log("REMOVING NOTE JUST FROM UI")
                }

                let nts = [...tk.notes].filter(t => t.id !== note.id)

                tk.notes = nts
                setAddOrDeleteNote(null)
                setEvent(tk)
            } else {
                alert("Note could not be deleted. It can only be deleted by the creator of the note")
            }
        }, null)


    }



    const noteSelected = (note) => {
        let noteByUserId = note.byUserInfo.id ?? ""
        let cu = currentUserInfo()
        if (noteByUserId === cu.id) {
            setAddOrDeleteNote({ note: note, crud: CRUDAction.read })
        }
    }


    const setEventName = (tl) => {
        let tk = { ...event }
        tk.name = tl
        setEvent(tk)
    }


    const setEventNameBlur = (tl) => {
        // console.log("Event name input is blurred with value " + tl)
        // let tk = { ...event }
        // tk.name = tl
        // setEvent(tk)
        // entityChanged("Name", "changed to " + tl, new Date())

        entityChanged("name", "Name", CRUDEvent.updated, tl, tl, new Date())
    }


    const statusChanged = (val) => {
        // console.log("STATUS CHANGED TO")
        // console.log(val)
        let tk = { ...event }
        tk.status = val
        if (val === EventCompletionStatus.complete) {
            const cu = isCurrentUser()
            tk.completedBy = userPointer(cu.id)
            tk.completedByInfo = currentUserInfo()
            tk.completedAt = new Date()
        } else {
            tk.completedBy = null
            tk.completedByInfo = null
            tk.completedAt = null
        }
        setEvent(tk)

        entityChanged("status", "Status", CRUDEvent.updated, val, val, new Date())

    }


    const selectedUsersAsPerShowFor = () => {

        if (showUserPickerFor === UserPickerFor.eventAssign) {
            return event.assignedToInfo
        }

        if (showUserPickerFor === UserPickerFor.eventSharedWith) {
            return event.sharedWithInfo
        }

        return null

    }

    const dropdownValueChanged = (val) => {

        if (val === "timeline") {
            setShowTimeline(true)
            return
        }

        let ev = Event.copyFrom(event)
        if (val === "markDone") {
            const cu = isCurrentUser()
            ev.status = EventCompletionStatus.complete
            ev.completedBy = userPointer(cu.id)
            ev.completedByInfo = currentUserInfo()
            ev.completedAt = new Date()
            entityChanged("status", "Status", CRUDEvent.updated, EventCompletionStatus.complete, EventCompletionStatus.complete, new Date())


            updateEvent(ev)
        }
        if (val === "archive") {

            confirmAction("Are you sure you want to cancel this event. It cannot be undone.", () => {
                ev.isArchived = true
                const cu = isCurrentUser()
                ev.archivedBy = userPointer(cu.id)
                ev.archivedByInfo = currentUserInfo()
                entityChanged("isArchived", "Is Archived", CRUDEvent.deleted, "", "", new Date())
                updateEvent(ev)
            }, null)


        }
    }


    const newModal = () => {


        // console.log("EVENT IN NEW MODAL IS")
        // console.log(event)

        let assetsLength = 0
        if (event.assets.length) {
            assetsLength = event.assets.length
        }


        return <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light height100vc lightDeleteButton animate-bottom p-0" >
            <Modal.Card className=" height100vc  m-0 p-0" radiusless>
                <Modal.Card.Header showClose className=" ">
                    <Modal.Card.Title className="   " >
                        <Dropdown left renderAs="button" className="  has-text-left  ml-minus-10 mt-minus-10  is-small is-arrowless has-background-transparent  is-borderless " label={
                            <HiOutlineDotsHorizontal className="is-size-5 mt-2 has-text-grey" />
                        } onChange={(val) => { dropdownValueChanged(val) }} >
                            <Dropdown.Item renderAs="a" value="timeline">Timeline</Dropdown.Item>
                            <Dropdown.Item renderAs="a" value="markDone">Mark Done</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item renderAs="a" value="archive">Cancel Event</Dropdown.Item>
                        </Dropdown>

                        <p className="has-text-grey-light is-size-7 has-text-centered"> {isPrivate(SelectableEntity.events, event) ? privateTag()  : null}  Added By {event.byUserInfo.name} </p>
                        {/* {
                            event.status === EventCompletionStatus.complete ?
                                <p className="has-text-grey-light is-size-7 has-text-centered mb-0 mt-3 pb-0"> <span className='mt-15'><IoCheckmarkDoneOutline /></span>  Completed By {event.completedByInfo.name} on {moment(event.completedAt).format('DD-MMM-YYYY h:mm a')} </p>
                                : null
                        } */}
                        {/* {
                            event.isArchived ?
                                <p className="has-text-danger has-text-weight-semibold is-size-7 has-text-centered mb-0 mt-3 pb-0"> Cancelled By {event.archivedByInfo.name}</p>
                                : null
                        } */}
                    </Modal.Card.Title>

                </Modal.Card.Header>
                <Modal.Card.Body className="p-0 m-0">

                    {showAssetsPicker ? <ModalExplorer selectables={{ entities: [SelectableEntity.assets], multiSelection: true }} toExplore={ProjectFolderLevel.project} selections={selectedAssets} selectionsChanged={selectionsChanged} close={clearSelections} /> : null}
                    {showUserPickerFor ? <UserPicker for={showUserPickerFor} selectedUsers={selectedUsersAsPerShowFor()} usersAreSelected={usersAreSelected} close={clearSelections} /> : null}
                    {addOrDeleteNote ? <TextEditor crud={addOrDeleteNote.crud} payload={addOrDeleteNote} text={addOrDeleteNote.note.note} footerLine={` By ${addOrDeleteNote.note.byUserInfo.name}  ${addOrDeleteNote.note.createdAt ? `· ${moment.unix(addOrDeleteNote.note.createdAt).format('DD-MMM-YYYY h:mm a')}` : ""}`} textChangeDone={textChangeDone} showDeleteOption={true} onDelete={noteDeletePressed} close={clearSelections} /> : null}
                    

                    {showTimeline && event.id ? <TimelineExplorer entity={EntityType.event} entityId={event.id} close={() => { setShowTimeline(false) }} /> : null}

                    {
                        event.status === EventCompletionStatus.complete ?
                            <Box radiusless borderless textColor='white' textWeight='semibold' className="has-background-success  is-size-7 has-text-centered py-2 my-0">Completed By {event.completedByInfo.name} on {moment(event.completedAt).format('DD-MMM-YYYY h:mm a')}</Box>
                            : null
                    }

                    {
                        event.isArchived ?
                            <Box radiusless borderless textColor='white' textWeight='semibold' className="has-background-danger  is-size-7 has-text-centered py-2 my-0">Cancelled By {event.archivedByInfo.name}</Box>
                            : null
                    }

                    <Box shadowless className=" " >

                        <Columns className="is-mobile mt-4">
                            <Columns.Column >
                                <Form.Field className="">
                                    <Form.Label className="is-size-7  mb-0 ml-2 " textWeight="semibold">On</Form.Label>
                                    <Form.Control>
                                        {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                        <DatePicker
                                            name="createdAfter"
                                            className=" datePicker input is-shadowless bottomBorderOnly  "
                                            placeholderText="DD/MM/YY"
                                            isClearable
                                            // selected={f}
                                            showTimeSelect={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            todayButton='Today'
                                            // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                            dateFormat="dd/MMM/yyyy,  h:mm aa"
                                            selected={event.starts}
                                            onChange={(value) => {
                                                onDateChange("on", value);
                                            }}
                                            onFocus={e => e.target.blur()}
                                        />
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                            <Columns.Column >
                                <Form.Field>
                                    <Form.Label className="is-size-7 mb-0 ml-2 " textWeight="semibold"  >Status</Form.Label>
                                    <Form.Control>
                                        <Form.Select
                                            size='small'
                                            value={event.status}
                                            className=" mt-2 ml-2 "
                                            onChange={(e) => {
                                                statusChanged(e.target.value)
                                            }}
                                        >
                                            <option value={EventCompletionStatus.pending}>Pending</option>
                                            <option value={EventCompletionStatus.complete}>Complete</option>
                                        </Form.Select>
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                        </Columns>

                        <Columns className="is-mobile mt-6">
                            <Columns.Column  >
                                <Form.Field>
                                    <Form.Label className="is-size-7  mb-0 ml-2 " textWeight="semibold"  > On {event.channels.length > 0 ? (event.channels.length > 1 ? `- ${event.channels.length} platforms` : `- ${event.channels.length} platform`) : ' - (Select platform)'} </Form.Label>
                                    <Box shadowless className=" px-0 pt-2 " >
                                        {
                                            allPlatformTags(event.channels)
                                        }
                                    </Box>
                                </Form.Field>
                            </Columns.Column>
                        </Columns>

                        <Form.Field >
                            <Form.Label className="is-size-7 mt-5  ml-2 " textWeight="semibold" >Event Title</Form.Label>
                            {/* <Form.Textarea
                                value={event.name ?? ""}
                                placeholder="Type task here..."
                                size={"normal"}
                                className=" is-size-7 has-background-white-bis "
                                onChange={(e) => {
                                    return setEventName(e.target.value);
                                }}
                            /> */}
                            <Form.Input
                                // color="success"
                                size="normal"
                                placeholder="Name this event"
                                textWeight="semibold"
                                value={event.name}
                                // color="danger"
                                className="is-centered has-background-white-bis "
                                onChange={(e) => { setEventName(e.target.value) }}
                                onBlur={(e) => { setEventNameBlur(e.target.value) }}
                            />
                        </Form.Field>
                        <br />
                        {/* <Form.Field onClick={() => { toggleProjAssComms() }} className="" > */}
                        <Form.Field onClick={() => { pickFile("pickAsset") }} className="" >
                            <Form.Label className="is-size-7 mt-6 mb-0 ml-2  " > Assets & Content
                                <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { pickFile("pickAsset") }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                            </Form.Label>

                            {/* <span className=" is-size-7  has-text-weight-semibold  mb-0  "> Assets & Content
                                    <Dropdown left renderAs="button" className="  has-text-left   ml-minus-14 mr-minus-10  is-small is-arrowless has-background-transparent  is-borderless " label={
                                        <IoAddCircleOutline className="is-size-5 mt-1 has-text-dark  " />
                                    } onChange={(val) => { pickFile(val) }} >
                                        <Dropdown.Item renderAs="a" value="pickAsset"> Add Asset</Dropdown.Item>
                                        <Dropdown.Item renderAs="a" value="pickUpload"> Upload </Dropdown.Item>
                                    </Dropdown>
                                </span> */}

                            {/* <Button size="small" className=" has-background-white-bis p-0 is-borderless is-hover-dark ml-2 "  > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button> */}

                            <Box shadowless className={" p-1 maxH300  overflow-scroll mt-0 " + ((assetsLength > 0) ? " has-background-white-bis " : null)} >
                                <Tag.Group className="mt-0" >
                                    {/* {
                                            filter.addedBy.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(su, UserPickerFor.taskAddedBy)} infoObj={su} nameWrap={true} />
                                            })
                                        } */}
                                    {assetsLength > 0 ? listAssets(event.assets) : null}
                                </Tag.Group>
                            </Box>
                        </Form.Field>

                        <Form.Field className=" mt-6 " >
                            <span className=" is-size-7  has-text-weight-semibold ml-2 "> Added Content
                                {/* <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark "> <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-4 ml-2 " /></Button> */}
                            </span>

                            <Form.Label className="is-size-7 mt-1  mb-0 ml-2 "  >
                            </Form.Label>
                            <Form.Control>
                                <Form.Textarea
                                    rows={9}
                                    size="small"
                                    fixedSize
                                    className=" has-background-white-bis "
                                    value={event.content}
                                    placeholder="Write text content here..."
                                    onChange={(e) => { contentChanged(e.target.value) }}
                                    onBlur={(e) => { contentChangedBlur(e.target.value) }}

                                />
                            </Form.Control>
                            {/* <Form.Help textSize={7} color="danger" className=" ml-2" >This username is available</Form.Help> */}
                            {/* <br /> */}
                        </Form.Field>

                        <Form.Field className=' mt-6 '>
                            <Form.Label className="is-size-7  mb-2 ml-2 " textWeight="semibold" onClick={addNotePressed} >Notes <span className=' has-text-grey-light mx-1'>{(event.notes.length > 0) ? `(${event.notes.length})` : null}</span>
                                <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark  "  > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                            </Form.Label>

                            <Box shadowless className={" px-0 pt-0 maxH300  overflow-scroll mt-0 " + (event.notes.length ? " has-background-white-bis " : null)} >
                                {
                                    event.notes.length ?
                                        event.notes.map((t) =>
                                            <Message key={t.id} size={"small"} color="light" className="  pb-0 mb-0" onClick={() => noteSelected(t)} >
                                                <Message.Body >
                                                    {t.note}
                                                    <p className='mt-2 has-text-grey-light m-0'> By {t.byUserInfo.name}  {t.createdAt ? `· ${moment.unix(t.createdAt).format('DD-MMM-YYYY h:mm a')}` : null}  </p>
                                                </Message.Body>
                                            </Message>
                                        )
                                        :
                                        null
                                }
                            </Box>
                        </Form.Field>

                        {/* <Form.Field className='mt-6'>
                            <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " textWeight="semibold"  >By
                                <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPicker(true) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                            </Form.Label>
                            <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                <Tag.Group className="mt-0">
                                    {
                                        selectedUsers.map((su) => {
                                            return <UserAvatar isRemovable={true} removePressed={userRemovePressed} infoObj={su} nameWrap={true} />
                                        })
                                    }
                                </Tag.Group>
                            </Box>
                        </Form.Field> */}

                        <Form.Field>
                            <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Assigned To
                                <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.eventAssign) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                            </Form.Label>
                            <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                <Tag.Group className="mt-0">
                                    {
                                        event.assignedToInfo.map((su) => {
                                            return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(UserPickerFor.eventAssign, su)} infoObj={su} nameWrap={true} />
                                        })
                                    }
                                </Tag.Group>
                            </Box>
                        </Form.Field>

                        <Form.Field>
                            <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Shared with
                                <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.eventSharedWith) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                            </Form.Label>
                            <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                <Tag.Group className="mt-0">
                                    {
                                        event.sharedWithInfo.map((su) => {
                                            return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(UserPickerFor.eventSharedWith, su)} infoObj={su} nameWrap={true} />
                                        })
                                    }
                                </Tag.Group>
                            </Box>
                        </Form.Field>
                        <Form.Field className=' mt-6 '>
                            <Form.Label className="is-size-7  mb-2 ml-2 " textWeight="semibold" onClick={addNotePressed} >Automatic Email Reminders </Form.Label>

                            <Box shadowless className=" px-0 pt-0 maxH300  overflow-scroll mt-0 is-size-7  has-background-white-bis has-text-grey " >
                                {/* <p className=' ml-2'>Automatic Reminders :</p> */}
                                <p>• To all participants 2 hours and 15 minus prior the event time.</p>
                                <p>• Another in case the events are not marked complete by any member after 10 min of event time.</p>
                            </Box>
                        </Form.Field>


                        {/* <Columns className="is-mobile mt-4">
                            <Columns.Column >
                                <Form.Field>
                                    <Form.Label className="is-size-7 mb-0 ml-2 " textWeight="semibold"> Reminder</Form.Label>
                                    <Form.Control>
                                        <Form.Select
                                            size='small'
                                            value={event.status}
                                            className=" mt-2 ml-2 "
                                            onChange={(e) => {
                                                statusChanged(e.target.value)
                                            }}
                                        >
                                            <option value={EventCompletionStatus.pending}>Pending</option>
                                            <option value={EventCompletionStatus.complete}>Complete</option>
                                        </Form.Select>
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                            <Columns.Column >
                                <Form.Field>
                                    <Form.Label className="is-size-7 mb-0 ml-2 " textWeight="semibold"  >Status</Form.Label>
                                    <Form.Control>
                                        <Form.Select
                                            size='small'
                                            value={event.status}
                                            className=" mt-2 ml-2 "
                                            onChange={(e) => {
                                                statusChanged(e.target.value)
                                            }}
                                        >
                                            <option value={EventCompletionStatus.pending}>Pending</option>
                                            <option value={EventCompletionStatus.complete}>Complete</option>
                                        </Form.Select>
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                        </Columns> */}

                        <br />
                    </Box>


                </Modal.Card.Body>
                <Modal.Card.Footer align="right" className="py-2 has-background-white is-borderless " >
                    {/* <Box shadowless> */}
                    <Button disabled={isSaving} loading={isSaving} color={changesMade.length ? "black" : "light"} className=" " fullwidth onClick={saveEvent} > Save </Button>
                    {/* </Box> */}
                </Modal.Card.Footer>
            </Modal.Card>
        </Modal >

    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light height100vc animate-bottom p-0" >



                <Modal.Card className=" height100vc is-width-70Perc m-0 p-0" radiusless>
                    <Modal.Card.Header showClose className=" pt-3 pb-2 has-background-white is-borderless ">
                        <Modal.Card.Title className="ml-minus-14   " >
                            <Dropdown left renderAs="button" className="  has-text-left  mx-0 mb-0 p-0 mt-minus-10  is-small is-arrowless has-background-transparent  is-borderless " label={
                                <HiOutlineDotsHorizontal className="is-size-5 mt-2 has-text-grey" />
                            }>
                                {/* <Dropdown.Item  renderAs="a" value="cancelOrder" className=" is-disabled has-text-grey-light ">Version 2 </Dropdown.Item> */}
                                <Dropdown.Item renderAs="a" value="cancelOrder"> Mark Done</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item renderAs="a" value="cancelOrder">Delete Event </Dropdown.Item>
                            </Dropdown>
                        </Modal.Card.Title>

                        {/* <Button.Group align="right" className="is-pulled-right" >
                            <Button className="delete is-black mt-minus-10 " onClick={props.close} />
                        </Button.Group> */}

                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0 m-0">
                        {showAssetsPicker ? <ModalExplorer selectables={{ entities: [SelectableEntity.assets], multiSelection: true }} toExplore={ProjectFolderLevel.project} selections={selectedAssets} selectionsChanged={selectionsChanged} close={clearSelections} /> : null}
                        {showUserPickerFor ? <UserPicker for={showUserPickerFor} selectedUsers={selectedUsersAsPerShowFor()} usersAreSelected={usersAreSelected} close={clearSelections} /> : null}

                        <Columns className="is-fullwidth p-0 m-0" >
                            <Columns.Column className="" size={7} >
                                <Box shadowless className=" has-background-white-ter mx-2 is-bordered ">
                                    {/* <span className="is-size-7 has-text-grey-light ml-2 has-text-weight-medium"> Contents </span> */}

                                    {/* <Button.Group
                                        align='right'
                                        size='small'
                                        className="my-0"
                                    >
                                        <Button size="small" textColor="grey-light" className="  is-borderless is-light mr-0" > Add Upload &nbsp; <RiFileUploadLine className="is-size-6" /> </Button>
                                        <Button size="small" textColor="grey" className="  is-borderless is-light ml-0" > Add Project Asset &nbsp; <RiImageAddLine className="is-size-6" /> </Button>

                                        <Button size="small" className="  is-borderless has-background-transparent " > <RiMoreFill className="is-size-5" /> </Button>

                                    </Button.Group> */}
                                    {/* <span className=" is-size-7 has-text-grey has-text-weight-normal ml-3 "> POST DETAILS </span> */}

                                    {/* <Dropdown right align="right" renderAs="button" className=" is-pulled-right  has-text-left  mx-0 mb-0 p-0 mt-minus-10 is-small is-arrowless has-background-transparent  is-borderless " label={
                                        <RiImageAddLine className=" mt-3 is-size-5 " />
                                    }>
                                        <Dropdown.Item renderAs="a" value="cancelOrder"> Add Asset</Dropdown.Item>
                                        <Dropdown.Item renderAs="a" value="cancelOrder"> Upload </Dropdown.Item>
                                    </Dropdown> */}

                                    {/* <hr className="hardHr mt-2 mb-5 opacity50" /> */}
                                    <span className=" is-size-7 has-text-grey-light has-text-weight-medium ml-3 mb-0 "> MEDIA
                                        {/* <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark "> <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-4 " />  </Button> */}
                                        <Dropdown left renderAs="button" className="  has-text-left  mt-minus-6 ml-minus-14 mr-minus-10  is-small is-arrowless has-background-transparent  is-borderless " label={
                                            <IoAddCircleOutline className="is-size-5 has-text-dark  " />
                                        } onChange={(val) => { pickFile(val) }} >
                                            <Dropdown.Item renderAs="a" value="pickAsset"> Add Asset</Dropdown.Item>
                                            {/* <Dropdown.Item renderAs="a" value="pickUpload"> Upload </Dropdown.Item> */}
                                        </Dropdown>
                                    </span>

                                    <Box shadowless className=" assetContainerInEvent mt-minus-8 px-0 mr-0 has-background-transparent ">
                                        {
                                            event.assets.map((asst) => {
                                                return <Box shadowless radiusless className=" assetBox minH240 maxH240 mr-0 px-2 py-0 has-background-transparent " >
                                                    <p >
                                                        {/* <span className=" is-pulled-left has-text-grey-light is-size-8 ml-2 "> Is Upload </span> */}
                                                        <Dropdown renderAs="button" className=" is-pulled-left has-text-left p-0 mr-0 mb-0 mt-minus-10 mr-minus-10  is-small is-arrowless has-background-transparent  is-borderless " label={
                                                            <HiOutlineDotsHorizontal className="is-size-6 m-0 p-0 has-text-grey-light " />
                                                        }>
                                                            <Dropdown.Item renderAs="a" value="cancelOrder">Remove</Dropdown.Item>
                                                        </Dropdown>
                                                    </p>
                                                    <p className="" >
                                                        <img className="border-radius-8" alt="upload" style={{ maxHeight: "200px" }} src={asst.url} />
                                                    </p>

                                                </Box>
                                            })
                                        }
                                    </Box>
                                    {/* <hr className="hardHr mt-2 mb-5 opacity50" /> */}

                                    <Box shadowless className=" has-background-transparent pl-2  " >
                                        <span className=" is-size-7 has-text-grey-light has-text-weight-medium mt-6 ml-1 mb-0 "> CONTENT <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark "> <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-4 ml-1 " />  </Button>   </span>

                                        <Form.Field className=" mt-3 " >
                                            <Form.Control>
                                                <Form.Textarea
                                                    rows={9}
                                                    size="normal"
                                                    fixedSize
                                                    // className="mx-2 "
                                                    value={event.content}
                                                    placeholder="Write text content here..."
                                                    onChange={(e) => { contentChanged(e.target.value) }}
                                                />
                                            </Form.Control>
                                            {/* <Form.Help textSize={7} color="danger" className=" ml-2" >This username is available</Form.Help> */}
                                            {/* <br /> */}
                                        </Form.Field>
                                    </Box>

                                </Box>
                                {/* <Form.Help textSize={7} color="danger" className=" ml-2" >This username is available</Form.Help> */}
                                <br />

                            </Columns.Column>
                            <Columns.Column>

                                {/* <Columns className=" has-background-white z-30 is-mobile p-2 pt-3 stick-to-top  ">
                                    <Columns.Column size={2} className="p-0 has-text-right " narrow>
                                    </Columns.Column>
                                    <Columns.Column className="p-1  " >
                                        <p className="has-text-weight-normal   has-text-grey-light has-text-centered is-size-7 "> POST EVENT </p>
                                    </Columns.Column>
                                    <Columns.Column size={3} className="p-0 " narrow>
                                        <Button.Group align="right">
                                            <Dropdown right renderAs="button" className=" is-aligned-right has-text-left  mx-0 mb-0 p-0 mt-minus-10  mr-1 is-small is-arrowless has-background-transparent  is-borderless " label={
                                                <HiOutlineDotsHorizontal className="is-size-5 mt-2 has-text-grey" />
                                            }>
                                                <Dropdown.Item renderAs="a" value="cancelOrder"> Mark Done</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item renderAs="a" value="cancelOrder">Delete Event </Dropdown.Item>
                                            </Dropdown>
                                            <Button className="delete is-black mt-minus-10 " onClick={props.close} />
                                        </Button.Group>
                                    </Columns.Column>
                                </Columns> */}

                                {/* <Button className="delete is-black ml-4 " onClick={props.close} /> */}

                                <Box shadowless className="  " >

                                    <Form.Field>
                                        <Form.Label className="is-size-7 mt-3 mb-0 ml-2 " textWeight="semibold" textColor="grey-light" >On</Form.Label>
                                        <Form.Control>

                                            {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                            <DatePicker
                                                name="name"
                                                className=" datePicker input is-shadowless bottomBorderOnly "
                                                placeholderText="DD/MM/YY"
                                                isClearable
                                                // selected={f}
                                                showTimeSelect
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                todayButton='Today'
                                                dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                selected={event.starts}
                                                onChange={(value) => {
                                                    onDateChange("on", value);
                                                }}
                                                // onBlur={() => {
                                                //     onBlur(name, true);
                                                // }}
                                                onFocus={e => e.target.blur()}

                                            />


                                            {/* <Form.Input
                                                placeholder="22/Jan/2021 "
                                                className=" is-shadowless bottomBorderOnly  mt-0 "
                                                color="light"
                                            /> */}

                                        </Form.Control>
                                        <br />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.Label className="is-size-7 mt-4 mb-0 ml-2 " textWeight="semibold" textColor="grey-light" >Publish on {event.channels.length > 0 ? (event.channels.length > 1 ? `- ${event.channels.length} platform` : `- ${event.channels.length} platform`) : '(Select platform)'} </Form.Label>
                                        <Box shadowless className=" px-0 pt-2 " >
                                            {
                                                allPlatformTags(event.channels)
                                            }
                                        </Box>
                                    </Form.Field>



                                    {/* <Form.Field>
                                        <Form.Label className="is-size-7 mt-4 mb-0 ml-2 " textWeight="semibold" textColor="grey-light" >By
                                            <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPicker(true) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                                        </Form.Label>
                                        <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                            <Tag.Group className="mt-0">
                                                {
                                                    selectedUsers.map((su) => {
                                                        return <UserAvatar isRemovable={true} removePressed={userRemovePressed} infoObj={su} nameWrap={true} />
                                                    })
                                                }
                                            </Tag.Group>
                                        </Box>
                                        <br />
                                    </Form.Field> */}

                                    <Form.Field>
                                        <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2 " textWeight="semibold" textColor="grey-light" >Status</Form.Label>
                                        <Form.Control>
                                            <Form.Select
                                                size='small'
                                                value={null}
                                                className=" mt-2 ml-2 "
                                                onChange={(e) => {
                                                    // const { value } = e.target;
                                                    // if (args.multiple) {
                                                    //     setSelectedOption(
                                                    //         Array.from(e.target.selectedOptions, (option) => {
                                                    //             return option.value;
                                                    //         }),
                                                    //     );
                                                    //     return;
                                                    // }
                                                    // setSelectedOption(value);
                                                }}
                                            >
                                                <option value={EventCompletionStatus.pending}>Pending</option>
                                                <option value={EventCompletionStatus.complete}>Complete</option>
                                            </Form.Select>
                                            {/* <Icon align="left">
                                                <MdSchedule className="is-size-5 mt-4 ml-4" />
                                            </Icon> */}
                                        </Form.Control>
                                        <br />
                                    </Form.Field>

                                </Box>


                            </Columns.Column>

                        </Columns>

                    </Modal.Card.Body>
                    <Modal.Card.Footer align="right" className="py-2 has-background-white is-borderless " >
                        {/* <Box shadowless> */}
                        <Button disabled={isSaving} loading={isSaving} color={changesMade.length ? "black" : "light"} className=" " fullwidth onClick={saveEvent} > Save </Button>
                        {/* </Box> */}
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }


    return newModal()



}

export default EventModal