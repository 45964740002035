import React, { useEffect, useState } from 'react'
import { Form, Tabs, Box, Button, Panel, Columns, Table, Tag, Heading, Image } from 'react-bulma-components';
import { Event } from '../../Models/Event'
import { IoMdClose } from 'react-icons/io';

import { EventType, EventStatus, EventSortOptions, EventPriority, EventCompletionStatus, SelectableEntity } from '../../Models/EnumsAndPointers';
import Selector from '../Common/Selector';

import UserAvatar from '../Calendar/UserAvatar';
// import EventModal from './EventModal';

import { BiTimeFive, BiLoaderCircle } from 'react-icons/bi';
import { RiFileUserFill } from 'react-icons/ri';
import { FaCheckCircle } from 'react-icons/fa';
import { GrFormAdd } from 'react-icons/gr';


import { BsCircleFill, BsCheckCircleFill } from 'react-icons/bs'
import { IoTimeSharp, IoAddSharp, IoCheckmarkDoneOutline } from 'react-icons/io5';
import { BsFileEarmarkPlus, BsImageAlt } from 'react-icons/bs';

import { CgImage, CgRadioCheck, CgCheckO } from 'react-icons/cg';
import moment from 'moment'
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';

import { MdDone } from 'react-icons/md';
import { GiSandsOfTime } from 'react-icons/gi';
import LoadingAux from '../../Containers/LoadingAux';
import EventModal from '../Calendar/EventModal';
import { Asset } from '../../Models/Asset';
import { isPrivate, privateTag } from '../../utilities/Utilities';


const AssetEvents = (props) => {

    const [fetching, setFetching] = useState(false)
    const [events, setEvents] = useState([])

    const [addEvent, setAddEvent] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)



    useEffect(() => {
        let isMounted = true;
        setFetching(true)





        Event.getAll(null, null, props.assetId, null, null, null, null, null, (succ, tsks, errMsg) => {
            if (isMounted) {
                setFetching(false)
            }
            if (succ) {
                console.log("GOT EVENTS FOR THIS ASSET")
                if (isMounted) {
                    setEvents(tsks)
                } else {
                    console.log("NOT MOUNTED ANYMORE")
                }
                return () => (isMounted = false)
            } else {
                console.log("ERROR WHILE GETTING EVENTS FOR THIS ASSET")
                console.log(errMsg)
            }

        })

        return () => (isMounted = false)

    }, [props.assetId])

    const eventSelected = (tsk) => {
        setSelectedEvent(tsk)
    }

    const eventIsAdded = (tk) => {
        let ev = [...events]
        ev.unshift(tk)
        setEvents(ev)
    }

    const eventIsUpdated = (tk) => {
        let ev = [...events]
        let index = ev.findIndex((t) => {
            return t.id === tk.id
        })
        if (index >= 0) {
            ev[index] = tk
            setEvents(ev)
        }
    }


    const eventStatusChanged = (tk, status) => {
        let ev = [...events]
        let index = ev.findIndex((t) => {
            return t.id === tk.id
        })
        if (index >= 0) {
            let thisEvent = ev[index]
            thisEvent.isUpdating = true
            // update event in backend
            ev[index] = thisEvent
            setEvents((oldVal) => {
                return ev
            })
            // updateEventInDB(thisEvent, status)
        }
    }

    const showAddEventNow = () => {


        // create event 
        let ev = new Event()
        ev.byUser = isCurrentUser().id
        ev.byUserInfo = currentUserInfo()
        ev.createdAt = new Date()

        // add this asset thumbnail to it
        const thisAsset = Asset.copyFrom(props.asset)
        // console.log("Incoming Asset is")
        // console.log(props.asset)
        const thisAssetThumbnail = thisAsset.thumbnailObject(props.asset.project.id)
        ev.assets = [thisAssetThumbnail]
        ev.assetsIds = [thisAssetThumbnail.id]

        // console.log("SETTING NEW EVENT AS")
        // console.log(ev)

        setAddEvent(ev)
    }

    const closeEvent = () => {
        setSelectedEvent(null)
        setAddEvent(null)
    }



    const event_row = (thisEvent) => {

        let tk = Event.copyFrom(thisEvent)
        // let imageUrl = null

        // let assignedToName = ""

        // if (tk.thumbImageUrls.length > 0) {
        //     imageUrl = tk.thumbImageUrls[0]
        // }


        // if (tk.assignedToInfo.length) {
        //     assignedToName = tk.assignedToInfo[0].name
        //     if (tk.assignedToInfo.length > 1) {
        //         assignedToName += ` + ${tk.assignedToInfo.length - 1} others`
        //     }
        // }


        // return <Box shadowless radiusless className={" is-bordered-bottom-1  p-4 is-hover-dark mb-0  " + (tk.isComplete ? "opacity35" : "")}>
        return <Box shadowless radiusless className={"  p-4 px-0 mx-0 is-hover-dark mb-0 has-background-transparent backblurWithSiteBg is-size-7 " + (tk.isComplete ? "opacity35" : "")}>

            <Columns className="is-mobile mx-0 px-0" onClick={() => { eventSelected(thisEvent) }}>


                <Columns.Column className='px-0 mx-0' >

                    <p className='has-text-grey-light'>   {isPrivate(SelectableEntity.events, tk) ? privateTag()  : null} {moment(tk.starts).format('DD-MMM-YYYY')}</p>
                    <span className="is-size-7 has-text-weight-medium text-lines-3 "> {tk.name}  </span>
                    {/* <div className="has-text-grey-light is-size-7 ">Assigned: <span className="has-text-weight-medium ">Candida Viegas</span>  </div> */}

                    <div className="has-text-grey-light is-size-7 has-text-weight-medium ">
                        {/* <span className=" mr-4 "><FaRegUser className="pt-2 is-size-5p4" /> {tk.byUserInfo.name}</span> */}


                        {/* {
                            assignedToName !== "" ?
                                <span className=" mr-4 "><RiFileUserFill className="pt-2 is-size-5p4 mb-minus-009rem" /> {assignedToName}</span>
                                : null
                        }
                        <span className=" mr-4"><IoTimeSharp className="pt-15 is-size-5p2 mb-minus-009rem" />{moment(tk.createdAt).format('DD·MMM·YYYY')}</span> */}

                        {
                            tk.status === EventCompletionStatus.complete ?

                                <span > <span className=" has-text-black "><IoCheckmarkDoneOutline /></span>  Completed By {tk.completedByInfo.name} </span>
                                : null
                        }


                    </div>


                    {/* <span className="is-size-6p4 has-text-grey " >  <Avatar render small imageUrl="https://randomuser.me/api/portraits/women/93.jpg" />  </span> */}
                    {/* <div className="" >
                                <span className="is-size-7  has-text-grey " > <UserAvatar infoObj={{ id: "25872573", name: "Candida Viegas", imageUrl: "https://randomuser.me/api/portraits/women/93.jpg" }} small={true} clearBg={true} /> </span>
                                <span className="is-size-7  has-text-grey mt-minus-10">Deadline</span>
                            </div> */}
                </Columns.Column>
                <Columns.Column className="py-0 px-0 mx-0" narrow>
                    <div className=" m-0 px-0 pb-0 mt-5 pt-2  has-text-grey">
                        {/* <div>
                                <Button size="small" className=" p-1 mt-1 is-hover-dark is-borderless has-background-transparent ">
                                    <RiMoreFill className="is-size-5  " />
                                </Button>
                            </div>       */}
                        {
                            tk.status === EventCompletionStatus.complete ?
                                <div>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Complete</p>
                                </div>
                                :
                                null
                        }


                        {
                            tk.status === EventCompletionStatus.pending ?
                                <div>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Pending</p>
                                </div>

                                :
                                null
                        }

                    </div>
                </Columns.Column>
            </Columns>
        </Box>
    }


    let allAssetEvents = () => {


        let pendingEvents = events.filter((tk) => tk.status === EventCompletionStatus.pending) ?? []
        let completeEvents = events.filter((tk) => tk.status === EventCompletionStatus.complete) ?? []

        // console.log("PENDING TASKS ARE")
        // console.log(pendingEvents)




        return <div className=' is-clearfix is-fullwidth '>
            {addEvent ? <EventModal isNew={true} event={addEvent} close={closeEvent} eventIsAdded={eventIsAdded} /> : null}
            {selectedEvent ? <EventModal event={selectedEvent} close={closeEvent} eventIsUpdated={eventIsUpdated} /> : null}
            <Button size="small" rounded className="p-2 m-2 mr-3 mt-3 is-borderless has-text-grey" onClick={showAddEventNow} >
                <GrFormAdd className=" has-text-warning m-0 is-size-5" />
            </Button>
            <LoadingAux className=" is-fullHeight is-clearfix  is-fullwidth m-0 p-0 mt-2 ml-2 " isLoading={fetching} isMain={true} isCentered={true} loaderHeight={600}>
                <Box shadowless className=" h-90vh is-fullwidth overflow-scroll has-background-transparent p-0 pb-6 pt-0 ">
                    <div className='overflow-scroll  is-size-7'>

                        {events.length === 0 ?
                            <Box shadowless radiusless className="is-fullwidth has-text-grey-light has-background-transparent is-size-7 has-text-left p-4 m-2">
                                No Events
                            </Box>
                            :

                            <div className=" is-fullwidth overflow-scroll ">
                                {pendingEvents.length ? <div>
                                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey py-2 mt-2 mb-0  mt-3 is-uppercase" >Pending</Box>
                                    <Table className="is-fullwidth overflow-scroll has-background-transparent no-border" hoverable={false} striped={false} bordered={false} fullwidth>
                                        <tbody>
                                            {
                                                pendingEvents.map((tk) => {
                                                    return <tr key={tk.id}>
                                                        <td className="is-borderless" align="left">
                                                            {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                            {event_row(tk)}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div> : null}


                                {completeEvents.length ? <div>
                                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey py-2 my-0  mt-3 is-uppercase" >Complete</Box>
                                    <Table className=" is-fullwidth overflow-scroll has-background-transparent" hoverable={false} striped={false} bordered={false} fullwidth>
                                        <tbody>
                                            {
                                                completeEvents.map((tk) => {
                                                    return <tr key={tk.id}>
                                                        <td className="is-borderless" align="left">
                                                            {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                            {event_row(tk)}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div> : null}
                            </div>}
                    </div>
                </Box>
            </LoadingAux>
        </div >

    }


    return allAssetEvents()
}

export default AssetEvents