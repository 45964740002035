import React from 'react'
import { Box, Button, Columns, Heading, Message, Media, Image, Content } from 'react-bulma-components'
import { Form as BulmaForm } from 'react-bulma-components'
import ColumnAux from '../../Containers/ColumnAux'
import { Formik, Form, Field } from 'formik'
import { signInNow } from '../../utilities/Login'
import { validateEmail, validatePassword } from '../../utilities/Utilities'
import { SignType } from '../../utilities/Enums'

const PricingPlans = (props) => {


    const plans = {
        standard: {
            name: "Standard Plan",
            description: "Visualise Posts and collbaratively review them with all stakeholders for feedback and approval.",
            priceId: "price_1Ipj3aHjZIbjlNruwWxafx1A",
            productId: "prod_JSeMhSRsweMtqV",
            price:"24",
            priceId_inr: "price_1IptQCHjZIbjlNru5briFj3x",
            productId_inr: "prod_JSp4Y4PobppUQX",
            price_inr:"1800"
        },
        premium: {
            name: "Premium Plan",
            description: "Visual Tests, Collabrative Reviews & Approval. Plus public pages and polls.",
            priceId: "price_1IqcMvHjZIbjlNru1nWYZFrY",
            productId: "prod_JTZV9kSQ2hyRDF",
            price:"39",
            priceId_inr: "price_1IptRJHjZIbjlNruFPqUvE1e",
            productId_inr: "prod_JSp5XolShDbOmL",
            price_inr:"2900"
        }

    }


    const allPlans = [plans.standard, plans.premium]


    return (

        //         <Box shadowless className="mt-4">
        //         <Media>
        //             <Media.Item renderAs="figure" align="left">
        //                 <Image
        //                     size={64}
        //                     alt="64x64"
        //                     src="http://bulma.io/images/placeholders/128x128.png"
        //                 />
        //             </Media.Item>
        //             <Media.Item>
        //                 <Content>
        //                     <p>
        //                         <strong>John Smith</strong> <small>@johnsmith</small>{' '}
        //                         <small>31m</small>
        //                         <br />
        //     If the children of the Modal is a card, the close button
        //     will be on the Card Head instead than the top-right corner
        //     You can also pass showClose = false to Card.Head to hide the
        //     close button
        //   </p>
        //                 </Content>
        //             </Media.Item>
        //         </Media>
        //     </Box>


        <ColumnAux isMiddle={true} size={11}>
            {
                allPlans.map(thisPlan => {
                    return (
                        <Box key={thisPlan.name} className="is-clearfix mt-5">
                            <Media>
                                <Media.Item renderAs="figure" align="left">
                                    <Image
                                        size={64}
                                        alt="64x64"
                                        src="http://bulma.io/images/placeholders/128x128.png"
                                    />
                                </Media.Item>
                                <Media.Item>
                                    <Content>
                                        <Heading size={4} > {thisPlan.name} </Heading>
                                        <Heading size={6} subtitle={true} weight="light">{thisPlan.description}</Heading>
                                        <Button loading={props.processingPlan ? props.processingPlan.priceId === thisPlan.priceId : false} disabled={props.processingPlan} color="black" className="is-pulled-right" onClick={() => { props.plansChosen(thisPlan) }}> Choose Plan </Button>
                                    </Content>
                                </Media.Item>
                            </Media>
                        </Box>
                    )
                })
            }

        </ColumnAux>


    )


}

export default PricingPlans