import React, { useRef, useState } from 'react'
import { Form, Card, Heading, Media, Content, Tabs, Box, Image, Tag, Button, Dropdown, Modal, Panel, Columns, Hero, Container, Message, Level, Section, Navbar } from 'react-bulma-components';
import { previewBoxType, SocialMedia_PostType, AssetCaptionType, SocialMedia_Platform } from '../../Models/EnumsAndPointers';

import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
// import sample_Li_Company_Bg from './sampleAssets/li-companyPage-bg.jpeg'
import Default_BG from './sampleAssets/Default-BG.png'
import Default_DP from './sampleAssets/Default-DP.png'
import Default_Li_Blank_User from './sampleAssets/Default-Li-Blank-User.svg'
import { randomUsers, rowOfColumns_leftImage } from './VisualiseModal';

import billgates_dp from './sampleAssets/billgates_dp.jpeg'
import satyanadella_dp from './sampleAssets/satyanadella_dp.jpeg'
import reedhastings_dp from './sampleAssets/reedhastings_dp.jpeg'
import calendarIcon from './sampleAssets/calendarIcon.svg'
import videoIcon from './sampleAssets/videoIcon.svg'
import avatarsImage from './sampleAssets/avatars.png'
import locationIcon from './sampleAssets/location.svg'
import calendarTWIcon from './sampleAssets/calendar.svg'
import linkTWIcon from './sampleAssets/link.svg'
import twitterAvatars from './sampleAssets/twitter-avatars.png'

import twitterLove from './sampleAssets/twitter-love.png'
import twitterShare from './sampleAssets/twitter-share.png'
import twitterComment from './sampleAssets/twitter-comment.png'
import twitterRetweet from './sampleAssets/twitter-retweet.png'
import twitterDotMenu from './sampleAssets/twitter-dotMenu.png'

import twitter_home_icon from './sampleAssets/twitter_home_icon.png'
import twitter_bookmarks_icon from './sampleAssets/twitter_bookmarks_icon.png'
import twitter_hashtag_icon from './sampleAssets/twitter_hashtag_icon.png'
import twitter_lists_icon from './sampleAssets/twitter_lists_icon.png'
import twitter_messages_icon from './sampleAssets/twitter_messages_icon.png'
import twitter_more_icon from './sampleAssets/twitter_more_icon.png'
import twitter_notifications_icon from './sampleAssets/twitter_notifications_icon.png'
import twitter_profile_icon from './sampleAssets/twitter_profile_icon.png'
//Insta Menu Icons
import twitter_logo_icon_white from './sampleAssets/twitter_logo_icon_white.png'
import twitter_logo_icon from './sampleAssets/twitter_logo_icon.png'
import twitter_magic_icon from './sampleAssets/twitter_magic_icon.png'




export const twitter_profileCard = (dpImage, bgImage, userName, handleName, userBio, locationName, website, joinedDate, followingCount, followerCount, followedBy) => {
    return <Card className=" overflow-hidden has-background-transparent is-centered is-h-center m-0 p-0 " radiusless shadowless >
        {/* <Card className="twitterBox-592w overflow-hidden is-centered is-h-center " radiusless > */}
        <Card.Image
            size='16/9'
            src={bgImage ?? Default_BG}
            // className="figure twitter-profilePage-bg overflow-hidden is-radiusless"
            className="figure twitter-profilePage-bg overflow-hidden is-radiusless m-0 "

        />
        <Card.Content className="has-background-transparent radiusless">
            <Columns className="is-mobile">
                <Columns.Column>
                </Columns.Column>
                <Columns.Column size={4} className="p-0">
                    <Level className="is-gapless is-mobile">
                        <Level.Item>
                            <Button className="p-2 ml-5 mr-0" outlined rounded color="info" textWeight="semibold">•••</Button>
                        </Level.Item>
                        <Level.Item>
                            <Button className="ml-0" outlined rounded color="info" textWeight="semibold">Follow</Button>
                        </Level.Item>
                    </Level>
                </Columns.Column>
            </Columns>
            <Media className="mb-1">
                <Media.Item className=" z-35  radiusless" align="left" >
                    <Image
                        src={dpImage ?? Default_DP}
                        backgroundColor="black"
                        className="twitter-profilePage-dp-size is-clipped"
                        rounded
                    />
                </Media.Item>
            </Media>



            <Heading className="mt-0 mb-0 li-companyPage-title " textWeight="bold" size={5}>{userName ?? "User Name"}</Heading>
            <p className="has-text-grey twitter-profile-handleFont "> @{handleName ?? "TestPostApp"} </p>
            <p className="mt-3 has-text-black twitter-fontSize-15" >{userBio ?? "Bio of user"}</p>

            <p>
                <Columns className="mb-0 is-mobile">
                    <Columns.Column>
                        <Columns className="mt-4 mb-0 is-gapless is-mobile" >
                            <Columns.Column narrow>
                                <Media className="">
                                    <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                                        <img
                                            src={locationIcon}
                                            alt="icon"
                                            className="twitter-iconSize figure has-text-grey-light"
                                        />
                                    </Media.Item>
                                    <span className="ml-1 twitter-fontSize-15">{locationName ?? "New York"}</span>
                                </Media>
                            </Columns.Column>
                            <Columns.Column narrow>
                                <Media className="ml-4">
                                    <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                                        <img
                                            src={linkTWIcon}
                                            alt="icon"
                                            className="twitter-iconSize figure has-text-grey-light"
                                        />
                                    </Media.Item>
                                    <span className="ml-1 twitter-fontSize-15 has-text-info">{website ?? "testpost.app"}</span>
                                </Media>
                            </Columns.Column>
                            <Columns.Column narrow>
                                <Media className="ml-4">
                                    <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                                        <img
                                            src={calendarTWIcon}
                                            alt="icon"
                                            className="twitter-iconSize figure has-text-grey-light"
                                        />
                                    </Media.Item>
                                    <span className="ml-1 twitter-fontSize-15 ">Joined {joinedDate ?? "April 2011"}</span>
                                </Media>
                            </Columns.Column>
                        </Columns>
                    </Columns.Column>
                    <Columns.Column size={2}>
                    </Columns.Column>
                </Columns>
                <Columns className="mt-0 is-gapless is-mobile" >
                    <Columns.Column narrow>
                        <span className="has-text-black has-text-weight-bold">{followingCount ?? "169"}</span>
                        <span className="ml-1 twitter-fontSize-15 has-text-grey">Following</span>
                    </Columns.Column>
                    <Columns.Column narrow>
                        <span className="ml-4 has-text-black has-text-weight-bold">{followerCount ?? "169"}</span>
                        <span className="ml-1 twitter-fontSize-15 has-text-grey">Followers</span>
                    </Columns.Column>
                </Columns>
            </p>
            <p className="mt-2 has-text-grey is-size-7" >
                <Media className="">
                    <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                        <img
                            src={twitterAvatars}
                            alt="icon"
                            className="twitter-avatarSize figure has-text-grey-light"
                        />
                    </Media.Item>
                    <span className="mt-1 ml-4 has-text-weight-medium"> Followed by {followedBy ?? "Don, Black Box, and 122 others you follow"} </span>
                </Media>
            </p>
        </Card.Content>
    </Card>
}

export const twitter_profileCard_mobile = (dpImage, bgImage, userName, handleName, userBio, locationName, website, joinedDate, followingCount, followerCount, followedBy) => {
    return <Card className=" overflow-hidden has-background-transparent is-centered is-h-center m-0 p-0 " radiusless shadowless >
        {/* <Card className="twitterBox-592w overflow-hidden is-centered is-h-center " radiusless > */}
        <Card.Image
            size='16/9'
            src={bgImage ?? Default_BG}
            // className="figure twitter-profilePage-bg overflow-hidden is-radiusless"
            className="figure twitter-profilePage-bg-mobile overflow-hidden is-radiusless m-0 "

        />
        <Card.Content className="has-background-transparent radiusless px-3">
            <Columns className="is-mobile">
                <Columns.Column className="p-0">
                    <Level className="is-gapless is-mobile is-pulled-right mr-2">
                        <Level.Item>
                            <Button size="small" className="p-2 ml-5 mr-0" outlined rounded color="info" textWeight="semibold">•••</Button>
                        </Level.Item>
                        <Level.Item>
                            <Button size="small" className="ml-0" outlined rounded color="info" textWeight="semibold">Follow</Button>
                        </Level.Item>
                    </Level>
                </Columns.Column>
            </Columns>
            <Media className="mb-1">
                <Media.Item className=" z-35  radiusless" align="left" >
                    <Image
                        src={dpImage ?? Default_DP}
                        backgroundColor="black"
                        className="twitter-profilePage-dp-size-mobile is-clipped"
                        rounded
                    />
                </Media.Item>
            </Media>



            <Heading className="mt-0 mb-0 li-companyPage-title-mobile " textWeight="bold" size={5}>{userName ?? "User Name"}</Heading>
            <p className="has-text-grey twitter-profile-handleFont-mobile "> @{handleName ?? "TestPostApp"} </p>
            <p className="mt-3 has-text-black twitter-fontSize-15-mobile" >{userBio ?? "Bio of user"}</p>

            <p>


                <Columns gap={0} className='mt-3 mb-2 mx-0 px-0  ' mobile={true}  >
                    <Columns.Column className="m-0 p-0" narrow >
                        <Media className="">
                            <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                                <img
                                    src={locationIcon}
                                    alt="icon"
                                    className="twitter-iconSize-mobile figure has-text-grey-light"
                                />
                            </Media.Item>
                            <span className="ml-1 mt-1 is-size-7">{locationName ?? "New York"}</span>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className="m-0 p-0 ml-2" narrow >
                        <Media className="">
                            <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                                <img
                                    src={linkTWIcon}
                                    alt="icon"
                                    className="twitter-iconSize-mobile figure has-text-grey-light"
                                />
                            </Media.Item>
                            <span className="ml-1 mt-1 is-size-7 has-text-info">{website ?? "testpost.app"}</span>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className="m-0 p-0 ml-2" narrow >
                        <Media className="">
                            <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                                <img
                                    src={calendarTWIcon}
                                    alt="icon"
                                    className="twitter-iconSize-mobile ml-1 figure has-text-grey-light"
                                />
                            </Media.Item>
                            <span className="ml-1 mt-1 is-size-7 ">Joined {joinedDate ?? "April 2011"}</span>
                        </Media>
                    </Columns.Column>
                </Columns>


                <Columns className="mt-0 is-gapless is-mobile" >
                    <Columns.Column narrow>
                        <span className="has-text-black twitter-fontSize-15-mobile has-text-weight-bold">{followingCount ?? "169"}</span>
                        <span className="ml-1 twitter-fontSize-15-mobile has-text-grey">Following</span>
                    </Columns.Column>
                    <Columns.Column narrow>
                        <span className="ml-4 has-text-black twitter-fontSize-15-mobile has-text-weight-bold">{followerCount ?? "169"}</span>
                        <span className="ml-1 twitter-fontSize-15-mobile has-text-grey">Followers</span>
                    </Columns.Column>
                </Columns>
            </p>
            <p className="mt-2 has-text-grey is-size-7" >
                <Media className="">
                    <Media.Item className="m-0 z-35 has-background-transparent has-text-grey-light" align="left" >
                        <img
                            src={twitterAvatars}
                            alt="icon"
                            className="twitter-avatarSize figure has-text-grey-light"
                        />
                    </Media.Item>
                    <span className="mt-1 ml-2 has-text-weight-medium"> Followed by {followedBy ?? "Don, Black Box, and 122 others you follow"} </span>
                </Media>
            </p>
        </Card.Content>
    </Card>
}

export const twitter_profileBox = (dpImage, bgImage, userName, handleName, userBio, locationName, website, joinedDate, followingCount, followerCount, followedBy) => {
    return (<Columns className='m-0'>
        <Columns.Column className='is-bordered-right' size={3} >
            {twitter_leftMenu("Profile")}
        </Columns.Column>
        <Columns.Column className="p-0">
            {twitter_profileCard(dpImage, bgImage, userName, handleName, userBio, locationName, website, joinedDate, followingCount, followerCount, followedBy)}
            <Tabs className='m-0 ' textWeight='bold' fullwidth  >
                <Tabs.Tab active>Tweets</Tabs.Tab>
                <Tabs.Tab>Tweets & replies</Tabs.Tab>
                <Tabs.Tab>Media</Tabs.Tab>
                <Tabs.Tab>Likes</Tabs.Tab>
            </Tabs>
            {twitterPostCard(Default_DP, Default_BG, userName, handleName, "Preview, Proof, Organise, Task, Schedule & Sort all media & content together before publishing with TestPost. It is much much needed for all organisations. We can tell you first hand, it has saved so much time, frustration and synergy for our teams.")}
        </Columns.Column>
        <Columns.Column className='is-bordered-left pl-4 ' size={3}>
            {twitter_rightFiller()}
        </Columns.Column>
    </Columns>
    )
}

export const twitter_profileBox_mobile = (dpImage, bgImage, userName, handleName, userBio, locationName, website, joinedDate, followingCount, followerCount, followedBy) => {
    return <>
        {twitter_profileCard_mobile(dpImage, bgImage, userName, handleName, userBio, locationName, website, joinedDate, followingCount, followerCount, followedBy)}
        <Tabs size="small" className='m-0 ' textWeight='bold' fullwidth  >
            <Tabs.Tab active>Tweets</Tabs.Tab>
            <Tabs.Tab>Tweets & replies</Tabs.Tab>
            <Tabs.Tab>Media</Tabs.Tab>
            <Tabs.Tab>Likes</Tabs.Tab>
        </Tabs>
        {twitterPostCard_mobile(Default_DP, Default_BG, userName, handleName, "Preview, Proof, Organise, Task, Schedule & Sort all media & content together before publishing with TestPost. It is much much needed for all organisations. We can tell you first hand, it has saved so much time, frustration and synergy for our teams.")}
    </>
}


export const twitterPostCard = (dpImge, postImage, userName, position, content) => {
    return <Card className=" overflow-hidden has-background-transparent is-centered is-h-center " shadowless  >
        {/* <Card className="linkedinBox-576w overflow-hidden is-centered is-h-center " shadowless  > */}
        <Card.Content className="has-background-transparent radiusless px-0">

            <Box shadowless radiusless className="has-background-transparent pt-0">
                <Button size="small" className="has-background-transparent is-borderless m-0 p-0 is-pulled-right">
                    <img
                        src={twitterDotMenu}
                        alt="like"
                        className="li-iconSize24  figure opacity75"
                    /></Button>
                <Columns className="is-mobile " gap={0}>
                    <Columns.Column size={1} className="" >
                        <Media className="m-0">
                            <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                                <Image
                                    src={dpImge ?? Default_DP}
                                    className="twitter-post-dp-size has-background-transparent"
                                    rounded
                                />
                            </Media.Item>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className=" pt-0 pl-4">
                        <Heading className="mt-3 mb-0 twitter-fontSize-15 " textWeight="bold" >{userName ?? "User Name"} <span className="has-text-grey is-size-6p4 ml-1 has-text-weight-medium"> @JohnDoe · 34m</span></Heading>
                        <Content className="mt-1 has-text-black twitter-fontSize-15  li-break-words" >{content ?? "Post content"}</Content>
                        {
                            postImage ?
                                <div className="twitter-imageBorderContainer is-clipped">
                                    <img
                                        src={postImage}
                                        alt="post"
                                        className=""
                                    />
                                </div>
                                : null
                        }

                        <Box shadowless radiusless className="has-background-transparent pt-2 pb-0 px-0 mx-0">

                            <Level className="mt-4 mb-0 " breakpoint="mobile" alignItems="left" textAlign="left" alignContent="left" >
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterComment}
                                                alt="like"
                                                className="li-iconSize24 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-6p4  ">2</span>
                                    </Media>

                                </Level.Item>
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterRetweet}
                                                alt="comment"
                                                className="li-iconSize24 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-6p4  ">24</span>
                                    </Media>
                                </Level.Item>
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterLove}
                                                alt="share"
                                                className="li-iconSize24 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-6p4   ">45</span>
                                    </Media>
                                </Level.Item>
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterShare}
                                                alt="send"
                                                className="li-iconSize24 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-6p4   ">11 </span>
                                    </Media>
                                </Level.Item>
                            </Level>
                        </Box>
                    </Columns.Column>
                </Columns>
            </Box>
        </Card.Content>
    </Card>
}

export const twitterPostCard_mobile = (dpImge, postImage, userName, position, content) => {
    return <Card className=" overflow-hidden has-background-transparent is-centered is-h-center is-bordered " radiusless shadowless  >
        {/* <Card className="linkedinBox-576w overflow-hidden is-centered is-h-center " shadowless  > */}
        <Card.Content className="has-background-transparent radiusless px-0">

            <Box shadowless radiusless className="has-background-transparent pt-0 px-1 pl-2">
                <Button size="small" className="has-background-transparent is-borderless m-0 p-0  is-pulled-right">
                    <img
                        src={twitterDotMenu}
                        alt="like"
                        className="li-iconSize16 opacity90 figure "
                    /></Button>
                <Columns className="is-mobile " gap={0}>
                    <Columns.Column narrow size={1} className="" >
                        <Media className="m-0">
                            <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                                <Image
                                    src={dpImge ?? Default_DP}
                                    // className="twitter-post-dp-size has-background-transparent"
                                    size={32}
                                    rounded
                                />
                            </Media.Item>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className=" pt-0 pl-4">
                        <Heading className="mt-3 mb-0 twitter-fontSize-15-mobile " textWeight="semibold" >{userName ?? "User Name"} <span className="has-text-grey  ml-1 has-text-weight-normal"> @JohnDoe · 34m</span></Heading>
                        <Content className="mt-1 has-text-black twitter-fontSize-15-mobile mb-3 li-break-words" >{content ?? "Post content"}</Content>
                        {
                            postImage ?
                                <div className="twitter-imageBorderContainer is-clipped">
                                    <img
                                        src={postImage}
                                        alt="post"
                                        className="mt-0"
                                    />
                                </div>
                                : null
                        }

                        <Box shadowless radiusless className="has-background-transparent pt-2 pb-0 px-0 mx-0">

                            <Level className="mt-2 mb-0 " breakpoint="mobile" alignItems="left" textAlign="left" alignContent="left" >
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterComment}
                                                alt="like"
                                                className="li-iconSize16 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-7  ">2</span>
                                    </Media>

                                </Level.Item>
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterRetweet}
                                                alt="comment"
                                                className="li-iconSize16 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-7  ">24</span>
                                    </Media>
                                </Level.Item>
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterLove}
                                                alt="share"
                                                className="li-iconSize16 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-7  ">45</span>
                                    </Media>
                                </Level.Item>
                                <Level.Item  >
                                    <Media className="">
                                        <Media.Item className="m-0"  >
                                            <img
                                                src={twitterShare}
                                                alt="send"
                                                className="li-iconSize16 figure opacity75"
                                            />
                                        </Media.Item>
                                        <span className="ml-1 is-size-7  ">11 </span>
                                    </Media>
                                </Level.Item>
                            </Level>
                        </Box>
                    </Columns.Column>
                </Columns>
            </Box>
        </Card.Content>
    </Card>
}

export const twitter_leftMenu = (activeTabName) => {
    return <Columns>
        <Columns.Column size={2}>
        </Columns.Column>
        <Columns.Column className=' ' >
            <Box shadowless className=' has-background-transparent' >
                {/* <Heading size={5} className=" mb-2">About</Heading> */}
                <p className='mb-5'>
                    {rowOfColumns_leftImage(twitter_logo_icon, 32, false, null, null, null, null)}
                </p>
                <p>
                    {rowOfColumns_leftImage(twitter_home_icon, 32, false, "Home", "has-text-black", "is-size-5", `${activeTabName === "Home" ? " has-text-weight-bold " : ""}`)}
                </p>
                <br />
                <p>
                    {rowOfColumns_leftImage(twitter_hashtag_icon, 32, false, "Explore", "has-text-black", "is-size-5", "")}
                </p>
                <br />
                <p>
                    {rowOfColumns_leftImage(twitter_notifications_icon, 32, false, "Notifications", "has-text-black", "is-size-5", "")}
                </p>
                <br />
                <p>
                    {rowOfColumns_leftImage(twitter_messages_icon, 32, false, "Messages", "has-text-black", "is-size-5", "")}
                </p>
                <br />
                <p>
                    {rowOfColumns_leftImage(twitter_bookmarks_icon, 32, false, "Bookmarks", "has-text-black", "is-size-5", "")}
                </p>
                <br />
                <p>
                    {rowOfColumns_leftImage(twitter_lists_icon, 32, false, "Lists", "has-text-black", "is-size-5", "")}
                </p>
                <br />
                <p>
                    {rowOfColumns_leftImage(twitter_profile_icon, 32, false, "Profile", "has-text-black", "is-size-5", `${activeTabName === "Profile" ? " has-text-weight-bold " : ""}`)}
                </p>
                <br />
                <p>
                    {rowOfColumns_leftImage(twitter_more_icon, 32, false, "More", "has-text-black", "is-size-5", "")}
                </p>
                <Button rounded size={"medium"} color={"info"} className=" mt-5 " textWeight='semibold' fullwidth >Tweet</Button>
            </Box>
        </Columns.Column>
    </Columns>
}

export const twitter_rightFiller = () => {
    return <div>
        <Box shadowless className=' p-0 has-background-transparent'>
            <Form.Field >
                <Form.Input
                    // color="success"
                    size="medium"
                    placeholder="Search Twitter"
                    // color="danger"
                    className="is-centered is-rounded has-background-light is-borderless is-size-5 "
                />
            </Form.Field>
        </Box>
        <Box shadowless className=' has-background-light' >
            <Heading size={5} className=" mb-2">Who to follow</Heading>
            <br />
            <Columns>
                <Columns.Column>
                    <p>
                        {rowOfColumns_leftImage(satyanadella_dp, 48, true, "Satya Nadella", "has-text-black", "is-size-6", " ")}
                    </p>
                    <p>
                        {rowOfColumns_leftImage(reedhastings_dp, 48, true, "Reed Hastings", "has-text-black", "is-size-6", " ")}
                    </p>
                    <p>
                        {rowOfColumns_leftImage(billgates_dp, 48, true, "Ankur Nagpal", "has-text-black", "is-size-6", " ")}
                    </p>
                </Columns.Column>
                <Columns.Column size={4} narrow>
                    <Button rounded size={"small"} color={"black"} className=" my-3 ">Follow</Button>
                    <br />
                    <Button rounded size={"small"} color={"black"} className=" my-3 ">Follow</Button>
                    <br />
                    <Button rounded size={"small"} color={"black"} className=" my-3 ">Follow</Button>

                </Columns.Column>
            </Columns>
        </Box>
    </div>
}


export const twitter_postBox = (dpImge, postImage, userName, position, content) => {
    return <Columns>
        <Columns.Column className='is-bordered-right' size={3} >
            {twitter_leftMenu("Home")}
        </Columns.Column>
        <Columns.Column size={6} className="">
            {twitterPostCard(dpImge, postImage, userName, position, content)}
            {twitterPostCard(dpImge, postImage, userName, position, content)}
        </Columns.Column>
        <Columns.Column className='is-bordered-left pl-4 '>
            {twitter_rightFiller()}
        </Columns.Column>
    </Columns>

}

export const twitterNav_mobile = (dpImge) => {
    return <Box shadowless radiusless className=' has-background-transparent is-fullwidth m-0 p-0 mt-5'>
        <Columns className=' m-0 p-0 is-fullwidth' mobile={true}>
            <Columns.Column >
                <Image
                    src={dpImge ?? Default_DP}
                    // backgroundColor="black"
                    size={24}
                    className=" is-clipped is-pulled-left"
                    rounded
                />
            </Columns.Column>
            <Columns.Column className=''>
                <Box shadowless radiusless className="m-0 p-0 has-text-centered has-background-transparent ">
                    <ColumnAux isMiddle={true}>
                        <Image
                            src={twitter_logo_icon}
                            // backgroundColor="black"
                            size={32}
                            className="is-centered  is-clipped"
                            rounded
                        />
                    </ColumnAux>

                </Box>

            </Columns.Column>
            <Columns.Column>
                <div className="is-pulled-right">
                    <Image
                        src={twitter_magic_icon}
                        // backgroundColor="black"
                        size={24}
                        className=" is-clipped"
                        rounded
                    />
                </div>
            </Columns.Column>
        </Columns>
    </Box>
}
export const twitter_postBox_mobile = (dpImge, postImage, userName, position, content) => {
    return <>
        {twitterNav_mobile(dpImge)}
        {twitterPostCard_mobile(dpImge, postImage, userName, position, content)}
        {twitterPostCard_mobile(dpImge, postImage, userName, position, content)}
    </>
}
