import React, { createContext, useCallback, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Box, Columns, Section, Heading, Table, Button, Media, Modal, Content, Image, Tag, Progress, Form } from 'react-bulma-components'
import axios from 'axios';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AWS from 'aws-sdk'
import Dropzone from 'react-dropzone'
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'
// var S3 = require('aws-sdk/clients/s3');
import ColumnAux from '../../Containers/ColumnAux'
import fileUploadIcon from '../../assets/file-upload.png'
import doneIcon from '../../assets/done.png'
import retryIcon from '../../assets/retry.png'
import waitIcon from '../../assets/wait.png'
import crossRedIcon from '../../assets/cross-red.png'
import crossBlackIcon from '../../assets/cross-black.png'
import crossBlackThickIcon from '../../assets/cross-black-thick.png'
import { createdSignedUrl, createPresignedUrl } from '../../utilities/Service'
import { bytesToSizeString, createAssetId, provideCompressedFile, randomCode } from '../../utilities/Utilities';
import { ProjectContext } from '../../Contexts/ProjectContext';
import { UploadContext } from '../../Contexts/UploadContext';
import { Asset } from '../../Models/Asset';
import { currentUserInfo, getCurrentWorkspaceId } from '../../utilities/Login';
import { FileUploadStatus, acceptedFileTypes_forDP } from '../../Models/EnumsAndPointers';



// const UploadContext = createContext({})



export const UploadPurpose = {
    workspaceDP: "workspaceDP",
    userDP: "userDP",
    assetVersion: "assetVersion"
}


export const provideLocalFile = async (thisFile) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
            let fileInfo = {
                data: thisFile,
                name: thisFile.name,
                extension: thisFile.name.split('.').pop() ?? "",
                fileId: randomCode(8),
                size: thisFile.size,
                sizeString: bytesToSizeString(thisFile.size),
                type: thisFile.type,
                url: fileReader.result,
                uploadStatus: FileUploadStatus.waiting,
                uploadProgress: 0
            }
            resolve(fileInfo);
        };
        fileReader.onerror = reject;
        fileReader.readAsDataURL(thisFile);
    });
}


export const createAwsFilePath = (purpose, payload, overrideExtension) => {
    let awsFilePath = ""
    if (purpose === UploadPurpose.workspaceDP) {
        awsFilePath = `workspaces-dp/workspace-${getCurrentWorkspaceId()}.png`
    } else if (purpose === UploadPurpose.userDP) {
        let cuInfo = currentUserInfo()
        awsFilePath = `users-dp/user-${cuInfo.id}.png`
    } else if (purpose === UploadPurpose.assetVersion) {

        // check project info in payload 
        // must have
        // workspace info
        // currentProject info 
        // currentAsset info


    } else {
        console("No image upload purpose set")
        return
    }
    return awsFilePath 
}


export const handleFileChosenFor = async (purpose, payload, overrideExtension, thisFile) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        // let workspaceFolder = `workspace-${getCurrentWorkspaceId()}`
        let awsFilePath = createAwsFilePath(purpose, payload, overrideExtension)


        fileReader.onload = () => {
            let fileInfo = {
                data: thisFile,
                name: thisFile.name,
                extension: overrideExtension ?? thisFile.name.split('.').pop() ?? "",
                fileId: createAssetId(null, 1),
                size: thisFile.size,
                sizeString: bytesToSizeString(thisFile.size),
                type: thisFile.type,
                url: fileReader.result,
                awsFilePath: awsFilePath
                // uploadStatus: FileUploadStatus.waiting,
                // uploadProgress: 0
            }
            resolve(fileInfo);
        };
        fileReader.onerror = reject;
        fileReader.readAsDataURL(thisFile);
    });
}



export const compressAnd_UploadFile = (file, awsFilePath, toCompresss, progressCallback, callback) => {

    let shouldCompress = toCompresss

    let maxWidthORHeight = 900
    let maxSize = 0.6 // mb
    let maxSizeBytes = 0.6 * 1024 * 1024

    if (file.size < maxSizeBytes){
        shouldCompress = false
    }



    if (shouldCompress) {
        provideCompressedFile(file, maxWidthORHeight, maxSize, (succ, compressedFile) => {
            if (succ) {
                console.log('Image file compressed')
                // then upload it

                console.log("Compressed file is")
                console.log(file)

                uploadAFile(compressedFile, awsFilePath,
                    //progress
                    (file, progress) => {
                        console.log("File upload progress changed " + progress)
                        progressCallback(file, progress)
                    },
                    //error
                    (file, errorMessage) => {
                        callback(false, file, errorMessage)
                    },
                    //upload complete - success
                    (success, fileUrl, errMsg) => {
                        callback(true, fileUrl, errMsg)
                    }
                )

            } else {
                console.log('Image could not be compressed')
            }
        })
    } else {
        uploadAFile(file, awsFilePath,
            //progress
            (file, progress) => {
                console.log("File upload progress changed " + progress)
                progressCallback(file, progress)
            },
            //upload complete - success
            (success, fileUrl, errMsg) => {
                callback(success, fileUrl, errMsg)
            }
        )

    }

}





// export const handleAnd_UploadFile = (purpose, payload, file, overrideExtension, toCompresss, progressCallback, callback) => {


//     // Promise.all(
//     //     allFiles.map((file) => {
//     //         return handleFileChosen(file);
//     //     })
//     // )

//     console.log("Incoming files to upload is ")
//     console.log(file)



//     handleFileChosenFor(purpose, payload, overrideExtension, file).then((result) => {
//         // cogoToast.success("PROMISE PRODUCTS Results Success")
//         console.log("The file is");
//         console.log(result);


//         // Compress the image here 
//         if (toCompresss) {
//             provideCompressedFile(result, 900, 0.6, (succ, file) => {
//                 if (succ) {
//                     console.log('Image file compressed')
//                     // then upload it

//                     console.log("Compressed file is")
//                     console.log(file)


//                     uploadAFile(file,
//                         //progress
//                         (file, progress) => {
//                             console.log("File upload progress changed " + progress)
//                             progressCallback(file, progress)
//                         },
//                         //error
//                         (file, errorMessage) => {
//                             callback(false, file, errorMessage)
//                         },
//                         //upload complete - success
//                         (file, successData) => {
//                             callback(true, file, successData)
//                         }
//                     )

//                 } else {
//                     console.log('Image could not be compressed')
//                 }
//             })
//         } else {
//             uploadAFile(result,
//                 //progress
//                 (file, progress) => {
//                     progressCallback(file, progress)
//                 },
//                 //error
//                 (file, errorMessage) => {
//                     callback(false, file, errorMessage)
//                 },
//                 //upload complete - success
//                 (file, successData) => {
//                     callback(true, file, successData)
//                 }
//             )

//         }



//     }).catch((error) => {
//         console.log("ERROR WHILE TRYING TO READ FILES IS");
//         console.log(error);
//     });

// }


export const uploadAFile = async (file, awsFilePath,  progressCallback, callback) => {
    // if this is unbatched put it in a batch to sync the progress and show of Uploading waiting files button
    console.log("Starting to upload file " + file.name)
    console.log(file)

    // const S3Client = new S3(awsConfig);

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWSS3_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWSS3_ACCESS_KEY
    })


    const s3Object = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME },
        region: process.env.REACT_APP_AWSS3_REGION,
    })

    // var opts = {
    //     queueSize: 1,
    //     partSize: 1024 * 1024 * 5
    // };

    // console.log("---- FILE NAME = " + file.name)
    // console.log("---- FILE TYPE = " + file.type)
    // console.log("---- FILE WITH TYPE = " + file.fileId + "." + file.extension)

    // let newFileName = file.fileId + "." + file.extension

    // add project folder
    // let projectFolder = currentProject.id
    // let workspaceFolder = `workspace-${getCurrentWorkspaceId()}`
    // let filePath = `workspaces/${workspaceFolder}/${projectFolder}/${newFileName}`

    var params = {
        Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
        Key: awsFilePath,
        ContentType: file.type,
        Body: file.data
    };



    // var upload = s3Object.upload(params, opts)


    var upload = s3Object.upload(params)
        .on('httpUploadProgress', function (evt) {
            // console.log("Uploaded :: " + parseInt((evt.loaded * 100) / evt.total) + '%');
            // console.log("Progress event is fired")
            // console.log(evt)
            var percentCompleted = Math.round((evt.loaded * 100) / evt.total)
            if (progressCallback) {
                if (`${percentCompleted}`.includes("0")) {
                    // only updating progress 10/20/30/...100
                    progressCallback(file, percentCompleted)
                }
            }

        })
        .promise()


    await upload.then((data) => {


        if (data) {
            // console.log("Data after uploading file = " + filePath)
            // console.log(data);
            if (callback) {
                // uploadedCallback(file, data)
                callback(true, data.Location ?? "data-location-not-known", null)

                // data.Location ?? "data-location-not-known"
                // changeFileUploadStatus(file, FileUploadStatus.uploaded, 100, null, null)


                // file is uploaded
                // createFileObject(file, data.Location ?? "data-location-not-known")
            }


        }



    }).catch((err) => {
        // if (err) {
        // console.log("Error while uploading file = " + file.name)
        // console.log("Error name = " + err.name)
        // console.log("Error message = " + err.message)


        if (err.name === "NetworkingError") {
            callback( false, file , FileUploadStatus.networkError)
        } else {
            callback( false, file, FileUploadStatus.failed)
        }

        console.log(err);
        // }
        // updateProgress()

    })

    // S3Client.upload({Body: file.data})
}







