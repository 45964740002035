import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';

import { Button, Columns, Container, Heading, Section, Notification, Box, Content, Image, Form } from 'react-bulma-components';
import Dashboard from '../Pages/Dashboard';
import WorkspaceImage from '../assets/workspace.png'
import Sign from '../Pages/Sign';
import { isCurrentUser, initialiseParse, getCurrentWorkspaceId, getRevalidateWSMembership, setMemberWorkspaces, getOwnedWorkspacesIds, getMemberWorkspaces } from '../utilities/Login'
import Entry from '../Pages/Entry';
import ColumnAux from './ColumnAux';
import CreateWorkspace from '../Components/Sign/CreateWorkspace'
import { Workspace } from '../Models/Workspace';
import { SubscriptionStatus } from '../Models/EnumsAndPointers';
import { Invitation } from '../Models/Invitation';


const WorkspaceAux = (props) => {

    const [wk, setWk] = useState(null)
    const [toCreateWK, setToCreateWK] = useState( false)
    const [toJoinWK, setToJoinWK] = useState(false)
    const [joinInviationPass, setJoinInvitationPass] = useState(null)
    const [validatedInvitationObj, setValidatedInvitationObj] = useState(null)
    const [wkForInvitation, setWKforInvitation] = useState(null)
    const [joinInProgress, setJoinInProgress] = useState(false)
    

    const [error, setError] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [allMemberWorkspaces, setAllMemberWorkspaces] = useState([])
    const [loadingWorkspaces, setLoadingWorkspaces] = useState(false)

    useEffect(() => {

        let isMounted = true;

        setWk(props.workspace)
        setToCreateWK((!props.workspace) ? true : false)
        let isInvitation = false

        const queryString = window.location.search;
        if (queryString) {
            const params = new URLSearchParams(queryString)
            if (params) {
                let inv = params.get('inv')
                if (inv) {
                    setToCreateWK(false)
                    setJoinInvitationPass(inv)
                    isInvitation = true
                    setToJoinWK(true)
                }
            }
        }
        console.log(queryString);



        if (!props.workspace) {
            // We only refetch on
            setLoadingWorkspaces(true)
            Workspace.getAllForCurrentUser((succ, results, errMsg) => {

                if (isMounted){
                    setLoadingWorkspaces(false)
                }
                if (succ) {
                    if (results) {
                        if (results.length) {
                            if (isMounted){
                                setAllMemberWorkspaces(results)
                                setMemberWorkspaces(results)
                            }



                            let toReval = getRevalidateWSMembership()

                            if (results.length === 1 && isInvitation === false && toReval === false) {
                                console.log("ATIVATING WORKSPACE AS ONLY ONE");
                                if (isMounted){
                                    props.activateWorkspace(results[0])
                                }
                            } else {
                                // check if is in link
                                if (isMounted){
                                    setToCreateWK(false)
                                }
                            }
                        }
                    }
                } else {
                    console.log("No workspace found of user - " + errMsg)
                }
            })
        }

        return () => (isMounted = false)
    }, [props])


    // const checkAndSetWorkspace = () => {
    //     let existingWK = getCurrentWorkspaceId()
    //     if (existingWK) {
    //         setWk(existingWK)
    //     }
    // }

    const clearToggles = () => {
        setToCreateWK(false)
        setToJoinWK(false)
        setValidatedInvitationObj(null)
        setWKforInvitation(null)
        setJoinInProgress(false)
    }


    const checkInvitationPass = () => {
        setJoinInProgress(true)
        Invitation.getMatching(joinInviationPass, (succ, result, errMsg) => {
            setJoinInProgress(false)
            if (succ) {
                if (result.length) {
                    let thisInv = result[0]
                    if (thisInv) {
                        setValidatedInvitationObj(thisInv)
                        let wkInfo = thisInv.workspaceInfo
                        console.log("INVITATION OBJ IS")
                        console.log(thisInv);
                        console.log("WORKSPACE IN INVITATION OBJ IS")
                        console.log(wkInfo);

                        // get workspace for this workspaceId
                        setWKforInvitation(wkInfo)
                    }
                } else {
                    alert("The pass could not be matched with any invitation for your email.")
                }
            } else {
                console.log(errMsg);
            }
        })

    }


    const toggleToCreateWk = (val) => {

        // check if any of allOwnedWrkspaces are in trial or locked 

        let ownedWKs = getOwnedWorkspacesIds() ?? []
        // console.log("OWNED WORKSPACES ARE");
        // console.log(ownedWKs);

        // console.log("MEMBER WORKSPACES ARE");
        // console.log(allMemberWorkspaces);

        if (ownedWKs.length) {
            let isPaymentPending = false
            allMemberWorkspaces.map((wk) => {
                if (ownedWKs.includes(wk.id)) {
                    if (wk.subsStatus === SubscriptionStatus.trial || wk.subsStatus === SubscriptionStatus.locked) {
                        isPaymentPending = true
                    }
                }
                return null
            })

            if (isPaymentPending) {
                alert("New workspaces can only be created when all your existing workspaces are operational/cancelled. Only 1 workspace can remain in trial mode.")
                return
            }
        }


        clearToggles()
        setToCreateWK(val ?? !toCreateWK)
    }

    const toggleToJoinWk = (val) => {
        clearToggles()
        setToJoinWK(val ?? !toJoinWK)
    }




    // CREATE FORMS
    //country
    //website
    //logo
    //strength / size
    //Name
    //industry
    //purpose
    //phone 
    //email

    const trySelectingWorkspaces = (wk) => {

        if (wk.subsStatus === SubscriptionStatus.locked || wk.subsStatus === SubscriptionStatus.cancelled) {
            alert("This workspaces is locked")
            return
        }

        props.activateWorkspace(wk)
    }


    const workspaceNeeded = () => {
        return <Box className="mt-6 mx-3 py-6 has-background-white-bis" textAlign="center"  >
            <Heading size={3} spaced={false}  > {allMemberWorkspaces.length ? "Choose Workspace" : "Create or join workspace"}  </Heading>
            <Heading subtitle size={6} textColor="grey" > Workspace is a central space for an organisation, brand or a team. </Heading>
            {
                (wk === null && allMemberWorkspaces.length) ?
                    <Box shadowless className="is-borderless m-6 has-background-transparent" >
                        {allMemberWorkspaces.map((ambWK) => {
                            return <Box key={ambWK.id} size="large" className=" is-black m-5 px-2 py-5 " onClick={() => { trySelectingWorkspaces(ambWK) }}>
                                <p className="is-uppercase has-text-weight-bold brandColorText ">{ambWK.name}</p>
                                {(ambWK.subsStatus === SubscriptionStatus.active || ambWK.subsStatus === SubscriptionStatus.warning) ? null : <p className="is-size-7 has-text-grey has-text-weight-light has-text-centered">  {ambWK.isOwned ? <span className="has-text-weight-semibold">OWNED  ·</span> : null} {ambWK.subsStatus}</p>}
                            </Box>
                        })}
                    </Box>
                    :
                    <img alt="workspace illustration" src={WorkspaceImage} width="360" className="is-centered m-1 " />

            }

            <Heading subtitle size={7} textColor="dark" className="mt-4" > Everything happens inside a workspace. Create one or join if you have an invitation. </Heading>
            <Button className="m-2 py-5" onClick={toggleToJoinWk}>I have an invitation</Button>
            <Button className="m-2 py-5" color="black" onClick={toggleToCreateWk}>Create new workspace</Button>
        </Box>
    }

    const createWorkspace = () => {
        return <Box className="mt-6 mx-3 py-6 has-background-white-bis" textAlign="center"  >
            <Heading size={3} spaced={false}  > Lets create your workspace </Heading>
            <Heading subtitle size={6} textColor="grey" > Workspace represents an organisation, brand or a team. </Heading>

            <CreateWorkspace joinWorkspace={() => { toggleToJoinWk(true) }} clearToggles={clearToggles} activateWorkspace={props.activateWorkspace} />

        </Box>
    }


    const passChanged = (val) => {

        if (validatedInvitationObj && wkForInvitation){
            setValidatedInvitationObj(null)
            setWKforInvitation(null)
        }

        setJoinInvitationPass(val)
    }


    const joinInClicked = () => {
        if (wkForInvitation && validatedInvitationObj) {
            // clearToggles()
            // ADD THIS USER AS THE DESCRIBED ROLE IN THE GIVEN WORKSPACE
            // alert("Add user to workspace now")
            setJoinInProgress(true)
            Workspace.addCurrentUserToWk(wkForInvitation.id, validatedInvitationObj.role, (succ, result, errMsg) =>{
                setJoinInProgress(false)
                if (succ){
                    console.log("Current user added to workspace");
                    let invCopy = Invitation.copyFrom(validatedInvitationObj)
                    invCopy.updateInvitationAsJoined((err)=>{
                        if (err){
                            console.log(err);
                            alert("Invitation could not be updated as joined")
                        }
                    })

                    props.activateWorkspace(wkForInvitation)


                }else{
                    alert("You could not be added to the workspace. Try again or report error.")
                    console.log(errMsg);
                }
            })

            // props.activateWorkspace(wkForInvitation)
        } else {
            checkInvitationPass()
        }
    }

    const joinWorkspace = () => {
        // welcome to workspace
        // eneter invitation key 
        return <Box className="mt-6 mx-3 py-6 has-background-white-bis" textAlign="center"  >

            {
                validatedInvitationObj && wkForInvitation ?
                    <div>
                        <Heading size={3} spaced={false}> Welcome to {wkForInvitation.name}</Heading>
                        <Heading subtitle size={6} textColor="grey" > <span className="is-capitalized">{validatedInvitationObj.byUserInfo.name} </span>  invited you to join as a <span className="is-capitalized"> {validatedInvitationObj.role} </span>  </Heading>
                    </div>
                    :
                    <div>
                        <Heading size={3} spaced={false}> Enter invitation pass</Heading>
                        <Heading subtitle size={6} textColor="grey" > Invitation is shared via email. Copy-Paste the Pass here. </Heading>
                    </div>
            }



                <br />                
                <br />                

            {/* <CreateWorkspace joinWorkspace={()=> { toggleToJoinWk(true) } } /> */}
            <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>
                <Form.Field >
                    <Form.Control>
                        {/* <Field name='name' type='text' value={values.name} className='input is-default' validate={validateName} placeholder="like John Doe" /> */}
                        <Form.Input disabled={joinInProgress} name='invitation' type='text' value={joinInviationPass ?? ""} className='input is-default' placeholder="Paste pass here..." onChange={(e) => { passChanged(e.target.value) }} />
                    </Form.Control>
                </Form.Field>
                <Button disabled={joinInProgress} loading={joinInProgress} type="submit" className="is-fullwidth mt-4" color="black" onClick={joinInClicked}> {validatedInvitationObj ? "Start" : "Join In"} </Button>

            </ColumnAux>


            <br />
            <br />
            <Box className=" p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light has-background-transparent">
                <div className=" has-text-centered">
                    <Button className=" has-background-transparent is-text is-size-7 has-text-grey" size="small" onClick={() => { toggleToJoinWk(false) }}  > Back. What is workspace ?</Button>

                    <p className="mt-4">
                        <span className=""> Start a new workspace?  </span>
                    </p>
                    {/* <Button className=" has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.SignIn) }} > Join Workspace</Button> */}
                    <Button className=" has-background-transparent " size="small" onClick={() => { toggleToCreateWk(true) }}  > Create Workspace</Button>

                </div>
            </Box>

        </Box>

    }

    const joinLinkError = () => {

    }

    const loadingWKsComp = () => {

        return <Box shadowless className="mt-6 mx-3 p-6  has-background-transparent " textAlign="center"  >
            <br />
            <Button loading={true} size="large" className="has-text-success is-size-3 is-borderless has-background-transparent" />
            <br />
        </Box>

    }


    const workspaceChoose = () => {

        console.log("WORKSPACE IS ");
        // console.log(wk);

        let content = workspaceNeeded()

        if (toCreateWK) {
            content = createWorkspace()
        }

        if (toJoinWK) {
            content = joinWorkspace()
        }

        if (loadingWorkspaces) {
            content = loadingWKsComp()
        }

        return <div>
            <Container>
                <ColumnAux isMiddle={true} size={8}>
                    {content}
                </ColumnAux>

            </Container>
        </div>
    }


    // 3 flows
    // 1 public flow ... get workspaceId & project from public id and then show it
    // 2 isInvitedToWorkspace


    //   isInvitedToWorkspace={null} isPublic={false} projectPublicId={null}


    return (
        <div>
            {
                wk ?
                    props.children
                    // <Dashboard workspace={wk} isPublic={props.isPublic} publicProjectId={null} activateWorkspace={activateWorkspace} userLoggedOut={props.userLoggedOut} />
                    :
                    workspaceChoose()
            }
        </div>
    );



}

export default WorkspaceAux