import React, { useState } from 'react'
import { Box, Button, Message } from 'react-bulma-components'
import { Form as BulmaForm } from 'react-bulma-components'
import ColumnAux from '../../Containers/ColumnAux'
import { Formik, Form, Field } from 'formik'
import { signUpNow } from '../../utilities/Login'
import { validateEmail, validateName, validatePassword, validateText, isQueryStringInvitationEmail } from '../../utilities/Utilities'
import { SignType } from '../../utilities/Enums'

const SignUpForm = (props) => {


    const [showMessage, setShowMessage] = useState(null)




    let initialVals = {
        name: null,
        email: isQueryStringInvitationEmail() ?? null ,
        password: null,
        confirmPassword: null,
        jobTitle: null,
        via: null,
        termsAgreed: false
    }

    // Add these
    // jobTitle

    // <select id="segmentationJobTitle-select-field" aria-label="Which of these roles best describes you?" name="segmentationJobTitle" class="SelectFieldMenu-sc-1ile0aj-0 cdHzLV" required=""><option value="">Which of these roles best describes you?</option>
    // <option value="Art Director">Art Director</option>
    // <option value="Content Creator">Content Creator/Writer</option>
    // <option value="Content Manager">Content Manager</option>
    // <option value="Consultant">Consultant</option>
    // <option value="Copywriter">Copywriter</option>
    // <option value="Creative Director">Creative Director</option>
    // <option value="Curator">Curator</option>
    // <option value="Designer">Designer</option>
    // <option value="Developer">Developer</option>
    // <option value="Editor">Editor</option>
    // <option value="Intern">Intern</option>
    // <option value="Managing Director">Managing Director</option>
    // <option value="Marketing Manager">Marketing Manager</option>
    // <option value="Manager">Manager</option>
    // <option value="Photographer">Photographer</option>
    // <option value="Producer">Producer</option>
    // <option value="Professor">Professor / teacher</option>
    // <option value="Product Manager">Product Manager</option>
    // <option value="Social Media Manager">Social Media Manager</option>
    // <option value="Student">Student</option>
    // <option value="Other">Other</option>
    // </select>



    const setShowMessageForTime = (thisMessage, type, secs) => {
        setShowMessage({
            type: "danger",
            message: thisMessage
        })
        setTimeout(() => {
            setShowMessage(null)
        }, (secs ?? 5) * 1000);
    }

    const trySignUp = (name, email, password, jobTitle, via, callback) => {

        signUpNow(name, email, password, jobTitle, via, (succ, user, errMsg) => {
            if (succ) {
                props.userSigned(user)
                callback(succ, user, errMsg)

            } else {
                callback(succ, user, errMsg)
                setShowMessageForTime(errMsg, "danger", 5)
                console.log(errMsg)
            }

        })


    }



    return (
        <Formik
            initialValues={initialVals}

            validate={(values) => {
                const errors = {};
                if (values.password !== values.confirmPassword) {
                    errors.confirmPassword = 'Passwords do not match'
                }

                if (values.termsAgreed !== true) {
                    errors.termsAgreed = 'Please read and agree the terms to continue.'
                }

                return errors;
            }}

            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                trySignUp(values.name, values.email, values.password, values.jobTitle, values.via, (succ, user, errMsg) => {
                    actions.setSubmitting(false)
                    actions.resetForm(true)
                    actions.setValues(initialVals)
                })
            }}
        >

            {
                ({ values, isSubmitting, errors, touched, isValidating, handleChange }) => {
                    return (
                        <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                            <Box className="has-text-left  is-shadowless">
                                {
                                    showMessage ?
                                        <Message color={showMessage.type ?? "default"}>
                                            <Message.Body>{showMessage.message}</Message.Body>
                                        </Message>
                                        : null
                                }
                                <Form>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7" >Name</BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='name' type='text' value={values.name ?? ""} className='input is-default' validate={validateName} placeholder="e.g. John Doe" />
                                        </BulmaForm.Control>
                                        {errors.name && touched.name && <BulmaForm.Help color="danger">{errors.name}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Email <span className="has-text-grey-lighter has-text-weight-normal">(username)</span></BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='email' type='email' value={values.email ?? ""} className='input is-default' validate={validateEmail} placeholder="e.g. john@website.com" />
                                        </BulmaForm.Control>
                                        {errors.email && touched.email && <BulmaForm.Help color="danger">{errors.email}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Password</BulmaForm.Label>
                                        <BulmaForm.Control >
                                            <Field name='password' type='password' value={values.password ?? ""} className='input is-default' validate={validatePassword} placeholder="your secure password" />
                                        </BulmaForm.Control>
                                        {errors.password && touched.password && <BulmaForm.Help color="danger">{errors.password}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Confirm Password</BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='confirmPassword' type='password' value={values.confirmPassword ?? ""} className='input  is-default' validate={validatePassword} placeholder="your secure password again" />
                                        </BulmaForm.Control>
                                        {errors.confirmPassword && touched.confirmPassword && <BulmaForm.Help color="danger">{errors.confirmPassword}</BulmaForm.Help>}
                                    </BulmaForm.Field>

                                    {/* <br /> */}
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Role that best describes you </BulmaForm.Label>
                                        <BulmaForm.Control>
                                        <Field name='jobTitle' type='text'  value={values.jobTitle ?? ""} className='input is-default  has-background-white-bis' validate={validateText}  placeholder="Designer, Editor, Manager, Entrepreneur, Art Director..." />
                                            {errors.jobTitle && touched.jobTitle && <BulmaForm.Help color="danger">{errors.jobTitle}</BulmaForm.Help>}
                                            {/* <BulmaForm.Select as="select" name="jobTitle" size="small" fullwidth onChange={handleChange}> 
                                                   <option value="None">&nbsp;</option>
                                                   <option value="Art Director">Art Director</option>
                                                   <option value="Content Creator">Content Creator/Writer</option>
                                                   <option value="Content Manager">Content Manager</option>
                                                   <option value="Consultant">Consultant</option>
                                                   <option value="Copywriter">Copywriter</option>
                                                   <option value="Creative Director">Creative Director</option>
                                                   <option value="Curator">Curator</option>
                                                   <option value="Designer">Designer</option>
                                                   <option value="Developer">Developer</option>
                                                   <option value="Editor">Editor</option>
                                                   <option value="Entrepreneur">Entrepreneur</option>
                                                   <option value="Intern">Intern</option>
                                                   <option value="Managing Director">Managing Director</option>
                                                   <option value="Marketing Manager">Marketing Manager</option>
                                                   <option value="Manager">Manager</option>
                                                   <option value="Photographer">Photographer</option>
                                                   <option value="Producer">Producer</option>
                                                   <option value="Professor">Professor / teacher</option>
                                                   <option value="Product Manager">Product Manager</option>
                                                   <option value="Social Media Manager">Social Media Manager</option>
                                                   <option value="Student">Student</option>
                                                <option value="Other">Other</option>
                                            </BulmaForm.Select>                 */}
                                        </BulmaForm.Control>
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">How did you know about us </BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='via' type='text'  value={values.via ?? ""} className='input is-default  has-background-white-bis' validate={validateText}  placeholder="You reached out to me, Google, LinkedIn, Instagram, Twitter, Friend..." />
                                            {errors.via && touched.via && <BulmaForm.Help color="danger">{errors.via}</BulmaForm.Help>}

                                            {/* <BulmaForm.Select as="select" name="via" size="small" fullwidth onChange={handleChange}> 
                                                  <option value="None">&nbsp;</option>
                                                   <option value="Google Search">Google Search (or similar)</option>
                                                   <option value="LinkedIn">LinkedIn</option>
                                                   <option value="Instagram">Instagram</option>
                                                   <option value="Twitter">Twitter</option>
                                                   <option value="Youtube">Youtube</option>
                                                   <option value="Reddit/Producthunt/Similar Networks">Reddit/Producthunt/Similar..</option>
                                                   <option value="Behance/Dribbble/Similar Networks">Behance/Dribbble/Similar..</option>
                                                   <option value="Word Of Mouth">Friend/Collegue</option>
                                                   <option value="Reached Out By Us">You reached out to me</option>
                                                <option value="Other">Other</option>
                                            </BulmaForm.Select>                 */}
                                        </BulmaForm.Control>
                                    </BulmaForm.Field>
                                    {/* <br /> */}

                                    

                                    <BulmaForm.Field>
                                        <BulmaForm.Control>
                                            {/* <BulmaForm.Checkbox render > */}
                                            <label className="checkbox is-size-6p7"  >
                                                {/* &nbsp; I agree to the <a href='https://tailorwise.com'>terms and conditions</a> */}
                                                <Field type="checkbox" className='checkbox is-size-6p7' name='termsAgreed'   />
                                                    &nbsp; I agree to the <a href='https://tailorwise.com'>terms and conditions</a>
                                            {errors.termsAgreed && touched.termsAgreed && <div className="help is-danger">{errors.termsAgreed}</div>}
                                            </label>

                                            {/* </BulmaForm.Checkbox> */}
                                        </BulmaForm.Control>
                                    </BulmaForm.Field>

                                <Button disabled={isSubmitting || isValidating} loading={isSubmitting} type="submit" className="is-fullwidth mt-4" color="black"> Create Account</Button>


                                </Form>

                            <br />
                            <br />
                            <Box className=" mt-2 p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light">
                                <div className=" has-tex-centered">
                                    <p className="">
                                        <span className="">  Already have an account? </span>

                                    </p>
                                    <Button  className=" has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.SignIn) }} > Sign In Now</Button>

                                </div>
                            </Box>

                            </Box>
                        </ColumnAux>


    )
}
            }

        </Formik >
    )
}

export default SignUpForm