import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon, Message } from 'react-bulma-components';
import { Task } from '../../Models/Task';
import { Event } from '../../Models/Event';
import { Asset } from '../../Models/Asset';

import { AiOutlineAppstoreAdd, AiFillFacebook, AiOutlineFacebook, AiFillInstagram, AiOutlineInstagram, AiFillLinkedin, AiOutlineLinkedin, AiOutlineTwitter, AiFillMediumSquare, AiFillYoutube, AiOutlineYoutube } from 'react-icons/ai';
import { RiFileUploadLine, RiMoreFill, RiImageAddLine, RiTwitterLine, RiMediumLine, RiSpotifyLine, RiSnapchatLine } from 'react-icons/ri';
import { FaSpotify, FaPinterest, FaSnapchatGhost } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { TiDocumentAdd } from 'react-icons/ti';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { GrFormClose } from 'react-icons/gr';
import { IoMdPersonAdd, IoMdAddCircleOutline, IoMdAddCircle } from 'react-icons/io';
import { IoAddCircleOutline, IoClose, IoCheckmarkDoneOutline, IoCloseOutline } from 'react-icons/io5';
import moment from 'moment'

import { MdSchedule } from 'react-icons/md';

// IoMdPersonAdd
import ReactTooltip from 'react-tooltip';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Allowed_SocialMedia_Platforms, CRUDEvent, emptyTaskAttachments, ProjectFolderLevel, SelectableEntity, SocialMedia_Platform, socialMediaChannels_HexColors, TaskPriority, TaskStatus, TaskType, EntityType, EntityLogType, TaskType_options, Task_Dynamic_Changeables, TastPriority_options, TaskStatus_options, UserPickerFor, userPointer, CRUDAction } from '../../Models/EnumsAndPointers';
import UserAvatar from '../Calendar/UserAvatar';
import UserPicker from '../Calendar/UserPicker';
import { completeDateTime, confirmAction, getTimeAfterMins, isPrivate, privateTag, randomCode } from '../../utilities/Utilities';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import ProjectExplorer from '../Common/ProjectExplorer';
import ModalExplorer from '../Common/ModalExplorer';
import TextEditor from './TextEditor';
import TimelineExplorer from '../Common/TimelineExplorer';
import { Timeline } from '../../Models/Timeline';


// AiFillFacebook, AiOutlineFacebook
// AiFillInstagram, AiOutlineInstagram
// AiFillLinkedin, AiOutlineLinkedin
// AiOutlineTwitter, RiTwitterLine
// FaPinterest, RiPinterestLine
// AiFillMediumSquare, RiMediumLine
// FaSpotify, RiSpotifyLine
// FaSnapchatGhost, RiSnapchatLine, 




const TaskModal = (props) => {

    let newTask = () => {
        let tk = new Task()
        tk.byUser = isCurrentUser().id
        tk.byUserInfo = currentUserInfo()
        tk.createdAt = new Date()

        return tk
    }

    const taskAttachmentsOnLoad = (tk) => {
        const { projects, assets, comments } = tk
        return {
            projects: projects ?? [],
            assets: assets ?? [],
            comments: comments ?? []
        }
    }


    const [task, setTask] = useState(props.task ?? newTask())
    const [showUserPickerFor, setShowUserPickerFor] = useState(null)
    const [addOrDeleteNote, setAddOrDeleteNote] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState([])

    const [showTimeline, setShowTimeline] = useState(false)


    const [lastUploadedTask, setLastUploaadedTask] = useState(task)
    const [changesMade, setChangesMade] = useState([])


    const [showAssetPicker, setShowAssetPicker] = useState(false)
    const [showEventPicker, setShowEventPicker] = useState(false)

    const [showProjAssComms, setShowProjAssComms] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    const [attachments, setAttachments] = useState(taskAttachmentsOnLoad(task))





    const contentChanged = (val) => {
        let ev = { ...task }
        ev.content = val
        console.log("CONTENT IS CHANGED")

        setTask(ev)

    }

    const saveTask = () => {

        console.log("SAVING THIS TASK")
        console.log(task);



        if (task.id) {
            //update task
            updateTask()
        } else {
            // add task
            addTask()
        }
    }


    const addTask = () => {
        if (!task.title || task.title === "") {
            alert("There is no task title set")
            return
        }
        setIsSaving(true)

        let tk = Task.copyFrom(task)


        console.log("SAVING TASK");

        tk.add((succ, result, errMsg) => {
            setIsSaving(false)
            if (succ) {
                console.log("Task is added")
                console.log(result)
                setTask(result)
                // props.newEventAdded(result)
                Timeline.updateTimline(EntityType.task, result.id, result, EntityLogType.created, "Created", null)

                updateParentAndClose(result)

            } else {
                console.log(errMsg)
            }
        })

        setChangesMade([])
    }

    const updateTask = (psdTask) => {

        setIsSaving(true)


        let tk = Task.copyFrom(psdTask ?? task)
        // ev.ends = getTimeAfterMins(date, 31)
        // ev.ends = moment(ev.starts).add(30, "minutes").toDate()



        // console.log("Date starts")
        // console.log(ev.starts)
        // console.log("Date ends")
        // console.log(ev.ends)

        let description = changesMade.map((c) => c.attrDesc + " " + c.operation + " : " + c.value).join(" >n>· ")

        // console.log("DESCRIPTION BEFORE UPDATE IS")
        // console.log(description)

        // check if changes are only dynamic .. if yes.. only Timeline needs to be updated. Nothinh else.
        let dynChangedAttrs = [
            Task.attributeForChangeable(Task_Dynamic_Changeables.AssignedToUsers),
            Task.attributeForChangeable(Task_Dynamic_Changeables.SharedWithUsers),
            Task.attributeForChangeable(Task_Dynamic_Changeables.Note),
            Task.attributeForChangeable(Task_Dynamic_Changeables.ProjectsAssetsComments)
        ]

        // console.log("ALL CHANGES")
        // console.log(changesMade)

        let nonDynamicChanges = changesMade.filter((c) => {
            return !dynChangedAttrs.includes(c.attribute)
        })

        // console.log("NON DYNAMIC CHANGES ARE")
        // console.log(nonDynamicChanges)

        if (nonDynamicChanges.length) {


            // let whatChangedOuter = Task.whatHasChanged(tk, psdTask)


            tk.update((succ, result, errMsg) => {
                setIsSaving(false)
                if (succ) {
                    // console.log("Task is updated")
                    // console.log(result)
                    setTask(result)
                    setLastUploaadedTask(result)

                    // console.log("WHAT CHANGED OUTER IS ")
                    // console.log(whatChangedOuter)

                    // let whatChangedInner = Task.whatHasChanged(tk, psdTask)
                    // console.log("WHAT CHANGED INNER IS ")
                    // console.log(whatChangedInner)


                    Timeline.updateTimline(EntityType.task, result.id, result, EntityLogType.updated, "Updated", description)
                    updateParentAndClose(result)
                    setChangesMade([])

                } else {
                    console.log(errMsg)
                }
            })

        } else {
            setIsSaving(true)
            //    if  (window.confirm("Upload only timeline")){
            //         console.log("YES")
            //     }else{
            //         console.log("NO")
            //     }

            Timeline.updateTimline(EntityType.task, tk.id, tk, EntityLogType.updated, "Updated", description)
            setTimeout(() => {
                setIsSaving(old => false)
            }, 1000)
            setChangesMade([])

        }



    }

    const updateParentAndClose = (result) => {
        if (props.isNew) {
            props.taskIsAdded(result)
            // props.close()
        } else {
            props.taskIsUpdated(result)
            // props.close()
        }
    }


    const usersAreSelected = (arr) => {
        // setSelectedUsers(arr)

        console.log("Users are selected")
        console.log(arr)
        // For Assigned to tasks
        let tk = Task.copyFrom(task)
        if (showUserPickerFor === UserPickerFor.taskAssign) {
            tk.assignedToInfo = arr

            tk.addAssignTo_multiple(arr.map(a => a.id), arr, (err) => {
                if (err) {
                    alert("Users could not be added for AssignTo ")
                    console.log(err)
                }
            })

        }
        if (showUserPickerFor === UserPickerFor.taskShareWith) {
            tk.sharedWithInfo = arr

            tk.addSharedWith_multiple(arr.map(a => a.id), arr, (err) => {
                if (err) {
                    alert("Users could not be added for TaskShare ")
                    console.log(err)
                }
            })
        }
        setTask(t => tk)
        // console.log("Setting new task")
        // console.log(tk)

    }

    const entityChanged = (attr, attributeDesc, opr, valObj, val, time) => {

        // console.log("NEW ENTITY CHANGED ENTRY")
        console.log(`ATTR = ${attr}, VAL = ${val}, TIME = ${time}`)

        let changes = [...changesMade]
        let matchingChangeIndex = changes.findIndex((c) => c.attribute === attr)

        // Event_Dynamic_Changeables



        // console.log("COMPARING LASTUPLOADEVENT")
        // console.log(lastUploadedTask)
        // console.log("WITH NEW")
        // console.log(val)


        // DOES NOT WORK FOR NOTE.. AS A PERSON CAN ADD MULTIPLE NOTES IN ONE GO



        // if (lastUploadedTask[attrLiteral] !== val){
        if (matchingChangeIndex >= 0) {
            let matchingChange = changes[matchingChangeIndex]

            if (attr === Task_Dynamic_Changeables.note) {
                if (matchingChange.value !== val) {
                    changes.push({ attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() })
                }
            } else {

                if (moment(time).isAfter(moment(matchingChange.time))) {
                    console.log("CHANGE REPLACED")
                    changes[matchingChangeIndex] = { attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() }
                }
                // console.log("The time of the change ")
                // console.log(`ATTR-${attr} VAL-${val} TIME-${time}`)
                // console.log("COMPARED WITH EARLIER")
                // console.log(matchingChange)
            }


        } else {
            console.log("CHANGE ADDED")
            changes.push({ attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() })
        }

        // }else{
        //     //If this attribute was changed earlier but now is being reversed back without saving
        //     if (matchingChangeIndex >= 0){
        //         console.log("CHANGE REMOVED")
        //         delete changes[matchingChangeIndex]

        //     }
        // }


        setChangesMade((old) => {
            // console.log("NOW THE CHNAGE ARE")
            // console.log(changes)
            return changes
        })
    }




    const userRemovePressed = (from, usr) => {


        confirmAction("Are you sure you want to remove " + usr.name, () => {
            let tk = Task.copyFrom(task)

            let arr = []
            if (from === UserPickerFor.taskAssign) {
                arr = [...task.assignedToInfo]
            }
            if (from === UserPickerFor.taskShareWith) {
                arr = [...task.sharedWithInfo]
            }

            let ussrs = arr.filter((u) => {
                return u.id !== usr.id
            })

            if (from === UserPickerFor.taskAssign) {
                tk.assignedToInfo = ussrs
                tk.removeAssignTo(usr.id, usr, (err) => {
                    if (err) {
                        alert("Users could not be removed for AssignTo ")
                        console.log(err)
                    }
                })
            }
            if (from === UserPickerFor.taskShareWith) {
                tk.sharedWithInfo = ussrs
                tk.removeSharedWith(usr.id, usr, (err) => {
                    if (err) {
                        alert("Users could not be removed for SharedWith ")
                        console.log(err)
                    }
                })
            }

            if (from === UserPickerFor.taskAssign) {
                let userNames = tk.assignedToInfo.map(u => u.name)
                // console.log("CHANGING SHARED WITH USERS TO")
                // console.log("changed to " + userNames.join(","))

                entityChanged(Task.attributeForChangeable(Task_Dynamic_Changeables.AssignedToUsers), Task_Dynamic_Changeables.AssignedToUsers, CRUDEvent.updated, arr, userNames.join(","), new Date())

            }

            if (from === UserPickerFor.taskShareWith) {
                let userNames = tk.sharedWithInfo.map(u => u.name)
                // console.log("CHANGING SHARED WITH USERS TO")
                // console.log("changed to " + userNames.join(","))

                entityChanged(Task.attributeForChangeable(Task_Dynamic_Changeables.SharedWithUsers), Task_Dynamic_Changeables.SharedWithUsers, CRUDEvent.updated, arr, userNames.join(","), new Date())
            }

            setTask(t => tk)
        }, null)



    }

    // title
    // description

    // asset
    // content


    const clearSelections = () => {

        if (showUserPickerFor === UserPickerFor.taskAssign) {
            let ev = Task.copyFrom(task)
            let userNames = ev.assignedToInfo.map(u => u.name)
            // console.log("CHANGING SHARED WITH USERS TO")
            // console.log("changed to " + userNames.join(","))
            entityChanged(Task.attributeForChangeable(Task_Dynamic_Changeables.AssignedToUsers), Task_Dynamic_Changeables.AssignedToUsers, CRUDEvent.updated, ev.assignedToInfo, userNames.join(","), new Date())
        }

        if (showUserPickerFor === UserPickerFor.taskShareWith) {
            let ev = Task.copyFrom(task)
            let userNames = ev.sharedWithInfo.map(u => u.name)
            // console.log("CHANGING SHARED WITH USERS TO")
            // console.log("changed to " + userNames.join(","))
            entityChanged(Task.attributeForChangeable(Task_Dynamic_Changeables.SharedWithUsers), Task_Dynamic_Changeables.SharedWithUsers, CRUDEvent.updated, ev.sharedWithInfo, userNames.join(","), new Date())
        }


        setShowUserPickerFor(null)
        setShowProjAssComms(false)
        setAddOrDeleteNote(null)
    }


    const addNotePressed = () => {
        let blankNote = Task.copyFrom(task).createNote(currentUserInfo(true), "")
        setAddOrDeleteNote({ note: blankNote, crud: CRUDAction.create })
        // alert("Add Note pressed")
    }



    const noteDeletePressed = () => {
        let note = { ...addOrDeleteNote.note }

        // console.log("NOTE IS")
        // console.log(note)

        let noteByUserId = note.byUserInfo.id ?? ""
        let cu = currentUserInfo()

        console.log(`noteByUserId = ${noteByUserId}`)
        console.log(`CU.ID = ${cu.id}`)

        if (noteByUserId === cu.id) {
            console.log("Deleting note")

            console.log(note)


            let tk = Task.copyFrom(task)
            if (note.id) {
                console.log("Deleting note now")
                // saved Task
                tk.removeNote(note, (err) => {
                    if (err) {
                        alert("Note could not be removed" + err)
                    }
                })

                let valDesc = note.note
                if (note.note.length > 50) {
                    valDesc = note.note.substring(0, 49) + "..."
                }
                entityChanged(Task.attributeForChangeable(Task_Dynamic_Changeables.Note), Task_Dynamic_Changeables.Note, CRUDEvent.deleted, note, valDesc, new Date())


            } else {
                console.log("REMOVING NOTE JUST FROM UI")
            }

            let nts = [...tk.notes].filter(t => t.id !== note.id)

            tk.notes = nts
            setAddOrDeleteNote(null)
            setTask(tk)
        } else {
            alert("Note could not be deleted. It can only be deleted by the creator of the note")
        }
    }



    const noteSelected = (note) => {
        let noteByUserId = note.byUserInfo.id ?? ""
        let cu = currentUserInfo()
        if (noteByUserId === cu.id) {
            setAddOrDeleteNote({ note: note, crud: CRUDAction.read })
        }

    }

    const textChangeDone = (val, payload, crud) => {

        if (!val || crud !== CRUDAction.create) {
            setAddOrDeleteNote(null)
            return
        }


        let tk = Task.copyFrom(task)
        let nts = [...tk.notes]

        // if (id){
        //     let thisNoteIndex = nts.findIndex((n) => n.id === id)
        //     if (thisNoteIndex >= 0){
        //         let thisTk = {...nts[thisNoteIndex]}
        //         thisTk.note = val
        //         nts[thisNoteIndex] = thisTk
        //     }
        // }else{


        // console.log("NOTE PAYLOAD IS")
        // console.log(payload)

        // console.log("NOTE CRUD IS")
        // console.log(crud)

        // console.log("VAL IS")
        // console.log(val)




        let nt = tk.createNote(currentUserInfo(), val)
        if (tk.id) {
            // saved Task
            tk.addNote(nt, (err) => {
                if (err) {
                    alert("Note could not be added" + err)
                }
            })

            if (nt !== "") {
                let valDesc = val
                if (val.length > 50) {
                    valDesc = val.substring(0, 49) + "..."
                }
                entityChanged(Task.attributeForChangeable(Task_Dynamic_Changeables.Note), Task_Dynamic_Changeables.Note, CRUDEvent.added, val, valDesc, new Date())
            }

        } else {
            console.log("ADDING TASK TO NOTE")
        }

        nts.push(nt)
        tk.notes = nts


        console.log("TASK AFTER NEW NOTE ADDED")
        console.log(tk)



        // }
        setTask(tk)
        setAddOrDeleteNote(null)
    }


    const onDateChange = (name, value, isCleared) => {
        // console.log("On date change called")
        // console.log(`Name is ${name}`)
        // console.log(value)

        let crudType = CRUDEvent.updated


        let tk = Task.copyFrom(task)
        if (name === "plannedOn") {
            if (isCleared) {
                tk.plannedOn = null
                crudType = CRUDEvent.deleted
            } else {
                if (tk.plannedOn === null) {
                    crudType = CRUDEvent.added
                }
                tk.plannedOn = value
            }
            entityChanged("plannedOn", "PlannedOn", crudType, value, isCleared ? null : completeDateTime(value), new Date())
        }

        if (name === "estimatedDate") {
            if (isCleared) {
                tk.estimatedDate = null
                crudType = CRUDEvent.deleted
            } else {
                if (tk.estimatedDate === null) {
                    crudType = CRUDEvent.added
                }
                tk.estimatedDate = value
            }
            entityChanged("estimatedDate", "EstimatedDate", crudType, value, isCleared ? null : completeDateTime(value), new Date())

        }

        if (name === "deadline") {
            if (isCleared) {
                tk.deadline = null
                crudType = CRUDEvent.deleted
            } else {
                if (tk.deadline === null) {
                    crudType = CRUDEvent.added
                }
                tk.deadline = value
            }
            entityChanged("deadline", "Deadline", crudType, value, isCleared ? null : completeDateTime(value), new Date())

        }

        setTask(tk)




    }


    const platformToggled = (pl) => {
        let evs = Task.copyFrom(task)
        let chls = [...evs.channels]
        let crudType = CRUDEvent.added
        if (chls.length) {
            crudType = CRUDEvent.updated
        }

        if (chls.includes(pl)) {
            chls = chls.filter((p) => {
                return p !== pl
            })
        } else {
            chls.push(pl)
        }
        evs.channels = chls
        setTask(evs)
        entityChanged("channels", "Platforms", crudType, chls, chls.join(","), new Date())

    }


    const tagForPlatform = (pl, selected) => {
        let plat = null

        switch (pl) {

            case SocialMedia_Platform.linkedIn: plat = <AiFillLinkedin style={selected ? { color: socialMediaChannels_HexColors.linkedIn } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.facebook: plat = <AiFillFacebook style={selected ? { color: socialMediaChannels_HexColors.facebook } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.instagram: plat = <AiFillInstagram style={selected ? { color: socialMediaChannels_HexColors.instagram } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.twitter: plat = <AiOutlineTwitter style={selected ? { color: socialMediaChannels_HexColors.twitter } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.youtube: plat = <AiFillYoutube style={selected ? { color: socialMediaChannels_HexColors.youtube } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.tiktok: plat = <SiTiktok style={selected ? { color: socialMediaChannels_HexColors.tiktok } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.pinterest: plat = <FaPinterest style={selected ? { color: socialMediaChannels_HexColors.pinterest } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.medium: plat = <AiFillMediumSquare style={selected ? { color: socialMediaChannels_HexColors.medium } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            default: break;
        }
        return <Tag className=" is-size-5 ml-minus-10 mr-1 has-background-white  " onClick={() => { platformToggled(pl) }} > {plat} </Tag>
    }

    const allPlatformTags = (plats) => {

        // console.log("PLATS ARE")
        // console.log(plats)

        let tags = []

        Allowed_SocialMedia_Platforms.sort((p) => {
            return !plats.includes(p)
        })
        Allowed_SocialMedia_Platforms.map((p) => {
            tags.push(tagForPlatform(p, plats.includes(p)))
            return null
        })


        return tags
    }


    const setTaskTitle = (tl) => {
        let tk = { ...task }
        tk.title = tl
        setTask(tk)
    }


    const setTaskNameBlur = (tl) => {
        // console.log("Event name input is blurred with value " + tl)
        // let tk = { ...event }
        // tk.name = tl
        // setEvent(tk)
        // entityChanged("Name", "changed to " + tl, new Date())

        entityChanged("title", "Title", CRUDEvent.updated, tl, tl, new Date())
    }


    const taskTypeChanged = (tp) => {
        let tk = { ...task }
        tk.type = tp
        setTask(tk)

        entityChanged("type", "Type", CRUDEvent.updated, tp, tp, new Date())

    }

    const taskStatusChanged = (sts) => {
        let tk = { ...task }
        tk.status = sts
        if (sts === TaskStatus.complete) {
            const cu = isCurrentUser()
            tk.completedBy = userPointer(cu.id)
            tk.completedByInfo = currentUserInfo()
            tk.completedAt = new Date()
        } else {
            tk.completedBy = null
            tk.completedByInfo = null
            tk.completedAt = null
        }
        setTask(tk)
        entityChanged("status", "Status", CRUDEvent.updated, sts, sts, new Date())

    }

    const taskPriorityChanged = (pt) => {
        let tk = { ...task }
        tk.priority = pt
        setTask(tk)

        entityChanged("priority", "Priority", CRUDEvent.updated, pt, pt, new Date())

    }



    const toggleProjAssComms = () => {
        setShowProjAssComms(true)
    }

    const selectionsChanged = (attcts) => {
        // console.log("ATTACHMENTS ARE")
        // console.log(attcts)
        // let tk = {...task}
        // tk.asse
        setAttachments(attcts)


        let tk = Task.copyFrom(task)
        let { projects, comments, assets } = attcts

        tk.projects = projects ?? []
        tk.projectsIds = tk.projects.map(p => p.id)

        tk.comments = comments ?? []
        tk.commentsIds = tk.comments.map(c => c.id)

        tk.assets = assets ?? []
        tk.assetsIds = tk.assets.map(a => a.id)
        let imgUrls = []
        tk.assets.forEach(ast => {
            if (ast.type === 'image/png' && ast.url) {
                imgUrls.push(ast.url)
            }
        });
        tk.thumbImageUrls = imgUrls

        setTask(tk)

        entityChanged(Task.attributeForChangeable(Task_Dynamic_Changeables.ProjectsAssetsComments), Task_Dynamic_Changeables.ProjectsAssetsComments, CRUDEvent.updated, assets, ` ${projects.length} Projects, ${assets.length} Assets , ${comments.length} Comments`, new Date())


        clearSelections()

        // alert("ADD TASK ATTACHEMENTS")

    }

    const listProjects = (pjs) => {
        if (pjs.length) {
            return pjs.map(p =>
                <Box className='m-2 px-4 py-5'>
                    <p className='is-size-9 has-text-grey-light'>
                        PROJECT
                    </p>
                    <p className='is-size-7 '>
                        {p.name}
                    </p>
                </Box>)
        }
    }

    const listAssets = (asts) => {
        if (asts.length) {
            return asts.map(ast => <Box className='m-1 p-2'>

                <p className='is-size-9 has-text-grey-light'>ASSET
                </p>
                <p>
                    <Image size={64} src={ast.url ?? "https://images.unsplash.com/photo-1627482965115-e8e29bbcd451?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"} className=" ml-0 mt-1 overflow-hidden is-bordered border-radius-6 " />

                </p>
                {/* <p className=' is-size-9 text-wrap80px has-text-grey' > {ast.name} </p> */}

            </Box>
            )
        }
    }
    const listComments = (cmmts) => {
        if (cmmts.length) {
            return cmmts.map((opt) => {
                let annotationAttached = ""
                if (opt.annotations.length) {
                    if (opt.annotations.length > 1) {
                        annotationAttached = " (1 annotation)"
                    } else {
                        annotationAttached = " (" + opt.annotations.length + " annotations)"
                    }
                }
                return <Box className='m-1 p-2'>
                    <p className='is-size-9 has-text-grey-light'>COMMENT</p>

                    <Columns mobile={true}>

                        {
                            opt.assetUrl ?
                                <Columns.Column narrow>  <Image size={48} src={opt.assetUrl} className=" ml-0 mt-1 overflow-hidden is-bordered border-radius-6 " /></Columns.Column> :
                                null
                        }

                        <Columns.Column narrow className=' is-size-7' >
                            {opt.message + annotationAttached}

                            {/* <Columns className='m-0 p-0' mobile={true}>
                            <Columns.Column className='my-0 py-0' narrow>
                            </Columns.Column>
                            <Columns.Column className='' narrow>
                            </Columns.Column>
                        </Columns> */}

                        </Columns.Column>
                    </Columns>

                </Box>
            })
        }
    }




    const listAttachments = (allAttachments, toCount) => {
        const { projects, assets, comments } = allAttachments

        if (toCount) {
            // just checking if any attachments exist
            if (projects.length > 0 || assets.length > 0 || comments.length > 0) {
                return projects.length + assets.length + comments.length
            }
            return false
        }

        return <>
            {listProjects(projects)}
            {listAssets(assets)}
            {listComments(comments)}
        </>

    }



    const selectedUsersAsPerShowFor = () => {

        if (showUserPickerFor === UserPickerFor.taskAssign) {
            return task.assignedToInfo
        }

        if (showUserPickerFor === UserPickerFor.taskShareWith) {
            return task.sharedWithInfo
        }

        return null

    }


    const dropdownValueChanged = (val) => {

        if (val === "timeline") {
            setShowTimeline(true)
            return
        }

        let tk = Task.copyFrom(task)
        if (val === "markDone") {
            const cu = isCurrentUser()
            tk.status = TaskStatus.complete
            tk.completedBy = userPointer(cu.id)
            tk.completedByInfo = currentUserInfo()
            tk.completedAt = new Date()
            entityChanged("status", "Status", CRUDEvent.updated, TaskStatus.complete, TaskStatus.complete, new Date())

            updateTask(tk)
        }
        if (val === "archive") {

            confirmAction("Are you sure you want to archive this task. It cannot be undone.", () => {
                tk.isArchived = true
                const cu = isCurrentUser()
                tk.archivedBy = userPointer(cu.id)
                tk.archivedByInfo = currentUserInfo()
                updateTask(tk)
                entityChanged("isArchived", "Is Archived", CRUDEvent.deleted, "", "", new Date())
            },null)


        }
    }


    const modal = () => {

        let projAssCommsCount = listAttachments(attachments, true)

        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light height100vc lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" height100vc  m-0 p-0" radiusless>
                    <Modal.Card.Header showClose className=" ">
                        <Modal.Card.Title className="   " >
                            <Dropdown left renderAs="button" className="  has-text-left  ml-minus-10 mt-minus-10  is-small is-arrowless has-background-transparent  is-borderless " label={
                                <HiOutlineDotsHorizontal className="is-size-5 mt-2 has-text-grey" />
                            } onChange={(val) => { dropdownValueChanged(val) }} >
                                {/* <Dropdown.Item  renderAs="a" value="cancelOrder" className=" is-disabled has-text-grey-light ">Version 2 </Dropdown.Item> */}
                                <Dropdown.Item renderAs="a" value="timeline">Timeline</Dropdown.Item>
                                <Dropdown.Item renderAs="a" value="markDone"> Mark Done</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item renderAs="a" value="archive">Archive Task</Dropdown.Item>
                            </Dropdown>

                            <p className="has-text-grey-light is-size-7 has-text-centered"> {isPrivate(SelectableEntity.tasks, task) ? privateTag()  : null}  Added By {task.byUserInfo.name} </p>
                            
                            {/* {
                                task.status === TaskStatus.complete ?
                                    <p className="has-text-grey-light is-size-7 has-text-centered mb-0 mt-3 pb-0"> <span className='mt-15'><IoCheckmarkDoneOutline /></span>  Completed By {task.completedByInfo.name} on {moment(task.completedAt).format('DD-MMM-YYYY h:mm a')} </p>
                                    : null
                            } */}
                            {/* {
                                task.isArchived ?
                                    <p className="has-text-danger has-text-weight-semibold is-size-7 has-text-centered mb-0 mt-3 pb-0"> Archived By {task.archivedByInfo.name}</p>
                                    : null
                            } */}
                        </Modal.Card.Title>

                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0 m-0">

                        {showUserPickerFor ? <UserPicker for={showUserPickerFor} selectedUsers={selectedUsersAsPerShowFor()} usersAreSelected={usersAreSelected} close={clearSelections} /> : null}

                        {showProjAssComms ? <ModalExplorer selectables={{ entities: [SelectableEntity.projects, SelectableEntity.assets, SelectableEntity.comments], multiSelection: true }} toExplore={ProjectFolderLevel.project} selections={attachments} selectionsChanged={selectionsChanged} close={clearSelections} /> : null}

                        {addOrDeleteNote ? <TextEditor crud={addOrDeleteNote.crud} payload={addOrDeleteNote} text={addOrDeleteNote.note.note} footerLine={` By ${addOrDeleteNote.note.byUserInfo.name}  ${addOrDeleteNote.note.createdAt ? `· ${moment.unix(addOrDeleteNote.note.createdAt).format('DD-MMM-YYYY h:mm a')}` : ""}`} textChangeDone={textChangeDone} showDeleteOption={true} onDelete={noteDeletePressed} close={clearSelections} /> : null}

                        {showTimeline && task.id ? <TimelineExplorer entity={EntityType.task} entityId={task.id} close={() => { setShowTimeline(false) }} /> : null}


                        {
                            task.status === TaskStatus.complete ?
                                <Box radiusless borderless textColor='white' textWeight='semibold' className="has-background-success  is-size-7 has-text-centered py-2 my-0">Completed By {task.completedByInfo.name} on {moment(task.completedAt).format('DD-MMM-YYYY h:mm a')}</Box>
                                : null
                        }

                        {
                            task.isArchived ?
                                <Box radiusless borderless textColor='white' textWeight='semibold' className="has-background-danger  is-size-7 has-text-centered py-2 my-0">Cancelled By {task.archivedByInfo.name}</Box>
                                : null
                        }

                        {
                            // type - [create, update]
                            // title 
                            // comments 
                            // assets
                            // assignTo
                            // status 
                            // tags
                            // priority
                            // deadline 
                            // estimatedDate
                        }

                        {/* urgencyStatus
                            sharedWith
                            isArchived */}

                        {/* plannedOn
                            estimatedDate
                            deadline

                            urgencyStatus
                            tags

                            assignedTo
                            assignedToInfo

                            time

                            isShared
                            sharedWith
                            sharedWithInfo

                            isArchived
                            archivedBy
                            archivedByInfo

                            completedBy
                            completedByInfo

                            startedWorkingAt
                            completedAt */}



                        <Box shadowless className=" " >
                            <Columns className="is-mobile mt-4">
                                <Columns.Column narrow >
                                    <Form.Field>
                                        <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2 " textWeight="semibold"  >Type</Form.Label>
                                        <Form.Control>
                                            <Form.Select
                                                size='small'
                                                value={task.type}
                                                className=" mt-2 ml-2 "
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    taskTypeChanged(value)

                                                }}
                                            >
                                                {
                                                    TaskType_options.map((tp) => {
                                                        return <option value={tp}>{tp}</option>
                                                    })
                                                }
                                            </Form.Select>
                                            {/* <Icon align="left">
                                                <MdSchedule className="is-size-5 mt-4 ml-4" />
                                            </Icon> */}
                                        </Form.Control>
                                        <br />
                                    </Form.Field>

                                </Columns.Column>
                                <Columns.Column narrow >
                                    <Form.Field>
                                        <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2 " textWeight="semibold"  >Priority</Form.Label>
                                        <Form.Control>
                                            <Form.Select
                                                size='small'
                                                value={task.priority}
                                                className={" mt-2 ml-2 " + (task.priority === TaskPriority.high ? " is-warning " : " ") + (task.priority === TaskPriority.urgent ? " is-danger " : " ")}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    taskPriorityChanged(value)
                                                }}
                                            >
                                                {

                                                    TastPriority_options.map((tp) => {
                                                        return <option value={tp}>{tp}</option>
                                                    })
                                                }
                                            </Form.Select>
                                            {/* <Icon align="left">
                                                <MdSchedule className="is-size-5 mt-4 ml-4" />
                                            </Icon> */}
                                        </Form.Control>
                                        <br />
                                    </Form.Field>
                                </Columns.Column>
                                <Columns.Column narrow >
                                    <Form.Field>
                                        <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2 " textWeight="semibold"  >Status</Form.Label>
                                        <Form.Control>
                                            <Form.Select
                                                size='small'
                                                value={task.status}
                                                className=" mt-2 ml-2 "
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    taskStatusChanged(value)
                                                }}
                                            >
                                                {
                                                    TaskStatus_options.map((tp) => {
                                                        return <option value={tp}>{tp}</option>
                                                    })
                                                }
                                            </Form.Select>
                                            {/* <Icon align="left">
                                                <MdSchedule className="is-size-5 mt-4 ml-4" />
                                            </Icon> */}
                                        </Form.Control>
                                        <br />
                                    </Form.Field>
                                </Columns.Column>

                            </Columns>
                            <Form.Field >
                                <Form.Label className="is-size-7 mt-2  ml-2 " textWeight="semibold" >Task</Form.Label>
                                <Form.Textarea
                                    value={task.title ?? ""}
                                    placeholder="Type task here..."
                                    size={"normal"}
                                    className=" is-size-7 has-background-white-bis "
                                    onChange={(e) => {
                                        return setTaskTitle(e.target.value);
                                    }}
                                    onBlur={(e) => { setTaskNameBlur(e.target.value) }}

                                />
                            </Form.Field>
                            <br />


                            <Columns className="is-mobile mt-4">
                                <Columns.Column  >
                                    <Form.Field className="">
                                        <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2 " textWeight="semibold"  >Planned On</Form.Label>
                                        <Form.Control>
                                            {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                            <DatePicker
                                                name="createdAfter"
                                                className=" datePicker input is-shadowless bottomBorderOnly is-size-7 "
                                                placeholderText="DD/MM/YY"
                                                isClearable
                                                // selected={f}
                                                showTimeSelect={false}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                todayButton='Today'
                                                // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                dateFormat="dd/MMM/yyyy"
                                                selected={task.plannedOn}
                                                onChange={(value) => {
                                                    onDateChange("plannedOn", value);
                                                }}
                                                // onBlur={() => {
                                                //     onBlur(name, true);
                                                // }}
                                                onFocus={e => e.target.blur()}
                                            />
                                        </Form.Control>
                                    </Form.Field>
                                </Columns.Column>
                                <Columns.Column  >
                                    <Form.Field className="ml-2">
                                        <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2 " textWeight="semibold" >Estimated On</Form.Label>
                                        <Form.Control>
                                            {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                            <DatePicker
                                                name="createdAfter"
                                                className=" datePicker input is-shadowless bottomBorderOnly is-size-7 "
                                                placeholderText="DD/MM/YY"
                                                isClearable
                                                // selected={f}
                                                showTimeSelect={false}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                todayButton='Today'
                                                // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                dateFormat="dd/MMM/yyyy"
                                                selected={task.estimatedDate}
                                                onChange={(value) => {
                                                    onDateChange("estimatedDate", value);
                                                }}
                                                // onBlur={() => {
                                                //     onBlur(name, true);
                                                // }}
                                                onFocus={e => e.target.blur()}
                                            />
                                        </Form.Control>
                                    </Form.Field>
                                </Columns.Column>
                                <Columns.Column  >
                                    <Form.Field className="ml-2">
                                        <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2 " textWeight="semibold"  >Deadline</Form.Label>
                                        <Form.Control>
                                            {/* <div className='datePicker has-text-weight-semibold type65 input'> */}
                                            <DatePicker
                                                name="createdAfter"
                                                className=" datePicker input is-shadowless bottomBorderOnly is-size-7 "
                                                placeholderText="DD/MM/YY"
                                                isClearable
                                                popperPlacement='bottom-end'
                                                // selected={f}
                                                showTimeSelect={false}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                todayButton='Today'
                                                // dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                dateFormat="dd/MMM/yyyy"
                                                selected={task.deadline}
                                                onChange={(value) => {
                                                    onDateChange("deadline", value);
                                                }}
                                                // onBlur={() => {
                                                //     onBlur(name, true);
                                                // }}
                                                onFocus={e => e.target.blur()}
                                            />
                                        </Form.Control>
                                    </Form.Field>
                                </Columns.Column>
                            </Columns>
                            {/* <Columns className="is-mobile">
                                <Columns.Column size={9}> */}
                            <br />

                            <Form.Field onClick={() => { toggleProjAssComms() }} className="" >
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 "  > For Project, Assets, Comments <span className=' has-text-grey-light mx-1'>{(projAssCommsCount > 0) ? `(${projAssCommsCount})` : null}</span>
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className=" has-background-white-bis p-0 is-borderless is-hover-dark ml-2 "  > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                                    {/* <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.taskAddedBy) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button> */}
                                </Form.Label>

                                <Box shadowless className={" px-0 pt-0 maxH300  overflow-scroll mt-0 " + ((projAssCommsCount > 0) ? " has-background-white-bis " : null)} >
                                    <Tag.Group className="mt-0" >
                                        {/* {
                                            filter.addedBy.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(su, UserPickerFor.taskAddedBy)} infoObj={su} nameWrap={true} />
                                            })
                                        } */}
                                        {attachments ? listAttachments(attachments) : null}
                                    </Tag.Group>
                                </Box>
                            </Form.Field>
                            <br />

                            <Form.Field className=' mt-6 '>
                                <Form.Label className="is-size-7  mb-2 ml-2 " textWeight="semibold" onClick={addNotePressed} >Notes <span className=' has-text-grey-light mx-1'>{(task.notes.length > 0) ? `(${task.notes.length})` : null}</span>
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 "  > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                                </Form.Label>

                                <Box shadowless className={" px-0 pt-0 maxH300  overflow-scroll mt-0 " + (task.notes.length ? " has-background-white-bis " : null)} >
                                    {
                                        task.notes.length ?

                                            task.notes.map((t) =>
                                                <Message key={t.id} size={"small"} color="light" className="  pb-0 mb-0" onClick={() => noteSelected(t)} >
                                                    <Message.Body >
                                                        {t.note}
                                                        <p className='mt-2 has-text-grey-light m-0'> By {t.byUserInfo.name}  {t.createdAt ? `· ${moment.unix(t.createdAt).format('DD-MMM-YYYY h:mm a')}` : null}  </p>
                                                    </Message.Body>
                                                </Message>
                                            )
                                            :
                                            null
                                    }
                                </Box>
                            </Form.Field>
                            <br />

                            <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Assigned To
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.taskAssign) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                                    {/* <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.taskAssign) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button> */}

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            task.assignedToInfo.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(UserPickerFor.taskAssign, su)} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>

                            <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Shared with
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.taskShareWith) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>
                                    {/* <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.taskAssign) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button> */}

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            task.sharedWithInfo.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => userRemovePressed(UserPickerFor.taskShareWith, su)} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>



                            <br />
                        </Box>


                    </Modal.Card.Body>
                    <Modal.Card.Footer align="right" className="py-2 has-background-white is-borderless " >
                        {/* <Box shadowless> */}
                        <Button disabled={isSaving} loading={isSaving} color={changesMade.length ? "black" : "light"} className="  " fullwidth onClick={saveTask} > Save </Button>
                        {/* </Box> */}
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }


    return modal()



}

export default TaskModal