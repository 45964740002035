import React, { useRef, useState } from 'react'
import { Form, Card, Heading, Media, Content, Tabs, Box, Image, Tag, Button, Dropdown, Modal, Panel, Columns, Hero, Container, Message, Level, Section, Navbar } from 'react-bulma-components';
import { previewBoxType, SocialMedia_PostType, AssetCaptionType, SocialMedia_Platform } from '../../Models/EnumsAndPointers';

import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
// import sample_Li_Company_Bg from './sampleAssets/li-companyPage-bg.jpeg'
import Default_BG from './sampleAssets/Default-BG.png'
import Default_DP from './sampleAssets/Default-DP.png'
import Default_Li_Blank_User from './sampleAssets/Default-Li-Blank-User.svg'
import { randomUsers, rowOfColumns_leftImage } from './VisualiseModal';

import Default_Li_Blank_Company from './sampleAssets/Default-Li-Blank-Company.svg'
import Default_Li_Blank_Cover from './sampleAssets/Default-Li-Blank-Cover.svg'
import Li_createevent_icon from './sampleAssets/li_createevent_icon.png'
import Li_adImage from './sampleAssets/li_adImage.png'
import Li_recentIcon from './sampleAssets/li-recentIcon.svg'
import Li_hashtagIcon from './sampleAssets/li-hashtagIcon.svg'



import calendarIcon from './sampleAssets/calendarIcon.svg'
import videoIcon from './sampleAssets/videoIcon.svg'
import avatarsImage from './sampleAssets/avatars.png'
import locationIcon from './sampleAssets/location.svg'
import calendarTWIcon from './sampleAssets/calendar.svg'
import linkTWIcon from './sampleAssets/link.svg'
import twitterAvatars from './sampleAssets/twitter-avatars.png'

import billgates_dp from './sampleAssets/billgates_dp.jpeg'
import satyanadella_dp from './sampleAssets/satyanadella_dp.jpeg'
import reedhastings_dp from './sampleAssets/reedhastings_dp.jpeg'

import AppleLogo_Square from './sampleAssets/AppleLogo_Square.jpeg'
import MicrosoftLogo_Square from './sampleAssets/MicrosoftLogo_Square.jpeg'
import GoogleLogo_Square from './sampleAssets/GoogleLogo_Square.jpeg'


import liGlobeIcon from './sampleAssets/li-globe.svg'
import liEventLinkIcon from './sampleAssets/li-event-link.svg'
import liLikeMiniIcon from './sampleAssets/li-like-mini.svg'
import liClapMiniIcon from './sampleAssets/li-clap-mini.svg'
import liLoveMiniIcon from './sampleAssets/li-love-mini.svg'
import liLike from './sampleAssets/li-like.svg'
import liComment from './sampleAssets/li-comment.svg'
import liShare from './sampleAssets/li-share.svg'
import liSend from './sampleAssets/li-send.svg'
import liDotMenu from './sampleAssets/li-dotmenu.svg'
//Li Nav Icons
import li_home_icon from './sampleAssets/li_home_icon.png'
import li_jobs_icon from './sampleAssets/li_jobs_icon.png'
import li_logo_icon from './sampleAssets/li_logo_icon.png'
import li_messages_icon from './sampleAssets/li_messages_icon.png'
import li_myNetwork_icon from './sampleAssets/li_myNetwork_icon.png'
import li_notif_icon from './sampleAssets/li_notif_icon.png'
import li_work_icon from './sampleAssets/li_work_icon.png'

import { GrAdd } from 'react-icons/gr';
import { FaSearch } from 'react-icons/fa';


export const liNav = () => {
    return <Box shadowless radiusless className='m-0 p-0 is-bordered stick-to-top z-55 has-background-white' >
        <div onClick={(e) => { e.stopPropagation(); }}>

            <Columns className='m-0 p-0'>
                <Columns.Column className='m-0 p-0'>
                </Columns.Column>
                <Columns.Column className='m-0 p-0 px-5' size={10} >
                    <Columns className='m-0 p-0' vCentered>
                        <Columns.Column className='m-0 p-0' size={5}>
                            <div className=" is-flex is-flex-direction-row is-mobile has-text-left py-2 " >
                                {/* <Level.Item className='m-0 p-0'> */}
                                <img src={li_logo_icon} alt="li_icon" className="is-clipped square-40 mr-2 " />
                                {/* </Level.Item>
                            <Level.Item className='m-0 p-0'> */}
                                <Form.Field className=' has-text-left mt-1  ' >
                                    <Form.Control className=' has-icons-left '>
                                        <span class="icon  is-left">
                                            <FaSearch color='grey' className=' is-size-6p6 mt-minus-8 mr-3 ml-2 ' />
                                        </span>
                                        <Form.Input
                                            // color="success"
                                            size="small"
                                            placeholder="Search"
                                            textColor='grey'
                                            // color="danger"
                                            className="border-radius-6 has-background-grey-lightest has-text-weight-medium is-borderless is-size-6p8 py-4 minW300 maxW300 "
                                        />
                                    </Form.Control>
                                </Form.Field>
                                {/* </Level.Item> */}
                            </div>
                        </Columns.Column>
                        <Columns.Column className='m-0 p-0' >
                            <Level className="is-mobile has-text-centered " >
                                <Level.Item className='m-0 p-0 is-flex-direction-column'><img src={li_home_icon} alt="li_icon" className="is-clipped square-24" />           <p className='is-size-7 has-text-center'>Home</p></Level.Item>
                                <Level.Item className='m-0 p-0 is-flex-direction-column'><img src={li_myNetwork_icon} alt="li_icon" className="is-clipped square-24" />      <p className='is-size-7 has-text-center has-text-grey '>My Network</p></Level.Item>
                                <Level.Item className='m-0 p-0 is-flex-direction-column'><img src={li_jobs_icon} alt="li_icon" className="is-clipped square-24 opacity60" />  <p className='is-size-7 has-text-center has-text-grey '>Jobs</p></Level.Item>
                                <Level.Item className='m-0 p-0 is-flex-direction-column'><img src={li_messages_icon} alt="li_icon" className="is-clipped square-24 " />      <p className='is-size-7 has-text-center has-text-grey '>Messaging</p></Level.Item>
                                <Level.Item className='m-0 p-0 is-flex-direction-column'><img src={li_notif_icon} alt="li_icon" className="is-clipped square-24 opacity60" /> <p className='is-size-7 has-text-center has-text-grey '>Notifications</p></Level.Item>
                                <Level.Item className='m-0 p-0 pr-2 is-flex-direction-column'><img src={Default_DP} rounded alt="li_icon" className="is-clipped square-24 image-rounded is-bordered" /><p className='is-size-7 has-text-center has-text-grey'>Me</p></Level.Item>
                                <Level.Item className='m-0 p-0 pr-1 pl-2 is-flex-direction-column is-bordered-left'><img src={li_work_icon} alt="li_icon" className="is-clipped square-24 opacity75" /><p className='is-size-7 has-text-center has-text-grey'>Work</p></Level.Item>
                                <Level.Item className='m-0 p-0 is-flex-direction-column is-size-7 has-text-center has-text-grey'>Get 50% Off <p className=' '>Sales Nav</p> </Level.Item>

                            </Level>
                        </Columns.Column>
                    </Columns>
                </Columns.Column>
                <Columns.Column className='m-0 p-0'>
                </Columns.Column>
            </Columns>
        </div>
    </Box>
}

export const li_eventBox = (dpImage, bgImage, eventName, eventBy, eventTime, attendeeLine) => {
    return (
        <Columns className=' mt-2 '>
            <Columns.Column>
                <Card className="linkedinBox-782w has-background-transparent overflow-hidden is-centered is-h-center " radiusless >
                    <Card.Image
                        size='16/9'
                        src={bgImage ?? Default_BG}
                        className="figure li-eventPage-bg-height overflow-hidden "
                    />
                    <Card.Content className="has-background-transparent">
                        <Media className="mb-4">
                            <Media.Item className=" z-35 " align="left" >
                                <Image
                                    src={dpImage ?? Default_DP}
                                    backgroundColor="black"
                                    className="li-eventPage-dp-size is-clipped"
                                />
                            </Media.Item>
                        </Media>
                        <Columns>
                            <Columns.Column>
                                <Heading className="mt-0 mb-0 li-companyPage-title" size={4}>{eventName ?? "User Name"}</Heading>
                                <p className="mt-0 li-companyPage-subtitle" >Event by <span className="has-text-grey has-text-weight-semibold">{eventBy ?? "Bio of user"}</span></p>
                                <p className="mt-3 li-companyPage-catLocAndFollowers alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-3" align="left" >
                                            <Image
                                                size={18}
                                                src={videoIcon}
                                                className=" figure has-text-grey-light"
                                            />
                                        </Media.Item>
                                        <span className="marginTop2 ml-0 pl-0">Online</span>
                                    </Media>
                                </p>
                                <p className="mt-2 li-companyPage-catLocAndFollowers alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-3" align="left" >
                                            <Image
                                                size={18}
                                                src={calendarIcon}
                                                className=" figure has-text-grey-light"
                                            />
                                        </Media.Item>
                                        <span className="marginTop2 ml-0 pl-0">{eventTime ?? "Jun 16, 2021, 9:15 PM - 11:30 PM (your local time)"}</span>
                                    </Media>
                                </p>
                                <p className="mt-2 li-companyPage-catLocAndFollowers alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-3" align="left" >
                                            <Image
                                                size={18}
                                                src={liEventLinkIcon}
                                                className=" figure has-text-grey-light"
                                            />
                                        </Media.Item>
                                        <span className="marginTop2 ml-0 pl-0"><span className='has-text-weight-medium has-text-grey'> Event link • </span>https://www.linkedin.com/samplevideo/live/urn:638763738687637/</span>
                                    </Media>
                                </p>

                                <p className="mt-2 li-companyPage-catLocAndFollowers alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-1" align="left" >
                                            <Image
                                                src={avatarsImage}
                                                className="li-eventAvatarImages-size figure has-text-grey-light"
                                            />
                                        </Media.Item>
                                        <span className="mt-3 ml-0 pl-0">{attendeeLine ?? "Adam Adamson and 1,268 other attendees"}</span>
                                    </Media>
                                </p>
                                <Button rounded color="link mt-4 mb-4 mr-3 mt-2">Attend Event</Button>
                                <Button rounded color="link mt-4 mb-4 mr-3 mt-2" outlined>Share</Button>
                                <Button className="mt-4 ml-0" outlined rounded color="grey">More</Button>
                            </Columns.Column>
                        </Columns>
                    </Card.Content>
                </Card>
                {li_aboutBox()}
            </Columns.Column>
            <Columns.Column>
                {li_createEventAd()}
            </Columns.Column>
        </Columns>

    )
}

export const li_eventBox_mobile = (dpImage, bgImage, eventName, eventBy, eventTime, attendeeLine) => {
    return (
        <>
            <Card className="overflow-hidden has-background-transparent is-centered is-h-center " radiusless >
                <Card.Image
                    size='16/9'
                    src={bgImage ?? Default_BG}
                    className="figure li-eventPage-bg-height-mobile overflow-hidden mt-4"
                />
                <Card.Content className="has-background-transparent px-3">
                    <Media className="mb-4">
                        <Media.Item className=" z-35 " align="left" >
                            <Image
                                src={dpImage ?? Default_DP}
                                backgroundColor="black"
                                className="li-eventPage-dp-size-mobile is-clipped"
                            />
                        </Media.Item>
                    </Media>
                    <Columns>
                        <Columns.Column>
                            <Heading className="mt-0 mb-0 li-companyPage-title-mobile" size={6}>{eventName ?? "User Name"}</Heading>
                            <p className="mt-0 li-companyPage-subtitle-mobile" >Event by <span className="has-text-grey has-text-weight-semibold">{eventBy ?? "Bio of user"}</span></p>

                            <div className=' is-size-7'>

                                <p className="mt-3  alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-3" align="left" >
                                            <Image
                                                size={16}
                                                src={videoIcon}
                                                className=" figure has-text-grey-light mt-1"
                                            />
                                        </Media.Item>
                                        <span className="marginTop2 ml-0 pl-0">Online</span>
                                    </Media>
                                </p>
                                <p className="mt-2  alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-3" align="left" >
                                            <Image
                                                size={16}
                                                src={calendarIcon}
                                                className=" figure has-text-grey-light mt-1"
                                            />
                                        </Media.Item>
                                        <span className="marginTop2 ml-0 pl-0">{eventTime ?? "Jun 16, 2021, 9:15 PM - 11:30 PM (your local time)"}</span>
                                    </Media>
                                </p>
                                <p className="mt-2  alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-3" align="left" >
                                            <Image
                                                size={16}
                                                src={liEventLinkIcon}
                                                className=" figure has-text-grey-light mt-1"
                                            />
                                        </Media.Item>
                                        <span className="marginTop2 ml-0 pl-0"><span className='has-text-weight-medium has-text-grey'> Event link • </span>https://www.linkedin.com/urn:638763738687637/</span>
                                    </Media>
                                </p>

                                <p className="mt-2 li- alignItems-center" textColor="black">
                                    <Media className="">
                                        <Media.Item className=" has-text-grey-light z-35 has-background-transparent mr-1" align="left" >
                                            <Image
                                                src={avatarsImage}
                                                className="li-eventAvatarImages-size-mobile figure has-text-grey-light mt-1"
                                            />
                                        </Media.Item>
                                        <span className="mt-3 ml-0 pl-0">{attendeeLine ?? "Adam Adamson and 1,268 other attendees"}</span>
                                    </Media>
                                </p>
                            </div>

                            <Columns gap={1} mobile={true} className="mt-2">
                                <Columns.Column>
                                    <Button size="small" fullwidth rounded color="link ">Attend Event</Button>
                                </Columns.Column>
                                <Columns.Column narrow>
                                    <Button size="small" rounded color="link " outlined>Share</Button>
                                </Columns.Column>
                                <Columns.Column narrow>
                                    <Button size="small" className="" outlined rounded color="grey">•••</Button>
                                </Columns.Column>
                            </Columns>
                        </Columns.Column>
                    </Columns>
                </Card.Content>
            </Card>
            {li_aboutBox(true)}
        </>

    )
}

export const li_profileBox = (dpImage, bgImage, userName, userBio, location, followerCount, companyLogoImage, companyName, universityLogoImage, universityName) => {
    return (
        <Columns className=' mt-2 '>
            <Columns.Column>
                <Card className="linkedinBox-782w overflow-hidden has-background-transparent is-centered is-h-center " radiusless >
                    <Card.Image
                        size='16/9'
                        src={bgImage ?? Default_BG}
                        className="figure li-profilePage-bg-height overflow-hidden "
                    />
                    <Card.Content className="has-background-transparent">
                        <Media className="mb-4">
                            <Media.Item className=" z-35 " align="left" >
                                <Image
                                    src={dpImage ?? Default_DP}
                                    backgroundColor="black"
                                    className="li-profilePage-dp-size is-clipped"
                                    rounded
                                />
                            </Media.Item>
                        </Media>

                        <Columns>
                            <Columns.Column>
                                <Heading className="mt-0 mb-0 li-companyPage-title" size={4}>{userName ?? "User Name"}</Heading>
                                <p className="mt-0 li-companyPage-subtitle" >{userBio ?? "Bio of user"}</p>
                                <p className="mt-1 li-companyPage-catLocAndFollowers" textColor="black">{location ?? "New York, NY"} · <span className="has-text-link has-text-weight-semibold">Contact Info</span></p>

                                <p className="mt-4 has-text-grey companyPage-catLocAndFollowers" ><span className="has-text-weight-medium">{followerCount ?? "500"}+</span> followers</p>
                                <Button rounded color="link mt-4 mb-4 mr-2 mt-2">Message</Button>
                                <Button className="mt-4 ml-0" outlined rounded color="grey">More</Button>
                            </Columns.Column>
                            <Columns.Column size={4} className="" aria-multiline={false}>
                                <p>
                                    <Media className="mb-2">
                                        <Media.Item className=" z-35 has-background-transparent mr-2" align="left" >
                                            <Image
                                                src={companyLogoImage ?? Default_Li_Blank_Company}
                                                className=" has-background-transparent mr-0"
                                                size={32}
                                            />
                                        </Media.Item>
                                        <p subtitle className="mt-2 ml-0 mb-0 has-text-weight-bold is-size-7" >{companyName ?? "TestPost"}</p>
                                    </Media>
                                </p>
                                <p>
                                    <Media className="mb-2">
                                        <Media.Item className=" z-35 has-background-transparent mr-2" align="left" >
                                            <Image
                                                src={universityLogoImage ?? Default_Li_Blank_Company}
                                                className=" has-background-transparent mr-0"
                                                size={32}
                                            />
                                        </Media.Item>
                                        <p subtitle className="mt-2 ml-0 mb-0 has-text-weight-bold is-size-7" >{universityName ?? "London Business School"}</p>
                                    </Media>
                                </p>
                            </Columns.Column>
                        </Columns>
                    </Card.Content>
                </Card>
                {li_aboutBox()}
            </Columns.Column>
            <Columns.Column>
                {li_adBox(companyLogoImage, companyName)}
                {li_alsoViewed_users_box()}
            </Columns.Column>
        </Columns>


    )
}

export const li_profileBox_mobile = (dpImage, bgImage, userName, userBio, location, followerCount, companyLogoImage, companyName, universityLogoImage, universityName) => {
    return (<>
        <Card className="linkedinBox-782w has-background-transparent overflow-hidden is-centered is-h-center " radiusless >
            <Card.Image
                size='16/9'
                src={bgImage ?? Default_BG}
                className="figure li-profilePage-bg-height-mobile overflow-hidden mt-5 "
            />
            <Card.Content className="has-background-transparent px-3">
                <Media className="mb-2">
                    <Media.Item className=" z-35 " align="left" >
                        <Image
                            src={dpImage ?? Default_DP}
                            backgroundColor="black"
                            className="li-profilePage-dp-size-mobile is-clipped"
                            rounded
                        />
                    </Media.Item>
                </Media>


                <Heading className="mt-0 mb-0 li-companyPage-title" size={5}>{userName ?? "User Name"}</Heading>
                <p className="mt-0 li-companyPage-subtitle-mobile" >{userBio ?? "Bio of user"}</p>
                <p className="mt-1 li-companyPage-catLocAndFollowers-mobile" textColor="black">{location ?? "New York, NY"} · <span className="has-text-link has-text-weight-semibold">Contact Info</span></p>

                <p className="mt-2 has-text-grey li-companyPage-catLocAndFollowers-mobile" ><span className="has-text-weight-medium">{followerCount ?? "500"}+</span> followers</p>
                <Columns>
                    <Columns.Column>
                        <Button size={"small"} fullwidth rounded color="link mt-4 mb-4 mr-2 mt-2">Message</Button>
                    </Columns.Column>
                    <Columns.Column narrow>
                        <Button size={"small"} className="mt-4 ml-0" outlined rounded color="grey">•••</Button>
                    </Columns.Column>
                </Columns>

                {/* <p>
                    <Media className="mb-2">
                        <Media.Item className=" z-35 has-background-transparent mr-2" align="left" >
                            <Image
                                src={companyLogoImage ?? Default_Li_Blank_Company}
                                className=" has-background-transparent mr-0"
                                size={32}
                            />
                        </Media.Item>
                        <p subtitle className="mt-2 ml-0 mb-0 has-text-weight-bold is-size-7" >{companyName ?? "TestPost"}</p>
                    </Media>
                </p>
                <p>
                    <Media className="mb-2">
                        <Media.Item className=" z-35 has-background-transparent mr-2" align="left" >
                            <Image
                                src={universityLogoImage ?? Default_Li_Blank_Company}
                                className=" has-background-transparent mr-0"
                                size={32}
                            />
                        </Media.Item>
                        <p subtitle className="mt-2 ml-0 mb-0 has-text-weight-bold is-size-7" >{universityName ?? "London Business School"}</p>
                    </Media>
                </p> */}

            </Card.Content>
        </Card>
        {li_aboutBox(true)}

    </>

    )
}


export const li_aboutBox = (isMobile) => {

    let cnSize = isMobile ? "is-size-7" : "is-size-6p4"



    return <Box className=' has-background-transparent my-5'>
        <Heading className="" size={isMobile ? 6 : 5} spaced >About</Heading>
        <p className={` ${cnSize} has-text-grey-dark" `}>Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Vivamus sagittis lacus vel augue laoreet rutrum faucibus. Praeterea iter est quasdam res quas ex communi. Cum ceteris in veneratione tui montes, nascetur mus. Integer legentibus erat a ante historiarum dapibus.</p>
        <p className={` ${cnSize} has-text-grey-dark mt-4 `}>Gallia est omnis divisa in partes tres, quarum. Contra legem facit qui id facit quod lex prohibet. Unam incolunt Belgae, aliam Aquitani, tertiam.</p>
        <p className={` ${cnSize} has-text-grey-dark mt-4 `}>Nihil hic munitissimus habendi senatus locus, nihil horum? Prima luce, cum quibus mons aliud consensu ab eo.</p>
    </Box>
}

export const li_createEventAd = () => {
    return <Box className='m-0 p-1 has-background-transparent'>
        <Columns className=' m-0 p-0'>
            <Columns.Column narrow className='m-0 p-0'>
                <Image
                    alt="profile"
                    size={48}
                    src={Li_createevent_icon}
                    className=" is-clipped has-bacground-transparent mx-0 mt-3 ml-2  "
                />
            </Columns.Column>
            <Columns.Column className='m-0'>
                <p className='is-size-6p4 has-text-weight-medium'> Host an event on LinkedIn and invite your network </p>
                {/* <p className=' is-size-7 has-text-weight-light has-text-grey ' > {followers} Followers </p> */}
                <Button className=" mt-2 is-size-6 has-text-grey py-2 " outlined rounded >Create Event</Button>
            </Columns.Column>
        </Columns>
    </Box>
}

export const li_companyBox = (dpImage, bgImage, companyName, companyDescription, category, location, followers, employeeCount) => {
    return (<Columns className=' mt-2 '>
        <Columns.Column>
            <Card className="linkedinBox-782w has-background-transparent overflow-hidden is-centered is-h-center "  >
                <Card.Image
                    size='16/9'
                    src={bgImage ?? Default_Li_Blank_Cover}
                    className="figure li-companyPage-bg-height overflow-hidden "
                />
                <Card.Content className="has-background-transparent">
                    <Media className="mb-1">
                        <Media.Item className="mt-minus-84 z-35 " align="left" >
                            <Image
                                alt="profile"
                                src={dpImage ?? Default_Li_Blank_Company}
                                backgroundColor="black"
                                className="li-companyPage-dp-size object-fit-cover is-clipped"
                            />
                        </Media.Item>
                    </Media>
                    <Heading className="mt-0 mb-0 li-companyPage-title" size={4}>{companyName ?? "TestPost"}</Heading>
                    <p className="mt-0 li-companyPage-subtitle" >{companyDescription ?? "Make creative testing and approval humanly easy and quick"}</p>
                    <p className="mt-0 li-companyPage-catLocAndFollowers" textColor="black">{category ?? "Internet"} · {location ?? "New York, NY"} · {followers ?? "151,359"} followers</p>

                    <p className="mt-4  has-text-weight-medium" >See all {employeeCount ?? "1,034"} employees on LinkedIn</p>
                    <Button rounded color="link mt-4 mb-4 mr-2 mt-2">Follow</Button>
                    <Button className="mt-4 ml-0 mr-2" outlined rounded color="link">Visit website</Button>
                    <Button className="mt-4 ml-0" outlined rounded color="grey">More</Button>

                </Card.Content>
                <Card.Footer>
                    <Tabs className='mx-4 mt-2 has-text-grey ' textWeight='medium'  >
                        <Tabs.Tab>Home</Tabs.Tab>
                        <Tabs.Tab active>About</Tabs.Tab>
                        <Tabs.Tab>Posts</Tabs.Tab>
                        <Tabs.Tab>Jobs</Tabs.Tab>
                        <Tabs.Tab>Life</Tabs.Tab>
                        <Tabs.Tab>People</Tabs.Tab>
                        <Tabs.Tab>Videos</Tabs.Tab>
                    </Tabs>
                </Card.Footer>
            </Card>
            {li_overviewBox()}
        </Columns.Column>
        <Columns.Column>
            {li_adBox(dpImage, companyName)}
            {li_alsoViwed_page_box()}
        </Columns.Column>
    </Columns>

    )
}

export const li_companyBox_mobile = (dpImage, bgImage, companyName, companyDescription, category, location, followers, employeeCount) => {
    return (<>
        <Card className=" overflow-hidden is-centered has-background-transparent is-h-center"  >
            <Card.Image
                size='16/9'
                src={bgImage ?? Default_Li_Blank_Cover}
                className="figure li-companyPage-bg-height-mobile overflow-hidden mt-4 "
            />
            <Card.Content className="has-background-transparent px-3">
                <Media className="mb-1">
                    <Media.Item className=" z-35 " align="left" >
                        <Image
                            alt="profile"
                            src={dpImage ?? Default_Li_Blank_Company}
                            backgroundColor="black"
                            className="li-companyPage-dp-size-mobile object-fit-cover is-clipped"
                        />
                    </Media.Item>
                </Media>
                <Heading className="mt-0 mb-0 li-companyPage-title-mobile" size={5}>{companyName ?? "TestPost"}</Heading>
                <p className="mt-0 li-companyPage-subtitle-mobile" >{companyDescription ?? "Make creative testing and approval humanly easy and quick"}</p>
                <p className="mt-0 li-companyPage-catLocAndFollowers-mobile" textColor="black">{category ?? "Internet"} · {location ?? "New York, NY"} · {followers ?? "151,359"} followers</p>

                <p className="mt-4  has-text-weight-medium is-size-7" >See all {employeeCount ?? "1,034"} employees on LinkedIn</p>
                <Columns gap={1} mobile={true} className=" mt-1">
                    <Columns.Column>
                        <Button size="small" fullwidth className="" rounded color="link">Visit website</Button>
                    </Columns.Column>
                    <Columns.Column narrow>
                        <Button size="small" outlined rounded color="link">Follow</Button>
                    </Columns.Column>

                    <Columns.Column narrow>
                        <Button size="small" className="" outlined rounded color="grey">•••</Button>
                    </Columns.Column>
                </Columns>

            </Card.Content>
            <Card.Footer>
                <Tabs size="small" className='has-text-grey ' textWeight='semibold'  >
                    <Tabs.Tab>Home</Tabs.Tab>
                    <Tabs.Tab active>About</Tabs.Tab>
                    <Tabs.Tab>Posts</Tabs.Tab>
                    <Tabs.Tab>Jobs</Tabs.Tab>
                    <Tabs.Tab>Life</Tabs.Tab>
                    <Tabs.Tab>People</Tabs.Tab>
                    <Tabs.Tab>Videos</Tabs.Tab>
                </Tabs>
            </Card.Footer>
        </Card>
        {li_overviewBox(true)}
    </>

    )
}

export const li_overviewBox = (isMobile) => {

    let cnSize = isMobile ? "is-size-7" : "is-size-6p4"


    return <Box className=' my-5 has-background-transparent'>
        <Heading className="" size={isMobile ? 6 : 5} spaced >Overview</Heading>
        <p className={` ${cnSize}  has-text-grey-dark`} >Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Vivamus sagittis lacus vel augue laoreet rutrum faucibus. Praeterea iter est quasdam res quas ex communi. Cum ceteris in veneratione tui montes, nascetur mus. Integer legentibus erat a ante historiarum dapibus.</p>
        <p className={` ${cnSize} has-text-grey-dark mt-4`}>Check out our career opportunities at testpost.app/careers</p>
        <span >
            <br />
            <Heading size={isMobile ? 7 : 6}>Website</Heading>
            <Heading subtitle size={isMobile ? 7 : 6} textColor="link"> <a href="https://testpost.app" target={"_blank"} rel="noreferrer"  > https://testpost.app</a> </Heading>
        </span>
        <span >
            <br />
            <Heading size={isMobile ? 7 : 6}>Industry</Heading>
            <Heading subtitle size={isMobile ? 7 : 6} textColor="grey">Enterprise Software</Heading>
        </span>
        {/* <span >
        <br />                        
        <Heading size={6}>Company Size</Heading>
        <Heading subtitle size={6} textColor="grey" className='mb-0'>10,001+ employees</Heading>
        <Heading subtitle size={6} textColor="grey" className='mt-0'>285,857 on LinkedIn</Heading>
    </span> */}
        {/* <span >
        <br />                        
        <Heading size={6}>Headquarters</Heading>
        <Heading subtitle size={6} textColor="grey">Mountain View, CA</Heading>
    </span> */}
        <span >
            <br />
            <Heading size={isMobile ? 7 : 6}>Specialties</Heading>
            <Heading subtitle size={isMobile ? 7 : 6} textColor="grey">Preview, proof, organise, task, schedule, calendar, review, validate content & media together.</Heading>
        </span>
    </Box>
}

export const li_adBox_image = () => {
    return <Box className='p-2 m-0 has-background-transparent'>
        <Box shadowless className=' m-0 p-0 has-background-transparent'>
            {/* <ColumnAux isMiddle={true} className="has-text-centered m-0 p-0 " > */}
            <Image
                alt="profile"
                size={128}
                src={Li_adImage}
                className="is-clipped has-background-transparent minW300 maxW300 minH240 "
            />
            {/* </ColumnAux> */}
        </Box>
    </Box>
}

export const li_adBox = (dpImage, companyName) => {
    return <Box className='pt-2 has-text-centered has-background-transparent'>
        <Box radiusless shadowless className='px-0 py-2 mb-2 has-background-transparent'> <p className='is-size-7 has-text-weight-semibold is-pulled-right'>Ad &nbsp;•••</p> </Box>
        {/* <p className='is-size-7 has-text-grey-light has-text-centered px-5'>Always innovating to find new ways to keer users weather aware</p> */}
        <Box shadowless className=' my-0  has-background-transparent'>
            <ColumnAux isMiddle={true} className="has-text-centered  " >
                <Media className="mb-1 is-centered">
                    <Media.Item className=" " align="left" >
                        <Image
                            alt="profile"
                            size={64}
                            src={Default_Li_Blank_User}
                            className="is-clipped has-bacground-transparent"
                            rounded
                        />
                    </Media.Item>
                    <Media.Item className=" " align="left" >
                        <Image
                            alt="profile"
                            size={64}
                            src={dpImage ?? Default_Li_Blank_Company}
                            className="is-clipped has-bacground-transparent"
                        />
                    </Media.Item>

                </Media>
            </ColumnAux>
        </Box>
        <p className='is-size-6p6 has-text-centered px-5 mt-0'>Explore jobs at {companyName ?? "TestPost"} that match your skills</p>
        <Button className="mt-4 ml-0 mr-2 is-size-6p4 is-centered has-text-weight-semibold " outlined rounded color="link">See Jobs</Button>
    </Box>
}

export const li_alsoViwed_page_box = () => {
    return <Box className=' has-background-transparent'>
        <Heading className="mt-0 mb-0 li-companyPage-title mb-6" size={5}>Pages people also viewed</Heading>
        {li_alsoViewed_page_row(AppleLogo_Square, "Apple", "Consumer Electronics", "16,454,959")}
        {li_alsoViewed_page_row(MicrosoftLogo_Square, "Microsoft", "Computer Software", "17,038,997")}
        {li_alsoViewed_page_row(GoogleLogo_Square, "Google", "Internet", "25,033,750")}
        <Heading className=" mt-minus-10 has-text-grey  has-text-centered " size={6}>See similar pages</Heading>
    </Box>
}

export const li_alsoViewed_page_row = (brandLogoIcon, pageName, industry, followers) => {
    return <Box shadowless className='my-0 py-0 has-background-transparent' >
        <Columns className=' mb-0 pb-0'>
            <Columns.Column narrow>
                <Image
                    alt="profile"
                    size={48}
                    src={brandLogoIcon}
                    className=" is-clipped has-bacground-transparent   "
                    backgroundColor='black'
                    rounded
                />
            </Columns.Column>
            <Columns.Column>
                <Heading size={6} spaced={false}>{pageName}</Heading>
                <Heading subtitle size={7} className="mb-1" >{industry}</Heading>
                <p className=' is-size-7 has-text-weight-light has-text-grey ' > {followers} Followers </p>
                <Button className=" mt-2 is-size-6p4 has-text-grey " outlined rounded ><GrAdd /> &nbsp; Follow</Button>
            </Columns.Column>
        </Columns>
        <hr />
    </Box>
}

export const li_newsfeed_recent_box = () => {
    return <Box className=' has-background-transparent' >
        <Box shadowless className='m-0 p-0 has-background-transparent'>
            <Heading className="mt-0 mb-0 li-companyPage-title mb-2" size={7}>Recent</Heading>
            <span className='is-size-7 has-text-weight-medium has-text-grey'>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>User Experience Design</Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>UX Stragtegy Design </Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>Continous Integration</Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>Social Media Preview</Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>Team Approval</Columns.Column></Columns>
            </span>
        </Box>
        <br />
        <Box shadowless className='m-0 p-0 has-background-transparent'>
            <Heading className="mt-0 mb-0 li-companyPage-title mb-2" textColor='link' size={7}>Groups</Heading>
            <span className='is-size-7 has-text-weight-medium has-text-grey'>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>Streamlining Enterprise</Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>Team Productivity </Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>Continous Testing</Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="recentIcon" size={16} className="opacity60" src={Li_recentIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>more</Columns.Column></Columns>
            </span>
        </Box>
        <br />
        <Box shadowless className='m-0 p-0 has-background-transparent'>
            <Columns Columns className='p-0 m-0'><Columns.Column className='p-0 m-0' narrow><Heading className="mt-0 mb-0 li-companyPage-title mb-2" textColor='link' size={7}>Events</Heading></Columns.Column> <Columns.Column className='p-0 pl-2 m-0 is-size-6p4 has-text-grey '><GrAdd className='is-pulled-right' /></Columns.Column></Columns>
        </Box>
        <br />
        <Box shadowless className='m-0 p-0 has-background-transparent'>
            <Heading className="mt-0 mb-0 li-companyPage-title mb-2" textColor='link' size={7}>Followed Hashtags</Heading>
            <span className='is-size-7 has-text-weight-medium has-text-grey'>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="hashtagIcon" size={16} className="opacity60" src={Li_hashtagIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>leadership</Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="hashtagIcon" size={16} className="opacity60" src={Li_hashtagIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>ai </Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="hashtagIcon" size={16} className="opacity60" src={Li_hashtagIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>augmentedreality</Columns.Column></Columns>
                <Columns className='p-0 m-0  mb-2' mobile={true}><Columns.Column className='p-0 m-0' narrow><Image alt="hashtagIcon" size={16} className="opacity60" src={Li_hashtagIcon} /></Columns.Column> <Columns.Column className='p-0 pl-2 m-0'>more</Columns.Column></Columns>
            </span>
        </Box>

        <hr className='my-0 ' />
        <p className=" mt-3 mb-0 has-text-grey has-text-weight-medium has-text-centered is-size-6p4 " >Discover more</p>
    </Box>
}

export const li_newsfeed_news_box = () => {
    return <Box className=' pb-2 has-background-transparent'>
        <Heading className="mt-0 mb-0 li-companyPage-title mb-2" size={6}>LinkedIn News</Heading>

        <Columns className='p-0 m-0  mb-2' mobile={true}>
            <Columns.Column className='p-0 m-0' narrow>•</Columns.Column>
            <Columns.Column className='p-0 pl-2 m-0'>
                <p className=" is-size-6p8 has-text-weight-semibold "> We need more than Design tools  </p>
                <p className=" is-size-7 has-text-grey " >1d ago • 5,622 readers</p>
            </Columns.Column>
        </Columns>
        <Columns className='p-0 m-0  mb-2' mobile={true}>
            <Columns.Column className='p-0 m-0' narrow>•</Columns.Column>
            <Columns.Column className='p-0 pl-2 m-0'>
                <p className=" is-size-6p8 has-text-weight-semibold "> Why Preview before Publishing </p>
                <p className=" is-size-7 has-text-grey " >5h ago • 13,234 readers</p>
            </Columns.Column>
        </Columns>
        <Columns className='p-0 m-0  mb-2' mobile={true}>
            <Columns.Column className='p-0 m-0' narrow>•</Columns.Column>
            <Columns.Column className='p-0 pl-2 m-0'>
                <p className=" is-size-6p8 has-text-weight-semibold "> Organising ideas & designs across teams </p>
                <p className=" is-size-7 has-text-grey " >1d ago • 2,623 readers</p>
            </Columns.Column>
        </Columns>
        <Columns className='p-0 m-0  mb-2' mobile={true}>
            <Columns.Column className='p-0 m-0' narrow>•</Columns.Column>
            <Columns.Column className='p-0 pl-2 m-0'>
                <p className=" is-size-6p8 has-text-weight-semibold "> Anyone in your team can have ideas </p>
                <p className=" is-size-7 has-text-grey " >1d ago • 7,243 readers</p>
            </Columns.Column>
        </Columns>
        <Columns className='p-0 m-0  mb-2' mobile={true}>
            <Columns.Column className='p-0 m-0' narrow>•</Columns.Column>
            <Columns.Column className='p-0 pl-2 m-0'>
                <p className=" is-size-6p8 has-text-weight-semibold "> Inter team collaboration is the real deal </p>
                <p className=" is-size-7 has-text-grey " >2d ago • 17,344 readers</p>
            </Columns.Column>
        </Columns>
        <hr className='m-0 p-0' />
        <Columns className='p-0 m-0  mb-2' mobile={true}>
            <Columns.Column className='p-0 m-0' narrow>&nbsp;</Columns.Column>
            <Columns.Column className='p-0 pl-2 m-0'>
                <p className=" is-size-6p8 has-text-weight-semibold mt-2 "> Show More </p>
            </Columns.Column>
        </Columns>
    </Box >
}

export const li_alsoViewed_users_box = () => {
    return <Box className=' has-background-transparent'>
        <Heading className="mt-0 mb-0 li-companyPage-title mb-2" size={6}>People also viewed</Heading>
        {li_alsoViewed_users_row(reedhastings_dp, "Reed Hastings", "CEO Netflix", "3rd")}
        {li_alsoViewed_users_row(satyanadella_dp, "Satya Nadella", "Chairman and CEO at Microsoft", "2nd+")}
        {li_alsoViewed_users_row(billgates_dp, "Bill Gates", "Co-chair, Bill & Melinda Gates Foundation", "1st")}
        <hr className='my-0 ' />
        <Heading className=" mt-4 has-text-grey  has-text-centered " size={6}>See more</Heading>
    </Box>
}

export const li_alsoViewed_users_row = (brandLogoIcon, pageName, industry, level) => {
    return <Box shadowless className='m-0 p-0 has-background-transparent' >
        <Columns className=' m-0 p-0'>
            <Columns.Column narrow>
                <Image
                    alt="profile"
                    size={48}
                    src={brandLogoIcon}
                    className=" is-clipped has-bacground-transparent   "
                    rounded
                />
            </Columns.Column>
            <Columns.Column>
                <Heading size={6} spaced={false}>{pageName} <span className=' has-text-grey has-text-weight-medium'>• {level}</span> </Heading>
                <Heading subtitle size={7} className="mb-1" >{industry}</Heading>
                {/* <p className=' is-size-7 has-text-weight-light has-text-grey ' > {followers} Followers </p> */}
                <Button className=" mt-2 is-size-6 has-text-grey py-2 " outlined rounded >Message</Button>
            </Columns.Column>
        </Columns>
    </Box>
}

export const li_postCard = (dpImge, postImage, userName, position, content) => {
    return <Card className="linkedinBox-576w overflow-hidde has-background-transparentn is-centered is-h-center "  >
        <Card.Content className="has-background-transparent radiusless px-0">
            <Box shadowless radiusless className="has-background-transparent py-0">
                <Button size="small" className="has-background-transparent is-borderless m-0 p-0 is-pulled-right">
                    <img
                        src={liDotMenu}
                        alt="like"
                        className="li-iconSize24  figure opacity50"
                    /></Button>
                <Columns className="is-mobile " gap={0}>
                    <Columns.Column size={1} className="" >
                        <Media className="m-0">
                            <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                                <Image
                                    src={dpImge ?? Default_DP}
                                    className="twitter-post-dp-size has-background-transparent"
                                    rounded
                                />
                            </Media.Item>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className=" py-0 pl-4">
                        <Heading className="mt-3 mb-0 li-companyPage-title " textWeight="semibold" size={6}>{userName ?? "User Name"} <span className="has-text-grey is-size-6p7 has-text-weight-normal"> • 2nd</span></Heading>
                        <p className="has-text-grey-dark twitter-profile-handleFont is-size-7 "> {position ?? "Social Media Manager, TestPost."} </p>
                        <p className="has-text-grey-dark twitter-profile-handleFont is-size-7 ">
                            <Columns gap={0} className="is-mobile m-0 p-0">
                                <Columns.Column narrow className="m-0 p-0">
                                    8h •
                                </Columns.Column>
                                <Columns.Column narrow className="m-0 p-0">
                                    <Media className="ml-1 ">
                                        <img
                                            alt="shared mt-2"
                                            src={liGlobeIcon}
                                            className="li-iconSize opacity50 figure has-text-grey-light"
                                        />
                                    </Media>
                                </Columns.Column>
                            </Columns>
                        </p>

                    </Columns.Column>
                </Columns>

                <Content className="mt-1 has-text-black li-fontSize-14  li-break-words" >{content ?? "Post content"}</Content>
            </Box>
            {
                postImage ?
                    <div className="li-imageWrapper">
                        <img
                            src={postImage}
                            alt="post"
                            className="li-imageAttributes"
                        />
                    </div>
                    : null
            }


            <Box shadowless radiusless className="has-background-transparent pt-2 pb-0">

                <Columns gap={0} className="is-mobile m-0 p-0">
                    <Columns.Column className="m-0 p-0 py-1" size={2} >
                        <img
                            src={liLikeMiniIcon}
                            alt="liked"
                            className="li-iconSize16 figure "
                        />
                        <img
                            src={liClapMiniIcon}
                            alt="loved"
                            className="li-iconSize16 ml-1 figure"
                        />
                        <img
                            src={liLoveMiniIcon}
                            alt="loved"
                            className="li-iconSize16 ml-1 figure"
                        />

                    </Columns.Column>
                    <Columns.Column className="m-0 p-0 has-text-left" >
                        <span className=" ml-minus-20 is-size-7 mt-minus-2 has-text-grey  has-text-weight-normal">137 • 4 comments</span>

                    </Columns.Column>
                </Columns>
                {/* <Media.Item className="m-0"  > */}

                {/* </Media.Item> */}

                <hr className="instaHr mb-2 mt-0 p-0 opacity50" />
                <p>

                    <Level className="mt-4 mb-0 " breakpoint="mobile" >
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liLike}
                                        alt="like"
                                        className="li-iconSize24 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2 is-size-6p4 has-text-grey-dark has-text-weight-medium">Like</span>
                            </Media>

                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liComment}
                                        alt="comment"
                                        className="li-iconSize24 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2 is-size-6p4 has-text-grey-dark has-text-weight-medium">Comment</span>
                            </Media>
                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liShare}
                                        alt="share"
                                        className="li-iconSize24 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2 is-size-6p4 has-text-grey-dark has-text-weight-medium ">Share</span>
                            </Media>
                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liSend}
                                        alt="send"
                                        className="li-iconSize24 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2 is-size-6p4 has-text-grey-dark has-text-weight-medium ">Send </span>
                            </Media>
                        </Level.Item>
                    </Level>

                </p>
            </Box>
        </Card.Content>
    </Card>
}

export const li_postCard_mobile = (dpImge, postImage, userName, position, content) => {
    return <Card className=" overflow-hidden has-background-transparent is-centered is-h-center "  >
        <Card.Content className="has-background-transparent radiusless px-0">
            <Box shadowless radiusless className="has-background-transparent py-0 px-3">
                <Button size="small" className="has-background-transparent is-borderless m-0 p-0 is-pulled-right">
                    <img
                        src={liDotMenu}
                        alt="like"
                        className="li-iconSize16  figure opacity50"
                    /></Button>
                <Columns className="is-mobile " gap={0}>
                    <Columns.Column size={1} className="" >
                        <Media className="m-0">
                            <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                                <Image
                                    src={dpImge ?? Default_DP}
                                    size={32}
                                    className=" has-background-transparent"
                                    rounded
                                />
                            </Media.Item>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className=" py-0 pl-4">
                        <Heading className="mt-3 mb-0 li-companyPage-title " textWeight="semibold" size={7}>{userName ?? "User Name"} <span className="has-text-grey is-size-6p7 has-text-weight-normal"> • 2nd</span></Heading>
                        <p className="has-text-grey-dark  is-size-8 "> {position ?? "Social Media Manager, TestPost."} </p>
                        <p className="has-text-grey-dark is-size-7 ">
                            <Columns gap={0} className="is-mobile m-0 p-0">
                                <Columns.Column narrow className="m-0 p-0">
                                    8h •
                                </Columns.Column>
                                <Columns.Column narrow className="m-0 p-0">
                                    <Media className="ml-1 ">
                                        <img
                                            alt="shared mt-2"
                                            src={liGlobeIcon}
                                            className="li-iconSize opacity50 figure has-text-grey-light"
                                        />
                                    </Media>
                                </Columns.Column>
                            </Columns>
                        </p>

                    </Columns.Column>
                </Columns>

                <Content className="mt-1 has-text-black is-size-7  li-break-words" >{content ?? "Post content"}</Content>
            </Box>
            {
                postImage ?
                    <div className="li-imageWrapper">
                        <img
                            src={postImage}
                            alt="post"
                            className="li-imageAttributes"
                        />
                    </div>
                    : null
            }


            <Box shadowless radiusless className="has-background-transparent pt-2 pb-0">

                <Columns gap={0} className="is-mobile m-0 p-0">
                    <Columns.Column className="" narrow >
                        <Level mobile={true}>
                            <Level.Item>
                                <img
                                    src={liLikeMiniIcon}
                                    alt="liked"
                                    className="li-iconSize16 figure "
                                />
                            </Level.Item>
                            <Level.Item>
                                <img
                                    src={liClapMiniIcon}
                                    alt="loved"
                                    className="li-iconSize16 ml-1 figure"
                                />
                            </Level.Item>
                            <Level.Item>
                                <img
                                    src={liLoveMiniIcon}
                                    alt="loved"
                                    className="li-iconSize16 ml-1 figure"
                                />
                            </Level.Item>
                            <Level.Item>
                                <span className="  is-size-7 ml-1 has-text-grey  has-text-weight-normal">82</span>

                            </Level.Item>
                        </Level>




                    </Columns.Column>
                    <Columns.Column className=""  >
                        <div className=" is-pulled-right is-size-7  has-text-grey  has-text-weight-normal">10 comments • 1 share</div>
                    </Columns.Column>
                </Columns>
                {/* <Media.Item className="m-0"  > */}

                {/* </Media.Item> */}

                <hr className="instaHr mb-2 mt-0 p-0 opacity50" />
                <p>

                    <Level className="mt-4 mb-0 is-size-7 " breakpoint="mobile" >
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liLike}
                                        alt="like"
                                        className="li-iconSize16 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-1  has-text-grey-dark has-text-weight-medium">Like</span>
                            </Media>

                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liComment}
                                        alt="comment"
                                        className="li-iconSize16 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-1  has-text-grey-dark has-text-weight-medium">Comment</span>
                            </Media>
                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liShare}
                                        alt="share"
                                        className="li-iconSize16 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-1  has-text-grey-dark has-text-weight-medium ">Share</span>
                            </Media>
                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={liSend}
                                        alt="send"
                                        className="li-iconSize16 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-1  has-text-grey-dark has-text-weight-medium ">Send </span>
                            </Media>
                        </Level.Item>
                    </Level>

                </p>
            </Box>
        </Card.Content>
    </Card>
}

export const li_postBox = (dpImge, postImage, userName, position, content) => {
    return (
        <Columns className=' mt-2 '>
            <Columns.Column >
                {/* <span className='stick-to-top-imp' > */}
                {li_newsfeed_recent_box()}
                {/* </span> */}
            </Columns.Column>
            <Columns.Column>
                {li_postCard(dpImge, postImage, userName, position, content)}
                <br />
                {li_postCard(dpImge, postImage, userName, position, content)}
            </Columns.Column>
            <Columns.Column >
                {/* <span className='stick-to-top-imp' > */}
                {li_newsfeed_news_box()}
                {li_adBox_image()}
                {/* </span> */}
            </Columns.Column>
        </Columns>
    )
}


export const li_postBox_mobile = (dpImge, postImage, userName, position, content) => {
    return (
        <div className=' mt-2 '>

            {li_postCard_mobile(dpImge, postImage, userName, position, content)}
            <br />
            {li_postCard_mobile(dpImge, postImage, userName, position, content)}

        </div>
    )
}