import moment from 'moment';
import Parse from 'parse'
import { currentUserInfo, getCurrentWorkspaceId, isCurrentUser, validateMemberInfoRoles } from '../utilities/Login';
import { randomCode, removeKeysFromUserInfo_Obj, cypherNoteObjToString, di_cypherNoteObjToString } from '../utilities/Utilities';
import { appMeta, assetPointer, NotificationFor, projectPointer, TaskStatus, TaskType, Task_Dynamic_Changeables, userPointer, workspacePointer } from './EnumsAndPointers';
import { Notification } from './Notification';

const TaskClass = Parse.Object.extend("Task");




export class TaskFilter {



    constructor(type, inProjects, channels, status, addedBy, assignedTo, createdAfter, createdBefore, deadlineAfter, deadlineBefore) {
        this.type = type
        this.inProjects = inProjects ?? []
        this.channels = channels ?? []
        this.status = status
        this.addedBy = addedBy ?? []
        this.assignedTo = assignedTo ?? []
        this.createdAfter = createdAfter
        this.createdBefore = createdBefore
        this.deadlineAfter = deadlineAfter
        this.deadlineBefore = deadlineBefore
    }

    static copyFrom = (obj) => {
        let ob = new TaskFilter()
        ob.type = obj.type
        ob.inProjects = [...obj.inProjects]
        ob.channels = [...obj.channels]
        ob.status = obj.status
        ob.addedBy = [...obj.addedBy]
        ob.assignedTo = [...obj.assignedTo]
        ob.createdAfter = obj.createdAfter
        ob.createdBefore = obj.createdBefore
        ob.deadlineAfter = obj.deadlineAfter
        ob.deadlineBefore = obj.deadlineBefore
        return ob
    }

    isActive = () => {
        let isA = false

        if (this.type) {
            isA = true
        }

        if (this.inProjects.length > 0 ||
            this.channels.length > 0 || this.addedBy.length > 0 || this.assignedTo.length > 0
        ) {
            isA = true
        }

        if (this.status) {
            isA = true
        }

        if (this.createdAfter || this.createdBefore || this.deadlineAfter || this.deadlineBefore) {
            isA = true
        }


        return isA
    }

    count = () => {
        let count = 0
        if (this.addedBy.length > 0) {
            count += 1
        }
        if (this.assignedTo.length > 0) {
            count += 1
        }

        if (this.channels.length > 0) {
            count += 1
        }
        if (this.inProjects.length > 0) {
            count += 1
        }

        if (this.createdAfter) {
            count += 1
        }

        if (this.createdBefore) {
            count += 1
        }

        if (this.deadlineAfter) {
            count += 1
        }

        if (this.deadlineBefore) {
            count += 1
        }

        if (this.type && this.type !== TaskType.all) {
            count += 1
        }

        if (this.status && this.type !== TaskStatus.all) {
            count += 1
        }
        return count
    }

}


export class Task {
    constructor(id, title, description, estimatedDate, plannedOn, deadline, priority, byUser, byUserInfo, assets, assetsIds, thumbImageUrls, comments, commentsIds, project, projectsIds, projectName, projects, workspace, tags, assignedTo, assignedToInfo, status, time, type, isShared, sharedWith, sharedWithInfo, notes, completedBy, completedByInfo, isArchived, archivedBy, archivedByInfo, startedWorkingAt, completedAt, createdAt, updatedAt, thisAppMeta) {
        this.id = id
        this.title = title
        this.description = description
        this.estimatedDate = estimatedDate
        this.plannedOn = plannedOn
        this.deadline = deadline
        this.priority = priority
        this.byUser = byUser
        this.byUserInfo = byUserInfo
        this.assets = assets ?? []
        this.assetsIds = assetsIds ?? []
        this.thumbImageUrls = thumbImageUrls ?? []  // handy to display image of asset while listing the tasks without fetching them multiple times
        this.comments = comments ?? []
        this.commentsIds = commentsIds ?? []
        this.projects = projects ?? []
        this.projectsIds = projectsIds ?? []
        this.workspace = workspace
        this.tags = tags ?? []
        this.assignedTo = assignedTo ?? []
        this.assignedToInfo = assignedToInfo ?? []
        this.status = status ?? TaskStatus.pending
        this.time = time
        this.type = type ?? TaskType.update
        this.isShared = isShared ?? false
        this.sharedWith = sharedWith ?? []
        this.sharedWithInfo = sharedWithInfo ?? []
        this.notes = notes ?? []
        this.isArchived = isArchived ?? false
        this.archivedBy = archivedBy
        this.archivedByInfo = archivedByInfo
        this.completedBy = completedBy
        this.completedByInfo = completedByInfo
        this.startedWorkingAt = startedWorkingAt
        this.completedAt = completedAt
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.appMeta = thisAppMeta ?? appMeta

    }

    static copyFrom(obj) {
        let ob = new Task()
        ob.id = obj.id
        ob.title = obj.title
        ob.description = obj.description
        ob.estimatedDate = obj.estimatedDate
        ob.plannedOn = obj.plannedOn
        ob.deadline = obj.deadline
        ob.priority = obj.priority
        ob.byUser = obj.byUser
        ob.byUserInfo = obj.byUserInfo

        ob.assets = obj.assets ?? []
        ob.assetsIds = obj.assetsIds ?? []
        ob.thumbImageUrls = obj.thumbImageUrls ?? []

        ob.comments = obj.comments ?? []
        ob.commentsIds = obj.commentsIds ?? []

        ob.projects = obj.projects ?? []
        ob.projectsIds = obj.projectsIds ?? []


        ob.workspace = obj.workspace
        ob.tags = obj.tags ?? []
        ob.assignedTo = obj.assignedTo ?? []
        ob.assignedToInfo = obj.assignedToInfo ?? []
        ob.status = obj.status ?? TaskStatus.pending
        ob.time = obj.time
        ob.type = obj.type ?? TaskType.update
        ob.isShared = obj.isShared ?? false
        ob.sharedWith = obj.sharedWith ?? []
        ob.sharedWithInfo = obj.sharedWithInfo ?? []
        ob.notes = obj.notes ?? []
        ob.isArchived = obj.isArchived ?? false
        ob.archivedBy = obj.archivedBy ?? false
        ob.archivedByInfo = obj.archivedByInfo
        ob.completedBy = obj.completedBy
        ob.completedByInfo = obj.completedByInfo
        ob.startedWorkingAt = obj.startedWorkingAt
        ob.completedAt = obj.completedAt
        ob.createdAt = obj.createdAt
        ob.updatedAt = obj.updatedAt
        ob.appMeta = obj.appMeta

        return ob
    }


    



    static whatHasChanged = (oldTk, newTk) => {
        let changedToCompleted = false
        let changedToWorking = false
        let changedStatus = false
        let changedPriority = false
        let changedDates = false
        let changedProjAssComms = false
        let changedTitle = false


        console.log("INCOMING OLD TK")
        console.log( JSON.stringify(oldTk) )



        console.log("INCOMING NEW TK")
        console.log( JSON.stringify(newTk) )


        // Compare current task with new changed one
        if (oldTk.status !== TaskStatus.complete && newTk.status === TaskStatus.complete) {
            changedToCompleted = true
        } else if (oldTk.status !== TaskStatus.working && newTk.status === TaskStatus.working) {
            changedToWorking = true
        } else if (oldTk.status !== newTk.status) {
            changedStatus = true
        }


        if (oldTk.priority !== newTk.priority) {
            changedPriority = true
        }

        if (oldTk.title !== newTk.title) {
            changedTitle = true
        }


        if (oldTk.plannedOn !== newTk.plannedOn || oldTk.estimatedDate !== newTk.estimatedDate || oldTk.deadline !== newTk.deadline) {
            changedDates = true
        }

        if (oldTk.assetsIds !== newTk.assetsIds || oldTk.projectsIds !== newTk.projectsIds || oldTk.commentsIds !== newTk.commentsIds) {
            changedProjAssComms = true
        }


        return  {
            "changedToCompleted" : changedToCompleted,
            "changedToWorking" : changedToWorking,
            "changedStatus" : changedStatus,
            "changedPriority" : changedPriority, 
            "changedDates" : changedDates,
            "changedProjAssComms" : changedProjAssComms, 
            "changedTitle" : changedTitle
        }
    }

    static attributeForChangeable = (changeable) => {
        switch (changeable) {

            case Task_Dynamic_Changeables.AssignedToUsers: return "assignedToInfo";
            case Task_Dynamic_Changeables.SharedWithUsers: return "sharedWithInfo";
            case Task_Dynamic_Changeables.Note: return "notes";
            case Task_Dynamic_Changeables.ProjectsAssetsComments: return "assets";
            default: return null;
        }
    }

    static changeableForAttribute = (attribute) => {
        switch (attribute) {
            case "assignedToInfo": return Task_Dynamic_Changeables.AssignedToUsers;
            case "sharedWithInfo": return Task_Dynamic_Changeables.SharedWithUsers;
            case "notes": return Task_Dynamic_Changeables.Note;
            case "assets": return Task_Dynamic_Changeables.ProjectsAssetsComments;
            default: return null;
        }
    }



    static initFromPFObject = (obj) => {
        let ob = new Task()

        if (!obj.id) {
            return null
        }

        ob.id = obj.id
        ob.title = obj.get("title")
        ob.description = obj.get("description")
        ob.estimatedDate = obj.get("estimatedDate")
        ob.plannedOn = obj.get("plannedOn")
        ob.deadline = obj.get("deadline")
        ob.priority = obj.get("priority")

        let bu = obj.get("byUser")
        ob.byUser = bu.id ?? isCurrentUser().id
        ob.byUserInfo = obj.get("byUserInfo")

        ob.assets = obj.get("assets") ?? []
        ob.assetsIds = obj.get("assetsIds") ?? []
        ob.thumbImageUrls = obj.get("thumbImageUrls") ?? []

        ob.comments = obj.get("comments") ?? []
        ob.commentsIds = obj.get("commentsIds") ?? []

        ob.projects = obj.get("projects") ?? []
        ob.projectsIds = obj.get("projectsIds") ?? []


        let wk = obj.get("workspace")
        ob.workspace = wk.id

        ob.tags = obj.get("tags") ?? []
        ob.assignedTo = obj.get("assignedTo") ?? []
        var assnInToInfo = obj.get("assignedToInfo") ?? []
        assnInToInfo = validateMemberInfoRoles(assnInToInfo, obj.get("byUserInfo")) ?? []
        ob.assignedToInfo = assnInToInfo


        ob.status = obj.get("status") ?? TaskStatus.pending
        ob.time = obj.get("time")
        ob.type = obj.get("type") ?? TaskType.general
        ob.isShared = obj.get("isShared") ?? false
        ob.sharedWith = obj.get("sharedWith") ?? []
        var sharedWInfo = obj.get("sharedWithInfo") ?? []
        sharedWInfo = validateMemberInfoRoles(sharedWInfo, obj.get("byUserInfo")) ?? []
        ob.sharedWithInfo = sharedWInfo

        let cipheredNotes = obj.get("notes") ?? []
        let notesObj = []
        if (cipheredNotes.length) {
            cipheredNotes.forEach((c) => {
                let d = di_cypherNoteObjToString(c)
                if (d) {
                    notesObj.push(d)
                }
            })
        }
        ob.notes = notesObj


        ob.isArchived = obj.get("isArchived") ?? false
        ob.archivedBy = obj.get("archivedBy") ?? false
        ob.archivedByInfo = obj.get("archivedByInfo")
        ob.completedBy = obj.get("completedBy")
        ob.completedByInfo = obj.get("completedByInfo")


        ob.startedWorkingAt = obj.get("startedWorkingAt")
        ob.completedAt = obj.get("completedAt")

        ob.createdAt = obj.get("createdAt")
        ob.updatedAt = obj.get("updatedAt")
        ob.appMeta = obj.get("appMeta")

        return ob
    }

    add = (callback) => {
        // let ob = new Task()

        let ob = new TaskClass()

        if (!this.title) {
            this.title = "Untitled"
        }
        ob.set("title", this.title)
        if (this.description) { ob.set("description", this.description) }
        if (this.estimatedDate) { ob.set("estimatedDate", this.estimatedDate) }
        if (this.plannedOn) { ob.set("plannedOn", this.plannedOn) }
        if (this.deadline) { ob.set("deadline", this.deadline) }
        if (this.priority) { ob.set("priority", this.priority) }


        const cu = isCurrentUser()
        ob.set("byUser", userPointer(cu.id))
        ob.set("byUserInfo", currentUserInfo())

        if (this.assets) { ob.set("assets", this.assets) }
        if (this.assetsIds) { ob.set("assetsIds", this.assetsIds) }
        if (this.thumbImageUrls) { ob.set("thumbImageUrls", this.thumbImageUrls) }

        if (this.comments) { ob.set("comments", this.comments) }
        if (this.commentsIds) { ob.set("commentsIds", this.commentsIds) }

        if (this.projects) { ob.set("projects", this.projects) }
        if (this.projectsIds) { ob.set("projectsIds", this.projectsIds) }


        ob.set("workspace", workspacePointer(getCurrentWorkspaceId()))
        if (this.tags) { ob.set("tags", this.tags) }
        if (this.assignedTo) { ob.set("assignedTo", this.assignedTo) }
        if (this.assignedToInfo) { ob.set("assignedToInfo", this.assignedToInfo) }
        ob.set("status", this.status)
        if (this.time) { ob.set("time", this.time) }
        ob.set("type", this.type)
        ob.set("isShared", this.isShared)
        if (this.sharedWith) { ob.set("sharedWith", this.sharedWith) }
        if (this.sharedWithInfo) { ob.set("sharedWithInfo", this.sharedWithInfo) }
        // ob.set("notes", this.notes)

        let notesObjStrings = []

        if (this.notes.length) {
            notesObjStrings = this.notes.map((n) => cypherNoteObjToString(n))
        }



        console.log("THIS.NOTES ")
        console.log(this.notes)

        console.log("NOTES OBJ STRINGS ARE")
        console.log(notesObjStrings)
        ob.set("notes", notesObjStrings)
        if (this.projectName) { ob.set("projectName", this.projectName) }


        ob.save()
            .then((ob) => {
                this.id = ob.id
                console.log('New Task Created')
                callback(true, this, '')
            }, (error) => {
                console.log('Failed to create new task, with error code: ' + error.message);
                callback(false, null, error.message)
            });
    }

    createNote = (userInfo, note, existingId) => {
        let finalObj = {
            id: existingId ?? randomCode(6),
            byUserInfo: userInfo,
            createdAt: `${moment().unix()}`,
            note: note
        }
        console.log("FINAL OBJ")

        console.log(finalObj)

        return finalObj
    }

    addNote = (noteObj, errorCallback) => {
        var query = new Parse.Query(TaskClass);


        // console.log("Adding Note Obj")
        // console.log(noteObj)


        let noteObjString = cypherNoteObjToString(noteObj)



        let cu = currentUserInfo()
        let notifyUsers = [...new Set( [this.byUser, ...this.assignedTo, ...this.sharedWith])]


        query.get(this.id)
            .then((ob) => {
                ob.addUnique("notes", noteObjString)
                ob.save()

                Notification.addNotification([], `Task:${this.title}`, `${cu.name} added a note to Task: ${this.title}`, notifyUsers, null, null, null, null, null, null, [this.id], null, null, "", null, `Task=${this.id}`, null, NotificationFor.task_addedNote)

            }).catch((error) => {
                errorCallback('Error while adding note in task =' + error.message)
            });
    }

    removeNote = (noteObj, errorCallback) => {
        var query = new Parse.Query(TaskClass);
        let noteObjString = cypherNoteObjToString(noteObj)

        console.log("TRYING TO DELETE THIS NOTE STRING")
        console.log(noteObjString)

        query.get(this.id)
            .then((ob) => {
                ob.remove("notes", noteObjString)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing note from task =' + error.message)
            });
    }

    addAssignTo_multiple = (userIds, userInfos, errorCallback) => {
        var query = new Parse.Query(TaskClass);
        var usrInfos = userInfos.map((u) => removeKeysFromUserInfo_Obj(u, true, true, true))

        let cu = currentUserInfo()

        query.get(this.id)
            .then((ob) => {
                ob.addAllUnique("assignedTo", userIds)
                ob.addAllUnique("assignedToInfo", usrInfos)
                ob.save()

                Notification.addNotification([], `Task:${this.title}`, `${cu.name} assigned you a Task: ${this.title}`, [...userIds], null, null, null, null, [this.id], [...userIds], null, null, null, "", null, `Task=${this.id}`, null, NotificationFor.task_assignedToYou)

            }).catch((error) => {
                errorCallback('Error while adding assignTo user in task =' + error.message)
            });
    }

    removeAssignTo = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(TaskClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // let cu = currentUserInfo()

        query.get(this.id)
            .then((ob) => {
                ob.remove("assignedTo", userId)
                ob.remove("assignedToInfo", usrInfo)
                ob.save()

                // Notification.addNotification([], `Task:${this.name}`, `${cu.name}  UN-assigned you a Task : ${this.name}`, [userId], null, null, null, null, [this.id], [userId], null, null, null, "", null, null, null)

            }).catch((error) => {
                errorCallback('Error while removing assignTo user in task =' + error.message)
            });
    }

    addSharedWith_multiple = (userIds, userInfos, errorCallback) => {
        var query = new Parse.Query(TaskClass);
        var usrInfos = userInfos.map((u) => removeKeysFromUserInfo_Obj(u, true, true, true))
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }
        let cu = currentUserInfo()

        query.get(this.id)
            .then((ob) => {
                ob.addAllUnique("sharedWith", userIds)
                ob.addAllUnique("sharedWithInfo", usrInfos)
                ob.save()

                Notification.addNotification([], `Task:${this.title}`, `${cu.name} shared a task with you. Task:${this.title}`, [...userIds], null, null, null, null, [this.id], [...userIds], null, null, null, "", null, `Task=${this.id}`, null, NotificationFor.task_sharedWithYou)

            }).catch((error) => {
                errorCallback('Error while adding assignTo user in task =' + error.message)
            });
    }

    removeSharedWith = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(TaskClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }
        // let cu = currentUserInfo()


        query.get(this.id)
            .then((ob) => {
                ob.remove("sharedWith", userId)
                ob.remove("sharedWithInfo", usrInfo)
                ob.save()

                // Notification.addNotification([], `Task:${this.name}`, `${cu.name} UN-shared a Task: ${this.name}`, [userId], null, null, null, null, [this.id], [userId], null, null, null, "", null, null, null)

            }).catch((error) => {
                errorCallback('Error while removing assignTo user in task =' + error.message)
            });
    }




    update = (callback) => {
        if (this.id == null) {
            callback(false, null, "Error : No object id to update Task")
            return
        }
        var query = new Parse.Query(TaskClass);
        query.get(this.id)
            .then((ob) => {
                if (!this.name) {
                    this.name = "Untitled"
                }
                ob.set("title", this.title)
                if (this.description) { ob.set("description", this.description) } else { ob.unset("description") }
                if (this.estimatedDate) { ob.set("estimatedDate", this.estimatedDate) } else { ob.unset("estimatedDate") }
                if (this.plannedOn) { ob.set("plannedOn", this.plannedOn) } else { ob.unset("plannedOn") }
                if (this.deadline) { ob.set("deadline", this.deadline) } else { ob.unset("deadline") }
                if (this.priority) { ob.set("priority", this.priority) }

                if (this.assets) { ob.set("assets", this.assets) } else { ob.unset("assets") }


                ob.set("assets", this.assets ?? [])
                ob.set("assetsIds", this.assetsIds ?? [])
                ob.set("thumbImageUrls", this.thumbImageUrls ?? [])

                ob.set("comments", this.comments ?? [])
                ob.set("commentsIds", this.commentsIds ?? [])

                ob.set("projects", this.projects ?? [])
                ob.set("projectsIds", this.projectsIds ?? [])

                // if (this.assignedTo) { ob.set("assignedTo", this.assignedTo) } else {ob.unset("assignedTo")}
                // if (this.assignedToInfo) { ob.set("assignedToInfo", this.assignedToInfo) } else {ob.unset("assignedToInfo")}

                ob.set("status", this.status)

                if (this.time) { ob.set("time", this.time) } else { ob.unset("time") }
                ob.set("type", this.type)
                ob.set("isShared", this.isShared)

                // if (this.sharedWith) { ob.set("sharedWith", this.sharedWith) } else {ob.unset("sharedWith")}
                // if (this.sharedWithInfo) { ob.set("sharedWithInfo", this.sharedWithInfo) } else {ob.unset("sharedWithInfo")}

                ob.set("isArchived", this.isArchived)
                if (this.archivedBy) { ob.set("archivedBy", this.archivedBy) } else { ob.unset("archivedBy") }
                if (this.archivedByInfo && this.isArchived) { ob.set("archivedByInfo", removeKeysFromUserInfo_Obj(this.archivedByInfo)) } else { ob.unset("archivedByInfo") }

                if (this.completedBy) { ob.set("completedBy", this.completedBy) } else { ob.unset("completedBy") }
                if (this.completedByInfo) { ob.set("completedByInfo", this.completedByInfo) } else { ob.unset("completedByInfo") }

                ob.set("appMeta", appMeta)
                ob.set("projectName", this.projectName)

                if (this.startedWorkingAt) { ob.set("startedWorkingAt", this.startedWorkingAt) } else { ob.unset("startedWorkingAt") }

                if (this.completedAt) { ob.set("completedAt", this.completedAt) } else { ob.unset("completedAt") }



                ob.save()
                callback(true, this, '')
            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });
    }

    delete = (callback) => {
        if (this.id) {
            callback(false, "Error : No id to delete Task ")
        }
        let thisObj = new TaskClass()
        thisObj.id = this.id

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            callback(false, 'Error while deleting Task ', error.message)
        });
    }

    // markPursuing = (callback) => {
    //     this.isPursuing = true
    //     this.update(callback)
    // }

    markPending = (callback) => {
        const cuInfo = currentUserInfo()
        this.status = TaskStatus.pending
        this.completedBy = null
        this.completedByInfo = null
        this.completedAt = null
        this.startedWorkingAt = null
        this.update(callback)

        let notifyUsers = [...new Set( [this.byUser, ...this.assignedTo, ...this.sharedWith])]
        Notification.addNotification([], `Task:${this.title}`, `${cuInfo.name} is working on Task: ${this.title}`, notifyUsers, null, null, null, null, null, null, [this.id], null, null, "", null, `Task=${this.id}`, null, NotificationFor.task_changedStatus)
    }


    markWorking = (callback) => {
        const cuInfo = currentUserInfo()
        this.status = TaskStatus.working
        this.startedWorkingAt = new Date()
        this.completedBy = userPointer(cuInfo.id)
        this.completedByInfo = cuInfo
        this.completedAt = null
        this.update(callback)

        let notifyUsers = [...new Set( [this.byUser, ...this.assignedTo, ...this.sharedWith])]
        Notification.addNotification([], `Task:${this.title}`, `${cuInfo.name} is working on Task: ${this.title}`, notifyUsers, null, null, null, null, null, null, [this.id], null, null, "", null, `Task=${this.id}`, null, NotificationFor.task_changedStatus)

    }


    markComplete = (callback) => {
        const cuInfo = currentUserInfo()
        this.status = TaskStatus.complete
        this.completedBy = userPointer(cuInfo.id)
        this.completedByInfo = currentUserInfo()
        this.completedAt = new Date()
        this.update(callback)

        let notifyUsers = [...new Set( [this.byUser, ...this.assignedTo, ...this.sharedWith])]
        Notification.addNotification([], `Task:${this.title}`, `${cuInfo.name} completed the Task: ${this.title}`, notifyUsers, null, null, null, null, null, null, [this.id], null, null, "", null, `Task=${this.id}`, null, NotificationFor.task_completed)
    }

    archive = (callback) => {
        const cuInfo = currentUserInfo()
        this.isArchived = true
        this.archivedBy = userPointer(cuInfo.id)
        this.isArchivedByInfo = currentUserInfo()
        this.update(callback)

        let notifyUsers = [...new Set( [this.byUser, ...this.assignedTo, ...this.sharedWith])]
        Notification.addNotification([], `Task:${this.title}`, `${cuInfo.name} archived the Task: ${this.title}`, notifyUsers, null, null, null, null, null, null, [this.id], null, null, "", null, `Task=${this.id}`, null, NotificationFor.task_archived)
    }

    static getAllTasks = (forPosition, byUserId, forAssetId, forCommentId, assignedToUserId, forProjectId, filter, sort, callback) => {
        console.log('Searching For Tasks ')
        var query = new Parse.Query(TaskClass);
        query.descending('createdAt')
        // query.notEqualTo('isArchived', true)  // not fetching archived 
        query.limit(60)

        console.log("WORKSPACE ID IS")
        let wkId = getCurrentWorkspaceId()
        console.log(wkId);

        query.equalTo("workspace", workspacePointer(wkId))


        // console.log(`FINDING FOR ASSET ID IS ${forAssetId}`)


        // let cuId = isCurrentUser().id
        if (byUserId) {
            query.equalTo("byUser", userPointer(byUserId))
        }

        if (forAssetId) {
            // query.containedIn("assetsIds", forAssetId)
            console.log(`ASSET ID IS ${forAssetId}`)
            query.equalTo("assetsIds", forAssetId)
        }

        if (forCommentId) {
            query.equalTo("commentsIds", forCommentId)
        }

        if (assignedToUserId) {
            query.equalTo("assignedTo", userPointer(assignedToUserId))
        }

        // For single project
        // if (forProjectId) {
        //     query.containedIn("project", projectPointer(forProjectId))
        // }

        // For attached projects - added later
        if (forProjectId) {
            query.equalTo("projectsIds", forProjectId)
        }



        query.find().then((objects) => {
            console.log('All Tasks are =')
            console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Task.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Tasks Found')
            }

        }, (error) => {
            console.log("ERROR MESSAGE IS ")
            console.log(error)
            console.log(error.message)

            callback(false, [], error.message)
        })
    }
}