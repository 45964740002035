import React, { useEffect, useState } from 'react'
import { CommonEntityFilter, emptyTaskAttachments, folderOptions, ProjectFolderLevel, ProjectStatus } from '../../Models/EnumsAndPointers'
import { Project } from '../../Models/Project'
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import { FiChevronRight } from 'react-icons/fi';
import LoadingAux from '../../Containers/LoadingAux';
import ProjectExplorer from './ProjectExplorer';
import AssetExplorer from './AssetExplorer';
import { Asset } from '../../Models/Asset';
import CommentExplorer from './CommentExplorer';
import { Comment } from '../../Models/Comment';
import { confirmAction } from '../../utilities/Utilities';



const ModalExplorer = (props) => {

    const SelectionTab = {
        all: "All",
        selected: "Selected"
    }





    const [title, setTitle] = useState(props.title ?? "Projects")
    const [currentFolder, setCurrentFolder] = useState(ProjectFolderLevel.project)
    const [currentTab, setCurrentTab] = useState(SelectionTab.all)
    const [currentFilter, setCurrentFilter] = useState(CommonEntityFilter.all)
    const [selectedEntities, setSelectedEntities] = useState(props.selections ?? emptyTaskAttachments)

    useEffect(() => {
        setCurrentTab(SelectionTab.all)
        setCurrentFilter(CommonEntityFilter.all)
    }, [currentFolder, SelectionTab.all])

    const folderLevelChanged = (folderLevel, tl) => {
        setCurrentFolder(folderLevel)
        setTitle(tl)
    }


    const beforeClose = () => {
        console.log("Selected Entities are")
        console.log(selectedEntities)
        props.selectionsChanged(selectedEntities)
        // props.close()
    }


    const breadcrumbSelected = (folderLevel) => {
        setCurrentFolder(folderLevel)
        setTitle(folderLevel)

    }

    const entitySelected = (type, entity, entityParentId, superParentId) => {

        let scf = { ...selectedEntities }

        if (type === ProjectFolderLevel.project) {
            let entityObj = Project.copyFrom(entity).thumbnailObject()
            scf.projects = [...scf.projects, entityObj]
        }

        if (type === ProjectFolderLevel.assets) {
            let entityObj = Asset.copyFrom(entity).thumbnailObject(entityParentId)
            scf.assets = [...scf.assets, entityObj]
        }

        if (type === ProjectFolderLevel.comments) {
            let entityObj = Comment.copyFrom(entity).thumbnailObject(entityParentId, superParentId)
            scf.comments = [...scf.comments, entityObj]
        }


        setSelectedEntities(scf)
    }


    const entityRemoved = (type, entity) => {

        confirmAction("Are you sure you want to remove this " + type.slice(0, -1), () => {
            let scf = { ...selectedEntities }

            if (type === ProjectFolderLevel.project) {
                scf.projects = scf.projects.filter((p) => p.id !== entity.id)
            }

            if (type === ProjectFolderLevel.assets) {
                scf.assets = scf.assets.filter((p) => p.id !== entity.id)
            }

            if (type === ProjectFolderLevel.comments) {
                scf.comments = scf.comments.filter((p) => p.id !== entity.id)
            }

            setSelectedEntities(scf)
        }, null)


    }


    const aptExplorer = () => {

        if (props.toExplore === ProjectFolderLevel.project) {
            return <ProjectExplorer showSelectedOnly={currentTab === SelectionTab.selected} statusFilter={currentFilter} selectables={props.selectables} selectedEntities={selectedEntities} entitySelected={entitySelected} entityRemoved={entityRemoved} close={beforeClose} currentFolder={currentFolder} folderLevelChanged={folderLevelChanged} />
        }

        if (props.toExplore === ProjectFolderLevel.assets && props.projectId) {
            return <AssetExplorer showSelectedOnly={currentTab === SelectionTab.selected} statusFilter={currentFilter} selectables={props.selectables} selectedEntities={selectedEntities} entitySelected={entitySelected} entityRemoved={entityRemoved} projectId={props.projectId} close={beforeClose} currentFolder={currentFolder} folderLevelChanged={folderLevelChanged} />
        }

        if (props.toExplore === ProjectFolderLevel.comments && props.assetId) {
            return <CommentExplorer showSelectedOnly={currentTab === SelectionTab.selected} selectables={props.selectables} selectedEntities={selectedEntities} entitySelected={entitySelected} entityRemoved={entityRemoved} assetId={props.assetId} projectId={props.projectId ?? ""} close={beforeClose} currentFolder={currentFolder} folderLevelChanged={folderLevelChanged} />
        }

        return <p>Null</p>

    }

    const filterDropdownChange = (val) => {
        setCurrentFilter(val)
    }

    const modal = () => {

        //  
        return <Modal show={true} closeOnBlur={true} showClose={false} onClose={beforeClose} className=" lightModalBg backblurOnly-light lightDeleteButton animate-bottom " >
            <Modal.Card className=" maxW540 minW450 ">
                {
                    props.noHeader ?
                        null
                        :
                        <Modal.Card.Header showClose={false} >
                            <Modal.Card.Title className="has-text-grey text-wrap30p" textSize={6} >{title ?? "Select Option"}</Modal.Card.Title>
                            <Button rounded className='is-pulled-right  ' size="small" color={'black'} onClick={beforeClose}>Done</Button>
                        </Modal.Card.Header>

                }
                {
                    currentFolder === ProjectFolderLevel.project ? null :
                        <Box className=' m-0 has-background-white p-2' shadowless radiusless>

                            {currentFolder === ProjectFolderLevel.assets ?
                                // assets
                                <Breadcrumb separator='succeeds' size='small' className='ml-2 '>
                                    <Breadcrumb.Item active={true} className='px-1 is-clickable' textColor='link' textWeight='semibold' onClick={() => breadcrumbSelected(ProjectFolderLevel.project)} > Project </Breadcrumb.Item>
                                    <Breadcrumb.Item className='px-1' > Assets </Breadcrumb.Item>
                                </Breadcrumb>
                                :
                                // assets > comments
                                <Breadcrumb separator='succeeds' size='small' className='ml-2'>
                                    <Breadcrumb.Item active={true} className='px-1 is-link is-clickable' textColor='link' textWeight='semibold' onClick={() => breadcrumbSelected(ProjectFolderLevel.project)}> Project </Breadcrumb.Item>
                                    <Breadcrumb.Item active={true} className='px-1 is-link is-clickable' textColor='link' textWeight='semibold' onClick={() => breadcrumbSelected(ProjectFolderLevel.assets)}> Assets </Breadcrumb.Item>
                                    <Breadcrumb.Item className='px-1'> Comments </Breadcrumb.Item>
                                </Breadcrumb>
                            }
                        </Box>
                }

                <Columns className='p-1 m-0 has-background-white'>
                    <Columns.Column>
                        <Tabs size={"small"} className="   " type='toggle' color='black'>
                            <Tabs.Tab active={currentTab === SelectionTab.all} onClick={() => { setCurrentTab(SelectionTab.all) }} > {SelectionTab.all} </Tabs.Tab>
                            <Tabs.Tab active={currentTab === SelectionTab.selected} onClick={() => { setCurrentTab(SelectionTab.selected) }} > {SelectionTab.selected} </Tabs.Tab>
                        </Tabs>
                    </Columns.Column>
                    <Columns.Column>

                        {currentTab === SelectionTab.all ?
                            <Form.Field>
                                <Form.Control>
                                    <Form.Select
                                        size='small'
                                        value={currentFilter}
                                        className=" mt-0  pt-0 is-pulled-right "
                                        onChange={(e) => {
                                            filterDropdownChange(e.target.value)
                                        }}
                                    >
                                        <option value={CommonEntityFilter.all}>{CommonEntityFilter.all}</option>
                                        <option value={CommonEntityFilter.pending}>{CommonEntityFilter.pending}</option>
                                        <option value={CommonEntityFilter.finalised}>{CommonEntityFilter.finalised}</option>
                                    </Form.Select>
                                </Form.Control>
                            </Form.Field>
                            : null}



                    </Columns.Column>
                </Columns>



                <Modal.Card.Body className="p-0">


                    <Box className={props.showButtons ? " py-5 is-fullwidth z-1000 has-text-left has-background-white p-0 fullHeight " : " is-fullwidth z-1000 has-text-left has-background-white p-0  fullHeight"}  >

                        {
                            aptExplorer()
                        }


                    </Box>
                </Modal.Card.Body>
                <Modal.Card.Footer align="center" textSize={7} >
                    {
                        selectedEntities.projects.length ?
                            <Box className='m-1 p-2'>
                                {selectedEntities.projects.length} Project Added
                            </Box> : null
                    }

                    {
                        selectedEntities.assets.length ?
                            <Box className='m-1 p-2'>
                                {selectedEntities.assets.length} Asset Added
                            </Box> : null
                    }

                    {
                        selectedEntities.comments.length ?
                            <Box className='m-1 p-2'>
                                {selectedEntities.comments.length} Comment Added
                            </Box> : null
                    }

                    {(selectedEntities.projects.length || selectedEntities.assets.length || selectedEntities.comments.length) ?
                        <Button className="is-text is-capitalized" rounded alignItems='right' textAlign='right' onClick={() => confirmAction("Are you sure to remove all selections ?", () => { setSelectedEntities(emptyTaskAttachments) }, null)}>Clear</Button>
                        : null}

                </Modal.Card.Footer>
            </Modal.Card>
        </Modal >

    }




    return modal()
}

export default ModalExplorer