import React, { useState } from "react";
import { ProjectContext } from "./ProjectContext";
import { UploadContext } from "./UploadContext";

const AllContexts = (props) => {

    let initialUploadsState = {
        files: [],
        newAsset: null,
        uploading: false,
        filesBatch: [],
        progress: 0
    }

    const [currentProject, setCurrentProject] = useState(null);
    const [uploads, setUploads] = useState(initialUploadsState);



    return <>
        <ProjectContext.Provider value={[currentProject, setCurrentProject]} >
            <UploadContext.Provider value={{ uploads, setUploads }} >
                {props.children}
            </UploadContext.Provider>
        </ProjectContext.Provider>
    </>
}

export default AllContexts



