
import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';
import ColumnAux from '../../Containers/ColumnAux';
import finalise_animate from '../../assets/finalise-animate.gif'
import { AnimationType } from '../../Models/EnumsAndPointers';



const ShowAnimation = (props) => {


    const [show, setShow] = useState(true)

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setTimeout(()=>{
                if (mounted){
                    setShow(false)
                }
            }, 3000)
        }
        return () => (mounted = false)

    }, [])


    const finalise_animation = () => {
        return <div className='' >
            <Box shadowless className=' has-background-transparent has-text-centered'>
                <ColumnAux isMiddle={true} size={1} className="has-text-centered">
                    <Image alignContent='center' alignItems='center' textAlign={"center"} size={128} src={finalise_animate} className=" is-centered has-text-centered " />
                </ColumnAux>
            </Box>
        </div>
    }

    const modal = () => {

        
        return <Modal show={show} closeOnBlur={true} showClose={false} onClose={props.closed} className=" has-background-transparent almostNoModalBg backblurOnly animate-bottom " >
            <Modal.Card shadowless radiusless className=" maxW300 minW300 has-background-transparent ">
                {/* {props.name === AnimationType.assetFinalised ?  finalise_animation()  : null} */}
                {finalise_animation()}
            </Modal.Card>
        </Modal >

    }


    return modal()


}

export default ShowAnimation