import React, { useEffect, useRef, useState } from 'react'
import { CommonEntityFilter, doSelectionsContain, folderOptions, ProjectFolderLevel, SelectableEntity } from '../../Models/EnumsAndPointers'
import { Project } from '../../Models/Project'
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import { FiChevronRight } from 'react-icons/fi';
import LoadingAux from '../../Containers/LoadingAux';
import AssetExplorer from './AssetExplorer';



const ProjectExplorer = (props) => {

    const [projects, setProjects] = useState([])
    const [exploredProject, setExploredProject] = useState(null)
    const [isFetching, setIsFetching] = useState(false)
    const headerRef = useRef(null)

    const scrollToTop = () => {

        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.scrollIntoView({ behavior: 'instant', block: 'start' })
            } else {
                const element = document.getElementById('projectExpTop')
                if (element) {
                    element.scrollIntoView({ behavior: 'instant', block: 'start' });
                }
            }
        }, 500)
    };




    const { currentFolder } = props
    useEffect(() => {
        if (currentFolder === ProjectFolderLevel.project && exploredProject !== null) {
            setExploredProject(null)
        }
    }, [currentFolder, exploredProject])

    // list folders
    // go into semifolders
    // show select on last screens

    useEffect(() => {
        let isMounted = true;
        setIsFetching(true)
        Project.getAll(null, (succ, projs, errMsg) => {
            if (isMounted) {
                setIsFetching(false)
            }
            if (succ) {
                if (isMounted) {
                    setProjects(projs)
                }
            } else {
                console.log(errMsg)
            }
        })

        return () => (isMounted = false)
    }, [])







    const clearSelections = () => {
        setExploredProject(null)
    }


    const projectExplored = (ast) => {
        setExploredProject(ast)
        props.folderLevelChanged(ProjectFolderLevel.assets, "Assets of - " + ast.name)
    }





    const row = (opt, hasImage, isSelectable) => {

        let selectedInfo = doSelectionsContain(props.selectedEntities, ProjectFolderLevel.project, opt)



        // return <tr key={opt.name} className=" my-0  " onClick={() => { optSelectionToggled(opt) }} >
        return <tr key={opt.id} className=" my-0  is-fullwidth " >

            {
                hasImage ?

                    <td align="left" className=" maxW100 " >
                        {/* <Image className="ml-2 mt-2">{opt.name}</Image> */}
                        <Image size={48} src={opt.imageUrl ?? "https://images.unsplash.com/photo-1627482965115-e8e29bbcd451?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"} className=" ml-0 overflow-hidden is-bordered border-radius-6 " />
                    </td>

                    :
                    null
            }

            <td align="left" className=" minW200 " >
                <p className="ml-2 mt-2">{opt.name} </p>
            </td>

            <td align="right  " className='m-0 pr-0 is-narrow '   >
                {
                    selectedInfo.isSelected ?
                        <Button size="small" color='black' rounded className='m-0 mt-2 is-right' onClick={() => props.entityRemoved(ProjectFolderLevel.project, opt)} >Added</Button>
                        :
                        <>
                            {
                                isSelectable ?
                                    <Button size="small" rounded className='m-0 mt-2 is-right' onClick={() => props.entitySelected(ProjectFolderLevel.project, opt)} >Add</Button>
                                    :
                                    null
                            }
                        </>
                }
            </td>
            <td align="right " className='m-0 pl-0 pr-1 is-narrow' >
                <Button size="small" type="text" className=" mt-2  has-background-transparent is-borderless has-text-grey" onClick={() => projectExplored(opt)}  >
                    Assets
                    <span className=' is-size-5 has-text-black has-text-weight-bold mx-2'>{selectedInfo.hasSelectedChildren ? "•" : null}</span>
                    <FiChevronRight className=" is-size-6 " />
                </Button>
            </td>

        </tr>
    }


    const isSelectable = () => {
        let slable = { allowed: false, multiple: false }
        if (props.selectables) {
            let entities = props.selectables.entities ?? []
            let multiSelection = props.selectables.multiSelection

            console.log("SELECTABLE LIST IS")
            console.log(props.selectables)
            
            if (entities.includes(SelectableEntity.projects)) {
                console.log("PROJECTS FOUND")
                console.log(props.selectables)
                slable.allowed = true
            }
            if (multiSelection) {
                console.log("multiSelection true FOUND")
                slable.multiple = true
            }
        }
        return slable
    }

    const up = () => {

        let iSLA = isSelectable()
        let allowSelection = isSelectable().allowed
        
        if (iSLA.multiple === false){
            if (props.selectedEntities){
                if (props.selectedEntities.projects){
                    if (props.selectedEntities.projects.length){
                        allowSelection = false
                    }
                }
            }
        }

        let showProjects = projects

        if (props.showSelectedOnly){
            let selectedProjs = props.selectedEntities.projects ?? []
            let selectedProjIds = selectedProjs.map(p => p.id)
            showProjects = projects.filter((p) => selectedProjIds.includes(p.id))
        }else{
            if (props.statusFilter){
                if (props.statusFilter !== CommonEntityFilter.all){
                    showProjects = showProjects.filter((p) => p.status === props.statusFilter)
                }
            }
        }


        return <div>

            <Panel className="m-0 is-shadowless ">
                <Table className="minW300 is-fullwidth h-100vh" hoverable={false} striped={false} bordered={false}  >
                    <tbody className='is-fullheight' >
                        {
                            showProjects.length ?
                            showProjects.map((opt) => {
                                    return row(opt, false, allowSelection)
                                })
                                :
                                <p className=' has-text-centered my-6 has-text-grey-light' > No {props.showSelectedOnly ? "Selected " : null}Projects </p>

                        }

                    </tbody>
                </Table>
            </Panel>
        </div>
    }

    const beforeClose = () => {
        props.close()
    }


    const modal = () => {
        return exploredProject ? <AssetExplorer {...props} projectId={exploredProject.id} currentFolder={props.currentFolder} folderLevelChanged={props.folderLevelChanged} close={props.close} /> :
            <LoadingAux isLoading={isFetching} fullHeight={true} >
                <div ref={headerRef} id="projectExpTop"></div>
                {up()}
            </LoadingAux>

    }




    return modal()
}

export default ProjectExplorer