import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';

import { AiOutlineAppstoreAdd, AiFillFacebook, AiOutlineFacebook, AiFillInstagram, AiOutlineInstagram, AiFillLinkedin, AiOutlineLinkedin, AiOutlineTwitter, AiFillMediumSquare, AiFillYoutube, AiOutlineYoutube } from 'react-icons/ai';
import { RiFileUploadLine, RiMoreFill, RiImageAddLine, RiTwitterLine, RiMediumLine, RiSpotifyLine, RiSnapchatLine } from 'react-icons/ri';
import { FaSpotify, FaPinterest, FaSnapchatGhost } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { TiDocumentAdd } from 'react-icons/ti';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { GrFormClose } from 'react-icons/gr';
import { IoMdPersonAdd, IoMdAddCircleOutline, IoMdAddCircle } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import moment from 'moment'
import { Asset, AssetFilter } from '../../Models/Asset';

import { MdSchedule } from 'react-icons/md';

// IoMdPersonAdd
import ReactTooltip from 'react-tooltip';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { allAllowedChannels, allAssetCreateStatusType, all_ProjectFilter_CreatedOrShared, all_ProjectStatuses, socialMediaChannels, socialMediaChannels_HexColors, UserPickerFor } from '../../Models/EnumsAndPointers';
import UserAvatar from '../Calendar/UserAvatar';
import UserPicker from '../Calendar/UserPicker';
import { getTimeAfterMins, randomCode } from '../../utilities/Utilities';
import { Event } from '../../Models/Event';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { ProjectFilter } from '../../Models/Project';


// AiFillFacebook, AiOutlineFacebook
// AiFillInstagram, AiOutlineInstagram
// AiFillLinkedin, AiOutlineLinkedin
// AiOutlineTwitter, RiTwitterLine
// FaPinterest, RiPinterestLine
// AiFillMediumSquare, RiMediumLine
// FaSpotify, RiSpotifyLine
// FaSnapchatGhost, RiSnapchatLine, 




const AssetsFilter = (props) => {


    const [showUserPickerFor, setShowUserPickerFor] = useState(null)


    const usersAreSelected = (arr) => {
        console.log("Got these Project member filters")
        console.log(arr);

        if (props.filter) {
            let filterCopy = ProjectFilter.copyFrom(props.filter)
            if (showUserPickerFor === UserPickerFor.projectAddedByFilter) {
                filterCopy.addedBy = arr
            }
            if (showUserPickerFor === UserPickerFor.projectMemberFilter) {
                filterCopy.members = arr
            }
            props.filterChanged(filterCopy)
        }
    }

    const userRemovePressed = (usr, forPicker) => {
        let filterCopy = ProjectFilter.copyFrom(props.filter)
        if (forPicker === UserPickerFor.projectMemberFilter) {
            let ussrs = [...filterCopy.members].filter((u) => {
                return u.id !== usr.id
            })
            filterCopy.members = ussrs
        }


        if (forPicker === UserPickerFor.projectAddedByFilter) {
            let ussrs = [...filterCopy.addedBy].filter((u) => {
                return u.id !== usr.id
            })
            filterCopy.addedBy = ussrs
        }
        props.filterChanged(filterCopy)
    }

    const clearFilter = () => {
        let newF = new ProjectFilter()
        props.filterChanged(newF)
    }



    const clearSelections = () => {
        setShowUserPickerFor(null)
    }





    const getSelectedUsers = () => {
        let usrs = []
        if (props.filter) {
            if (showUserPickerFor === UserPickerFor.taskAssign) {
                usrs = props.filter.members
            }

            if (showUserPickerFor === UserPickerFor.taskAddedBy) {
                usrs = props.filter.addedBy
            }
        }
        return usrs
    }


    const projectCreatedOrSharedChanged = (tp) => {
        let filterCopy = ProjectFilter.copyFrom(props.filter)
        filterCopy.createdOrShared = tp
        props.filterChanged(filterCopy)
    }


    const projectStatusChanged = (st) => {
        let filterCopy = ProjectFilter.copyFrom(props.filter)
        filterCopy.status = st
        props.filterChanged(filterCopy)
    }


    const modal = () => {

        let filter = props.filter


        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light height100vc lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" height100vc  m-0 p-0" >
                    <Modal.Card.Header showClose className="    ">
                        <Modal.Card.Title className="has-text-grey-light" textSize={6} >Filter By</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0 m-0">

                        {showUserPickerFor ? <UserPicker for={showUserPickerFor} selectedUsers={getSelectedUsers()} allMembers={props.allMembers} usersAreSelected={usersAreSelected} close={clearSelections} /> : null}

                        <Box shadowless className="  " >

                            <div>
                                <Form.Field>
                                    <Form.Label n className="is-size-7 mt-minus-10 mb-0 ml-2  mt-5" >Created by me Or Shared </Form.Label>
                                    <Form.Control>
                                        <Form.Select
                                            name="createdOrShared"
                                            size='small'
                                            value={filter.createdOrShared}
                                            className=" mt-2 ml-2 "
                                            onChange={(e) => {
                                                projectCreatedOrSharedChanged(e.target.value)
                                            }}
                                        >
                                            {
                                                all_ProjectFilter_CreatedOrShared.map((act) => {
                                                    return <option key={act} value={act}>{act}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Control>
                                    <br />
                                </Form.Field>

                            </div>


                            <Form.Field>
                                <Form.Label className="is-size-7 mt-2 mb-0 ml-2 "  > Has Members
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.projectMemberFilter) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            filter.members.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => { userRemovePressed(su, UserPickerFor.projectMemberFilter) }} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>
                            <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Added By
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.projectAddedByFilter) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            filter.addedBy.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => { userRemovePressed(su, UserPickerFor.projectAddedByFilter) }} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>
                            <br />



                            {/* <Form.Field>
                                <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2  mt-5" >Status</Form.Label>
                                <Form.Control>
                                    <Form.Select
                                        name="status"
                                        size='small'
                                        value={filter.status}
                                        className=" mt-2 ml-2 "
                                        onChange={(e) => {
                                            projectStatusChanged(e.target.value)
                                        }}
                                    >
                                        {
                                            all_ProjectStatuses.map((act) => {
                                                return <option key={act} value={act}>{act}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Control>
                                <br />
                            </Form.Field> */}

                        </Box>
                        <br />

                    </Modal.Card.Body>
                    <Modal.Card.Footer renderAs={Button.Group} align="center" className="pt-2" >
                        <Button className="is-size-7 has-text-centered has-text-grey m-0 is-borderless has-background-transparent " onClick={clearFilter} > CLEAR </Button>
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }


    return modal()



}

export default AssetsFilter