import React, { useState } from 'react'
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon, Message } from 'react-bulma-components';
import ColumnAux from '../../Containers/ColumnAux';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { CRUDAction } from '../../Models/EnumsAndPointers';




const TextEditor = (props) => {

    const [text, setText] = useState(props.text)

    const textChangeHandler = (val) => {
        setText(val)
    }

    const textChangeDone = () => {
        props.textChangeDone(text, props.payload, props.crud)
    }

    const optPressed = (val) => {
        if (val === "delete") {
            if (props.onDelete) {
                props.onDelete()
            }
        }
    }


    const modal = () => {

        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className="m-0" radiusless>
                    <Modal.Card.Header showClose={false} className="  is-borderless has-background-grey-lightest m-0  pb-0 ">

                        <Modal.Card.Title className=" m-0 p-0 is-borderless " >
                            {
                                props.showDeleteOption ?
                                    <Dropdown left renderAs="button" className="  has-text-left  ml-minus-10 mt-minus-10  is-small is-arrowless has-background-transparent  is-borderless "
                                        onChange={(val) => { optPressed(val) }}
                                        label={
                                            <HiOutlineDotsHorizontal className="is-size-5 mt-2 has-text-grey" />
                                        }>
                                        <Dropdown.Item renderAs="a" value="delete" >Delete</Dropdown.Item>
                                    </Dropdown>
                                    : null
                            }
                            <Button rounded className='is-pulled-right mb-3 ' size="small" color={"black"} onClick={textChangeDone}>Done</Button>
                        </Modal.Card.Title>
                    </Modal.Card.Header>

                    <Modal.Card.Body className="has-background-grey-lightest m-0 pb-0 pt-2 is-borderless">
                        <ColumnAux size={12} isMiddle={true}>
                            <Box shadowless className="has-background-transparent mt-0 pt-0 mb-0" >



                                <Form.Field className="">
                                    {/* <Form.Label size="small">Content</Form.Label> */}
                                    <Form.Control>
                                        <Form.Textarea
                                            disabled={props.crud === CRUDAction.read}
                                            className="h-300px "
                                            size="normal"
                                            color="text"
                                            // rows={3}
                                            fixedSize={true}
                                            placeholder="Add text here..."
                                            value={text}
                                            onChange={(e) => { textChangeHandler(e.target.value) }}
                                            onClick={(e) => { if (props.crud === CRUDAction.read) { alert("Notes can only be created. Not edited.") }   }}
                                        />
                                    </Form.Control>
                                </Form.Field>

                                <p className='is-size-8 has-text-grey'>{props.footerLine}</p>

                                <p className='is-size-8 has-text-grey mt-2'>{ props.crud === CRUDAction.read  ? "Cannot be edited" : null} </p>

                            </Box>
                        </ColumnAux>
                        {/* <Button size="normal" color="text" className="is-pulled-left is-text ml-4 noDecoration " onClick={props.close}> Cancel </Button>
                        <Button size="normal" color="black" className="is-pulled-right mx-4 px-5" onClick={close}> Save </Button> */}
                    </Modal.Card.Body>
                    {/* <Modal.Card.Footer className="pt-0 pb-6 has-text-right has-background-grey-lightest is-borderless " >
                        <Button fullwidth size="normal" color="black" className="mx-5" onClick={textChangeDone}> Done </Button>
                    </Modal.Card.Footer> */}
                </Modal.Card>
            </Modal >
        )
    }



    return modal()

}

export default TextEditor