import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';

import { Button, Columns, Container, Heading, Section, Notification, Box, Content } from 'react-bulma-components';
import Dashboard from '../Pages/Dashboard';
import WorkspaceAux from './WorkspaceAux'
import Sign from '../Pages/Sign';
import { isCurrentUser, initialiseParse, getCurrentWorkspaceId, setCurrentWorkspaceId, setCurrentWorkspace, getCurrentWorkspace, setRevalidateWSMembership, getRevalidateWSMembership } from '../utilities/Login'
import Entry from '../Pages/Entry';


const IsLoggedAux = (props) => {

  const [cu, setCu] = useState(null)
  const [wk, setWk] = useState(null)
  const [wkValidated, setWkValidated] = useState(false)



  // Check public or invited user


  useEffect(() => {
    initialiseParse()

    //checkCurrentUser copy
    let thisUser = isCurrentUser()
    if (thisUser) {
      setCu(thisUser)
      checkAndSetWorkspace()
    } else {
      setCu(null)
    }

  }, [])


  const changeRevalidateWSMember = (val) => {
    setWk(null)
    setWkValidated(false)
    setRevalidateWSMembership(val)
    // setRevalidateWSMembership(val)
  }

  const changeWKValidated = (val) => {
    setWkValidated(val)
  }

  const checkCurrentUser = () => {
    let thisUser = isCurrentUser()
    if (thisUser) {
      setCu(thisUser)
      checkAndSetWorkspace()
    } else {
      setCu(null)
    }
  }

  const userLoggedOut = () => {
    setCu(null)
    setWk(null)
  }

  const userSigned = (user) => {
    setCu(isCurrentUser())
    checkAndSetWorkspace()
  }



  const checkAndSetWorkspace = () => {
    let existingWK = getCurrentWorkspace()

    const queryString = window.location.search;
    if (queryString){
      const params = new URLSearchParams(queryString)
      if (params){
        let inv = params.get('inv')
        if (inv){
          return
        }
      }
    }
    // console.log(queryString);

    let getReval = getRevalidateWSMembership()

    if (existingWK && getReval === false) {
      setWk(existingWK)
    }
  }


  const activateWorkspace = (wk) => {
    // setCurrentWorkspaceId(wkId)
    setRevalidateWSMembership(false)
    setCurrentWorkspace(wk)
    setWk(wk)
    // setTimeout(() => {
    //   checkAndSetWorkspace()
    // }, 200);
  }



  //public = public
  //invite = invite
  //reference = reference



  return (
    <div className="App " >
      {
        cu ?
          <div>
              {/* <WorkspaceAux isInvitedToWorkspace={null} isPublic={false} projectPublicId={null} workspace={wk} userLoggedOut={userLoggedOut} /> */}
              <Dashboard wkValidated={wkValidated} changeWKValidated={changeWKValidated} changeRevalidateWSMember={changeRevalidateWSMember}  isPublic={false} projectPublicId={null} workspace={wk} activateWorkspace={activateWorkspace} userLoggedOut={userLoggedOut} />
          </div>
          // <Entry />
          : <Sign userSigned={userSigned} />
      }
    </div>
  );



}

export default IsLoggedAux