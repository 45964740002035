import React, { useContext, useEffect, useState, useRef } from 'react';
import { Card, Heading, Media, Content, Tile, Box, Container, Section, Button, Columns, Tabs, Tag } from 'react-bulma-components';
import LoadingAux from '../../Containers/LoadingAux';
import { ProjectContext } from '../../Contexts/ProjectContext';
import { Project, ProjectFilter } from '../../Models/Project';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { randomCode } from '../../utilities/Utilities';
import ProjectCard from './ProjectCard'
import ProjectPage from './ProjectPage';
import ProjectsFilter from './ProjectsFilter'
import { RiCheckDoubleFill } from 'react-icons/ri'
import { BiFilter, BiSort, BiSelectMultiple } from 'react-icons/bi';
import { AiOutlineFolderAdd } from 'react-icons/ai';
import { GrAdd, GrFormAdd } from 'react-icons/gr';
import { ProjectFilter_CreatedOrShared, ProjectStatus } from '../../Models/EnumsAndPointers';
import ColumnAux from '../../Containers/ColumnAux';
import Guide from '../Guides/Guide';
import AddButton_Img from '../../assets/AddButton.png'
// import AssetsProjsWorks_Img from '../../assets/Asstes-Projects-Workspace.png'
import NoProject from '../../assets/noProject.png'



const Projects = (props) => {


    const ProjectTABS = {
        pending: "Pending",
        finalised: "Finalised",
        acrhived: "Archived"
    }


    const [currentTab, setCurrentTab] = useState(ProjectTABS.pending)

    const [currentProject, setCurrentProject] = useContext(ProjectContext)

    const [addProject, setAddProject] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null)

    const [projects, setProjects] = useState([])

    const [isFetching, setIsFetching] = useState(false)
    const [noProjs, setNoProjs] = useState(false)

    const [showFilter, setShowFilter] = useState(false)
    const [showSorter, setShowSorter] = useState(false)
    const [filter, setFilter] = useState(new ProjectFilter())
    const [sortBy, setSortBy] = useState(null)

    const headerRef = useRef(null)



    useEffect(() => {

        let isMounted = true;
        setIsFetching(true)
        Project.getAll(null, (succ, projs, errMsg) => {
            if (isMounted) {
                setIsFetching(false)
            }
            if (succ) {
                if (isMounted) {
                    if (projs.length === 0) {
                        setNoProjs(true)
                    }
                    setProjects(projs)
                }
            } else {
                console.log(errMsg)
            }
        })

        return () => (isMounted = false)

    }, [])



    const scrollToTop = () => {

        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.scrollIntoView({ behavior: 'instant', block: 'start' })
                // window.scrollBy(0, -10)
            } else {
                // window.scrollTo({
                //     top: 0,
                //     behavior: "smooth"
                // });
                const element = document.getElementById('projectTop')
                if (element) {
                    element.scrollIntoView({ behavior: 'instant', block: 'start' });
                }
            }
        }, 500)
    };


    const showFilterModal = () => {
        setShowFilter(true)
    }

    const filterChanged = (flt) => {
        setFilter(() => {
            return flt
        })
    }

    const clearSelections = () => {
        setShowFilter(false)
    }


    const addProjectPressed = () => {
        scrollToTop()

        props.toggleFeaturesTab(false)
        setAddProject(true)
        // alert("Add Project Pressed")
    }

    const projectIsSelected = (proj) => {
        scrollToTop()

        props.toggleFeaturesTab(false)

        // alert("SELECTING PROJECT")

        setSelectedProject(proj)
        // Change current project context
        setCurrentProject(proj)

        // setTimeout(() => {

        // }, 600);

    }

    const unselect = () => {
        props.toggleFeaturesTab(true)
        setAddProject(false)
        setSelectedProject(null)
        // Change current project context
        setCurrentProject(null)
    }

    const projectAdded = (proj) => {
        console.log("PROJECT ADDED CALLED");
        let pros = [...projects]
        pros.unshift(proj)
        setProjects(pros)
    }

    const changeCurrentTab = (tb) => {
        setCurrentTab(tb)
    }


    const getFilteredProjectsByTab = (tab, countOnly) => {
        let allProjs = [...projects]
        let filteredProjs = []

        // console.log("ALL PROJECTS ARE");
        // console.log(allProjs);

        if (tab) {
            filteredProjs = allProjs.filter((a) => {
                if (tab === ProjectTABS.pending) {
                    // console.log("tab is ProjectTABS.pending");
                    // console.log(`${a.status} is NOT ${ProjectStatus.pending}`);
                    return a.status === ProjectStatus.pending
                }


                if (tab === ProjectTABS.finalised) {
                    return a.status === ProjectStatus.finalised
                }

                if (tab === ProjectTABS.acrhived) {
                    return a.status === ProjectStatus.archived
                }

                return false
            })

            // console.log("FILTERED PROJECTS ARE 1");
            // console.log(filteredProjs);

        } else {
            filteredProjs = allProjs
        }

        // console.log("FILTERED PROJECTS ARE");
        // console.log(filteredProjs);

        // removing archived assets
        if (tab !== ProjectTABS.acrhived) {
            filteredProjs = filteredProjs.filter((a) => { if (a.isArchived === true) { return false }; return true })
        }



        //BY FILTERS
        if (filter.isActive()) {
            if (filter.members.length > 0) {
                let usrsIds = filter.members.map((u) => { return u.id })
                filteredProjs = filteredProjs.filter((ast) => {
                    let commonFound = ast.members.some(e => usrsIds.includes(e))
                    if (commonFound) {
                        return true
                    }
                    return false
                })
            }



            if (filter.addedBy.length > 0) {
                let usrsIds = filter.addedBy.map((u) => { return u.id })
                filteredProjs = filteredProjs.filter((ast) => {
                    let isAddedByCommon = usrsIds.includes(ast.byUserInfo.id)
                    if (isAddedByCommon) {
                        return true
                    }
                    return false
                })
            }

            if (filter.createdOrShared !== ProjectFilter_CreatedOrShared.all) {
                let cuIno = currentUserInfo()
                filteredProjs = filteredProjs.filter((ast) => {
                    if (filter.createdOrShared === ProjectFilter_CreatedOrShared.createdByMe) {
                        return ast.byUserInfo.id === cuIno.id

                    }
                    // else
                    return ast.byUserInfo.id !== cuIno.id
                })
            }

            if (filter.status !== ProjectStatus.all) {
                filteredProjs = filteredProjs.filter((ast) => {
                    return ast.status === filter.status
                })
            }
        }

        return (countOnly ? filteredProjs.length : filteredProjs)
    }

    const projectChanged = (proj) => {

        let allProjs = [...projects]
        let index = allProjs.findIndex((a) => { return a.id === proj.id })
        if (index >= 0) {
            allProjs[index] = proj
            setProjects(allProjs)
        } else {
            console.log("Error - Updated project was not found inside all project list");
        }
    }



    const isAnySelection = () => {
        const decide = (addProject === true) || (selectedProject)
        alert("Decided =" + decide)
        return decide
    }

    const myProjects = () => {

        let finalProjs = getFilteredProjectsByTab(currentTab, false)

        let showNoProjs = false
        if (projects.length === 0 && noProjs) {
            showNoProjs = true
        }

        // AssetsProjectsWorkspaces_Img

        return (
            <Container className="hideScrollBar">
                <LoadingAux className="is-centered has-text-centered mt-0" isMiddle={true} isMain={true} isLoading={isFetching} loaderHeight={400} >
                    {/* <Tabs  type="toggle-rounded" align="center"  textWeight="semibold" size="small" className="m-0 px-0 has-background-grey-lightest " >
                        <Tabs.Tab className="" active={currentTab === ProjectTABS.reviewing} onClick={() => { changeCurrentTab(ProjectTABS.reviewing) }} >                <span className="px-4">{ProjectTABS.reviewing}        </span></Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === ProjectTABS.finalised} onClick={() => { changeCurrentTab(ProjectTABS.finalised) }} >        <span className="px-4">{ProjectTABS.finalised}                </span></Tabs.Tab>
                    </Tabs> */}
                    <Columns className=" m-0 p-0 pb-2">
                        <Columns.Column className="">
                            <Tabs type="boxed" textAlign="left" textWeight="semibold" size="small" className="m-0 px-0  has-background-grey-lightest " >
                                <Tabs.Tab className="" active={currentTab === ProjectTABS.pending} onClick={() => { changeCurrentTab(ProjectTABS.pending) }} >     <span className="px-4">{ProjectTABS.pending}  <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredProjectsByTab(ProjectTABS.pending, true)} </span> </span></Tabs.Tab>
                                <Tabs.Tab className="" active={currentTab === ProjectTABS.finalised} onClick={() => { changeCurrentTab(ProjectTABS.finalised) }} >       <span className="px-4"> {ProjectTABS.finalised} <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredProjectsByTab(ProjectTABS.finalised, true)} </span>   </span></Tabs.Tab>
                                <Tabs.Tab className="" active={currentTab === ProjectTABS.acrhived} onClick={() => { changeCurrentTab(ProjectTABS.acrhived) }} >      <span className="px-4 has-text-grey-light">{ProjectTABS.acrhived} <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredProjectsByTab(ProjectTABS.acrhived, true)} </span> </span></Tabs.Tab>
                            </Tabs>
                        </Columns.Column>
                        <Columns.Column className=" has-text-right " narrow>
                            <Button size="small" rounded className=" px-2 mx-4 is-pulled-right has-background-warning " onClick={addProjectPressed}> <GrFormAdd className="  m-0 is-size-5" /></Button>
                            <Button.Group className="is-pulled-right mt-0 " >
                                {/* <Button backgroundColor="grey-lightest" className=" has-text-grey is-borderless is-hover-dark " size="small" onClick={showFilterModal} > <BiFilter className="mr-1 is-size-6" /> Filter</Button> */}
                                <Button backgroundColor={filter.isActive() ? "black" : "grey-lightest"} textColor={filter.isActive() ? "white" : "grey"} textWeight={filter.isActive ? "semibold" : "normal"} className=" is-borderless  " size="small" onClick={showFilterModal} > <BiFilter className="mr-1 is-size-6" /> {filter.isActive() ? "Filtered" : "Filter"} {filter.isActive() ? <Tag rounded className=" is-light has-text-weight-semibold py-1 ml-2" >{filter.count() ?? ""}</Tag> : null} </Button>

                                {/* <Button backgroundColor="grey-lightest" className={" has-text-grey is-borderless is-hover-dark "} size="small"   > <BiSort className="mr-1" /> Sort</Button> */}
                                {/* <Button backgroundColor="grey-lightest" className=" has-text-grey is-borderless is-hover-dark " size="small"> <BiSelectMultiple className="mr-1 is-size-6" /> Select</Button> */}
                            </Button.Group>
                        </Columns.Column>
                    </Columns>
                    <Box className="grid-container m-0 is-radiusless has-background-transparent" shadowless>
                        {/* <Columns className="is-vcentered">
                        <Columns.Column className="">
                            <Box shadowless className=" has-text-centered has-background-transparent">
                                <Button radiusless size="large" className="has-background-transparent is-borderless is-hover-dark p-6" textWeight="medium" textColor="grey" textSize={6} onClick={addProjectPressed}> Add Project +</Button>
                            </Box>
                        </Columns.Column>
                    </Columns> */}


                        {

                            showNoProjs ?
                                <>
                                    <ColumnAux size={5} isMiddle={true}>
                                        <Guide noVid={false} type="image" src={NoProject} title="No projects here" description="Click + buttton on top-right to create a project" boxClassName="is-shadowless"  isDense={true} />
                                    </ColumnAux>

                                </>

                                :
                                <>
                                    {
                                        finalProjs.length ?
                                            finalProjs.map((thisProj) => {
                                                return <ProjectCard key={randomCode(6)} project={thisProj} projectChanged={projectChanged} onClick={projectIsSelected} />
                                            })
                                            :
                                            <ColumnAux size={4} isMiddle={true}>
                                                <p className='has-text-grey-light has-text-centered is-size-6 mt-6'>Nothing here</p>
                                            </ColumnAux>
                                    }
                                </>
                        }



                    </Box>
                </LoadingAux>


            </Container>

        )
    }




    return (
        <div className={` is-fullHeightAlways ${(addProject || selectedProject) ? " overflow-hidden" : "overflow-scroll"}`}>
            <div ref={headerRef} id="projectTop"></div>
            {showFilter ? <ProjectsFilter filter={filter} filterChanged={filterChanged} close={clearSelections} /> : null}

            {(addProject || selectedProject) ?
                <div>
                    {addProject ? <ProjectPage isNewProject={true} goBack={unselect} projectAdded={projectAdded} /> : null}
                    {selectedProject ? <ProjectPage project={selectedProject} goBack={unselect} /> : null}
                </div>
                :
                <div>
                    {myProjects()}
                </div>
            }
            <br />
            <br />
            <br />
        </div>
    )
}

export default Projects