import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';

import { Button, Columns, Container, Heading, Section, Notification, Box, Content, Image, Form } from 'react-bulma-components';
import Dashboard from '../Pages/Dashboard';
import WorkspaceImage from '../assets/workspace.png'
import Sign from '../Pages/Sign';
import { isCurrentUser, initialiseParse, getCurrentWorkspaceId, setCurrentWorkspaceId, setMemberWorkspaces, getOwnedWorkspacesIds, getMemberWorkspaces, currentUserInfo, getCurrentWorkspace, setCurrentWorkspace, expandCurrentWorkspace } from '../utilities/Login'
import Entry from '../Pages/Entry';
import ColumnAux from './ColumnAux';
import CreateWorkspace from '../Components/Sign/CreateWorkspace'
import { Workspace } from '../Models/Workspace';
import { SubscriptionStatus, UserRole } from '../Models/EnumsAndPointers';
import { Invitation } from '../Models/Invitation';


const WorkspaceValidatorAux = (props) => {

    // check current user role in workspace
    // check workspace billing status


    const [gate, setGate] = useState(null)
    const [reminderModal, setReminderModal] = useState(null)
    const [reportProblem, setReportProblem] = useState(null)

    useEffect(() => {

        let isMounted = true;
        if (props.resetGate) {
            setGate(null)
        }


        // get current workspace info 
        var wkInfo = getCurrentWorkspace()

        var userNotAMemberNow = false
        var role = null


        Workspace.getWS(wkInfo.id, (succ, result, errMsg) => {
            if (isMounted) {
                if (succ && result) {
                    let wk = result
                    let membersInfo = wk.membersInfo
                    let byUserInfo = wk.byUserInfo
                    let status = wk.subsStatus
                    let subsEnds = wk.subsEnds
                    let subsPlanName = wk.subsPlanName

                    let cuInfo = currentUserInfo()

                    console.log("Current User Info is");
                    console.log(cuInfo);

                    let thisUserInfoMM = membersInfo.filter((thisM) => {
                        return thisM.id === cuInfo.id
                    })

                    console.log("thisUserInfoMM is");
                    console.log(thisUserInfoMM);

                    if (thisUserInfoMM) {
                        let thisUserMM = thisUserInfoMM[0]
                        if (thisUserMM) {
                            let thisUserROLE = thisUserMM.role
                            if (thisUserROLE) {
                                console.log("thisUserROLE is " + thisUserROLE);
                                role = thisUserROLE
                            }

                        }
                        if (byUserInfo.id === cuInfo.id) {
                            role = UserRole.admin
                        }
                    }

                    if (role === null) {
                        userNotAMemberNow = true
                    }

                    wkInfo.membersInfo = membersInfo
                    wkInfo.byUserInfo = byUserInfo
                    wkInfo.status = status
                    wkInfo.subsEnds = subsEnds
                    wkInfo.subsPlanName = subsPlanName
                    wkInfo.currentUserRole = role
                    wkInfo.userStillAMember = !userNotAMemberNow
                    if (isMounted) {
                        expandCurrentWorkspace(wkInfo)

                        if (userNotAMemberNow === true) {
                            props.changeRevalidateWSMember(true)
                        } else {

                            // if (status === SubscriptionStatus.locked) {
                            //     let gateHeading = "Workspace needs Billing info."
                            //     let gateSubHeading = "This workspace needs an update for billing. Go to User Menu on top right > Billing."
                            //     setGate({ heading: gateHeading, subHeading: gateSubHeading })
                            // }

                            // if (status === SubscriptionStatus.cancelled) {
                            //     let gateHeading = "Workspace is cancelled"
                            //     let gateSubHeading = "This workspace has been cancelled. It can be reactivated by admin by updating billing info. Go to User Menu on top right > Billing."
                            //     setGate({ heading: gateHeading, subHeading: gateSubHeading })
                            // }

                            props.changeWKValidated(true) // for Main Menu

                        }
                    }

                } else {
                    console.log(errMsg);
                    setReportProblem("A problem occured while validating workspace. Report this with your workspace name.")
                }
            }
        })


        return () => (isMounted = false)

    }, [props])



    let gateInfo = () => {
        return <Box className="p-5 has-text-centered">
            <Heading size={3} spaced={false}  > {gate.heading} </Heading>
            <Heading subtitle size={6} textColor="grey" > {gate.subHeading} </Heading>
        </Box>
    }


    return (
        <div>
            {
                !gate ?
                    props.children
                    // <Dashboard workspace={wk} isPublic={props.isPublic} publicProjectId={null} activateWorkspace={activateWorkspace} userLoggedOut={props.userLoggedOut} />
                    :
                    gateInfo()
            }
        </div>
    );



}

export default WorkspaceValidatorAux