import Parse from 'parse'
import { currentUserInfo, getCurrentWorkspaceId, isCurrentUser, validateMemberInfoRoles } from '../utilities/Login';
import { removeKeysFromUserInfos, removeKeysFromUserInfos_Arr, removeKeysFromUserInfo_Obj } from '../utilities/Utilities';
import { appMeta, CoverImageType, EntityType, NotificationFor, ProjectFilter_CreatedOrShared, ProjectStatus, userPointer, UserRole, workspacePointer } from './EnumsAndPointers';
import { Notification } from './Notification';

const ProjectClass = Parse.Object.extend("Project");


export class ProjectFilter {
    constructor(createdOrShared, members, addedBy, status) {
        this.createdOrShared = createdOrShared ?? ProjectFilter_CreatedOrShared.all
        this.members = members ?? []
        this.addedBy = addedBy ?? []
        this.status = status ?? ProjectStatus.all
    }

    static copyFrom = (obj) => {
        let ob = new ProjectFilter()
        ob.createdOrShared = obj.createdOrShared
        ob.members = [...obj.members]
        ob.addedBy = [...obj.addedBy]
        ob.status = obj.status
        return ob
    }

    isActive = () => {
        let isA = false

        if (this.createdOrShared !== ProjectFilter_CreatedOrShared.all) {
            isA = true
        }

        if (this.members.length > 0 ||
            this.addedBy.length > 0
        ) {
            isA = true
        }

        if (this.status !== ProjectStatus.all) {
            isA = true
        }
        return isA
    }

    count = () => {
        let count = 0
        if (this.members.length > 0) {
            count += 1
        }
        if (this.addedBy.length > 0) {
            count += 1
        }

        if (this.createdOrShared !== ProjectFilter_CreatedOrShared.all) {
            count += 1
        }

        if (this.status !== ProjectStatus.all) {
            count += 1
        }
        return count
    }

}


export class Project {

    constructor(id, name, description, coverImageType, coverImageUrl, coverImageColor, createdAt, updatedAt, byUser, byUserInfo, members, membersInfo, workspace, assets, status, isShared, sharedWith, sharedWithInfo, isArchived, archivedBy, archivedByInfo, thisAppMeta) {
        this.id = id
        this.name = name
        this.description = description
        this.coverImageType = coverImageType ?? CoverImageType.default
        this.coverImageUrl = coverImageUrl
        this.coverImageColor = coverImageColor
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.byUser = byUser
        this.byUserInfo = byUserInfo
        this.members = members ?? []
        this.membersInfo = membersInfo ?? []
        this.workspace = workspace
        this.assets = assets ?? []
        this.status = status ?? ProjectStatus.pending
        this.isShared = isShared ?? false
        this.sharedWith = sharedWith ?? []
        this.sharedWithInfo = sharedWithInfo ?? []
        this.isArchived = isArchived ?? false
        this.archivedBy = archivedBy
        this.archivedByInfo = archivedByInfo
        this.appMeta = thisAppMeta ?? appMeta
        this.role = ""
    }

    thumbnailObject = () => {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            coverImageType: this.coverImageType,
            coverImageUrl: this.coverImageUrl,
            coverImageColor: this.coverImageColor,
            byUserInfo: this.byUserInfo,
            workspace: this.workspace
        }
    }

    static newProjectbyCurrentUser = () => {
        let np = new Project()
        let cuInfo = currentUserInfo()
        np.byUser = cuInfo.id
        np.byUserInfo = cuInfo
        return np
    }

    static copyFrom(obj) {
        let ob = new Project()
        ob.id = obj.id
        ob.name = obj.name
        ob.description = obj.description
        ob.coverImageType = obj.coverImageType
        ob.coverImageUrl = obj.coverImageUrl
        ob.coverImageColor = obj.coverImageColor
        ob.createdAt = obj.createdAt
        ob.updatedAt = obj.updatedAt
        ob.byUser = obj.byUser
        ob.byUserInfo = obj.byUserInfo
        ob.members = obj.members
        ob.membersInfo = obj.membersInfo
        ob.workspace = obj.workspace
        ob.assets = obj.assets
        ob.status = obj.status
        ob.isShared = obj.isShared
        ob.sharedWith = obj.sharedWith
        ob.sharedWithInfo = obj.sharedWithInfo
        ob.isArchived = obj.isArchived
        ob.archivedBy = obj.archivedBy
        ob.archivedByInfo = obj.archivedByInfo
        ob.appMeta = obj.appMeta


        return ob
    }

    static initFromPFObject = (obj) => {
        let np = new Project()

        if (!obj.id) {
            return null
        }

        np.id = obj.id
        np.name = obj.get("name") ?? "Not found"
        np.description = obj.get("description") ?? ""
        np.coverImageType = obj.get("coverImageType") ?? CoverImageType.default
        np.coverImageUrl = obj.get("coverImageUrl")
        np.coverImageColor = obj.get("coverImageColor")
        np.createdAt = obj.get("createdAt")
        np.updatedAt = obj.get("updatedAt")
        let bu = obj.get("byUser")
        np.byUser = bu.id ?? isCurrentUser().id

        np.byUserInfo = obj.get("byUserInfo")
        np.members = obj.get("members") ?? []
        let mmInfo = obj.get("membersInfo") ?? []
        mmInfo = validateMemberInfoRoles(mmInfo, obj.get("byUserInfo"))
        np.membersInfo = mmInfo

        let wk = obj.get("workspace")
        np.workspace = wk.id

        np.assets = [] // assets are always fetched seperately

        np.status = obj.get("status") ?? ProjectStatus.pending
        np.isShared = obj.get("isShared") ?? false
        np.sharedWith = obj.get("sharedWith") ?? []
        np.sharedWithInfo = obj.get("sharedWithInfo") ?? []

        np.isArchived = obj.get("isArchived") ?? false
        np.archivedBy = obj.get("archivedBy")
        np.archivedByInfo = obj.get("archivedByInfo")
        // np.appMeta = obj.get("appMeta") // only required for backend
        return np
    }

    addMember = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(ProjectClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        let cu = currentUserInfo()

        query.get(this.id)
            .then((ob) => {
                ob.addUnique("members", userId)
                ob.addUnique("membersInfo", usrInfo)
                ob.save()
                // Notification.addNotification([], `Project:${this.name}`, `<strong>${cu.name}</strong> added you as a member to <strong>Project : ${this.name}</strong>`, [userId], null,null, null, null, [this.id], [userId], null, null, null, "", null, `Project=${this.id}`,null )
                Notification.addNotification([], `Project:${this.name}`, `${cu.name} added you as a member to Project : ${this.name}`, [userId], null, null, null, null, [this.id], [userId], null, null, null, "", null, `Project=${this.id}`, null, NotificationFor.project_addedYouAsMember)

            }).catch((error) => {
                errorCallback('Error while adding member in asset =' + error.message)
            });
    }

    removeMember = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(ProjectClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }
        // let cu = currentUserInfo()

        query.get(this.id)
            .then((ob) => {
                ob.remove("members", userId)
                ob.remove("membersInfo", usrInfo)
                ob.save()

                // Notification.addNotification([], `Project:${this.name}`, `${cu.name} removed you as a member to Project : ${this.name}`, [userId], null,null, null, null, [this.id], [userId], null, null, null, "", null, null,null )

            }).catch((error) => {
                errorCallback('Error while removing members in asset =' + error.message)
            });
    }

    addSharedWith = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(ProjectClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                ob.addUnique("sharedWith", userId)
                ob.addUnique("sharedWithInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding sharedWith in asset =' + error.message)
            });
    }

    removeSharedWith = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(ProjectClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                ob.remove("sharedWith", userId)
                ob.remove("sharedWithInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing sharedWith in asset =' + error.message)
            });
    }

    // addAssets = (assetIds, errorCallback) => {
    // }

    // removeAssets = (assetIds, errorCallback) => {
    // }



    add = (callback) => {
        let ob = new ProjectClass()
        if (!this.name) {
            this.name = "Untitled"
        }
        ob.set("name", this.name)
        if (this.description) { ob.set("description", this.description) }
        ob.set("coverImageType", this.coverImageType)
        if (this.coverImageUrl) { ob.set("coverImageUrl", this.coverImageUrl) }
        if (this.coverImageColor) { ob.set("coverImageColor", this.coverImageColor) }
        const cu = isCurrentUser()
        const cuInfo = ({ ...currentUserInfo() })
        ob.set("byUser", userPointer(cu.id))
        ob.set("byUserInfo", currentUserInfo())

        let mm = this.members
        let mmInfo = this.membersInfo ?? []
        if (mm.length === 0) {
            mm = [cu.id]
            cuInfo.role = UserRole.admin
            mmInfo = [cuInfo]
        }

        ob.set("members", mm) // list of users associated with this project.. list of objects {userId, userName, position, permission}
        ob.set("membersInfo", removeKeysFromUserInfos_Arr(mmInfo))

        // let orgPointer = {
        //     __type: 'Pointer',
        //     className: 'Workspace',
        //     objectId: cu.workspaceId
        // }
        // ob.set("workspace", orgPointer)

        // if (this.workspace) { ob.set("workspace", workspacePointer(getCurrentWorkspaceId()))  }

        ob.set("workspace", workspacePointer(getCurrentWorkspaceId()))


        ob.set("status", this.status)
        ob.set("isShared", this.isShared)
        if (this.sharedWith) { ob.set("sharedWith", this.sharedWith) }
        if (this.sharedWithInfo) { ob.set("sharedWithInfo", removeKeysFromUserInfos_Arr(this.sharedWithInfo)) }

        // ob.set("isArchived", this.isArchived)
        // if (this.archivedBy) { ob.set("archivedBy", this.archivedBy) }
        ob.set("appMeta", appMeta)

        ob.save()
            .then((proj) => {
                this.id = proj.id
                this.members = mm
                this.membersInfo = mmInfo
                this.byUser = cu.id
                this.byUserInfo = cuInfo
                console.log('New Project Created')

                callback(true, this, '')
            }, (error) => {
                console.log('Failed to create new project, with error code: ' + error.message);
                callback(false, null, error.message)
            });
    }

    update = (callback) => {
        if (this.id == null) {
            callback(false, null, "Error : No object id to update Project")
            return
        }

        var query = new Parse.Query(ProjectClass);
        query.get(this.id)
            .then((ob) => {
                if (!this.name) {
                    this.name = "Untitled"
                }
                ob.set("name", this.name)
                if (this.description) { ob.set("description", this.description) } else { ob.unset("description") }
                ob.set("coverImageType", this.coverImageType)
                if (this.coverImageUrl) { ob.set("coverImageUrl", this.coverImageUrl) } else { ob.unset("coverImageUrl") }
                if (this.coverImageColor) { ob.set("coverImageColor", this.coverImageColor) } else { ob.unset("coverImageColor") }

                // if (this.members) {
                //     ob.addAllUnique("members", this.members ?? []) // list of users associated with this project.. list of objects {userId, userName, position, permission}
                // }

                // let orgPointer = {
                //     __type: 'Pointer',
                //     className: 'Workspace',
                //     objectId: cu.workspaceId
                // }
                // ob.set("workspace", orgPointer)

                ob.set("status", this.status)
                ob.set("isShared", this.isShared)
                // if (this.sharedWith) { ob.set("sharedWith", this.sharedWith) } else {ob.unset("sharedWith")}
                ob.set("isArchived", this.isArchived)
                if (this.archivedBy) { ob.set("archivedBy", this.archivedBy) } else { ob.unset("archivedBy") }
                if (this.archivedByInfo && this.isArchived) { ob.set("archivedByInfo", this.archivedByInfo) } else { ob.unset("archivedByInfo") }

                ob.set("appMeta", appMeta)

                ob.save()
                callback(true, this, '')

                let cu = currentUserInfo()
                Notification.addNotification([], `Project:${ob.name}`, `${cu.name} changed details of Project: ${this.name} `, [ob.members], null, null, null, null, [this.id], [ob.members], null, null, null, "", null, `Project=${this.id}`, null, NotificationFor.project_changedDetails)

            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });

    }

    static getProjectDetails = (projId, callback) => {
        if (projId == null) {
            return
        }
        var query = new Parse.Query(ProjectClass);
        query.get(projId)
            .then((ob) => {
                ob.save()
                callback(true, ob, '')
            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });

    }

    static getProjectDetails_AndNotify = (projId, notifFor, entityInfo) => {
        if (projId == null) {
            return
        }

        var query = new Parse.Query(ProjectClass);
        query.get(projId)
            .then((ob) => {

                // console.log("FETCHED PROJECT OBJ IS ")
                // console.log(ob)

                let cuInfo = currentUserInfo()
                let projMembers = ob.get("members")

                let noti = new Notification()


                //Projects


                //Assets
                if (entityInfo.type === EntityType.asset) {
                    noti.imageUrlsInfo = entityInfo.imageUrlsInfo ?? []
                    noti.location = `Asset:${entityInfo.name}`
                    noti.forUsers = projMembers
                    noti.assets = [entityInfo.id]
                    noti.goToEnity = `Asset=${entityInfo.id}`
                    noti.notificationFor = notifFor


                    // console.log("NOTI FOR USERS")
                    // console.log(projMembers)

                    if (notifFor === NotificationFor.asset_AddedCreateRequest) {
                        noti.message = `${cuInfo.name} requested to creare an Asset in Project: ${ob.get("name")} `
                    }

                    if (notifFor === NotificationFor.asset_updatedDescTitleContent) {
                        noti.message = `${cuInfo.name} updated Description/Title/Content of Asset: ${entityInfo.name} `

                    }

                    if (notifFor === NotificationFor.asset_uploadedNewVersion) {
                        noti.message = `${cuInfo.name} uploaded a new version of Asset: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.asset_changedDetails) {
                        noti.message = `${cuInfo.name} changed details of Asset: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.asset_archived) {
                        noti.message = `${cuInfo.name} archived the Asset: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.asset_finalised) {
                        noti.message = `${cuInfo.name} finalised the Asset: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.asset_approved) {
                        noti.message = `${cuInfo.name} approved Asset(v${entityInfo.version}): ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.asset_rejected) {
                        noti.message = `${cuInfo.name} rejected Asset(v${entityInfo.version}): ${entityInfo.name} `
                    }


                    // asset_AddedCreateRequest : "asset_AddedCreateRequest",
                    // asset_updatedDescTitleContent : "asset_updatedDescTitleContent",
                    // asset_uploadedNewVersion : "asset_uploadedNewVersion",
                    // asset_changedDetails : "asset_changedDetails",
                    // asset_archived : "asset_archived",
                    // asset_finalised : "asset_finalised",
                    // asset_approved : "asset_approved",
                    // asset_rejected : "asset_rejected",


                }

                //Tasks
                if (entityInfo.type === EntityType.task) {
                    noti.imageUrlsInfo = entityInfo.imageUrlsInfo ?? []
                    noti.location = `Task:${entityInfo.name}`
                    noti.forUsers =  projMembers
                    noti.tasks = [entityInfo.id]
                    noti.goToEnity = `Task=${entityInfo.id}`


                    // console.log("NOTI FOR USERS")
                    // console.log(projMembers)

                    if (notifFor === NotificationFor.task_assignedToYou) {
                        noti.message = `${cuInfo.name} assigned you a Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_sharedWithYou) {
                        noti.message = `${cuInfo.name} shared a task with you. Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_changedStatus) {
                        noti.message = `${cuInfo.name} changed status of Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_archived) {
                        noti.message = `${cuInfo.name} archived Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_completed) {
                        noti.message = `${cuInfo.name} completed Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_updatedProjAssComms) {
                        noti.message = `${cuInfo.name} updated projects/assets/comments in Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_changedDates) {
                        noti.message = `${cuInfo.name} changed dates of Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_changedDetails) {
                        noti.message = `${cuInfo.name} changed details of Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_addedNote) {
                        noti.message = `${cuInfo.name} added a note to Task: ${entityInfo.name} `
                    }

                    if (notifFor === NotificationFor.task_changedPrioirty) {
                        noti.message = `${cuInfo.name} added a note to Task: ${entityInfo.name} `
                    }

                }

                //Events
                if (entityInfo.type === EntityType.event) {

                }

                //Comments
                if (entityInfo.type === EntityType.comment) {
                    noti.imageUrlsInfo = entityInfo.imageUrlsInfo ?? []
                    noti.location = `Comment:${entityInfo.name}`
                    noti.forUsers = projMembers
                    noti.assets = [entityInfo.id]
                    noti.goToEnity = `Comment=${entityInfo.id}`
                    noti.notificationFor = notifFor


                    if (notifFor === NotificationFor.comment_added) {
                        noti.message = `${cuInfo.name} added a comment on asset. Comment: ${entityInfo.message} `
                    }

                    if (notifFor === NotificationFor.comment_replyAdded) {
                        if (entityInfo.overrideSendToInfo){
                            if (entityInfo.overrideSendToInfo.id) {
                                noti.forUsers = [entityInfo.overrideSendToInfo.id]
                                noti.message = `${cuInfo.name} replied to your comment on asset. Comment: ${entityInfo.message} `
                            }else{
                                console.log("NO REPLY TO COMMENT _ USER ID - entityInfo.replyToInfo")
                                return
                            }
                        }else{
                            console.log("NO REPLY TO COMMENT _ USER ID - entityInfo.replyToInfo")
                            return
                        }
                    }

                    if (notifFor === NotificationFor.comment_resolved) {
                        noti.message = `${cuInfo.name} resolved your comment on asset. Comment: ${entityInfo.message} `
                    }

                    if (notifFor === NotificationFor.comment_upvoteAdded) {
                        noti.message = `${cuInfo.name} upvoted your comment on asset. Comment: ${entityInfo.message} `
                    }

                }


                noti.add((succ, result, errMsg) =>{
                    if (succ) {
                        console.log("Notification is added")
                        console.log(result)
                    } else {
                        console.log("Notification could not be added")
                        console.log(errMsg)
                    }
                })



            }, (error) => {
                // callback(false, null, 'Error =' + error.message)
                alert("Error while getting project details to add notification - " + error.message)

            });


        // Project.getProjectDetails(projId, (succ, pro, errMsg) => {
        //     if (succ) {
        //     } else {
        //         alert("Error while getting project details to add notification - " + errMsg)
        //     }
        // })
    }



    static archive = (callback) => {
            const cuInfo = currentUserInfo()
            this.isArchived = true
            this.status = ProjectStatus.archived
            this.archivedBy = userPointer(cuInfo.id)
            this.archivedByName = cuInfo
            this.update(callback)

            Notification.addNotification([], `Project:${this.name}`, `${cuInfo.name} archived the Project: ${this.name} `, [this.members], null, null, null, null, [this.id], [this.members], null, null, null, "", null, `Project=${this.id}`, null, NotificationFor.project_archived)

        }

    static getAll = (filter, callback) => {
            console.log('Searching For Projects ')
            var query = new Parse.Query(ProjectClass);
            query.descending('createdAt')
            // query.notEqualTo('isArchived', true)  // not fetching archived 

            query.equalTo("workspace", workspacePointer(getCurrentWorkspaceId()))

            query.limit(40)

            let cuId = isCurrentUser().id
            query.equalTo("members", cuId)

            query.find().then((objects) => {
                // console.log('All Projects =')
                // console.log(objects)
                if (objects.length > 0) {
                    let allObjects = []
                    for (let i = 0; i < objects.length; i++) {
                        const thisObj = objects[i];
                        let thisConvertedObject = Project.initFromPFObject(thisObj)
                        if (thisConvertedObject !== null) {
                            allObjects.push(thisConvertedObject)
                        }
                    }
                    callback(true, allObjects, '')
                } else {
                    callback(true, [], 'No Projects Found')
                }

            }, (error) => {
                callback(false, [], error.message)
            })
        }


}
