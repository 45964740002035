import React, { useEffect, useState } from 'react'
import { Card, Heading, Media, Content, Tile, Box, Container, Section, Image, Columns, Tabs, Button, Tag } from 'react-bulma-components';
import LoadingAux from '../../Containers/LoadingAux';
import { Asset, AssetFilter } from '../../Models/Asset';
import AssetCard from './AssetCard'
import AssetsModal from './AssetsModal';
// import AssetPhotos_Img from '../../assets/Asset-Photos.png'

import AssetsProjsWorks_Img from '../../assets/Asstes-Projects-Workspace.png'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { ImTarget } from 'react-icons/im';
import { BiFilter, BiSort, BiSelectMultiple } from 'react-icons/bi';
import { useLayoutEffect } from 'react/cjs/react.production.min';
import { randomCode } from '../../utilities/Utilities';
import LazyLoad from 'react-lazyload';
import AssetsFilter from './AssetsFilter';
import Selector from '../Common/Selector';
import { AssetCreateStatusType } from '../../Models/EnumsAndPointers';
import ColumnAux from '../../Containers/ColumnAux';
import Guide from '../Guides/Guide';


import AddAsset_Vid from '../../assets/videos/AddAsset.mov'
import { RiCheckboxCircleFill } from 'react-icons/ri';

// Selector



const Assets = (props) => {

    const sortOptions = [
        {
            name: "Date Added",
        },
        {
            name: "Assigned Editor",
        },
        {
            name: "Project",
        },
        {
            name: "Alphabetic",
        },
    ]




    const AssetTABS = {
        all: "All",
        noReview: "No Review",
        rejected: "Rejected",
        approved: "Approved",
        finalised: "Finalised",
        acrhived: "Archived"
    }


    const [currentTab, setCurrentTab] = useState(AssetTABS.all)
    const [assets, setAssets] = useState(props.assets ?? [])
    const [filteredAssets, setFilteredAssets] = useState([])

    const [noAssets, setNoAssets] = useState(props.isNewProject ?? false)

    const [isFetching, setIsFetching] = useState(false)
    const [selectedAsset, setSelectedAsset] = useState(null)


    const [showFilter, setShowFilter] = useState(false)
    const [showSorter, setShowSorter] = useState(false)
    const [filter, setFilter] = useState(new AssetFilter())
    const [sortBy, setSortBy] = useState(null)

    // useLayoutEffect(()=> {})


    useEffect(() => {
        if (props.toFetch) {
            fetchAssets(props.forProjectId)
        }
    }, [props.toFetch, props.forProjectId])

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            let assts = [...assets]
            let asstIds = assts.map((a) => { return a.id })

            props.assets.map((ast) => {
                if (!asstIds.includes(ast.id)) {
                    assts.unshift({ ...ast })
                }
                return null
            })
            setAssets(assts)
        }

        return () => (isMounted = false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.assets])


    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setFilteredAssets(assets, false)
        }
        return () => (isMounted = false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets, currentTab, filter, sortBy])


    const clearSelections = () => {
        setShowSorter(false)
        setShowFilter(false)
    }

    const showSorterModal = () => {
        setShowSorter(true)
    }

    const sortOptionSelected = (opt) => {
        setSortBy(opt)
        clearSelections()
    }

    const showFilterModal = () => {
        setShowFilter(true)
    }

    const filterChanged = (flt) => {
        setFilter(flt)
    }

    const unselectAsset = () => {
        setSelectedAsset(null)
        reopenAllScrolling()
    }

    const changeCurrentTab = (tb) => {
        setCurrentTab(tb)
    }

    const assetsChanged = (changedAssets) => {


        console.log("CHANGED ASSETS ARE")
        console.log(changedAssets)


        let allAssets = [...assets]

        changedAssets.forEach(cA => {
            let ind = allAssets.findIndex((a) => a.id === cA.id)
            if (ind >= 0) {
                allAssets[ind] = cA
            }
        })

        // allAssets.map((a) => (
        //     let inChanged = 
        // ))

        setAssets([...allAssets])



        // setAssets((prev)=> {
        //     return changedAssets
        // })
    }

    const fetchAssets = (forProjectId) => {
        setIsFetching(true)
        Asset.getAll(forProjectId, null, null, (succ, results, errMsg) => {
            setIsFetching(false)
            if (succ) {
                if (results.length === 0) {
                    setNoAssets(true)
                }
                setAssets(results)
            } else {
                console.log(errMsg)
            }
        })
    }

    let assetIsSelected = (asst) => {

        let fromAssets = getFilteredAssetsByTab(currentTab, false)

        let index = fromAssets.findIndex((thisAsset) => {
            return thisAsset.id === asst.id
        })

        if (index) {
            if (index >= 0) {
                asst.currentSlideIndex = index
            }
        }
        setSelectedAsset(asst)

        // NOT WORKING
        // setTimeout(()=> {
        //     let assetsModal = document.querySelector('.assetsModalRef')
        //     if (assetsModal){
        //         console.log("SCROLL LOCKED")
        //         disableBodyScroll(assetsModal)
        //     }else{
        //         console.log("No current target ref")
        //     }
        // },300)

    }




    let reopenAllScrolling = () => {
        console.log("SCROLL UNLOCKED")
        clearAllBodyScrollLocks()
    }


    const assetChanged = (asset) => {
        let allAssets = [...assets]
        let index = allAssets.findIndex((a) => { return a.id === asset.id })
        if (index >= 0) {
            allAssets[index] = asset
            // console.log("CHANGED ASSETS ARE");
            // console.log(allAssets);
            setAssets(allAssets)
        }
    }

    const getFilteredAssetsByTab = (tab, countOnly) => {
        let allAssets = [...assets]
        let filteredAssets = []


        // BY TABS
        if (tab !== AssetTABS.all) {
            filteredAssets = allAssets.filter((a) => {
                if (tab === AssetTABS.noReview) {

                    if (a.approvedBy.length > 0 || a.rejectedBy.length > 0) {
                        return false
                    } else {
                        return true
                    }
                }

                if (tab === AssetTABS.rejected) {
                    if (a.rejectedBy.length > 0) {
                        return true
                    } else {
                        return false
                    }
                }

                if (tab === AssetTABS.approved) {
                    if (a.approvedBy.length > 0) {
                        return true
                    } else {
                        return false
                    }
                }

                if (tab === AssetTABS.finalised) {
                    if (a.isFinalised) {
                        return true
                    } else {
                        return false
                    }
                }

                if (tab === AssetTABS.acrhived) {
                    if (a.isArchived) {
                        return true
                    } else {
                        return false
                    }
                }

                return false
            })
        } else {
            filteredAssets = allAssets
        }

        // removing archived assets
        if (tab !== AssetTABS.acrhived) {
            filteredAssets = filteredAssets.filter((a) => { return a.isArchived !== true })
        }



        //BY FILTERS
        if (filter.isActive()) {
            if (filter.approvedBy.length > 0) {
                let usrsIds = filter.approvedBy.map((u) => { return u.id })
                filteredAssets = filteredAssets.filter((ast) => {
                    let commonFound = ast.approvedBy.some(e => usrsIds.includes(e))
                    if (commonFound) {
                        return true
                    }
                    return false
                })
            }

            if (filter.rejectedBy.length > 0) {
                let usrsIds = filter.rejectedBy.map((u) => { return u.id })
                filteredAssets = filteredAssets.filter((ast) => {
                    let commonFound = ast.rejectedBy.some(e => usrsIds.includes(e))
                    if (commonFound) {
                        return true
                    }
                    return false
                })
            }

            if (filter.addedBy.length > 0) {
                let usrsIds = filter.addedBy.map((u) => { return u.id })
                filteredAssets = filteredAssets.filter((ast) => {
                    let isAddedByCommon = usrsIds.includes(ast.byUserInfo.id)
                    if (isAddedByCommon) {
                        return true
                    }
                    return false
                })
            }

            if (filter.type !== AssetCreateStatusType.all) {
                filteredAssets = filteredAssets.filter((ast) => {
                    return ast.createStatus === filter.type
                })
            }

        }



        return (countOnly ? filteredAssets.length : filteredAssets)
    }


    let assetsOnPage = () => {

        let finalAssets = getFilteredAssetsByTab(currentTab)

        let showNoAssets = false
        if (assets.length === 0 && noAssets) {
            showNoAssets = true
        }

        return (
            <Box className="grid-container has-background-transparent is-radiusless is-shadowless hideScrollBar ">
                <LoadingAux isLoading={isFetching} loaderHeight={300} size="large" >



                    {

                        showNoAssets ?
                            <>
                                <ColumnAux size={5} isMiddle={true} >
                                    {/* <Guide noVid={false} type="image" src={AssetPhotos_Img} title="No assets here" description="Click + buttton on top-right to upload or request assets" vidClassName="maxH200"  /> */}
                                    <Guide noVid={false} type="image" src={AssetsProjsWorks_Img} title="No assets here" description="Click + buttton on top-right to upload or request assets"  boxClassName="is-shadowless m-4" imgClassName="my-4 p-6"  isDense={true} />

                                </ColumnAux>

                            </>

                            :
                            <>
                                {
                                    finalAssets.length ?
                                        finalAssets.map((thisAsset) => {
                                            return (
                                                <LazyLoad key={thisAsset.id ?? randomCode(6)} overflow throttle={100} height={200}>
                                                    <AssetCard asset={thisAsset} assetIsSelected={assetIsSelected} assetChanged={assetChanged} />
                                                </LazyLoad>
                                            )
                                        })
                                        :
                                        <ColumnAux size={4} isMiddle={true}>
                                            <p className='has-text-grey-light has-text-centered is-size-6 mt-6'>Nothing here</p>
                                        </ColumnAux>
                                }
                            </>
                    }
                </LoadingAux>
            </Box>
        )
    }


    const mainTabs = () => {
        return <>
            <Columns className='m-0 p-1  '>
                {/* <Columns.Column narrow >
                    <div className='w100'></div>
                </Columns.Column> */}
                <Columns.Column>
                    <Tabs type="toggle" size={"small"} align="left" className="ml-0 pl-0 mt-1 mb-2 pb-0 has-text-grey-light " textWeight="semibold">
                        <Tabs.Tab className="  minW140" >IDEAS  </Tabs.Tab>
                        <Tabs.Tab className="  minW140" active >ASSETS </Tabs.Tab>
                        <Tabs.Tab className="  minW140" >FOLDERS </Tabs.Tab>
                        <Tabs.Tab className="  minW140" >REPORTS </Tabs.Tab>
                        {/* <Tabs.Tab> <span className="">Rejected</span> </Tabs.Tab> */}
                    </Tabs>
                </Columns.Column>
                <Columns.Column size={2} narrow>
                </Columns.Column>
            </Columns>
        </>
    }


    return (
        <div>
            {showFilter ? <AssetsFilter filter={filter} allMembers={props.project.membersInfo} filterChanged={filterChanged} close={clearSelections} /> : null}

            {showSorter ? <Selector title="Sort By" options={sortOptions} optionSelected={sortOptionSelected} selectedOption={sortBy} close={clearSelections} /> : null}

            <Columns className=" m-0 p-0 is-clearfix">
                {/* <Columns.Column narrow className=' 	 '>
                    <div className=' adjustAssetsFilterLeftGapColumn' style={{ width: "55px" }}>
                    </div>
                </Columns.Column> */}
                <Columns.Column className=" mx-0">
                    <Tabs type="boxed" textAlign="left" textWeight="semibold" size="small" className="m-0 px-0 has-background-grey-lightest " >
                        <Tabs.Tab className="" active={currentTab === AssetTABS.all} onClick={() => { changeCurrentTab(AssetTABS.all) }} >                <span className="px-4">{AssetTABS.all}                          <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredAssetsByTab(AssetTABS.all, true)} </span></span></Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === AssetTABS.noReview} onClick={() => { changeCurrentTab(AssetTABS.noReview) }} >        <span className="px-4">{AssetTABS.noReview}                      <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredAssetsByTab(AssetTABS.noReview, true)} </span></span></Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === AssetTABS.rejected} onClick={() => { changeCurrentTab(AssetTABS.rejected) }} >        <span className="px-4">{AssetTABS.rejected}                      <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredAssetsByTab(AssetTABS.rejected, true)} </span></span></Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === AssetTABS.approved} onClick={() => { changeCurrentTab(AssetTABS.approved) }} >      <span className="px-4">{AssetTABS.approved}                     <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredAssetsByTab(AssetTABS.approved, true)} </span></span></Tabs.Tab>
                        <Tabs.Tab className=" " active={currentTab === AssetTABS.finalised} onClick={() => { changeCurrentTab(AssetTABS.finalised) }} >    <span className="px-4 "  > <RiCheckboxCircleFill className=' has-text-success ' />  {AssetTABS.finalised}<span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredAssetsByTab(AssetTABS.finalised, true)} </span></span></Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === AssetTABS.acrhived} onClick={() => { changeCurrentTab(AssetTABS.acrhived) }} >      <span className="px-4 has-text-grey-light">{AssetTABS.acrhived} <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredAssetsByTab(AssetTABS.acrhived, true)} </span></span></Tabs.Tab>
                    </Tabs>
                </Columns.Column>
                <Columns.Column className=" " narrow>
                    <Button.Group className="is-pulled-right mt-0 " >
                        <Button backgroundColor={filter.isActive() ? "black" : "grey-lightest"} textColor={filter.isActive() ? "white" : "grey"} textWeight={filter.isActive ? "semibold" : "normal"} className=" is-borderless  " size="small" onClick={showFilterModal} > <BiFilter className="mr-1 is-size-6" /> {filter.isActive() ? "Filtered" : "Filter"} {filter.isActive() ? <Tag rounded className=" is-light has-text-weight-semibold py-1 ml-2" >{filter.count() ?? ""}</Tag> : null} </Button>
                        {/* <Button backgroundColor="grey-lightest" className={" has-text-grey is-borderless is-hover-dark "} size="small" onClick={showSorterModal}  > <BiSort className="mr-1" /> Sort</Button> */}
                        {/* <Button backgroundColor="grey-lightest" className=" has-text-grey is-borderless is-hover-dark " size="small"> <BiSelectMultiple className="mr-1 is-size-6" /> Select</Button> */}
                    </Button.Group>
                </Columns.Column>
            </Columns>
            {selectedAsset ? <AssetsModal project={props.project} assets={getFilteredAssetsByTab(currentTab, false)} assetsChanged={assetsChanged} currentAsset={selectedAsset} currentSlideIndex={selectedAsset.currentSlideIndex} close={unselectAsset} /> : null}
            {assetsOnPage()}
        </div>
    )
}

export default Assets