import React, { useEffect, useState, useRef } from 'react'
import { doSelectionsContain, folderOptions, ProjectFolderLevel, SelectableEntity } from '../../Models/EnumsAndPointers'
import { Project } from '../../Models/Project'
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import { FiChevronRight } from 'react-icons/fi';
import LoadingAux from '../../Containers/LoadingAux';
import { Comment } from '../../Models/Comment';



const CommentExplorer = (props) => {

    const [comments, setComments] = useState([])
    const [selectedComment, setSelectedComment] = useState(null)
    const [isFetching, setIsFetching] = useState(false)

    const { projectId, assetId } = props

    const headerRef = useRef(null)

    const scrollToTop = () => {

        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.scrollIntoView({ behavior: 'instant', block: 'start' })
            } else {
                const element = document.getElementById('commentsExpTop')
                if (element) {
                    element.scrollIntoView({ behavior: 'instant', block: 'start' });
                }
            }
        }, 500)
    };

    // list folders
    // go into semifolders
    // show select on last screens

    useEffect(() => {
        let isMounted = true;
        setIsFetching(true)
        Comment.getAll(assetId, null, null, (succ, comments, errMsg) => {
            if (isMounted) {
                setIsFetching(false)
            }
            if (succ) {
                if (isMounted) {
                    setComments(comments)
                    scrollToTop()
                }
                return () => (isMounted = false)
            } else {
                console.log(errMsg)
            }
        })

        return () => (isMounted = false)
    }, [assetId])






    const clearSelections = () => {
        setSelectedComment(null)
    }


    const commentSelected = (ast) => {
        setSelectedComment(ast)
    }




    const row = (opt, hasImage, isSelectable) => {

        let selectedInfo = doSelectionsContain(props.selectedEntities, ProjectFolderLevel.comments, opt)


        let annotationAttached = ""

        if (opt.annotations.length) {
            if (opt.annotations.length > 1) {
                annotationAttached = " (1 annotation)"
            } else {
                annotationAttached = " (" + opt.annotations.length + " annotations)"

            }
        }



        // return <tr key={opt.name} className=" my-0  " onClick={() => { optSelectionToggled(opt) }} >
        return <tr key={opt.id} className=" my-0  is-fullwidth " >

            {
                hasImage ?

                    <td align="left" className=" w10Perc " >
                        {/* <Image className="ml-2 mt-2">{opt.name}</Image> */}
                        <Image rounded size={32} src={opt.byUserInfo.imageUrl ?? "https://images.unsplash.com/photo-1627482965115-e8e29bbcd451?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"} className=" ml-2 mt-1 overflow-hidden  " />
                    </td>

                    :
                    null
            }

            <td align="left" className=" minW200 " >
                <p className="ml-2 mt-2 is-size-7">{opt.message + annotationAttached} </p>
            </td>
            {/* <td align="right " >
                <Button size="small" rounded className='m-0 mt-2 is-right' onClick={() => commentSelected(opt)} >Add</Button>
            </td> */}

            <td align="right  " className='m-0 pr-0 is-narrow '   >
                {
                    selectedInfo.isSelected ?
                        <Button size="small" color='black' rounded className='m-0 mt-2 mr-2 is-right' onClick={() => props.entityRemoved(ProjectFolderLevel.comments, opt, assetId, projectId)} >Added</Button>
                        :
                        <>
                            {
                                isSelectable ?
                                    <Button size="small" rounded className='m-0 mt-2 mr-2 is-right' onClick={() => props.entitySelected(ProjectFolderLevel.comments, opt, assetId, projectId)} >Add</Button>
                                    :
                                    null
                            }
                        </>

                }
            </td>

        </tr>
    }


    const isSelectable = () => {
        let slable = { allowed: false, multiple: false }
        if (props.selectables) {
            let entities = props.selectables.entities ?? []
            let multiSelection = props.selectables.multiSelection
            if (entities.includes(SelectableEntity.comments)) {
                slable.allowed = true
            }
            if (multiSelection) {
                slable.multiple = true
            }
        }
        return slable
    }


    const up = () => {

        let iSLA = isSelectable()
        let allowSelection = isSelectable().allowed

        if (iSLA.multiple === false) {
            if (props.selectedEntities) {
                if (props.selectedEntities.comments) {
                    if (props.selectedEntities.comments.length) {
                        allowSelection = false
                    }
                }
            }
        }

        let showComments = comments

        if (props.showSelectedOnly){
            let selectedComms = props.selectedEntities.comments ?? []
            let selectedCommIds = selectedComms.map(p => p.id)
            showComments = comments.filter((p) => selectedCommIds.includes(p.id))
        }


        return <div>
            <Panel className="m-0 is-shadowless ">
                <Table className="minW300 is-fullwidth h-100vh" hoverable={false} striped={false} bordered={false}  >
                    <tbody  >
                        {
                            showComments.length ?
                            showComments.map((opt) => {
                                    return row(opt, true, allowSelection)
                                })
                                :
                                <p className=' has-text-centered my-6 has-text-grey-light' > No {props.showSelectedOnly ? "Selected " : null}Comments </p>
                        }

                    </tbody>
                </Table>
            </Panel>
        </div>
    }

    const beforeClose = () => {
        props.close()
    }


    const modal = () => {

        //  
        return <LoadingAux isLoading={isFetching} fullHeight={true} >
            <div ref={headerRef} id="commentsExpTop"></div>
            {up()}
        </LoadingAux>
    }




    return modal()
}

export default CommentExplorer