import React, {  useState } from 'react'
import { Box, Button, Message } from 'react-bulma-components'
import { Form as BulmaForm } from 'react-bulma-components'
import ColumnAux from '../../Containers/ColumnAux'
import { Formik, Form, Field } from 'formik'
import { signInNow } from '../../utilities/Login'
import { isQueryStringInvitationEmail, validateEmail, validatePassword } from '../../utilities/Utilities'
import { SignType } from '../../utilities/Enums'


const SignInForm = (props) => {

    const [showMessage, setShowMessage] = useState(null)

    let initialVals = {
        email: isQueryStringInvitationEmail() ?? '',
        password: ''
    }



    const setShowMessageForTime = (thisMessage, type, secs) => {
        setShowMessage({
            type: "danger",
            message: thisMessage
        })
        setTimeout(() => {
            setShowMessage(null)
        }, (secs ?? 5) * 1000);
    }


    const trySignIn = (email, password, callback) => {
        signInNow(email, password, (succ, user, errMsg) => {
            if (succ) {
                props.userSigned(user)
            } else {
                setShowMessageForTime(errMsg, "danger", 5)
                console.log(errMsg)
            }

            callback(succ, user, errMsg)
        })
    }



    return (
        <Formik
            initialValues={initialVals}

            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                trySignIn(values.email, values.password, (succ, user, errMsg) => {
                    actions.setSubmitting(false)
                    actions.resetForm(true)
                    actions.setValues(initialVals)
                })
            }}

        >

            {
                ({ values, isSubmitting, errors, touched, isValidating }) => {

                    return (

                        <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                            <Box className="has-text-left  is-shadowless">
                                {
                                    showMessage ?
                                        <Message color={showMessage.type ?? "default"}>
                                            <Message.Body>{showMessage.message}</Message.Body>
                                        </Message>
                                        : null
                                }

                                <Form>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Email</BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='email' type='email' value={values.email} className='input is-default' validate={validateEmail} placeholder="your@email.com" />
                                            {/* <Form.Input color="default" type="text" placeholder="Field control - text input" /> */}
                                        </BulmaForm.Control>
                                        {errors.email && touched.email && <BulmaForm.Help color="danger">{errors.email}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Password</BulmaForm.Label>
                                        <BulmaForm.Control >
                                            <Field name='password' type='password' value={values.password} className='input is-default' validate={validatePassword} placeholder="your secure password" />
                                        </BulmaForm.Control>
                                        {errors.password && touched.password && <BulmaForm.Help color="danger">{errors.password}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <Button disabled={isSubmitting || isValidating} loading={isSubmitting} type="submit" className="is-fullwidth mt-4" color="black"> Sign In</Button>
                                </Form>

                                <br />
                                <br />
                                <Box className=" mt-2 p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light">
                                    <div className=" has-tex-centered">
                                        <p className="">
                                            <span className="">  Need a TestPost account? </span>

                                        </p>
                                        <Button  className=" has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.SignUp) }} > Create a free account</Button>

                                    </div>

                                    <Button  className=" mt-4 is-text has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.ForgotPassword) }} >Forgot Password ?</Button>
                                </Box>
                            </Box>
                        </ColumnAux>

                    )
                }
            }

        </Formik>
    )


}

export default SignInForm