import React, { useRef, useState } from 'react'
import { Form, Card, Heading, Media, Content, Tabs, Box, Image, Tag, Button, Dropdown, Modal, Panel, Columns, Hero, Container, Message, Level, Section, Navbar, Tile } from 'react-bulma-components';
import { previewBoxType, SocialMedia_PostType, AssetCaptionType, SocialMedia_Platform } from '../../Models/EnumsAndPointers';

import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
// import sample_Li_Company_Bg from './sampleAssets/li-companyPage-bg.jpeg'
import Default_BG from './sampleAssets/Default-BG.png'
import Default_DP from './sampleAssets/Default-DP.png'
import Default_Li_Blank_User from './sampleAssets/Default-Li-Blank-User.svg'
import { randomUsers, rowOfColumns_leftImage } from './VisualiseModal';

import billgates_dp from './sampleAssets/billgates_dp.jpeg'
import satyanadella_dp from './sampleAssets/satyanadella_dp.jpeg'
import reedhastings_dp from './sampleAssets/reedhastings_dp.jpeg'

import yt_logo from './sampleAssets/yt_logo.png'
import yt_menu_browseChannels from './sampleAssets/yt_menu_browseChannels.png'
import yt_menu_explore from './sampleAssets/yt_menu_explore.png'
import yt_menu_feedback from './sampleAssets/yt_menu_feedback.png'
import yt_menu_help from './sampleAssets/yt_menu_help.png'
import yt_menu_history from './sampleAssets/yt_menu_history.png'
import yt_menu_home from './sampleAssets/yt_menu_home.png'
import yt_menu_library from './sampleAssets/yt_menu_library.png'
import yt_menu_premium from './sampleAssets/yt_menu_premium.png'
import yt_menu_report from './sampleAssets/yt_menu_report.png'
import yt_menu_settings from './sampleAssets/yt_menu_settings.png'
import yt_menu_shorts from './sampleAssets/yt_menu_shorts.png'
import yt_menu_subscription from './sampleAssets/yt_menu_subscription.png'



import yt_menu_360video from './sampleAssets/yt_menu_360video.jpeg'
import yt_menu_fashion from './sampleAssets/yt_menu_fashion.jpeg'
import yt_menu_films from './sampleAssets/yt_menu_films.jpeg'
import yt_menu_gaming from './sampleAssets/yt_menu_gaming.jpeg'

import yt_menu_learning from './sampleAssets/yt_menu_learning.jpeg'
import yt_menu_live from './sampleAssets/yt_menu_live.jpeg'
import yt_menu_music from './sampleAssets/yt_menu_music.jpeg'
import yt_menu_news from './sampleAssets/yt_menu_news.jpeg'

import yt_menu_sport from './sampleAssets/yt_menu_sport.jpeg'
import yt_menu_spotlight from './sampleAssets/yt_menu_spotlight.jpeg'



// import yt_nav_3dot from './sampleAssets/yt_nav_3dot.png'
import yt_nav_apps from './sampleAssets/yt_nav_apps.png'
import yt_nav_menu from './sampleAssets/yt_nav_menu.png'
import yt_nav_mic from './sampleAssets/yt_nav_mic.png'
import yt_nav_search from './sampleAssets/yt_nav_search.png'
import yt_nav_signin from './sampleAssets/yt_nav_signin.png'


import yt_vid_3dot from './sampleAssets/yt_vid_3dot.png'
import yt_vid_like from './sampleAssets/yt_vid_like.png'
import yt_vid_dislike from './sampleAssets/yt_vid_dislike.png'
import yt_vid_save from './sampleAssets/yt_vid_save.png'
import yt_vid_share from './sampleAssets/yt_vid_share.png'


import yt_vid_airplay from './sampleAssets/yt_vid_airplay.png'
import yt_vid_autoplay from './sampleAssets/yt_vid_autoplay.png'
import yt_vid_caption from './sampleAssets/yt_vid_caption.png'
import yt_vid_cinema from './sampleAssets/yt_vid_cinema.png'
import yt_vid_expand from './sampleAssets/yt_vid_expand.png'
import yt_vid_main_expand from './sampleAssets/yt_vid_main_expand.png'
import yt_vid_main_info from './sampleAssets/yt_vid_main_info.png'
import yt_vid_main_play from './sampleAssets/yt_vid_main_play.png'
import yt_vid_main_volume from './sampleAssets/yt_vid_main_volume.png'
import yt_vid_minimise from './sampleAssets/yt_vid_minimise.png'
import yt_vid_next from './sampleAssets/yt_vid_next.png'
import yt_vid_play from './sampleAssets/yt_vid_play.png'
import yt_vid_settings from './sampleAssets/yt_vid_settings.png'
import yt_vid_small_3dot from './sampleAssets/yt_vid_small_3dot.png'
import yt_vid_small_airplay from './sampleAssets/yt_vid_small_airplay.png'
import yt_vid_small_caption from './sampleAssets/yt_vid_small_caption.png'
import yt_vid_small_settings from './sampleAssets/yt_vid_small_settings.png'
import yt_vid_small_volume from './sampleAssets/yt_vid_small_volume.png'
import yt_vid_volume from './sampleAssets/yt_vid_volume.png'
import yt_feed_vid_3dot from './sampleAssets/yt_feed_vid_3dot.png'
import yt_home_explore_mobile from './sampleAssets/yt_home_explore_mobile.png'
import yt_home_search_mobile from './sampleAssets/yt_home_search_mobile.png'
import yt_home_notif_mobile from './sampleAssets/yt_home_notif_mobile.png'
import yt_home_cast_mobile from './sampleAssets/yt_home_cast_mobile.png'
import { FaSearch } from 'react-icons/fa';



{/* <Box shadowless radiusless className='m-0 p-0' >
    <Columns className='m-0 p-0'>
        <Columns.Column size={2} className='m-0 p-0'>
        </Columns.Column>
        <Columns.Column className='m-0 p-0'>
        </Columns.Column>
    </Columns>
</Box> */}


const layoutBgColor = (isDarkMode) => {
    let bg = "has-background-white"
    if (isDarkMode){
        bg = "has-background-black"
    }
    return bg
}

const primaryTextColor = (isDarkMode) => {
    let color = "has-background-black"
    if (isDarkMode){
        color = "has-background-white"
    }
    return color
}

const secondoryTextColor = (isDarkMode) => {
    let color = "has-background-dark"
    if (isDarkMode){
        color = "has-background-light"
    }
    return color
}

const tirtiaryTextColor = (isDarkMode, forLight, forDark) => {
    let color = forLight
    if (isDarkMode){
        color = forDark
    }
    return color
}



export const ytNav = () => {
    return <Box shadowless radiusless className='m-0 p-2 has-background-transparent is-bordered stick-to-top z-55' >
        <div onClick={(e) => { e.stopPropagation(); }}>

            <Columns className='m-0' vCentered>
                <Columns.Column className='m-0 p-0 ' narrow >
                    <Level className=" " >
                        <Level.Item className='mr-4 p-0'><img src={yt_nav_menu} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                        <Level.Item className='m-0 p-0'>
                            <img
                                alt="insta logo"
                                // size={128}
                                src={yt_logo}
                                className="is-clipped has-bacground-transparent "
                            />
                        </Level.Item>
                    </Level>

                </Columns.Column>
                <Columns.Column className='m-0 p-0 ' size={3}>
                </Columns.Column>

                <Columns.Column className='m-0 p-0'>

                    <Form.Field className='' >
                        <Form.Control className=' has-icons-left ' fullwidth>
                            <span class="icon is-small is-left">
                                {/* <FaSearch color='dark' className='opacity75' /> */}
                                <img src={yt_nav_search} alt="insta_icon" className="is-clipped icon-28" />
                            </span>

                            <Form.Input
                                // color="success"
                                size="small"
                                placeholder="Search"
                                // color="danger"

                                className="border-radius-6 has-background-transparent is-size-6  "
                            />
                            <span class="icon is-small is-right">
                                {/* <FaSearch color='dark' className='opacity75' /> */}
                                <img src={yt_nav_mic} alt="insta_icon" className="is-clipped icon-28 mt-2 " />
                            </span>
                        </Form.Control>
                    </Form.Field>

                </Columns.Column>
                <Columns.Column className='m-0 p-0 ' size={1}>
                </Columns.Column>
                <Columns.Column className='m-0 p-0' narrow>
                    <Level className="is-pulled-right mr-4 " >
                        <Level.Item className='ml-4 p-0'><img src={yt_nav_apps} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                        <Level.Item className='ml-4 p-0'><img src={yt_vid_3dot} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                        <Level.Item className='ml-4 p-0'> <Button className=' has-background-transparent '> <img src={yt_nav_signin} alt="insta_icon" className="is-clipped icon-28 mr-2" /> SIGN IN </Button>  </Level.Item>
                    </Level>
                </Columns.Column>
            </Columns>

        </div>
    </Box>
}

export const yt_vid_grid = (image, videoName, dpImage, username, views, agoText) => {
    return <div>
        <div className='yt-desktop-container pt-4 '>
            {yt_feedCol(image, videoName, dpImage, username, views, agoText)}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(image, videoName, dpImage, username, views, agoText)}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(image, videoName, dpImage, username, views, agoText)}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
            {yt_feedCol(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}

        </div>
    </div>
}


export const yt_feedCol = (image, videoName, dpImage, username, views, agoText) => {
    return <div className='m-0 p-0 yt-desktop-item' >
        {/* <Image size="16by9" src={image ?? Default_BG} alt="video image" className='object-fit-cover has-background-dark' />
        <Columns gap={0} className=' mt-1'>
            <Columns.Column narrow className='px-3'>
                <Image size={32} rounded src={dpImage ?? Default_DP} alt="user image" className='object-fit-cover ' />
            </Columns.Column>
            <Columns.Column className=' px-0'>
                <Heading size={6} textWeight="normal" className='text-lines-2 mb-1' >{videoName}</Heading>
                <p className="is-size-8 has-text-grey">{username} • {views} views • {agoText}</p>
            </Columns.Column>
            <Columns.Column narrow>
                <Image size={24} rounded src={yt_feed_vid_3dot} alt="user image" className='  is-pulled-right mr-1' />
            </Columns.Column>
        </Columns> */}
        <Card className=" has-background-transparent overflow-hidden m-0 p-0 pb-1 is-clearfix " radiusless shadowless >
            {/* <Card className="twitterBox-592w overflow-hidden is-centered is-h-center " radiusless > */}
            <Card.Image
                size='16/9'
                src={image ?? Default_BG}
                alt="video image"
                className=" yt-thumb-image has-background-dark is-clipped object-fit-cover is-clearfix "
            />
            <Card.Content className="has-background-transparent m-0 p-0 ">
                <Columns gap={0} className=' mt-1'>
                    <Columns.Column narrow className='px-3'>
                        <Image size={32} rounded src={dpImage ?? Default_DP} alt="user image" className=' image-rounded has-background-dark ' />
                    </Columns.Column>
                    <Columns.Column className=' px-0'>
                        <Heading size={7} textWeight="bold" className='text-lines-2 mb-1 has-text-black ' >{videoName}</Heading>
                        <p className="is-size-7 mt-2 has-text-grey-dark">{username} </p>
                        <p className="is-size-7 has-text-grey-dark">{views} views • {agoText}</p>
                    </Columns.Column>
                </Columns>
            </Card.Content>
        </Card>
    </div>
}


export const yt_feedRow = (image, videoName, dpImage, username, views, agoText) => {
    return <Box radiusless shadowless className='m-0 p-0 mt-3 mb-5 has-background-transparent' >
        <Image size="16by9" src={image ?? Default_BG} alt="video image" className='  has-background-dark' />
        <Columns gap={0} className=' mt-1'>
            <Columns.Column narrow className='px-3'>
                <Image size={32} rounded src={dpImage ?? Default_DP} alt="user image" className=' image-rounded has-background-dark ' />
            </Columns.Column>
            <Columns.Column className=' px-0'>
                <Heading size={6} textWeight="normal" className='text-lines-2 mb-1' >{videoName}</Heading>
                <p className="is-size-8 has-text-grey">{username} • {views} views • {agoText}</p>
            </Columns.Column>
            <Columns.Column narrow>
                <Image size={24} rounded src={yt_feed_vid_3dot} alt="user image" className='  is-pulled-right mr-1' />
            </Columns.Column>
        </Columns>
    </Box>
}


export const yt_feed_mobile = (image, videoName, dpImage, username, views, agoText) => {
    return <Box radiusless shadowless className='m-0 p-0 mt-4 has-background-transparent'>
        <Columns className='mb-1 pb-0'>
            <Columns.Column narrow vCentered className="mb-0">
                <img src={yt_logo} alt=" " className='ml-4 ' />
            </Columns.Column>
            <Columns.Column className="mb-0">
            </Columns.Column>
            <Columns.Column className="mb-0">
                <div className="is-pulled-right">
                    <Level className=' mr-4'>
                        <Level.Item className='ml-4'> <Image size={24} src={yt_home_cast_mobile} /> </Level.Item>
                        <Level.Item className='ml-4'> <Image size={24} src={yt_home_notif_mobile} /> </Level.Item>
                        <Level.Item className='ml-4'> <Image size={24} src={yt_home_search_mobile} /> </Level.Item>
                        <Level.Item className='ml-4'> <Image size={24} rounded src={dpImage ?? Default_DP} className="has-background-dark image-rounded" /> </Level.Item>
                    </Level>
                </div>
            </Columns.Column>
        </Columns>
        <hr className='hardHr mt-minus-10 mb-1' />
        <Tabs className='mt-2 ml-2' type="toggle">
            <Tag className="py-4" rounded color={"light"}  >
                <Level className='has-background-light'>
                    <Level.Item className=''> <Image size={24} src={yt_home_explore_mobile} /> </Level.Item>
                    <Level.Item className=' ml-2'>  Explore </Level.Item>
                </Level>
            </Tag>
            <Tag className=" ml-2 py-4" rounded color={"light"} >All</Tag>
            <Tag className=" ml-2 py-4" rounded color={"light"} >Apple</Tag>
            <Tag className=" ml-2 py-4" rounded color={"light"} >Productivity</Tag>
            <Tag className=" ml-2 py-4" rounded color={"light"} >Technology</Tag>
        </Tabs>
        <div className='mt-minus-14'>
            {yt_feedRow(image, videoName, dpImage, username, views, agoText)}
            {yt_feedRow(image, videoName, dpImage, username, views, agoText)}
            {yt_feedRow(image, videoName, dpImage, username, views, agoText)}
            {yt_feedRow(image, videoName, dpImage, username, views, agoText)}

        </div>


    </Box>

}


export const yt_profileCard_mobile = (dpImage, bgImage, userName, aboutText) => {

    return <Box radiusless shadowless className='m-0 p-0 mt-4 has-background-transparent' >
        <Tabs size="small" className=' ml-4 mb-0'>
            <Tabs.Tab active className='has-text-weight-medium'>HOME</Tabs.Tab>
            <Tabs.Tab className='has-text-weight-medium '>VIDEOS</Tabs.Tab>
            <Tabs.Tab className='has-text-weight-medium '>PLAYLISTS</Tabs.Tab>
            <Tabs.Tab className='has-text-weight-medium '>COMMUNITY</Tabs.Tab>
            <Tabs.Tab className='has-text-weight-medium '>CHANNELS</Tabs.Tab>
            <Tabs.Tab className='has-text-weight-medium  '>ABOUT</Tabs.Tab>
        </Tabs>
        <Card className=" overflow-hidden has-background-transparent is-centered is-h-center m-0 p-0 " radiusless shadowless >
            {/* <Card className="twitterBox-592w overflow-hidden is-centered is-h-center " radiusless > */}
            <Card.Image
                size='16/9'
                src={bgImage ?? Default_BG}
                // className="figure twitter-profilePage-bg overflow-hidden is-radiusless"
                className="figure yt-profile-bg-height-mobile has-background-dark is-fullwidth overflow-hidden m-0 "
            />
            <Card.Content className="has-background-transparent m-0 p-0 has-text-black">
                <div className='px-4' >
                    <ColumnAux size={4} isMiddle={true} className="has-text-centered">
                        <Image rounded alt="" src={dpImage ?? Default_DP} className=" yt-profile-dp has-background-dark is-centered mt-2  " />
                    </ColumnAux>
                    <Heading size={5} textAlign="center" textColor='black' className='mt-3 mb-0' spaced={true}>{userName}</Heading>
                    <p className=' has-text-centered is-size-7 has-text-weight-semibold mt-3 text_youtubeRed'>SUBSCRIBE</p>
                    <p className=' has-text-centered is-size-8 has-text-weight-semibold mt-3 has-text-grey text-lines-2'>{aboutText ?? "Cum ceteris in veneratione tui montes, nascetur mus. Ambitioni dedisse scripsisse iudicaretur."}</p>
                </div>

                {/* <Columns className='m-0 p-0 my-2' vCentered>
                    <Columns.Column narrow className='m-0 p-0 ml-2 pl-2'>
                        <Image rounded alt="" src={dpImage ?? Default_DP} className=" yt-profile-dp  mt-2  " />
                    </Columns.Column>
                    <Columns.Column className='m-0 p-0 pl-2'>
                        <Heading size={5} textWeight="normal">{userName ?? "TestPost"}</Heading>
                        <Heading size={6} subtitle textWeight='medium' textColor='grey' className='is-size-7' >1.58 crore subscribers</Heading>
                    </Columns.Column>
                    <Columns.Column className='m-0 p-0'>
                    </Columns.Column>
                    <Columns.Column className='p-0' narrow>
                        <Button radiusless textColor='white' className=' bg_youtubeRed is-pulled-right mr-2'>SUBSCRIBE</Button>
                    </Columns.Column>
                </Columns> */}
                <div>
                    {yt_feedRow(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
                    {yt_feedRow(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}

                    {yt_feedRow(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}
                    {yt_feedRow(null, "How TestPost changed our organistaion", null, "TestPost", "9.8k", "3 weeeks ago")}

                </div>
            </Card.Content>
        </Card>

    </Box>

}

export const yt_ProfileCard = (dpImage, bgImage, userName) => {

    return <Card className=" overflow-hidden has-background-transparent is-centered is-h-center m-0 p-0 " radiusless shadowless >
        {/* <Card className="twitterBox-592w overflow-hidden is-centered is-h-center " radiusless > */}
        <Card.Image
            size='16/9'
            src={bgImage ?? Default_BG}
            // className="figure twitter-profilePage-bg overflow-hidden is-radiusless"
            className="figure yt-profile-bg-height has-background-dark is-fullwidth overflow-hidden m-0 "


        />
        <Card.Content className="has-background-transparent m-0 p-0 px-4 has-text-black">
            <Columns className='m-0 p-0 my-2' vCentered>
                <Columns.Column narrow className='m-0 p-0 ml-2 pl-2'>
                    <Image rounded alt="" src={dpImage ?? Default_DP} className=" yt-profile-dp  mt-2 has-background-dark  " />
                </Columns.Column>
                <Columns.Column className='m-0 p-0 pl-2'>
                    <Heading size={5} textWeight="normal">{userName ?? "TestPost"}</Heading>
                    <Heading size={6} subtitle textWeight='medium' textColor='grey' className='is-size-7' >1.58 crore subscribers</Heading>
                </Columns.Column>
                <Columns.Column className='m-0 p-0'>
                </Columns.Column>
                <Columns.Column className='p-0' narrow>
                    <Button radiusless textColor='white' className=' bg_youtubeRed is-pulled-right mr-2'>SUBSCRIBE</Button>
                </Columns.Column>
            </Columns>
            <Tabs size="small" className=''>
                <Tabs.Tab className='has-text-weight-medium is-size-6p4 mx-4'>HOME</Tabs.Tab>
                <Tabs.Tab className='has-text-weight-medium is-size-6p4  mx-4'>VIDEOS</Tabs.Tab>
                <Tabs.Tab className='has-text-weight-medium is-size-6p4  mx-4'>PLAYLISTS</Tabs.Tab>
                <Tabs.Tab className='has-text-weight-medium is-size-6p4  mx-4'>COMMUNITY</Tabs.Tab>
                <Tabs.Tab className='has-text-weight-medium is-size-6p4  mx-4'>CHANNELS</Tabs.Tab>
                <Tabs.Tab active className='has-text-weight-medium is-size-6p4 mx-3'>ABOUT</Tabs.Tab>
            </Tabs>
        </Card.Content>
    </Card>



    // return <Box shadowless radiusless className='m-0 p-0' >
    //     <img alt="" src={Default_BG} className=" yt-profile-bg-height   " fullwidth />

    // </Box>
}


export const yt_leftMenu = () => {

    return <Box shadowless radiusless className='m-0 p-3 has-background-transparent'>
        {rowOfColumns_leftImage(yt_menu_home, 24, false, "Home", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_explore, 24, false, "Explore", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_shorts, 24, false, "Shorts", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_subscription, 24, false, "Subscriptions", "", "is-size-6p8", "")}
        <hr />
        {rowOfColumns_leftImage(yt_menu_library, 24, false, "Library", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_history, 24, false, "History", "", "is-size-6p8", "")}
        <hr />
        <p className='is-size-6p4 has-text-grey has-text-weight-semibold m-2 my-2'>BEST OF YOUTUBE</p>
        {rowOfColumns_leftImage(yt_menu_music, 24, true, "Music", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_sport, 24, true, "Sports", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_gaming, 24, true, "Gaming", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_films, 24, true, "Films", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_news, 24, true, "News", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_live, 24, true, "Live", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_fashion, 24, true, "Fashion & Beauty", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_learning, 24, true, "Learning", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_spotlight, 24, true, "Spotlight", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_360video, 24, true, "360° Video", "", "is-size-6p8", "")}
        <hr />
        {rowOfColumns_leftImage(yt_menu_browseChannels, 24, false, "Browse channels", "", "is-size-6p8", "")}
        <hr />
        <p className='is-size-6p4 has-text-grey has-text-weight-semibold m-2 my-2'>MORE FROM YOUTUBE</p>
        {rowOfColumns_leftImage(yt_menu_premium, 24, false, "Youtube Premium", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_live, 24, false, "Live", "", "is-size-6p8", "")}
        <hr />
        {rowOfColumns_leftImage(yt_menu_settings, 24, false, "Settings", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_report, 24, false, "Report history", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_help, 24, false, "Help", "", "is-size-6p8", "")}
        {rowOfColumns_leftImage(yt_menu_feedback, 24, false, "Send feedback", "", "is-size-6p8", "")}

    </Box>

}



export const thumbnailRow = (image, title, username, text) => {
    return <Columns className='p-0 m-0 mb-2' mobile={true}>
        <Columns.Column className='p-0 m-0' >
            <Image alt="icon" size="5by3" src={image ?? Default_BG} />
        </Columns.Column>
        <Columns.Column className={`p-0 pl-2 m-0 ml-2`} >
            <p className=' has-text-weight-bold is-size-7'> {title ?? "How TestPost helps teams"} </p>
            <p className='mt-3 is-size-7'>{username ?? "TestPost"}</p>
            <p className='is-size-8'>{text ?? "Donec sed odio operae, eu vulputate felis rhoncus."}</p>
        </Columns.Column>
    </Columns>
}


export const suggestionCol = () => {

    return <Box shadowless radiusless className='m-0 p-0 has-background-transparent'>
        {thumbnailRow(null, null, null, null)}
        {thumbnailRow(null, null, null, null)}
        {thumbnailRow(null, null, null, null)}
        {thumbnailRow(null, null, null, null)}
        {thumbnailRow(null, null, null, null)}
        {thumbnailRow(null, null, null, null)}
        {thumbnailRow(null, null, null, null)}
        {thumbnailRow(null, null, null, null)}
    </Box>

}

export const videoBox = (dpImage, postImage, userName, vidName) => {
    return <Card className=" overflow-hidden is-centered is-h-center m-0 p-0  has-background-transparent" radiusless shadowless >
        {/* <Card className="twitterBox-592w overflow-hidden is-centered is-h-center " radiusless > */}
        <Card.Image
            size='16by9'
            src={postImage ?? Default_BG}
            // className="figure twitter-profilePage-bg overflow-hidden is-radiusless"
            className="figure is-fullwidth has-background-black overflow-hidden m-0 opacity90 "
        />
        <Box shadowless radiusless className=' has-background-black p-0 mt-minus-56 z-35'>
            <Columns className=' px-4'>
                <Columns.Column narrow>
                    <Level className=''>
                        <Level.Item className='mr-4'> <Image size={32} src={yt_vid_main_play} /> </Level.Item>
                        <Level.Item className='mr-4'> <Image size={32} src={yt_vid_next} /> </Level.Item>
                        <Level.Item className='mr-4'> <Image size={32} src={yt_vid_volume} /> </Level.Item>
                        <Level.Item textColor='white' className='is-size-7 has-text-weight-bold  '> 0:01 / 9:14</Level.Item>
                    </Level>
                </Columns.Column>
                <Columns.Column >
                </Columns.Column>

                <Columns.Column narrow>
                    <div>
                        <Level>
                            <Level.Item className='ml-4'> <Image size={32} src={yt_vid_autoplay} /> </Level.Item>
                            <Level.Item className='ml-4'> <Image size={32} src={yt_vid_caption} /> </Level.Item>
                            <Level.Item className='ml-4'> <Image size={32} src={yt_vid_settings} /> </Level.Item>
                            <Level.Item className='ml-4'> <Image size={32} src={yt_vid_minimise} /> </Level.Item>
                            <Level.Item className='ml-4'> <Image size={32} src={yt_vid_cinema} /> </Level.Item>
                            <Level.Item className='ml-4'> <Image size={32} src={yt_vid_airplay} /> </Level.Item>
                            <Level.Item className='ml-4'> <Image size={32} src={yt_vid_expand} /> </Level.Item>
                        </Level>
                    </div>
                </Columns.Column>
            </Columns>
        </Box>
        <Card.Content className="has-background-transparent m-0 p-0 has-text-black">
            <>
                <p className=' has-text-weight-medium is-size-5 mt-4 text-lines-1' >{vidName ?? "The name of the video"}</p>
                <Columns className='m-0 p-0 my-2' >
                    <Columns.Column className='is-size-7 has-text-black m-0 p-0'>
                        <p className='mt-1 has-text-grey'>79,012 • 28-Jun-2022</p>
                    </Columns.Column>
                    <Columns.Column className='p-0' narrow>
                        <div className=' is-pulled-right'>
                            <Level >
                                <Level.Item className='mx-2'> {rowOfColumns_leftImage(yt_vid_like, 24, false, "5.1K", "black", "is-size-6", "is-size-medium")} </Level.Item>
                                <Level.Item className='mx-4'> {rowOfColumns_leftImage(yt_vid_dislike, 24, false, "DISLIKE", "black", "is-size-6", "is-size-medium")} </Level.Item>
                                <Level.Item className='mx-4'> {rowOfColumns_leftImage(yt_vid_share, 24, false, "SHARE", "black", "is-size-6", "is-size-medium")} </Level.Item>
                                <Level.Item className='mx-4'> {rowOfColumns_leftImage(yt_vid_save, 24, false, "SAVE", "black", "is-size-6", "is-size-medium")} </Level.Item>
                                <Level.Item className='ml-4 mr-1'> {rowOfColumns_leftImage(yt_vid_3dot, 24, false, "", "black", "is-size-6", "is-size-medium")} </Level.Item>
                            </Level>
                        </div>
                    </Columns.Column>
                </Columns>
            </>
            <hr className='hardHr mt-1' />
            <Columns className='m-0 p-0 my-2' >
                <Columns.Column narrow className=''>
                    <Image rounded alt="" size={48} src={dpImage ?? Default_DP} className="  has-background-dark  " />
                </Columns.Column>
                <Columns.Column className='is-size-7 has-text-black'>
                    <p className=' has-text-weight-bold is-size-6p4' >{userName ?? "TestPost"}</p>
                    <p className='mt-1'>1.58 crore subscribers</p>
                    <p className='mt-5' >Me non paenitet nullum festiviorem excogitasse ad hoc. Quid securi etiam tamquam eu fugiat nulla pariatur. Gallia est omnis divisa in partes tres, quarum.</p>

                </Columns.Column>
                <Columns.Column className='p-0' narrow>
                    <Button radiusless textColor='white' className=' bg_youtubeRed is-pulled-right mr-2 mt-2'>SUBSCRIBE</Button>
                </Columns.Column>
            </Columns>
        </Card.Content>
    </Card>
}

export const yt_videoPage = (dpImage, postImage, userName, vidName) => {
    return <Box shadowless radiusless className='has-background-light m-0 p-0'>
        <Box className='m-0 p-0 has-background-transparent'>
            {ytNav()}
            <Columns className='p-4'>
                <Columns.Column size={9}>
                    {videoBox(dpImage, postImage, userName, vidName)}
                </Columns.Column>
                <Columns.Column className='pr-4'>
                    {suggestionCol()}
                </Columns.Column>
            </Columns>
        </Box>
    </Box>
}


export const yt_home_feed = (image, videoName, dpImage, username, views, agoText) => {


    return <Box shadowless radiusless className='m-0 p-0 has-background-transparent' >
        {ytNav()}
        <Columns className='m-0 p-0' mobile={true}>
            <Columns.Column size={2} className='m-0 p-0'>
                {yt_leftMenu()}
            </Columns.Column>
            <Columns.Column className='m-0 p-0'>
                <Box shadowless radiusless className='m-0 p-0 pt-2 has-background-transparent'>
                    {/* <hr className='hardHr mt-minus-10 mb-1' /> */}
                    <Tabs className='mt-0 ml-2' type="toggle">
                        <Tag size={"medium"} className="py-4" rounded color={"light"}  >
                            <Level className='has-background-light'>
                                <Level.Item className=''> <Image size={24} src={yt_home_explore_mobile} /> </Level.Item>
                                <Level.Item className=' ml-2'>  Explore </Level.Item>
                            </Level>
                        </Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >All</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >Apple</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >Productivity</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >Technology</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >Business</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >Softwares</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >Apps</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >TestPost</Tag>
                        <Tag size={"medium"} className=" ml-3 is-bordered py-4" rounded color={"light"} >Tesla</Tag>

                    </Tabs>
                    <hr className='hardHr mt-minus-16 mb-0' />
                    <Box shadowless radiusless className='py-0 m-0 has-background-light'>
                        {yt_vid_grid(image, videoName, dpImage, username, views, agoText)}
                    </Box>
                </Box>
            </Columns.Column>
        </Columns>
    </Box>


    return

}


export const yt_Desktop_UI = (dpImage, bgImage, userName, aboutText) => {

    return <Box shadowless radiusless className='m-0 p-0 has-background-transparent' >
        {ytNav()}
        <Columns className='m-0 p-0'>
            <Columns.Column size={2} className='m-0 p-0'>
                {yt_leftMenu()}
            </Columns.Column>
            <Columns.Column className='m-0 p-0'>
                {yt_ProfileCard(dpImage, bgImage, userName)}
                <Columns className='has-background-light'>
                    <Columns.Column>
                        {yt_profile_aboutBox(false, aboutText)}
                    </Columns.Column>
                    <Columns.Column size={4}>
                        {yt_profile_stats()}
                    </Columns.Column>
                </Columns>
            </Columns.Column>
        </Columns>
    </Box>

}



export const yt_profile_aboutBox = (isMobile, aboutText) => {
    let cnSize = isMobile ? "is-size-7" : "is-size-6p4"
    return <Box shadowless className=' my-5 has-text-black has-background-transparent'>
        <Heading textWeight='normal' className="" size={6} spaced >About</Heading>{
            aboutText ?
                <p className={` ${cnSize} " `}>{aboutText}</p>
                :
                <>
                    <p className={` ${cnSize} " `}>Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Vivamus sagittis lacus vel augue laoreet rutrum faucibus. Praeterea iter est quasdam res quas ex communi. Cum ceteris in veneratione tui montes, nascetur mus. Integer legentibus erat a ante historiarum dapibus.</p>
                    <p className={` ${cnSize}  mt-4 `}>Gallia est omnis divisa in partes tres, quarum. Contra legem facit qui id facit quod lex prohibet. Unam incolunt Belgae, aliam Aquitani, tertiam.</p>
                    <p className={` ${cnSize}  mt-4 `}>Nihil hic munitissimus habendi senatus locus, nihil horum? Prima luce, cum quibus mons aliud consensu ab eo.</p>
                </>
        }
        <hr />
        <p className={` is-size-6  has-text-weight-medium" `}>Details</p>
        <p className={` is-size-7  has-text-weight-medium mt-3`}>Joined 22-Jun-2005</p>
        <hr />
        <p className={` is-size-6  has-text-weight-medium" `}>Links</p>
        <p className={` is-size-7 has-text-link mt-3`}><a href="https://testpost.app">testpost.app</a></p>
        <hr />
    </Box>
}


export const yt_profile_stats = (isMobile) => {
    return <Box shadowless className=' my-5 has-background-transparent'>

        <p className={` is-size-6 has-text-black has-text-weight-medium" `}>Stats</p>
        <hr className='my-3' />
        <p className={` is-size-7 has-text-black has-text-weight-medium`}>Joined 22-Jun-2005</p>
        <hr className='my-3' />
        <p className={` is-size-7 has-text-black has-text-weight-medium`}>81,02,76,568 views</p>
    </Box>
}
