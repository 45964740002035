import Parse from 'parse'
import { currentUserInfo, getCurrentWorkspaceId, isCurrentUser } from '../utilities/Login';
import { appMeta, userPointer, workspacePointer } from './EnumsAndPointers';

const NotificationClass = Parse.Object.extend("Notification");


export class Notification {
    constructor(id, imageUrlsInfo, location, message, forUsers, forPositionTypes, workspace, workspaceId, assets, projects, users, tasks, comments, events, type, tags, goToEnity, createdAt, expireOn, readBy, time, notificationFor, appMeta) {
        this.id = id
        this.imageUrlsInfo = imageUrlsInfo ?? []
        this.location = location
        this.message = message
        this.forUsers = forUsers ?? []
        this.forPositionTypes = forPositionTypes ?? []

        let wkId = getCurrentWorkspaceId()
        this.workspace = workspace ?? workspacePointer(wkId)
        this.workspaceId = workspaceId ?? wkId

        this.assets = assets ?? []
        this.projects = projects ?? []
        this.users = users ?? [] //taggedUsers (taggedUsers)
        this.tasks = tasks ?? []
        this.comments = comments ?? []
        this.events = events ?? []

        this.goToEnity = goToEnity
        this.type = type
        this.tags = tags ?? []
        this.createdAt = createdAt
        this.readBy = readBy ?? []
        this.time = time
        this.expireOn = expireOn
        this.notificationFor = notificationFor
        this.appMeta = appMeta

    }


    static copyFrom = (obj) => {
        let ob = new Notification()
        ob.id = obj.id
        ob.imageUrlsInfo = obj.imageUrlsInfo ?? []
        ob.location = obj.location
        ob.message = obj.message
        ob.forUsers = obj.forUsers ?? []
        ob.forPositionTypes = obj.forPositionTypes ?? []
        ob.workspace = obj.workspace
        ob.workspaceId = obj.workspaceId
        ob.assets = obj.assets ?? []
        ob.projects = obj.projects ?? []
        ob.users = obj.users ?? [] //taggedUsers (taggedUsers)
        ob.tasks = obj.tasks ?? []
        ob.comments = obj.comments ?? []
        ob.events = obj.events ?? []
        ob.goToEnity = obj.goToEnity
        ob.type = obj.type
        ob.tags = obj.tags ?? []
        ob.createdAt = obj.createdAt
        ob.readBy = obj.readBy ?? []
        ob.time = obj.time
        ob.expireOn = obj.expireOn
        ob.notificationFor = obj.notificationFor
        ob.appMeta = obj.appMeta


        return ob
    }

    static isReadByCurrentUser = (nt) => {
        let cu = currentUserInfo()
        let isR = false
        if (nt.readBy.includes(cu.id)){
            isR = true
        }
        return isR
    }

    static unreadNotifsCount = (allNotifs) => {
        let count = 0
        if (allNotifs.length){
            let cu = currentUserInfo()
            let allRead = allNotifs.filter((n) => {
               return n.readBy.includes(cu.id)
            }) ?? []
            count = allNotifs.length - allRead.length
        }
        return count
    }

    markRead = (errorCallback) => {
        var query = new Parse.Query(NotificationClass);
        let cu = currentUserInfo()

        query.get(this.id)
            .then((ob) => {
                ob.addUnique("readBy", cu.id)
                // ob.addUnique("approvedByInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while marking notification as read =' + error.message)
            });
    }

    markUnRead = (errorCallback) => {
        var query = new Parse.Query(NotificationClass);
        let cu = currentUserInfo()
        query.get(this.id)
            .then((ob) => {
                // only what can be changed after creation
                ob.remove("readBy", cu.id)
                // ob.removeAll("approvedByInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing notification as read (Unread) =' + error.message)
            });
    }

    static initFromPFObject = (obj) => {
        let np = new Notification()
        if (!obj.id) {
            return null
        }
        np.id =                 obj.id
        np.imageUrlsInfo =      obj.get("imageUrlsInfo") ?? []
        np.location =           obj.get("location")
        np.message =            obj.get("message")
        np.forUsers =           obj.get("forUsers") ?? []
        np.forPositionTypes =   obj.get("forPositionTypes") ?? []
        np.workspace =          obj.get("workspace")
        np.workspaceId =        obj.get("workspaceId")
        np.assets =             obj.get("assets") ?? []
        np.projects =           obj.get("projects") ?? []
        np.users =              obj.get("users") ?? []
        np.tasks =              obj.get("tasks") ?? []
        np.comments =           obj.get("comments") ?? []
        np.events =             obj.get("events") ?? []
        np.goToEnity =          obj.get("goToEnity")
        np.type =               obj.get("type")
        np.tags =               obj.get("tags") ?? []
        np.createdAt =          obj.get("createdAt")
        np.readBy =             obj.get("readBy") ?? []
        np.time =               obj.get("time")
        np.expireOn =           obj.get("expireOn")
        np.notificationFor =           obj.get("notificationFor")
        np.appMeta =            obj.get("appMeta")



        return np
    }


    static addNotification = ( imageUrlsInfo, location, message, forUsers, forPositionTypes, workspace, workspaceId, assets, projects, users, tasks, comments, events, type, tags, goToEnity, expireOn, notificationFor ) => {
        let ob = new Notification()
        
        ob.imageUrlsInfo = imageUrlsInfo ?? []
        ob.location = location
        ob.message = message
        ob.forUsers = forUsers ?? []
        ob.forPositionTypes = forPositionTypes ?? []
        ob.workspace = workspace 
        ob.workspaceId = workspaceId 
        ob.assets = assets ?? []
        ob.projects = projects ?? []
        ob.users = users ?? [] //taggedUsers (taggedUsers)
        ob.tasks = tasks ?? []
        ob.comments = comments ?? []
        ob.events = events ?? []

        ob.goToEnity = goToEnity
        ob.type = type
        ob.tags = tags ?? []
        ob.expireOn = expireOn
        ob.notificationFor = notificationFor

        ob.add((succ, result, errMsg) =>{
            if (succ) {
                console.log("Notification is added")
                console.log(result)
            } else {
                console.log("Notification could not be added")
                console.log(errMsg)
            }
        })
    }



    add = (callback) => {
        let ob = new NotificationClass()

        let wkId = getCurrentWorkspaceId()

        ob.set("imageUrlsInfo", this.imageUrlsInfo)
        ob.set("location", this.location)
        ob.set("message", this.message)
        ob.set("forUsers", this.forUsers)
        ob.set("forPositionTypes", this.forPositionTypes)
        ob.set("workspace", workspacePointer(wkId))
        ob.set("workspaceId", wkId)
        ob.set("assets", this.assets)
        ob.set("projects", this.projects)
        ob.set("users", this.users)
        ob.set("tasks", this.tasks)
        ob.set("comments", this.comments)
        ob.set("events", this.events)
        ob.set("goToEnity", this.goToEnity)
        ob.set("type", this.type)
        ob.set("tags", this.tags)
        ob.set("readBy", this.readBy)
        ob.set("time", this.time)
        ob.set("expireOn", this.expireOn)
        ob.set("notificationFor", this.notificationFor)
        ob.set("appMeta", this.appMeta)


        ob.save()
            .then((av) => {
                this.id = av.id
                console.log("Notification is added")
                callback(true, this, '')

            }, (error) => {
                console.log('Notification could not be added with error: ' + error.message);
                callback(false, null, error.message)
            });
    }


    static getAll = (callback) => {
        console.log('Searching For Notifications ')
        var query = new Parse.Query(NotificationClass);
        query.equalTo("workspaceId", getCurrentWorkspaceId())
        // forUsers should contain currentUserId
        query.equalTo("forUsers", currentUserInfo().id)
        query.descending('createdAt')
        // query.notEqualTo('isArchived', true)  // not fetching archived 
        query.limit(60)

        query.find().then((objects) => {
            console.log('All Notifications are =')
            console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Notification.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Notifications Found')
            }

        }, (error) => {
            console.log("Notifications :ERROR MESSAGE IS ")
            console.log(error)
            console.log(error.message)

            callback(false, [], error.message)
        })
    }
}