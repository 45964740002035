import { currentUserInfo, isCurrentUser } from "../utilities/Login"
import { AiOutlineAppstoreAdd, AiFillFacebook, AiOutlineFacebook, AiFillInstagram, AiOutlineInstagram, AiFillLinkedin, AiOutlineLinkedin, AiOutlineTwitter, AiFillMediumSquare, AiFillYoutube, AiOutlineYoutube } from 'react-icons/ai';
import { RiFileUploadLine, RiMoreFill, RiImageAddLine, RiTwitterLine, RiMediumLine, RiSpotifyLine, RiSnapchatLine } from 'react-icons/ri';
import { FaSpotify, FaPinterest, FaSnapchatGhost } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';





//TODO: Load values from package.json
export const appMeta = {
    version: "0.0001",
    platform: "web",
    name: "appbeta"
}

export const FileUploadStatus = {
    invalid: "Invalid",
    waiting: "Waiting",
    uploading: "Uploading",
    failed: "Failed",
    networkError: "NetworkError",
    uploaded: "Uploaded"
}

// const acceptedFiles = "image/png, image/jpeg, image/jpg, image/pdf"
// const acceptedFiles = "image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp, image/eps, image/gif,  image/pdf, application/pdf"
// /* NO application/pdf FOR NOW */
export const acceptedFileTypes_forAssets = "image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp, image/eps, image/gif, image/pdf"

export const acceptedFileTypes_forDP = "image/png, image/jpeg, image/jpg"


export const CRUDAction = {
    create: "create",
    read: "read",
    update: "update",
    delete: "delete"
}

export const CRUDEvent = {
    added: "added",
    updated: "updated",
    deleted: "deleted"
}

export const CRUDName = (crudEvent) => {
    let name = "created "
    switch (crudEvent) {
        case CRUDEvent.updated: name = "changed to "; break;
        case CRUDEvent.deleted: name = "deleted "; break;
        default: break;
    }
    return name
}


export const Event_Dynamic_Changeables = {
    AssignedToUsers: "AssignedToUsers",
    SharedWithUsers: "SharedWithUsers",
    Note: "Note",
    AssetsAndContents: "AssetsAndContents",
}

export const Task_Dynamic_Changeables = {
    AssignedToUsers: "AssignedToUsers",
    SharedWithUsers: "SharedWithUsers",
    Note: "Note",
    ProjectsAssetsComments: "AssetsAndContents",
}

export const CoverImageType = {
    default: "Default",
    customImage: "Custom Image",
    color: "Color"
}

export const UserRole = {
    admin: "Admin",
    owner: "Owner",
    manager: "Manager",
    member: "Member",
    reviewer: "Reviewer",
    // viewer: "Viewer",
}

export const allRoles = [
    UserRole.owner,
    UserRole.manager,
    UserRole.member,
    UserRole.reviewer,
    UserRole.viewer
]

export const definationLinesForUserRole = (role) => {
    let deflines = []

    if (role === UserRole.owner) {
        deflines.push("Owner owns a workpsace along with admin.")
        deflines.push("Owner can do everything including certain workspace settings.")
        deflines.push("Owner can create/edit Managers.")
    }

    if (role === UserRole.manager) {
        deflines.push("Manager has access to all features.")
        deflines.push("Manager can invite or remove members.")
        deflines.push("Manager can assign and edit assets/tasks/events to or for other members.")
        deflines.push("Manager CANNOT view workspace settings.")
    }

    if (role === UserRole.member) {
        deflines.push("Member has scoped access to all features.")
        deflines.push("Member can view Projects, Assets, Tasks and Events which are either created by them or they are part of.")
        deflines.push("Member can edit Assets, Comments, Tasks and Events which are created by themselves only.")
        deflines.push("Member can review assets and mark comments as resolved.")
        deflines.push("Member can review assets in all stages.")
        deflines.push("Member can add requests to create new assets.")
        deflines.push("Member can invite new members only if explicitly allowed in Workspace settings. But they cannot remove any members.")
    }

    if (role === UserRole.reviewer) {
        deflines.push("Reviewer has limited & scoped access to Assets & Events only.")
        deflines.push("Reviewer can view Assets, and write Comments in the Projects which they are part of.")
        deflines.push("Reviewer can view Assets only in Review stage or after.")
        deflines.push("Reviewer can view events relating to Projects which they are part of.")
        deflines.push("Reviewer CANNOT view tasks.")
    }

    // Currently not using viewer
    if (role === UserRole.viewer) {
        deflines.push("Viewer has only scoped view access to Assets only.")
        deflines.push("Viewer can view Assets in the Projects which they are added to.")
        deflines.push("Viewer CANNOT write or view comments.")
    }

    return deflines
}


export const EntityType = {
    project: "Project",
    asset: "Asset",
    task: "Task",
    event: "Event",
    poll: "Poll",
    comment: "Comment",
    invitation: "Invitation"
}



export const EntityLogType = {
    created: "Created",
    updated: "Updated",
    archived: "Archieved",
    queried: "Queried",
}






export const ProjectStatus = {
    all: "All",  // Used in filters
    pending: "Pending",
    finalised: "Finalised",
    archived: "Archived"
}

export const all_ProjectStatuses = [
    ProjectStatus.all,
    ProjectStatus.pending,
    ProjectStatus.finalised,
    ProjectStatus.archived
]


export const ProjectFilter_CreatedOrShared = {
    all: "All",
    createdByMe: "Created By Me",
    sharedWithMe: "Shared With Me"
}

export const all_ProjectFilter_CreatedOrShared = [
    ProjectFilter_CreatedOrShared.all,
    ProjectFilter_CreatedOrShared.createdByMe,
    ProjectFilter_CreatedOrShared.sharedWithMe
]

export const AssetStatus = {
    pending: "Pending",
    finalised: "Finalised",
    archived: "Archived",
}

export const AnimationType = {
    assetFinalised: "assetFinalised",
    assetApproved: "assetApproved",
    assetRejected: "assetRejected",
}


export const CommonEntityFilter = {
    all: ProjectStatus.all,
    pending: ProjectStatus.pending,
    finalised: ProjectStatus.finalised
}





export const AssetType = {
    image: "Image plus caption",
    text: "Text only",
    html: "Html",
    video: "Video plus caption",
    mixed: "Mixed"
}


export const allAssetTypes = [
    AssetType.text,
    AssetType.image,
    // AssetType.html,
    AssetType.video,
    // AssetType.mixed
]


export const AssetCreateStatusType = {
    all: "All",
    createRequest: "Create Request",
    createdAsset: "Created Asset"
}

export const allAssetCreateStatusType = [
    AssetCreateStatusType.all,
    AssetCreateStatusType.createRequest,
    AssetCreateStatusType.createdAsset
]

export const ProjectFolderLevel = {
    project: "Projects",
    assets: "Assets",
    comments: "Comments"
}

export const emptyTaskAttachments = {
    projects: [],
    assets: [],
    comments: []
}

export const doSelectionsContain = (objOfArr, type, entity) => {

    let doesContain = false
    let containsChildElements = false

    if (type === ProjectFolderLevel.project) {

        // console.log("ARRAY OF OBJECTS")
        // console.log(objOfArr)

        // console.log("ENTITY")
        // console.log(entity)

        let foundElements = objOfArr.projects.filter((p) => { return p.id === entity.id })
        if (foundElements.length) {
            doesContain = true
        }

        let assetsOfProj = objOfArr.assets.filter((p) => { return p.projectId === entity.id })
        let commentsOfProj = objOfArr.comments.filter((c) => { return c.projectId === entity.id })
        if (assetsOfProj.length || commentsOfProj.length) {
            containsChildElements = true
        }

    }

    if (type === ProjectFolderLevel.assets) {
        let foundElements = objOfArr.assets.filter((p) => { return p.id === entity.id })
        if (foundElements.length) {
            doesContain = true
        }
        let commentsOfProj = objOfArr.comments.filter((c) => { return c.assetId === entity.id })
        if (commentsOfProj.length) {
            containsChildElements = true
        }
    }

    if (type === ProjectFolderLevel.comments) {
        let foundElements = objOfArr.comments.filter((p) => { return p.id === entity.id })
        if (foundElements.length) {
            doesContain = true
        }
    }

    return { isSelected: doesContain, hasSelectedChildren: containsChildElements }
}


export const AssetCaptionType = {
    caption: "Caption",
    description: "Description",
    title: "Title",
    content: "Content",
    // idea : "Idea"
}

export const allAssetCaptionTypes = [
    AssetCaptionType.caption,
    AssetCaptionType.description,
    AssetCaptionType.title,
    AssetCaptionType.content,
    // AssetCaptionType.idea
]

export const TaskStatus = {
    all: "All",
    pending: "Pending",
    working: "Working",
    complete: "Complete",
}


export const entityInfo_ForNotif = (entityType, entity, imgUrlsInfoArr, actionBy_defaultCurrentUser, overrideSendToInfo) => {

    let name = ""
    let imgUrlsInfo = imgUrlsInfoArr ?? []
    let messagePayload = ""
    let version = entity.version ? entity.version : ""
    let createdByInfo = {}
    let assignedTo = []
    let sharedWith = []
    let members = []
    let actionByInfo = actionBy_defaultCurrentUser ?? currentUserInfo()


    console.log("ENTITY TYPE IS")
    console.log(entityType)

    console.log("ENTITY IS")
    console.log(entity)

    createdByInfo = entity.byUserInfo ?? {}

    if (entityType === EntityType.task) {
        name = entity.title ?? ""
        assignedTo = entity.assignedTo
        sharedWith = entity.sharedWith
        // actionByUser Image 
    } else {
        name = entity.name ?? ""

    }

    if (entityType === EntityType.event) {
        assignedTo = entity.assignedTo
        sharedWith = entity.sharedWith

        // actionByuserImage
    }

    if (entityType === EntityType.asset) {
        if (entity.createStatus === AssetCreateStatusType.createdAsset && entity.createRequestDesc) {
            messagePayload = entity.createRequestDesc
        }
    }

    if (entityType === EntityType.project) {
        members = entity.members ?? []
    }

    if (entityType === EntityType.comment) {
        messagePayload = entity.message
    }

    return {
        "type": entityType,
        "id": entity.id,
        "version": version,
        "name": name,
        "message": messagePayload,
        "imgUrlsInfo": imgUrlsInfo,
        "createdByInfo": createdByInfo,
        "overrideSendToInfo": overrideSendToInfo ?? null,
        "actionByInfo": actionByInfo,
        "assignedTo": assignedTo,
        "sharedWith": sharedWith,
        "members": members
    }
}

export const NotificationFor = {

    project_addedYouAsMember: "project_addedYouAsMember",
    project_changedDetails: "project_changedDetails",
    project_changedDates: "project_changedDates",
    project_archived: "project_archived",
    project_finalised: "project_finalised",
    project_changedStatustPending: "project_changedStatustPending",

    task_assignedToYou: "task_assignedToYou",
    task_sharedWithYou: "task_sharedWithYou",
    task_changedStatus: "task_changedStatus",
    task_completed: "",
    task_archived: "",
    task_changedDetails: "task_changedInfo",
    task_changedPrioirty: "task_changedPrioirty",
    task_changedDates: "task_changedDates",
    task_updatedProjAssComms: "task_updatedProjAssComms",
    task_addedNote: "task_addedNote",

    asset_AddedCreateRequest: "asset_AddedCreateRequest",
    asset_updatedDescTitleContent: "asset_updatedDescTitleContent",
    asset_uploadedNewVersion: "asset_uploadedNewVersion",
    asset_changedDetails: "asset_changedDetails",
    asset_archived: "asset_archived",
    asset_finalised: "asset_finalised",
    asset_approved: "asset_approved",
    asset_rejected: "asset_rejected",

    event_assignedToYou: "event_assignedToYou",
    event_sharedWithYou: "event_sharedWithYou",
    event_changedStatus: "event_changedStatus",
    event_changedDetails: "event_changedDetails",
    event_changedDates: "event_changedDates",
    event_updatedProjAssComms: "event_updatedProjAssComms",
    event_addedNote: "event_addedNote",

    comment_added: "comment_added",
    comment_replyAdded: "comment_replyAdded",
    comment_upvoteAdded: "comment_upvoteAdded",
    comment_resolved: "comment_resolved",
}

export const TaskStatus_options = [
    TaskStatus.pending, TaskStatus.working, TaskStatus.complete
]

export const TaskPriority = {
    all: "All",
    low: "Low",
    normal: "Normal",
    high: "High !",
    urgent: "Urgent !!!"
}


export const TastPriority_options = [
    TaskPriority.normal,
    TaskPriority.low,
    TaskPriority.high,
    TaskPriority.urgent
]

export const TaskSortOptions = {
    createdDateAscending: "Created Date Ascending",
    createdDateDescending: "Created Date Descending",
    typeToCreateFirst: "To be created first",
    typeToUpdateFirst: "To be updated first",
}

export const TaskType = {
    all: "All",
    update: "Update",
    create: "Create",
}

export const TaskType_options = [
    TaskType.update, TaskType.create
]


export const all_TaskFilter_type = [
    TaskType.all,
    TaskType.create,
    TaskType.update
]

export const all_TaskFilter_status = [
    TaskStatus.all,
    TaskStatus.pending,
    TaskStatus.working,
    TaskStatus.complete
]



export const SelectableEntity = {
    projects: "Projects",
    assets: "Assets",
    comments: "Comments",
    tasks: "Tasks",
    events: "Events"
}


export const UserPickerFor = {
    projectMember: "Project Member",
    taskAssign: "Task Assign To",
    taskAddedBy: "Task Added By",
    taskShareWith: "Task Shared With",
    eventAssign: "Event Assign",
    eventSharedWith: "Event Shared With",
    projectMemberFilter: "Project With Members",
    projectAddedByFilter: "Project Approved By",
    assetApprovedByFilter: "Asset Approved By",
    assetRejectedByFilter: "Asset Rejected By",
    assetAddedByFilter: "Asset Added By",

}

export const AvatarStackFor = {
    projectMembers: "Project Members",
    taskAssignTo: "Task Assign to",
    eventAssignTo: "Event Assign to",
    filterAddedBy: "Filter Added by",
    filterAssignedTo: "Filter Assigned to",
    eventMember: "Event Member",
    assetApprovalBrief: "Asset Approval Brief",
    other: "Other"
}


export const SubscriptionStatus = {
    trial: "Trial",
    active: "Active",
    warning: "Warning",
    locked: "Locked",
    cancelled: "Cancelled"
}

export const ProfileCompletionStatus = {
    pending: "Pending",
    complete: "Complete"
}


export const EventCompletionStatus = {
    pending: "Pending",
    complete: "Complete"
}

export const EventFilterType = {
    all: "All",
    pending: EventCompletionStatus.pending,
    complete: EventCompletionStatus.complete,
    archived: "Archived"
}


export const AnnotationType = {
    tag: "Tag",
    highlight: "Highlight"
}


export const AnnotationHoveredFrom = {
    asset: "OnAsset",
    comment: "OnComment"
}

export const AnnotationShowType = {
    composing: "Composing",
    existingComment: "Existing Comment"
}

export const filterAnnotationsByAssetType = (annotationsArray) => {
    // Filter
    let textAnnts = []
    let vidAnnts = []
    let imageAnnts = []

    annotationsArray.forEach(a => {
        if (a.assetType === AssetType.text) {
            textAnnts.push(a)
        } else if (a.assetType === AssetType.video) {
            vidAnnts.push(a)
        } else {
            imageAnnts.push(a)
        }
    })
    return { "textAnnotations": textAnnts, "videoAnnotations": vidAnnts, "imageAnnotations": imageAnnts }
}


export const CommentMessageType = {
    general: "General",
    approving: "Approving",
    feedback: "Feedback",
    rejecting: "Rejecting"
}


// Parse Pointers

export const userPointer = (id) => {
    return {
        __type: 'Pointer',
        className: '_User',
        objectId: id
    }
}

export const workspacePointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Workspace',
        objectId: id
    }
}

export const projectPointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Project',
        objectId: id
    }
}

export const assetPointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Asset',
        objectId: id
    }
}

export const eventPointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Event',
        objectId: id
    }
}

export const taskPointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Task',
        objectId: id
    }
}

export const notificationPointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Notification',
        objectId: id
    }
}

export const commentPointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Comment',
        objectId: id
    }
}

export const previewPointer = (id) => {
    return {
        __type: 'Pointer',
        className: 'Preview',
        objectId: id
    }
}


export const socialMediaChannels_HexColors = {
    linkedIn: "#0a66c2",
    facebook: "#1877f2",
    instagram: "#c32aa3",
    youtube: "#ff0000",
    twitter: "#1da1f2",
    tiktok: "#010101",
    pinterest: "#bd081c",
    medium: "#02b875",
    snapchat: "#fffc00",
    glassdoor: "#0caa41",
    whatsapp: "#25d366",
    telegram: "#0088cc",
    tumblr: "#35465d",
    reddit: "#ff4500",
    yelp: "#d32323",
    spotify: "#1ed760",
    quora: "#aa2200",
    twitch: "#9146ff",
    slack: "#4a154b",
    discord: "#5865f2",
    soundCloud: "#ff5500"
}




export const PlatformType = {
    any: "Any",
    socialMedia: "Social Media",
    website: "Website",
    app: "App",
    print: "Print",
    all: "All",
    other: "Other"
}
export const all_PlatformTypes = [
    PlatformType.any,
    PlatformType.socialMedia,
    PlatformType.website,
    PlatformType.app,
    PlatformType.print,
    PlatformType.all,
    PlatformType.other
]




export const DeviceType = {
    any: "Any",
    desktop: "Desktop",
    mobile: "Mobile",
    tv: "TV",
    all: "All",
    other: "Other"
}
export const all_DeviceTypes = [
    DeviceType.any,
    DeviceType.desktop,
    DeviceType.mobile,
    // DeviceType.tv,
    DeviceType.all,
    DeviceType.other
]



export const ColorMode = {
    any: "Any",
    default: "Default",
    light: "Light",
    dark: "Dark",
    all: "All",
    other: "Other"
}
export const all_ColorModes = [
    ColorMode.any,
    // ColorMode.default,
    ColorMode.light,
    ColorMode.dark,
    // ColorMode.all,
    // ColorMode.other
]

export const apt_ColorModes = (sm_Platform) => {
    let cm = []

    return cm
}


export const SocialMedia_Platform = {
    any: "Any",
    linkedIn: "LinkedIn",
    facebook: "Facebook",
    instagram: "Instagram",
    twitter: "Twitter",
    youtube: "Youtube",
    // tiktok : "Tiktok",
    pinterest: "Pinterest",
    // medium: "Medium",
    // snapchat: "#fffc00",
    // glassdoor: "#0caa41",
    // whatsapp: "#25d366",
    // telegram: "#0088cc",
    // tumblr: "#35465d",
    // reddit: "#ff4500",
    // yelp: "#d32323",
    // spotify: "#1ed760",
    // quora: "#aa2200",
    // twitch: "#9146ff",
    // slack: "#4a154b",
    // discord: "#5865f2",
    // soundCloud : "#ff5500"
    all: "All",
    other: "Other"
}

export const SubPlatform = {
    any: "Any",
    all: "All",
    other: "Other"
}


export const all_subPlatforms = [
    SubPlatform.any,
    SocialMedia_Platform.linkedIn,
    SocialMedia_Platform.facebook,
    SocialMedia_Platform.instagram,
    SocialMedia_Platform.twitter,
    SocialMedia_Platform.youtube,
    // socialMediaChannels.tiktok,
    // SocialMedia_Platform.pinterest,
    // socialMediaChannels.medium
    SubPlatform.all,
    SubPlatform.other
]


export const Allowed_SocialMedia_Platforms = [
    SocialMedia_Platform.linkedIn,
    SocialMedia_Platform.facebook,
    SocialMedia_Platform.instagram,
    SocialMedia_Platform.twitter,
    SocialMedia_Platform.youtube,
    // socialMediaChannels.tiktok,
    SocialMedia_Platform.pinterest,
    // socialMediaChannels.medium
]



export const thumbnailArt_for_SocailMedia_Platform = (channel, selected, extraClass) => {
    let img = null

    if (channel === SocialMedia_Platform.linkedIn) {
        img = <AiFillLinkedin style={selected ? { color: socialMediaChannels_HexColors.linkedIn } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.facebook) {
        img = <AiFillFacebook style={selected ? { color: socialMediaChannels_HexColors.facebook } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.instagram) {
        img = <AiFillInstagram style={selected ? { color: socialMediaChannels_HexColors.instagram } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.twitter) {
        img = <AiOutlineTwitter style={selected ? { color: socialMediaChannels_HexColors.twitter } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.youtube) {
        img = <AiFillYoutube style={selected ? { color: socialMediaChannels_HexColors.youtube } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.youtube) {
        img = <AiFillYoutube style={selected ? { color: socialMediaChannels_HexColors.youtube } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.tiktok) {
        img = <SiTiktok style={selected ? { color: socialMediaChannels_HexColors.tiktok } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.pinterest) {
        img = <FaPinterest style={selected ? { color: socialMediaChannels_HexColors.pinterest } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    if (channel === SocialMedia_Platform.medium) {
        img = <AiFillMediumSquare style={selected ? { color: socialMediaChannels_HexColors.medium } : { opacity: 0.20 }} className={" is-size-4 " + (extraClass ?? "")} />
    }

    return img
}

export const infoArrayWithoutCurrentUser = (arr) => {
    let cu = currentUserInfo()
    let filteredArr = arr.filter((inf) => inf.id !== cu.id)
    return filteredArr ?? []
}

export const ContentType = {
    any: "Any",
    post: "Post",
    story: "Story",
    profilePic: "Account Profile Pic",
    coverPic: "Account Cover Pic",
    page_profilePic: "FB Page Profile Pic", //FB 
    page_coverPic: "FB Page Cover Pic", //FB
    company_profilePic: "Company Profile Pic",
    company_coverPic: "Company Cover Pic",
    event_profilePic: "Event Profile Pic",
    event_coverPic: "Event Cover Pic",
    videoThumbnail: "Video Thumbnail",
    all: "All",
    other: "Other"
}

export const apt_ContentTypes = (sm_Platform) => {
    let pstTypes = []

    pstTypes.push(ContentType.any)


    //Insta
    if (sm_Platform === SocialMedia_Platform.instagram) {
        pstTypes.push(ContentType.post)
        pstTypes.push(ContentType.story)
        pstTypes.push(ContentType.profilePic)
    }

    //Fb 
    if (sm_Platform === SocialMedia_Platform.facebook) {
        pstTypes.push(ContentType.post)
        pstTypes.push(ContentType.story)
        pstTypes.push(ContentType.profilePic)
        pstTypes.push(ContentType.coverPic)
        pstTypes.push(ContentType.page_profilePic)
        pstTypes.push(ContentType.page_coverPic)
    }

    //Twitter
    if (sm_Platform === SocialMedia_Platform.twitter) {
        pstTypes.push(ContentType.post)
        pstTypes.push(ContentType.story)
        pstTypes.push(ContentType.profilePic)
        pstTypes.push(ContentType.coverPic)

    }

    //YT
    if (sm_Platform === SocialMedia_Platform.youtube) {
        pstTypes.push(ContentType.post)
        pstTypes.push(ContentType.story)
        pstTypes.push(ContentType.profilePic)
        pstTypes.push(ContentType.coverPic)
        pstTypes.push(ContentType.videoThumbnail)
    }

    //LinkedIn
    if (sm_Platform === SocialMedia_Platform.linkedIn) {
        pstTypes.push(ContentType.post)
        pstTypes.push(ContentType.story)
        pstTypes.push(ContentType.profilePic)
        pstTypes.push(ContentType.coverPic)
        pstTypes.push(ContentType.company_profilePic)
        pstTypes.push(ContentType.company_coverPic)
        pstTypes.push(ContentType.event_profilePic)
        pstTypes.push(ContentType.event_coverPic)
    }

    //Pinterest
    if (sm_Platform === SocialMedia_Platform.pinterest) {
        pstTypes.push(ContentType.post)
        pstTypes.push(ContentType.story)
        pstTypes.push(ContentType.profilePic)
        pstTypes.push(ContentType.coverPic)
    }


    if (sm_Platform === SocialMedia_Platform.any || sm_Platform === SocialMedia_Platform.all) {
        pstTypes.push(ContentType.post)
        pstTypes.push(ContentType.story)
        pstTypes.push(ContentType.profilePic)
        pstTypes.push(ContentType.coverPic)
    }

    pstTypes.push(ContentType.all)
    pstTypes.push(ContentType.other)

    return pstTypes
}

export const create_AssetForInfo = (thisDict) => {
    let dict = thisDict ?? {}
    let newDict = {
        platform: dict.platform ?? PlatformType.any,
        platform_Other: dict.platform_Other ?? "",
        subPlatform: dict.subPlatform ?? SubPlatform.any,
        subPlatform_Other: dict.subPlatform_Other ?? "",
        contentType: dict.contentType ?? ContentType.any,
        contentType_Other: dict.contentType_Other ?? "",
        deviceType: dict.deviceType ?? DeviceType.any,
        deviceType_Other: dict.deviceType_Other ?? "",
        colorMode: dict.colorMode ?? ColorMode.any,
        colorMode_Other: dict.colorMode_Other ?? ""
    }
    return newDict
}



export const SocialMedia_PageType = {
    feed: "Feed",
    profile: "Profile",
    company: "Company",
    group: "Group",
    event: "Event",
}

// export const previewImageType = {
//     post_Pic           : "Post_Pic",                  
//     profile_Pic           : "Profile_Pic",                  
//     cover_Pic             : "Cover_Pic",
//     company_Profile_Pic   : "Company_Profile_Pic",
//     company_Cover_Pic     : "Company_Cover_Pic",
//     event_Profile_Pic     : "Company_Profile_Pic",
//     event_Cover_Pic       : "Company_Cover_Pic",
// }


export const previewType = {
    instagram_Profle_Image: "Instagram_Profile_Image",
    instagram_Post: "Instagram_Post",
    instagram_Story: "Instagram_Story",
    facebook_User_Profle_Image: "Facebook_User_Profile_Image",
    facebook_User_Cover_Image: "Facebook_User_Cover_Image",
    facebook_Page_Profle_Image: "Facebook_Page_Profile_Image",
    facebook_Page_Cover_Image: "Facebook_Page_Cover_Image",
    facebook_Group_Profle_Image: "Facebook_Group_Profile_Image",
    facebook_Group_Cover_Image: "Facebook_Group_Cover_Image",
    facebook_Post: "Facebook_Post",
    facebook_Story: "Facebook_Story",
    linkedIn_User_Profle_Image: "LinkedIn_User_Profile_Image",
    linkedIn_User_Cover_Image: "LinkedIn_User_Cover_Image ",
    linkedIn_Company_Profle_Image: "LinkedIn_Company_Profile_Image",
    linkedIn_Company_Cover_Image: "LinkedIn_Company_Cover_Image",
    linkedIn_Event_Profle_Image: "LinkedIn_Event_Profle_Image",
    linkedIn_Event_Cover_Image: "LinkedIn_Event_Cover_Image",
    linkedIn_Post: "LinkedIn_Post",
    linkedIn_Story: "LinkedIn_Story",
    twitter_Profle_Image: "Twitter_Profile_Image",
    twitter_Cover_Image: "Twitter_Cover_Image ",
    twitter_Post: "Twitter_Post",
    twitter_Story: "Twitter_Story",
    youtube_Profile_Image: "Youtube_Profile_Image",
    youtube_Cover_Image: "Youtube_Profile_Cover",
    youtube_Post: "Youtube_Video_Thumbnail",
    youtube_Story: "Youtube_Story",
    // insta_profileBox
    // fb_companyBox
    // fb_profileBox
    // twitter_profileBox
    // li_eventBox
    // li_profileBox
    // li_companyBox

}

export const previewBoxType = {
    instagram_profileBox: "insta_profileBox",
    instagram_postBox: "insta_postBox", //TODO
    instagram_storyBox: "insta_storyBox", //TODO
    facebook_companyBox: "fb_companyBox",
    facebook_groupBox: "fb_groupBox",
    facebook_profileBox: "fb_profileBox",
    facebook_postBox: "fb_postBox", //TODO
    facebook_storyBox: "fb_storyBox", //TODO
    twitter_profileBox: "twitter_profileBox",
    twitter_postBox: "twitter_postBox", //TODO
    twitter_storyBox: "twitter_storyBox", //TODO
    linkedIn_eventBox: "li_eventBox",
    linkedIn_profileBox: "li_profileBox",
    linkedIn_companyBox: "li_companyBox",
    linkedIn_postBox: "li_postBox", //TODO
    linkedIn_storyBox: "li_storyBox", //TODO

    youtube_profileBox: "yt_profileBox", //TODO
    youtube_coverBox: "yt_coverBox", //TODO
    youtube_postBox: "yt_postBox", //TODO
    youtube_storyBox: "yt_storyBox", //TODO

}

export const previewComponents = () => {
    // previewType
    // previewBoxType
    // category
    // pageCategory
    // channel

    let twitter_Story = {
        "type": previewType.twitter_Story,
        "boxtype": previewBoxType.twitter_storyBox,
        "category": ContentType.story,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.twitter
    }

    let instagram_Profle_Image = {
        "type": previewType.instagram_Profle_Image,
        "boxtype": previewBoxType.instagram_profileBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.instagram
    }
    let instagram_Post = {
        "type": previewType.instagram_Post,
        "boxtype": previewBoxType.instagram_postBox,
        "category": ContentType.post,
        "pageCategory": SocialMedia_PageType.feed,
        "channel": SocialMedia_Platform.instagram
    }
    let instagram_Story = {
        "type": previewType.instagram_Story,
        "boxtype": previewBoxType.instagram_storyBox,
        "category": ContentType.story,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.instagram
    }

    let facebook_User_Profle_Image = {
        "type": previewType.facebook_User_Profle_Image,
        "boxtype": previewBoxType.facebook_profileBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.facebook
    }
    let facebook_User_Cover_Image = {
        "type": previewType.facebook_User_Cover_Image,
        "boxtype": previewBoxType.facebook_profileBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.facebook
    }
    let facebook_Page_Profle_Image = {
        "type": previewType.facebook_Page_Profle_Image,
        "boxtype": previewBoxType.facebook_companyBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.company,
        "channel": SocialMedia_Platform.facebook
    }
    let facebook_Page_Cover_Image = {
        "type": previewType.facebook_Page_Cover_Image,
        "boxtype": previewBoxType.facebook_companyBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.company,
        "channel": SocialMedia_Platform.facebook
    }
    let facebook_Group_Profle_Image = {
        "type": previewType.facebook_Group_Profle_Image,
        "boxtype": previewBoxType.facebook_groupBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.group,
        "channel": SocialMedia_Platform.facebook
    }
    let facebook_Group_Cover_Image = {
        "type": previewType.facebook_Group_Cover_Image,
        "boxtype": previewBoxType.facebook_groupBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.group,
        "channel": SocialMedia_Platform.facebook
    }
    let facebook_Post = {
        "type": previewType.facebook_Post,
        "boxtype": previewBoxType.facebook_postBox,
        "category": ContentType.post,
        "pageCategory": SocialMedia_PageType.feed,
        "channel": SocialMedia_Platform.facebook
    }
    let facebook_Story = {
        "type": previewType.facebook_Story,
        "boxtype": previewBoxType.facebook_storyBox,
        "category": ContentType.story,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.facebook
    }

    let linkedIn_User_Profle_Image = {
        "type": previewType.linkedIn_User_Profle_Image,
        "boxtype": previewBoxType.linkedIn_profileBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.linkedIn
    }
    let linkedIn_User_Cover_Image = {
        "type": previewType.linkedIn_User_Cover_Image,
        "boxtype": previewBoxType.linkedIn_profileBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.linkedIn
    }
    let linkedIn_Company_Profle_Image = {
        "type": previewType.linkedIn_Company_Profle_Image,
        "boxtype": previewBoxType.linkedIn_companyBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.company,
        "channel": SocialMedia_Platform.linkedIn
    }
    let linkedIn_Company_Cover_Image = {
        "type": previewType.linkedIn_Company_Cover_Image,
        "boxtype": previewBoxType.linkedIn_companyBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.company,
        "channel": SocialMedia_Platform.linkedIn
    }
    let linkedIn_Event_Profle_Image = {
        "type": previewType.linkedIn_Event_Profle_Image,
        "boxtype": previewBoxType.linkedIn_eventBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.event,
        "channel": SocialMedia_Platform.linkedIn
    }
    let linkedIn_Event_Cover_Image = {
        "type": previewType.linkedIn_Event_Cover_Image,
        "boxtype": previewBoxType.linkedIn_eventBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.event,
        "channel": SocialMedia_Platform.linkedIn
    }
    let linkedIn_Post = {
        "type": previewType.linkedIn_Post,
        "boxtype": previewBoxType.linkedIn_postBox,
        "category": ContentType.post,
        "pageCategory": SocialMedia_PageType.feed,
        "channel": SocialMedia_Platform.linkedIn
    }
    let linkedIn_Story = {
        "type": previewType.linkedIn_Story,
        "boxtype": previewBoxType.linkedIn_storyBox,
        "category": ContentType.story,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.linkedIn
    }

    let twitter_Profle_Image = {
        "type": previewType.twitter_Profle_Image,
        "boxtype": previewBoxType.twitter_profileBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.twitter
    }
    let twitter_Cover_Image = {
        "type": previewType.twitter_Cover_Image,
        "boxtype": previewBoxType.twitter_profileBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.twitter
    }
    let twitter_Post = {
        "type": previewType.twitter_Post,
        "boxtype": previewBoxType.twitter_postBox,
        "category": ContentType.post,
        "pageCategory": SocialMedia_PageType.feed,
        "channel": SocialMedia_Platform.twitter
    }


    let youtube_Post = {
        "type": previewType.youtube_Post,
        "boxtype": previewBoxType.youtube_postBox,
        "category": ContentType.post,
        "pageCategory": SocialMedia_PageType.feed,
        "channel": SocialMedia_Platform.youtube
    }

    let youtube_Profile_Image = {
        "type": previewType.youtube_Profile_Image,
        "boxtype": previewBoxType.youtube_profileBox,
        "category": ContentType.profilePic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.youtube
    }

    let youtube_Cover_Image = {
        "type": previewType.youtube_Cover_Image,
        "boxtype": previewBoxType.youtube_coverBox,
        "category": ContentType.coverPic,
        "pageCategory": SocialMedia_PageType.profile,
        "channel": SocialMedia_Platform.youtube
    }

    let youtube_Story = {
        "type": previewType.youtube_Story,
        "boxtype": previewBoxType.youtube_storyBox,
        "category": ContentType.story,
        "pageCategory": SocialMedia_PageType.feed,
    }


    let allComps = [
        instagram_Profle_Image,
        instagram_Post,
        instagram_Story,
        facebook_User_Profle_Image,
        facebook_User_Cover_Image,
        facebook_Page_Profle_Image,
        facebook_Page_Cover_Image,
        // facebook_Group_Profle_Image,
        // facebook_Group_Cover_Image,
        facebook_Post,
        facebook_Story,
        linkedIn_User_Profle_Image,
        linkedIn_User_Cover_Image,
        linkedIn_Company_Profle_Image,
        linkedIn_Company_Cover_Image,
        linkedIn_Event_Profle_Image,
        linkedIn_Event_Cover_Image,
        linkedIn_Post,
        // linkedIn_Story,
        twitter_Profle_Image,
        twitter_Cover_Image,
        twitter_Post,
        twitter_Story,
        youtube_Post,
        youtube_Profile_Image,
        youtube_Cover_Image,
        youtube_Story
    ]

    return allComps

}

export const previewCompForType = (type) => {
    let allComps = previewComponents()
    let filtered = allComps.filter((p) => {
        return p.type === type
    })
    return filtered[0]
}

export const previewFilter = {
    pinned: "Pinned",
    all: "All",
    posts: "Posts",
    linkedIn: "LinkedIn",
    facebook: "Facebook",
    instagram: "Instagram",
    twitter: "Twitter",
    youtube: "Youtube",
    profile: "Profile",
    company: "Company",
    event: "Event",
    profilePic: "ProfilePic",
    cover: "CoverPic"
}

export const filterTabs = [
    previewFilter.pinned,
    previewFilter.all,
    previewFilter.posts,
    previewFilter.linkedIn,
    previewFilter.facebook,
    previewFilter.instagram,
    previewFilter.twitter,
    previewFilter.profile,
    previewFilter.company,
    previewFilter.event,
    previewFilter.profilePic,
    previewFilter.cover,
]

export const getPreviewFilter = (tab) => {

    var filterObj = {
        "type": null,
        "boxtype": null,
        "category": null,
        "pageCategory": null,
        "channel": null
    }
    // previewType
    // previewBoxType
    // previewCategory
    // previewPageCategory
    // socialMediaChannels

    if (tab === previewFilter.all) {
        // Do nothing
    }

    if (tab === previewFilter.posts) {
        filterObj.category = ContentType.post
    }
    if (tab === previewFilter.linkedIn) {
        filterObj.channel = SocialMedia_Platform.linkedIn
    }
    if (tab === previewFilter.facebook) {
        filterObj.channel = SocialMedia_Platform.facebook
    }
    if (tab === previewFilter.instagram) {
        filterObj.channel = SocialMedia_Platform.instagram
    }
    if (tab === previewFilter.twitter) {
        filterObj.channel = SocialMedia_Platform.twitter
    }
    if (tab === previewFilter.youtube) {
        filterObj.channel = SocialMedia_Platform.youtube
    }
    if (tab === previewFilter.profile) {
        filterObj.pageCategory = SocialMedia_PageType.profile
    }
    if (tab === previewFilter.company) {
        filterObj.pageCategory = SocialMedia_PageType.company
    }
    if (tab === previewFilter.event) {
        filterObj.pageCategory = SocialMedia_PageType.event
    }
    if (tab === previewFilter.profilePic) {
        filterObj.category = ContentType.profilePic
    }
    if (tab === previewFilter.cover) {
        filterObj.category = ContentType.coverPic
    }

    return filterObj
}

