
import React, { useEffect, useState, useRef } from 'react'
import { Form, Tabs, Box, Button, Panel, Columns, Table, Tag, Heading } from 'react-bulma-components';
import LoadingAux from '../../Containers/LoadingAux';
import { Comment } from '../../Models/Comment';
import { useInView } from 'react-hook-inview'

import { containsUpvoteByCU, hasEmoji, randomCode, removeKeyFromObjs_InArray, removeKeysFromUserInfo_Obj, sampleImageUrl } from '../../utilities/Utilities';
import CommentRow from './CommentRow';
import { IoTrashBinOutline, IoClose } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';

import { AnnotationHoveredFrom, AnnotationShowType, CRUDEvent, TaskStatus, userPointer } from '../../Models/EnumsAndPointers';
import { HiAnnotation, HiOutlineAnnotation } from 'react-icons/hi';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { GrEmoji } from 'react-icons/gr';
import { MdDone } from 'react-icons/md';
import { GiSandsOfTime } from 'react-icons/gi';
import { FaRegCircle, FaComment } from 'react-icons/fa';
import UserAvatar from '../Calendar/UserAvatar';
import { BiArrowBack } from 'react-icons/bi';
import { ImCheckmark, ImCross } from 'react-icons/im';
import ReactTooltip from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { Task } from '../../Models/Task';
import AssetTasks from './AssetTasks';
import AssetEvents from './AssetEvents';

// MdDone, IoMdClose, GiSandsOfTime


const AlwaysScrollToLast = (props) => {
    const elementRef = useRef(null);
    useEffect(() => {

        console.log("ALWAYS PROPS ARE")
        console.log(props.depVal)

        if (elementRef.current) {
            elementRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            });
        } else {
            console.log("NO CURRENT ELEMENT REF")
        }
    }, [props.depVal]);
    return <div ref={elementRef} />;
};



const AssetAttributeBox = (props) => {


    const MainTABS = {
        comments: "Comments",
        tasks: "Tasks",
        events: "Events",
        info: "Info",
        members: "Members"
    }


    const CommentTABS = {
        unresolved: "Unresolved",
        resolved: "Resolved"
    }

    const [currentTab, setCurrentTab] = useState(MainTABS.comments)
    const [currentCommentTab, setCurrentCommentTab] = useState(CommentTABS.unresolved)
    const [isFetching, setIsFetching] = useState(false)

    const [comments, setComments] = useState([])



    const [replyComments, setReplyComments] = useState([])

    const [started, setStarted] = useState(false)


    //NEED FROM PROPS - existingComment, currentAsset, annotationRemoved, clearAnnotations
    const [currentMessage, setCurrentMessage] = useState(null)
    const [existingComment, setExistingComment] = useState(null)
    const [mainCommentOfThread, setMainCommentOfThread] = useState(null)
    const [isSavingComment, setIsSavingComment] = useState(false)
    const [lastFocusedTextareaId, setLastFocusedTextareaId] = useState(null)
    const composeTextMessageAreaId = "composeMessageTextarea"


    const [ref, isVisible] = useInView({
        threshold: 1,
    })




    useEffect(() => {
        let isMounted = true;


        if (isMounted) {

            if (!started) {
                if (isMounted) {
                    setStarted(true)
                }
                console.log("INITIAL COMMENT START");
            }


            setCurrentTab(MainTABS.comments)
            setCurrentCommentTab(CommentTABS.unresolved)
            setCurrentMessage(null)
            setMainCommentOfThread(null)
            setLastFocusedTextareaId(null)
            setComments([])
            setReplyComments([])
            if (started && isVisible) {
                console.log("START IS TRUE");
                console.log(`IS VISIBLE =  ${isVisible}`);


                setTimeout(() => {
                    if (started && isVisible) {
                        console.log("SETTING TO FETCH COMMENTS NOW");
                        if (isMounted) {
                            setIsFetching(true)
                            Comment.getAll(props.currentAsset.id, null, null, (succ, comments, errMsg) => {
                                if (isMounted) {
                                    setIsFetching(false)
                                }
                                if (succ) {
                                    if (isMounted) {
                                        setComments(comments)
                                    }
                                    return () => (isMounted = false)
                                } else {
                                    console.log(errMsg)
                                }

                            })
                        }
                    } else {
                        // started = false
                        // if (toShowMarkableImage) {
                        //     setToShowMarkableImgae(false)
                        // }
                        if (isFetching && isMounted) {
                            setIsFetching(false)
                        }

                        if (!isVisible) {
                            setStarted(false)
                        }

                        console.log("SETTING EVERYTHING IN COMMENTS FALSE");
                    }
                }, 2500)
            }
        }

        return () => (isMounted = false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentAsset.id, MainTABS.comments, isVisible])


    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setReplyComments([])

            if (mainCommentOfThread) {
                // fetch reply comments 
                Comment.getAll(null, mainCommentOfThread.id, null, (succ, comments, errMsg) => {
                    if (isMounted) {
                        setIsFetching(false)
                    }
                    if (succ) {

                        // comments.map((c) => {
                        //     c.byUser = {
                        //         dpUrl: sampleImageUrl,
                        //         email: "priyanka@testpost.app",
                        //         id: "",
                        //         workspace: ""
                        //     }
                        //     return null
                        // })

                        if (isMounted) {
                            setReplyComments(comments)
                        }
                    } else {
                        console.log(errMsg)
                    }
                })
            }
        }

        return () => (isMounted = false)

    }, [mainCommentOfThread])


    let getComments = () => {
        // let oneComment = new Comment()
        // oneComment.byUserName = "Priyanka Moza"
        // oneComment.id = randomCode(6)
        // oneComment.byUser = {
        // dpUrl: "https://randomuser.me/api/portraits/men/44.jpg",
        // email: "priyanka@testpost.app",
        // id: "",
        // workspace: ""
        // }
        // oneComment.message = "Hi, good work. I like the feel of it. Just that we have missed 2 key points from our campain description here, which is that this post has be centerd around tech and also must contain Apple product."
        // oneComment.isReply = false
        // oneComment.replies = []
        // oneComment.createdAt = "1 min ago"

        // return [oneComment, oneComment, oneComment, oneComment, oneComment, oneComment, oneComment]
    }


    let filteredComments = (resolved = false) => {
        let comms = [...comments]
        comms = comms.filter((c) => { return c.isMarkedDone === resolved })
        return comms ?? []
    }

    let changeCurrentTab = (newTab) => {
        setCurrentTab(newTab)
    }

    let changeCurrent_CommentTab = (newTab) => {
        setCurrentCommentTab(newTab)
    }


    let toggleCompose = (val, from) => {
        setCurrentMessage(null)
        setIsSavingComment(false)
        setExistingComment(null)
        props.toggleComposeAnntActive(val, from)
    }

    const messageChanged = (e) => {
        // make sure the text is clean if needed
        setCurrentMessage(e.target.value)
    }


    const removeAnnotation = (thisAnnotat) => {
        props.annotationRemove(thisAnnotat)
    }

    const emojis = () => {
        // let str = "😎😊😄😃😀😁🤣🤩😍🤗🧐😜🤪😕😏😬😑🤔😕🤤😲🤯😯👀👨‍💻🧑‍💻👩‍💻🙋‍♂️🙋🙋‍♀️🤟👌👍👎✌️☝🏻🙏🙀👏🙌 🎉🥳🎊 💯🎈🔥🥂💥💭💡🌈🎨🖍🕐🕜🎖✍️🖥💻📱⌚️⏱✂️"
        // return str.split('')
        return ["😎", "😊", "😄", "😃", "😀", "😁", "🤣", "🤩", "😍", "🤗", "🧐", "😜", "🤪", "😕", "😏", "😬", "😑", "🤔", "😕", "🤤", "😲", "👀", "👨‍💻", "🧑‍💻", "👩‍💻", "🙋‍♂️", "🙋", "🙋‍♀️", "🤯", "😯", "🤟", "👌", "👍", "👎", "✌️", "☝🏻", "🙏", "🙀", "👏", "🙌", "🎉", "🥳", "🎊", "💯", "🎈", "🔥", "🥂", "💥", "💭", "💡", "🌈", "🎨", "🖍", "🕐", "🕜", "🎖", "✍️", "🖥", "💻", "📱", "⌚️", "⏱", "✂️"]
    }


    const addEmojiToComment = (emoji) => {
        // get currenlty focused textarea.

        if (lastFocusedTextareaId === null || lastFocusedTextareaId === composeTextMessageAreaId) {
            setCurrentMessage(`${currentMessage ?? ""} ${emoji}`)
        } else {
            // Else get note by id and add it to note
            let nt = document.getElementById(lastFocusedTextareaId)
            if (nt) {
                let currentVal = document.getElementById(lastFocusedTextareaId).value
                let annotationId = lastFocusedTextareaId.replace("annotation-note-textarea-", "")
                if (annotationId.length) {
                    // Change the note
                    props.annotationNoteChanged(annotationId, currentVal + ` ${emoji}`)
                }

            }

        }

        // var activeElement = document.activeElement;
        // var inputs = ['input', 'textarea'];

        // if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
        //     console.log("ACTIVE ELEMENT IS TEXTAREA")
        //     activeElement.value += activeElement.value + " " + emoji

        // } else {
        //     console.log("ACTIVE ELEMENT IS NOT TEXTAREA")
        // }

        // console.log("CONTENT OF ACTIVE ELEMENT ARE")
        // console.log(activeElement.textContent)


    }

    const toggleUploader = () => {

    }


    const updateComments = (crud, commentObj, isReply, isMainOfThread, mainComment) => {

        let currentComments = [...comments]

        if (isReply) {
            currentComments = [...replyComments]
        }

        if (crud === CRUDEvent.added) {
            //TODO: Users dpUrls and make them as object. or define this as byUserInfo
            commentObj.byUser = {
                dpUrl: "https://randomuser.me/api/portraits/men/44.jpg",
                email: "priyanka@testpost.app",
                id: "",
                workspace: ""
            }
            currentComments.unshift(commentObj)

            // if(isReply && mainComment){

            //     let mainComms = [...comments]
            //     let index = mainComms.findIndex((tc) => {
            //         return tc.id === mainComment.id
            //     })
            //     if (index){
            //         if (index >= 0){
            //             let mainCommCopy = {...mainComment}
            //             if (mainCommCopy.replies.length){
            //                 mainCommCopy.replies = [...mainCommCopy.replies, commentObj.id]
            //             }else{
            //                 mainCommCopy.replies = [commentObj.id]
            //             }

            //             mainComms[index] = mainCommCopy
            //             setComments((prev)=>{
            //                 return mainComms
            //             })
            //         }
            //     }
            //     // setMainCommentOfThread(mainComment)
            // }


        }

        if (crud === CRUDEvent.deleted) {
            currentComments = currentComments.filter((thisCm) => {
                return thisCm.id !== commentObj.id
            })

            if (isMainOfThread) {
                setReplyComments([])
                setMainCommentOfThread(null)
            }

            // if(isReply && mainComment){

            //     let mainComms = [...comments]
            //     let index = mainComms.findIndex((tc) => {
            //         return tc.id === mainComment.id
            //     })
            //     if (index){
            //         if (index >= 0){
            //             let mainCommCopy = {...mainComment}
            //             mainCommCopy.replies = mainCommCopy.replies.filter((replyIds) => {
            //                 return replyIds !== commentObj.id
            //             })
            //             mainComms[index] = mainCommCopy
            //             setComments((prev)=>{
            //                 return mainComms
            //             })
            //         }
            //     }
            // }

        }

        if (crud === CRUDEvent.updated) {
            let allCommnts = []
            currentComments.forEach((thisCm) => {
                if (thisCm.id === commentObj.id) {
                    allCommnts.push(commentObj)
                } else {
                    allCommnts.push(thisCm)
                }
            })
            currentComments = allCommnts

            // if(isReply && mainComment){
            //     setMainCommentOfThread(mainComment)
            // }
        }

        if (isReply) {
            setReplyComments((prev) => {
                return currentComments
            })
            return
        }


        setComments((prev) => {
            return currentComments
        })

    }


    const saveComment = () => {

        if (currentMessage) {

            if (existingComment) {
                // update
                console.log("Is existing comment")
                console.log(existingComment)

                // let nOb = new Comment()
                var existingCommentObj = Comment.copyFrom(existingComment)
                existingCommentObj.message = currentMessage
                // existingCommentObj.annotations = props.composeAnnotations
                existingCommentObj.assetThumbnailUrl = props.currentAsset.url
                existingCommentObj.assetVersion = props.currentAsset.version

                existingCommentObj.annotations = removeKeyFromObjs_InArray(props.composeAnnotations, "addedOn")
                existingCommentObj.assetVersion = props.currentAsset.version
                existingCommentObj.isEdited = true

                setIsSavingComment(true)
                existingCommentObj.update((succ, obj, errMsg) => {
                    setIsSavingComment(false)
                    if (succ) {
                        // alert("Comment is Added")
                        updateComments(CRUDEvent.updated, obj, mainCommentOfThread !== null)
                        toggleCompose(false, "Comment has been updated")
                        console.log("Comment updated to")
                        console.log(obj)
                    } else {
                        console.log(errMsg)
                        alert("comment could not be updated = " + errMsg)
                    }
                })



            } else {
                // add
                console.log("Is New comment")
                setIsSavingComment(true)
                let comment = new Comment()
                comment.message = currentMessage

                let cuInfo = currentUserInfo()
                comment.byUser = cuInfo.id
                comment.byUserInfo = cuInfo

                // comment.annotations = props.composeAnnotations
                comment.annotations = removeKeyFromObjs_InArray(props.composeAnnotations, "addedOn")


                console.log("THE CURRENT ASSET OBJECT IS ")
                console.log(props.currentAsset)

                comment.asset = props.currentAsset
                comment.assetId = props.currentAsset.id
                comment.assetType = props.currentAsset.type
                comment.assetThumbnailUrl = props.currentAsset.url
                comment.assetVersion = props.currentAsset.version
                comment.project = props.currentAsset.project
                comment.projectId = props.currentAsset.project.id
                comment.workspace = props.currentAsset.workspace



                if (mainCommentOfThread) {
                    comment.isReply = true
                    comment.replyToId = mainCommentOfThread.id
                    console.log("Reply to comment ByUserInfo")
                    console.log(mainCommentOfThread.byUserInfo)
                    comment.replyToUserInfo = mainCommentOfThread.byUserInfo
                }


                comment.add((succ, obj, errMsg) => {
                    setIsSavingComment(false)
                    if (succ) {
                        // alert("Comment is Added")
                        if (mainCommentOfThread) {
                            // Updated main comment of thread with this id
                            Comment.addUniqueReply(mainCommentOfThread.id, obj.id, (errMsg) => {
                                alert(errMsg)
                            })
                        }

                        updateComments(CRUDEvent.added, obj, mainCommentOfThread !== null, false, mainCommentOfThread)
                        toggleCompose(false)

                    } else {
                        console.log(errMsg)
                        alert("comment could not be added = " + errMsg)

                    }
                })
            }

        }
    }

    const editCommentPressed = (thisComment) => {

        setIsSavingComment(false)
        setExistingComment(thisComment)
        setCurrentMessage(thisComment.message)
        props.toggleComposeAnntActive(true, "Attribute Edit Comment Pressd")
        props.composeAnnotationsChanged(thisComment.annotations)
    }

    const commentThreadPressed = (thisComment) => {
        setMainCommentOfThread(thisComment)
    }

    const backFromThread = () => {
        setMainCommentOfThread(null)
    }



    const replyCommentPressed = (thisComment) => {
        alert("REPLY COMMENT PRESSED")

        // setIsSavingComment(false)
        // setExistingComment(thisComment)
        // setCurrentMessage(thisComment.message)
        // props.toggleComposeAnntActive(true, "Attribute Comment Reply Pressd")
        // props.composeAnnotationsChanged(thisComment.annotations)
    }

    const commentChanged = (thisComment) => {
        let updatedComment = Comment.copyFrom(thisComment)

        let index = -1
        if (updatedComment.isReply) {
            index = replyComments.findIndex((vt) => {
                return vt.id === thisComment.id
            })
        } else {
            index = comments.findIndex((vt) => {
                return vt.id === thisComment.id
            })
        }


        if (index >= 0) {
            let allComments = []

            if (updatedComment.isReply) {
                allComments = [...replyComments]
                allComments[index] = updatedComment
                setReplyComments(allComments)

            } else {
                allComments = [...comments]
                allComments[index] = updatedComment
                setComments(allComments)

                // Also this comment in Main in thread, update there too
                if (mainCommentOfThread) {
                    if (mainCommentOfThread.id === updatedComment.id) {
                        setMainCommentOfThread(updatedComment)
                    }
                }
            }
        }
    }

    const upvoteCommentPressed = (thisComment) => {
        let cu = isCurrentUser()
        let cuInfo = currentUserInfo()
        let currentUpvotes = [...thisComment.upvotes]
        let cm = Comment.copyFrom(thisComment)

        if (containsUpvoteByCU(thisComment.upvotes)) {
            // Remove upvote

            currentUpvotes = currentUpvotes.filter((vt) => vt.id !== cu.id)

            cm.removeUpvote(cuInfo, (errMsg) => {
                if (errMsg) {
                    console.log(errMsg);
                }
            })

        } else {
            // add upvote
            if (cuInfo) {
                currentUpvotes.push(cuInfo)
            } else {
                console.log("There is no current user info to as upvote")
            }

            cm.addUpvote(cuInfo, thisComment, (errMsg) => {
                if (errMsg) {
                    console.log(errMsg);
                }
            })
        }


        // var commentObj = Comment.copyFrom(thisComment)
        // commentObj.upvotes = currentUpvotes

        // commentObj.update((succ, obj, errMsg) => {
        //     setIsSavingComment(false)
        //     if (succ) {
        //         // change thisComment
        //         console.log("Comment upvotes changed")
        //         console.log(obj)
        //     } else {
        //         console.log(errMsg)
        //         console.log("comment upvotes could not be updated = " + errMsg)
        //     }
        // })


        // change this on ui first, let it update comment in background
        let updatedComment = { ...thisComment }

        updatedComment.upvotes = currentUpvotes

        let index = -1
        if (updatedComment.isReply) {
            index = replyComments.findIndex((vt) => {
                return vt.id === thisComment.id
            })
        } else {
            index = comments.findIndex((vt) => {
                return vt.id === thisComment.id
            })
        }


        if (index >= 0) {
            let allComments = []

            if (updatedComment.isReply) {
                allComments = [...replyComments]
                allComments[index] = updatedComment
                setReplyComments(allComments)

            } else {
                allComments = [...comments]
                allComments[index] = updatedComment
                setComments(allComments)

                // Also this comment in Main in thread, update there too
                if (mainCommentOfThread) {
                    if (mainCommentOfThread.id === updatedComment.id) {
                        setMainCommentOfThread(updatedComment)
                    }
                }
            }
        }
    }


    const deleteComment = (id, isReply) => {
        console.log("Deleting comment with id " + id)
        console.log("IsReply =  " + isReply)

        let cmArr = []
        if (isReply) {
            if (mainCommentOfThread.id !== id) {
                cmArr = [...replyComments]
                console.log("cmArr is REPLY COMMENTS ")
                console.log(cmArr)
            }
        } else {
            cmArr = [...comments]

            console.log("cmArr is COMMENTS ")
            console.log(cmArr)
        }

        let thisCmtArr = cmArr.filter((thisCm) => {
            return thisCm.id === id
        })

        console.log("thisCmtArr is ")
        console.log(thisCmtArr)


        if (thisCmtArr.length) {
            let thiscm = thisCmtArr[0]

            console.log("Deleting comment ")
            console.log(thiscm)

            if (isReply && mainCommentOfThread) {
                // delete its entry from the mainComment.replies array first and then this one
                Comment.removeReply(mainCommentOfThread.id, thiscm.id, (errMsg) => {
                    alert(errMsg)
                })

            }


            let isMainOfThread = false
            if (mainCommentOfThread) {
                if (mainCommentOfThread.id === id) {
                    isMainOfThread = true
                }
            }

            if (isMainOfThread) {
                // if it has replies.. delete all its replies 
                let replyIds = mainCommentOfThread.replies
                Comment.deleteAll([...replyIds])
            }


            Comment.delete(thiscm.id, (succ, errMsg) => {
                if (succ === true) {
                    updateComments(CRUDEvent.deleted, thiscm, isReply, isMainOfThread)
                } else {
                    console.log("comment could not be deleted")
                }
                console.log("Error Message = " + errMsg)
            })





        }
    }


    // props.newTags , props.asset
    let commentInputBox = () => {
        // console.log("Incominf Props Annotations are ")
        // console.log(props.composeAnnotations)

        let annts = props.composeAnnotations
        // if (existingComment){
        //     annts = existingComment.annotations
        // }



        return <Box radiusless shadowless className="radiusless shadowless animate-bottom has-background-transparent overflow-hidden is-fullHeight p-0  " >
            {/* return <Box className="  p-0 mt-2 has-background-white-bis" radiusless> */}

            <div className="comment-compose-row-grid-container h-90vh overflow-hidden">

                {/* <input className="is-size-6 textView field is-borderless has-background-transparent is-fullWidth" type="text" placeholder="Add Comment" /> */}
                {/* <Heading size={6} textColor="grey" className=" ml-4 mt-4 mb-1" > Compose Comment </Heading> */}
                <Box radiusless className=" has-background-white-bis  m-0 p-0 pt-4 " >
                    <Form.Field className="m-0 p-0" radiusless>
                        <Form.Control className=" ">
                            <Form.Textarea
                                id={composeTextMessageAreaId}
                                value={currentMessage ?? ""}
                                onChange={(e) => { messageChanged(e) }}
                                onFocus={() => { setLastFocusedTextareaId("composeMessageTextarea") }}
                                type="text"
                                placeholder={mainCommentOfThread ? "Write reply here..." : "Write comment here..."}
                                className="m-0 z-35 pt-4 pl-4 has-background-transparent is-shadowless is-borderless is-radiusless "
                                fixedSize
                                disabled={isSavingComment}
                                rows={6}
                            />
                        </Form.Control>
                    </Form.Field>
                    <Box radiusless shadowless className=" has-background-transparent ">
                        <Columns mobile className="" gap={0} >
                            <Columns.Column size={2} >
                                <Button size="small" align="right" className=" has-text-grey is-text mt-3 " textWeight="medium" onClick={() => { toggleCompose(false, "Cancel compose/update comment pressed") }} >   CANCEL </Button>
                            </Columns.Column>
                            <Columns.Column className=" has-text-right ">
                                <Button.Group mobile narrow align="right" className=" is-clearfix " >


                                    <Button size="small" className=" has-background-transparent is-borderless is-hover-dark mr-4 " data-tip={"emojiPicker"} >

                                        <span className=" is-size-5 opacity50 pt-3 pb-1"><GrEmoji /></span>
                                        <ReactTooltip
                                            getContent={(dataTip) =>
                                                <Box shadowless className="w-240  is-size-5 has-background-transparent text-break-warp-all p-0 m-0 z-1000">
                                                    {
                                                        emojis().map((thisEmoji) => {
                                                            return <span key={randomCode(4)} className=" " onClick={() => { addEmojiToComment(thisEmoji) }}>{thisEmoji} &nbsp;</span>
                                                        })
                                                    }
                                                </Box>
                                            }
                                            offset={{ top: 10 }}
                                            effect='solid'
                                            delayHide={50}
                                            delayShow={50}
                                            delayUpdate={50}
                                            place={'bottom'}
                                            border={false}
                                            type={'light'}
                                            backgroundColor={"white"}
                                            clickable={true}
                                            className="border-radius-9"

                                        />


                                    </Button>




                                    {/* <Button size="small" className=" has-background-transparent is-borderless is-hover-dark  " onClick={toggleUploader}> <span className=" is-size-5 opacity50 pt-3 pb-1 "><AiOutlineFileAdd /></span>  </Button> */}
                                    <Button disabled={isSavingComment} loading={isSavingComment} className=" is-aligned-right is-black  " textWeight="semibold" onClick={saveComment} > Save </Button>

                                    {/* <Button color={props.composeAnntActive ? "primary" : "white"} className={"   "} onClick={() => { props.toggleComposeAnntActive() }}>
                                        {
                                            props.composeAnntActive ?
                                                <span className="is-size-8">
                                                    <HiAnnotation className="is-size-4 fullwidth" /> ON
                                                </span>
                                                :
                                                <span className="is-size-8">
                                                    <HiOutlineAnnotation className="is-size-4 fullwidth" /> OFF
                                                </span>
                                        }
                                    </Button> */}
                                </Button.Group>
                            </Columns.Column>
                        </Columns>


                    </Box>
                </Box>

                <Box shadowless radiusless className=" m-0 pt-0 has-background-transparent overflow-scroll " >
                    <Box shadowless radiusless className=" has-background-transparent m-0 p-0">
                        <Heading size={7} spaced={false} textWeight="" textColor="grey-lighter" className=" mt-5 ml-4 is-uppercase " > Attachments </Heading>
                        {annts.length ? null : <p className=" is-size-7 has-text-grey  mt-0 ">  <FaRegCircle className="pt-1  hover-pulse-slow-infinite " />  Tap anywhere on image to point note</p>}
                    </Box>
                    {
                        annts.length ?
                            <div className="mt-3 mb-2 ">

                                <div className=" notes-container pt-2 has-background-transparent "  >
                                    {[...annts].map((thisAnn) => {

                                        if (thisAnn.isHovered && thisAnn.isHoveredFrom === AnnotationHoveredFrom.asset ) {
                                            setTimeout(() => {
                                                let thisNt = document.getElementById("annotation-note-" + thisAnn.id)
                                                if (thisNt) {
                                                    thisNt.scrollIntoView({
                                                        behavior: 'smooth',
                                                        block: 'start',
                                                        // inline: 'center'
                                                    });
                                                }

                                                if (!isMobile) {
                                                    let thiNtTxt = document.getElementById("annotation-note-textarea-" + thisAnn.id)
                                                    if (thiNtTxt) {
                                                        if (document.activeElement.id === thiNtTxt.id) {
                                                        } else {
                                                            thiNtTxt.focus()
                                                        }
                                                    }
                                                }


                                            }, 100)
                                        }

                                        return <Box shadowless id={"annotation-note-" + thisAnn.id} style={{ backgroundColor: thisAnn.color ?? thisAnn.colorHex }} className={thisAnn.isHovered ? "p-0 px-1 pb-1 maxH100 boxLast0 hover-pulse-small  notes-item" : "p-0 px-1 pb-1 maxH100 boxLast0 notes-item"} onMouseEnter={() => { props.annotationHovered(true, thisAnn.id, "note", AnnotationShowType.composing) }} onMouseLeave={() => { props.annotationHovered(false, thisAnn.id, "note", AnnotationShowType.composing) }}  >

                                            {/* {
                                            props.annotationEditable ?
                                                <Button size="small" rounded className="mt-minus-10 m-0  is-pulled-right is-hover-dark  is-borderless p-0 px-2" onClick={() => { props.annotationRemove(thisAnn.id) }} >
                                                    <IoTrashBinOutline className="is-size-6 p-0 m-0" />
                                                </Button>
                                                : null
                                        } */}
                                            <Columns className=" is-mobile" gap={0}>
                                                <Columns.Column>
                                                    <Form.Field className="mx-0 mb-0 mt-minus-10">
                                                        <Form.Control className="m-0">
                                                            <Form.Textarea
                                                                id={"annotation-note-textarea-" + thisAnn.id}
                                                                shadowless
                                                                readOnly={false}
                                                                fixedSize
                                                                placeholder="Add note here... "
                                                                textWeight="semibold"
                                                                textSize={thisAnn.note ? (thisAnn.note.length ? ((thisAnn.note.length <= 3 && hasEmoji(thisAnn.note)) ? 4 : 7) : 7) : 7}
                                                                textColor="dark"
                                                                maxLength={90}
                                                                rows={4}
                                                                value={thisAnn.note ?? ""}
                                                                className="m-0 mt-4 h-80  has-background-transparent is-borderless  "
                                                                onChange={(e) => { props.annotationNoteChanged(thisAnn.id, e.target.value) }}
                                                                onFocus={() => { setLastFocusedTextareaId("annotation-note-textarea-" + thisAnn.id) }}
                                                            />
                                                        </Form.Control>
                                                    </Form.Field>
                                                </Columns.Column>
                                                <Columns.Column narrow>
                                                    <Button size="small" rounded className="mt-minus-10 m-0 p-0 mr-1 mt-1  has-background-transparent has-text-black is-pulled-right   is-borderless " onClick={() => { props.annotationRemove(thisAnn.id) }}>
                                                        <IoClose className="is-size-6 m-0 p-0 opacity50" />
                                                    </Button>
                                                </Columns.Column>
                                            </Columns>
                                        </Box>
                                        // return <Tag.Group key={thisAnn.id} className={thisAnn.isHovered ? "mx-1 my-1 hover-pulse" : "mx-1 my-1"} hasAddons={true} onMouseEnter={() => { props.annotationHovered(true, thisAnn.id) }} onMouseLeave={() => { props.annotationHovered(false, thisAnn.id) }} >
                                        //     <Tag color="dark" style={{ color: thisAnn.color }} className=" px-3 py-0 " > <span className="is-size-8 has-text-weight-semibold"> {thisAnn.id}</span></Tag>
                                        //     <Tag className="opacity50 py-0" remove rounded onClick={() => { props.annotationRemove(thisAnn.id) }} />
                                        // </Tag.Group>
                                    })}
                                    <AlwaysScrollToLast depVal={annts.length} />
                                </div>

                            </div>
                            :
                            null
                    }
                    <br />
                    <br />
                    <br />
                    <br />
                </Box>
            </div>
        </Box >
    }


    let commentTable = () => {

        let arr = mainCommentOfThread ? replyComments : comments
        let noCommentStr = "No Comments"
        let addMessage = <p className="is-size-7 has-text-weight-semibold  ml-0 mt-2 p-0"> You can annotate changes and write about them. <p>Click 'Add Comment' to begin.</p> </p>

        if (mainCommentOfThread) {
            noCommentStr = "No Replies"
        } else {
            // means it is comments and not reply comments 
            arr = filteredComments(currentCommentTab === CommentTABS.resolved)

            if (arr.length === 0) {
                if (currentCommentTab === CommentTABS.resolved) {
                    noCommentStr = "No Resolved Comments"
                    addMessage = <p className="is-size-7 has-text-weight-semibold  ml-0 mt-2 p-0"> To mark a comment resolved. In the comment, go inside ••• and choose - Mark Resolved  </p>
                }
            }

        }

        let tabClasses = " is-borderless is-fullwidth "
        if (currentCommentTab === CommentTABS.unresolved){
            tabClasses += "has-background-light"
        }



        return <div className="is-clearfix is-fullwidth  " >

            {
                comments.length ?
                    <div className={tabClasses} >
                        {
                            mainCommentOfThread ? null :
                                <Tabs type="toggle-rounded" align='center' size="small" textWeight="medium" className=" greyTab p-2 is-clearfix m-0 has-background-transparent  "  >
                                    <Tabs.Tab className=" " active={currentCommentTab === CommentTABS.unresolved} onClick={() => { changeCurrent_CommentTab(CommentTABS.unresolved) }}><span className=" px-1  ">{CommentTABS.unresolved}</span>  </Tabs.Tab>
                                    <Tabs.Tab className="" active={currentCommentTab === CommentTABS.resolved}  onClick={() => { changeCurrent_CommentTab(CommentTABS.resolved) }}>  <span className=" px-1  ">{CommentTABS.resolved}</span>   </Tabs.Tab>
                                </Tabs>
                        }
                    </div>
                    :
                    null
            }



            <div style={{ width: "1px", height: "1px", margin: "auto" }} ref={ref}></div>

            <LoadingAux className=" is-fullHeight is-fullwidth m-0 p-0 mt-2 ml-2 " isLoading={isFetching} isMain={true} isCentered={true} loaderHeight={600}>
                <Box radiusless className="h-90vh is-fullwidth has-background-transparent p-0 pb-6 pt-0 " shadowless>
                    <Table  hoverable id="commentTable" className="overflow-scroll is-fullwidth has-background-transparent no-border p-0 pb-6  is-fullHeight is-flex is-flex-grow-4" >

                        <tbody className=" pb-6 is-fullwidth minW100p ">
                            {mainCommentOfThread ?  
                                <CommentRow key={mainCommentOfThread.id} isThreadMain={true} backFromThread={backFromThread} editPressed={editCommentPressed} commentChanged={commentChanged} upvotePressed={upvoteCommentPressed} isFirst={false} isLast={false} comment={mainCommentOfThread} deleteComment={deleteComment} displayingAnnotaions={props.commentAnnotations} isReply={false} annotationHovered={props.annotationHovered} asset={props.currentAsset} />
                                : null
                            }
                            {
                                arr.length ?

                                    arr.map((thisCom, index) => {
                                        let isLast = (index === (arr.length - 1))
                                        let isFirst = (index === 0)

                                        return <CommentRow key={thisCom.id} editPressed={editCommentPressed} commentChanged={commentChanged} upvotePressed={upvoteCommentPressed} commentThreadPressed={commentThreadPressed} composeReplyPressed={replyCommentPressed} isFirst={isFirst} isLast={isLast} comment={thisCom} deleteComment={deleteComment} displayingAnnotaions={props.commentAnnotations} isReply={thisCom.isReply} annotationHovered={props.annotationHovered} asset={props.currentAsset} />
                                    })

                                    :
                                    <tr> <td><Box shadowless radiusless className="is-fullwidth has-text-grey-light has-background-transparent is-size-7 has-text-left"> {noCommentStr} {addMessage} </Box></td> </tr>

                            }
                        </tbody>

                    </Table>
                    <br />
                </Box>
            </LoadingAux>
        </div>
    }

    // let commentThreadTable = (mainComment) => {
    //     return <div className="is-clearfix is-fullwidth ">
    //         <Box radiusless className="h-90vh is-fullwidth has-background-transparent p-0 pb-6 pt-0 " shadowless>
    //             <Table className="overflow-scroll is-fullwidth has-background-transparent no-border p-0 pb-6  is-fullHeight is-flex is-flex-grow-4" >
    //                 {/* // create header of main comment and apply shadow also. */}
    //                 <thead>
    //                     <CommentRow isThreadMain={true} editPressed={editCommentPressed} upvotePressed={upvoteCommentPressed} isFirst={false} isLast={false} comment={mainComment} deleteComment={deleteComment} displayingAnnotaions={props.commentAnnotations} isReply={false} annotationHovered={props.annotationHovered} />
    //                 </thead>
    //                 <tbody className=" pb-6 is-fullwidth minW100p ">
    //                     <LoadingAux className=" is-fullHeight is-fullwidth m-0 p-0 mt-2 ml-2 " isLoading={isFetching} isMain={true} isCentered={true} loaderHeight={600}>
    //                         {replyComments.map((thisCom, index) => {
    //                             let isLast = (index === (comments.length - 1))
    //                             let isFirst = (index === 0)

    //                             return <CommentRow key={thisCom.id} editPressed={editCommentPressed} upvotePressed={upvoteCommentPressed} replyPressed={replyCommentPressed} isFirst={isFirst} isLast={isLast} comment={thisCom} deleteComment={deleteComment} displayingAnnotaions={props.commentAnnotations} isReply={thisCom.isReply} annotationHovered={props.annotationHovered} />
    //                         })}
    //                     </LoadingAux>
    //                 </tbody>
    //             </Table>
    //             <br />
    //         </Box>
    //     </div>
    // }




    let commentContent = () => {

        // if (mainCommentOfThread) {
        //     setIsFetching(true)
        //     setTimeout(() => {
        //         setIsFetching(false)
        //     }, 2000)
        //     return commentThreadTable(mainCommentOfThread)
        // }

        if (props.composeAnntActive || existingComment) {
            return commentInputBox()
        }

        return commentTable()
    }


    let assetMembers = () => {
        let users = []
        if (props.project) {
            if (props.project.membersInfo) {
                users = props.project.membersInfo
            }
        }


        let appBy = props.currentAsset.approvedBy ?? []
        let rejBy = props.currentAsset.rejectedBy ?? []
        let approvedByInfo = props.currentAsset.approvedByInfo ?? []
        let rejectedByInfo = props.currentAsset.rejectedByInfo ?? []

        let remainingByInfo = users.filter((m) => {
            return !(appBy.includes(m.id) || rejBy.includes(m.id))
        })


        // let approvedBy = users.filter((u)=> {
        //     return u.
        // })

        // return <Table className="minW240 " hoverable={false} striped={false} bordered={false} fullwidth>


        return <div className=' is-clearfix is-fullwidth '>

            <Box shadowless className=" h-90vh is-fullwidth overflow-scroll has-background-transparent p-0 pb-6 pt-0 ">

                {approvedByInfo.length ? <div>
                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey is-uppercase py-2 mt-2 mb-0" >Approved</Box>
                    <Table className="is-fullwidth has-background-transparent no-border" hoverable={false} striped={false} bordered={false} fullwidth>

                        <tbody>
                            {
                                approvedByInfo.map((usr) => {
                                    return <tr key={usr.id}>
                                        <td className="is-borderless" align="left">
                                            <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} />
                                        </td>
                                        <td className=" is-borderless py-4" align="center">
                                            <ImCheckmark className=" " />
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div> : null}

                {rejectedByInfo.length ? <div>
                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey is-uppercase py-2 my-0" >Rejected</Box>
                    <Table className=" is-fullwidth has-background-transparent" hoverable={false} striped={false} bordered={false} fullwidth>
                        <tbody>
                            {
                                rejectedByInfo.map((usr) => {
                                    return <tr key={usr.id}>
                                        <td className="is-borderless" align="left">
                                            <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} />
                                        </td>
                                        <td className=" is-borderless py-4" align="center">
                                            <ImCross className=" is-size-7 mr-1 " />
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div> : null}


                {remainingByInfo.length ? <div>
                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey is-uppercase py-2 my-0" >Pending</Box>
                    <Table className=" is-fullwidth has-background-transparent" hoverable={false} striped={false} bordered={false} fullwidth>

                        <tbody>
                            {
                                remainingByInfo.map((usr) => {
                                    return <tr key={usr.id}>
                                        <td className="is-borderless" align="left">
                                            <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} />
                                        </td>
                                        <td className=" is-borderless py-4" align="center">
                                            <GiSandsOfTime className="mt-2" />
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                    : null}

            </Box>
        </div>



    }

    let assetInfo = () => {

        let asst = props.currentAsset
        let infoObj = {
            "Name": asst.name,
            "Type": asst.type,
            "Version": asst.version,
            "By": asst.byUserInfo.name,
            "Finalised": asst.isFinalised ? "Yes" : "No",
            "Size": asst.sizeInBytes ? `${asst.sizeInBytes}` : "-",
            "Dimension": asst.dimensionInPx ? `${asst.dimensionInPx}` : "-",
            "Aspect Ratio": asst.aspectRatio ? `${asst.aspectRatio}` : "-",
            "Compressed": "No"
        }

        // name 
        // image type
        // by
        // isFinalised
        // size 
        // compressed Versions   

        return <div className=' is-clearfix is-fullwidth '>
            <Box shadowless className=" h-90vh is-fullwidth overflow-scroll has-background-transparent p-0 pb-6 pt-0 ">
                <Table className=" has-background-transparent is-fullwidth is-size-7 has-text-weight-medium" hoverable={false} striped={false} bordered={false} fullwidth>
                    <tbody>
                        {
                            Object.keys(infoObj).map((key, index) => {
                                let infName = key
                                let infVal = infoObj[key]
                                return <tr key={infName} className="py-5">
                                    <td className=" has-text-grey-light " align="left">
                                        {infName}
                                    </td>
                                    <td className=" has-text-grey " align="right">
                                        {infVal}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </Box>
        </div>

    }


    let dummyTable = () => {

        let pendingTasks = [
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
        ]

        let workingTasks = [
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
        ]

        let completeTasks = [
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
            { title: "Sed haec quis possit intrepidus aestimare tellus. Cras mattis iudicium purus sit amet fermentum. Ut enim ad minim veniam, quis nostrud exercitation. Ab illo tempore, ab est sed immemorabili." },
        ]


        // <div className="is-clearfix is-fullwidth ">
        // <LoadingAux className=" is-fullHeight is-fullwidth m-0 p-0 mt-2 ml-2 " isLoading={isFetching} isMain={true} isCentered={true} loaderHeight={600}></LoadingAux>
        // <Box radiusless className="h-90vh is-fullwidth has-background-transparent p-0 pb-6 pt-0 " shadowless>
        // <Table hoverable id="commentTable" className="overflow-scroll is-fullwidth has-background-transparent no-border p-0 pb-6  is-fullHeight is-flex is-flex-grow-4" >




        return <div className=' is-clearfix is-fullwidth '>

            <LoadingAux className=" is-fullHeight  is-fullwidth m-0 p-0 mt-2 ml-2 " isLoading={isFetching} isMain={true} isCentered={true} loaderHeight={600}>


                <Box shadowless className=" h-90vh is-fullwidth overflow-scroll has-background-transparent p-0 pb-6 pt-0 ">
                    <div className='overflow-scroll  is-size-7'>




                        {pendingTasks.length ? <div>
                            <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey-light py-2 mt-2 mb-0" >Pending</Box>
                            <Table className="overflow-scroll is-fullwidth is-fullHeight  overflow-scroll has-background-transparent no-border" hoverable={false} striped={false} bordered={false} fullwidth>
                                <tbody>
                                    {
                                        pendingTasks.map((tk) => {
                                            return <tr key={tk.id}>
                                                <td className="is-borderless" align="left">
                                                    {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                    <p className='has-text-black'> {tk.title}</p>
                                                </td>
                                                <td className=" py-4" align="center">
                                                    <GiSandsOfTime className="mt-2" />
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div> : null}

                        {workingTasks.length ? <div>
                            <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey-light py-2 my-0" >Working</Box>
                            <Table className=" overflow-scroll is-fullwidth is-fullHeight has-background-transparent" hoverable={false} striped={false} bordered={false} fullwidth>
                                <tbody>
                                    {
                                        workingTasks.map((tk) => {
                                            return <tr key={tk.id}>
                                                <td className="is-borderless" align="left">
                                                    {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                    {tk.title}
                                                </td>
                                                <td className=" py-4" align="center">
                                                    <IoMdClose className=" has-text-danger" />
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div> : null}

                        {completeTasks.length ? <div>
                            <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey-light py-2 my-0" >Complete</Box>
                            <Table className=" overflow-scroll is-fullwidth is-fullHeight has-background-transparent" hoverable={false} striped={false} bordered={false} fullwidth>
                                <tbody>
                                    {
                                        completeTasks.map((tk) => {
                                            return <tr key={tk.id}>
                                                <td className="is-borderless" align="left">
                                                    {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                    {tk.title}
                                                </td>
                                                <td className=" has-text-grey-light py-4" align="center">
                                                    <ImCheckmark className=" " />
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div> : null}

                    </div>
                </Box>
            </LoadingAux>
        </div >

    }



    let content = () => {
        switch (currentTab) {
            case MainTABS.comments: return commentContent();
            // case MainTABS.tasks: return <AssetTasks assetId={props.currentAsset.id} />;
            case MainTABS.tasks: return <AssetTasks asset={props.currentAsset} assetId={props.currentAsset.id} />;
            case MainTABS.events: return <AssetEvents asset={props.currentAsset} assetId={props.currentAsset.id} />;
            case MainTABS.members: return assetMembers();
            default: return assetInfo()
        }

    }


    return <div className=" is-fullwidth ">
        <div className=" is-fullHeight animate-right-to-left is-fullwidth overflow-hidden ">
            <Box radiusless shadowless className={"has-background-black m-0 is-hidden-desktop " + (props.composeAnntActive ? " is-hidden " : null)}>
                <Button className=" is-fullwidth" onClick={() => { if (currentTab !== MainTABS.comments) { changeCurrentTab(MainTABS.comments) }; props.toggleComposeAnntActive(); }} > Add Comment </Button>
            </Box>
            {/* //NEED FROM PROPS -existingComment, currentAsset, annotations, annotationRemoved, clearAnnotations */}

            <Box shadowless radiusless className="m-0  no-border p-0 h-100vh has-background-transparent">
                <Panel className=" p-0 no-border m-0 " radiusless shadowless>
                    <div className=" m-0" >
                        <Tabs size="small" backgroundColor="light" textWeight="semibold" className=" pt-1 is-clearfix m-0 "  >
                            <Tabs.Tab className=" " active={currentTab === MainTABS.comments} onClick={() => { changeCurrentTab(MainTABS.comments) }}><span className="px-0 ">{MainTABS.comments}</span>  </Tabs.Tab>
                            <Tabs.Tab className=" " active={currentTab === MainTABS.tasks} onClick={() => { changeCurrentTab(MainTABS.tasks) }}><span className=" px-1 ">{MainTABS.tasks}</span>  </Tabs.Tab>
                            <Tabs.Tab className=" " active={currentTab === MainTABS.events} onClick={() => { changeCurrentTab(MainTABS.events) }}><span className=" px-1 ">{MainTABS.events}</span>  </Tabs.Tab>
                            <Tabs.Tab className=" " active={currentTab === MainTABS.members} onClick={() => { changeCurrentTab(MainTABS.members) }}>  <span className=" px-0 ">{MainTABS.members}</span>   </Tabs.Tab>
                            <Tabs.Tab className=" " active={currentTab === MainTABS.info} onClick={() => { changeCurrentTab(MainTABS.info) }}>        <span className="px-0">{MainTABS.info}</span>      </Tabs.Tab>
                        </Tabs>
                        {/* <Panel.Header className=" m-0 has-background-light" radiusless>
                                    Comments
                                    <Tabs type="toggle" size="small" textWeight="medium" className=" mt-2"  >
                                        <Tabs.Tab active>Current</Tabs.Tab>
                                        <Tabs.Tab>Passed</Tabs.Tab>
                                    </Tabs>
                                </Panel.Header > */}
                    </div>

                    {content()}
                </Panel>
            </Box>
        </div>
        {
            currentTab === MainTABS.comments ?
                <Box radiusless className={" has-background-grey-lightest is-bordered has-border-thick-onhover is-fullwidth  is-centered m-0 p-1   stick-to-bottom-0   " + (props.composeAnntActive ? " is-hidden " : " is-hidden-touch ")} onClick={() => { if (currentTab !== MainTABS.comments) { changeCurrentTab(MainTABS.comments) }; props.toggleComposeAnntActive() }} >
                    {/* <Button size="medium"  className=" has-background-transparent is-fullwidth " onClick={() => { if (currentTab !== MainTABS.comments) { changeCurrentTab(MainTABS.comments) }; props.toggleComposeAnntActive() }} > */}
                    <Columns className='m-0 p-0  ' mobile={true}>
                        <Columns.Column size={2} narrow>
                            <FaComment className="mt-1 opacity50 is-size-6  " />
                        </Columns.Column>
                        <Columns.Column className=' px-0'>
                            <span className="is-size-6 has-text-grey has-text-weight-medium"> {mainCommentOfThread ? "Add Reply..." : "Add Comment..."}</span>
                        </Columns.Column>
                    </Columns>
                    {/* </Button> */}
                </Box>
                : null
        }



    </div>

}

export default AssetAttributeBox