import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Modal, Panel, Columns, Button, Container, Table, Box, Form, Tabs, Heading, Image } from 'react-bulma-components';
import { FaLeaf } from 'react-icons/fa';
import ColumnAux from '../../Containers/ColumnAux';
import { Asset } from '../../Models/Asset';
import { allAssetCaptionTypes, AssetCaptionType, entityInfo_ForNotif, EntityType, NotificationFor } from '../../Models/EnumsAndPointers';
import { Project } from '../../Models/Project';



const AssetCaptionEditorModal = (props) => {

    let [captionType, setCaptionType] = useState(props.asset.captionType ?? AssetCaptionType.caption)
    let [caption, setCaption] = useState(props.asset.caption ?? "")
    let [saving, setSaving] = useState(false)


    let captionTypeChanged = (val) => {
        console.log("Changing caption type to = ");
        console.log(val);
        setCaptionType(val)
    }


    let captionChanged = (val) => {
        setCaption(val)
    }


    let saveAsset = () => {

        if (props.asset.captionType === captionType && props.asset.caption === caption) {
            props.close()
            return
        }

        if (saving) {
            return
        }

        let assetCopy = Asset.copyFrom(props.asset)
        assetCopy.caption = caption
        assetCopy.captionType = captionType

        setSaving(true)
        assetCopy.update((succ, result, errMsg) => {
            setSaving(false)
            if (succ) {

                let entityInfoObj = entityInfo_ForNotif(EntityType.asset, result, [], null)
                Project.getProjectDetails_AndNotify(result.project.id, NotificationFor.asset_updatedDescTitleContent, entityInfoObj)

                if (props.assetChanged) {
                    props.assetChanged(result)
                    props.close()
                }
            } else {
                console.log(errMsg);
                alert("Could not save caption change")
            }
        })

    }




    const modal = () => {

        // let sampleCaption = "Cum sociis natoque penatibus et magnis dis parturient. Unam incolunt Belgae, aliam Aquitani, tertiam. Ullamco laboris nisi ut aliquid ex ea commodi consequat. Ambitioni dedisse scripsisse iudicaretur."



        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className="" radiusless>
                    <Modal.Card.Header showClose className="  has-background-grey-lightest is-borderless pb-0 ">
                        <Modal.Card.Title className="has-text-grey-light is-size-7"> {props.asset.name ?? ""} </Modal.Card.Title>
                    </Modal.Card.Header>

                    <Modal.Card.Body className="has-background-grey-lightest pb-0 pt-2">
                        <ColumnAux size={12} isMiddle={true}>
                            <Box shadowless className="has-background-transparent mt-0 pt-0 mb-0" >
                                <Form.Field>
                                    {/* <Form.Label size="small">Select type</Form.Label> */}
                                    <Form.Control>
                                        <Form.Select size="small"
                                            disabled={saving}
                                            value={captionType}
                                            onChange={(e) => { captionTypeChanged(e.target.value) }}
                                        >
                                            {
                                                allAssetCaptionTypes.map((t) => {
                                                    return <option value={t}>{t}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Control>
                                </Form.Field>


                                <Form.Field className="">
                                    {/* <Form.Label size="small">Content</Form.Label> */}
                                    <Form.Control>
                                        <Form.Textarea
                                            disabled={saving}
                                            className="h-300px "
                                            size="normal"
                                            color="text"
                                            // rows={3}
                                            fixedSize={false}
                                            placeholder="Add text here..."
                                            value={caption}
                                            onChange={(e) => { captionChanged(e.target.value) }}
                                        // onClick={togglePostCaptionEditor}
                                        />
                                    </Form.Control>
                                </Form.Field>

                            </Box>
                        </ColumnAux>
                        {/* <Button size="normal" color="text" className="is-pulled-left is-text ml-4 noDecoration " onClick={props.close}> Cancel </Button>
                        <Button size="normal" color="black" className="is-pulled-right mx-4 px-5" onClick={close}> Save </Button> */}
                    </Modal.Card.Body>
                    <Modal.Card.Footer className="pt-0 pb-6 has-text-right has-background-grey-lightest is-borderless " >
                        <Button fullwidth disabled={saving} loading={saving} size="normal" color="black" className="mx-5" onClick={saveAsset}> Save </Button>
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }



    return modal()

}

export default AssetCaptionEditorModal