import React from "react";
import Lottie from "lottie-react";
import Lottie_star from '../../assets/Lottie_star.json';
import Lottie_check_green from '../../assets/Lottie_check_green.json';
import Lottie_check_black from '../../assets/Lottie_check_black.json';
import Lottie_cross_black from '../../assets/Lottie_cross_black.json';
import { AnimationType } from "../../Models/EnumsAndPointers";
import { Heading } from "react-bulma-components";



const LottieView = (props) => {

    // const star_options = {
    //     animationData: Lottie_star,
    //     loop: false,
    // };


    const animation = () => {
        let ani = Lottie_check_black
        let name = "Approved by me"
        let changeScaleClass = ""

        if (props.type === AnimationType.assetRejected) {
            ani = Lottie_cross_black
            // changeScaleClass = "scaleDown-p75"
            name = "Rejected by me"
        }

        if (props.type === AnimationType.assetFinalised) {
            ani = Lottie_check_green
            name = "Finalised"
        }

        return {"animation": ani, "changeScaleClass": changeScaleClass, "name": name } 
    }

    const size = () => {
        let s = "square200"
        if (props.size === "large"){
            s = "square400"
        }
        return s
    }


    const lv = () => {
        let animate = animation()
        return < div className='overlay-div  has-text-centered is-flex is-flex-direction-column' >
            <div className={animate.changeScaleClass + " h80p "}>
                <Lottie className={size()}  animationData={animate.animation} loop={false} />
            </div>
            <Heading size={5} textAlign="center" className="">{animate.name}</Heading>
        </div >


    }

    return lv()

}

export default LottieView