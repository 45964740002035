import React, { useRef, useState } from 'react'
import { Form, Card, Heading, Media, Content, Tabs, Box, Image, Tag, Button, Dropdown, Modal, Panel, Columns, Hero, Container, Message, Level, Section, Navbar } from 'react-bulma-components';
import { previewBoxType, SocialMedia_PostType, AssetCaptionType, SocialMedia_Platform } from '../../Models/EnumsAndPointers';
import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
// import sample_Li_Company_Bg from './sampleAssets/li-companyPage-bg.jpeg'
import Default_BG from './sampleAssets/Default-BG.png'
import Default_DP from './sampleAssets/Default-DP.png'


import instaLove from './sampleAssets/insta-love.png'
import instaSave from './sampleAssets/insta-save.png'
import instaShare from './sampleAssets/insta-share.png'
import instaComment from './sampleAssets/insta-comment.png'
import instaAddCommentFace from './sampleAssets/insta-addCommentFace.png'
import instaDotMenu from './sampleAssets/insta-dotMenu.png'
import instaLogoDropdown from './sampleAssets/insta-logo-dropdown.png'
import instaMenu from './sampleAssets/insta-menu-icon.png'

// import instaTabTagged from './sampleAssets/insta_tab_tagged.png'
// import instaTabVideos from './sampleAssets/insta_tab_videos.png'
// import instaTabReels from './sampleAssets/insta_tab_reels.png'
// import intsaTabPosts from './sampleAssets/intsa_tab_posts.png'


import instaStoryDotMenu from './sampleAssets/insta-story-dotMenu.png'
import instaStoryClose from './sampleAssets/insta-story-close.png'
import instaStoryShare from './sampleAssets/insta-story-share.png'
//Insta Nav Icons
import insta_add_icon from './sampleAssets/insta_add_icon.png'
import insta_explore_icon from './sampleAssets/insta_explore_icon.png'
import insta_home_icon_fill from './sampleAssets/insta_home_icon_fill.png'
import insta_home_icon from './sampleAssets/insta_home_icon.png'
import insta_liked_icon from './sampleAssets/insta_liked_icon.png'
import insta_logo_black from './sampleAssets/insta_logo_black.png'
import insta_logo_icon from './sampleAssets/insta_logo_icon.png'
import insta_messages_icon from './sampleAssets/insta_messages_icon.png'
import insta_search_icon from './sampleAssets/insta_search_icon.png'




import { randomUsers } from './VisualiseModal';
import { FaSearch } from 'react-icons/fa';



export const instaNav_mobile = () => {
    return <Box shadowless radiusless className='m-0 p-0 pt-4  stick-to-top z-55' >
        <div onClick={(e) => { e.stopPropagation(); }}>

            <Columns className='m-0 p-0' vCentered>
                <Columns.Column className='m-0 p-0 '>
                    <img
                        alt="insta logo"
                        // size={128}
                        src={insta_logo_black}
                        className="is-clipped has-bacground-transparent instaLogo-103w mt-0 mb-0 ml-2"
                    />


                </Columns.Column>
                <Columns.Column className='m-0 p-0' size={4}>
                    {/* <Level className="is-mobile has-text-centered " >
                                <Level.Item className='m-0 p-0'><img src={insta_home_icon_fill} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_messages_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_add_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_explore_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_liked_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={Default_DP} rounded alt="insta_icon" className="is-clipped icon-28 image-rounded is-bordered" /></Level.Item>
                            </Level> */}
                    <img src={insta_liked_icon} alt="insta_icon" className="is-clipped icon-28 is-pulled-right mr-3 " />
                </Columns.Column>
            </Columns>
        </div>
    </Box>
}


export const instaNav_mobile_profile = (username) => {
    return <Box shadowless radiusless className='m-0 pt-4 pb-1 px-2  stick-to-top z-55' >
        <div onClick={(e) => { e.stopPropagation(); }}>

            <Columns className='m-0 p-0' vCentered>
                <Columns.Column className='m-0 p-0' size={3}>
                    {/* <Heading size={5} weight="bold" className="ml-1">dipeshparashar</Heading> */}
                </Columns.Column>
                <Columns.Column className='m-0 p-0 ' >
                    <Heading size={6} weight="bold" className="has-text-centered is-lowercase">{username}</Heading>
                </Columns.Column>
                <Columns.Column className='m-0 p-0' size={3}>
                    {/* <Level className="is-mobile has-text-centered " >
                                <Level.Item className='m-0 p-0'><img src={insta_home_icon_fill} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_messages_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_add_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_explore_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_liked_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={Default_DP} rounded alt="insta_icon" className="is-clipped icon-28 image-rounded is-bordered" /></Level.Item>
                            </Level> */}

                    <img
                        src={instaDotMenu}
                        alt="menu"
                        className="li-iconSize24 is-pulled-right mr-1 figure "
                    />

                    <div className='is-pulled-right mr-2'>
                        {/* <img src={instaDotMenu} alt="insta_icon" className="is-clipped icon-28 p-1" /> */}
                    </div>


                </Columns.Column>
            </Columns>
        </div>
    </Box>
}


export const instaNav = () => {
    return <Box shadowless radiusless className='m-0 p-0 is-bordered stick-to-top z-55' >
        <div onClick={(e) => { e.stopPropagation(); }}>
            <Columns className='m-0 p-0'>
                <Columns.Column className='m-0 p-0'>
                </Columns.Column>
                <Columns.Column className='m-0 p-0' size={8} >
                    <Columns className='m-0 p-0' vCentered>
                        <Columns.Column className='m-0 p-0 '>
                            <img
                                alt="insta logo"
                                // size={128}
                                src={insta_logo_black}
                                className="is-clipped has-bacground-transparent instaLogo-103w mt-3 mb-1"
                            />
                        </Columns.Column>
                        <Columns.Column className='m-0 p-0'>
                            <Form.Field >
                                <Form.Control className=' has-icons-left '>
                                    <span class="icon is-small is-left">
                                        <FaSearch color='dark' className='opacity75' />
                                    </span>
                                    <Form.Input
                                        // color="success"
                                        size="small"
                                        placeholder="Search"
                                        // color="danger"
                                        className="border-radius-6 has-background-grey-lightest is-borderless is-size-6 minW240 maxW240 "
                                    />
                                </Form.Control>
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column className='m-0 p-0' size={4}>
                            <Level className="is-mobile has-text-centered " >
                                <Level.Item className='m-0 p-0'><img src={insta_home_icon_fill} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_messages_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_add_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_explore_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={insta_liked_icon} alt="insta_icon" className="is-clipped icon-28" /></Level.Item>
                                <Level.Item className='m-0 p-0'><img src={Default_DP} rounded alt="insta_icon" className="is-clipped icon-28 image-rounded is-bordered" /></Level.Item>
                            </Level>
                        </Columns.Column>
                    </Columns>
                </Columns.Column>
                <Columns.Column className='m-0 p-0'>
                </Columns.Column>
            </Columns>
        </div>
    </Box>
}

export const insta_profileCard_mobile = (dpImage, highlightImg, postImg, userName, userBio, websiteUrl, dpFill, isMobile) => {

    return <Box radiusless shadowless className='m-0 p-0'>


        <Columns className='is-size-7 px-4 has-text-centered mt-2 mb-0 pb-0' vCentered={true}>
            <Columns.Column size={4}>
                <Media.Item className=" " align="left" >
                    <Image
                        src={dpImage ?? Default_DP}
                        backgroundColor="black"
                        // size={"128"}
                        className={` ${dpFill ? "insta-dp-contain-mobile" : "insta-dp-cover-mobile"}  is-clipped `}
                        rounded
                    />
                </Media.Item>
            </Columns.Column>
            <Columns.Column>
                <p className='has-text-weight-bold'>3,005</p>
                <p>Posts</p>
            </Columns.Column>
            <Columns.Column>
                <p className='has-text-weight-bold'>4.8m</p>
                <p>Followers</p>
            </Columns.Column>
            <Columns.Column>
                <p className='has-text-weight-bold'>1600</p>
                <p>Following</p>
            </Columns.Column>
        </Columns>

        <Box radiusless shadowless className='insta-line-height insta-font-size m-0 px-3 pt-0' >
            <p className='is-size-6p6 has-text-weight-bold'>{userName}</p>
            <p className='is-size-7 has-text-weight-medium'>{userBio}</p>
            <p className=" has-text-weight-medium  "><a className="insta-link-color" href={websiteUrl ?? "https://testpost.app"} target="_blank" rel="noreferrer">{websiteUrl ?? "https://testpost.app"}</a> </p>
            <p className="mt-2  is-size-7">Followed by <strong>adam</strong>, <strong>eve</strong>, <strong>trump</strong> +37 more</p>
        </Box>
        <Tabs size="small" align="left" className=" no-lines mt-2 is-size-7p4" >
            <Tabs.Tab  >
                <div className="is-clearfix">
                    <Image
                        src={highlightImg ?? Default_DP}
                        className="insta-highlight-pic-mobile has-background-transparent"
                        rounded
                    />
                    <p className="has-text-centered  pt-2" shadowless>
                        Highlight
                    </p>
                </div>
            </Tabs.Tab>
            <Tabs.Tab  >
                <div className="is-clearfix">
                    <Image
                        src={highlightImg ?? Default_DP}
                        className="insta-highlight-pic-mobile has-background-transparent"
                        rounded
                    />
                    <p className="has-text-centered  pt-2" shadowless>
                        Highlight
                    </p>
                </div>
            </Tabs.Tab>
            <Tabs.Tab >
                <div className="is-clearfix">
                    <Image
                        src={highlightImg ?? Default_DP}
                        className="insta-highlight-pic-mobile has-background-transparent "
                        rounded
                    />
                    <p className="has-text-centered  pt-2" shadowless>
                        Highlight
                    </p>
                </div>
            </Tabs.Tab>
            <Tabs.Tab >
                <div className="is-clearfix">
                    <Image
                        src={highlightImg ?? Default_DP}
                        className="insta-highlight-pic-mobile has-background-transparent "
                        rounded
                    />
                    <p className="has-text-centered pt-2" shadowless>
                        Highlight
                    </p>
                </div>
            </Tabs.Tab>
            <Tabs.Tab >
                <div className="is-clearfix">
                    <Image
                        src={highlightImg ?? Default_DP}
                        className="insta-highlight-pic-mobile has-background-transparent "
                        rounded
                    />
                    <p className="has-text-centered pt-2" shadowless>
                        Highlight
                    </p>
                </div>
            </Tabs.Tab>
        </Tabs>
        <Tabs size="small" align="center" fullwidth className=" no-lines  has-text-weight-semibold ml-2 has-text-grey-light mb-0" >
            <Tabs.Tab active> <span className=" pb-3">POSTS</span> </Tabs.Tab>
            <Tabs.Tab> <span className=" pb-3">REELS</span> </Tabs.Tab>
            <Tabs.Tab> <span className=" pb-3">IGTV</span> </Tabs.Tab>
            <Tabs.Tab> <span className=" pb-3">TAGGED</span> </Tabs.Tab>
            {/* <Tabs.Tab active> <span className=" pb-3"> <Image size={24} src={instaTabTagged} alt="intsa_tab_posts" /> </span> </Tabs.Tab> */}
            {/* <Tabs.Tab active> <span className=" pb-3"> <Image size={24} src={instaTabReels} alt="insta_tab_reels" /> </span> </Tabs.Tab>
            <Tabs.Tab active> <span className=" pb-3"> <Image size={24} src={instaTabVideos} alt="insta_tab_videos" /> </span> </Tabs.Tab>
            <Tabs.Tab active> <span className=" pb-3"> <Image size={24} src={instaTabTagged} alt="insta_tab_tagged" /> </span> </Tabs.Tab> */}
        </Tabs>
        <div>
            <Columns gap={0} className="m-0 p-0 is-mobile">
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
            </Columns>
            <Columns gap={0} className="m-0 p-0 is-mobile">
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
            </Columns>
            <Columns gap={0} className="m-0 p-0 is-mobile">
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
                <Columns.Column className="m-0 p-0" >
                    <Image
                        alt="post"
                        size="square"
                        src={postImg ?? Default_BG}
                        className=" object-fit-cover-imp has-background-transparent is-bordered-white-0p4"
                    />
                </Columns.Column>
            </Columns>
        </div>

    </Box>
}

export const insta_profileCard = (dpImage, highlightImg, postImg, userName, userBio, websiteUrl, dpFill, isMobile) => {
    return <Card className=" overflow-hidden  has-background-transparent  " radiusless shadowless >
        {/* <Card className="fbBox-940w overflow-hidden  has-background-transparent " radiusless shadowless ></Card> */}
        {/* <Card.Content className="has-background-transparent radiusless  p-1"> */}
        {/* <Card.Content className="has-background-transparent radiusless fbBox-940w p-1"> */}
        <Card.Content className="has-background-transparent radiusless is-centered   p-1">

            <ColumnAux isMiddle={true} size={isMobile ? 12 : null}>
                <Box shadowless borderless className={` has-background-transparent m-0 p-0 is-centered ${isMobile ? "" : "fbBox-940w"}  `} >
                    {/* <br /> */}
                    {/* <br /> */}
                    <Columns className=" is-mobile">
                        <Columns.Column narrow>
                            <Box className="p-4 has-background-transparent" shadowless>

                            </Box>
                        </Columns.Column>
                        <Columns.Column size={3}>
                            <Media.Item className="mt-5 z-25 " align="left" >
                                <Image
                                    src={dpImage ?? Default_DP}
                                    backgroundColor="black"
                                    className={` ${dpFill ? "insta-dp-contain" : "insta-dp-cover"}  is-clipped ml-2`}
                                    rounded
                                />
                            </Media.Item>
                        </Columns.Column>
                        <Columns.Column className="mt-4" size={7}>
                            <ColumnAux size={8} className="is-mobile">
                                <Level alignItems="left " className='is-mobile'>
                                    <Level.Item>
                                        <p className="ml-2 is-size-3 has-text-black has-text0-weight-light" textColor="black" >testpostapp</p>
                                    </Level.Item>
                                    <Level.Item className="is-hidden-touch">
                                        <Button size="small" color="info" className="px-6 mr-0 ml-4 " textWeight="bold">Follow</Button>
                                    </Level.Item>
                                    <Level.Item>
                                        <Button className="ml-0 has-background-transparent is-borderless" textWeight="semibold">•••</Button>
                                    </Level.Item>
                                </Level>
                            </ColumnAux>
                            <ColumnAux size={10} className="is-mobile">
                                <Level alignItems="left is-mobile has-text-weight-medium">
                                    <Level.Item>
                                        <p><strong>3,005</strong> posts</p>
                                    </Level.Item>
                                    <Level.Item>
                                        <p><strong>4.8m</strong> followers</p>
                                    </Level.Item>
                                    <Level.Item>
                                        <p><strong>1600</strong> following</p>
                                    </Level.Item>
                                </Level>
                            </ColumnAux>
                            <p className="ml-4 ">
                                <p className="has-text-black has-text-weight-bold  ">{userName ?? "UserName"} </p>
                                <p className=" has-text-weight-normal has-text-black   ">{userBio ?? "User Bio"} </p>
                                <p className=" has-text-weight-semibold  "><a className="insta-link-color" href={websiteUrl ?? "https://testpost.app"} target="_blank" rel="noreferrer">{websiteUrl ?? "https://testpost.app"}</a> </p>
                                <p className="mt-4  is-size-7">Followed by <strong>adam</strong>, <strong>eve</strong>, <strong>trump</strong> +37 more</p>
                            </p>
                        </Columns.Column>
                    </Columns>
                    <Tabs size="small" align="left" className=" no-lines has-text-weight-semibold mt-6" >
                        <Tabs.Tab className="mx-2" >
                            <div className="is-clearfix">
                                <Image
                                    src={highlightImg ?? Default_DP}
                                    className="insta-highlight-pic has-background-transparent ml-2"
                                    rounded
                                />
                                <Box className="has-text-centered has-background-transparent mt-0 pt-2" shadowless>
                                    Highlight
                                </Box>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab className="mx-2" >
                            <div className="is-clearfix">
                                <Image
                                    src={highlightImg ?? Default_DP}
                                    className="insta-highlight-pic has-background-transparent ml-2"
                                    rounded
                                />
                                <Box className="has-text-centered has-background-transparent mt-0 pt-2" shadowless>
                                    Highlight
                                </Box>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab className="mx-2" >
                            <div className="is-clearfix">
                                <Image
                                    src={highlightImg ?? Default_DP}
                                    className="insta-highlight-pic has-background-transparent ml-2"
                                    rounded
                                />
                                <Box className="has-text-centered has-background-transparent mt-0 pt-2" shadowless>
                                    Highlight
                                </Box>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab className="mx-2" >
                            <div className="is-clearfix">
                                <Image
                                    src={highlightImg ?? Default_DP}
                                    className="insta-highlight-pic has-background-transparent ml-2"
                                    rounded
                                />
                                <Box className="has-text-centered has-background-transparent mt-0 pt-2" shadowless>
                                    Highlight
                                </Box>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab className="mx-2" >
                            <div className="is-clearfix">
                                <Image
                                    src={highlightImg ?? Default_DP}
                                    className="insta-highlight-pic has-background-transparent ml-2"
                                    rounded
                                />
                                <Box className="has-text-centered has-background-transparent mt-0 pt-2" shadowless>
                                    Highlight
                                </Box>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab className="mx-2" >
                            <div className="is-clearfix">
                                <Image
                                    src={highlightImg ?? Default_DP}
                                    className="insta-highlight-pic has-background-transparent ml-2"
                                    rounded
                                />
                                <Box className="has-text-centered has-background-transparent mt-0 pt-2" shadowless>
                                    Highlight
                                </Box>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab className="mx-2" >
                            <div className="is-clearfix">
                                <Image
                                    src={highlightImg ?? Default_DP}
                                    className="insta-highlight-pic has-background-transparent ml-2"
                                    rounded
                                />
                                <Box className="has-text-centered has-background-transparent mt-0 pt-2" shadowless>
                                    Highlight
                                </Box>
                            </div>
                        </Tabs.Tab>
                    </Tabs>
                    <hr className="instaHr mb-3" />
                    <Tabs size="small" align="center" className=" no-lines  has-text-weight-semibold ml-4 has-text-grey-light mb-0" >
                        <Tabs.Tab active> <span className=" mx-5 pb-3">POSTS</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="mx-5 pb-3">REELS</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="mx-5 pb-3">IGTV</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="mx-5 pb-3">TAGGED</span> </Tabs.Tab>
                    </Tabs>

                    <Columns gap={1} className="mt-0 is-mobile">
                        <Columns.Column className="has-text-left mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-centered mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-right mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-left mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-centered mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-right mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-left mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-centered mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                        <Columns.Column className="has-text-right mt-0 pt-3" >
                            <img
                                alt="post"
                                src={postImg ?? Default_BG}
                                className="insta-gallery-post has-background-transparent"
                            />
                        </Columns.Column>
                    </Columns>
                    <br />
                    <br />
                    <br />
                </Box>
            </ColumnAux>


        </Card.Content>
    </Card>
}


export const insta_profileBox = (dpImage, highlightImg, postImg, userName, userBio, websiteUrl, dpFill) => {
    return <Columns className='m-0 has-background-transparent'>
        {/* <Columns.Column className='' size={2} >
            </Columns.Column> */}
        <Columns.Column className="">
            {insta_profileCard(dpImage, highlightImg, postImg, userName, userBio, websiteUrl, dpFill)}
        </Columns.Column>
        {/* <Columns.Column className=' ' size={2}>
            </Columns.Column> */}
    </Columns>
}


export const insta_postCard = (dpImge, postImage, userName, position, content, postFill, setPostFill, isMobile) => {
    return <Box radiusless={isMobile} shadowless className={` m-0 pb-0 px-0 has-background-white is-bordered ${isMobile ? "" : "insta-post-boxWidth-470w"} `}>
        <Box shadowless radiusless className="has-background-transparent mt-1 py-0 m-0  ">

            {/* <Box shadowless radiusless className= "has-background-transparent py-0 m-0 insta-post-boxWidth-614w "> */}

            <Button size="small" className={`has-background-transparent is-borderless m-0 p-0  is-pulled-right ${isMobile ? "mr-minus-10" : ""}`}>
                <img
                    src={instaDotMenu}
                    alt="menu"
                    className="li-iconSize24  figure "
                />
            </Button>
            <Columns className="is-mobile pb-3  " gap={0}>
                <Columns.Column size={1} className="pt-0" >
                    <Media className="m-0">
                        <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                            <Image
                                src={dpImge ?? Default_DP}
                                className={` insta-post-dp-size has-background-transparent ${isMobile ? "ml-minus-9 " : ""}`}
                                rounded
                            />
                        </Media.Item>
                    </Media>
                </Columns.Column>
                <Columns.Column className=" py-0 pt-0 pl-0">
                    <Heading className="mt-2 ml-2  mb-0 insta-fontSize14 is-lowercase no-word-space " textWeight="semibold" >{userName ?? "User Name"} </Heading>
                </Columns.Column>
            </Columns>
        </Box>
        {
            postImage ?
                <div className="li-imageWrapper is-bordered-y m-0 p-0 " onClick={() => { setPostFill(!postFill) }} >
                    <img
                        src={postImage}
                        alt="post"
                        className={`${postFill ? "insta-post-imageContainer-cover m-0 p-0 has-background-black " : "insta-post-imageContainer-contain  m-0 p-0 has-background-black"}`}
                    />
                </div>
                : null
        }

        <Box shadowless radiusless className="has-background-transparent pt-1 px-0 pb-0 mb-0">
            <Columns className="mx-0 my-0 mb-0 is-mobile"  >
                <Columns.Column  >
                    <img
                        src={instaLove}
                        alt="like"
                        className="li-iconSize24  "
                    />
                    <img
                        src={instaComment}
                        alt="comment"
                        className="li-iconSize24 ml-4  "
                    />
                    <img
                        src={instaShare}
                        alt="share"
                        className="li-iconSize24 ml-4  "
                    />
                </Columns.Column>

                <Columns.Column className=" has-text-right"  >
                    <img
                        src={instaSave}
                        alt="send"
                        className="li-iconSize24 figure "
                    />
                </Columns.Column>
            </Columns>
        </Box>
        <Box shadowless radiusless className=" has-background-transparent m-0 px-2 pt-0 pb-2">
            <Columns className="m-0 p-0 is-mobile"  >
                <Columns.Column className=" p-0 m-0 " size={1}  >
                    <Image
                        src={Default_DP}
                        alt="testpost"
                        size={24}
                        className=" ml-1 figure m-0 p-0"
                        rounded
                    />
                </Columns.Column>
                <Columns.Column className=" p-0 m-0 "  >
                    <span className={` is-size-7 has-text-grey  mt-minus-20 ${isMobile ? "ml-2" : ""} `}>  Liked by <strong>testpost.app</strong> and <strong>445,171 others</strong> </span>
                </Columns.Column>
            </Columns>
        </Box>
        <Box shadowless radiusless className=" has-background-transparent m-0 px-3 pt-0 pb-0">
            {
                content ?
                    <div className='insta-line-height insta-font-size' > <span className="  mt-minus-20 is-lowercase no-word-space "> <strong>{userName ?? "testpost"}</strong> </span> <span className="ml-1  has-text-weight-medium  "> {content} </span> </div>
                    : null
            }
        </Box>
        <Box shadowless radiusless className=" has-background-transparent m-0 px-3 pt-0 pb-3">

            <span className="ml-1 is-size-8 has-text-grey has-text-weight-normal"> 7 HOURS AGO </span>

        </Box>
        <Box shadowless radiusless className=" is-bordered-y m-0 mt-0 pt-3 mb-0 px-2 pb-2 has-background-transparent ">

            <Columns className="m-0 p-0 is-mobile"  >
                <Columns.Column className=" p-0 m-0 " size={1}  >
                    <img
                        src={instaAddCommentFace}
                        alt="add comment"
                        className="li-iconSize24 ml-1 figure "
                    />
                </Columns.Column>
                <Columns.Column className=" p-0 m-0 "  >
                    <span className=" is-size-7 has-text-grey ml-2 mt-minus-20 "> Add a comment... </span>
                </Columns.Column>
                <Columns.Column className=" p-0 m-0 has-text-right" narrow  >
                    <span className=" is-size-7 has-text-info opacity35 mr-2 has-text-weight-semibold "> Post </span>
                </Columns.Column>
            </Columns>

        </Box>
    </Box>
}

export const insta_postArea = (dpImge, postImage, userName, position, content, postFill, setPostFill) => {

    return <Card shadowless className=" has-background-transparent  overflow-hidden is-centered is-h-center "  >

        {/* <Card className=" insta-post-boxWidth-614w insta-bg-color overflow-hidden is-centered is-h-center "  > */}

        <Card.Content className=" has-background-transparent radiusless px-0 pb-0 mb-0">
            {/* <ColumnAux isMiddle={true} className=" m-0 p-0 has-background-warning " > */}
            {/* <Box shadowless borderless className=' has-background-transparent m-0 p-0 is-centered fbBox-940w ' > */}
            <Columns className=" m-0 p-0">
                <Columns.Column size={2}>
                </Columns.Column>
                <Columns.Column>
                    <Columns mobile={true}>
                        <Columns.Column size={7}>
                            {insta_postCard(dpImge, postImage, userName, position, content, postFill, setPostFill)}
                            <br />
                            {insta_postCard(dpImge, postImage, userName, position, content, postFill, setPostFill)}
                        </Columns.Column>
                        <Columns.Column >
                            <Box shadowless className=' has-background-transparent '>
                                {insta_rowOfColumns_leftImage(dpImge ?? Default_DP, 48, true, userName, userName, "has-text-weight-bold is-size-7 has-text-black", "has-text-weight-medium is-size-7 has-text-grey is-lowercase no-word-space", "Switch", "has-text-weight-semibold is-size-7 has-text-info is-borderless has-background-transparent")}
                                <p className=' my-0' />
                                {insta_rowOfColumns_leftImage(null, null, null, "Suggestions for you", null, "has-text-weight-medium is-size-6 has-text-grey ml-0", "", "See All", "has-text-weight-semibold is-size-7 has-text-black is-borderless has-background-transparent")}
                                {
                                    randomUsers().map(u =>
                                        insta_rowOfColumns_leftImage(u.image, 32, true, u.name, u.name, "has-text-weight-bold is-size-7 has-text-black", "has-text-weight-medium is-size-7 has-text-grey is-lowercase no-word-space", "Follow", "has-text-weight-semibold is-size-7 has-text-info is-borderless has-background-transparent")
                                    )
                                }
                            </Box>
                        </Columns.Column>
                    </Columns>
                </Columns.Column>
                <Columns.Column size={1}>
                </Columns.Column>
            </Columns>
            {/* </ColumnAux> */}
        </Card.Content>

    </Card>

}


export const insta_postBox = (dpImge, postImage, userName, position, content, postFill, setPostFill) => {
    return <Columns className='m-0 has-background-transparent'>
        {/* <Columns.Column className='' size={2} >
        </Columns.Column> */}
        <Columns.Column className="">
            {insta_postArea(dpImge, postImage, userName, position, content, postFill, setPostFill)}
        </Columns.Column>
        {/* <Columns.Column className=' ' size={2}>
        </Columns.Column> */}
    </Columns>
}

export const insta_rowOfColumns_leftImage = (image, imageSize, isRounded, mainText, secondaryText, mainTextClasses, secondaryTextClasses, rightColButtonText, rightColButtonClasses) => {
    let comp = <Columns className='p-0 m-0  mb-2 is-vcentered ' mobile={true}>
        {image ?
            <Columns.Column className='p-0 m-0' narrow>
                <Image rounded={isRounded} alt="icon" size={imageSize ?? 16} className="" src={image} />
            </Columns.Column>
            : null}

        <Columns.Column className={`p-0  m-0 ${image ? "ml-2 pl-2" : null} `} >
            <p className={`${mainTextClasses}`}>{mainText}</p>
            <p className={`${secondaryTextClasses}`}>{secondaryText}</p>
        </Columns.Column>
    </Columns>

    if (rightColButtonText) {
        comp = <Columns vCentered className='py-0 my-0'>
            <Columns.Column className='py-1 my-0'>
                {comp}
            </Columns.Column>
            <Columns.Column narrow className='py-1 my-0'>
                <Button type='text' className={`${rightColButtonClasses}`}>{rightColButtonText}</Button>
            </Columns.Column>
        </Columns>
    }

    return comp
}




