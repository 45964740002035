
import Parse from 'parse'
import { currentUserInfo, getCurrentWorkspaceId, isCurrentUser, validateMemberInfoRoles } from '../utilities/Login';
import { randomCode, removeKeysFromUserInfo_Obj, cypherNoteObjToString, di_cypherNoteObjToString } from '../utilities/Utilities';
import { appMeta, EntityLogType, EventCompletionStatus, projectPointer, userPointer, workspacePointer } from './EnumsAndPointers';

const TimelineClass = Parse.Object.extend("Timeline");



export class Timeline {

    constructor(id, entity, entityId, byUser, byUserInfo, createdAt, updatedAt, title, description, logType, isByAdmin, workspace, appMeta) {
        this.id = id
        this.entity = entity
        this.entityId = entityId
        this.byUser = byUser
        this.byUserInfo = byUserInfo
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.title = title
        this.description = description
        this.logType = logType ?? EntityLogType.updated
        this.isByAdmin = isByAdmin ?? false
        this.workspace = workspace
        this.appMeta = appMeta
    }


    static copyFrom(obj) {
        let ob = new Timeline()
        ob.id = obj.id
        ob.entity = obj.entity
        ob.entityId = obj.entityId
        ob.byUser = obj.byUser
        ob.byUserInfo = obj.byUserInfo
        ob.createdAt = obj.createdAt
        ob.updatedAt = obj.updatedAt
        ob.title = obj.title
        ob.description = obj.description
        ob.logType = obj.logType
        ob.isByAdmin = obj.isByAdmin
        ob.workspace = obj.workspace
        ob.appMeta = obj.appMeta
    }

    static initFromPFObject = (obj) => {

        let np = new Timeline()
        if (!obj.id) {
            return null
        }

        np.id = obj.id
        np.entity = obj.get("entity")
        np.entityId = obj.get("entityId")

        let bu = obj.get("byUser")
        np.byUser = bu.id ?? isCurrentUser().id
        np.byUserInfo = obj.get("byUserInfo")

        np.createdAt = obj.get("createdAt")
        np.updatedAt = obj.get("updatedAt")

        np.title = obj.get("title")
        np.description = obj.get("description")

        np.logType = obj.get("logType") ?? EntityLogType.updated

        np.isByAdmin = obj.get("isByAdmin") ?? false

        let wk = obj.get("workspace")
        np.workspace = wk.id
        np.appMeta = obj.get("appMeta")

        return np
    }


    static updateTimline = ( entity, entityId, obj, logType, title, description ) => {
        let tmLog = new Timeline()
        tmLog.entity = entity
        tmLog.entityId = entityId
        tmLog.title = title ??  (entity + " " + logType)
        tmLog.description = description ?? ""

        tmLog.logType = logType
        if (obj.isArchived){
            tmLog.logType = EntityLogType.archived
        }
        console.log("SAVING TIMELINE LOG")
        tmLog.add((succ, result, errMsg) =>{
            if (succ) {
                console.log("Timeline log is added")
                console.log(result)
            } else {
                console.log("Timeline log could not be added")
                console.log(errMsg)
            }
        })
    }



    add = (callback) => {
        let ob = new TimelineClass()

        ob.set("entity", this.entity)
        ob.set("entityId", this.entityId)
        ob.set("title", this.title)
        ob.set("description", this.description)

        ob.set("logType", this.logType)
        ob.set("isByAdmin", this.isByAdmin)

        const cu = isCurrentUser()
        ob.set("byUser", userPointer(cu.id))
        ob.set("byUserInfo", currentUserInfo())

        ob.set("workspace", workspacePointer(getCurrentWorkspaceId()))
        ob.set("appMeta", appMeta)

        ob.save()
            .then((evnt) => {
                this.id = evnt.id
                console.log('New Timeline Log Created for ' + this.entity)
                callback(true, this, '')
            }, (error) => {
                console.log('Failed to create new timeline log, with error code: ' + error.message);
                callback(false, null, error.message)
            });

    }

    static getAll = (entity, entityId, logType, callback) => {
        console.log('Searching For Timeline of ' + entity + " " + entityId)
        var query = new Parse.Query(TimelineClass);
        query.equalTo("workspace", workspacePointer(getCurrentWorkspaceId()))

        // Apply pagination
        query.limit(100)

        query.descending('createdAt')

        if (entity) {
            query.equalTo("entity", entity)
        }
        if (entityId) {
            query.equalTo("entityId", entityId)
        }
        if (logType){
            query.equalTo("logType", logType)
        }

        query.find().then((objects) => {
            if (objects.length > 0) {
                let allObjects = []

                console.log("ALL OBJECTS ARE")
                console.log(objects)

                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Timeline.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Timlline logs found.')
            }
        }, (error) => {
            callback(false, [], error.message)
        })
    }




}