import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import Avatar from './Avatar';
import { MdAddBox } from 'react-icons/md';
import { IoAddCircleSharp, IoRemoveCircleSharp } from 'react-icons/io5';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { TiDelete } from 'react-icons/ti';





const UserAvatar = (props) => {


    const imageOnly = () => {
        return <Tag key={props.infoObj.id} className={props.clearBg ? " py-4 m-1 ml-0 has-background-transparent " : " py-5 m-1 has-background-white-bis "}>
            <Avatar small={props.small ?? false} imageUrl={props.infoObj.imageUrl} />
        </Tag>
    }

    const avatar_list = () => {
        return <Box shadowless key={props.infoObj.id} className={props.clearBg ? " p-0 m-0 py-1 has-background-transparent " : " p-0 m-0 has-background-white-bis "}>

            <Columns className=" is-mobile px-2 " vCentered>
                <Columns.Column size={2}>
                    <Avatar small={props.small ?? false} imageUrl={props.infoObj.imageUrl} />

                </Columns.Column>
                <Columns.Column>
                    <p className={"is-size-7 ml-2 mt-15 has-text-grey  has-text-weight-medium  " + (props.nameWrap ? " text-wrap80px " : " text-wrap125px ")}> {props.infoObj.name} </p>
                    {
                        props.showRole ?
                            <p className={"is-size-8 ml-2 has-text-grey-light  has-text-weight-semibold  "}> {props.infoObj.role ?? ""} {props.infoObj.isEntityCreator ? " • Creator" : null} </p>
                            :
                            null
                    }

                    {
                        props.showFullInfo ?
                            <p className={"is-size-7 ml-2 has-text-grey  has-text-weight-medium  "}> {props.infoObj.email} &nbsp; • &nbsp; {props.infoObj.role ?? ""} {props.infoObj.isEntityCreator ? " • Creator" : null} </p>
                            :
                            null
                    }
                </Columns.Column>
            </Columns>




            {
                props.noButton ? null
                    :
                    <Columns.Column narrow>
                        {
                            props.isAddable ?
                                <IoAddCircleSharp className="ml-2 has-text-grey opacity50 is-hover-opacity100 is-size-5" onClick={() => { props.addPressed(props.infoObj) }} /> :
                                null
                        }
                        {
                            props.isRemovable ?
                                <TiDelete className="ml-2 has-text-grey-light opacity25 is-hover-opacity100 is-size-5" onClick={() => { props.removePressed(props.infoObj) }} /> :
                                null
                        }
                    </Columns.Column>
            }
        </Box>
    }

    const avatar = () => {
        return <Tag key={props.infoObj.id} className={props.clearBg ? " py-4 m-1 ml-0 has-background-transparent " : " py-5 m-1 has-background-white-bis "}>
            <Avatar small={props.small ?? false} imageUrl={props.infoObj.imageUrl} />
            <span className={"is-size-7 ml-2 has-text-grey  has-text-weight-medium  " + (props.nameWrap ? " text-wrap80px " : " text-wrap125px ")}> {props.infoObj.name} </span>
            {/* <MdAddBox className="ml-2 has-text-grey-light is-size-5" /> */}
            {/* <IoRemoveCircleSharp className="ml-2 has-text-grey-light opacity35 is-hover-opacity100 is-size-5" /> */}

            {
                props.showRole ?
                    <span className={"is-size-6p7 ml-2 has-text-grey  has-text-weight-medium  "}> • &nbsp; {props.infoObj.role ?? ""} {props.infoObj.isEntityCreator ? " • Creator" : null} </span>
                    :
                    null
            }

            {
                props.showFullInfo ?
                    <span className={"is-size-7 ml-2 has-text-grey  has-text-weight-medium  "}> · &nbsp;{props.infoObj.email} &nbsp; • &nbsp; {props.infoObj.role ?? ""} {props.infoObj.isEntityCreator ? " • Creator" : null} </span>
                    :
                    null
            }

            {
                props.noButton ? null
                    :
                    <span>
                        {
                            props.isAddable ?
                                <IoAddCircleSharp className="ml-2 has-text-grey opacity50 is-hover-opacity100 is-size-5" onClick={() => { props.addPressed(props.infoObj) }} /> :
                                null
                        }
                        {
                            props.isRemovable ?
                                <TiDelete className="ml-2 has-text-grey-light opacity25 is-hover-opacity100 is-size-5" onClick={() => { props.removePressed(props.infoObj) }} /> :
                                null
                        }
                    </span>
            }
        </Tag>
    }


    const largeAvatar = () => {
        return <Tag key={props.infoObj.id} className={props.clearBg ? " py-4 m-1 ml-0 has-background-transparent " : " py-5 m-1 has-background-white-bis "}>
                <Avatar medium={true} imageUrl={props.infoObj.imageUrl} />
                <span className={"is-size-6p7 ml-2 has-text-grey  has-text-weight-medium  " + (props.nameWrap ? " text-wrap80px " : " text-wrap125px ")}> {props.infoObj.name} </span>
                {/* <MdAddBox className="ml-2 has-text-grey-light is-size-5" /> */}
                {/* <IoRemoveCircleSharp className="ml-2 has-text-grey-light opacity35 is-hover-opacity100 is-size-5" /> */}

            {
                props.showRole ?
                    <span className={"is-size-6p7 ml-2 has-text-grey  has-text-weight-medium  "}> • &nbsp; {props.infoObj.role ?? ""} {props.infoObj.isEntityCreator ? " • Creator" : null} </span>
                    :
                    null
            }

            {
                props.showFullInfo ?
                    <span className={"is-size-6p7 ml-2 has-text-grey  has-text-weight-medium  "}> · &nbsp;{props.infoObj.email} &nbsp; • &nbsp; {props.infoObj.role ?? ""} {props.infoObj.isEntityCreator ? " • Creator" : null} </span>
                    :
                    null
            }

            {
                props.noButton ? null
                    :
                    <span>
                        {
                            props.isAddable ?
                                <IoAddCircleSharp className="ml-2 has-text-grey opacity50 is-hover-opacity100 is-size-5" onClick={() => { props.addPressed(props.infoObj) }} /> :
                                null
                        }
                        {
                            props.isRemovable ?
                                <TiDelete className="ml-2 has-text-grey-light opacity25 is-hover-opacity100 is-size-5" onClick={() => { props.removePressed(props.infoObj) }} /> :
                                null
                        }
                    </span>
            }




        </Tag>
    }





    return props.large ? largeAvatar() : (props.imageOnly ? imageOnly() : (props.isList ?  avatar_list() : avatar() ))


}

export default UserAvatar