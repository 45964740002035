import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Modal, Panel, Columns, Button, Container, Table, Box, Form, Tabs, Dropdown, Message } from 'react-bulma-components';
import { Invitation } from '../../Models/Invitation'
import { randomCode, validateEmail, copyToClipboard } from '../../utilities/Utilities'

import { FcInvite } from 'react-icons/fc';
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'
import { TiTick } from 'react-icons/ti';
import { HiDotsVertical } from 'react-icons/hi';
import { MdContentCopy } from 'react-icons/md';



import { getCurrentWorkspaceId, getShowInvitationReminderToAdmin, setShowInvitationIfNeeded } from '../../utilities/Login';
import ColumnAux from '../../Containers/ColumnAux';
import moment from 'moment';
import LoadingAux from '../../Containers/LoadingAux';
import { canDeleteEntity } from '../../utilities/TpRules';
import { EntityType } from '../../Models/EnumsAndPointers';



const SentInvitations = (props) => {



    const [invites, setInvites] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {

        let isMounted = true;

        setLoading(true)
        Invitation.getAll((succ, invs, errMsg) => {
            if (isMounted){
                setLoading(false)
            }
            if (succ) {
                if (isMounted){
                    setInvites(invs)
                }
            } else {
                console.log(errMsg);
            }
        })
        return () => (isMounted = false)
    }, [])



    const inviteChanged = (typeChanged, pass, val) => {

        let invs = [...invites]

        // if (anyEmailContainsError(invs)){
        //     invs = removeAllErrors()
        // }

        // let index = invs.findIndex((thisInv) => {
        //     return thisInv.pass === pass
        // })

        // if (index >= 0){
        //     let thisInv = invs[index]
        //     if (typeChanged === "email"){
        //         thisInv.email = val
        //     }
        //     if (typeChanged === "role"){
        //         thisInv.role = val
        //     }
        //     invs[index] = thisInv
        //     setInvites(invs)
        // }
    }

    const inviteRemoved = (pass) => {


        // Remove invitaions

        let invs = [...invites].filter((inv) => {
            return inv.pass !== pass
        })
        setInvites(invs)
    }


    const modal = () => {
        return (
            // <ColumnAux isMiddle={true} size={12}>
            <Box shadowless className=" has-text-left">
                <LoadingAux isLoading={loading} isMain={true} isCentered={true} loaderHeight={300} >

                    <Message size="small">
                        <Message.Body>
                            <ul>
                                <li>• You can copy invitation pass and share with user directly.</li>
                                <li>• On removing invitaion. The user will not be able to join workspace if not already joined.</li>
                                <li>• If a user has already joined workspace. Removing invitation has no affect.</li>
                            </ul>

                        </Message.Body>
                    </Message>


                    {invites.length ?

                        <Table bordered={false} striped={false} className=" ">
                            <thead>
                                <tr className="is-size-8  has-text-grey ">
                                    <th>Email</th>
                                    <th>Joined</th>
                                    <th>Pass</th>
                                    <th ></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invites.map((nv) => {
                                        return <tr className=" has-text-left ">

                                            <td className="">
                                                <p className=" is-size-6p7 has-text-weight-medium ">{nv.email} • <span className="has-text-weight-semibold">{nv.role}</span></p>
                                                <p className="is-size-7 has-text-grey-light"> By {nv.byUserInfo.name} · {nv.createdAt ? moment(nv.createdAt).format('DD-MMM-YYYY') : "_"}  </p>
                                            </td>
                                            <td>
                                                {nv.joined ? <TiTick className="has-text-grey-light" /> : ""}
                                            </td>
                                            <td>
                                                <p className="has-text-grey is-size-7 has-text-left"> {nv.pass} <Button size="small" className="is-borderless" onClick={() => { copyToClipboard(nv.pass, "Pass is copied") }}> <MdContentCopy /> </Button> </p>
                                            </td>
                                            <td >
                                                {
                                                    canDeleteEntity(EntityType.invitation, nv.byUserInfo, null) ?
                                                        <Dropdown size="small" right renderAs="button" className="  m-0  is-small is-arrowless  p-0  is-borderless " label={
                                                            <HiDotsVertical className="is-size-6 m-0 p-0 has-text-grey" />
                                                        }>
                                                            <Dropdown.Item renderAs="a" value="cancelOrder">Delete Invitaion </Dropdown.Item>
                                                        </Dropdown>
                                                        : null
                                                }

                                            </td>
                                        </tr>

                                    })
                                }
                            </tbody>
                        </Table>
                        :
                        <Box shadowless >
                            <p className="has-text-grey-light has-text-centered"> No invitations </p>
                        </Box>
                    }


                </LoadingAux>
            </Box>
            // </ColumnAux>
        )
    }



    return modal()

}

export default SentInvitations