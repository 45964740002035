import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';



// ImCheckboxUnchecked
// ImCheckboxChecked

// CgCloseO

// {showSorter ? <Selector title="Sort By" options={sortOptions} optionSelected={sortOptionSelected} selectedOption={sortBy} close={clearSelections} /> : null}


const Selector = (props) => {

    const [options, setOptions] = useState(props.options ?? [])
    const [selectedOption, setSelectedOption] = useState(props.selectedOption ?? null)

    const optSelectionToggled = (opt) => {
        if (selectedOption) {
            if (selectedOption.name === opt.name){
                setSelectedOption(null)
                return
            }
        } 

        setSelectedOption(opt)
    }


    const selectAndClose = (opt) => {
        setSelectedOption(opt)
        beforeClose()
    }

    const beforeClose = (overrideOpt) => {

        console.log("Sorted BY")
        console.log(selectedOption)

        if (overrideOpt === ""){
            console.log("Sorted BY Empty string")
            props.optionSelected(null)
            return
        }


        props.optionSelected(selectedOption)
    }




    const row = (opt) => {
        return <tr key={opt.name} className=" my-0  " onClick={() => { optSelectionToggled(opt) }} >
            <td align="left" className="  " >
                <p className="ml-2 mt-2">{opt.name}</p>
            </td>
            <td align="right " >
                <Button className=" has-background-transparent is-borderless" onClick={() => { optSelectionToggled(opt) }} >
                    {
                        selectedOption ?
                        <> {
                            opt.name === (selectedOption.name ?? "") ?
                            <RiCheckboxCircleFill className="mt-1 is-size-4 " />
                            :
                            <RiCheckboxBlankCircleLine className="mt-1 is-size-4 " />
                        }
                        </>
                            :
                            <RiCheckboxBlankCircleLine className="mt-1 is-size-4 " />

                    }
                </Button>
            </td>
        </tr>
    }

    const buttonRow = (opt) => {
        return <tr key={opt.name} className=" my-0  " onClick={() => { selectAndClose(opt)}} >
        <td align="center" className=" no-border has-text-centered " >
            <Button fullwidth color={opt.color ?? "light"} >
                {opt.name}
            </Button>
        </td>
    </tr>
    }
 


    const up = () => {
        return <div>

            <Panel className="m-0 is-shadowless ">
                {/* <Panel.Block backgroundColor="light" className="minW300 stick-to-top has-background-light z-35 ">
                    <Form.Control className=" mx-2" >
                        <Columns className="  is-mobile is-narrow" gap="gapless">
                            <Columns.Column >
                                <Form.Input color="black" textWeight="semibold" size="small" type="text" placeholder="search" className="py-4" value={filterString} onChange={(e) => { searchStringChanged(e.target.value) }} />
                            </Columns.Column>
                            <Columns.Column size={2}>
                                <Button fullwidth size="small" color="light" className="mt-0 mb-0 mr-1 p-0 is-fullHeight ml-1 " onClick={() => { clearSearch() }} > Clear </Button>
                            </Columns.Column>
                        </Columns>
                    </Form.Control>
                </Panel.Block> */}
                <Table className="minW300 is-fullwidth" hoverable={false} striped={false} bordered={false} >
                    <tbody  >
                        {
                            options.map((opt) => {
                                if (props.showButtons){
                                    return buttonRow(opt)
                                }
                                return row(opt)
                            })
                        }
                    </tbody>
                </Table>
            </Panel>
        </div>
    }

    const modal = () => {
        return <Modal show={true} closeOnBlur={true} showClose={false} onClose={beforeClose} className=" almostNoModalBg backblurOnly lightDeleteButton animate-bottom " >
            <Modal.Card className=" maxW300 minW300 ">
                {
                    props.noHeader ?
                    null 
                    :
                    <Modal.Card.Header showClose >
                        <Modal.Card.Title className="has-text-grey-light" textSize={6} >{props.title ?? "Select Option"}</Modal.Card.Title>
                    </Modal.Card.Header>
                }

                <Modal.Card.Body className="p-0">
                    <Box className={ props.showButtons ? " py-5 is-fullwidth z-1000 has-text-left has-background-white p-0 "  : " is-fullwidth z-1000 has-text-left has-background-white p-0 "  }  >
                        {up()}

                    </Box>
                </Modal.Card.Body>
                {
                    props.noClear ?
                    null :
                    <Modal.Card.Footer renderAs={Button.Group} align="center" className="pt-2" >
                        <Button className="is-size-7 has-text-centered has-text-grey m-0 is-borderless has-background-transparent " onClick={() => { beforeClose("") }} > CLEAR </Button>
                    </Modal.Card.Footer>
                }

            </Modal.Card>
        </Modal >

    }


    return modal()

}

export default Selector