import React, { useEffect, useRef, useState } from "react"
import Parse from 'parse'
import { Box, Columns, Hero, Media, Tabs, Modal, Panel, Content, Button, Message, Heading, Image } from 'react-bulma-components'
import { Form as BulmaForm } from 'react-bulma-components'
import ColumnAux from '../../Containers/ColumnAux'
import { Formik, Form, Field } from 'formik'
import UserDP from '../../assets/user_dp.svg'
import { countryList, detectedCountry, countryAndMCodeforString } from '../../utilities/CountryOpts'
import { validateNumber, validateText, validateRequired, validateEmail, validateName, validatePassword, isQueryStringInvitationEmail } from '../../utilities/Utilities'
import { acceptedFileTypes_forDP } from "../../Models/EnumsAndPointers"

import fileUploadIcon from '../../assets/file-upload.png'
import { FaFile, FaUserCircle } from "react-icons/fa"
import { compressAnd_UploadFile, createAwsFilePath, provideLocalFile, UploadPurpose } from "../Upload/SilentUpload"
import { currentFullUserInfo, currentUserInfo } from "../../utilities/Login"



const ProfileModal = (props) => {


    let cuInfo = currentFullUserInfo()

    let initialVals = {
        name: cuInfo.name,
        email: cuInfo.email,
        jobTitle: cuInfo.jobTitle,
        via: cuInfo.via,
        localDpFile: null,
        ownedWorkspaceIds: cuInfo.ownedWorkspaceIds,
        memberWorkspaceIds: cuInfo.memberWorkspaceIds,
        bio: cuInfo.bio,
        hasImage: false,
        imageUrl: cuInfo.imageUrl
    }

    // id: cu.id,
    // name: cu.get("name"),
    // imageUrl: cu.get("imageUrl"),
    // hasImage: cu.get("hasImage"),
    // email: cu.getEmail(),
    // workspaceId: cu.get('workspaceId'),
    // jobTitle: cu.get('jobTitle'),
    // via: cu.get("via"),
    // ownedWorkspaceIds: cu.get("ownedWorkspaceIds"),
    // memberWorkspaceIds: cu.get("memberWorkspaceIds"),
    // bio: cu.get("bio"),
    // role: ""


    const [imageFile, setImageFile] = useState(null)
    // const [userInfo, setUserInfo] = useState(cuInfo)

    const [allowClose, setAllowClose] = useState(props.restrictCloseForSecs ? false : true)

    // const [saving, setSaving] = useState(false)

    const filePickerRef = useRef(null)


    useEffect(() => {
        let isMounted = true;
        if (props.restrictCloseForSecs) {
            setTimeout(() => {
                setAllowClose(true)
            }, (props.restrictCloseForSecs * 1000))
        }
        if (isMounted) {

        }
        return () => (isMounted = false)
    }, [props.restrictCloseForSecs])




    // const setShowMessageForTime = (thisMessage, type, secs) => {
    //     setShowMessage({
    //         type: "danger",
    //         message: thisMessage
    //     })
    //     setTimeout(() => {
    //         setShowMessage(null)
    //     }, (secs ?? 5) * 1000);
    // }


    const updateProfileNow = ( newImageUrl, values, callback) => {

        let cu = Parse.User.current()

        cu.set("name", values.name)
        cu.set("jobTitle", values.jobTitle)
        cu.set("bio", values.bio)
        if (cuInfo.imageUrl || newImageUrl){
            cu.set("hasImage", true)
        }
        if (newImageUrl) {
            cu.set("imageUrl", newImageUrl)
        }

        cu.save().then(() => {

            // all done
            callback(true, null)

            // setSaving(false)

        }).catch(error => {
            // setSaving(false)
            callback(false, "Problem updating profile : " + error.message)
            // alert("There was a problem updating the profile")
            console.log("Problem updating profile : " + error.message)
        })


    }


    const updateProfile = (values, callback) => {

        // setSaving{true}


        // if imageFile exists .. first upload image 
        if (imageFile) {

            let filePath = createAwsFilePath(UploadPurpose.userDP, null, "png")

            compressAnd_UploadFile(imageFile, filePath, true,
                (fl, progress) => {
                    console.log("Profile picture upload progress changed " + progress)

                },
                (succ, result, errorMsg) => {
                    if (succ) {
                        // result is imageUrl
                        updateProfileNow(result, values, callback)

                    } else {
                        // FAILED : result is file 

                        alert("Problem while upload profile picture")
                        console.log(errorMsg)
                    }
                })


        }else{
            updateProfileNow(null, values, callback)
        }


        // update user and save 


        // wk.add((succ, wkspace, errMsg) => {
        //     if (succ) {
        //         // props.activateWorkspace(wkspace.id)

        //         callback(succ, wkspace, errMsg)

        //     } else {
        //         callback(succ, wkspace, errMsg)
        //         setShowMessageForTime(errMsg, "danger", 5)
        //         console.log(errMsg)
        //     }
        // })




    }

    const startFilePick = () => {
        if (filePickerRef.current) {
            filePickerRef.current.click()
        }
    }

    // const valChanged = (type, val) => {

    //     let cInfo = { ...userInfo }

    //     if (type === "bio") {
    //         cInfo.bio = val
    //     }

    //     setUserInfo(cInfo)

    // }


    const filesAreSelected = (files, setFieldValue) => {

        // alert("Files are selected")




        provideLocalFile(files[0]).then((fl) => {
            setImageFile(fl)
            // setFieldValue("localDpFile", fl)
            setFieldValue("imageUrl", fl.url)
            console.log("Selected file is")
            console.log(fl)

        }).catch((err) => console.log(err))




    }

    const profileForm = () => {

        return <Formik
            initialValues={cuInfo}
            enableReinitialize={true}

            validate={(values) => {
                const errors = {};
                // if (values.password !== values.confirmPassword) {
                //     errors.confirmPassword = 'Passwords do not match'
                // }

                let v_name = validateName(values.name, 3, false)
                if (v_name) {
                    errors.name = v_name
                }
                let v_email = validateEmail(values.email)
                if (v_email) {
                    errors.email = v_email
                }


                if (values.imageUrl) {
                    console.log("IMAGE URL EXISTS")
                    console.log(values.imageUrl)
                } else {
                    errors.imageUrl = "Required. Your unique picture makes your approvals instantly recognised."

                }

                // if (values.termsAgreed !== true) {
                //     errors.termsAgreed = 'Please read and agree the terms to continue.'
                // }


                console.log("ERRORS NOW")
                console.log(errors)

                return errors;
            }}

            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                updateProfile(values, (succ, errMsg) => {

                    actions.setSubmitting(false)

                    if (succ){
                        alert("Profile updated successfully")
                        if (props.closeOnUpdate){
                            props.close()
                        }
                    }else{
                        alert(errMsg)
                    }
                    // actions.resetForm(true)
                    
                    // if (succ) {
                    //     actions.setValues(values)
                    // }
                    // if (succ) {
                    //     props.activateWorkspace(wkspace)
                    // }

                })
            }}
        >

            {
                ({ values, setFieldValue, isSubmitting, errors, touched, isValidating, handleChange }) => {
                    return (
                        <ColumnAux isMiddle={true} size={10} noPaddingSides={false}>

                            <Box className="has-text-left overflow-scroll  is-shadowless has-background-transparent ">
                                {/* {
                                showMessage ?
                                    <Message color={showMessage.type ?? "default"}>
                                        <Message.Body>{showMessage.message}</Message.Body>
                                    </Message>
                                    : null
                            } */}
                                <Form>
                                    {props.heading ? <Box radiusless shadowless className="has-text-centered"> <Heading size={4}> {props.heading} </Heading> </Box> : null}
                                    <BulmaForm.Field className=' mt-0' >
                                        <ColumnAux isMiddle={true} size={4}>
                                            <Box shadowless radiusless className=' has-text-centered  p-1 m-0'>
                                                <Image rounded size={"square"} src={imageFile ? imageFile.url : (values.imageUrl ? values.imageUrl : UserDP)} className=" is-centered  annotation-border mt-0" onClick={() => { startFilePick() }} />
                                            </Box>
                                        </ColumnAux>

                                    </BulmaForm.Field>
                                    <BulmaForm.Field className="">
                                        <BulmaForm.Control>
                                            <div className="file   is-radiusless m-0 is-centered">
                                                <label className="file-label">

                                                    {/* <input multiple={true} disabled={false} className="file-input" type="file" accept={acceptedFileTypes_forAssets} name="images" onChange={(event) => { filesAreSelected([...event.target.files]) }} /> */}
                                                    <input ref={filePickerRef} multiple={false} disabled={false} className="file-input" type="file" accept={acceptedFileTypes_forDP} name="images" onChange={(event) => { filesAreSelected([...event.target.files], setFieldValue) }} />

                                                    <span className="file-cta is-borderless">
                                                        <span className="file-icon ">
                                                            {/* <Image size={48} src={fileUploadIcon} className="" /> */}
                                                            <FaUserCircle className=" is-size-3" />
                                                        </span>
                                                        <span className="file-label is-size-6 ">
                                                            {imageFile ? "Change profile picture" : "Upload profile picture"}
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                        </BulmaForm.Control>

                                        <Field name='imageUrl' type='text' value={values.imageUrl ?? ""} className='input is-hidden is-default' />
                                        {errors.imageUrl && touched.imageUrl && <BulmaForm.Help className="has-text-centered mt-3 is-size-7" color="danger">{errors.imageUrl}</BulmaForm.Help>}

                                        {errors.imageUrl ? null : <p className=" has-text-centered is-size-7 my-4 has-text-grey"> Your unique picture makes your approvals instantly recognised. </p>}


                                    </BulmaForm.Field>

                                    <BulmaForm.Field >
                                        {/* <BulmaForm.Label textWeight="default" className=" is-size-6p7">Email <span className="has-text-grey-lighter has-text-weight-normal">(username)</span></BulmaForm.Label> */}
                                        <BulmaForm.Control>
                                            <Field name='email' disabled={true} type='email' value={values.email ?? ""} className='input is-default mt-2' validate={validateEmail} placeholder="e.g. john@website.com" />
                                        </BulmaForm.Control>
                                        {errors.email && touched.email && <BulmaForm.Help color="danger">{errors.email}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7" >Name</BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='name' type='text' value={values.name ?? ""} className='input is-default' validate={validateName} placeholder="e.g. John Doe" />
                                        </BulmaForm.Control>
                                        {errors.name && touched.name && <BulmaForm.Help color="danger">{errors.name}</BulmaForm.Help>}
                                    </BulmaForm.Field>

                                    {/* <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Password</BulmaForm.Label>
                                        <BulmaForm.Control >
                                            <Field name='password' type='password' value={values.password ?? ""} className='input is-default' validate={validatePassword} placeholder="your secure password" />
                                        </BulmaForm.Control>
                                        {errors.password && touched.password && <BulmaForm.Help color="danger">{errors.password}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Confirm Password</BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='confirmPassword' type='password' value={values.confirmPassword ?? ""} className='input  is-default' validate={validatePassword} placeholder="your secure password again" />
                                        </BulmaForm.Control>
                                        {errors.confirmPassword && touched.confirmPassword && <BulmaForm.Help color="danger">{errors.confirmPassword}</BulmaForm.Help>}
                                    </BulmaForm.Field> */}
                                    {/* <br /> */}
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">Role that best describes you </BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='jobTitle' type='text' value={values.jobTitle ?? ""} className='input is-default  has-background-white-bis' validate={validateText} placeholder="Designer, Editor, Manager, Writer, Art Director..." />
                                            {errors.jobTitle && touched.jobTitle && <BulmaForm.Help color="danger">{errors.jobTitle}</BulmaForm.Help>}
                                        </BulmaForm.Control>
                                    </BulmaForm.Field>

                                    <BulmaForm.Field className="m-0 p-0" radiusless>
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">About me </BulmaForm.Label>
                                        <BulmaForm.Control className=" ">
                                            <Field name='bio' type='text' value={values.bio ?? ""} className='input is-default  has-background-white-bis' validate={validateText} placeholder="A short bio of yourself..." />
                                            {errors.bio && touched.bio && <BulmaForm.Help color="danger">{errors.bio}</BulmaForm.Help>}

                                            {/* <BulmaForm.Textarea
                                                name="bio"
                                                value={values.bio}
                                                // onChange={(e) => { valChanged(e.target.value) }}
                                                type="text"
                                                placeholder={"A short bio of yourself..."}
                                                className=" "
                                                fixedSize
                                                disabled={saving}
                                                rows={6}
                                            /> */}
                                        </BulmaForm.Control>
                                    </BulmaForm.Field>

                                    {/* <BulmaForm.Field className=" mt-4" >
                                        <BulmaForm.Label textWeight="default" className=" is-size-6p7">How did you know about us </BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='via' type='text' value={values.via ?? ""} className='input is-default  has-background-white-bis' validate={validateText} placeholder="Google, LinkedIn, Instagram, Twitter, Friend..." />
                                            {errors.via && touched.via && <BulmaForm.Help color="danger">{errors.via}</BulmaForm.Help>}
                                        </BulmaForm.Control>
                                    </BulmaForm.Field> */}




                                    {/* <br /> */}




                                    <Button disabled={ isSubmitting || isValidating} loading={isSubmitting} type="submit" className="is-fullwidth my-5" color="black"> Update profile</Button>


                                </Form>

                            </Box>
                        </ColumnAux>


                    )
                }
            }

        </Formik >

    }



    let profileModal = () => {

        return (

            <Modal show={true} closeOnBlur={allowClose} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" h-90vh  m-0 p-0" radiusless>
                    <Modal.Card.Header showClose={allowClose} className="    ">
                        <Modal.Card.Title className="has-text-grey-light"  >Profile</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0">
                        <Panel className=" stick-to-top z-30">
                            {/* <Panel.Header>Repositories</Panel.Header> */}
                            {/* <Tabs size="small" backgroundColor="light" textWeight="semibold" className="  " >
                                    <Tabs.Tab active={currentTab === invTabs.new}  onClick={()=>{toggleTab(invTabs.new)}}> {invTabs.new}</Tabs.Tab>
                                    <Tabs.Tab active={currentTab === invTabs.sent}  onClick={()=>{toggleTab(invTabs.sent)}}> {invTabs.sent}</Tabs.Tab>
                                    <Tabs.Tab active={currentTab === invTabs.members}  onClick={()=>{toggleTab(invTabs.members)}}> {invTabs.members}</Tabs.Tab>
                                </Tabs> */}
                        </Panel>

                        {
                            profileForm()
                        }

                    </Modal.Card.Body>
                </Modal.Card>
            </Modal >
        )
    }



    return profileModal()

}

export default ProfileModal