import React from "react";

// Setting from AllContexts.js Now
// let initialFilesState = {
//     files: [],
//     assets: [],
//     uploading: false, 
//     filesBatch: [],
//     progress: 0
// }

export const UploadContext = React.createContext();