import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';

const Avatar = (props) => {


    const av_medium = () => {
        return <div className={" avatar-border has-background-grey-lighter square-40 p-0 " + props.className} >
            {
                props.showText ?
                    <Box shadowless className=" m-0 p-15  has-background-transparent has-text-centered is-size-6p4 has-text-weight-semibold has-text-grey"> {props.showText} </Box>
                    :
                    <img alt="user avatar" className=" avatar square-36  overflow-hidden " src={props.imageUrl} />
            }
        </div>
    }


    const av_small = () => {
        return <div className={" avatar-border has-background-grey-lighter square-24 p-0 " + props.className} >
            {
                props.showText ?
                    <Box shadowless className=" m-0 p-0  has-background-transparent has-text-centered is-size-7 has-text-weight-semibold has-text-grey"> {props.showText} </Box>
                    :
                    <img alt="user avatar" className=" avatar square-20  overflow-hidden " src={props.imageUrl} />
            }
        </div>
    }


    const av = () => {

        return <div className={" avatar-border has-background-grey-lighter square-34 p-0 is-clipped " + props.className} >
            {
                props.showText ?
                    <Box shadowless className=" m-0 p-15  has-background-transparent has-text-centered is-size-7 has-text-weight-semibold has-text-grey"> {props.showText} </Box>
                    :
                    <img alt="user avatar" className=" avatar square-30  overflow-hidden " src={props.imageUrl} />
            }

        </div>
    }

    const showAptAvatar = () => {
        let a = av()
        if (props.small) {
            a = av_small()
        }
        if (props.medium) {
            a = av_medium()
        }
        return a
    }


    return showAptAvatar()


}

export default Avatar