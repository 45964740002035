import React, { useState } from 'react'
import { Box, Button, Message, Columns, Image } from 'react-bulma-components'
import { Form as BulmaForm } from 'react-bulma-components'
import ColumnAux from '../../Containers/ColumnAux'
import { Formik, Form, Field } from 'formik'
import { validateNumber, validateText, validateRequired } from '../../utilities/Utilities'
import { Workspace } from '../../Models/Workspace'
import { countryList, detectedCountry, countryAndMCodeforString } from '../../utilities/CountryOpts'
import WorkspaceDP from '../../assets/workspace_dp.svg'




const CreateWorkspace = (props) => {


    const [showMessage, setShowMessage] = useState(null)


    // CREATE FORMS
    //Name
    //logo
    //strength / size
    //country
    //website/socai
    //purpose


    // If i signup with an email and own an account
    // then if a new business invites me into different workspace
    // what will happen
    // what if when there is another, 3rd one. 


    // User > Team > Invite > Plan 

    // ON invitation page -- it just says.. Join .. Company

    //businessName
    //howBigIsYourTeam  1-5, 25, 50, 100, 100+
    //phoneNumber
    //Website

    let initialVals = {
        name: null,
        // shortname: null, 
        // logo : null, // SKIPPING LOGO FOR NOW
        website: null,
        country: null,
        // countryCode: null,    
        phone: null,
        teamSize: null,
        purpose: null
    }

    const setShowMessageForTime = (thisMessage, type, secs) => {
        setShowMessage({
            type: "danger",
            message: thisMessage
        })
        setTimeout(() => {
            setShowMessage(null)
        }, (secs ?? 5) * 1000);
    }

    const createWorkspaceNow = (name, website, country, phone, teamSize, purpose, callback) => {

        // alert("CREATING WORKSPACE")


        let wk = new Workspace()
        wk.name = name
        wk.website = website
        wk.teamSize = teamSize
        wk.purpose = purpose
        wk.phone = phone


        let countryObject = countryAndMCodeforString(country)
        wk.country = countryObject.country
        wk.countryCode = "" + countryObject.mcode


        wk.add((succ, wkspace, errMsg) => {
            if (succ) {
                // props.activateWorkspace(wkspace.id)

                callback(succ, wkspace, errMsg)

            } else {
                callback(succ, wkspace, errMsg)
                setShowMessageForTime(errMsg, "danger", 5)
                console.log(errMsg)
            }
        })


        // createWorkspace(name, website, teamSize, purpose,  (succ, wkspace, errMsg) => { 
        //     if (succ) {
        //         props.activateWorkspace(wkspace)
        //         callback(succ, wkspace, errMsg)

        //     } else {
        //         callback(succ, wkspace, errMsg)
        //         setShowMessageForTime(errMsg, "danger", 5)
        //         console.log(errMsg)
        //     }
        // })
        // signUpNow(name, email, password, (succ, user, errMsg) => {
        //     if (succ) {
        //         props.userSigned(user)
        //         callback(succ, user, errMsg)

        //     } else {
        //         callback(succ, user, errMsg)
        //         setShowMessageForTime(errMsg, "danger", 5)
        //         console.log(errMsg)
        //     }

        // })




    }


    // const teamSizeChanged = (val) => {

    // }


    return (
        <Formik
            initialValues={initialVals}

            // validate={(values) => {
            //     const errors = {};
            //     if (values.password !== values.confirmPassword) {
            //         errors.confirmPassword = 'Passwords do not match'
            //     }

            //     if (values.termsAgreed !== true) {
            //         errors.termsAgreed = 'Please read and agree the terms to continue.'
            //     }

            //     return errors;
            // }}

            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                createWorkspaceNow(values.name, values.website, values.country, values.phone, values.teamSize, values.purpose, (succ, wkspace, errMsg) => {
                    actions.setSubmitting(false)
                    actions.resetForm(true)
                    if (succ) {
                        actions.setValues(initialVals)
                    }
                    if (succ) {
                        props.activateWorkspace(wkspace)
                    }

                })
            }}
        >

            {
                ({ values, isSubmitting, errors, touched, isValidating, handleChange }) => {
                    return (
                        <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                            <Box className="has-text-left  is-shadowless has-background-transparent ">
                                {
                                    showMessage ?
                                        <Message color={showMessage.type ?? "default"}>
                                            <Message.Body>{showMessage.message}</Message.Body>
                                        </Message>
                                        : null
                                }
                                <Form>
                                    {/* <BulmaForm.Field className=' mt-0' >
                                        <ColumnAux isMiddle={true} size={4}>
                                            <Box className=' has-text-centered is-bordered p-1 m-0'>
                                                <Image size={"square"} src={WorkspaceDP} className=" is-centered  mt-0" />
                                            </Box>
                                        </ColumnAux>
                                    </BulmaForm.Field> */}

                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className="is-size-6p7">Name <span className="has-text-weight-normal has-text-grey-light is-size-7"> &nbsp; &nbsp; Name of your Organisation/Brand/Team  </span></BulmaForm.Label>
                                        <BulmaForm.Control>
                                            {/* <Field name='name' type='text' value={values.name} className='input is-default' validate={validateName} placeholder="like John Doe" /> */}
                                            <Field name='name' type='text' value={values.name ?? ""} className='input is-default' validate={validateText} placeholder="TestPost" />

                                        </BulmaForm.Control>
                                        {errors.name && touched.name && <BulmaForm.Help color="danger">{errors.name}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className="is-size-6p7 mt-4">Website <span className="has-text-weight-normal has-text-grey-light is-size-7"> &nbsp; &nbsp; No website ? Paste link of social media page </span></BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field name='website' type='text' value={values.website ?? ""} className='input is-default' validate={validateText} placeholder="testpost.app" />
                                        </BulmaForm.Control>
                                        {errors.website && touched.website && <BulmaForm.Help color="danger">{errors.website}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className="is-size-6p7 mt-4">Country </BulmaForm.Label>
                                        <BulmaForm.Control>
                                            <Field as='select' className=' select is-size-6p7 is-fullwidth little-border-shadow has-background-white ' validate={validateRequired} name='country'>
                                                {/* <option value={""}></option>
                                                    <optgroup label="">
                                                        {detectedCountry()}
                                                    </optgroup> */}

                                                <optgroup label="">
                                                    <option value={""}></option>

                                                    {
                                                        countryList.map((thisCountry) => {
                                                            let val = `${thisCountry.name} (${thisCountry.mcode})`
                                                            return <option key={val} value={val} >{val}</option>
                                                        })
                                                    }
                                                </optgroup>
                                            </Field>
                                        </BulmaForm.Control>
                                        {errors.country && touched.country && <BulmaForm.Help color="danger">{errors.country}</BulmaForm.Help>}
                                    </BulmaForm.Field>
                                    <BulmaForm.Field >
                                        <BulmaForm.Label textWeight="default" className="is-size-6p7 mt-4">Phone <span className="has-text-weight-normal has-text-grey-light is-size-7"> &nbsp; &nbsp;   </span> </BulmaForm.Label>

                                        <BulmaForm.Field kind="addons" className=" is-fullwidth ">
                                            <BulmaForm.Control >
                                                <Button disabled> {values.country ? countryAndMCodeforString(values.country).mcode : "Select Country"} </Button>
                                            </BulmaForm.Control>
                                            <BulmaForm.Control >
                                                <Field name='phone' type='phone' value={values.phone} className='input is-default is-fullwidth' placeholder="Phone no." />
                                            </BulmaForm.Control>
                                            {errors.phone && touched.phone && <BulmaForm.Help color="danger">{errors.password}</BulmaForm.Help>}
                                        </BulmaForm.Field>

                                    </BulmaForm.Field>
                                    <Columns className="is-mobile">
                                        <Columns.Column narrow>
                                            <BulmaForm.Field >
                                                <BulmaForm.Label textWeight="default" className="is-size-6p7 mt-4">Team Size</BulmaForm.Label>
                                                <BulmaForm.Control>
                                                    {/* <Field name='teamSize' type='number' min={1} max={10000} value={values.teamSize} className='input is-default' validate={validateNumber}  placeholder="1, 5, 10, 20, 100, 500 ..." /> */}
                                                    <Field as='select' className=' select is-size-6p7 little-border-shadow has-background-white ' validate={validateRequired} name='teamSize'>
                                                        <option value={""}></option>
                                                        <option value="1">Just me</option>
                                                        <option value="2 - 5">2 - 5</option>
                                                        <option value="5 - 10">5 - 10</option>
                                                        <option value="10 - 25">10 - 25</option>
                                                        <option value="25 - 50">25 - 50</option>
                                                        <option value="50 - 100">50 - 100</option>
                                                        <option value="100+">100+</option>
                                                    </Field>
                                                </BulmaForm.Control>
                                                {errors.teamSize && touched.teamSize && <BulmaForm.Help color="danger">{errors.teamSize}</BulmaForm.Help>}
                                            </BulmaForm.Field>
                                        </Columns.Column>
                                        <Columns.Column narrow>
                                            <BulmaForm.Field >
                                                <BulmaForm.Label textWeight="default" className="is-size-6p7 mt-4">Looking forward to</BulmaForm.Label>
                                                <BulmaForm.Control>
                                                    {/* <Field name='purpose' type='text'  value={values.purpose ?? ""} className='input is-default' validate={validateText}  placeholder="Visualise, Proofing, Caldendar, Tasks or All ..." /> */}
                                                    <Field as='select' className=' select little-border-shadow is-size-6p7 has-background-white ' validate={validateRequired} name='purpose'>
                                                        <option value={""}></option>
                                                        <option value="Just trying">Just trying</option>
                                                        <option value="Visualise">Visualise</option>
                                                        <option value="Content Proofing">Content Proofing</option>
                                                        <option value="Calendar">Calendar</option>
                                                        <option value="Tasks">Tasks</option>
                                                        <option value="Designer Workflow">Designer Workflow</option>
                                                        <option value="Writer Workflow">Writer Workflow</option>
                                                        <option value="Manager Workflow">Manager Workflow</option>
                                                        <option value="Stakeholder Workflow">Stakeholder Workflow</option>
                                                        <option value="Everything">Everything</option>
                                                    </Field>
                                                </BulmaForm.Control>
                                                {errors.purpose && touched.purpose && <BulmaForm.Help color="danger">{errors.purpose}</BulmaForm.Help>}
                                            </BulmaForm.Field>
                                        </Columns.Column>
                                    </Columns>



                                    <Button disabled={isSubmitting || isValidating} loading={isSubmitting} type="submit" className="is-fullwidth mt-4" color="black"> Create workspace</Button>


                                </Form>

                                <br />
                                <br />
                                <Box className=" p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light has-background-transparent">
                                    <div className=" has-tex-centered">
                                        <Button className=" has-background-transparent is-text is-size-7 has-text-grey" size="small" onClick={props.clearToggles}  > Back. What is workspace ?</Button>

                                        <p className="mt-4">
                                            <span className="">  Have an invitaion? </span>
                                        </p>
                                        {/* <Button className=" has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.SignIn) }} > Join Workspace</Button> */}
                                        <Button className=" has-background-transparent " size="small" onClick={props.joinWorkspace}  > Join Workspace</Button>

                                    </div>
                                </Box>

                            </Box>
                        </ColumnAux>


                    )
                }
            }

        </Formik >
    )
}

export default CreateWorkspace