import Parse from 'parse'



// createStripeCustomerPortalSession > customerId, returnUrl > portalSessionUrl
export const createStripeCustomerPortalSession = (customerId, returnUrl, callback) => {

    var params = {
        "userObjectId": customerId,
        "returnUrl": returnUrl
    }

    Parse.Cloud.run('createStripeCustomerPortalSession', params)
        .then((result) => {
            console.log(`Stripe URL Response`)
            console.log(result)

            // sessionId: session.id,
            // priceId: priceId,
            // userObjId: serObjId

            if (result.portalSessionUrl) {
                callback(true, result.portalSessionUrl, "")
            } else {
                callback(false, result.portalSessionUrl, "Got success in createStripeCustomerPortalSession but session id is not found")
            }

        }).catch((error) => {
            let err = `Stripe createStripeCustomerPortalSession Response Func Error is : ${error.message}`
            console.log(err)
            callback(false, null, err)
        })

}

//alpha.testpost.app
// createStripeSession > priceId, quantity, userObjId
export const createStripeSession = (priceId, quantity, userObjId, userEmail, stripeCustId, trialDays, cancelUrl, successUrl, callback) => {
    var params = {
        "priceId": priceId,
        "quantity": quantity ?? 1,
        "userObjId": userObjId,
        "userEmail": userEmail,
        "stripeCustId": stripeCustId,
        "trialDays": trialDays,
        "cancelUrl": cancelUrl,
        "successUrl": successUrl
    }




    // sample success url
    // 'https://testpost.appsquare.in/plan-start-success?session_id={CHECKOUT_SESSION_ID}'

    Parse.Cloud.run('createStripeSession', params)
        .then((result) => {
            console.log(`Stripe Session Response`)
            console.log(result)

            // sessionId: session.id,
            // priceId: priceId,
            // userObjId: serObjId

            if (result.sessionId) {
                alert("Session Id = " + result.sessionId)
                callback(true, result.sessionId, "")
            } else {
                callback(false, result, "Got success but session id is not found")
            }

        }).catch((error) => {
            let err = `Stripe Session Response Func Error is : ${error.message}`
            console.log(err)
            callback(false, null, err)
        })

}


export const createPresignedUrl = async (fileName) => {
    const params = {
        "fileName": fileName,
    }
    console.log("Sending PreSignedin URl Request with filename = " + fileName)

    try {
        let result = await Parse.Cloud.run('createS3PresignedUrl', params)
        console.log("PreSignedin URl Request concluded")
            console.log(`Create Signed Url Response`)
            console.log(result)
            // sessionId: session.id,
            // priceId: priceId,
            // userObjId: serObjId

            if (result) {
                // alert("Presigned Url is = " + result.url)
                // callback(true, result.url, "")
            } else{
                alert("Presigned Url could not be created = " + result.url)
            }
            return result
    } catch (error) {
        let err = `Create Presigned Url Error is : ${error.message ?? "no error message details available"}`
        console.log(err)
        // callback(false, null, err)
    }
}


export const createdSignedUrl = (fileName, callback) => {
    var params = {
        "fileName": fileName,
    }

    // sample success url
    // 'https://testpost.appsquare.in/plan-start-success?session_id={CHECKOUT_SESSION_ID}'

    console.log("Sending PreSignedin URl Request with filename = " + fileName)

    
    // Parse.Cloud.run('createS3PresignedUrl', params)
    Parse.Cloud.run('createS3PresignedPost', params)

        .then((result) => {
            console.log("PreSignedin URl Request concluded")

            console.log(`Create Signed Url Response`)
            console.log(result)

            // sessionId: session.id,
            // priceId: priceId,
            // userObjId: serObjId

            if (result.success) {
                alert("Presigned Url is = " + result.url)
                callback(true, result.url, "")
            } else {
                callback(false, result.url, result.errMsg)
            }

        }).catch((error) => {
            let err = `Create Presigned Url Error is : ${error.message ?? "no error message details available"}`
            console.log(err)
            callback(false, null, err)
        })

}