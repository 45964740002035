import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import reactDom from 'react-dom';
import ImageMarker, { MarkerComponentProps } from 'react-image-marker';
import { RiRecordCircleFill } from 'react-icons/ri';
import { FaRegCircle } from 'react-icons/fa';
import { FiMaximize } from 'react-icons/fi';


import { BiRadioCircleMarked, BiRectangle, BiSquareRounded } from 'react-icons/bi';
import { GrTarget } from 'react-icons/gr';
import { IoScanCircleOutline, IoRadioButtonOnSharp, IoRemoveCircle, IoRemoveCircleOutline, IoTrashBinOutline } from 'react-icons/io5';
import { TiDeleteOutline } from 'react-icons/ti';
import { MdRadioButtonUnchecked } from 'react-icons/md';
import { ImRadioUnchecked, ImCheckmark2 } from 'react-icons/im';


// IoRadioButtonOnSharp was originally used for annotations



import { randomCode } from '../../utilities/Utilities'
import { Box, Tag, Button, Dropdown, Form, Heading } from 'react-bulma-components';
import { Annotation } from '../../Models/Annotaion';
import { AnnotationType, AnnotationShowType, AssetType, AnnotationHoveredFrom } from '../../Models/EnumsAndPointers';
import ReactTooltip from 'react-tooltip';
import { Popover } from '@varld/popover';
import { useInView } from 'react-hook-inview'
import { prop } from 'dom7';
import moment from 'moment';



var randomColor = require('randomcolor'); // import the script
var noteIdPreString = "anntNote"

const AnnotatableImage = (props) => {



    const imageCompRef = React.useRef(null)
    // const [imageDimension, setImageDimension] = useState(null);

    const [overrideWidth, setOverrideWidth] = useState(null)

    const [toShowMarkableImage, setToShowMarkableImgae] = useState(false)

    const [loaded, setLoaded] = useState(false)

    // const [annotations, setAnnotations] = useState(props.annotations)
    const [ref, isVisible] = useInView({
        threshold: 1,
    })

    const [started, setStarted] = useState(false)




    useEffect(() => {
        let mounted = true
        if (!started) {
            if (mounted) {
                setStarted(true)
            }
            console.log("INITIAL START");
        }

        console.log(`IS VISIBLE =  ${isVisible}`);


        if (started && isVisible) {
            console.log("START IS TRUE");
            console.log(`IS VISIBLE =  ${isVisible}`);


            setTimeout(() => {
                if (started && isVisible) {
                    console.log("SETTING TO SHOW MARKABLE IMAGE");
                    if (mounted) {
                        setToShowMarkableImgae(true)
                    }

                } else {
                    // started = false
                    // if (toShowMarkableImage) {
                    //     setToShowMarkableImgae(false)
                    // }
                    console.log("SETTING EVERYTHING FALSE");
                }
            }, 3000)
        }

        return () => (mounted = false)
    }, [isVisible, started])

    // useEffect(() => {
    //     if (overrideWidth) {
    //         console.log("Override width is updated = " + overrideWidth)
    //     }
    // }, [overrideWidth])

    useLayoutEffect(() => {

        console.log("\n\n\n ------------ LAYOUT EFFECTED ------------ ")

        if (toShowMarkableImage === true) {
            const getRenderedSize = (contains, cWidth, cHeight, width, height, pos) => {
                var oRatio = width / height,
                    cRatio = cWidth / cHeight;
                return function () {
                    if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
                        this.width = cWidth;
                        this.height = cWidth / oRatio;
                    } else {
                        this.width = cHeight * oRatio;
                        this.height = cHeight;
                    }
                    this.left = (cWidth - this.width) * (pos / 100);
                    this.right = this.width + this.left;
                    return this;
                }.call({});
            }

            const getImgSizeInfo = (img) => {
                var pos = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
                return getRenderedSize(true,
                    img.width,
                    img.height,
                    img.naturalWidth,
                    img.naturalHeight,
                    parseInt(pos[0]));
            }
            // let imgDivs = null

            // if (isVisible ){
            let imgDivs = reactDom.findDOMNode(imageCompRef.current).getElementsByClassName("image-marker__image")
            if (imgDivs) {
                if (imgDivs.length) {
                    const thisImg = imgDivs[0]
                    thisImg.addEventListener("load", (evt) => {
                        console.log("LOAD HAPPENED")
                        setLoaded(true)
                        console.log(evt)
                        if (evt) {
                            if (evt.target) {
                                if (evt.target.naturalWidth && evt.target.naturalHeight) {
                                    // setImageDimension({ width: evt.target.naturalWidth, height: evt.target.naturalHeight })
                                    // console.log("GETTING IMAGE DIMENSIONS")
                                    let renderedImgSizeInfo = getImgSizeInfo(evt.target)
                                    if (renderedImgSizeInfo) {
                                        // console.log(renderedImgSizeInfo);
                                        setOverrideWidth(Math.round(renderedImgSizeInfo.width))
                                    }
                                    // console.log(`Width = ${evt.target.naturalWidth}, Height=${evt.target.naturalHeight}, src = ${evt.src}`)
                                }
                            }
                        }

                    })

                }
            }

            // }




            function updateSize() {
                if (!imgDivs) {
                    return
                }
                const thisImg = imgDivs[0]
                if (thisImg) {
                    let renderedImgSizeInfo = getImgSizeInfo(thisImg)
                    if (renderedImgSizeInfo) {
                        // console.log(renderedImgSizeInfo);
                        setOverrideWidth((prev) => {
                            return null
                        })

                        setTimeout(() => {
                            setOverrideWidth((prev) => {
                                return setOverrideWidth(Math.round(renderedImgSizeInfo.width))
                            })
                        }, 390);

                    }
                }
            }
            window.addEventListener('resize', updateSize);
            // updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }

    }, [toShowMarkableImage])


    //     useLayoutEffect(() => {
    //         if (imageCompRef.current) {

    //             let newDim = {
    //                 width: imageCompRef.current.offsetWidth,
    //                 height: imageCompRef.current.offsetHeight
    //             }

    //             console.log("New Dim are")
    //             console.log(newDim)

    //             setImageDimension(newDim);
    // }
    //     }, []);


    // const [annotation, setAnnotation] = useState({})
    // const [allAnnotations, setAllAnnotations] = useState([])


    // const annotationChanged = (anntt) => {
    //     setAnnotation(anntt)
    // }

    // const annotationSubmitted = (anntt) => {
    //     const { geometry, data } = anntt
    //     let newAnntt = {
    //         geometry,
    //         data: {
    //             ...data,
    //             id: randomCode(8)
    //         }
    //     }
    //     let newAllAnntts = [...allAnnotations, newAnntt]
    //     setAllAnnotations(newAllAnntts)
    //     setAnnotation({})
    // }



    const changeNoteInAnnotation = (annId, nt) => {
        props.annotationNoteChanged(annId, nt)
    }



    const customMarker_withTextAreaIn_ReactTooltip = (customMarkerProps) => {
        console.log("Custom marker props are ")
        console.log(customMarkerProps)



        if (customMarkerProps.addedOn) {
            // console.log("ADDED ON IS THERE");

            const now = moment(); // create a moment with the current time
            const addedOn = moment(customMarkerProps.addedOn).unix(); // create a moment with the other time timestamp in seconds
            const delta = now.diff(addedOn, 'milliseconds'); // get the millisecond difference

            console.log("ADDED ON TIMEIS ");
            console.log(customMarkerProps.addedOn);
            console.log("ADDED ON UNIX IS ");
            console.log(addedOn);

            console.log("DELTA IS ");
            console.log(delta);
        }



        const noteId = noteIdPreString + customMarkerProps.id

        return (
            <div className="custom-marker " data-for={"Annotate-" + customMarkerProps.id} data-tip={customMarkerProps.color ?? customMarkerProps.colorHex}>
                {/* <FaRegCircle >
                </FaRegCircle> */}

                {/* <BiRadioCircleMarked className="mb-0 is-size-2" />{props.itemNumber} */}

                {/* <Box className="m-0 p-2" backgroundColor="primary" >
                    <p className="">{props.itemNumber}</p>
                </Box> */}

                {/* <Tag rounded color="danger" colorVariant="dark" className="">
                    <p className="">{props.itemNumber}</p>
                </Tag> */}
                {/* <div key={customMarkerProps.id} className={customMarkerProps.isHovered ? "p-0 hover-pulse " : "p-0"}  onMouseEnter={() => { props.annotationHovered(true, customMarkerProps.id) }} onMouseLeave={() => { props.annotationHovered(false, customMarkerProps.id) }}> */}


                <div key={customMarkerProps.id} className={customMarkerProps.isHovered ? "p-0 hover-pulse " : "p-0"} >
                    <IoRadioButtonOnSharp style={{ color: customMarkerProps.color ?? customMarkerProps.colorHex }} className="m-0 p-0 opacity75 is-size-3  " />
                    {/* <Button className="opacity50 has-background-transparent drop-shadow p-0 m-0" remove onClick={() => { props.annotationRemove(customMarkerProps.id) }} /> */}
                    {/* <Box style={{backgroundColor: customMarkerProps.color, float: "left"}} className="">
                        Some text here
                    </Box> */}
                    {/* <p className="is-size-9 has-text-grey-lighter  ml-minus-18  mt-minus-10">{customMarkerProps.id}</p> */}
                </div>

                <ReactTooltip

                    id={"Annotate-" + customMarkerProps.id}
                    getContent={(dataTip) =>
                        <Box shadowless className="has-background-transparent p-0 m-0 z-1000">

                            {
                                (props.annotationEditable) ?
                                    <Button size="small" rounded className="mt-minus-10 m-0  is-hover-dark has-background-transparent is-borderless p-0 px-2" onClick={() => { props.annotationRemove(customMarkerProps.id) }} >
                                        <IoTrashBinOutline className="is-size-6 p-0 m-0" />
                                    </Button>
                                    : null
                            }

                            <Form.Field className="mx-0 mb-0 mt-minus-10">
                                <Form.Control className="m-0">
                                    <Form.Textarea
                                        id={noteId}
                                        show
                                        readOnly={!props.annotationEditable}
                                        fixedSize
                                        placeholder="Add note here..."
                                        textWeight="bold"
                                        textColor="black"
                                        maxLength={124}
                                        textSize={7}
                                        value={customMarkerProps.note ?? ""}
                                        className="m-0 mt-4 minW200 has-background-transparent is-borderless nofocus border-radius-21"
                                    />
                                </Form.Control>
                            </Form.Field>
                            <br />
                        </Box>
                    }
                    offset={{ top: 10 }}
                    effect='solid'
                    delayHide={50}
                    delayShow={50}
                    delayUpdate={50}
                    place={'right'}
                    border={false}
                    type={'light'}
                    backgroundColor={customMarkerProps.color ?? customMarkerProps.colorHex}
                    clickable={true}
                    className="border-radius-31 opacity-full"
                    afterHide={
                        () => {
                            // get value of this note and send for change 
                            let thisAnntNote = document.getElementById(noteId).value
                            changeNoteInAnnotation(customMarkerProps.id, thisAnntNote)
                        }
                    }
                />

            </div>
        );
    };



    const customMarker = (customMarkerProps) => {
        // console.log("Custom marker props are ")
        // console.log(customMarkerProps)


        // console.log("Custom marker props are ")
        // console.log(customMarkerProps)


        // A fix.. to show initial added animation for chrome browser
        let showAddingAnimationOnChrome = false

        if (customMarkerProps.addedOn && customMarkerProps.isHovered === false) {
            // console.log("ADDED ON IS THERE");
            const now = moment()
            const addedOn = moment(customMarkerProps.addedOn);
            let difference = 0

            if (now.isSame(addedOn, 'day')) {
                if (now.format('HH:mm:ss') === addedOn.format('HH:mm:ss')) {
                    difference = now.diff(addedOn)
                    if (difference) {
                        if (difference !== 0) {
                            console.log("Millisecond difference is");
                            console.log(difference);
                            if (difference <= 300) {
                                showAddingAnimationOnChrome = true
                            }
                        }
                    }
                }
            }

            // const delta = addedOn.diff(now, 'milliseconds'); // get the millisecond difference
            // console.log("ADDED ON TIMEIS ");
            // console.log(customMarkerProps.addedOn);
            // console.log("ADDED ON UNIX IS ");
            // console.log(addedOn);
            // console.log("DELTA IS ");
            // console.log(delta);
        }



        return (
            <div className="custom-marker " >
                {/* <FaRegCircle >
                </FaRegCircle> */}

                {/* <BiRadioCircleMarked className="mb-0 is-size-2" />{props.itemNumber} */}

                {/* <Box className="m-0 p-2" backgroundColor="primary" >
                    <p className="">{props.itemNumber}</p>
                </Box> */}

                {/* <Tag rounded color="danger" colorVariant="dark" className="">
                    <p className="">{props.itemNumber}</p>
                </Tag> */}
                {/* <div key={customMarkerProps.id} className={customMarkerProps.isHovered ? "p-0 hover-pulse " : "p-0"}  onMouseEnter={() => { props.annotationHovered(true, customMarkerProps.id) }} onMouseLeave={() => { props.annotationHovered(false, customMarkerProps.id) }}> */}

                <div id={customMarkerProps.id} key={customMarkerProps.id} className={customMarkerProps.isHovered ? "p-0 hover-pulse " : (showAddingAnimationOnChrome === true ? " p-0 hover-pulse " : "p-0 ")} onMouseEnter={() => { props.annotationHovered(true, customMarkerProps.id, "image", null, null, customMarkerProps.lastHovered ?? null) }} onMouseLeave={() => { props.annotationHovered(false, customMarkerProps.id) }}>

                    {/* <div key={customMarkerProps.id} className={customMarkerProps.isHovered ? "p-0 hover-pulse " : "p-0"}  */}
                    <ImRadioUnchecked style={{ color: customMarkerProps.color ?? customMarkerProps.colorHex }} className="m-0 p-0 drop-shadow is-size-3    " />

                    {
                        (props.annotationEditable) ?
                            <Button className="opacity25 is-hover-black is-hover-fullOpacity drop-shadow p-0 m-0" remove onClick={() => { props.annotationRemove(customMarkerProps.id) }} />
                            : null
                    }

                    {/* <Box style={{backgroundColor: customMarkerProps.color, float: "left"}} className="">
                        Some text here
                    </Box> */}
                    {/* <p className="is-size-9 has-text-grey-lighter  ml-minus-18  mt-minus-10">{customMarkerProps.id}</p> */}
                </div>
            </div>
        );
    };


    const addAnnotation = (marker) => {
        let newAnn = new Annotation()
        // const randomCode = randomCode(6)
        newAnn.id = randomCode(6)
        newAnn.type = AnnotationType.tag
        newAnn.assetType = AssetType.image
        newAnn.assetId = props.asset.id
        newAnn.assetVersionId = props.asset.currentVersionId ?? ""
        newAnn.top = marker.top
        newAnn.left = marker.left
        newAnn.isHovered = true  // it starts from hovering automatically
        newAnn.isHoveredFrom = AnnotationHoveredFrom.asset
        newAnn.lastHovered = new Date()
        newAnn.addedOn = new Date()
        newAnn.color = randomColor({ luminosity: 'light' })
        // {...marker, id: randomCode(4)}

        console.log("New Marker is")
        console.log(newAnn)

        // TO make initial entry animation work in chrome 
        // setTimeout(() => {
        //     let nnt = document.createElement(noteIdPreString + randomCode);
        //     if ()


        // }, 250)

        // create Annotation from marker
        // setAnnotations([...props.annotations, newMark])
        props.annotationAdd(newAnn)
    }

    const imgView = () => {



        // console.log("INCOMING PROPS ANNOTAIONS ARE")
        // console.log(props.annotations)

        let comp =
            <div className=" " style={{ width: overrideWidth !== 0 ? overrideWidth : "94%", height: "94%", margin: "auto" }}>
                {/* <div className=' swiper-zoom-container '> */}
                {/* <div className=" image-marker-copy swiper-zoom-target "> */}
                <div className=" image-marker-copy ">

                    <img
                        alt={props.asset.name}
                        // src={props.asset.url}
                        data-src={props.asset.url}
                        // className="maxH90p fadeIn object-fit-contain is-centered object-position-center-xyntered "
                        className="swiper-lazy   fadeIn  object-fit-contain object-position-center-xy image-marker__image"
                    />
                </div>

                {/* </div> */}
            </div>


        if (toShowMarkableImage === true && isVisible) {
            console.log("SHOWING MARKABLE IMAGE for = " + props.asset.name);
            comp = <div
                className=" "
                style={{ width: overrideWidth !== 0 ? overrideWidth : "94%", height: "94%", margin: "auto" }}
                ref={imageCompRef}
            >
                <ImageMarker
                    src={props.asset.url}
                    // data-src={props.asset.url}
                    markers={[...props.annotations]}
                    onAddMarker={(marker) => { if (props.shouldComposeAnnotations) { addAnnotation(marker) } }}
                    markerComponent={customMarker}
                    className="  object-fit-contain object-position-center-xy image-marker__image"
                    extraClass="  object-fit-contain object-position-center-xy"
                // className="swiper-lazy maxH90p fadeIn object-fit-contain is-centered object-position-center-xyntered image-marker__image"
                />

            </div >
        }
        return <>  <div style={{ width: "1px", height: "1px", margin: "auto" }} ref={ref}></div> {comp} </>



        // return <img
        //     alt={props.asset.name}
        //     // src={props.asset.url}
        //     data-src={props.asset.url}
        //     markers={annotations}
        //     onAddMarker={(marker) => setAnnotations([...annotations, marker])}
        //     // className="maxH90p fadeIn object-fit-contain is-centered object-position-center-xyntered "
        //     className="swiper-lazy maxH90p fadeIn object-fit-contain is-centered object-position-center-xyntered image-marker__image"
        // />



        // {/* <Annotation
        //     src={props.asset.url}
        //     alt={props.asset.name}
        //     annotations={allAnnotations}
        //     type={RectangleSelector.type}
        //     value={annotation}
        //     onChange={annotationChanged}
        //     onSubmit={annotationSubmitted}
        //     allowTouch
        // /> */}
    }


    return imgView()

}

export default AnnotatableImage