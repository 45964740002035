import React, { useRef, useState } from 'react'
import { Form, Card, Heading, Media, Content, Tabs, Box, Image, Tag, Button, Dropdown, Modal, Panel, Columns, Hero, Container, Message, Level, Section, Navbar } from 'react-bulma-components';
import { previewBoxType, SocialMedia_PostType, AssetCaptionType, SocialMedia_Platform } from '../../Models/EnumsAndPointers';

import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
import Default_BG from './sampleAssets/Default-BG.png'
import Default_DP from './sampleAssets/Default-DP.png'
import instaStoryShare from './sampleAssets/insta-story-share.png'




 export const common_storyCard = (dpImge, postImage, userName, storyFill, setStoryFill) => {
        return <Card shadowless className=" insta-story-box-fixed-size has-background-black overflow-hidden is-centered  m-0 p-0 "  >
            <Card.Content className="has-background-transparent radiusless m-0 p-0">
                <Box shadowless radiusless className="has-background-transparent is-fullwidth z-35 m-0 pt-5 px-0 insta-story-Header-Container">
                    <Columns className="is-mobile pb-3 my-0 mx-2 is-fullwidth " >
                        <Columns.Column size={1} className="pt-0" >
                            <Media className="m-0">
                                <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                                    <Image
                                        src={dpImge ?? Default_DP}
                                        className="insta-post-dp-size has-background-transparent"
                                        rounded
                                    />
                                </Media.Item>
                            </Media>
                        </Columns.Column>
                        <Columns.Column className="py-0">
                            <Heading className="mt-2 ml-4  mb-0 has-text-white insta-fontSize14 is-lowercase " textWeight="semibold" >{userName ?? "User Name"} </Heading>
                        </Columns.Column>
                        {/* <Columns.Column  className="m-0 p-0 has-text-right" >
                        <Button.Group className=" m-0 p-0 ">
                            <Button size="small" className="has-background-transparent is-borderless m-0 p-0">
                                <img
                                    src={instaStoryDotMenu}
                                    alt="menu"
                                    className="li-iconSize24  figure "
                                /></Button>
                            <Button size="small" className="has-background-transparent is-borderless m-0 p-0  ">
                                <img
                                    src={instaStoryClose}
                                    alt="menu"
                                    className="li-iconSize24  figure "
                                /></Button>
                        </Button.Group>
                    </Columns.Column> */}
                    </Columns>
                </Box>
                {
                    postImage ?
                        <div className="insta-story-imageContainer is-bordered-y" onClick={() => { setStoryFill(!storyFill) }}  >
                            <img
                                src={postImage}
                                alt="post"
                                className={` ${storyFill ? "insta-story-image-cover" : " insta-story-image-contain"} `}
                            />
                        </div>
                        : null
                }
                <Box shadowless radiusless className="has-background-transparent z-35 m-0 p-0 insta-story-Footer-Container">

                    <Columns className="is-mobile m-0 p-0 insta-story-Footer-bottom" >
                        <Columns.Column className="pt-0 mr-0 pr-2"  >
                            <Button rounded className="has-background-transparent has-text-white" fullwidth> <span className="is-size-7"> Reply to testpost... </span> </Button>
                        </Columns.Column>
                        <Columns.Column className="py-0 ml-0 pl-2" narrow>
                            <Button size="small" className="has-background-transparent is-borderless m-0 p-0  is-pulled-right">
                                <img
                                    src={instaStoryShare}
                                    alt="menu"
                                    className="li-iconSize24 mt-3 mr-2 figure "
                                /></Button>
                        </Columns.Column>
                    </Columns>
                </Box>
            </Card.Content>
        </Card>
    }

  export const common_storyBox = (dpImge, postImage, userName, storyFill, setStoryFill) => {
        return <Columns className='m-0 has-background-transparent  m-0 p-0 '>
            <Columns.Column className=''  >
            </Columns.Column>
            <Columns.Column className="is-centered m-0 p-0" size={6}>
                <ColumnAux isMiddle={true} className="has-background-transparent m-0 p-0">
                    {common_storyCard(dpImge, postImage, userName, storyFill, setStoryFill)}
                </ColumnAux>
            </Columns.Column>
            <Columns.Column className='  m-0 p-0 ' >
            </Columns.Column>
        </Columns>
    }