import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';
import TpLoading from './assets/TpLoading.gif'
import { Button, Columns, Container, Heading, Section, Notification, Box, Content } from 'react-bulma-components';
import './App.css';
import Dashboard from './Pages/Dashboard';
import Sign from './Pages/Sign';
import { isCurrentUser, initialiseParse, getCurrentWorkspaceId } from './utilities/Login'
import Entry from './Pages/Entry';
import IsLoggedAux from './Containers/IsLoggedAux'


const App = () => {

  



  return (
    <div className="App " >
      <IsLoggedAux />
      {/* <Box shadowless radiusless className="is-fullwidth has-background-transparent h-100vh has-text-centered">
        <img alt="Testpost App loading" src={TpLoading} width="200px" height="200px" className="is-centered is-vcentered opacity50" />
      </Box> */}
    </div>
  );
}



export default App;
