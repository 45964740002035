import React, { useEffect, useRef, useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Container, Heading, Section, Content, Table, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon, Tag } from 'react-bulma-components';
import Avatar from '../Calendar/Avatar';
import Selector from '../Common/Selector';
import Filter from './TasksFilter';

import UserAvatar from '../Calendar/UserAvatar';
import TaskModal from './TaskModal';

import { MdAssignmentInd, MdRadioButtonUnchecked, MdOutlineCheckCircleOutline } from 'react-icons/md';
import { RiMoreFill, RiFolderLine } from 'react-icons/ri';
import { GrCheckbox, GrCheckboxSelected, GrFormAdd } from 'react-icons/gr';
import { FaUserCheck, FaRegUser, FaCheckCircle } from 'react-icons/fa';
import { BiTimeFive, BiLoaderCircle } from 'react-icons/bi';
import { RiFileUserFill } from 'react-icons/ri';

import { IoIosTime } from 'react-icons/io';
import { BsCircleFill } from 'react-icons/bs'
import { IoTimeSharp, IoAddSharp, IoCheckmarkDoneOutline } from 'react-icons/io5';
import { BsFileEarmarkPlus, BsImageAlt } from 'react-icons/bs';

import { CgImage, CgRadioCheck, CgCheckO } from 'react-icons/cg';
import { BiSort, BiFilter, BiSelectMultiple } from 'react-icons/bi'
import { Task, TaskFilter } from '../../Models/Task';
import { TaskType, TaskStatus, TaskSortOptions, TaskPriority } from '../../Models/EnumsAndPointers'
import moment from 'moment'
import TasksFilter from './TasksFilter';
import { currentUserInfo } from '../../utilities/Login';
import ColumnAux from '../../Containers/ColumnAux';
import Guide from '../Guides/Guide';
import TasksChecks_Img from '../../assets/Tasks-Checks.png'
import { privateTag } from '../../utilities/Utilities';










const Tasks = (props) => {

    const sortOptions = [
        {
            name: TaskSortOptions.createdDateAscending,
        },
        {
            name: TaskSortOptions.createdDateDescending,
        },
        {
            name: TaskSortOptions.typeToCreateFirst,
        },
        {
            name: TaskSortOptions.typeToUpdateFirst,
        }
    ]

    const TaskTABS = {
        all: "All",
        pending: "Pending",
        working: "Working",
        complete: "Complete",
        acrhived: "Archived"
    }


    const [showAddTask, setShowAddTask] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)

    const [showFilter, setShowFilter] = useState(false)
    const [showSorter, setShowSorter] = useState(false)
    const [filter, setFilter] = useState(new TaskFilter())
    const [sortBy, setSortBy] = useState(null)

    const [tasks, setTasks] = useState([])
    const [newTask, setNewTask] = useState(null)

    const [noTasks, setNoTasks] = useState(false)

    const [currentTab, setCurrentTab] = useState(TaskTABS.pending)
    const taskTopRef = useRef(null)



    useEffect(() => {
        let isMounted = true;
        console.log("Getting tasks")

        Task.getAllTasks(null, null, null, null, null, null, null, null, (succ, results, errMsg) => {
            if (succ) {
                // console.log("Got Tasks")
                // console.log(results)
                if (isMounted) {
                    if (results.length === 0) {
                        setNoTasks(true)
                    }
                    setTasks(results)
                }
            } else {
                console.log(errMsg)
            }
        })

        return () => (isMounted = false)

    }, [])

    const clearSelections = () => {
        setShowSorter(false)
        setShowFilter(false)
        setShowAddTask(false)
        setSelectedTask(null)
    }


    const scrollToTop = () => {
        // setTimeout(() => {

        //     if (taskTopRef.current) {
        //         taskTopRef.current.scrollIntoView({ behavior: 'instant',  block: 'start' })
        //     } else {
        //         const element = document.getElementById('taskTop')
        //         if (element) {
        //             element.scrollIntoView({ behavior: 'instant', block: 'start' });
        //         }
        //     }
        // }, 500)
    };

    const showSorterModal = () => {
        setShowSorter(true)
    }

    const sortOptionSelected = (opt) => {
        scrollToTop()
        setSortBy(opt)

        // sort tasks

        if (opt !== null) {

            console.log("Filtering Tasks");

            let allTasks = [...tasks]

            if (opt.name === TaskSortOptions.createdDateAscending) {
                console.log("Filtering createdDateAscending");

                allTasks = allTasks.sort((a, b) => {
                    return a.createdAt > b.createdAt
                })
            }

            if (opt.name === TaskSortOptions.createdDateDescending) {
                console.log("Filtering createdDateDescending");

                allTasks = allTasks.sort((a, b) => {
                    return a.createdAt < b.createdAt
                })
            }

            if (opt.name === TaskSortOptions.typeToCreateFirst) {
                console.log("Filtering typeToCreateFirst");

                allTasks = allTasks.sort((a, b) => {
                    return a.type === TaskType.update
                })
            }

            if (opt.name === TaskSortOptions.typeToUpdateFirst) {
                console.log("Filtering typeToUpdateFirst");

                allTasks = allTasks.sort((a, b) => {
                    return a.type === TaskType.create
                })
            }

            setTasks(() => {
                return allTasks
            })

            console.log("Filtered Tasks");
            console.log(allTasks);

        }


        clearSelections()
    }


    const filterChanged = (ft) => {
        setFilter(ft)
        scrollToTop()
    }

    const showFilterModal = () => {
        setShowFilter(true)
    }

    const showAddTaskNow = () => {
        setShowAddTask(true)
    }

    const taskIsSelected = (tsk) => {
        selectedTask(tsk)
    }

    const changeCurrentTab = (tb) => {
        setCurrentTab(tb)
        scrollToTop()
    }


    const getFilteredTasksByTab = (tab, countOnly) => {
        let allTasks = [...tasks]
        let filteredTasks = []

        // console.log("ALL PROJECTS ARE");
        // console.log(allProjs);

        if (tab && tab !== TaskTABS.all) {


            filteredTasks = allTasks.filter((a) => {
                if (tab === TaskTABS.pending) {
                    // console.log("tab is ProjectTABS.pending");
                    // console.log(`${a.status} is NOT ${ProjectStatus.pending}`);
                    return a.status === TaskStatus.pending
                }
                if (tab === TaskTABS.working) {
                    // console.log("tab is ProjectTABS.pending");
                    // console.log(`${a.status} is NOT ${ProjectStatus.pending}`);
                    return a.status === TaskStatus.working
                }
                if (tab === TaskTABS.complete) {
                    return a.status === TaskStatus.complete
                }

                if (tab === TaskTABS.acrhived) {
                    return a.isArchived === true
                }
                return false
            })

            // console.log("FILTERED PROJECTS ARE 1");
            // console.log(filteredProjs);

        } else {
            filteredTasks = allTasks
        }

        // console.log("FILTERED PROJECTS ARE");
        // console.log(filteredProjs);

        // removing archived assets
        if (tab !== TaskTABS.acrhived) {
            filteredTasks = filteredTasks.filter((a) => { if (a.isArchived === true) { return false }; return true })
        }



        //BY FILTERS
        if (filter.isActive()) {
            if (filter.assignedTo.length > 0) {

                console.log("Filter Assign to is ")
                console.log(filter)

                let usrsIds = filter.assignedTo.map((u) => { return u.id })
                // USER IDs [a, b, c]



                filteredTasks = filteredTasks.filter((ast) => {

                    let commonFound = ast.assignedTo.some(a => usrsIds.includes(a))
                    console.log("COMMON FOUND IS")
                    console.log(commonFound)
                    return commonFound
                })


                console.log("FILTERED TASK AFTER ASSIGN TO")
                console.log(filteredTasks)

            }




            if (filter.addedBy.length > 0) {
                let usrsIds = filter.addedBy.map((u) => { return u.id })
                filteredTasks = filteredTasks.filter((ast) => {
                    let isAddedByCommon = usrsIds.includes(ast.byUserInfo.id)
                    return isAddedByCommon
                })
            }

            console.log("FILTER IS")
            console.log(filter)



            if (filter.type && filter.type !== TaskType.all) {
                filteredTasks = filteredTasks.filter((ast) => {
                    return ast.type === filter.type
                })
            }

            if (filter.status && filter.status !== TaskStatus.all) {
                filteredTasks = filteredTasks.filter((ast) => {
                    return ast.status === filter.status
                })
            }
        }

        return (countOnly ? filteredTasks.length : filteredTasks)
    }



    const task_boxed = (done, create) => {
        return <Box shadowless className={" is-bordered-light has-background-white-bis p-3 is-hover-dark  " + (done ? "opacity35" : "")}>
            <Columns className="is-mobile">
                <Columns.Column narrow>
                    {
                        create ?
                            <Box shadowless className="square-48 ml-0 m-0 p-1 has-text-centered is-bordered-light border-radius-6 has-background-transparent ">
                                <BsFileEarmarkPlus className="has-text-grey-lighter is-size-5 mt-1 mb-0 " />
                                <div className="is-size-9 has-text-grey-light mt-minus-4 "> CREATE </div>
                            </Box>
                            :
                            <Image size={48} src="https://images.unsplash.com/photo-1627482965115-e8e29bbcd451?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" className=" ml-0 overflow-hidden is-bordered border-radius-6 " />

                    }
                    {/* <Avatar className="ml-2" small imageUrl="https://randomuser.me/api/portraits/women/93.jpg" /> */}
                    {/* <span className="is-size-6p4 has-text-grey is-centered  ml-2" >   </span> */}
                </Columns.Column>
                <Columns.Column>
                    <span className="is-size-6p4"> Tityre, tu patulae recubans sub tegmine fagi dolor. Inmensae subtilitatis, obscuris et malesuada fames.  </span>
                    {/* <div className="has-text-grey-light is-size-7 ">Assigned: <span className="has-text-weight-medium ">Candida Viegas</span>  </div> */}

                    <div className="has-text-grey-light is-size-7 has-text-weight-medium ">
                        <span className=" mr-4 "><FaUserCheck className="pt-2 is-size-5p4" /> Candida Viegas</span>
                        <span className=" mr-4"><IoTimeSharp className="pt-15 is-size-5p2 mb-minus-009rem" /> 1·Aug·2021</span>
                        <span className=" mr-2"><RiFolderLine className="pt-15 is-size-5p2 mb-minus-009rem mr-0 pr-0" /> Social Media Posts</span>

                    </div>

                    {/* <span className="is-size-6p4 has-text-grey " >  <Avatar render small imageUrl="https://randomuser.me/api/portraits/women/93.jpg" />  </span> */}
                    {/* <div className="" >
                                <span className="is-size-7  has-text-grey " > <UserAvatar infoObj={{ id: "25872573", name: "Candida Viegas", imageUrl: "https://randomuser.me/api/portraits/women/93.jpg" }} small={true} clearBg={true} /> </span>
                                <span className="is-size-7  has-text-grey mt-minus-10">Deadline</span>
                            </div> */}
                </Columns.Column>
                <Columns.Column className="py-0" narrow>
                    <div className=" m-0 px-0 pb-0  has-text-grey">
                        <div>
                            <Button size="small" className=" p-1 mt-1 is-hover-dark is-borderless has-background-transparent ">
                                <RiMoreFill className="is-size-5  " />
                            </Button>
                        </div>
                        <div>
                            <Button size="small" className=" p-1 mt-1 is-hover-dark  is-borderless has-background-transparent ">
                                <GrCheckbox className="is-size-5 " />
                            </Button>
                        </div>
                    </div>
                </Columns.Column>
            </Columns>
        </Box>
    }

    const taskSelected = (tsk) => {
        setSelectedTask(tsk)
    }

    const taskIsAdded = (tk) => {
        let tsks = [...tasks]
        tsks.unshift(tk)
        setTasks(tsks)
    }

    const taskIsUpdated = (tk) => {
        let tsks = [...tasks]
        let index = tsks.findIndex((t) => {
            return t.id === tk.id
        })
        if (index >= 0) {
            tsks[index] = tk
            setTasks(tsks)
        }
    }


    const taskStatusChanged = (tk, status) => {
        let tsks = [...tasks]
        let index = tsks.findIndex((t) => {
            return t.id === tk.id
        })
        if (index >= 0) {
            let thisTask = tsks[index]
            thisTask.isUpdating = true
            // update task in backend
            tsks[index] = thisTask
            setTasks((oldVal) => {
                return tsks
            })
            updateTaskInDB(thisTask, status)
        }
    }


    const updateTaskInDB = (tsk, status) => {
        let tkOriginal = tsk
        let tk = Task.copyFrom(tsk)
        tk.status = status

        if (status === TaskStatus.pending) {
            tk.markPending((succ, result, errMsg) => {
                let thisTask = result
                thisTask.isUpdating = false
                if (succ) {
                    // Updated succesfully
                } else {
                    thisTask.status = tkOriginal.status
                    alert("Error")
                    console.log("LOGGING ERROR = " + errMsg)
                }
                taskIsUpdated(thisTask)
            })
        }

        if (status === TaskStatus.working) {
            tk.markWorking((succ, result, errMsg) => {
                let thisTask = result
                thisTask.isUpdating = false
                if (succ) {
                    // Updated succesfully
                } else {
                    thisTask.status = tkOriginal.status
                    alert("Error")
                    console.log("LOGGING ERROR = " + errMsg)
                }
                taskIsUpdated(thisTask)
            })
        }

        if (status === TaskStatus.complete) {
            tk.markComplete((succ, result, errMsg) => {
                let thisTask = result
                thisTask.isUpdating = false
                if (succ) {
                    // Updated succesfully
                } else {
                    thisTask.status = tkOriginal.status
                    alert("Error")
                    console.log("LOGGING ERROR = " + errMsg)
                }
                taskIsUpdated(thisTask)
            })
        }
    }


    const task_row = (thisTask) => {

        let tk = Task.copyFrom(thisTask)
        let imageUrl = null

        let isPrivate = true
        if (tk.sharedWith.length || tk.assignedTo.length) {
            isPrivate = false
        }
        let assignedToName = ""

        if (tk.thumbImageUrls.length > 0) {
            imageUrl = tk.thumbImageUrls[0]
        }

        if (tk.assignedToInfo.length) {
            assignedToName = tk.assignedToInfo[0].name
            if (tk.assignedToInfo.length > 1) {
                assignedToName += ` + ${tk.assignedToInfo.length - 1} others`
            }
        }



        // return <Box shadowless radiusless className={" is-bordered-bottom-1  p-4 is-hover-dark mb-0  " + (tk.isComplete ? "opacity35" : "")}>
        return <Box shadowless radiusless className={"  p-4 is-hover-dark mb-0 has-background-transparent backblurWithSiteBg " + (tk.isComplete ? "opacity35" : "")}>

            <Columns className="is-mobile">
                <Columns.Column narrow onClick={() => { taskSelected(thisTask) }}>
                    {
                        tk.type === TaskType.create ?
                            <Box shadowless className="square-48 ml-0 m-0 p-1 has-text-centered is-bordered-light border-radius-6 has-background-transparent ">
                                <BsFileEarmarkPlus className="has-text-grey-lighter is-size-5 mt-1 mb-0 " />
                                <div className="is-size-9 has-text-grey-light mt-minus-4 "> CREATE </div>
                            </Box>
                            :
                            <div>
                                {
                                    imageUrl ?
                                        // <Image size={48} src="https://images.unsplash.com/photo-1627482965115-e8e29bbcd451?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" className=" ml-0 overflow-hidden is-bordered border-radius-6 " />
                                        <Image size={48} src={imageUrl} className=" ml-0 overflow-hidden is-bordered border-radius-6 has-background-grey" />
                                        :
                                        <span className=" has-text-grey-lighter "><BsImageAlt className="pt-2 is-size-1 " /> </span>
                                }


                            </div>
                    }
                    {/* <Avatar className="ml-2" small imageUrl="https://randomuser.me/api/portraits/women/93.jpg" /> */}
                    {/* <span className="is-size-6p4 has-text-grey is-centered  ml-2" >   </span> */}
                </Columns.Column>

                <Columns.Column onClick={() => { taskSelected(thisTask) }}>
                    <span className="is-size-6p4 text-lines-3 "> {tk.title}  </span>
                    {/* <div className="has-text-grey-light is-size-7 ">Assigned: <span className="has-text-weight-medium ">Candida Viegas</span>  </div> */}

                    <div className="has-text-grey-light is-size-7 has-text-weight-medium ">
                        {/* <span className=" mr-4 "><FaRegUser className="pt-2 is-size-5p4" /> {tk.byUserInfo.name}</span> */}
                        {tk.priority === TaskPriority.urgent ? <span className='mr-3 has-text-danger '> <BsCircleFill /> </span> : null}
                        {tk.priority === TaskPriority.high ? <span className='mr-3 has-text-warning '> <BsCircleFill /> </span> : null}
                        {
                            isPrivate ? privateTag() : null
                        }
                        <span className=" mr-4 ">By {tk.byUserInfo.name}</span>



                        {
                            assignedToName !== "" ?
                                <span className=" mr-4 "><RiFileUserFill className="pt-2 is-size-5p4 mb-minus-009rem" /> {assignedToName}</span>
                                : null
                        }
                        <span className=" mr-4"><IoTimeSharp className="pt-15 is-size-5p2 mb-minus-009rem" />{moment(tk.createdAt).format('DD·MMM·YYYY')}</span>

                        {
                            tk.status === TaskStatus.complete ?

                                <span > <span className=" has-text-black "><IoCheckmarkDoneOutline /></span>  Completed By {tk.completedByInfo.name} </span>
                                : null
                        }


                    </div>

                    {/* <span className="is-size-6p4 has-text-grey " >  <Avatar render small imageUrl="https://randomuser.me/api/portraits/women/93.jpg" />  </span> */}
                    {/* <div className="" >
                                <span className="is-size-7  has-text-grey " > <UserAvatar infoObj={{ id: "25872573", name: "Candida Viegas", imageUrl: "https://randomuser.me/api/portraits/women/93.jpg" }} small={true} clearBg={true} /> </span>
                                <span className="is-size-7  has-text-grey mt-minus-10">Deadline</span>
                            </div> */}
                </Columns.Column>
                <Columns.Column className="py-0" narrow>
                    <div className=" m-0 px-0 pb-0  has-text-grey">
                        {/* <div>
                                <Button size="small" className=" p-1 mt-1 is-hover-dark is-borderless has-background-transparent ">
                                    <RiMoreFill className="is-size-5  " />
                                </Button>
                            </div>       */}
                        {
                            tk.status === TaskStatus.complete ?
                                <div>
                                    <Button rounded loading={tk.isUpdating} disabled={tk.isUpdating} size="medium" className={` mt-3  is-borderless ${tk.isUpdating ? " has-background-white " : "has-background-transparent  "}`} onClick={() => { taskStatusChanged(tk, TaskStatus.pending) }}>
                                        <FaCheckCircle className="is-size-4 " />
                                    </Button>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Complete</p>
                                </div>
                                :
                                null
                        }

                        {
                            tk.status === TaskStatus.working ?
                                <div>
                                    <Button rounded loading={tk.isUpdating} disabled={tk.isUpdating} size="medium" className={` mt-3  is-borderless ${tk.isUpdating ? " has-background-white " : "has-background-transparent"}`} onClick={() => { taskStatusChanged(tk, TaskStatus.complete) }}>
                                        <BiLoaderCircle className="is-size-4 " />
                                    </Button>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Working</p>
                                </div>
                                :
                                null
                        }

                        {
                            tk.status === TaskStatus.pending ?
                                <div>
                                    <Button rounded loading={tk.isUpdating} disabled={tk.isUpdating} size="medium" className={` mt-3  is-borderless ${tk.isUpdating ? " has-background-white " : "has-background-transparent"}`} onClick={() => { taskStatusChanged(tk, TaskStatus.working) }} >
                                        <CgRadioCheck className="is-size-4 " />
                                    </Button>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Pending</p>
                                </div>

                                :
                                null
                        }

                    </div>
                </Columns.Column>
            </Columns>
        </Box>
    }



    const taskComp = () => {


        let finalTasks = getFilteredTasksByTab(currentTab, false)

        let showNoTasks = false
        if (tasks.length === 0 && noTasks) {
            showNoTasks = true
        }


        return <div className="is-fullHeightAlways overflow-scroll">
            <div ref={taskTopRef} id="taskTop"></div>

            {showAddTask ? <TaskModal isNew={true} task={null} close={clearSelections} taskIsAdded={taskIsAdded} /> : null}
            {selectedTask ? <TaskModal task={selectedTask} close={clearSelections} taskIsUpdated={taskIsUpdated} /> : null}
            {showFilter ? <TasksFilter title="Filter By" filter={filter} filterChanged={filterChanged} close={clearSelections} /> : null}

            {showSorter ? <Selector title="Sort By" options={sortOptions} optionSelected={sortOptionSelected} selectedOption={sortBy} close={clearSelections} /> : null}
            <Columns className=" m-0 p-0 pb-2">
                <Columns.Column className="">

                    <Tabs type="boxed" textAlign="left" size="small" textWeight="semibold" className=" m-0 px-0  " >
                        <Tabs.Tab className="" active={currentTab === TaskTABS.all} onClick={() => { changeCurrentTab(TaskTABS.all) }} > <span className="px-4">{TaskTABS.all}  <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredTasksByTab(TaskTABS.all, true)} </span> </span>    </Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === TaskTABS.pending} onClick={() => { changeCurrentTab(TaskTABS.pending) }} > <span className="px-4">{TaskTABS.pending}  <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredTasksByTab(TaskTABS.pending, true)} </span> </span> </Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === TaskTABS.working} onClick={() => { changeCurrentTab(TaskTABS.working) }} > <span className="px-4">{TaskTABS.working}  <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredTasksByTab(TaskTABS.working, true)} </span> </span> </Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === TaskTABS.complete} onClick={() => { changeCurrentTab(TaskTABS.complete) }} > <span className="px-4">{TaskTABS.complete}  <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredTasksByTab(TaskTABS.complete, true)} </span> </span> </Tabs.Tab>
                        <Tabs.Tab className="" active={currentTab === TaskTABS.acrhived} onClick={() => { changeCurrentTab(TaskTABS.acrhived) }} > <span className="px-4">{TaskTABS.acrhived}  <span className=" has-text-grey-light px-2 has-text-weight-light "> {getFilteredTasksByTab(TaskTABS.acrhived, true)} </span> </span>  </Tabs.Tab>
                    </Tabs>

                </Columns.Column>
                <Columns.Column className=" has-text-right " narrow>
                    <Button.Group className="is-pulled-right mt-0" >
                        <Button backgroundColor={filter.isActive() ? "black" : "grey-lightest"} textColor={filter.isActive() ? "white" : "grey"} textWeight={filter.isActive ? "semibold" : "normal"} className=" is-borderless  " size="small" onClick={showFilterModal} > <BiFilter className="mr-1 is-size-6" /> {filter.isActive() ? "Filtered" : "Filter"} {filter.isActive() ? <Tag rounded className=" is-light has-text-weight-semibold py-1 ml-2" >{filter.count() ?? ""}</Tag> : null} </Button>
                        <Button backgroundColor={sortBy ? "black" : "grey-lightest"} textColor={sortBy ? "white" : "grey"} textWeight={sortBy ? "semibold" : "normal"} className=" is-borderless  " size="small" onClick={showSorterModal} > <BiSort className="mr-1" /> {sortBy ? "Sorted" : "Sort"}</Button>
                        <Button rounded className=" px-2 mx-4 is-pulled-right has-background-warning " size="small" onClick={showAddTaskNow} > <GrFormAdd className="  m-0 is-size-5" /> </Button>

                        {/* <Button className=" has-text-grey is-borderless is-hover-dark " size="small"> <BiSelectMultiple className="mr-1 is-size-6" /> Select</Button> */}
                    </Button.Group>
                </Columns.Column>
            </Columns>
            <Columns className="m-0 p-0 pt-2">
                <Columns.Column size={2}>
                </Columns.Column>
                <Columns.Column className="m-0 p-0">




                    <Box shadowless className="px-3 pt-1 has-background-transparent " >
                        <div className=" h72vh overflow-scroll">

                            <div className="mb-6 mt-0">
                                {/* <Button.Group className="is-pulled-right  " >
                                    <Button rounded className=" px-2 mx-4 is-pulled-right has-background-warning " size="small" onClick={showAddTaskNow} > <GrFormAdd className="  m-0 is-size-5" /> </Button>
                                </Button.Group> */}
                                <Heading size={6} className="" >{currentTab} Tasks{filter.isActive() ? ". Filtered." : null} </Heading>
                                <Heading spaced={false} textColor={"grey"} subtitle size={7} className="" >  {sortBy ? `Sorted by ${sortBy.name}` : ""} </Heading>
                            </div>
                            {/* {task_boxed(false, true)}
                            {task_boxed()}
                            {task_boxed()}
                            {task_boxed()}
                            {task_boxed()}
                            {task_boxed()}
                            {task_boxed()} */}

                            {

                                showNoTasks ?
                                    <>
                                        <ColumnAux size={6} isMiddle={true}>
                                            <Guide noVid={false} type="image" src={TasksChecks_Img} title="No tasks here" description="Click + buttton on top-right to create a task" boxClassName="is-shadowless" isDense={true} />
                                        </ColumnAux>

                                    </>

                                    :
                                    <>
                                        {
                                            finalTasks.length ?
                                                <Table className=" is-fullHeightAlways is-fullwidth  has-background-transparent " >
                                                    <tbody className='  ' >
                                                        {
                                                            finalTasks.map((tk, index) => {
                                                                return <tr index={index} className=" is-fullwidth p-0 maxH150   has-background-transparent  " ><td className=" is-fullwidth  p-0 "> {task_row(tk)} </td></tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                :
                                                <ColumnAux size={4} isMiddle={true}>
                                                    <p className='has-text-grey-light has-text-centered is-size-6 mt-6'>Nothing here</p>
                                                </ColumnAux>
                                        }
                                    </>
                            }




                            <br />
                            <br />
                            <br />

                            {/* {task_row(false, true)}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()}
                            {task_row()} */}

                        </div>


                    </Box>




                </Columns.Column>
                <Columns.Column size={2}>
                </Columns.Column>
            </Columns>

        </div>
    }

    return <Container>

        {taskComp()}
    </Container>
}

export default Tasks;