import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import { isCurrentUser } from './Login';
import moment from 'moment';
import { PlatformType, SelectableEntity, SubPlatform } from '../Models/EnumsAndPointers';
import randomColor from 'randomcolor';
import { Tag } from 'react-bulma-components';
import imageCompression from 'browser-image-compression';


export const sampleImageUrl = () => {


    let arr = [
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV1.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV2.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV3.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV4.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV5.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV6.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV7.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV8.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV9.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV10.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV11.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV12.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV13.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV14.PNG",
        "https://testpostin.s3.ap-south-1.amazonaws.com/website/AV15.PNG"
    ]


    let thisItem = arr[Math.floor(Math.random() * arr.length)]

    return thisItem
}




export const isQueryStringInvitationEmail = () => {
    const queryString = window.location.search;
    if (queryString) {
        const params = new URLSearchParams(queryString)
        if (params) {
            let inv = params.get('inv')
            if (inv) {
                let email = params.get('email')
                if (email) {
                    if (email.length) {
                        return email
                    }
                }
            }
        }
    }
    return null
}


export const provideCompressedFile = (imageFile, maxWidthOrHeight, maxSizeMB , callback) => {

    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    var options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker: true
    }
    imageCompression(imageFile, options)
        .then(function (compressedFile) {
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            callback(true, compressedFile)
        })
        .catch(function (error) {
            console.log('Error while compressing image' + error.message);
            callback(false, imageFile)
        });
}



export const copyToClipboard = (textToCopy, alertText) => {
    navigator.clipboard.writeText(textToCopy)
    // Add cogoToast
    alert(alertText)
}


export const getRandomColor = () => {
    let color = randomColor({ luminosity: 'light', alpha: 0.1 })
    return color
}

export const getRandomColorDark = () => {
    let color = randomColor({
        luminosity: 'light',
        hue: 'yellow', alpha: 0.2
    })
    return color
}

export const isPrivate = (entityType, entity) => {

    let isP = true

    if (entityType === SelectableEntity.tasks) {
        if (entity.sharedWith.length || entity.assignedTo.length) {
            isP = false
        }
    }

    if (entityType === SelectableEntity.events) {
        if (entity.sharedWith.length || entity.assignedTo.length) {
            isP = false
        }
    }

    if (entityType === SelectableEntity.projects) {
        if (entity.members.length > 1 ) {
            isP = false
        }
    }


    return isP
}

export const privateTag = () => {
    return <span className='opacity50'> <Tag color={"dark"}  rounded className='is-bordered' > Private </Tag> &nbsp; </span>
}


export const replaceUnderscoreWith = (text, replaceTo) => {
    if (!text || !replaceTo) {
        return text ?? ""
    }
    let changed = text.replace(/_/g, replaceTo);

    if (changed) {
        return changed
    }
    return text
}

export const findChangedAttribute = (obj1, obj2) => {
    let keyFound = false;
    Object.keys(obj1).forEach(key => {
        if (obj1[key] !== obj2[key]) {
            return keyFound = key;
        }
    });
    return keyFound || -1;
};


export const completeDateTime = (date) => {
    return moment(date).format('DD-MMM-YYYY h:mm a')
}


export const assetfor_description = (assetForInfoDict) => {
    let forInfoText = ""

    if (assetForInfoDict.platform === PlatformType.socialMedia) {
        forInfoText = "For " + assetForInfoDict.subPlatform + " · " + assetForInfoDict.contentType
        if (assetForInfoDict.subPlatform === SubPlatform.other) {
            if (assetForInfoDict.subPlatform_Other === "") {
                forInfoText = "For " + assetForInfoDict.platform + " · " + assetForInfoDict.subPlatform
            } else {
                forInfoText = "For " + assetForInfoDict.platform + " · " + assetForInfoDict.subPlatform_Other
            }
        }
    } else {

        if (assetForInfoDict.platform === PlatformType.other || assetForInfoDict.platform === PlatformType.all || assetForInfoDict.platform === PlatformType.print) {
            forInfoText = "For " + (assetForInfoDict.platform_Other !== "" ? assetForInfoDict.platform_Other : assetForInfoDict.platform)
        } else {
            forInfoText = "For " + assetForInfoDict.platform + " · " + assetForInfoDict.subPlatform
        }

    }

    return forInfoText
}


export const arrays_commonElements = (arrA, arrB) => {
    // Also known as array Intersection 
    return arrA.filter(x => arrB.includes(x));
}

export const arrays_differentElements = (arrA, arrB) => {
    // Also known as array Difference
    return arrA.filter(x => !arrB.includes(x));
}


export const arrays_uncommonElements = (arrA, arrB) => {
    // Also known as array Symmetrical Difference 
    return arrA
        .filter(x => !arrB.includes(x))
        .concat(arrB.filter(x => !arrA.includes(x)));
}

export const arrays_allElements = (arrA, arrB) => {
    // Also known as array Union
    return [...new Set([...arrA, ...arrB])];
}


export const confirmAction = (title, confirmCallback, cancelCallback) => {
    if (window.confirm(title)) {
        console.log("YES")
        if (confirmCallback && typeof confirmCallback === 'function') {
            confirmCallback()
        }
    } else {
        console.log("NO")
        if (cancelCallback && typeof cancelCallback === 'function') {
            cancelCallback()
        }
    }
}


export const removeKeysFromUserInfos_Arr = (userInfos, removeIsSelected, removeRole, removeIsEntityCreator) => {
    if (userInfos.length === 0) {
        return []
    }
    let uInfos = [...userInfos]
    uInfos = uInfos.map((mInfo) => {
        var mIn = ({ ...mInfo })

        if (removeIsSelected && mIn.hasOwnProperty("isSelected")) {
            delete mIn.isSelected;
        }
        if (removeRole && mIn.hasOwnProperty("role")) {
            delete mIn.role;
        }
        if (removeIsEntityCreator && mIn.hasOwnProperty("isEntityCreator")) {
            delete mIn.isEntityCreator;
        }
        return mIn
    })

    return uInfos
}

export const removeKeysFromUserInfo_Obj = (userInfo, removeIsSelected, removeRole, removeIsEntityCreator, removeWorkspaceId) => {
    var mIn = ({ ...userInfo })
    if (removeIsSelected && mIn.hasOwnProperty("isSelected")) {
        delete mIn.isSelected;
    }
    if (removeRole && mIn.hasOwnProperty("role")) {
        delete mIn.role;
    }
    if (removeIsEntityCreator && mIn.hasOwnProperty("isEntityCreator")) {
        delete mIn.isEntityCreator;
    }

    if (removeWorkspaceId && mIn.hasOwnProperty("workspaceId")) {
        delete mIn.workspaceId;
    }
    return mIn
}


export const removeKeyFromObjs_InArray = (array, keyName) => {
    if (array.length === 0) {
        return []
    }
    let objsArr = [...array]
    objsArr = objsArr.map((obj) => {
        var o = removeKeyFromObj(obj, keyName)
        return o
    })

    return objsArr
}


export const removeKeyFromObj = (obj, keyName) => {
    var mIn = ({ ...obj })
    if (mIn.hasOwnProperty(keyName)) {
        delete mIn[keyName];
    }
    return mIn
}

export const removeStringFromArr = (arr, string) => {
    let arrCopy = [...arr]
    if (arrCopy.includes(string)) {
        arrCopy = arrCopy.filter((a) => {
            return a !== string
        })
    }
    return arrCopy
}


export const removeObjFromArr = (arr, obj) => {
    let arrCopy = [...arr]
    let arrAfterRemoval = arrCopy.filter((m) => {
        return m.id === obj.id
    })
    return arrAfterRemoval
}

export const removeWhiteSpaces = (str) => {
    return str.replace(/\s/g, "");
}

// byUserName, byUserId, byUserEmail, createdAt, id, note,

// byUserName::dknnd~~byUserId::dnjndknd::nekjneje~~

// byUserInfo: "id:ZHRmEwF;name:Applivin;email:applivin@gmailcom;imageUrl: httpstestpostinsapsouthamazonawscomwebsiteAVPNG;workspaceId: ;role: "

// createdAt: "1647537872"

// id: "jhWHM1"

// note: "bjbjkbkjbj"

export const cypherNoteObjToString = (obj) => {

    console.log("Cyphering obj")
    console.log(obj)

    let str = `id::${obj.id}~~note::${obj.note}~~byUserName::${obj.byUserInfo.name}~~byUserId::${obj.byUserInfo.id}~~byUserEmail::${obj.byUserInfo.email}~~createdAt::${obj.createdAt}`
    return str
}

export const di_cypherNoteObjToString = (string) => {
    if (!string || !string.length) {
        return string
    }

    let compsSplit = string.split('~~')
    var myObj = {}
    // console.log("COMPS SPLIT")
    // console.log(compsSplit)
    if (compsSplit.length >= 0) {
        compsSplit.forEach((c) => {
            if (c.length) {
                let keyValSplit = c.split("::")
                if (keyValSplit.length === 2) {
                    myObj[keyValSplit[0]] = keyValSplit[1]
                }
            }
        })
    }

    // console.log("BEFOFR DC OBJ")
    // console.log(myObj)

    if (myObj.byUserName) {
        let thisObj = {
            byUserInfo: {
                name: myObj.byUserName,
                email: myObj.byUserEmail ?? "",
                id: myObj.byUserId ?? "",
            },
            createdAt: (myObj.createdAt ? myObj.createdAt : null),  // still in unix epoch.. will be formatted on UI
            id: myObj.id ?? "",
            note: myObj.note ?? ""
        }

        return thisObj
    }

    console.log("ERROR: Note Obj could not be dicyphered")
    return null

}

export const removeSpecialChars = (string) => {
    // let outString = string.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //allowinfg @ and . for emails AND slashes for imageUrls
    let str = string ?? ""
    let outString = str.replace(/[`~!#$%^*()_|+-?;:'",<>]/gi, '');
    return outString
}

export const bytesToSizeString = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export const createAssetId = (existingId, newVersion) => {
    if (existingId) {
        //TODO: if we are passing entire id with ver.. split it here

        return `${existingId}-ver-${newVersion}`
    }
    return uuidv4() + `-ver-${newVersion}`
}


export const hasEmoji = (string) => {
    return /\p{Extended_Pictographic}/u.test(string)
}


export const containsUpvoteByCU = (upvotes) => {
    if (upvotes.length) {
        let cu = isCurrentUser()
        let suchVotes = upvotes.filter((vt) => {
            if (vt.id === cu.id) {
                return vt
            }
            return null
        })
        if (suchVotes.length > 0) {
            return true
        }
    }
    return false
}

// function removeEmojis(string) {
//     var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
//     return string.replace(regex, "");
// }
// function isOnlyEmoji(string) {
//     return !removeEmojis(string).length;
// }

export const randomCode = (length) => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const randomAlphabets = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const randomIntegerString = (length) => {
    var result = '';
    var characters = '1234567890';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const validateEmail = (value) => {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }
    return error;
}

export const validatePassword = (value, leastChars) => {
    let leastNumberOfCharacters = leastChars ?? 3
    let error;
    if (!value) {
        error = 'Required';
    } else if (value.length < leastNumberOfCharacters) {
        error = `Password should have atleast ${leastNumberOfCharacters} characters`;
    }
    return error;
}

export const validateName = (value, leastChars, spacesAllowed) => {
    let areSpacesAllowed = spacesAllowed ?? true
    let leastNumberOfCharacters = leastChars ?? 3
    let error;
    if (!value) {
        error = 'Required';
    } else if ((value.split(' ').length !== 1) && !areSpacesAllowed) {
        error = 'Username Cannot have spaces';
    } else if (value.length < leastNumberOfCharacters) {
        error = `Username should have atleast ${leastNumberOfCharacters} characters`;
    }
    return error;
}

export const validatePhone = (value) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    let error;
    if (!value) {
        error = 'Required';
    } else if (phoneRegExp.test(value)) {
        error = 'Invalid Phone No';
    }
    return error;
}


export const validateNumber = (value) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    let error;
    if (!value) {
        error = 'Required';
    } else if (phoneRegExp.test(value)) {
        error = 'Invalid Number';
    }
    return error;
}

export const validateText = (value, leastChars, spacesAllowed) => {
    let areSpacesAllowed = spacesAllowed ?? true
    let leastNumberOfCharacters = leastChars ?? 2
    let error;
    if (!value) {
        error = 'Required';
    } else if ((value.split(' ').length !== 1) && !areSpacesAllowed) {
        error = 'Value Cannot have spaces';
    } else if (value.length < leastNumberOfCharacters) {
        error = `Value should have atleast ${leastNumberOfCharacters} characters`;
    }
    return error;
}

export const validateRequired = (value) => {
    let error;
    if (!value) {
        error = 'Required';
    }
    return error;
}



export const getTimeAfterMins = (date, mins) => {
    let timeAfterMins = new Date(date.setMinutes(date.getMinutes() + mins));
    return timeAfterMins
};