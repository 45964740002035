import React, { useEffect, useRef, useState } from 'react'
import { doSelectionsContain, folderOptions, TimelineFolderLevel, SelectableEntity } from '../../Models/EnumsAndPointers'
import { Timeline } from '../../Models/Timeline'
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon, Heading, Content } from 'react-bulma-components';
import { FiChevronRight, FiClock } from 'react-icons/fi';
import LoadingAux from '../../Containers/LoadingAux';
import AssetExplorer from './AssetExplorer';
import moment from 'moment';
import TimeAgo from 'react-timeago'



const TimelineExplorer = (props) => {

    const [timeline, setTimeline] = useState([])
    const [explicitLogType, setExplicitLogType] = useState(null)
    const [isFetching, setIsFetching] = useState(false)
    const headerRef = useRef(null)

    const scrollToTop = () => {

        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.scrollIntoView({ behavior: 'instant', block: 'start' })
            } else {
                const element = document.getElementById('projectExpTop')
                if (element) {
                    element.scrollIntoView({ behavior: 'instant', block: 'start' });
                }
            }
        }, 500)
    };

    const groupBy = (array, key) => {
        // Return the reduced array
        return array.reduce((result, currentItem) => {
            // If an array already present for key, push it to the array. Otherwise create an array and push the object.
            (result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem);
            // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
            return result;
        }, {}); // Empty object is the initial value for result object
    };


    useEffect(() => {
        let isMounted = true;
        setIsFetching(true)

        Timeline.getAll(props.entity, props.entityId, explicitLogType, (succ, projs, errMsg) => {
            if (isMounted) {
                setIsFetching(false)
            }
            if (succ) {
                if (isMounted) {

                    console.log("FOUND TIMELINE IS")
                    console.log(projs)

                    let withDate = []

                    projs.map((t) => {
                        let thisDate = moment(t.createdAt)
                        // t.date = parseInt(thisDate.format("YYYYMMDD"))
                        t.date = thisDate.format("Do MMMM YYYY")
                        withDate.push(t)
                        return t
                    })


                    let group = groupBy(withDate, "date")
                    console.log("GROUP IS")
                    console.log(group)


                    setTimeline(group)
                }
            } else {
                console.log(errMsg)
            }
        })

        return () => (isMounted = false)
    }, [props.entity, props.entityId, explicitLogType])



    const group = (date, rows) => {

        return <>
            <tr key={date} className=" is-borderless  is-fullwidth ">
                <td className='has-text-weight-semibold is-size-7 is-borderless pt-6 '>  <p>{date}</p> </td>
            </tr>
            {
                rows.map((r) => row(r))
            }
        </>

    }


    const row = (opt) => {

        // console.log("OPT IS")
        // console.log(opt)
        let descriptionRows = []
        if (opt.description){
            if (opt.description.includes(">n>·")){
                descriptionRows = opt.description.split(">n>·")
            }else{
                descriptionRows.push(opt.description)
            }
        }
        

        return <tr key={opt.id} className=" my-0 py-0  is-fullwidth  pb-6 " >
            <td align="left " className=' pl-5 is-size-7 ' >
                {/* <FiClock className=" is-size-7 " /> {moment(opt.createdAt, "dd/MMM/yyyy,  h:mm aa")} */}
                <p className="has-text-grey is-size-8 ">
                    {moment(opt.createdAt).format("h:mm:ss a")}
                    {/* <TimeAgo live={false} className="  " date={opt.createdAt} /> */}
                </p>
                <Heading size={7} className="  has-text-weight-medium my-0 py-0 ">
                    {descriptionRows.map((r) => {
                        return <p>· {r} </p>  
                    })}
                    
                </Heading>
                <Heading  subtitle={true} className=" is-size-8 has-text-grey has-text-weight-medium my-0 py-0 mb-2 pl-2 mt-1 ">{opt.Title ?? opt.logType} by {opt.byUserInfo.name} </Heading>

            </td>
            {/* <td align="left" className=" minW200 is-size-7 " >
            </td> */}
        </tr>
    }




    const up = () => {

        let allDateKeys = Object.keys(timeline).sort((a, b) => {
            return moment(b, "Do MMMM YYYY").diff(moment(a, "Do MMMM YYYY"))
        })

        return <div>
            <Panel className="m-0 is-shadowless ">
                <Table className="minW300 is-fullwidth h-100vh  " hoverable={false} striped={false} bordered={false}  >
                    <tbody className='is-fullheight  ' >
                        {
                            allDateKeys.length ?
                                allDateKeys.map((key, index) => {
                                    let dateName = key
                                    let logs = timeline[key]
                                    // let dateString = logs[0].dateString
                                    return group(dateName, logs)
                                })
                                :
                                <p className=' has-text-centered my-6 has-text-grey-light' > No Timeline Log </p>

                        }
                    </tbody>
                </Table>
                <br />
                <br />
            </Panel>
        </div>
    }





    const modalContent = () => {
        return <LoadingAux isLoading={isFetching} fullHeight={true} >
            <div ref={headerRef} id="timelineExpTop"></div>
            {up()}
        </LoadingAux>

    }


    const modal = () => {
        return <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className=" lightModalBg backblurOnly-light lightDeleteButton animate-bottom " >
            <Modal.Card className=" maxW540 minW450 ">
                <Modal.Card.Header showClose >
                    <Modal.Card.Title className="has-text-grey text-wrap30p" textSize={6} >{props.heading ?? (props.entity + " Timeline")}</Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body className="p-0">
                    {modalContent()}
                </Modal.Card.Body>
                {/* <Modal.Card.Footer align="center" textSize={7} >
                </Modal.Card.Footer> */}
            </Modal.Card>
        </Modal >
    }




    return modal()
}

export default TimelineExplorer