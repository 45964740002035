import React, { useEffect, useState, useRef, useCallback } from 'react'
import {  Modal, Panel, Columns, Button, Container, Table, Box, Form, Tabs, Heading } from 'react-bulma-components';
import {Invitation} from '../../Models/Invitation'
import {randomCode, validateEmail} from '../../utilities/Utilities'

import { FcInvite } from 'react-icons/fc';
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'
import { BiMailSend } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';
import { getCurrentWorkspaceId, getShowInvitationReminderToAdmin, setShowInvitationIfNeeded } from '../../utilities/Login';
import ColumnAux from '../../Containers/ColumnAux';
import NewInvitations from './NewInvitations'
import SentInvitations from './SentInvitations';
import Members from './Members';


const InvitationModal = (props) => {


    const invTabs = {
        new : "New",
        sent : "Sent Invitations",
        members: "Members"
    }


    const [currentTab, setCurrentTab] = useState(invTabs.new)


    const toggleTab = (tb) => {
        setCurrentTab(tb)
    }


    const tabContent = () => {
        let tabContent = null

        if (currentTab === invTabs.new){
            tabContent = <NewInvitations />
        }

        if (currentTab === invTabs.sent){
            tabContent = <SentInvitations />
        }
        
        if (currentTab === invTabs.members){
            tabContent = <Members />
        }

        return tabContent
    }


    const modal = () => {   
        

        
            return (
                <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" h-90vh  m-0 p-0" radiusless>
                    <Modal.Card.Header showClose className="    ">
                        <Modal.Card.Title className="has-text-grey-light"  >Invite team members</Modal.Card.Title>
                    </Modal.Card.Header>
                        <Modal.Card.Body className="p-0">
                            <Panel className=" stick-to-top z-30">
                                {/* <Panel.Header>Repositories</Panel.Header> */}
                                <Tabs size="small" backgroundColor="light" textWeight="semibold" className="  " >
                                    <Tabs.Tab active={currentTab === invTabs.new}  onClick={()=>{toggleTab(invTabs.new)}}> {invTabs.new}</Tabs.Tab>
                                    <Tabs.Tab active={currentTab === invTabs.sent}  onClick={()=>{toggleTab(invTabs.sent)}}> {invTabs.sent}</Tabs.Tab>
                                    <Tabs.Tab active={currentTab === invTabs.members}  onClick={()=>{toggleTab(invTabs.members)}}> {invTabs.members}</Tabs.Tab>
                                </Tabs>
                            </Panel>

                            {
                                tabContent()
                            }

                        </Modal.Card.Body>
                        {/* <Modal.Card.Footer renderAs={Button.Group} align="center" className="pt-2" >
                            {
                                props.isInviteReminder ? 
                                <Form.Checkbox  defaultChecked={props.showInviteReminder} onChange={ (e) => { props.changeInviteReminder(e.target.checked) } } > <span className=" is-size-7  has-text-grey has-text-weight-medium "> Show invitation reminder on admin/owner login </span> </Form.Checkbox>
                                : 
                                null
                            }
                            <Button className="is-size-7 has-text-centered has-text-grey m-0 is-borderless has-background-transparent "  > Show invitation reminder on login  </Button>
                        </Modal.Card.Footer> */}
                    </Modal.Card>
                </Modal >
            )
        }



    return modal()

}

export default InvitationModal