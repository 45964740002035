import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';

import { AiOutlineAppstoreAdd, AiFillFacebook, AiOutlineFacebook, AiFillInstagram, AiOutlineInstagram, AiFillLinkedin, AiOutlineLinkedin, AiOutlineTwitter, AiFillMediumSquare, AiFillYoutube, AiOutlineYoutube } from 'react-icons/ai';
import { RiFileUploadLine, RiMoreFill, RiImageAddLine, RiTwitterLine, RiMediumLine, RiSpotifyLine, RiSnapchatLine } from 'react-icons/ri';
import { FaSpotify, FaPinterest, FaSnapchatGhost } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { TiDocumentAdd } from 'react-icons/ti';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { GrFormClose } from 'react-icons/gr';
import { IoMdPersonAdd, IoMdAddCircleOutline, IoMdAddCircle } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import moment from 'moment'
import { Asset, AssetFilter } from '../../Models/Asset';

import { MdSchedule } from 'react-icons/md';

// IoMdPersonAdd
import ReactTooltip from 'react-tooltip';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Allowed_SocialMedia_Platforms, allAssetCreateStatusType, SocialMedia_Platform, socialMediaChannels_HexColors, UserPickerFor } from '../../Models/EnumsAndPointers';
import UserAvatar from '../Calendar/UserAvatar';
import UserPicker from '../Calendar/UserPicker';
import { getTimeAfterMins, randomCode } from '../../utilities/Utilities';
import { Event } from '../../Models/Event';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';


// AiFillFacebook, AiOutlineFacebook
// AiFillInstagram, AiOutlineInstagram
// AiFillLinkedin, AiOutlineLinkedin
// AiOutlineTwitter, RiTwitterLine
// FaPinterest, RiPinterestLine
// AiFillMediumSquare, RiMediumLine
// FaSpotify, RiSpotifyLine
// FaSnapchatGhost, RiSnapchatLine, 




const AssetsFilter = (props) => {




    const [event, setEvent] = useState(null)
    const [showUserPickerFor, setShowUserPickerFor] = useState(null)


    const usersAreSelected = (arr) => {
        // setSelectedUsers(arr)
        // clearSelections()


        console.log("Filter is");
        console.log(props.filter);

        console.log("To add array is");
        console.log(arr);

        if (props.filter) {
            let filterCopy = AssetFilter.copyFrom(props.filter)
            if (showUserPickerFor === UserPickerFor.assetAddedByFilter) {
                filterCopy.addedBy = arr
            }
            if (showUserPickerFor === UserPickerFor.assetApprovedByFilter) {
                filterCopy.approvedBy = arr
            }
            if (showUserPickerFor === UserPickerFor.assetRejectedByFilter) {
                filterCopy.rejectedBy = arr
            }

            props.filterChanged(filterCopy)
        }
    }

    const userRemovePressed = (usr, forPicker) => {
        let filterCopy = AssetFilter.copyFrom(props.filter)
        if (forPicker === UserPickerFor.assetApprovedByFilter) {
            let ussrs = [...filterCopy.approvedBy].filter((u) => {
                return u.id !== usr.id
            })
            filterCopy.approvedBy = ussrs
        }

        if (forPicker === UserPickerFor.assetRejectedByFilter) {
            let ussrs = [...filterCopy.rejectedBy].filter((u) => {
                return u.id !== usr.id
            })
            filterCopy.rejectedBy = ussrs
        }

        if (forPicker === UserPickerFor.assetAddedByFilter) {
            let ussrs = [...filterCopy.addedBy].filter((u) => {
                return u.id !== usr.id
            })
            filterCopy.addedBy = ussrs
        }
        props.filterChanged(filterCopy)
    }

    const clearFilter = () => {
        let newF = new AssetFilter()
        props.filterChanged(newF)
    }



    const clearSelections = () => {
        setShowUserPickerFor(null)
    }


    const onDateChange = (name, value, isCleared) => {
        console.log("On date change called")
        console.log(`Name is ${name}`)
        console.log(value)

        if (name === "on") {

            let ev = Event.copyFrom(event)
            if (isCleared) {
                ev.starts = null
            } else {
                ev.starts = value
            }
            setEvent(ev)

        }
    }


    const platformToggled = (pl) => {
        let evs = Event.copyFrom(event)
        let chls = [...evs.channels]
        if (chls.includes(pl)) {
            chls = chls.filter((p) => {
                return p !== pl
            })
        } else {
            chls.push(pl)
        }
        evs.channels = chls
        setEvent(evs)
    }


    const tagForPlatform = (pl, selected) => {
        let plat = null

        switch (pl) {

            case SocialMedia_Platform.linkedIn: plat = <AiFillLinkedin style={selected ? { color: socialMediaChannels_HexColors.linkedIn } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.facebook: plat = <AiFillFacebook style={selected ? { color: socialMediaChannels_HexColors.facebook } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.instagram: plat = <AiFillInstagram style={selected ? { color: socialMediaChannels_HexColors.instagram } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.twitter: plat = <AiOutlineTwitter style={selected ? { color: socialMediaChannels_HexColors.twitter } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.youtube: plat = <AiFillYoutube style={selected ? { color: socialMediaChannels_HexColors.youtube } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.tiktok: plat = <SiTiktok style={selected ? { color: socialMediaChannels_HexColors.tiktok } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.pinterest: plat = <FaPinterest style={selected ? { color: socialMediaChannels_HexColors.pinterest } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            case SocialMedia_Platform.medium: plat = <AiFillMediumSquare style={selected ? { color: socialMediaChannels_HexColors.medium } : { opacity: 0.20 }} className=" is-size-4 " />; break;
            default: break;
        }
        return <Tag className=" is-size-5 ml-minus-10 mr-1 has-background-white  " onClick={() => { platformToggled(pl) }} > {plat} </Tag>
    }

    const allPlatformTags = (plats) => {

        let tags = []

        Allowed_SocialMedia_Platforms.sort((p) => {
            return !plats.includes(p)
        })
        Allowed_SocialMedia_Platforms.map((p) => {
            tags.push(tagForPlatform(p, plats.includes(p)))
            return null
        })


        return tags
    }


    const getSelectedUsers = () => {
        let usrs = []
        if (props.filter) {
            if (showUserPickerFor === UserPickerFor.assetApprovedByFilter) {
                usrs = props.filter.approvedBy
            }

            if (showUserPickerFor === UserPickerFor.assetRejectedByFilter) {
                usrs = props.filter.rejectedBy
            }

            if (showUserPickerFor === UserPickerFor.assetAddedByFilter) {
                usrs = props.filter.addedBy
            }
        }
        return usrs
    }


    const assetTypeChanged = (tp) => {
        let filterCopy = AssetFilter.copyFrom(props.filter)
        filterCopy.type = tp
        props.filterChanged(filterCopy)

    }


    const modal = () => {

        let filter = props.filter


        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light height100vc lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" height100vc  m-0 p-0" >
                    <Modal.Card.Header showClose className="    ">
                        <Modal.Card.Title className="has-text-grey-light" textSize={6} >Filter By</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0 m-0">

                        {showUserPickerFor ? <UserPicker for={showUserPickerFor} selectedUsers={getSelectedUsers()} allMembers={props.allMembers} usersAreSelected={usersAreSelected} close={clearSelections} /> : null}

                        <Box shadowless className="  " >

                            <Form.Field>
                                <Form.Label className="is-size-7 mt-2 mb-0 ml-2 "  > Approved By
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.assetApprovedByFilter) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            filter.approvedBy.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => { userRemovePressed(su, UserPickerFor.assetApprovedByFilter) }} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>
                            <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Rejected By
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.assetRejectedByFilter) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            filter.rejectedBy.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => { userRemovePressed(su, UserPickerFor.assetRejectedByFilter) }} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>

                            <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 " > Added By
                                    {/* <IoMdPersonAdd className=" is-size-6 has-text-dark ml-2" /> */}
                                    <Button size="small" className="has-background-transparent p-0 is-borderless is-hover-dark ml-2 " onClick={() => { setShowUserPickerFor(UserPickerFor.assetAddedByFilter) }} > <IoAddCircleOutline className="is-size-5 has-text-dark mt-minus-14 " />  </Button>

                                </Form.Label>

                                <Box shadowless className=" px-0 pt-0 maxH200  overflow-scroll mt-0 " >
                                    <Tag.Group className="mt-0">
                                        {
                                            filter.addedBy.map((su) => {
                                                return <UserAvatar isRemovable={true} removePressed={() => { userRemovePressed(su, UserPickerFor.assetAddedByFilter) }} infoObj={su} nameWrap={true} />
                                            })
                                        }
                                    </Tag.Group>
                                </Box>
                            </Form.Field>
                            <br />
                            {/* <div>
                                <Form.Label className="is-size-7 mt-4 mb-0 ml-2 "  >Added</Form.Label>
                                <Columns>
                                    <Columns.Column gap={0}>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >After</Form.Label>
                                            <Form.Control>
                                                <DatePicker
                                                    name="createdAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    // selected={f}
                                                    showTimeSelect
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    selected={null}
                                                    onChange={(value) => {
                                                        onDateChange("on", value);
                                                    }}
                                                    onFocus={e => e.target.blur()}
                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                    <Columns.Column>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >Before</Form.Label>
                                            <Form.Control>
                                                <DatePicker
                                                    name="createdAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    // selected={f}
                                                    showTimeSelect
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    selected={null}
                                                    onChange={(value) => {
                                                        onDateChange("on", value);
                                                    }}
                                                    onFocus={e => e.target.blur()}
                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                </Columns>
                                <br />
                            </div>

                            <div>
                                <Form.Label className="is-size-7 mt-3 mb-0 ml-2 "  >Updated</Form.Label>
                                <Columns>
                                    <Columns.Column gap={0}>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >After</Form.Label>
                                            <Form.Control>
                                                <DatePicker
                                                    name="deadlineAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    showTimeSelect
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    selected={null}
                                                    onChange={(value) => {
                                                        onDateChange("on", value);
                                                    }}
                                                    onFocus={e => e.target.blur()}
                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                    <Columns.Column>
                                        <Form.Field className="ml-2">
                                            <Form.Label className="ml-2 m-0 is-size-7 has-text-grey-light has-text-weight-normal " >Before</Form.Label>
                                            <Form.Control>
                                                <DatePicker
                                                    name="deadlineAfter"
                                                    className=" datePicker input is-shadowless bottomBorderOnly "
                                                    placeholderText="DD/MM/YY"
                                                    isClearable
                                                    showTimeSelect
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    todayButton='Today'
                                                    dateFormat="dd/MMM/yyyy,  h:mm aa"
                                                    selected={null}
                                                    onChange={(value) => {
                                                        onDateChange("on", value);
                                                    }}
                                                    onFocus={e => e.target.blur()}
                                                />
                                            </Form.Control>
                                        </Form.Field>
                                    </Columns.Column>
                                </Columns>
                                <br />
                            </div> */}




                            {/* <Form.Field>
                                <Form.Label className="is-size-7 mt-6 mb-0 ml-2 "  >For Channels </Form.Label>
                                <Box shadowless className=" px-0 pt-2 " >
                                    {
                                        allPlatformTags([])
                                    }
                                </Box>
                            </Form.Field> */}


                            <Form.Field>
                                <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2  mt-5" >Type</Form.Label>
                                <Form.Control>
                                    <Form.Select
                                        size='small'
                                        value={filter.type}
                                        className=" mt-2 ml-2 "
                                        onChange={(e) => {
                                            assetTypeChanged(e.target.value)
                                        }}
                                    >
                                        {
                                            allAssetCreateStatusType.map((act) => {
                                                return <option key={act} value={act}>{act}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Control>
                                <br />
                            </Form.Field>

                            {/* <Form.Field>
                                <Form.Label className="is-size-7 mt-minus-10 mb-0 ml-2  mt-5"  >Status</Form.Label>
                                <Form.Control>
                                    <Form.Select
                                        size='small'
                                        value={null}
                                        className=" mt-2 ml-2 "
                                    >
                                        <option value="Pending">Pending</option>
                                        <option value="Archived">Archived</option>
                                        <option value="Finalised">Finalised</option>
                                    </Form.Select>
                                </Form.Control>
                                <br />
                            </Form.Field> */}
                        </Box>
                        <br />



                    </Modal.Card.Body>
                    <Modal.Card.Footer renderAs={Button.Group} align="center" className="pt-2" >
                        <Button className="is-size-7 has-text-centered has-text-grey m-0 is-borderless has-background-transparent " onClick={clearFilter} > CLEAR </Button>
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }


    return modal()



}

export default AssetsFilter