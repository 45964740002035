import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Modal, Panel, Columns, Button, Container, Table, Box, Form, Tabs, Dropdown, Message } from 'react-bulma-components';
import { Workspace } from '../../Models/Workspace'
import { randomCode, validateEmail, copyToClipboard } from '../../utilities/Utilities'

import { FcImbite } from 'react-icons/fc';
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'
import { TiTick } from 'react-icons/ti';
import { HiDotsVertical } from 'react-icons/hi';
import { MdContentCopy } from 'react-icons/md';



import { getCurrentWorkspaceId, getShowImbitationReminderToAdmin, setShowImbitationIfNeeded } from '../../utilities/Login';
import ColumnAux from '../../Containers/ColumnAux';
import moment from 'moment';
import LoadingAux from '../../Containers/LoadingAux';
import UserAvatar from '../Calendar/UserAvatar';



const Members = (props) => {

    const memTabs = {
        current: "Current",
        allTime: "All Time"
    }


    const [currentTab, setCurrentTab] = useState(memTabs.current)
    const [members, setMembers] = useState([])
    const [allMembersEver, setAllMembersEver] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {

        let isMounted = true;

        setLoading(true)

        Workspace.getMembers((succ, membersObj, errMsg) => {
            if (isMounted) {
                setLoading(false)
            }
            if (succ && membersObj) {

                console.log("MEMBERS OBJ IS");
                console.log(membersObj);

                let mm = membersObj.membersInfo ?? []
                let allMMEver = membersObj.allMembersEverInfo ?? []
                if (isMounted) {
                    setMembers(mm)
                    setAllMembersEver(allMMEver)
                }

            } else {
                console.log(errMsg);
            }
        })

        return () => (isMounted = false)

    }, [])



    const toggleTab = (tb) => {
        setCurrentTab(tb)
    }

    const modal = () => {
        return (
            // <ColumnAux isMiddle={true} size={12}>
            <Box shadowless className=" has-text-left">
                <LoadingAux isLoading={loading} isMain={true} isCentered={true} loaderHeight={300} >
                    <Tabs type="toggle" size="small" align="center" textWeight="semibold" className="  " >
                        <Tabs.Tab active={currentTab === memTabs.current} onClick={() => { toggleTab(memTabs.current) }}> {memTabs.current}</Tabs.Tab>
                        <Tabs.Tab active={currentTab === memTabs.allTime} onClick={() => { toggleTab(memTabs.allTime) }}> {memTabs.allTime}</Tabs.Tab>
                    </Tabs>
                    <ColumnAux size={10} isMiddle={true}>

                        {/* <Message size="small">
                        <Message.Body>
                            <ul>
                                <li>• You can copy imbitation pass and share with user directly.</li>
                                <li>• On removing imbitaion. The user will not be able to join workspace if not already joined.</li>
                                <li>• If a user has already joined workspace. Removing imbitation has no affect.</li>
                            </ul>
                        </Message.Body>
                    </Message> */}



                        {
                            currentTab === memTabs.current ?
                                <div>
                                    {
                                        members.map((mb) => {
                                            return <Box shadowless className="has-background-transparent m-0 py-0"> <UserAvatar infoObj={mb} noButton={true} showFullInfo={true} large={true} /> </Box>
                                        })
                                    }
                                </div>
                                :
                                <div>
                                    {
                                        allMembersEver.map((mb) => {
                                            return <Box shadowless className="has-background-transparent m-0 py-0"> <UserAvatar infoObj={mb} noButton={true} showFullInfo={true} large={true} /> </Box>
                                        })
                                    }
                                </div>
                        }



                    </ColumnAux>
                </LoadingAux>
            </Box>
            // </ColumnAux>
        )
    }



    return modal()

}

export default Members