
import React, { useState } from 'react'
import { Card, Heading, Media, Content, Tile, Box, Container, Section, Image, Columns, Tabs, Button, Tag } from 'react-bulma-components';
import LoadingAux from '../../Containers/LoadingAux';
import ColumnAux from '../../Containers/ColumnAux';





const Guide = (props) => {




    const justVid = (vidSrc) => {
        return <Box shadowless radiusless className={'has-background-transparent has-text-centered m-0 p-0 overflow-hidden ' + props.className}>
            <video placeholder='Click + button to add assets' src={vidSrc} playback autoPlay={true} loop={true} muted={true} />
        </Box>
    }

    const vidTitleDesc = (title, mediaSrc, description, boxClassName, vidClassName) => {
        return <Box className={' has-text-centered p-4 has-background-transparent px-6 ' + boxClassName } >
            {props.isDense ? null : <br /> }
            
            <Heading size={6} textColor="grey-light" textWeight='medium' className="my-2  ">{title}</Heading>
            {props.isDense ? null : <br /> }
            {
                props.noVid ? null :
                    <Box shadowless radiusless className={'has-background-transparent has-text-centered m-0 p-0 overflow-hidden ' + vidClassName}>
                        {
                            props.type === "video" && mediaSrc ?
                                <video placeholder='Click + button to add assets' src={mediaSrc} playback autoPlay={true} loop={true} muted={true} />
                                :
                                null
                        }
                        {
                            props.type === "image" && mediaSrc ?
                                <Image src={mediaSrc} alt="image" size="square" />
                                :
                                null
                        }
                    </Box>
            }

            <p className=" has-text-grey">{description}</p>
            {props.isDense ? null : <br /> }
        </Box>
    }


    const finalComp = () => {
        let comp = <></>

        if (props.title) {
            comp = vidTitleDesc(props.title,  props.src , props.description, props.boxClassName, props.vidClassName)
        } else {
            comp = justVid(props.src)
        }
        return comp
    }

    return (
        <>
            {finalComp()}
        </>
    )

}

export default Guide