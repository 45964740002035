import React, { useEffect, useState, useRef, useCallback } from 'react'
import {  Modal, Panel, Columns, Button, Container, Table, Box, Form, Tabs } from 'react-bulma-components';
import {Invitation} from '../../Models/Invitation'
import {randomCode, validateEmail} from '../../utilities/Utilities'

import { FcInvite } from 'react-icons/fc';
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'
import { BiMailSend } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';
import { getCurrentWorkspaceId, getShowInvitationReminderToAdmin, setShowInvitationIfNeeded } from '../../utilities/Login';
import ColumnAux from '../../Containers/ColumnAux';
import { assignableInvitaionRoles } from '../../utilities/TpRules';



const NewInvitations = (props) => {

    const createSampleInvite = () => {
        let sampleInvite = new Invitation()
        let wkId = getCurrentWorkspaceId()
        sampleInvite.pass = `WK-${wkId}-P-${randomCode(10)}`
        return sampleInvite 
    }

    const initialNewInvites = [
        createSampleInvite(),
        createSampleInvite(),
        createSampleInvite()
    ]

    const [newInvites, setNewInvites] = useState(initialNewInvites)
    const [saving, setSaving] = useState(false)


    const addInvite = () => {
        let invs = [...newInvites]
        let inv = createSampleInvite()
        invs.push(inv)
        setNewInvites(invs)
    }


    const anyEmailContainsError = (invs) => {
        let err = false
        invs.map((inv) => {
            if (inv.error){
                err = true 
            }
            return null
        })
        return err
    }

    const removeAllErrors = () => {
        let invs = [...newInvites].map((inv) => { inv.error = null ; return inv })
        setNewInvites(invs)
        return invs
    }


    const sendInvitation = () => {
        
        if (!newInvites.length){
            return
        }

        let invs = [...newInvites].map((inv) => { inv.error = validateEmail(inv.email) ; return inv })

        if (anyEmailContainsError(invs)){
            setNewInvites(invs)
            return
        }


        setSaving(true)

        for (let index = 0; index < invs.length; ++index) {
            let inn = Invitation.copyFrom(invs[index])
            inn.add((succ, result, errMsg) => {

                if (succ){
                    console.log( inn.email +  " - Invitation added");
                }
                else{
                    console.log( inn.email + " - Error -" + errMsg);
                }

                if (index === invs.length - 1){
                    setSaving(false)
                    setNewInvites([])
                    alert("Invitations sent")
                }


                // confirmed += 1
                // if (!succ && errMsg){
                //     errorMessages.push(errMsg)
                // }

                // if (confirmed === invs.length){
                //     if (errorMessages.length){
                //         alert("Invitations sent with few errors")
                //         console.log(errorMessages);
                //     }else{
                //         alert("All invitations sent")
                //     }
                // }
            })

        }



    }

    const inviteChanged = (typeChanged, pass, val) => {

        let invs = [...newInvites]

        if (anyEmailContainsError(invs)){
            invs = removeAllErrors()
        }

        let index = invs.findIndex((thisInv) => {
            return thisInv.pass === pass
        })

        if (index >= 0){
            let thisInv = invs[index]
            if (typeChanged === "email"){
                thisInv.email = val
            }
            if (typeChanged === "role"){
                thisInv.role = val
            }
            invs[index] = thisInv
            setNewInvites(invs)
        }
    }

    const inviteRemoved = (pass) => {
        let invs = [...newInvites].filter((inv) => {
            return inv.pass !== pass
        })
        setNewInvites(invs)
    }

    const modal = () => {


        
            return (
                            <ColumnAux isMiddle={true} size={10}>
                            <Box shadowless className=" has-text-centered">
                                {
                                    newInvites.map((nv) => {
                                        return    <Form.Field key ={nv.pass}>
                                            <Form.Field kind="group">
                                            <Form.Control  >
                                                <Button className="is-borderless opacity35 is-hover-dark m-0 p-0" onClick={ () => { inviteRemoved(nv.pass) } }> <IoIosRemoveCircleOutline className="is-size-6" /> </Button>
                                            </Form.Control>
                                            <Form.Control fullwidth >
                                                <Form.Input  className={`has-background-light ${ nv.error ? "is-danger" : null }`} value={nv.email} onChange={(e) => { return inviteChanged("email", nv.pass, e.target.value) }} placeholder="Email Id" />
                                                {nv.error ? <Form.Help textAlign="left" color="danger">{nv.error}</Form.Help> : null}

                                            </Form.Control>
                                            <Form.Control>
                                                <Form.Select
                                                value={nv.role}
                                                onChange={(e) => { return inviteChanged("role", nv.pass, e.target.value) }}
                                                className=" has-background-light "
                                                >
                                                    {
                                                      
                                                        assignableInvitaionRoles().map(thisRole => {
                                                            return <option key={thisRole} value={thisRole}>{thisRole}</option>
                                                        })
        
                                                    }
                                                </Form.Select>
                                            </Form.Control>
                                            </Form.Field>
                                        </Form.Field>
                                    })
                                }
                                    <Button className="is-centered is-hover-dark is-borderless" onClick={addInvite}> <IoIosAddCircleOutline className="is-size-4" /> </Button>
                                    <p className=" mt-5"> 
                                    <Button disabled={saving} loading={saving}  className="is-centered m-4 p-5  is-dark" onClick={sendInvitation}> <BiMailSend className="mr-4 is-size-5" /> Send Invitation </Button>

                                    </p>
                                    

                                </Box>
                            </ColumnAux>


                        
            )
        }



    return modal()

}

export default NewInvitations