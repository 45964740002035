import Parse from 'parse'
import { currentUserInfo, getCurrentWorkspaceId, isCurrentUser, validateMemberInfoRoles } from '../utilities/Login';
import { removeKeysFromUserInfo_Obj, removeStringFromArr } from '../utilities/Utilities';
import { appMeta, AssetStatus, AssetType, projectPointer, userPointer, workspacePointer, AssetCaptionType, AssetCreateStatusType, EntityType, EntityLogType, assetPointer, create_AssetForInfo, entityInfo_ForNotif, NotificationFor } from './EnumsAndPointers';
import { Notification } from './Notification';
import { Project } from './Project';
import { Timeline } from './Timeline';

const AssetClass = Parse.Object.extend("Asset");
const AssetVersionClass = Parse.Object.extend("AssetVersion");


export class AssetFilter {
    constructor(addedBy, approvedBy, rejectedBy, type) {
        this.addedBy = addedBy ?? []
        this.approvedBy = approvedBy ?? []
        this.rejectedBy = rejectedBy ?? []
        this.type = type ?? AssetCreateStatusType.all
    }

    static copyFrom = (obj) => {
        let ob = new AssetFilter()
        ob.addedBy = [...obj.addedBy]
        ob.approvedBy = [...obj.approvedBy]
        ob.rejectedBy = [...obj.rejectedBy]
        ob.type = obj.type
        return ob
    }

    isActive = () => {
        let isA = false
        if (this.addedBy.length > 0 ||
            this.approvedBy.length > 0 ||
            this.rejectedBy.length > 0
        ) {
            isA = true
        }

        if (this.type !== AssetCreateStatusType.all) {
            isA = true
        }
        return isA
    }

    count = () => {
        let count = 0
        if (this.addedBy.length > 0) {
            count += 1
        }
        if (this.approvedBy.length > 0) {
            count += 1
        }
        if (this.rejectedBy.length > 0) {
            count += 1
        }

        if (this.type !== AssetCreateStatusType.all) {
            count += 1
        }
        return count
    }

}

export class AssetVersion {
    constructor(id, url, text, type, size, dimensions, name, version, isCurrent, buckedId, downloadUrl, aspectRatio, uploadedOn, retiredOn, meta, assetId, asset, projectId, project, workspaceId, workspace, byUser, byUserInfo, createdAt, updatedAt, tags, appMeta) {
        this.id = id
        this.url = url
        this.text = text  // for text based file types
        this.type = type
        this.size = size
        this.dimensions = dimensions
        this.name = name
        this.version = version
        this.isCurrent = isCurrent
        this.buckedId = buckedId
        this.downloadUrl = downloadUrl
        this.aspectRatio = aspectRatio
        this.uploadedOn = uploadedOn
        this.retiredOn = retiredOn
        this.meta = meta
        this.isCurrent = isCurrent
        this.assetId = assetId
        this.asset = asset
        this.projectId = projectId
        this.project = project
        this.workspace = workspace
        this.byUser = byUser
        this.byUserInfo = byUserInfo
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.tags = tags
        this.appMeta = appMeta
    }

    thumbnailObject = (projectId) => {
        return {
            id: this.id,
            fileId: this.fileId,
            name: this.name,
            version: this.version,
            url: this.url,
            type: this.type,
            caption: this.caption,
            captionType: this.captionType,
            status: this.status,
            byUserInfo: this.byUserInfo,
            projectId: this.project.objectId ?? projectId,
            workspace: getCurrentWorkspaceId(),
            createStatus: this.createStatus
        }
    }

    static copyFrom(obj) {
        let ob = new AssetVersion()
        this.id = obj.id
        this.url = obj.url
        this.text = obj.text  // for text based file types
        this.type = obj.type
        this.size = obj.size
        this.dimensions = obj.dimensions
        this.name = obj.name
        this.version = obj.version
        this.isCurrent = obj.isCurrent
        this.buckedId = obj.buckedId
        this.downloadUrl = obj.downloadUrl
        this.aspectRatio = obj.aspectRatio
        this.uploadedOn = obj.uploadedOn
        this.retiredOn = obj.retiredOn
        this.meta = obj.meta
        this.assetId = obj.assetId
        this.asset = obj.asset
        this.projectId = obj.projectId
        this.project = obj.project
        this.workspace = obj.workspace
        this.byUser = obj.byUser
        this.byUserInfo = obj.byUserInfo
        this.createdAt = obj.createdAt
        this.updatedAt = obj.updatedAt
        this.tags = obj.tags
        this.appMeta = obj.appMeta
        return ob
    }

    static initFromPFObject = (obj) => {
        let np = new AssetVersion()
        if (!obj.id) {
            return null
        }

        np.id = obj.id
        np.url = obj.get("url")
        np.text = obj.get("text")  // for text based file types
        np.size = obj.get("size")
        np.dimensions = obj.get("dimensions")

        np.type = obj.get("type")
        np.name = obj.get("name")

        np.version = obj.get("version")
        np.isCurrent = obj.get("isCurrent") ?? false
        np.buckedId = obj.get("buckedId")
        np.downloadUrl = obj.get("downloadUrl")
        np.aspectRatio = obj.get("aspectRatio")

        np.uploadedOn = obj.get("uploadedOn")
        np.retiredOn = obj.get("retiredOn")

        np.meta = obj.get("meta")
        np.assetId = obj.get("assetId")
        np.asset = obj.get("asset")

        np.projectId = obj.get("projectId")
        np.project = obj.get("project")
        np.workspace = obj.get("workspace")

        let bu = obj.get("byUser")
        np.byUser = bu.id ?? isCurrentUser().id
        np.byUserInfo = obj.get("byUserInfo")

        np.createdAt = obj.get("createdAt")
        np.updatedAt = obj.get("updatedAt")

        np.tags = obj.get("tags")
        np.appMeta = obj.get("appMeta")
        return np
    }





    add = (callback) => {
        let ob = new AssetVersionClass()
        if (!this.name) {
            this.name = "Untitled"
        }


        // Create Asset Version with this


        ob.set("url", this.url)
        ob.set("text", this.text)
        ob.set("type", this.type)
        ob.set("size", this.size)
        ob.set("dimensions", this.dimensions)
        ob.set("name", this.name)
        ob.set("version", this.version)
        ob.set("isCurrent", this.isCurrent)
        ob.set("buckedId", this.buckedId)
        ob.set("downloadUrl", this.downloadUrl)
        ob.set("aspectRatio", this.aspectRatio)
        ob.set("uploadedOn", this.uploadedOn ?? new Date())
        ob.set("retiredOn", this.retiredOn)
        ob.set("meta", this.meta)
        ob.set("assetId", this.assetId)
        ob.set("asset", assetPointer(this.assetId))
        ob.set("projectId", this.projectId)
        ob.set("project", projectPointer(this.projectId))
        ob.set("workspace", this.workspace)

        const cu = isCurrentUser()
        if (this.byUser) {
            ob.set("byUser", this.byUser)
            ob.set("byUserInfo", this.byUserInfo)
        } else {
            ob.set("byUser", userPointer(cu.id))
            ob.set("byUserInfo", currentUserInfo())
        }
        ob.set("tags", this.tags)
        ob.set("appMeta", this.appMeta)


        ob.save()
            .then((av) => {
                this.id = av.id
                console.log('New AssetVersion Created')

                Timeline.updateTimline(EntityType.asset, this.assetId, av, EntityLogType.updated, "Created", "Version of Asset is added/uploaded " + av.id)

                callback(true, this, '')

            }, (error) => {
                console.log('Failed to create new AssetVersion, with error code: ' + error.message);
                callback(false, null, error.message)
            });
    }


    update = (callback) => {

        if (this.id == null) {
            callback(false, null, "Error : No object id to update AssetVersion")
            return
        }

        var query = new Parse.Query(AssetVersionClass);
        query.get(this.id)
            .then((ob) => {
                if (!this.name) {
                    this.name = "Untitled"
                }
                ob.set("text", this.text)
                ob.set("name", this.name)
                ob.set("version", this.version)
                ob.set("isCurrent", this.isCurrent)
                ob.set("retiredOn", this.retiredOn)
                ob.set("meta", this.meta)

                ob.save()
                callback(true, this, '')
            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });

    }


    delete = (callback) => {
        if (this.id) {
            callback(false, "Error : No id to delete AssetVersion ")
        }
        let thisObj = new AssetVersionClass()
        thisObj.id = this.id

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            callback(false, 'Error while deleting AssetVersion ', error.message)
        });
    }

}



export class Asset {
    constructor(id, fileId, name, url, version, currentVersionId, allVersionUrls, allVersionInfo, allVersionIds, bucketId, downloadUrl, type, sizeInBytes, dimensionInPx, aspectRatio, caption, captionType, meta, status, isFinalised, isArchived, isRejected, approvedBy, approvedByInfo, rejectedBy, rejectedByInfo, byUser, byUserInfo, finalisedBy, finalisedByInfo, finalisedAt, archivedBy, archivedByInfo, comments, project, workspace, owner, createdAt, updatedAt, folders, tags, createStatus, createRequestDesc, assetForInfo, thisAppMeta) {
        this.id = id
        this.fileId = fileId
        this.name = name
        this.version = version ?? 1
        this.url = url
        this.currentVersionId = currentVersionId
        this.allVersionUrls = allVersionUrls ?? []
        this.allVersionInfo = allVersionInfo ?? []
        this.allVersionIds = allVersionIds ?? []
        this.bucketId = bucketId
        this.downloadUrl = downloadUrl
        this.type = type ?? AssetType.image
        this.sizeInBytes = sizeInBytes
        this.dimensionInPx = dimensionInPx
        this.aspectRatio = aspectRatio

        this.caption = caption ?? ""
        this.captionType = captionType ?? AssetCaptionType.caption


        this.meta = meta
        this.status = status ?? AssetStatus.pending
        this.isFinalised = isFinalised ?? false
        this.isArchived = isArchived ?? false
        this.isRejected = isRejected ?? false

        this.approvedBy = approvedBy ?? []
        this.approvedByInfo = approvedByInfo ?? []

        this.rejectedBy = rejectedBy ?? []
        this.rejectedByInfo = rejectedByInfo ?? []

        this.byUser = byUser
        this.byUserInfo = byUserInfo

        this.finalisedBy = finalisedBy
        this.finalisedByInfo = finalisedByInfo
        this.finalisedAt = finalisedAt


        this.archivedBy = archivedBy
        this.archivedByInfo = archivedByInfo

        this.comments = comments ?? []
        this.project = project
        this.workspace = workspace
        this.owner = owner
        this.createdAt = createdAt
        this.updatedAt = updatedAt

        this.folders = folders ?? []
        this.tags = tags ?? []
        this.createStatus = createStatus ?? AssetCreateStatusType.createdAsset
        this.createRequestDesc = createRequestDesc

        this.assetForInfo = assetForInfo ?? create_AssetForInfo()

        this.appMeta = thisAppMeta ?? appMeta
        // Just for local new comment states
        this.currentAnnotations = null
    }


    thumbnailObject = (projectId) => {
        return {
            id: this.id,
            fileId: this.fileId,
            name: this.name,
            version: this.version,
            url: this.url,
            type: this.type,
            caption: this.caption,
            captionType: this.captionType,
            status: this.status,
            byUserInfo: this.byUserInfo,
            projectId: this.project.objectId ?? projectId,
            workspace: getCurrentWorkspaceId(),
            createStatus: this.createStatus,
            createRequestDesc: this.createRequestDesc
        }
    }



    static copyFrom(obj) {
        let ob = new Asset()
        ob.id = obj.id
        ob.fileId = obj.fileId
        ob.name = obj.name
        ob.version = obj.version
        ob.url = obj.url

        ob.currentVersionId = obj.currentVersionId
        ob.allVersionUrls = obj.allVersionUrls
        ob.allVersionInfo = obj.allVersionInfo ?? []
        ob.allVersionIds = obj.allVersionIds ?? []

        ob.bucketId = obj.bucketId
        ob.downloadUrl = obj.downloadUrl
        ob.type = obj.type
        ob.sizeInBytes = obj.sizeInBytes
        ob.dimensionInPx = obj.dimensionInPx
        ob.aspectRatio = obj.aspectRatio
        ob.caption = obj.caption
        ob.captionType = obj.captionType

        ob.meta = obj.meta
        ob.status = obj.status
        ob.isFinalised = obj.isFinalised
        ob.isArchived = obj.isArchived
        ob.isRejected = obj.isRejected
        ob.approvedBy = obj.approvedBy
        ob.approvedByInfo = obj.approvedByInfo
        ob.rejectedBy = obj.rejectedBy
        ob.rejectedByInfo = obj.rejectedByInfo
        ob.byUser = obj.byUser
        ob.byUserInfo = obj.byUserInfo


        ob.finalisedBy = obj.finalisedBy
        ob.finalisedByInfo = obj.finalisedByInfo
        ob.finalisedAt = obj.finalisedAt

        ob.archivedBy = obj.archivedBy
        ob.archivedByInfo = obj.archivedByInfo
        ob.comments = obj.comments
        ob.project = obj.project
        ob.workspace = obj.workspace
        ob.owner = obj.owner
        ob.createdAt = obj.createdAt
        ob.updatedAt = obj.updatedAt
        ob.folders = obj.folders
        ob.tags = obj.tags
        ob.createStatus = obj.createStatus
        ob.createRequestDesc = obj.createRequestDesc
        ob.assetForInfo = obj.assetForInfo
        ob.appMeta = obj.appMeta
        ob.currentAnnotations = obj.currentAnnotations
        return ob
    }




    static initFromPFObject = (obj) => {

        let np = new Asset()

        if (!obj.id) {
            return null
        }

        np.id = obj.id
        np.name = obj.get("name")
        np.type = obj.get("type")
        np.fileId = obj.get("fileId")
        np.url = obj.get("url")
        np.createdAt = obj.get("createdAt")
        np.updatedAt = obj.get("updatedAt")

        let bu = obj.get("byUser")
        np.byUser = bu.id ?? isCurrentUser().id
        np.byUserInfo = obj.get("byUserInfo")

        // this.workspace = workspace
        let wk = obj.get("workspace")
        np.workspace = wk.id

        np.status = obj.get("status")
        // np.isShared = obj.get("isShared")
        // this.sharedWith = sharedWith
        np.isArchived = obj.get("isArchived")
        np.version = obj.get("version")
        np.allVersionUrls = obj.get("allVersionUrls") ?? []

        np.currentVersionId = obj.get("currentVersionId") ?? obj.id // For
        np.allVersionUrls = obj.get("allVersionUrls") ?? []
        np.allVersionInfo = obj.get("allVersionInfo") ?? []
        np.allVersionIds = obj.get("allVersionIds") ?? []


        np.bucketId = obj.get("bucketId")
        np.downloadUrl = obj.get("downloadUrl")
        np.type = obj.get("type") ?? AssetType.image
        np.sizeInBytes = obj.get("sizeInBytes")

        np.dimensionInPx = obj.get("dimensionInPx")
        np.aspectRatio = obj.get("aspectRatio")

        np.caption = obj.get("caption") ?? ""
        np.captionType = obj.get("captionType") ?? AssetCaptionType.caption

        np.meta = obj.get("meta")
        np.status = obj.get("status") ?? AssetStatus.pending

        np.isFinalised = obj.get("isFinalised") ?? false
        np.isArchived = obj.get("isArchived") ?? false
        np.isRejected = obj.get("isRejected") ?? false

        np.approvedBy = obj.get("approvedBy") ?? []
        var approInfo = obj.get("approvedByInfo") ?? []
        approInfo = validateMemberInfoRoles(approInfo, obj.get("byUserInfo")) ?? []
        np.approvedByInfo = approInfo

        np.rejectedBy = obj.get("rejectedBy") ?? []
        var rejInfo = obj.get("rejectedByInfo") ?? []
        rejInfo = validateMemberInfoRoles(rejInfo, obj.get("byUserInfo")) ?? []
        np.rejectedByInfo = rejInfo

        np.byUser = obj.get("byUser").id
        np.byUserInfo = obj.get("byUserInfo")

        np.finalisedBy = obj.get("finalisedBy")
        np.finalisedByInfo = obj.get("finalisedByInfo")
        np.finalisedAt = obj.get("finalisedAt")

        np.archivedBy = obj.get("archivedBy")
        np.archivedByInfo = obj.get("archivedByInfo")

        np.comments = obj.get("comments") ?? []
        np.project = obj.get("project")
        np.workspace = obj.get("workspace")
        np.owner = obj.get("owner")
        np.createdAt = obj.get("createdAt")
        np.updatedAt = obj.get("updatedAt")

        np.folders = obj.get("folders")
        np.createStatus = obj.get("createStatus") ?? AssetCreateStatusType.createdAsset

        np.createRequestDesc = obj.get("createRequestDesc")

        np.tags = obj.get("tags")
        np.assetForInfo = obj.get("assetForInfo") ?? create_AssetForInfo()
        np.appMeta = obj.get("appMeta")

        return np

    }




    addApprovedBy = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        console.log("USRINFO")
        console.log(usrInfo)

        query.get(this.id)
            .then((ob) => {
                ob.addUnique("approvedBy", userId)
                ob.addUnique("approvedByInfo", usrInfo)

                // If they are in rejectlist just in case
                ob.remove("rejectedBy", userId)
                ob.removeAll("rejectedByInfo", usrInfo)

                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding approvedBy in asset =' + error.message)
            });
    }

    removeApprovedBy = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        console.log("USRINFO")
        console.log(usrInfo)

        query.get(this.id)
            .then((ob) => {
                // only what can be changed after creation
                ob.remove("approvedBy", userId)
                ob.removeAll("approvedByInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing approvedBy in asset =' + error.message)
            });
    }


    removeApprovedAndRejected = (userId, userInfo, callback) => {
        var query = new Parse.Query(AssetClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        console.log("USRINFO")
        console.log(usrInfo)

        query.get(this.id)
            .then((ob) => {
                // only what can be changed after creation
                ob.remove("approvedBy", userId)
                ob.removeAll("approvedByInfo", usrInfo)
                ob.remove("rejectedBy", userId)
                ob.removeAll("rejectedByInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                callback(false, 'Error while removing approvedBy in asset =' + error.message)
            });
    }

    addRejectedBy = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                ob.addUnique("rejectedBy", userId)
                ob.addUnique("rejectedByInfo", usrInfo)

                // If they are in approvelist just in case
                ob.remove("approvedBy", userId)
                ob.remove("approvedByInfo", usrInfo)

                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding rejectedBy in asset =' + error.message)
            });
    }


    removeRejectedBy = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                // only what can be changed after creation
                ob.remove("rejectedBy", userId)
                ob.remove("rejectedByInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing rejectedBy in asset =' + error.message)
            });
    }

    addToFolder = (folderId, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        query.get(this.id)
            .then((ob) => {
                ob.addUnique("folders", folderId)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding folders in asset =' + error.message)
            });
    }

    removeFromFolder = (folderId, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        query.get(this.id)
            .then((ob) => {
                ob.remove("folders", folderId)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing folders in asset =' + error.message)
            });
    }

    addVersionUrl = (versionUrl, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        query.get(this.id)
            .then((ob) => {
                ob.addUnique("allVersionUrls", versionUrl)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding versionUrl in asset =' + error.message)
            });
    }

    removeVersionUrl = (versionUrl, errorCallback) => {
        var query = new Parse.Query(AssetClass);
        query.get(this.id)
            .then((ob) => {
                ob.remove("allVersionUrls", versionUrl)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing versionUrl in asset =' + error.message)
            });
    }

    addWithVersion = (callback) => {
        let ob = new AssetClass()
        if (!this.name) {
            this.name = "Untitled"
        }

        // Create a version first 



    }




    add = (callback) => {
        let ob = new AssetClass()
        if (!this.name) {
            this.name = "Untitled"
        }
        ob.set("name", this.name)
        ob.set("fileId", this.fileId)
        ob.set("type", this.type)
        ob.set("url", this.url)

        ob.set("version", this.version)
        ob.set("status", this.status)
        ob.set("isArchived", this.isArchived)

        const cu = isCurrentUser()
        ob.set("byUser", userPointer(cu.id))

        // ob.set("byUser", cu)
        ob.set("byUserInfo", currentUserInfo())


        if (this.finalisedBy) { ob.set("finalisedBy", this.finalisedBy) }
        if (this.finalisedByInfo) { ob.set("finalisedByInfo", this.finalisedByInfo) }
        if (this.finalisedAt) { ob.set("finalisedAt", this.finalisedAt) }


        ob.set("project", projectPointer(this.project.id))

        // make workspace neceesary
        ob.set("workspace", workspacePointer(getCurrentWorkspaceId()))

        ob.set("isShared", this.isShared)
        if (this.sharedWith) { ob.set("sharedWith", this.sharedWith) }

        ob.set("allVersionUrls", this.allVersionUrls)

        if (this.bucketId) { ob.set("bucketId", this.bucketId) }
        if (this.downloadUrl) { ob.set("downloadUrl", this.downloadUrl) }
        if (this.sizeInBytes) { ob.set("sizeInBytes", this.sizeInBytes) }
        if (this.dimensionInPx) { ob.set("dimensionInPx", this.dimensionInPx) }
        if (this.aspectRatio) { ob.set("aspectRatio", this.aspectRatio) }

        ob.set("caption", this.caption)
        ob.set("captionType", this.captionType)


        if (this.meta) { ob.set("meta", this.meta) }
        ob.set("isFinalised", this.isFinalised)
        ob.set("isArchived", this.isArchived)
        ob.set("isRejected", this.isRejected)

        if (this.approvedBy) { ob.set("approvedBy", this.approvedBy) }
        if (this.approvedByInfo) { ob.set("approvedByInfo", this.approvedByInfo) }

        if (this.rejectedBy) { ob.set("rejectedBy", this.rejectedBy) }
        if (this.rejectedByInfo) { ob.set("rejectedByInfo", this.rejectedByInfo) }

        if (this.archivedBy) { ob.set("archivedBy", this.archivedBy) }
        if (this.archivedByInfo) { ob.set("archivedByInfo", this.archivedByInfo) }

        ob.set("comments", this.comments)
        ob.set("folders", this.folders)
        ob.set("tags", this.tags)
        ob.set("createStatus", this.createStatus)
        ob.set("createRequestDesc", this.createRequestDesc)
        ob.set("assetForInfo", this.assetForInfo)

        ob.set("appMeta", this.appMeta)

        if (this.owner) { ob.set("owner", this.owner) }

        ob.save()
            .then((proj) => {
                this.id = proj.id
                console.log('New Asset Created')

                Timeline.updateTimline(EntityType.asset, proj.id, proj, EntityLogType.created, "Created", "")

                if (this.createStatus === AssetCreateStatusType.createRequest) {
                    let entityInfoObj = entityInfo_ForNotif(EntityType.asset, this, [], null)
                    Project.getProjectDetails_AndNotify(this.project.id, NotificationFor.asset_AddedCreateRequest, entityInfoObj)
                }


                callback(true, this, '')

            }, (error) => {
                console.log('Failed to create new asset, with error code: ' + error.message);
                callback(false, null, error.message)
            });
    }





    update = (callback) => {

        if (this.id == null) {
            callback(false, null, "Error : No object id to update Asset")
            return
        }

        var query = new Parse.Query(AssetClass);
        query.get(this.id)
            .then((ob) => {

                if (!this.name) {
                    this.name = "Untitled"
                }
                ob.set("name", this.name)
                ob.set("fileId", this.fileId)
                ob.set("type", this.type)
                ob.set("url", this.url)

                ob.set("version", this.version)
                ob.set("status", this.status)
                ob.set("isArchived", this.isArchived)

                ob.set("isShared", this.isShared)
                if (this.sharedWith) { ob.set("sharedWith", this.sharedWith) }

                ob.set("currentVersionId", this.currentVersionId)
                ob.set("allVersionUrls", this.allVersionUrls)
                ob.set("allVersionInfo", this.allVersionInfo)
                ob.set("allVersionIds", this.allVersionIds)

                if (this.bucketId) { ob.set("bucketId", this.bucketId) }
                if (this.downloadUrl) { ob.set("downloadUrl", this.downloadUrl) }
                if (this.sizeInBytes) { ob.set("sizeInBytes", this.sizeInBytes) }
                if (this.dimensionInPx) { ob.set("dimensionInPx", this.dimensionInPx) }
                if (this.aspectRatio) { ob.set("aspectRatio", this.aspectRatio) }

                ob.set("caption", this.caption)
                ob.set("captionType", this.captionType)

                if (this.finalisedBy) { ob.set("finalisedBy", this.finalisedBy) } else (ob.unset("finalisedBy"))
                if (this.finalisedByInfo) { ob.set("finalisedByInfo", this.finalisedByInfo) } else (ob.unset("finalisedByInfo"))
                if (this.finalisedAt) { ob.set("finalisedAt", this.finalisedAt) } else (ob.unset("finalisedAt"))

                if (this.meta) { ob.set("meta", this.meta) }
                ob.set("isFinalised", this.isFinalised)
                ob.set("isArchived", this.isArchived)
                ob.set("isRejected", this.isRejected)

                // if (this.approvedBy) { ob.set("approvedBy", this.approvedBy) }
                // if (this.approvedByInfo) { ob.set("approvedByInfo", this.approvedByInfo) }

                // if (this.rejectedBy) { ob.set("rejectedBy", this.rejectedBy) }
                // if (this.rejectedByInfo) { ob.set("rejectedByInfo", this.rejectedByInfo) }

                // if (this.archivedBy) { ob.set("archivedBy", this.archivedBy) }
                // if (this.archivedByInfo) { ob.set("archivedByInfo", this.archivedByInfo) }

                ob.set("comments", this.comments)
                ob.set("folders", this.folders)
                ob.set("tags", this.tags)
                ob.set("createStatus", this.createStatus)
                ob.set("createRequestDesc", this.createRequestDesc)
                ob.set("assetForInfo", this.assetForInfo)
                ob.set("appMeta", this.appMeta)

                if (this.owner) { ob.set("owner", this.owner) }

                ob.save()
                callback(true, this, '')
            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });

    }

    delete = (callback) => {
        if (this.id) {
            callback(false, "Error : No id to delete Asset ")
        }
        let thisObj = new AssetClass()
        thisObj.id = this.id

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            callback(false, 'Error while deleting asset ', error.message)
        });
    }

    archive = (callback) => {
        const cu = isCurrentUser()
        this.isArchived = true
        this.archivedBy = userPointer(cu.id)
        this.archivedByInfo = currentUserInfo()
        this.update(callback)
    }

    static getAll = (forProjectId, filter, sort, callback) => {
        console.log('Searching For Assets ')
        var query = new Parse.Query(AssetClass);

        // Must have rules
        query.equalTo("project", projectPointer(forProjectId))

        // Apply pagination
        query.limit(100)

        // Apply filter
        // query.notEqualTo('isArchived', true)  // not fetching archived 

        // Apply sort
        query.descending('createdAt')


        query.find().then((objects) => {
            console.log('All Assets =')
            console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Asset.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Assets found in project ' + forProjectId)
            }

        }, (error) => {
            callback(false, [], error.message)
        })
    }



    static getTop = (forProjectId, number, callback) => {
        console.log('Searching For Assets ')
        var query = new Parse.Query(AssetClass);


        query.notEqualTo("createStatus", AssetCreateStatusType.createRequest)

        // Must have rules
        query.equalTo("project", projectPointer(forProjectId))

        query.include("url")

        // Apply pagination
        query.limit(number)

        // Apply filter
        query.notEqualTo('isArchived', true)  // not fetching archived 

        // Apply sort
        query.descending('createdAt')


        query.find().then((objects) => {
            // console.log('All Projects =')
            // console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Asset.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }

                let urls = allObjects.map((a) => { return a.url })

                callback(true, urls, '')
            } else {
                callback(true, [], 'No Assets found in project ' + forProjectId)
            }

        }, (error) => {
            callback(false, [], error.message)
        })
    }

}





export const approveAsset = (currentAsset) => {
    let ca = Asset.copyFrom(currentAsset)
    let cuInfo = currentUserInfo()


    ca.addApprovedBy(cuInfo.id, cuInfo, (errMsg) => {
        if (errMsg) {
            alert("Approval could not be marked. Reload and retry. Error = " + errMsg)
        }
    })

    // console.log("BEFORE ADDING APPROVE BY ")
    // console.log(ca.approvedByInfo)

    cuInfo = removeKeysFromUserInfo_Obj(cuInfo, true, true, true, true)

    ca.approvedBy = removeStringFromArr(ca.approvedBy ?? [], cuInfo.id)
    ca.rejectedBy = removeStringFromArr(ca.rejectedBy ?? [], cuInfo.id)
    ca.approvedByInfo = ca.approvedByInfo.length ? ca.approvedByInfo.filter((m) => { return m.id !== cuInfo.id }) : []
    ca.rejectedByInfo = ca.rejectedByInfo.length ? ca.rejectedByInfo.filter((m) => { return m.id !== cuInfo.id }) : []

    ca.approvedBy.push(cuInfo.id)
    ca.approvedByInfo.push(cuInfo)

    // console.log("APPROVED BY pre")
    // console.log(ca.approvedByInfo)

    let description = `Approval added : ${cuInfo.name}`
    Timeline.updateTimline(EntityType.asset, ca.id, ca, EntityLogType.updated, "Updated", description)

    // console.log("CURRENT ASSET IS")
    // console.log(ca)

    let entityInfoObj = entityInfo_ForNotif(EntityType.asset, ca, [], null)
    Project.getProjectDetails_AndNotify(ca.project.id, NotificationFor.asset_approved, entityInfoObj)



    // console.log("ADDED APPROVED BY");
    // console.log(ca.approvedByInfo);

    return ca
}

export const rejectAsset = (currentAsset) => {
    let ca = Asset.copyFrom(currentAsset)
    let cuInfo = currentUserInfo()

    ca.addRejectedBy(cuInfo.id, cuInfo, (errMsg) => {
        if (errMsg) {
            alert("Rejection could not be marked. Reload and retry. Error = " + errMsg)
        }
    })

    cuInfo = removeKeysFromUserInfo_Obj(cuInfo, true, true, true, true)

    ca.approvedBy = removeStringFromArr(ca.approvedBy ?? [], cuInfo.id)
    ca.rejectedBy = removeStringFromArr(ca.rejectedBy ?? [], cuInfo.id)
    ca.approvedByInfo = ca.approvedByInfo.length ? ca.approvedByInfo.filter((m) => { return m.id !== cuInfo.id }) : []
    ca.rejectedByInfo = ca.rejectedByInfo.length ? ca.rejectedByInfo.filter((m) => { return m.id !== cuInfo.id }) : []

    ca.rejectedBy.push(cuInfo.id)
    ca.rejectedByInfo.push(cuInfo)


    // console.log("REJECTED BY pre")
    // console.log(ca.rejectedByInfo)

    let description = `Rejection added : ${cuInfo.name}`
    Timeline.updateTimline(EntityType.asset, ca.id, ca, EntityLogType.updated, "Updated", description)

    console.log("CURRENT ASSET IS")
    console.log(ca)

    let entityInfoObj = entityInfo_ForNotif(EntityType.asset, ca, [], null)
    Project.getProjectDetails_AndNotify(ca.project.id, NotificationFor.asset_rejected, entityInfoObj)


    return ca
}

export const resetAssetApproval = (currentAsset) => {
    let ca = Asset.copyFrom(currentAsset)
    let cuInfo = currentUserInfo()

    ca.removeApprovedAndRejected(cuInfo.id, cuInfo, (errMsg) => {
        if (errMsg) {
            alert("Approval choice could not be reset. Reload and retry. Error = " + errMsg)
        }
    })

    // ca.approvedBy = ca.approvedBy.length ? ca.approvedBy.filter((m) => { return m !== cuInfo.id }) : []
    // ca.rejectedBy = ca.rejectedBy.length ? ca.rejectedBy.filter((m) => { return m !== cuInfo.id }) : []
    // ca.approvedByInfo = ca.approvedByInfo.length ? ca.approvedByInfo.filter((m)=> { return m.id !== cuInfo.id }) : []
    // ca.rejectedByInfo = ca.rejectedByInfo.length ? ca.rejectedByInfo.filter((m)=> { return m.id !== cuInfo.id }) : []

    ca.approvedBy = removeStringFromArr(ca.approvedBy ?? [], cuInfo.id)
    ca.rejectedBy = removeStringFromArr(ca.rejectedBy ?? [], cuInfo.id)
    ca.approvedByInfo = ca.approvedByInfo.length ? ca.approvedByInfo.filter((m) => { return m.id !== cuInfo.id }) : []
    ca.rejectedByInfo = ca.rejectedByInfo.length ? ca.rejectedByInfo.filter((m) => { return m.id !== cuInfo.id }) : []

    // console.log("APPROVED BY pre")
    // console.log(ca.approvedByInfo)

    // console.log("REJECTED BY pre")
    // console.log(ca.rejectedByInfo)


    let description = `Approval/Rejection removed : ${cuInfo.name}`
    Timeline.updateTimline(EntityType.asset, ca.id, ca, EntityLogType.updated, "Updated", description)

    // console.log("REMOVED APPROVED BY");
    // console.log(ca.approvedByInfo);

    return ca
}