import React, { useContext, useEffect, useState } from 'react'
import { Card, Heading, Media, Content, Tile, Box, Container, Section, Button, Tabs, Columns, Menu, Tag, Image, Form, Dropdown } from 'react-bulma-components';
import ColumnAux from '../../Containers/ColumnAux';
import Assets from '../Assets/Assets';
import Uploader from '../Upload/Uploader';

import previousImage from '../../assets/previous.svg'
import penIcon from '../../assets/pen-icon.png'
import { ProjectContext } from '../../Contexts/ProjectContext';
import { UploadContext } from '../../Contexts/UploadContext';

import { Project } from '../../Models/Project';
import { HiOutlineDotsHorizontal, HiDotsVertical } from 'react-icons/hi';
import { FiEdit2 } from 'react-icons/fi';
import { IoChevronBackCircle } from 'react-icons/io5';
import { IoMdAddCircle } from 'react-icons/io';
import { MdClear } from 'react-icons/md';
import { BiFilter, BiSort, BiSelectMultiple } from 'react-icons/bi';
import { RiFileAddFill } from 'react-icons/ri';
import { GrFormAdd } from 'react-icons/gr';
import AssetsProjectsWorkspaces_Img from '../../assets/Asstes-Projects-Workspace.png'

import Avatar from '../Calendar/Avatar';
import { getRandomColor, isPrivate, privateTag, randomCode } from '../../utilities/Utilities';
import AvatarStack from '../Calendar/AvatarStack';
import UserAvatar from '../Calendar/UserAvatar';
import UserPicker from '../Calendar/UserPicker'
import { AvatarStackFor, EntityType, SelectableEntity, UserPickerFor } from '../../Models/EnumsAndPointers';
import { canEditEntity } from '../../utilities/TpRules';
import AssetRequestModal from '../Assets/AssetRequestModal'
import moment from 'moment';
import randomColor from 'randomcolor';





const ProjectPage = (props) => {


    const sampleUsers = [
        {
            name: "Beth Castillo",
            imageUrl: "https://randomuser.me/api/portraits/women/24.jpg",
            id: randomCode(6),
            role: "Creator",
            isSelected: true,
        },
        {
            name: "Avery Schmidt",
            imageUrl: "https://randomuser.me/api/portraits/women/35.jpg",
            id: randomCode(6),
            role: "Member",
            isSelected: true,
        },
        {
            name: "Maxine Gray",
            imageUrl: "https://randomuser.me/api/portraits/women/81.jpg",
            id: randomCode(6),
            role: "Manager",
            isSelected: true,

        },
        {
            name: "Brooklyn Nelson",
            imageUrl: "https://randomuser.me/api/portraits/women/34.jpg",
            id: randomCode(6),
            role: "Reviewer",
            isSelected: true,
        },
    ]


    const [currentProject, setCurrentProject] = useContext(ProjectContext)


    const { uploads } = useContext(UploadContext)
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {

            if (uploads.newAsset) {
                // console.log("THIS IS IN PROJECT PAGE")
                // console.log("INCOMING UPLOADED ASSET IS")
                // console.log(uploads.newAsset)

                let duration = moment.duration(moment(new Date()).diff(uploads.newAsset.createdAt));
                // let mill_secondsAgo = duration.asMilliseconds()
                let secondsAgo = duration.asSeconds()

                // console.log("MOMENT mill_secondsAgo ")
                // console.log(mill_secondsAgo)
                // console.log("MOMENT secondsAgo ")
                // console.log(secondsAgo)


                if (secondsAgo < 1 && uploads.newAsset.project.id === project.id) {
                    console.log("Adding Asset ")

                    assetAdded(uploads.newAsset)
                }

                // If this ass

            }


        }
        return () => (isMounted = false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploads.newAsset])

    // const [upl, setCurrentProject] = useContext(ProjectContext)

    // const [projectId, setProjectId] = useState(props.project ? props.project.id : null)
    // const [isNewProject, setIsNewProject] = useState(props.isNewProject ?? false)
    const [isEditingProject, setIsEditingProject] = useState(props.isNewProject ? true : false)



    const [project, setProject] = useState(props.isNewProject ? Project.newProjectbyCurrentUser() : props.project)

    const [showUploader, setShowUploader] = useState(false)
    const [showUserPicker, setShowUserPicker] = useState(false)
    const [projectMembers, setProjectMembers] = useState(sampleUsers)

    const [addedAssets, setAddedAssets] = useState([])

    const [showAssetRequest, setShowAssetRequest] = useState(false)

    const [isSaving, setIsSaving] = useState(false)




    // useEffect(() => {
    //     // if (props.isNewProject == null) {
    //     //     setIsEditingProject(true)
    //     // }
    //     if (props.isNewProject) {
    //         setIsEditingProject(true)
    //     }
    // }, [props.isNewProject])

    const addProject = () => {
        let thisProj = Project.copyFrom({ ...project })
        thisProj.coverImageColor = getRandomColor()

        setIsSaving(true)

        thisProj.add((succ, pro, errMsg) => {
            setIsSaving(false)

            if (succ) {
                // alert("Project is saved")

                console.log("Incoming Project = ")
                console.log(pro)

                setIsEditingProject(() => {
                    return false
                })

                if (props.projectAdded) {
                    props.projectAdded(pro)
                }
                setProject(pro)

                // setProjectId(pro.id)
                // Change current project context
                setCurrentProject(pro)

                // setIsNewProject(false)
                // setTimeout(() => {

                // }, 300)

            } else {
                alert("Error while saving project - " + errMsg)
            }
        })

    }



    const saveProject = () => {

        // if no objectid then call add project
        if (!project.id) {
            addProject()
            return
        }


        let thisProj = Project.copyFrom({ ...project })

        setIsSaving(true)

        thisProj.update((succ, pro, errMsg) => {
            setIsSaving(false)

            if (succ) {
                // alert("Project is update")
                setProject(pro)
                // setIsNewProject(false)
                setIsEditingProject(false)
            } else {
                alert("Error while updating project - " + errMsg)
            }
        })



    }

    const foldersComp = () => {
        return (
            <div className="has-background-white ">
                <Container className=" is-clearfix" >
                    <Tabs radiusless={true} type="toggle" size="small" align="right" className=" mr-0 pr-4 is-radiusless is-black z-5 mt-0 ml-4 mb-0 p-1  " textWeight="semibold">
                        <Tabs.Tab className=" " active>View Stages </Tabs.Tab>
                        <Tabs.Tab className=" " >View Folders        </Tabs.Tab>
                        {/* <Tabs.Tab> <span className="">Rejected</span> </Tabs.Tab> */}
                    </Tabs>

                    <Tabs type="boxed" radiusless align="left" className="stick-to-top-61 ml-4 mr-4 z-10  pt-4 is-radiusless is-thick bottomBorderLight  backblur-white has-text-black " textWeight="semibold">
                        <Tabs.Tab className=" minW140" active>All Files <Button rounded size="small" textWeight="semibold" className=" mx-4 is-size-7 has-text-grey-light has-background-transparent p-1 is-borderless" >20</Button> </Tabs.Tab>
                        <Tabs.Tab className=" minW140" >Needs Review        <Button rounded size="small" textWeight="semibold" className=" mx-4 is-size-7 has-text-grey-light has-background-transparent p-1 is-borderless" >20</Button> </Tabs.Tab>
                        <Tabs.Tab className=" minW140" >Needs Change        <Button rounded size="small" textWeight="semibold" className=" mx-4 is-size-7 has-text-grey-light has-background-transparent p-1 is-borderless" >20</Button> </Tabs.Tab>
                        <Tabs.Tab className=" minW140" >Finalised         <Button rounded size="small" textWeight="semibold" className=" mx-4 is-size-7 has-text-grey-light has-background-transparent p-1 is-borderless" >20</Button> </Tabs.Tab>
                        {/* <Tabs.Tab> <span className="">Rejected</span> </Tabs.Tab> */}
                    </Tabs>
                </Container>
            </div>
        )
    }



    const projectNameChanged = (e) => {
        let thisProj = Project.copyFrom({ ...project })
        thisProj.name = e.target.value
        setProject(thisProj)
    }

    const projectDescriptionChanged = (e) => {
        let thisProj = Project.copyFrom({ ...project })
        thisProj.description = e.target.value
        setProject(thisProj)
    }

    const toggleEditProjectPressed = () => {
        setIsEditingProject(!isEditingProject)
    }

    const coverImageTypeChanged = () => {
    }

    const toggleEditProjectMembers = (val) => {
        setShowUserPicker(val ?? !showUserPicker)
    }

    const assetAdded = (ast) => {
        let assts = [...addedAssets]
        console.log("UPLOADED ASSET IS")
        console.log(ast)
        // IF it is same project update it
        if (ast.project.id) {
            if (ast.project.id === project.id) {

                let alreadyExists = assts.filter((a) => a.id === ast.id)
                if (alreadyExists.length) {
                    console.log("ADDED ASSET IS ALREADY ON UI")
                } else {
                    console.log("ASSET ADDED TO CURRENT PROJECT PAGE")
                    assts.unshift(ast)
                    setAddedAssets(assts)
                }
            }
        }
    }

    const projectIsUpdated = (proj) => {
        setProject(proj)
    }


    const projectMembersChanged = (membersInfo) => {
        // let proj = { ...project }
        // let mm = [...proj.members]
        // let mmInfo = [...proj.membersInfo]

        //     mm = members.map((m) => {
        //         return m.id
        //     })

        //     mmInfo = membersInfo 

        // let projCopy = Project.copyFrom(proj)


        setProjectMembers(membersInfo)
        setShowUserPicker(false)
    }


    // asst.createStatus = AssetCreateStatusType.createRequest

    const assetRequestAdded = (asst) => {
        let asts = [...addedAssets]
        asts.push(asst)
        setAddedAssets(asts)
        setShowAssetRequest(false)
    }

    const toggleAssetRequest = (val) => {
        setShowAssetRequest(val ?? !showAssetRequest)
    }

    const projectAddPressed = (val) => {
        if (val === "upload") {
            toggleUploader(true)
        }
        if (val === "request") {
            // alert("Add Asset Request")
            setShowAssetRequest(true)
        }
    }

    const projectDropdownOptionPressed = (val) => {
        if (val === "editName") {
            toggleEditProjectPressed(true)
        }
        if (val === "delete") {
            alert("Archive Project now")
        }
    }

    const projectHeader = () => {

        return (
            // <Box shadowless radiusless className=" stick-to-top-60 backblur-bis z-20 pb-4 pt-0 bottomBorderHeavy">
            <Box shadowless radiusless className=" stick-to-top backblur-white  z-20 pb-3 pt-0 bottomBorderHeavy mb-0">

                {/* <Image
                        src="https://images.unsplash.com/photo-1577760258779-e787a1733016?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                        className="maxH140 object-fit-cover overflow-hidden mb-0"
                        backgroundColor="light"
                        radiusless={true}
                    /> */}
                <Columns className=" is-mobile m-0 p-0 " >
                    <Columns.Column size={1} className="m-0 px-0 pb-0">
                        <Button rounded className="pl-1 mx-0 ml-1  is-borderless has-background-transparent" onClick={props.goBack} >
                            <Image src={previousImage} className="backButtonImage is-vcentered opacity35" color="primary" />
                            {/* <IoChevronBackCircle className="is-size-3" /> */}
                        </Button>

                    </Columns.Column>
                    <Columns.Column className="mb-0 pb-0">
                        {
                            isEditingProject ?
                                <div className="mx-4 mb-2 pb-0">
                                    <ColumnAux size={6} className="has-text-centered">
                                        <Form.Field className="is-centered">
                                            {/* <Form.Label>Username</Form.Label> */}
                                            <Form.Control fullwidth={false} className="is-centered">
                                                <Form.Input
                                                    // color="success"
                                                    size="normal"
                                                    placeholder="Add Project Name"
                                                    textWeight="semibold"
                                                    value={project.name}
                                                    // color="danger"
                                                    className="is-centered"
                                                    onChange={(e) => { projectNameChanged(e) }}
                                                />
                                            </Form.Control>
                                            {/* <Form.Help color="danger">Cannot be empty</Form.Help> */}
                                        </Form.Field>
                                        <Form.Field>
                                            {/* <Form.Label>Username</Form.Label> */}
                                            <Form.Control className="maxH250 overflow-hidden" fullwidth={false}>
                                                <Form.Textarea
                                                    // color="success"
                                                    value={project.description}
                                                    size="normal"
                                                    placeholder="Description"
                                                    textWeight="light"
                                                    textSize={6}
                                                    type="textarea"
                                                    className="p-4 "
                                                    textColor="grey"
                                                    onChange={(e) => { projectDescriptionChanged(e) }}
                                                />
                                            </Form.Control>
                                        </Form.Field>

                                        {/* <div>
                                                    <Form.Field>
                                                        <Form.Label size="small" className="has-text-grey-light has-text-mono">Cover Image</Form.Label>
                                                        <Form.Control>
                                                            <Form.Field kind="group">
                                                                <Form.Select
                                                                    size={'small'}
                                                                >
                                                                    <option value="firstImage">First image asset</option>
                                                                    <option value="color">Color</option>
                                                                </Form.Select>
                                                                <Form.Label className="ml-4 mt-2 has-text-grey-light is-size-7" textWeight="normal">
                                                                    <span className="">
                                                                        something
                                                                        </span>
                                                                </Form.Label>
                                                            </Form.Field>
                                                        </Form.Control>
                                                    </Form.Field>
                                                </div> */}



                                        <Button disabled={isSaving} loading={isSaving} color="black" className="mt-4 px-6" onClick={saveProject} >Save</Button>


                                    </ColumnAux>
                                </div>
                                :

                                <div className="pb-0">
                                    <Heading className="mb-2 mx-4 " size={5}>{project.name}</Heading>

                                    <p className="has-text-grey-light has-text-weight-semibold  mx-4 mb-0 is-size-8" >{project.description}</p>
                                </div>

                        }
                    </Columns.Column>
                    <Columns.Column narrow className="m-0  pb-0">
                        <Columns gap={0} className='m-0 p-0' vCentered>
                            <Columns.Column className='m-0 p-0' narrow>
                                {
                                    isPrivate(SelectableEntity.projects, project) ?
                                        privateTag()
                                        :
                                        null
                                }
                            </Columns.Column>
                            <Columns.Column className='m-0 p-0'>
                                {
                                    isEditingProject ? null :
                                        <Box shadowless className="p-0 m-0 has-background-transparent">
                                            {/* Member Avatars */}
                                            <AvatarStack for={AvatarStackFor.projectMembers} entity={EntityType.project} project={project} users={project.membersInfo} editUsersPressed={toggleEditProjectMembers} />
                                        </Box>
                                }
                            </Columns.Column>
                        </Columns>


                    </Columns.Column>

                    <Columns.Column narrow className="m-0 px-0 pb-0">
                        {
                            project.id ?

                                <Button.Group className="pb-0">
                                    {
                                        isEditingProject ?
                                            <Button size="normal" className="px-4 mx-0 is-borderless has-text-grey has-background-transparent is-hover-dark" onClick={toggleEditProjectPressed}>
                                                <MdClear className="is-size-4 p-0 has-text-grey " />
                                            </Button>
                                            : null
                                    }

                                    {
                                        isEditingProject ? null :

                                            <Dropdown right renderAs="button" className=" mx-0  has-background-transparent p-0  is-small is-borderless  " label={
                                                <Button size="small" rounded className="px-2 mt-2 py-2 mx-0 is-borderless has-text-grey has-background-warning " >
                                                    {/* <RiFileAddFill className="is-size-5 p-0 has-text-grey " /> */}
                                                    {/* <Button size="small" rounded className=" px-3 mx-4 is-pulled-right " > <GrFormAdd className=" has-text-warning m-0 is-size-5" /></Button> */}
                                                    <GrFormAdd className=" has-text-warning m-0 is-size-5" />
                                                </Button>
                                            } onChange={(val) => { projectAddPressed(val) }} >
                                                <Dropdown.Item renderAs="a" value="upload" className="has-text-left">Upload</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item renderAs="a" value="request" className="has-text-left">Create / Request</Dropdown.Item>
                                            </Dropdown>


                                    }
                                    {
                                        isEditingProject ? null :
                                            <>
                                                {
                                                    canEditEntity(EntityType.project, project.byUserInfo, project.membersInfo) ?
                                                        <Dropdown right renderAs="button" className=" mx-0  has-background-transparent p-0  is-small is-hover-dark is-borderless mt-minus-6 " label={
                                                            <HiDotsVertical className="is-size-5 mt-3 mx-0 p-0 has-text-grey " />
                                                        } onChange={(val) => { projectDropdownOptionPressed(val) }} >
                                                            <Dropdown.Item renderAs="a" value="editName" className="has-text-left"> Edit Name</Dropdown.Item>
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item renderAs="a" value="delete" className="has-text-danger has-text-left">Archive </Dropdown.Item>
                                                        </Dropdown>
                                                        :
                                                        null
                                                }
                                            </>

                                    }

                                </Button.Group>

                                : null

                        }


                        {/* <Button onClick={() => { toggleUploader(true) }} >
                            + Add Asset
                        </Button> */}
                    </Columns.Column>
                </Columns>
            </Box>
        )
    }


    const assetsWithFilterAndSort = () => {
        return (
            <Container>
                {/* 
                <Tabs radiusless={true} type="toggle" size="small" align="right" className="mt-2 mr-0 pr-4 is-radiusless  z-5 mt-0 ml-4 mb-0 p-1  " textWeight="semibold">
                    <Tabs.Tab className=" " active>Filter </Tabs.Tab>
                    <Tabs.Tab className=" " >Sort        </Tabs.Tab>
                </Tabs> */}

                <Assets toFetch={props.isNewProject ? false : true} isNewProject={props.isNewProject} assets={addedAssets} forProjectId={project.id} project={project} />
            </Container>
        )
    }


    const folderColumns = (child) => {
        return (
            <Container>

                <Columns >
                    <Columns.Column size={3}>
                        <Box className="mt-6">
                            Menu goes here
                        </Box>
                    </Columns.Column>
                    <Columns.Column>
                        {child}
                    </Columns.Column>
                </Columns>
            </Container>

        )
    }

    const toggleUploader = (override) => {
        if (override) {
            setShowUploader(override)
        } else {
            setShowUploader(!showUploader)
        }
    }

    const unselectAll = () => {
        setShowUploader(false)
        setIsEditingProject(false)
    }





    return (
        <div className=" is-fullHeight overflow-hidden">
            {showAssetRequest ? <AssetRequestModal close={() => { toggleAssetRequest(false) }} project={project} assetRequestAdded={assetRequestAdded} /> : null}
            {showUploader ? <Uploader project={project} close={unselectAll} existingAssetsCount={addedAssets.count} assetAdded={assetAdded} /> : null}
            {showUserPicker ? <UserPicker for={UserPickerFor.projectMember} project={project} projectUpdated={projectIsUpdated} close={() => { toggleEditProjectMembers(false) }} /> : null}

            {projectHeader()}
            <div className=" h-85vh mt-0 overflow-scroll">
                {/* <ColumnAux className="stick-to-top z-10 " isMiddle={true} size={12}> */}
                {/* <Button.Group className="is-pulled-right mt-0" >
                        <Button backgroundColor="grey-lightest" className=" has-text-grey is-borderless is-hover-dark " size="small"  > <BiFilter className="mr-1 is-size-6" /> Filter</Button>
                        <Button backgroundColor="grey-lightest" className={" has-text-grey is-borderless is-hover-dark "} size="small"  > <BiSort className="mr-1" /> Sort</Button>
                        <Button backgroundColor="grey-lightest" className=" has-text-grey is-borderless is-hover-dark " size="small"> <BiSelectMultiple className="mr-1 is-size-6" /> Select</Button>
                    </Button.Group> */}
                {/* <Heading subtitle spaced={false} size={7} > Sorted by Created by you </Heading> */}
                {/* </ColumnAux> */}

                {project.id ?
                    <div>
                        {/* {foldersComp()} */}
                        {assetsWithFilterAndSort()}
                        {/* {folderColumns(assetsWithFilterAndSort())} */}
                    </div>
                    : null}
            </div>


        </div>
    )
}

export default ProjectPage