import React, { useState } from 'react'
import { Card, Heading, Media, Content, Tile, Box, Image, Tag, Button, Dropdown, Columns } from 'react-bulma-components';
import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
import PreviewModal from '../../Components/Preview/PreviewModal';
import { ImCheckmark, ImCheckmark2, ImCross } from 'react-icons/im';
import { BsLayoutSidebarInsetReverse, BsLayoutSidebarReverse } from 'react-icons/bs';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { HiOutlineDotsHorizontal, HiOutlineDotsVertical, HiOutlineCheckCircle, HiCheckCircle, HiOutlineXCircle, HiXCircle, HiOutlineCheck, HiOutlineX, HiOutlineViewGrid, HiUpload, HiOutlineLightBulb, HiLightBulb } from 'react-icons/hi';
import { approveAsset, Asset, rejectAsset, resetAssetApproval } from '../../Models/Asset';
import LazyLoad from 'react-lazyload';
import { AnimationType, AssetCreateStatusType, AssetStatus, AssetType, AvatarStackFor, entityInfo_ForNotif, EntityLogType, EntityType, infoArrayWithoutCurrentUser, NotificationFor, userPointer } from '../../Models/EnumsAndPointers';
import { removeKeysFromUserInfo_Obj } from '../../utilities/Utilities';
import { BsFileEarmarkPlus } from 'react-icons/bs';
import AvatarStack from '../Calendar/AvatarStack';
import { Timeline } from '../../Models/Timeline';
import ShowAnimation from '../Common/ShowAnimation';
import LottieView from '../Common/LottieView';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import createIcon from '../../assets/createIcon.png'
import createImage from '../../assets/createImage.png'
import createText from '../../assets/createText.png'
import createVideo from '../../assets/createVideo.png'

import { Project } from '../../Models/Project';
// import { useLottie } from "lottie-react";
// import Lottie_star from '../../assets/Lottie_star.json';


const AssetCard = (props) => {

    const [showPreview, setShowPreview] = useState(false)
    const [savingApprovalChoice, setSavingApprovalChoice] = useState(false)
    const [saving, setSaving] = useState(false)
    const [showAnim, setShowAnim] = useState(null)

    const [showLottie, setShowLottie] = useState(null)


    // const star_options = {
    //     animationData: Lottie_star,
    //     loop: false,
    //   };

    //   const { LottieView } = useLottie(star_options);



    const togglePreview = (val) => {
        setShowPreview(val ?? !showPreview)
    }

    const toggleLottie = (val) => {
        setShowLottie(val)
        setTimeout(() => {
            setShowLottie(null)
        }, 2000)
    }

    const dropdownValueClick = (val) => {
        if (val === "preview") {
            togglePreview(true)
            return
        }

        if (val === "upload") {
            // show uploader for this asset
            return
        }

        let assetCopy = Asset.copyFrom(props.asset)
        let cu = isCurrentUser()
        let cuInfo = currentUserInfo()

        if (val === "finalise") {
            // finalise this asset
            assetCopy.status = AssetStatus.finalised
            assetCopy.isFinalised = true
            assetCopy.isArchived = false
            assetCopy.finalisedBy = userPointer(cu.id)
            assetCopy.finalisedByInfo = removeKeysFromUserInfo_Obj(cuInfo, true, false, true, true)
            assetCopy.finalisedAt = new Date()
            // setShowAnim(AnimationType.assetFinalised)
            toggleLottie(AnimationType.assetFinalised)

        }

        if (val === "pending") {
            // finalise tihs project
            assetCopy.status = AssetStatus.pending
            assetCopy.isFinalised = false
            assetCopy.isArchived = false
        }

        if (val === "archive") {
            // finalise tihs project
            // assetCopy.status = AssetStatus.archived
            assetCopy.isArchived = true
            assetCopy.archivedBy = userPointer(cu.id)
            assetCopy.archivedByInfo = removeKeysFromUserInfo_Obj(cuInfo, true, false, true, true)
        }

        if (val === "unArchive") {
            assetCopy.isArchived = false
            assetCopy.archivedBy = null
            assetCopy.archivedByInfo = null
        }

        setSaving(true)

        assetCopy.update((succ, result, errMsg) => {
            if (saving !== null) {
                setSaving(false)
                if (succ) {

                    let entityInfoObj = entityInfo_ForNotif(EntityType.asset, result, [], null)


                    if (val === "finalise") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", "Is Finalised")
                        Project.getProjectDetails_AndNotify(result.project.id, NotificationFor.asset_finalised, entityInfoObj)

                    }
                    if (val === "pending") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", "Is Un-Finalised. Now Pending.")
                    }
                    if (val === "archive") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.archived, "Updated", "Is Archived")
                        Project.getProjectDetails_AndNotify(result.project.id, NotificationFor.asset_archived, entityInfoObj)

                    }
                    if (val === "unArchive") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", "Is Un-Archived.")
                    }


                    if (props.assetChanged) {
                        props.assetChanged(result)
                    }
                } else {
                    console.log(errMsg);
                    alert("An error occured. Could not update asset")
                }
            }
        })
    }


    const dropDownOpts = () => {

        // <Dropdown.Item renderAs="a" value="info">Info</Dropdown.Item>
        // <Dropdown.Item renderAs="a" value="uploadVersion">Upload Version</Dropdown.Item>
        // <Dropdown.Item renderAs="a" value="preview">Preview</Dropdown.Item>
        // <Dropdown.Divider />
        // <Dropdown.Item renderAs="a" className="has-text-danger" value="delete">Delete</Dropdown.Item>


        // Finalised
        // Un-Finalise
        // Archive
        // Un-Archive

        let comps = []

        if (props.asset.status === AssetStatus.pending) {
            comps.push(<Dropdown.Item renderAs="a" value="upload">Upload New</Dropdown.Item>)
            comps.push(<Dropdown.Item renderAs="a" value="preview">Preview</Dropdown.Item>)
            comps.push(<Dropdown.Divider />)
            comps.push(<Dropdown.Item renderAs="a" value="finalise">Finalise</Dropdown.Item>)
            comps.push(<Dropdown.Divider />)
            comps.push(<Dropdown.Item renderAs="a" textColor='danger' value="archive">Archive</Dropdown.Item>)
        }

        if (props.asset.status === AssetStatus.finalised) {
            comps.push(<Dropdown.Item renderAs="a" value="upload">Upload New</Dropdown.Item>)
            comps.push(<Dropdown.Item renderAs="a" value="preview">Preview</Dropdown.Item>)
            comps.push(<Dropdown.Divider />)
            comps.push(<Dropdown.Item renderAs="a" value="pending">Un-Finalise</Dropdown.Item>)
            comps.push(<Dropdown.Divider />)
            comps.push(<Dropdown.Item renderAs="a" textColor='danger' value="archive">Archive</Dropdown.Item>)
        }

        if (props.asset.isArchived) {
            comps.push(<Dropdown.Item renderAs="a" value="unArchive">Un-Archive</Dropdown.Item>)
            // comps.push(<Dropdown.Item renderAs="a" value="finalise">Set Finalised</Dropdown.Item>)
            // comps.push(<Dropdown.Divider />)
            // comps.push(<Dropdown.Item renderAs="a" value="finalise">Un-Archive</Dropdown.Item>)
        }

        return comps
    }




    const approvalObj = () => {

        let cu = currentUserInfo()

        let isApproved = false
        let isRejected = false


        if (props.asset.approvedBy) {
            if (props.asset.approvedBy.includes(cu.id)) {
                isApproved = true
            }
        }
        if (props.asset.rejectedBy) {
            if (props.asset.rejectedBy.includes(cu.id)) {
                isRejected = true
            }
        }

        return { "isApproved": isApproved, "isRejected": isRejected, "isAnyAction": isApproved || isRejected }

    }


    const changeApproval = (val) => {
        if (savingApprovalChoice) {
            return
        }

        let ca = null
        if (val === "approve") {

            ca = approveAsset(props.asset)
            toggleLottie(AnimationType.assetApproved)
        }

        if (val === "reject") {
            ca = rejectAsset(props.asset)
            toggleLottie(AnimationType.assetRejected)
        }

        if (val === "unapprove" || val === "unreject") {
            ca = resetAssetApproval(props.asset)
        }
        setSavingApprovalChoice(true)
        setTimeout(() => {
            setSavingApprovalChoice(false)
            props.assetChanged(ca)
        }, 1000 * 1.2)
    }

    const createImageComp = () => {

        
        if (props.asset.type === AssetType.text) {
            return  <img alt ="Create Text Asset" src ={createText} className ="img-h200 object-fit-contain object-position-center-xy  " />
        }

        if (props.asset.type === AssetType.image) {
            return  <img alt = "Create Img Asset" src ={createImage} className ="img-h200 object-fit-contain object-position-center-xy " />
        }

        if (props.asset.type === AssetType.video) {
            return  <img alt = "Create Video Asset" src ={createVideo} className ="img-h200 object-fit-contain object-position-center-xy " />
        }

        return <img alt = "Create Asset" src ={createIcon} className ="img-h200 object-fit-contain object-position-center-xy  " />

    }

    const comp = () => {

        let approval = approvalObj()
        let approvalOpts = []

        let allApprovedBy = props.asset.approvedByInfo
        let allRejectedBy = props.asset.rejectedByInfo

        let isFinsalised = props.asset.isFinalised

        // let approvedByInfoArr = infoArrayWithoutCurrentUser(allApprovedBy)
        // let rejectedByInfoArr = infoArrayWithoutCurrentUser(allRejectedBy)

        if (approval.isAnyAction === false) {
            approvalOpts.push(<Dropdown.Item renderAs="a" value="approve" className="">
                <p>Approve</p>
                <p> {allApprovedBy.length ?
                    <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allApprovedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                    : null
                } </p>
            </Dropdown.Item>)
            approvalOpts.push(<Dropdown.Divider />)
            approvalOpts.push(<Dropdown.Item renderAs="a" value="reject" className="">
                <p>Reject</p>
                <p> {allRejectedBy.length ?
                    <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allRejectedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                    : null
                } </p>
            </Dropdown.Item>)
        }

        if (approval.isApproved === true) {
            approvalOpts.push(<Dropdown.Item renderAs="a" value="reject" className="">
                <p>Reject</p>
                <p> {allRejectedBy.length ?
                    <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allRejectedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                    : null
                } </p>
            </Dropdown.Item>)
            approvalOpts.push(<Dropdown.Divider />)
            approvalOpts.push(<Dropdown.Item renderAs="a" value="unapprove" className="">
                <p>Remove my approval</p>
                <p> {allApprovedBy.length ?
                    <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allApprovedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                    : null
                } </p>
            </Dropdown.Item>)
        }


        if (approval.isRejected === true) {
            approvalOpts.push(<Dropdown.Item renderAs="a" value="approve" className="">
                <p>Approve</p>
                <p> {allApprovedBy.length ?
                    <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allApprovedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                    : null
                } </p>
            </Dropdown.Item>)
            approvalOpts.push(<Dropdown.Divider />)
            approvalOpts.push(<Dropdown.Item renderAs="a" value="unreject" className="">
                <p>Remove my rejection</p>
                <p> {allRejectedBy.length ?
                    <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allRejectedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                    : null
                } </p>
            </Dropdown.Item>)
        }


        // {(approval.isAnyAction === false || approval.isRejected === true) ? <Dropdown.Item renderAs="a" value="approve" className="has-text-grey">Approve</Dropdown.Item> : null}
        // {(approval.isApproved === true) ? <Dropdown.Item renderAs="a" value="unapprove" className="has-text-success">Remove my approval</Dropdown.Item> : null}
        // <Dropdown.Divider />
        // {(approval.isAnyAction === false || approval.isApproved === true) ? <Dropdown.Item renderAs="a" value="unreject" className="has-text-danger">Reject</Dropdown.Item> : null}
        // {(approval.isRejected === true) ? <Dropdown.Item renderAs="a" value="approve" className="has-text-grey">Remove my rejection</Dropdown.Item> : null}


        let imgUrl = props.asset.url ? props.asset.url : null
        let isRequest = false

        if (!imgUrl) {
            if (props.asset.createStatus === AssetCreateStatusType.createRequest) {
                isRequest = true
            }
        }

        return (
            <Card className="maxH420 maxH420 backblurWithSiteBg " shadowless radiusless >
                {showPreview ?
                    <PreviewModal asset={props.asset} close={() => { togglePreview(false) }} />
                    : null}
                {showAnim ? <ShowAnimation name={showAnim} close={() => { setShowAnim(null) }} /> : null}


                <Card.Header shadowless className="">
                    {/* <GrCheckbox className="is-size-4 has-text-grey-lighter opacity35 is-hover-dark" /> */}
                    {/* <GrCheckboxSelected className="is-size-4 has-text-grey-lighter opacity35 is-hover-dark" /> */}

                </Card.Header>
                <Box className="has-background-transparent is-centered has-text-centered" shadowless radiusless>
                    {/* <LazyLoad scroll={true} height={200} offset={100} once> */}

                    {
                        // isRequest ?
                        // <Box shadowless className="square-48 ml-0 m-0 p-1 has-text-centered is-bordered-light border-radius-6 has-background-transparent ">
                        //     <BsFileEarmarkPlus className="has-text-grey-lighter is-size-5 mt-1 mb-0 " />
                        //     <div className="is-size-9 has-text-grey-light mt-minus-4 "> CREATE </div>
                        // </Box>
                        // <Image size={128}  src={createIcon} />
                        // :
                        <LazyLoad throttle={200} height={300} offset={100}>
                            <div className=' wh100Perc  ' onClick={() => { props.assetIsSelected(props.asset) }}>

                                {
                                    isRequest ?
                                        <>
                                            {
                                                props.asset.createRequestDesc || props.asset.caption ?
                                                    <div className='img-h200 p-2 has-background-grey-lightest overflow-scroll has-text-grey-light  has-text-left has-text-weight-medium'>
                                                        <p className='is-size-7 has-text-grey-light has-text-weight-medium'>
                                                            {
                                                                props.asset.type === AssetType.text ?
                                                                    <>
                                                                        <p className='is-size-7 has-text-grey-light has-text-weight-medium'>  Text only</p>
                                                                        <p className='is-size-5'>{props.asset.caption}</p>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p className='is-size-7 has-text-grey-light has-text-weight-medium'>  <HiLightBulb className='is-size-6' /> Idea/Request for {props.asset.type} </p>
                                                                        <p className='is-size-5 '>{props.asset.createRequestDesc}</p>
                                                                    </>
                                                            }
                                                        </p>
                                                    </div>

                                                    :
                                                   <> {createImageComp()} </>  
                                            }
                                        </>
                                        :
                                        <img
                                            alt="Asset"
                                            // size='1by1'
                                            src={isRequest ? createIcon : imgUrl}
                                            className="img-h200 object-fit-contain object-position-center-xy   "
                                        // radiusless={true}
                                        />

                                }


                            </div>


                            {showLottie ?
                                <LottieView type={showLottie} />
                                :
                                null
                            }

                        </LazyLoad>

                    }




                    <Card.Content className="p-0 ">
                        <div>
                            {/* <hr className="mb-3 mt-2" /> */}
                            <Button.Group className="is-centered is-bordered-bottom-1" size="small">

                                {
                                    saving ?
                                        <Button loading={saving} disabled={saving} size="normal" className=" has-background-transparent is-borderless " />
                                        :

                                        <Dropdown up renderAs="button" textAlign="left" className=" is-arrowless has-background-transparent is-hover-dark is-borderless" color="light" label={
                                            <HiOutlineDotsHorizontal className=" mt-2 is-size-4 has-text-grey" />
                                        } onChange={(val) => { dropdownValueClick(val) }}>
                                            {
                                                dropDownOpts()
                                            }
                                        </Dropdown>
                                }

                                <Dropdown up right renderAs="button" textAlign="left" className=" mr-minus-10 mx-0 mb-0 p-0 has-background-transparent  is-small    is-borderless " label={
                                    <Button loading={savingApprovalChoice} disabled={savingApprovalChoice} className="has-background-transparent has-text-black  mt-3 is-hover-dark is-borderlessWithoutHover " size="small"  >
                                        {
                                            approval.isAnyAction ?
                                                <>
                                                    {approval.isApproved ?
                                                        <ImCheckmark className="ml-1 is-size-6  is-light " />
                                                        :
                                                        <ImCross className="ml-1 is-size-6p4  is-light " />
                                                    }
                                                </>
                                                :
                                                <>
                                                    <span className=" has-text-grey "> &nbsp;Approve?</span>
                                                    <ImCheckmark2 className="ml-1 is-size-6p7 has-text-grey" />
                                                </>
                                        }
                                    </Button>

                                } onChange={(val) => { changeApproval(val) }} >
                                    {
                                        approvalOpts.map((m) => { return m })
                                    }
                                </Dropdown>
                            </Button.Group>
                        </div>

                        <Content className="mt-4 mb-0 has-text-centered is-size-8 pt-1" textColor="grey">


                            {
                                isFinsalised ?
                                    <div className='has-text-centered my-0 py-0'> <RiCheckboxCircleFill className=' is-size-4 has-text-success my-0 py-0' /> </div>
                                    : <>

                                        <p className="has-text-weight-medium mb-3 text-lines-1">{props.asset.name}</p>

                                        {/* {
                                            isRequest && props.asset.name === "Untitled" ?
                                                <p className="has-text-weight-medium mb-3 text-lines-1">{props.asset.createRequestDesc ?? "Request not elaborated"}</p>
                                                :
                                                <p className="has-text-weight-medium mb-3 text-lines-1">{props.asset.name}</p>
                                        } */}
                                    </>
                            }

                        </Content>
                        {/* <Heading subtitle size={6} textSize={7} className=" is-centered m-0 p-0">
                            <Tag color="info" className="is-light m-1">Ongoing</Tag>
                            <Tag color="success" className="is-light m-1">Approved</Tag>
                            <Tag color="danger" className="is-light m-1">Rejected</Tag>
                        </Heading> */}
                        {/* <Box radiusless shadowless className="has-text-ceneterd" alignContent="center"> */}
                        {/* <div className="p-0 m-0 has-text-centered">
                            <Tag color="light" className="is-light m-1">Ongoing</Tag>
                            <Tag color="light" className="is-light m-1">Approved</Tag>
                        </div> */}


                        {/* </Box> */}
                    </Card.Content>
                </Box>

            </Card>
        )
    }

    return comp()
}

export default AssetCard