import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Tag, Section, Content, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon, Table } from 'react-bulma-components';
import Avatar from './Avatar';
import UserAvatar from './UserAvatar';
import { AvatarStackFor, EntityType } from '../../Models/EnumsAndPointers';
import { MdGroupAdd } from 'react-icons/md';
import { canEditEntity } from '../../utilities/TpRules';
import { randomAlphabets } from '../../utilities/Utilities';



const AvatarStack = (props) => {

    const title = () => {
        let t = "Project Members"
        if (props.for !== AvatarStackFor.projectMembers) {
            t = ""
        }
        return t
    }

    const subTitle = () => {
        let s = "Only these users can view and participate in the assets, tasks & events of this project."
        if (props.for !== AvatarStackFor.projectMembers) {
            s = ""
        }
        return s
    }



    const addWhat = () => {
        let w = "Members"
        if (props.for !== AvatarStackFor.projectMembers) {
            w = ""
        }
        return w
    }


    const canBeEdited = () => {
        let c = false
        if (props.entity === EntityType.project) {
            if (props.project.id) {
                c = canEditEntity(EntityType.project, props.project.byUserInfo, props.project.membersInfo)
            }
        }
        return c
    }

    const maxDisplay = 3


    const justAvatars = () => {

        if (props.nonEditableAvatars){
            return nonEditableAvatars()
        }

        let users = props.users
        let displayUsers = users

        // console.log("DISPLAY USERS ARE");
        // console.log(displayUsers);

        let plusOtherUsers = 0
        if (users.length > maxDisplay) {
            displayUsers = users.slice(0, maxDisplay)
            plusOtherUsers = users.length - maxDisplay
        }

        return <Box shadowless className={" has-background-transparent m-0 p-0 " + props.className}>
            <Tag.Group className="m-0 p-0">
                {users.length === 0 ?
                    null
                    :
                    <>
                        {
                            displayUsers.map((su) => {
                                return <Avatar key={randomAlphabets(10)} small={props.small ?? null} medium={props.medium ?? null} imageUrl={su.imageUrl} className="ml-minus-6" />
                                // return <UserAvatar isRemovable={true} removePressed={userRemovePressed} infoObj={su} nameWrap={true} />
                            })
                        }
                        {
                            (plusOtherUsers !== 0) ?
                                <Avatar key={randomAlphabets(10)} small={props.small ?? null} medium={props.medium ?? null} showText={`+${plusOtherUsers}`} className=" ml-minus-6" />
                                : null
                        }
                    </>
                }
                {(users.length <= 3 && !props.cannotAdd) ?
                    <Button rounded className="has-background-light has-text-grey is-size-8 mx-1" size="small" onClick={props.editUsersPressed} ><MdGroupAdd className="is-size-4" /></Button>
                    : null
                }
            </Tag.Group>

        </Box>
    }

    const nonEditableAvatars = () => {
        let users = props.users
        let displayUsers = users

        // console.log("DISPLAY USERS ARE");
        // console.log(displayUsers);

        let plusOtherUsers = 0
        if (users.length > maxDisplay) {
            displayUsers = users.slice(0, maxDisplay)
            plusOtherUsers = users.length - maxDisplay
        }

        return <Box shadowless className="py-0 px-1 m-0 has-background-transparent">
            {/* Member Avatars */}
            <Tag.Group className="m-0 p-0">

                {users.length === 0 ?
                    null
                    :
                    <>
                        {
                            displayUsers.map((su) => {
                                return <Avatar key={randomAlphabets(10)} small={props.small ?? null} medium={props.medium ?? null} imageUrl={su.imageUrl} className="ml-minus-6" />
                                // return <UserAvatar isRemovable={true} removePressed={userRemovePressed} infoObj={su} nameWrap={true} />
                            })
                        }
                        {
                            (plusOtherUsers !== 0) ?
                                <Avatar key={randomAlphabets(10)} small={props.small ?? null} medium={props.medium ?? null} showText={`+${plusOtherUsers}`} className=" ml-minus-6" />
                                : null
                        }
                    </>

                }

            </Tag.Group>
        </Box>

    }

    const allAvatars = () => {
        let users = props.users
        let displayUsers = users

        // console.log("DISPLAY USERS ARE");
        // console.log(displayUsers);

        let plusOtherUsers = 0
        if (users.length > maxDisplay) {
            displayUsers = users.slice(0, maxDisplay)
            plusOtherUsers = users.length - maxDisplay
        }


        return <Dropdown right hoverable={true} className=" is-borderless p-0 m-0 has-background-transparent"
            icon={
                <Box shadowless className="py-0 px-1 m-0 has-background-transparent">
                    {/* Member Avatars */}
                    <Tag.Group className="m-0 p-0">

                        {users.length === 0 ?
                            null
                            :
                            <>
                                {
                                    displayUsers.map((su) => {
                                        return <Avatar key={randomAlphabets(10)} small={props.small ?? null} medium={props.medium ?? null} imageUrl={su.imageUrl} className="ml-minus-6" />
                                        // return <UserAvatar isRemovable={true} removePressed={userRemovePressed} infoObj={su} nameWrap={true} />
                                    })
                                }
                                {
                                    (plusOtherUsers !== 0) ?
                                        <Avatar key={randomAlphabets(10)} small={props.small ?? null} medium={props.medium ?? null} showText={`+${plusOtherUsers}`} className=" ml-minus-6" />
                                        : null
                                }
                            </>

                        }

                        {(users.length <= 3 && !props.cannotAdd) ?
                            <Button rounded className="has-background-light has-text-grey is-size-8 mx-1" size="small" onClick={props.editUsersPressed} ><MdGroupAdd className="is-size-4" /></Button>
                            : null
                        }


                    </Tag.Group>
                </Box>
            }
        >
            <Box shadowless className="mt-0 pt-2">
                <p className="has-text-grey is-size-7 has-text-weight-semibold"> {title()} </p>
                <p className="has-text-grey-light has-text-weight-medium is-size-7 "> {subTitle()} </p>
                <Box shadowless className="h-30vh overflow-scroll is-clipped">
                    {
                        <Table className="minW240 " hoverable={false} striped={false} bordered={false} fullwidth>

                            <tbody>
                                {
                                    users.map((usr) => {
                                        return <tr key={usr.id}>
                                            <td className="is-borderless">
                                                <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} />
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        // <Tag.Group className="mt-2 p-0">
                        //     {
                        //         users.map((su) => {
                        //             return <UserAvatar key={su.id} nameWrap showRole={true} infoObj={su} className="ml-minus-6" />
                        //                             // <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={true} clearBg={true} noButton={true} addPressed={addPressedOnUser} />
                        //             // return <UserAvatar isRemovable={true} removePressed={userRemovePressed} infoObj={su} nameWrap={true} />
                        //         })
                        //     }
                        // </Tag.Group>
                    }
                    <br />
                </Box>

                {
                    canBeEdited() ?
                        <Button size="small" color="light" fullwidth onClick={props.editUsersPressed}>
                            Edit
                        </Button>
                        : null
                }

            </Box>
        </Dropdown>





    }




    return props.justAvatars ? justAvatars() : allAvatars()


}

export default AvatarStack