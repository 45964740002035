import React, { useRef, useState } from 'react'
import { Form, Card, Heading, Media, Content, Tabs, Box, Image, Tag, Button, Dropdown, Modal, Panel, Columns, Hero, Container, Message, Level, Section, Navbar } from 'react-bulma-components';
import { previewBoxType, ContentType, AssetCaptionType, SocialMedia_Platform } from '../../Models/EnumsAndPointers';

import randomUser1 from './sampleAssets/randomUser1.jpg'
import randomUser2 from './sampleAssets/randomUser2.jpg'
import randomUser3 from './sampleAssets/randomUser3.jpg'
import randomUser4 from './sampleAssets/randomUser4.jpg'
import randomUser5 from './sampleAssets/randomUser5.jpg'
import randomUser6 from './sampleAssets/randomUser6.jpg'
import randomUser7 from './sampleAssets/randomUser7.jpg'
import randomUser8 from './sampleAssets/randomUser8.jpg'
import randomUser9 from './sampleAssets/randomUser9.jpg'
import randomUser10 from './sampleAssets/randomUser10.jpg'
import randomUser11 from './sampleAssets/randomUser11.jpg'
import randomUser12 from './sampleAssets/randomUser12.jpg'

import phoneImage from './sampleAssets/tp_phone.png'
import statusBarLight from './sampleAssets/status-bar-light.png'
import statusBarDark from './sampleAssets/status-bar-dark.png'



import { insta_postBox, insta_profileBox, instaNav, instaNav_mobile, instaNav_mobile_profile, insta_postCard, insta_profileCard, insta_profileCard_mobile } from './Instagram_UIs'
import { fb_companyBox, fb_profileBox, fb_postBox, fbNav, fb_profileBox_mobile, fb_companyBox_mobile, fb_postBox_mobile } from './Facebook_UIs'
import { li_profileBox, li_companyBox, li_eventBox, li_postBox, liNav, li_postBox_mobile, li_profileBox_mobile, li_companyBox_mobile, li_eventBox_mobile } from './LinkedIn_UIs'
import { twitter_profileBox, twitter_postBox, twitter_postBox_mobile, twitter_profileBox_mobile } from './Twitter_UIs'
import { yt_Desktop_UI, yt_videoPage, yt_profileCard_mobile, yt_feed_mobile, yt_home_feed } from './Youtube_UIs'
import { common_storyBox } from './CommonStory_UIs'



import { AiFillPushpin, AiOutlineDesktop, AiOutlineMobile } from 'react-icons/ai';
import { VscColorMode } from 'react-icons/vsc';



import ColumnAux from '../../Containers/ColumnAux';


export const randomUsers = () => {
    return [
        { name: "Brayden Wright", image: randomUser1 },
        { name: "Bertha Barnett", image: randomUser2 },
        { name: "Charlotte Simpson", image: randomUser3 },
        { name: "Noah Walters", image: randomUser4 },
        { name: "Aiden Henry", image: randomUser5 },
        { name: "Ronnie Pearson", image: randomUser6 },
        { name: "Alfred Black", image: randomUser7 },
        { name: "Bobbie Hunter", image: randomUser8 },
        { name: "Jeremy Peck", image: randomUser9 },
        { name: "Elizabeth Alexander", image: randomUser10 },
        { name: "Mason Powell", image: randomUser11 },
        { name: "Albert Rodriquez", image: randomUser12 },
    ]
}

export const rowOfColumns_leftImage = (image, imageSize, isRounded, text, textColor, textSize, textWeight, imageClassNames) => {
    return <Columns className='p-0 m-0  mb-2 is-vcentered ' mobile={true}>
        <Columns.Column className='p-0 m-0' narrow>
            <Image rounded={isRounded} alt="icon" size={imageSize ?? 16} className={imageClassNames} src={image} />
        </Columns.Column>
        <Columns.Column className={`p-0 pl-2 m-0 ml-2 ${textSize} ${textColor} ${textWeight} `} >
            {text}
        </Columns.Column>
    </Columns>
}




const VisualiseModal = (props) => {


    // selectedPreview
    // var filterObj = {
    //     "type": null,
    //     "boxtype": null,
    //     "category": null,
    //     "pageCategory": null,
    //     "channel": null
    // }

    const [box, setBox] = useState(props.selectedPreview ? props.selectedPreview.boxtype : null)
    const [mediaCategory, setMediaCategory] = useState(props.selectedPreview ? props.selectedPreview.category : null)
    const [storyFill, setStoryFill] = useState(false)
    const [postFill, setPostFill] = useState(true)
    const [dpFill, setDPFill] = useState(false)
    const [bgFill, setBGFill] = useState(true)

    const [isPinned, setIsPinned] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isDarkMode, setIsDarkMode] = useState(false)



    const content = () => {

        var cont = null

        let assetUrl = props.asset.url ?? null
        let assetCaption = ""

        if (props.asset.caption) {
            if (props.asset.captionType) {
                let type = props.asset.captionType

                let aptTypes = [
                    AssetCaptionType.title,
                    AssetCaptionType.caption,
                    AssetCaptionType.content,
                    AssetCaptionType.description,
                    // AssetCaptionType.idea
                ]

                if (aptTypes.includes(type)) {
                    assetCaption = props.asset.caption
                }
            }
        }




        if (box === previewBoxType.instagram_profileBox) {

            if (isMobile) {

                cont = <div>
                    {instaNav_mobile_profile("TestPostApp")}
                    {/* {insta_postCard((mediaCategory === previewCategory.profilePic ? assetUrl : null), (mediaCategory === previewCategory.post ? assetUrl : null), "John Mayer Doe", "Senior Director, Brand & Creative at TestPost", assetCaption, postFill, setPostFill, true)} */}
                    {insta_profileCard_mobile(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        null,
                        null,
                        "TestPostApp",
                        "TestPost opens the door to interesting collaborations and experiences.",
                        "testpost.app",
                        dpFill,
                        true)}
                </div>

            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">Instagram User</p> */}
                    {insta_profileBox(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        null,
                        null,
                        "TestPostApp",
                        "TestPost opens the door to interesting collaborations and experiences.",
                        "testpost.app",
                        dpFill
                    )}
                </div>
            }
        }
        if (box === previewBoxType.instagram_postBox) {

            if (isMobile) {
                cont = <div>
                    {instaNav_mobile()}
                    {insta_postCard((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.post ? assetUrl : null), "John Mayer Doe", "Senior Director, Brand & Creative at TestPost", assetCaption, postFill, setPostFill, true)}
                    {insta_postCard(mediaCategory === ContentType.profilePic ? assetUrl : null, (mediaCategory === ContentType.post ? assetUrl : null), "John Mayer Doe", "Senior Director, Brand & Creative at TestPost", assetCaption, postFill, setPostFill, true)}
                </div>
            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">Instagram Post</p> */}
                    {insta_postBox(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        (mediaCategory === ContentType.post ? assetUrl : null),
                        "John Mayer Doe",
                        "Senior Director, Brand & Creative at TestPost",
                        //         `Art Direction, Design & Illustration for the new Wannabe™'s eCommerce, an independent store selling high-end iconic collectibles of action figures and props.
                        // Coming out early this winter ☃️
                        // `,   
                        assetCaption,
                        postFill,
                        setPostFill
                    )}
                </div>
            }


        }

        if (box === previewBoxType.facebook_companyBox) {
            if (isMobile) {
                // fb_companyBox_mobile
                cont = <div>
                    {/* <p className="has-text-grey-light">Facebook Company</p> */}
                    {fb_companyBox_mobile((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost User", "Product/Service")}
                    <br className="my-6" />
                </div>
            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">Facebook Company</p> */}
                    {fb_companyBox((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost User", "Product/Service")}
                    <br className="my-6" />
                </div>
            }

        }

        if (box === previewBoxType.facebook_profileBox) {

            if (isMobile) {
                cont = <div>
                    {/* <p className="has-text-grey-light">Facebook User</p> */}
                    {fb_profileBox_mobile((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost User", "Bio of the user.")}
                    <br className="my-6" />
                </div>

            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">Facebook User</p> */}
                    {fb_profileBox((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost User", "Bio of the user.")}
                    <br className="my-6" />
                </div>
            }


        }
        if (box === previewBoxType.facebook_postBox) {

            if (isMobile) {
                cont = <div>
                    {fb_postBox_mobile(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        (mediaCategory === ContentType.post ? assetUrl : null),
                        "John Mayer Doe",
                        "Senior Director, Brand & Creative at TestPost",
                        //         `Art Direction, Design & Illustration for the new Wannabe™'s eCommerce, an independent store selling high-end iconic collectibles of action figures and props.
                        // Coming out early this winter ☃️
                        // `,   
                        assetCaption
                    )}
                </div>

            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">Facebook Post</p> */}
                    {fb_postBox(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        (mediaCategory === ContentType.post ? assetUrl : null),
                        "John Mayer Doe",
                        "Senior Director, Brand & Creative at TestPost",
                        //         `Art Direction, Design & Illustration for the new Wannabe™'s eCommerce, an independent store selling high-end iconic collectibles of action figures and props.
                        // Coming out early this winter ☃️
                        // `,   
                        assetCaption
                    )}
                </div>
            }



        }

        if (box === previewBoxType.twitter_profileBox) {

            if (isMobile) {
                cont = <div>
                    {/* <p className="has-text-grey-light">Twitter User</p> */}
                    {twitter_profileBox_mobile((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost", "TestPostApp", "Design testing, finalising and preview are now collaborative and easy.", "Globe", "testpost.app", "April 2011", "169", "2.7M", null)}
                    <br className="my-6" />
                </div>

            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">Twitter User</p> */}
                    {twitter_profileBox((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost", "TestPostApp", "Design testing, finalising and preview are now collaborative and easy.", "Globe", "testpost.app", "April 2011", "169", "2.7M", null)}
                    <br className="my-6" />
                </div>
            }


        }
        if (box === previewBoxType.twitter_postBox) {


            if (isMobile) {
                cont = <div>
                    {/* <p className="has-text-grey-light">Twitter Post</p> */}
                    {twitter_postBox_mobile(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        (mediaCategory === ContentType.post ? assetUrl : null),
                        "John Mayer Doe",
                        "Senior Director, Brand & Creative at TestPost",
                        //         `Art Direction, Design & Illustration for the new Wannabe™'s eCommerce, an independent store selling high-end iconic collectibles of action figures and props.
                        // Coming out early this winter ☃️
                        // `,   
                        assetCaption
                    )}
                </div>

            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">Twitter Post</p> */}
                    {twitter_postBox(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        (mediaCategory === ContentType.post ? assetUrl : null),
                        "John Mayer Doe",
                        "Senior Director, Brand & Creative at TestPost",
                        //         `Art Direction, Design & Illustration for the new Wannabe™'s eCommerce, an independent store selling high-end iconic collectibles of action figures and props.
                        // Coming out early this winter ☃️
                        // `,   
                        assetCaption
                    )}
                </div>
            }
        }

        if (box === previewBoxType.linkedIn_profileBox) {
            if (isMobile) {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn User</p> */}
                    {li_profileBox_mobile((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "Bianca Davis", "Marketing Director at TestPost | Brand Strategost | HCI | Culture First | Red Dot Design Award Winner | #Collaborate | Book Author | Podcaster", "London, United Kingdom", "500", null, null)}
                    <br className="my-6" />
                </div>
            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn User</p> */}
                    {li_profileBox((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "Bianca Davis", "Marketing Director at TestPost | Brand Strategost | HCI | Culture First | Red Dot Design Award Winner | #Collaborate | Book Author | Podcaster", "London, United Kingdom", "500", null, null)}
                    <br className="my-6" />
                </div>
            }
        }


        if (box === previewBoxType.linkedIn_companyBox) {

            if (isMobile) {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn Company</p> */}
                    {li_companyBox_mobile((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost", "Make creative testing and approval humanly easy and quick", "Software Service", "India, IN", "1,256", "17")}
                    <br className="my-6" />
                </div>
            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn Company</p> */}
                    {li_companyBox((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "TestPost", "Make creative testing and approval humanly easy and quick", "Software Service", "India, IN", "1,256", "17")}
                    <br className="my-6" />
                </div>
            }


        }

        if (box === previewBoxType.linkedIn_eventBox) {

            if (isMobile) {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn Event</p> */}
                    {li_eventBox_mobile((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "UX Night: Design Beyond Devices with Cheryl Platz", "UX Salon", "Jun 16, 2021, 9:15 PM - 11:30 PM (your local time)", "Eve Adamson and 1,268 other attendees")}
                    <br className="my-6" />
                </div>
            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn Event</p> */}
                    {li_eventBox((mediaCategory === ContentType.profilePic ? assetUrl : null), (mediaCategory === ContentType.coverPic ? assetUrl : null), "UX Night: Design Beyond Devices with Cheryl Platz", "UX Salon", "Jun 16, 2021, 9:15 PM - 11:30 PM (your local time)", "Eve Adamson and 1,268 other attendees")}
                    <br className="my-6" />
                </div>
            }


        }

        if (box === previewBoxType.linkedIn_postBox) {

            if (isMobile) {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn Post</p> */}
                    {li_postBox_mobile(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        (mediaCategory === ContentType.post ? assetUrl : null),
                        "John Mayer Doe",
                        "Senior Director, Brand & Creative at TestPost",
                        //         `Art Direction, Design & Illustration for the new Wannabe™'s eCommerce, an independent store selling high-end iconic collectibles of action figures and props.
                        // Coming out early this winter ☃️
                        // `,   
                        assetCaption
                    )}
                </div>
            } else {
                cont = <div>
                    {/* <p className="has-text-grey-light">LinkedIn Post</p> */}
                    {li_postBox(
                        (mediaCategory === ContentType.profilePic ? assetUrl : null),
                        (mediaCategory === ContentType.post ? assetUrl : null),
                        "John Mayer Doe",
                        "Senior Director, Brand & Creative at TestPost",
                        //         `Art Direction, Design & Illustration for the new Wannabe™'s eCommerce, an independent store selling high-end iconic collectibles of action figures and props.
                        // Coming out early this winter ☃️
                        // `,   
                        assetCaption
                    )}
                </div>
            }
        }

        if (box === previewBoxType.youtube_profileBox) {
            if (isMobile) {
                cont = <div>
                    {yt_profileCard_mobile(mediaCategory === ContentType.profilePic ? assetUrl : null, mediaCategory === ContentType.coverPic ? assetUrl : null, "TestPost", null)}
                </div>
            } else {
                cont = <div>
                    {yt_Desktop_UI(mediaCategory === ContentType.profilePic ? assetUrl : null, mediaCategory === ContentType.coverPic ? assetUrl : null, "TestPost", null)}
                </div>
            }
        }

        if (box === previewBoxType.youtube_coverBox) {
            if (isMobile) {
                cont = <div>
                    {yt_profileCard_mobile(mediaCategory === ContentType.profilePic ? assetUrl : null, mediaCategory === ContentType.coverPic ? assetUrl : null, "TestPost", null)}
                </div>
            } else {
                cont = <div>
                    {yt_Desktop_UI(mediaCategory === ContentType.profilePic ? assetUrl : null, mediaCategory === ContentType.coverPic ? assetUrl : null, "TestPost", null)}
                </div>
            }
        }


        if (box === previewBoxType.youtube_postBox) {
            if (isMobile) {
                cont = <div>
                    {/* image, videoName, dpImage, username, views, agoText */}
                    {yt_feed_mobile(mediaCategory === ContentType.post ? assetUrl : null, assetCaption ?? "A good title to the video", null, "TestPost", "9.8k", "3 weeks ago")}
                </div>

            } else {
                // cont = <div>
                //     {yt_videoPage(mediaCategory === previewCategory.profilePic ? assetUrl : null, mediaCategory === previewCategory.post ? assetUrl : null, "TestPost", assetCaption ?? "TestPost makes teams faster & productive")}
                // </div>
                cont = yt_home_feed(mediaCategory === ContentType.post ? assetUrl : null, assetCaption ?? "A good title to the video", null, "TestPost", "9.8k", "3 weeks ago")
            }

        }



        if (box === previewBoxType.instagram_storyBox) {
            cont = <div>
                {/* <p className="has-text-grey-light">Instagram Story</p> */}
                {common_storyBox(null, assetUrl ?? null, "testpost.app", storyFill, setStoryFill)}
                <br className="my-6" />
            </div>
        }

        if (box === previewBoxType.linkedIn_storyBox) {
            cont = <div>
                <p className="has-text-grey-light">LinkedIn Story</p>
                {common_storyBox(null, assetUrl ?? null, "testpost.app", storyFill, setStoryFill)}
                <br className="my-6" />
            </div>
        }
        if (box === previewBoxType.facebook_storyBox) {
            cont = <div>
                {/* <p className="has-text-grey-light">Facebook Story</p> */}
                {common_storyBox(null, assetUrl ?? null, "testpost.app", storyFill, setStoryFill)}
                <br className="my-6" />
            </div>
        }
        if (box === previewBoxType.twitter_storyBox) {
            cont = <div>
                {/* <p className="has-text-grey-light">Twitter Story</p> */}
                {common_storyBox(null, assetUrl ?? null, "testpost.app", storyFill, setStoryFill)}
                <br className="my-6" />
            </div>
        }

        return <div onClick={(e) => { e.stopPropagation() }}> {cont} </div>

    }


    const pinPressed = () => {
        // alert("Pin Preview")
        setIsPinned(!isPinned)
    }


    const blurPressed = () => {
        // alert("Blur pressed")
        props.close()
    }

    const currentBg = () => {

        let bgColor = "has-background-white"



        bgColor = " has-background-light "

        if (props.selectedPreview.channel === SocialMedia_Platform.facebook) {
            bgColor = " has-background-fb-light "
        }


        if (props.selectedPreview.channel === SocialMedia_Platform.twitter) {
            bgColor = " has-background-white "
        }

        if (props.selectedPreview.category === ContentType.story) {
            bgColor = " has-background-black-ter "
        }

        if (isDarkMode) {
            // bgColor = "has-background-black"
            bgColor = "has-background-dark"

        } else if (isMobile) {
            bgColor = "has-background-grey-lighter"

        }

        return bgColor

    }


    const widthBreakpoint = () => {
        let bp = "widescreen"
        if (props.selectedPreview.channel === SocialMedia_Platform.facebook) {
            bp = "fullhd"
        }

        if (props.selectedPreview.channel === SocialMedia_Platform.twitter) {
            bp = "fullhd"
        }

        if (props.selectedPreview.channel === SocialMedia_Platform.youtube) {
            bp = "noContainer"
        }

        return bp
    }

    const platformNavigation = () => {
        let nav = null

        if (props.selectedPreview.category === ContentType.story) {
            return nav
        }

        if (props.selectedPreview.channel === SocialMedia_Platform.instagram) {
            nav = instaNav()
        }

        if (props.selectedPreview.channel === SocialMedia_Platform.linkedIn) {
            nav = liNav()
        }

        if (props.selectedPreview.channel === SocialMedia_Platform.facebook) {
            nav = fbNav()
        }

        return nav
    }



    const desktopUI = () => {

        let breakpoint = widthBreakpoint()


        if (breakpoint === "noContainer") {
            return <div>
                {platformNavigation()}
                {content()}
            </div>
        }

        return <>
            {platformNavigation()}
            <Container breakpoint={widthBreakpoint()} max className=' my-0 py-0' >
                {content()}
            </Container>
        </>
    }

    const mobileUI = () => {

        let bgColor = "white"
        let statusBarImg = statusBarDark

        if (isDarkMode) {
            bgColor = "black"
            statusBarImg = statusBarLight
        }

        return <Columns className='m-0 has-background-transparent  m-0 p-0 '>
            <Columns.Column className=''  >
            </Columns.Column>
            <Columns.Column className="is-centered m-0 p-0" size={6}>
                <ColumnAux isMiddle={true} className=" m-0 p-0 ">
                    <Box radiusless shadowless className=' has-background-transparent  p-0'>
                        <Box radiusless shadowless className=' has-background-transparent mobileBox_Wrapper is-clipped p-0   m-0 '>
                            {/* <Image src={phoneImage} className="mobilePhoneSize position-absolute z-100" /> */}

                            <Box radiusless shadowless className=' has-background-transparent is-clipped  py-4 px-4  ' >
                                <Box shadowless backgroundColor={bgColor} className='mobileBox  border-radius-21 py-0 px-0  '>
                                    {/* <Box radiusless shadowless className='m-0 p-0'> Status</Box> */}
                                    {/* <img src={statusBarDark} alt="status bar" className="mobileStatusBarSize has-background-white position-absolute z-10 ml-minus-9 mt-minus-8 " /> */}
                                    <Box radiusless shadowless backgroundColor={bgColor} className='  mx-0 p-0 mb-0 pr-1 stick-to-top-16 z-55'>
                                        <img src={statusBarImg} alt="status bar" className="mobileStatusBarSize   z-10   " />
                                    </Box>
                                    <Box shadowless className='mt-0 mx-0 p-0 has-background-transparent position-relative mobileBox-content overflow-scroll'>
                                        {/* {instaNav_mobile_profile()} */}
                                        {/* {insta_postCard(randomUser1, randomUser2, "TestPostApp", "smething", "my first post", postFill, setPostFill, true)}
                                        {insta_postCard(randomUser1, randomUser2, "TestPostApp", "smething", "my first post", postFill, setPostFill, true)} */}
                                        {/* {insta_profileCard(randomUser10, null, null, "TestPostApp", "TestPost opens the door to interesting collaborations and experiences.", "testpost.app/linkinbio", dpFill, true)} */}
                                        {content()}
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </ColumnAux>
            </Columns.Column>
            <Columns.Column className='  m-0 p-0 ' >
            </Columns.Column>
        </Columns>

    }


    const toggleMobile = (val) => {
        setIsMobile(val)
        if (props.selectedPreview.channel === SocialMedia_Platform.instagram && val === false && isDarkMode) {
            setIsDarkMode(false)
        }
    }

    const toShowDarkMode = () => {
        let show = true
        if (props.selectedPreview.channel === SocialMedia_Platform.instagram && isMobile === false){
            show = false
        }

        return show
    }


    const modal = () => {

        console.log("Selected Preview comp is")
        console.log(props.selectedPreview)

        return (

            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className=" backblurOnly is-fullHeightAlways-vh bigDeleteButton  animate-bottom p-0 m-0 " >
                {/* <Modal.Card className="is-width-80Perc m-0 is-centered is-h-centered has-background-black" radiusless> */}
                <Modal.Card className="is-fullWidthAlways is-fullHeightAlways-vh  m-0 is-centered is-h-centered has-background-transparent" radiusless>
                    <Modal.Card.Header showClose radiusless className="m-0 py-2   has-background-transparent is-borderless">
                        <Modal.Card.Title textSize="6" textColor="grey" className=" "  >
                            <ColumnAux isMiddle={false} size={3}>

                                <Columns gap={0} mobile={true} vCentered>
                                    <Columns.Column>
                                        <Button backgroundColor={isPinned ? "white" : "black"} size="small" className=" is-hover-dark" onClick={pinPressed}>
                                            <AiFillPushpin className={`is-size-6  ${!isPinned ? "has-text-white" : "has-text-black"} `} />
                                        </Button>
                                    </Columns.Column>
                                    <Columns.Column>
                                        <Tabs type='toggle' size={"small"} >
                                            <Tabs.Tab active={!isMobile} backgroundColor={isMobile ? "" : "white"} onClick={() => { toggleMobile(false) }} > <AiOutlineDesktop className={` is-size-6 ${isMobile ? "" : "has-text-black"} `} /> </Tabs.Tab>
                                            <Tabs.Tab active={isMobile} backgroundColor={isMobile ? "white" : ""} onClick={() => { toggleMobile(true) }}> <AiOutlineMobile className={` is-size-6 ${isMobile ? "has-text-black" : ""} `} /> </Tabs.Tab>
                                        </Tabs>
                                    </Columns.Column>
                                    <Columns.Column>
                                        {
                                            (toShowDarkMode()) ?
                                                <Level.Item>
                                                    <Button backgroundColor={!isDarkMode ? "black" : "white"} isSelected={true} size="small" className="is-hover-dark " onClick={() => { setIsDarkMode(!isDarkMode) }} >
                                                        <VscColorMode className={`is-size-6  ${!isDarkMode ? "has-text-white" : "has-text-black"} `} />
                                                    </Button>
                                                </Level.Item>
                                                : null
                                        }
                                    </Columns.Column>
                                </Columns>

                                {/* <Level alignContent='left' mobile={true}>
                                    <Level.Item>

                                    </Level.Item>
                                    <Level.Item>

                                    </Level.Item>
                                    {
                                        (props.selectedPreview.channel === socialMediaChannels.instagram && isMobile) ?
                                            <Level.Item>
                                                <Button backgroundColor={!isDarkMode ? "black" : "white"} isSelected={true} size="small" className="is-hover-dark " onClick={() => { setIsDarkMode(!isDarkMode) }} >
                                                    <VscColorMode className={`is-size-6  ${!isDarkMode ? "has-text-white" : "has-text-black"} `} />
                                                </Button>
                                            </Level.Item>
                                            : null
                                    }
                                </Level> */}
                            </ColumnAux>
                        </Modal.Card.Title>
                    </Modal.Card.Header>
                    {/* <Modal.Card.Body className=" has-background-transparent " alignItems="center" alignContent="center" onClick={blurPressed} > */}
                    <Modal.Card.Body className={currentBg() + " m-0 p-0"} alignItems="center" alignContent="center" onClick={blurPressed} >

                        {isMobile ? mobileUI() : desktopUI()}
                    </Modal.Card.Body>
                </Modal.Card>
            </Modal >

        )
    }


    return modal()

}

export default VisualiseModal