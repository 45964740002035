
import React, { useState } from 'react'
import { Media, Content, Image, Dropdown, Columns, Box, Tag, Button, Message } from 'react-bulma-components';
import { RiCheckboxBlankCircleFill, RiImageLine, RiStickyNoteFill, RiStickyNoteLine } from 'react-icons/ri';
import { AiTwotoneMessage, AiOutlineUserAdd } from 'react-icons/ai';
import { MdReply, MdReplyAll, MdCheckBoxOutlineBlank, MdCheckBox, MdAssignmentInd } from 'react-icons/md';

import { HiAnnotation, HiOutlineDotsHorizontal, HiOutlineReply } from 'react-icons/hi';
import { ImRadioUnchecked, ImCheckboxChecked, ImCheckboxUnchecked, ImCheckmark, ImCheckmark2, ImCross, ImArrowUp, ImBackward2 } from 'react-icons/im';
import { RiRecordCircleFill, RiTodoLine, RiTaskFill } from 'react-icons/ri';
import { FaRegCircle } from 'react-icons/fa';
import { BsThreeDots, BsCheck } from 'react-icons/bs';
import { BiUpvote, BiTask, BiTaskX } from 'react-icons/bi';
import { FiUser, FiUserCheck } from 'react-icons/fi';

import { IoIosArrowRoundUp, IoMdCheckboxOutline, IoMdCheckboxm, IoIosCheckmarkCircle } from 'react-icons/io';
import { IoArrowBackCircleSharp } from 'react-icons/io5';

import ReactTooltip from 'react-tooltip';
import { AnnotationType, AnnotationShowType, userPointer, AvatarStackFor, EntityType } from '../../Models/EnumsAndPointers';
import TimeAgo from 'react-timeago'
import { Comment } from '../../Models/Comment';
import { containsUpvoteByCU, hasEmoji, sampleImageUrl, removeKeysFromUserInfo_Obj } from '../../utilities/Utilities';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { isMobile } from 'react-device-detect';
import UserAvatar from '../Calendar/UserAvatar';
import Avatar from '../Calendar/Avatar';
import AvatarStack from '../Calendar/AvatarStack';
import { Task } from '../../Models/Task';
import { Asset } from '../../Models/Asset';
import TaskModal from '../Tasks/TaskModal';





const CommentRow = (props) => {

    // this.id = id
    // this.byUser = byUser
    // this.byUserName = byUserName
    // this.message = message
    // this.annotation = annotation
    // this.messageType = messageType
    // this.isReply = isReply
    // this.replies = replies

    const [updating, setIsUpdating] = useState(false)
    const [addTask, setAddTask] = useState(null)




    const dismissTask = (tk) => {
        // let tsks = [...tasks]
        // tsks.unshift(tk)
        // setTasks(tsks)
        setAddTask(null)
    }



    const annotationIcon = (note, colorHex) => {

        const clNames = " mx-1 buttonBoxShadow1 has-background-transparent"
        const fallbackBGHex = " grey "
        if (note) {
            if (note.length <= 3 && note.length > 0) {

                if (hasEmoji(note)) {
                    return <Button rounded size="small" style={{ backgroundColor: colorHex ?? fallbackBGHex }} className={"has-text-grey-dark  p-0 is-borderless comment_note_btn_24 buttonBoxShadow1"}> <span className="is-size-5 has-text-weight-bold ">{note}</span></Button>
                }

                return <Button rounded size="small" style={{ backgroundColor: colorHex ?? fallbackBGHex }} className={"has-text-grey-dark  p-0 is-borderless comment_note_btn_24 buttonBoxShadow1"}> <span className="is-size-8 has-text-weight-bold ">{note}</span></Button>
            } else {
                return <HiAnnotation style={{ color: colorHex ?? fallbackBGHex }} className={clNames} />
            }

        }


        // TRIED TO ADD ASSET TYPE HIGHLIGHTER ON TAG
        // RiImageFill, RiImageLine
        // BiText
    
        // BsPlayBtnFill, BsPlayBtn
    
        // ImTextColor
        // MdOutlineTextFields
        // RiText

    

        return <FaRegCircle style={{ color: colorHex ?? fallbackBGHex }} className={clNames} />
    }


    const to_hover = (thisAnn) => {
        let should_hover = false
        if (props.displayingAnnotaions) {
            props.displayingAnnotaions.map((ann) => {
                if (ann.id === thisAnn.id) {
                    // if (ann.isHovered){
                    should_hover = true
                    // }
                }
                return null
            })
        }
        return should_hover
    }


    const dropdownValueChanged = (val) => {

        console.log("Dropdown value changed")

        if (val === "delete") {

            console.log(`SENDING DELTE INFO - props.comment.id = ${props.comment.id} and props.comment.isReply = ${props.comment.isReply} `)

            props.deleteComment(props.comment.id, props.comment.isReply)
        }

        if (val === "edit") {
            props.editPressed(props.comment, props.comment.isReply)
        }


        if (val === "createTask") {

            // create task 
            let tk = new Task()
            tk.byUser = isCurrentUser().id
            tk.byUserInfo = currentUserInfo()
            tk.createdAt = new Date()

            // add this asset thumbnail to it
            const thisAsset = Asset.copyFrom(props.asset)
            // console.log("Incoming Asset is")
            // console.log(props.asset)
            const thisAssetThumbnail = thisAsset.thumbnailObject(props.asset.project.id)
            tk.assets = [thisAssetThumbnail]
            tk.assetsIds = [thisAssetThumbnail.id]


            const thisCommentThumbnail = Comment.copyFrom(props.comment).thumbnailObject(thisAsset.id, props.asset.project.id)
            tk.comments = [thisCommentThumbnail]
            tk.commentsIds = [thisCommentThumbnail.id]

            // show task in modal
            setAddTask(tk)


        }


        if (val === "markResolved") {
            markResolved()
        }

    }

    const markResolved = () => {
        let cu = isCurrentUser()
        let cuInfo = currentUserInfo()
        let cm = Comment.copyFrom(props.comment)

        cm.isMarkedDone = true
        cm.isMarkedDoneBy = userPointer(cu.id)
        cm.isMarkedDoneByInfo = removeKeysFromUserInfo_Obj(cuInfo, true, false, true, true)

        setIsUpdating(true)
        cm.update((succ, result, errMsg) => {
            if (props) {
                if (props.comment) {
                    if (succ) {
                        setIsUpdating(false)
                        props.commentChanged(result)
                    } else {
                        alert("Could not mark resolved.")
                        console.log(errMsg);
                    }
                }
            }

        })
    }


    const upvotePressed = () => {
        props.upvotePressed(props.comment, props.comment.isReply)
    }

    const threadPressed = () => {
        props.commentThreadPressed(props.comment)
    }




    const adjustWidtOfTrForShortComments_bugSolution = () => {
        return (
            <span>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
            // <span>
            //     &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            // </span>
        )
    }


    const dropDown = (comment, isCreator, isReply, isThreadMain) => {

        let comps = []
        let icon = <HiOutlineDotsHorizontal className="is-size-5 has-text-grey mt-minus-10" />

        if (comment.isMarkedDone) {
            icon = <BiTask className="is-size-4 has-text-grey mt-minus-10" />
            comps.push(<div className=" is-size-8 has-text-grey p-4"> Marked Done by : {comment.isMarkedDoneByInfo.name} </div>)
        }


        if (isCreator) {
            comps.push(<Dropdown.Item renderAs="a" value="edit">Edit </Dropdown.Item>)
        }



        comps.push(<Dropdown.Item renderAs="a" value="createTask">Create Task </Dropdown.Item>)
        // comps.push(<Dropdown.Item renderAs="a" value="addToTask">Add to Task </Dropdown.Item>)




        if (!isReply && !isThreadMain) {
            comps.push(<Dropdown.Divider />)
            if (comment.isMarkedDone) {
                comps.push(<Dropdown.Item renderAs="a" value="markUnResolved">  <BiTaskX className=" has-text-grey mr-1" /> <span className=" ">Mark Un-Resolved</span>  </Dropdown.Item>)

                // also show the icon as done
            } else {
                comps.push(<Dropdown.Item renderAs="a" value="markResolved">  <BiTask className="is-size-6 has-text-grey  mr-2" /> Mark Resolved</Dropdown.Item>)
            }
        }
        if (isCreator) {
            comps.push(<Dropdown.Divider />)
            comps.push(<Dropdown.Item className=" has-text-danger " renderAs="a" value="delete">Delete </Dropdown.Item>)
        }







        return <Dropdown right renderAs="button" className=" has-text-left is-size-7 is-arrowless has-background-transparent m-0 p-0 is-borderless" label={
            <div> {icon} </div>
        } onChange={(val) => { dropdownValueChanged(val) }} >
            {comps.map((c) => {
                return c
            })}
        </Dropdown>

    }


    const commentContentNew = (comment, isReply, isThreadMain) => {
        const cu = isCurrentUser()
        const isCreator = comment.byUser === cu.id
        const usrInfo = comment.byUserInfo ?? {}

        let classes = " commentMessageRow " + (isThreadMain ? " pl-3  commentMessageRowMain " : "")

        if (comment.isMarkedDone) {
            classes += "  has-background-transparent  "
        }

        return <div className="is-fullwidth m-0 p-0">


            <Message radiusless size="small" className={classes}>

                {
                    isThreadMain ?
                        <span>
                            <Button size="" className=" ml-minus-10 mb-1  has-background-transparent mt-2 is-radiusless is-borderless " onClick={props.backFromThread} > <IoArrowBackCircleSharp className=" is-size-4 mr-2 " /> <span className=" is-size-7 has-text-grey "> Back </span>  </Button>
                            {adjustWidtOfTrForShortComments_bugSolution()}
                        </span>

                        : null
                }


                <Message.Body radiusless>

                    {
                        props.isFirst && (!isThreadMain) ?
                            <span  >
                                {adjustWidtOfTrForShortComments_bugSolution()}
                                <br />
                            </span> :
                            null
                    }

                    <Columns gap={0} className="m-0 p-0 is-clearfix">
                        <Columns.Column narrow>
                            <Avatar imageUrl={usrInfo.imageUrl ?? " mt-minus-10 "} />
                        </Columns.Column>
                        <Columns.Column >
                            <p className="has-text-weight-semibold mt-0 ml-3 mb-0"> {usrInfo.name ?? "-"} <span className="has-text-weight-light "></span> </p>
                            {/* <p className="has-text-grey-light is-size-8 has-text-weight-semibold  mt-0 ml-3">  Unknown Role &nbsp;·&nbsp; <TimeAgo live={false} className="  " date={comment.createdAt} /> </p> */}
                            <p className="has-text-grey-light is-size-8 has-text-weight-semibold  mt-0 ml-3"> <TimeAgo live={false} className="  " date={comment.createdAt} /> </p>

                        </Columns.Column>
                        <Columns.Column narrow>


                            {
                                updating ?
                                    <Button loading={true} disabled={true} size="small" color="light is-borderless has-background-transparent" />
                                    :
                                    <>
                                        {dropDown(comment, isCreator, isReply, isThreadMain)}
                                    </>
                            }


                        </Columns.Column>
                    </Columns>
                    <div className={comment.isMarkedDone ? "opacity75" : ""}>{comment.message} </div>

                    <Box shadowless className="is-size-4 p-0 mx-0 my-1 mt-4 has-background-transparent ">
                        {
                            comment.annotations ?
                                <div>
                                    {
                                        comment.annotations.map((thisAnn) => {
                                            return <span data-for={"Annotate-" + thisAnn.id} data-tip={thisAnn.color ?? thisAnn.colorHex} className={to_hover(thisAnn) ? " hover-pulse " : " "} onMouseEnter={() => { props.annotationHovered(true, thisAnn.id, "icon", AnnotationShowType.existingComment, thisAnn) }} onMouseLeave={() => { props.annotationHovered(false, thisAnn.id, "icon", AnnotationShowType.existingComment, thisAnn) }}  >

                                                {annotationIcon(thisAnn.note, thisAnn.color ?? thisAnn.colorHex)}
                                                {/* <HiAnnotation style={{ color: thisAnn.color ?? "grey" }} className="mx-1" /> */}

                                                {
                                                    thisAnn.note ?
                                                        <ReactTooltip

                                                            id={"Annotate-" + thisAnn.id}
                                                            getContent={(dataTip) =>
                                                                <div>
                                                                    {
                                                                        thisAnn.note ?
                                                                            <Box shadowless className=" w-190 h-80 has-background-transparent p-0 m-0 z-1000">
                                                                                <p className={thisAnn.note.length <= 3 && hasEmoji(thisAnn.note) ? " has-text-weight-semibold has-text-dark is-size-4 " : " has-text-weight-semibold has-text-dark is-size-7 "}>
                                                                                    {thisAnn.note}
                                                                                </p>
                                                                            </Box>
                                                                            :
                                                                            <span className="has-text-weight-light is-size-8">NO NOTE</span>
                                                                    }

                                                                </div>

                                                            }
                                                            offset={{ top: 10 }}
                                                            effect='solid'
                                                            delayHide={50}
                                                            delayShow={50}
                                                            delayUpdate={50}
                                                            place={'bottom'}
                                                            border={false}
                                                            type={'light'}
                                                            backgroundColor={thisAnn.color ?? thisAnn.colorHex}
                                                            clickable={true}
                                                            className="border-radius-9"
                                                        />
                                                        :
                                                        null
                                                }

                                            </span>

                                        })
                                    }
                                </div>
                                :
                                null
                        }
                    </Box>
                    <Box shadowless radiusless className=" mx-0 my-2 p-0 has-background-transparent">

                        <Button.Group size="small" className="is-left is-size-6" >



                            {isThreadMain ?
                                null
                                :

                                <Dropdown up hoverable={comment.upvotes.length} renderAs="button" textAlign="left" className=" mr-minus-10 mx-0 mb-0 p-0 has-background-transparent  is-small    is-borderless " label={
                                    <Button className=" has-background-transparent is-borderless is-hover-dark has-text-grey-light px-2 mr-4 no-text-decoration " onClick={upvotePressed} >
                                        {
                                            containsUpvoteByCU(comment.upvotes) ?
                                                <ImArrowUp className=" is-size-6p6 mr-1" />
                                                :
                                                <BiUpvote className=" is-size-6p2 mr-1" />
                                        }
                                        {comment.upvotes.length ? `${comment.upvotes.length}` : null}
                                    </Button>

                                }  >
                                    {comment.upvotes.length ?
                                        <Box shadowless className='has-background-transparent m-0 py-1' > <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={comment.upvotes} justAvatars={true} small={true} nonEditableAvatars={true} /> </Box>
                                        : null}

                                </Dropdown>



                            }





                            {
                                isThreadMain || isReply ?
                                    null :
                                    <Button className=" has-background-transparent is-borderless is-hover-dark has-text-grey-light px-2" onClick={threadPressed} >
                                        {comment.replies.length ? (comment.replies.length === 1 ? `${comment.replies.length} Reply` : `${comment.replies.length} Replies`) : "Reply"}
                                    </Button>
                            }


                        </Button.Group>
                    </Box>

                    {
                        isThreadMain ?
                            <span>
                                <br />
                            </span>
                            :
                            null
                    }
                    {
                        props.isLast && !props.isFirst ?
                            <span>
                                <br className="m-6" />
                                <br className="m-6" />
                                <br className="m-6" />
                                <br className="m-6" />
                                <br className="m-6" />
                                <br className="m-6" />
                                <br className="m-6" />
                            </span> :
                            null
                    }
                </Message.Body>
            </Message>

        </div>
    }


    const commentContent = (comment, isReply, isThreadMain) => {

        const cu = isCurrentUser()

        const isCreator = comment.byUser === cu.id

        console.log("Comment created by is ")
        console.log(comment.byUser)

        console.log(comment)


        // const isCreator = true


        return <div className=" " >


            {
                props.isFirst ?
                    <span  >
                        {adjustWidtOfTrForShortComments_bugSolution()}
                        <br className="m-2" />
                    </span> :
                    null
            }

            <Box shadowless={isThreadMain ? false : true} radiusless className=" has-background-transparent  m-0 pb-5 is-fullwidth " >

                {
                    isThreadMain ?
                        <span>
                            <Button size="" className=" ml-minus-10 mb-1 has-background-transparent  is-radiusless is-borderless " onClick={props.backFromThread} > <IoArrowBackCircleSharp className=" is-size-4 mr-2 " /> <span className=" is-size-7 has-text-grey-lighter "> Back </span>  </Button>
                            {adjustWidtOfTrForShortComments_bugSolution()}
                        </span>

                        : null
                }


                <Columns className=" is-mobile  " >
                    <Columns.Column>
                        <Media className="">
                            <Media.Item renderAs="figure" align="left">
                                <span className="">
                                    <Image
                                        size={32}
                                        alt="32x32"
                                        // src={comment.byUser.dpUrl}
                                        src={sampleImageUrl()}
                                        rounded
                                        className=" round-32-border "
                                    />
                                    <Button rounded size="small" className=" ml-2 mt-0 is-borderless has-text-success is-rounded has-text-centered  m-0 p-0" >
                                        {/* Ver.12 */}
                                        <BsCheck className="is-size-6 " />
                                    </Button>
                                    {/* <span className>some</span> */}
                                </span>

                            </Media.Item>
                            <Media.Item>
                                <Content size="small">
                                    <strong>{comment.byUserName}</strong>{' '}
                                    <small className="has-text-grey-light ">(Editor) &nbsp;·&nbsp; <TimeAgo live={false} className="  " date={comment.createdAt} /> </small>
                                    <p className="  ">
                                        {comment.message}
                                    </p>

                                    <div className="is-size-4">
                                        {
                                            comment.annotations ?
                                                <div>
                                                    {
                                                        comment.annotations.map((thisAnn) => {
                                                            return <span data-for={"Annotate-" + thisAnn.id} data-tip={thisAnn.color ?? thisAnn.colorHex} className={to_hover(thisAnn) ? " hover-pulse " : " "} onMouseEnter={() => { props.annotationHovered(true, thisAnn.id, "icon", AnnotationShowType.existingComment, thisAnn) }} onMouseLeave={() => { props.annotationHovered(false, thisAnn.id, "icon", AnnotationShowType.existingComment, thisAnn) }}  >

                                                                {annotationIcon(thisAnn.note, thisAnn.color ?? thisAnn.colorHex)}
                                                                {/* <HiAnnotation style={{ color: thisAnn.color ?? "grey" }} className="mx-1" /> */}

                                                                {
                                                                    thisAnn.note ?
                                                                        <ReactTooltip

                                                                            id={"Annotate-" + thisAnn.id}
                                                                            getContent={(dataTip) =>
                                                                                <div>
                                                                                    {
                                                                                        thisAnn.note ?
                                                                                            <Box shadowless className=" w-190 h-80 has-background-transparent p-0 m-0 z-1000">
                                                                                                <p className={thisAnn.note.length <= 3 && hasEmoji(thisAnn.note) ? " has-text-weight-semibold has-text-dark is-size-4 " : " has-text-weight-semibold has-text-dark is-size-7 "}>
                                                                                                    {thisAnn.note}
                                                                                                </p>
                                                                                            </Box>
                                                                                            :
                                                                                            <span className="has-text-weight-light is-size-8">NO NOTE</span>
                                                                                    }

                                                                                </div>

                                                                            }
                                                                            offset={{ top: 10 }}
                                                                            effect='solid'
                                                                            delayHide={50}
                                                                            delayShow={50}
                                                                            delayUpdate={50}
                                                                            place={'bottom'}
                                                                            border={false}
                                                                            type={'light'}
                                                                            backgroundColor={thisAnn.color ?? thisAnn.colorHex}
                                                                            clickable={true}
                                                                            className="border-radius-9"
                                                                        />
                                                                        :
                                                                        null
                                                                }

                                                            </span>

                                                        })
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                    </div>


                                    <Button.Group size="small" className="is-left is-size-6" >

                                        {isThreadMain ?
                                            null
                                            :
                                            <Button className=" has-background-transparent is-borderless is-hover-dark has-text-grey-light px-2 mr-4 no-text-decoration " onClick={upvotePressed} >
                                                {
                                                    containsUpvoteByCU(comment.upvotes) ?
                                                        <ImArrowUp className=" is-size-6p6 mr-1" />
                                                        :
                                                        <BiUpvote className=" is-size-6p2 mr-1" />
                                                }
                                                {comment.upvotes.length ? `${comment.upvotes.length}` : null}
                                            </Button>
                                        }




                                        {
                                            isThreadMain || isReply ?
                                                null :
                                                <Button className=" has-background-transparent is-borderless is-hover-dark has-text-grey-light px-2" onClick={threadPressed} >
                                                    {comment.replies.length ? (comment.replies.length === 1 ? `${comment.replies.length} Reply` : `${comment.replies.length} Replies`) : "Reply"}
                                                </Button>
                                        }


                                    </Button.Group>


                                    {/* <p>
                                    <small className="has-text-grey-lighter has-text-right is-size-7">  </small>
                                </p> */}


                                </Content>
                            </Media.Item>
                        </Media>
                    </Columns.Column>
                    <Columns.Column narrow className="p-0 m-0">
                        {/* <Dropdown right renderAs="button" className=" is-size-7 is-arrowless has-background-transparent dropdown-content-light m-0 p-0 is-borderless" label={ */}

                        {
                            dropDown(comment, isCreator, isReply, isThreadMain)
                        }



                        {/* <p className="is-size-5 mt-1 has-text-centered has-text-grey-light  " ><FiUserCheck  /></p> */}
                        <p className="is-size-6  has-text-centered has-text-grey-lighter  " ><FiUserCheck /></p>
                        <p className="is-size-6 mt-2 has-text-centered has-text-grey-lighter  " ><RiTodoLine /></p>



                        <p className="is-size-6 mt-2 has-text-centered has-text-grey-lighter ">
                            {/* <ImCheckboxChecked className="  " /> */}
                            {/* <ImCheckboxUnchecked className="  " /> */}

                            <IoIosCheckmarkCircle className="  " />
                            {/* <MdCheckBox className=" has-text-black " /> */}

                            {/* <IoMdCheckboxOutline className="  " /> */}
                            {/* <IoMdCheckbox className="  " /> */}
                        </p>
                    </Columns.Column>

                </Columns>
            </Box>

            {
                props.isLast ?
                    <span>
                        <br className="m-6" />
                        <br className="m-6" />
                        <br className="m-6" />
                        <br className="m-6" />
                        <br className="m-6" />
                        <br className="m-6" />
                        <br className="m-6" />
                    </span> :
                    null
            }
            {/* <hr /> */}

        </div>
    }

    const comment = (comment, isReply, isThreadMain) => {

        let bgColor = comment.isMarkedDone ? "has-background-transparent" : "has-background-light"

        return (
            <tr key={comment.id} className={isReply ? ` ${bgColor} is-clipped is-fullwidth  ` : `  ${bgColor} p-0 m-0  is-clipped is-fullwidth `}>
                {addTask ? <TaskModal isNew={true} task={addTask} close={dismissTask} taskIsAdded={dismissTask} /> : null}

                {isThreadMain ?
                    <td align="left" className=" p-0 m-0  is-fullwidth  has-background-transparent" >
                        {commentContentNew(comment, isReply, true)}
                    </td>
                    :
                    <td className={isReply ? "pl-5 py-1 m-0  is-fullwidth has-background-transparent " : " px-3  py-1 m-0  is-fullwidth has-background-transparent "} >
                        {/* {commentContent(comment, isReply)} */}
                        {commentContentNew(comment, isReply)}

                    </td>
                }
            </tr >
        )
    }


    return comment(props.comment, props.isReply, props.isThreadMain)

}

export default CommentRow