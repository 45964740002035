import React from 'react'
import {Columns } from 'react-bulma-components';

const ColumnAux = (props) => {
        // console.log('COLUMN AUX PROPS = ',props)
        if (props.size === 12){
                return props.children
        }
        if (props.isMiddle){
           return     <Columns className={props.className}> <Columns.Column className={ props.noPaddingSides  ? ' p-0' : ' ' }></Columns.Column> <Columns.Column className={props.className} size={props.size}> {props.children} </Columns.Column>  <Columns.Column className={ props.noPaddingSides ? 'p-0 ' : ' ' } ></Columns.Column> </Columns>
        }
        return <Columns> <Columns.Column size={props.size}> {props.children} </Columns.Column> </Columns>
}

export default ColumnAux