import React, { createContext, useCallback, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Box, Columns, Section, Heading, Table, Button, Media, Modal, Content, Image, Tag, Progress, Form } from 'react-bulma-components'
import axios from 'axios';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AWS from 'aws-sdk'
import Dropzone from 'react-dropzone'
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'


// var S3 = require('aws-sdk/clients/s3');


import ColumnAux from '../../Containers/ColumnAux'

import fileUploadIcon from '../../assets/file-upload.png'

import doneIcon from '../../assets/done.png'
import retryIcon from '../../assets/retry.png'
import waitIcon from '../../assets/wait.png'

import crossRedIcon from '../../assets/cross-red.png'
import crossBlackIcon from '../../assets/cross-black.png'
import crossBlackThickIcon from '../../assets/cross-black-thick.png'
import { createdSignedUrl, createPresignedUrl } from '../../utilities/Service'
import { bytesToSizeString, createAssetId, randomCode } from '../../utilities/Utilities';
import { ProjectContext } from '../../Contexts/ProjectContext';
import { UploadContext } from '../../Contexts/UploadContext';
import { Asset } from '../../Models/Asset';
import { currentUserInfo, getCurrentWorkspaceId } from '../../utilities/Login';
import { FileUploadStatus, acceptedFileTypes_forAssets } from '../../Models/EnumsAndPointers';



// const UploadContext = createContext({})


const Uploader = (props) => {







    // const awsConfig = {
    //     // bucketName: process.env.REACT_APP_AWSS3_BUCKET_NAME,
    //     // dirName: process.env.REACT_APP_AWSS3_DIR_NAME /* optional */,
    //     region: process.env.REACT_APP_AWSS3_REGION,
    //     credentials: {
    //         accessKeyId: process.env.REACT_APP_AWSS3_ACCESS_ID,
    //         secretAccessKey: process.env.REACT_APP_AWSS3_ACCESS_KEY,
    //     }
    // };

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWSS3_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWSS3_ACCESS_KEY
    })



    // To create context
    // We need project(has to be passed) , workspace(can be saved in local storage and also user) and currentUser (from util)


    const [currentProject, setCurrentProject] = useContext(ProjectContext);
    const { uploads, setUploads } = useContext(UploadContext);


    // const {
    //     acceptedFiles,
    //     fileRejections
    //   } = useDropzone({
    //     accept: 'image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp, image/eps, image/gif,  application/pdf '
    //   });



    // const [files, setFiles] = useState([])
    // const [isUploading, setIsUploading] = useState(false)
    // const [filesBatch, setFilesBatch] = useState([])
    // const [progress, setProgress] = useState(0)


    // specify upload params and url for your files
    // const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const getUploadParams = async ({ file, meta: { name } }) => {


        // createdSignedUrl(name, (succ, url, errMsg) => {
        //     if (succ && url){
        //         return { body: file, meta: { url }, url: url }
        //     }else{
        //         return { body: file, meta: { url }, url: null }
        //     }
        // })


        const fields = {
            // "AWSAccessKeyId": "AKIAJSQUO7ORWYVCSV6Q",
            "acl": "public-read",
            // "key": "files/89789486-d94a-4251-a42d-18af752ab7d2-test.txt",
            // "policy": "eyJleHBpcmF0aW9uIjogIjIwMTgtMTAtMzBUMjM6MTk6NDdaIiwgImNvbmRpdGlvbnMiOiBbeyJhY2wiOiAicHVibGljLXJlYWQifSwgWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDEwLCAzMTQ1NzI4MF0sIHsiYnVja2V0IjogImJlYW10ZWNoLWZpbGUifSwgeyJrZXkiOiAiY29tcGFueS8zLzg5Nzg5NDg2LWQ5NGEtNDI1MS1hNDJkLTE4YWY3NTJhYjdkMi10ZXN0LnR4dCJ9XX0=",
            // "signature": "L7r3KBtyOXjUKy31g42JTYb1sio="
        }

        const { uploadUrl, fileUrl } = await createPresignedUrl(name)

        console.log("FILE IS ")
        console.log(file)

        // return { body: file, meta: { fileUrl }, url: uploadUrl }

        return { fields, meta: { fileUrl }, url: uploadUrl }

        // const { url } = await myApiService.getPresignedUploadParams(name)
    }



    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }


    // const statusIsChanged = (data) => {
    //     console.log("Upload Status is changed")
    //     console.log(data)
    // }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (fls, allFiles) => {
        console.log(fls.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    

    const handleFileChosen = async (thisFile) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = () => {
                let fileInfo = {
                    data: thisFile,
                    name: thisFile.name,
                    extension: thisFile.name.split('.').pop() ?? "",
                    fileId: createAssetId(null, 1),
                    project: { ...currentProject },
                    projectName: currentProject.name,
                    projectId: currentProject.id ?? "randomProjectId-" + uuidv4(),
                    workspaceId: currentProject.workspaceId ?? "randomWorkspaceId-" + uuidv4(),
                    size: thisFile.size,
                    sizeString: bytesToSizeString(thisFile.size),
                    type: thisFile.type,
                    url: fileReader.result,
                    uploadStatus: FileUploadStatus.waiting,
                    uploadProgress: 0
                }
                resolve(fileInfo);
            };
            fileReader.onerror = reject;
            fileReader.readAsDataURL(thisFile);
        });
    }


    const autoStartUpload = () => {
        setTimeout(() => {
            uploadWaitingFiles()
        }, 1000)
    }

    const filesAreSelected = (allFiles) => {
        // let allFiles = [...event.target.files]
        console.log("Files are selected")
        console.log(allFiles)
        Promise.all(
            allFiles.map((file) => {
                return handleFileChosen(file);
            })
        ).then((results) => {
            // cogoToast.success("PROMISE PRODUCTS Results Success")
            console.log("ALL READ FILES ARE");
            console.log(results);
            // console.log("ALL FIs ARE");
            // console.log(allFIs);
            // get unique only

            // let finalFileInfos = Array(new Set(allFIs))
            // let prev = { ...uploads }
            setUploads((prev) => {
                let npp = { ...prev }

                let allFIs = [...prev.files]
                results.map((thisr) => {
                    let existingArr = allFIs.filter((thisInfo) => {
                        return (thisInfo.url === thisr.url && thisInfo.projectId === currentProject.id)
                    })
                    if (!existingArr.length) {
                        allFIs.unshift(thisr)
                    }
                    return null
                })

                npp.files = allFIs
                return npp
            })


            // autoStartUpload()

            // setFiles(allFIs)

        }).catch((error) => {
            console.log("ERROR WHILE TRYING TO READ FILES IS");
            console.log(error);
        });

    }


    const filePicker = () => {
        return (
            <div className="file   is-radiusless m-0 is-centered">
                <label className="file-label">

                    <input multiple={true} disabled={false} className="file-input" type="file" accept={acceptedFileTypes_forAssets} name="images" onChange={(event) => { filesAreSelected([...event.target.files]) }} />

                    <span className="file-cta is-borderless">
                        <span className="file-icon mt-2 pt-2 mb-0">
                            <Image size={48} src={fileUploadIcon} className="" />
                        </span>
                        <span className="file-label is-size-6 ">
                            Click to Choose files or Drag them here
                        </span>
                    </span>
                </label>
            </div>

        )
    }

    const fileDropZone = () => {
        return (
            <Box shadowless className=" has-text-centered m-0 p-0 is-fullwidth ">
                <label className="file-label is-fullwidth ">
                    <Dropzone noClick={true} className=" is-fullwidth " onDrop={(acceptedFiles) => { filesAreSelected(acceptedFiles) }}
                        accept={acceptedFileTypes_forAssets}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className=" has-text-centered p-0 m-0 is-fullwidth ">
                                <div className="has-background-white-ter px-6 pt-5 pb-5 is-radiusless is-bordered-dashed  "  {...getRootProps()}>
                                    {/* <input {...getInputProps()} className="has-background-grey-light" /> */}
                                    {/* <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or Drag&Drop here &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> */}
                                    {/* <p className="has-text-grey is-size-8 has-text-centered m-0  is-family-monospace has-text-weight-light"  > for project - {currentProject.name} </p> */}
                                    {filePicker()}

                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <p className="has-text-grey is-size-8 has-text-centered mt-4  is-family-monospace has-text-weight-light"  > for project - {currentProject.name} </p>
                    {/* </span> */}
                </label>
            </Box>
        )
    }

    const filesInput = () => {
        return (
            <div className="file is-boxed mt-0 mx-0 is-centered is-fullwidth ">
                {currentProject ?
                    <Box shadowless className=" has-text-centered m-0 p-0 is-fullwidth ">
                        {/* {filePicker()} */}
                        {fileDropZone()}
                        <br />
                    </Box>
                    :
                    <p className="has-text-grey-light is-size-6 has-text-centered m-6 has-text-weight-light" > + New files can be uploaded from within projects only </p>
                }
            </div>
        )
    }


    const filesInput_o = () => {
        return (
            // <Form.InputFile
            //     // no way to select multiple
            //     aling="center"
            //     className=" is-centered m-6"
            //     size="large"
            //     // icon="fas fa-upload"
            //     onChange={(e) => { filesAreSelected(e.target.files) }}
            // />
            <>

                <div className="file is-boxed mt-0 mx-0 is-centered is-fullwidth ">

                    {currentProject ?

                        <Box shadowless className=" has-text-centered m-0 p-0 is-fullwidth ">

                            <label className="file-label is-fullwidth ">
                                {/* <input multiple={(props.type === picType.CustomerProfilePic || props.type === picType.UserPic) ? false : true} disabled={isUploading} className="file-input" type="file" accept="image/png, image/jpeg, image/jpg, image/pdf" name="images" webkitdirectory="true" onChange={readAllFiles} /> */}
                                {/* <input multiple={true} disabled={uploads.isUploading} className="file-input" type="file" accept="image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp, image/eps, image/gif,  image/pdf" name="images" onChange={filesAreSelected} /> */}
                                {/* <input multiple={true} className="file-input" type="file" accept="image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp, image/eps, image/gif,  application/pdf , application/vnd.ms-excel, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .pages, .docx, doc" name="images" onChange={filesAreSelected} /> */}
                                {/* <input multiple={true} className="file-input" type="file" accept="image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp, image/eps, image/gif,  application/pdf " name="images" onChange={filesAreSelected} /> */}

                                {/* <span className="file-cta has-text-centered p-0"> */}

                                {/* <span className="file-label has-text-centered">
                                        Choose files
                                    </span> */}

                                <Dropzone className=" is-fullwidth " onDrop={(acceptedFiles) => { filesAreSelected(acceptedFiles) }}
                                    accept='image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp, image/eps, image/gif,  application/pdf '

                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section className=" has-text-centered p-6 m-0 is-fullwidth ">
                                            <div className="has-background-white-ter p-6 is-bordered-dashed "  {...getRootProps()}>
                                                <input {...getInputProps()} className="has-background-grey-light" />
                                                {/* <span  className="file-icon mt-5 pt-4 has-text-centered is-centered">
                                                            <Image size={64} src={fileUploadIcon} className=" has-text-centered is-centered" />
                                                        </span> */}

                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Drag n drop, or browse &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                <p className="has-text-grey is-size-8 has-text-centered m-0  is-family-monospace has-text-weight-light"  > for project - {currentProject.name} </p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>

                                {/* <p className="has-text-grey is-size-8 has-text-centered m-0  is-family-monospace has-text-weight-light"  > for project - {currentProject.name} </p> */}
                                {/* </span> */}
                            </label>


                        </Box>


                        :
                        <p className="has-text-grey-light is-size-6 has-text-centered m-6 has-text-weight-light" > + New files can be uploaded from within projects only </p>
                    }

                </div>
            </>


        )
    }

    const fileStatusTag = (status, uploadProgress, fl) => {

        let thisView = null;
        switch (status) {
            // case FileUploadStatus.uploaded: return <Image size={24} src={doneIcon} className="" />;
            case FileUploadStatus.uploaded: case FileUploadStatus.uploading: thisView = <div style={{ width: 28, height: 28 }}>

                <CircularProgressbarWithChildren className="mt-4 p-0" value={uploadProgress} text={``}


                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        // rotation: 0.25,

                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',

                        // Text size
                        textSize: '24px',

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors
                        pathColor: `black`,
                        textColor: 'black',
                        trailColor: '#d6d6d6',
                        backgroundColor: '#3e98c7',
                    })}

                >
                    {(uploadProgress === 100) ? <img style={{ width: 12, marginTop: -16 }} className="fadeIn" src={doneIcon} alt="uploaded" /> : null}


                    {/* <img size={16} src={doneIcon} className="" /> */}
                </CircularProgressbarWithChildren>
            </div>;; break;
            case FileUploadStatus.invalid: thisView = <div style={{ width: 28, height: 28 }}>
                <p className="is-size-8 is-light mt-4 p-0 has-text-danger has-text-weight-semibold">Invalid</p>
            </div>; break;
            case FileUploadStatus.waiting:

                thisView = <div style={{ width: 28, height: 28 }}>
                    {/* <Image size={16} src={waitIcon} className="mt-5 opacity25" /> */}
                    <p className="is-size-8 is-light mt-4 p-0  has-text-grey">Waiting</p>

                </div>; break;




            // thisView = <div style={{ width: 28, height: 28 }}>
            //     <p className="is-size-8 is-light mt-4 has-text-grey pr-4">Waiting</p>
            // </div>; break;



            default: thisView = <div style={{ width: 28, height: 28 }}>
                <Image size={24} src={retryIcon} className=" mt-4" onClick={() => { uploadAFile(fl, true) }} />
                <p >
                    <span className="is-size-9 has-text-danger"> {status} </span>
                </p>
            </div>; break;

            // case FileUploadStatus.failed: return <Tag className="is-light" color="warning">Failed</Tag>;
            // case FileUploadStatus.networkError: return <Tag className="is-light" color="danger">Error</Tag>;
            // default: return <Tag className="is-light" color="warning">Unkown Error</Tag>
        }

        return <div style={{ width: 48, height: 48 }} className="has-text-centered">
            {thisView}
        </div>

    }



    const removeFile = (flId) => {
        // let currentFl = [...uploads.files]
        // let allOtherFl = currentFl.filter((thisFl) => {
        //     return thisFl.fileId !== fl.fileId
        // })

        // upp.files = allOtherFl
        let prevVal = { ...uploads }

        setUploads((prevVal) => {
            let nVal = { ...prevVal }
            let prevFiles = [...prevVal.files]
            let allOtherFl = prevFiles.filter((thisFl) => {
                return thisFl.fileId !== flId
            })
            if (prevVal.filesBatch) {
                let prevBatchFiles = [...prevVal.filesBatch]
                let allOtherBatchFl = prevBatchFiles.filter((thisFl) => {
                    return thisFl.fileId !== flId
                })
                nVal.filesBatch = allOtherBatchFl
            }
            nVal.files = allOtherFl
            return nVal
        });

        // setUploads(upp)
        // setFiles(allOtherFl)
    }

    const oneFile = (fl) => {
        return (
            <tr key={fl.fileId} className={(fl.uploadStatus === FileUploadStatus.uploaded) ? " no-border is-borderless is-fullwidth" : "no-border is-borderless is-fullwidth "} >
                {/* <tr key={fl.fileId} className=" no-border is-borderless is-fullwidth "  > */}
                {/* <tr className={isReply ? "is-selected" : ""}> */}
                {/* <td className="is-narrow">

                    <Button size="small" disabled={fl.uploadStatus === FileUploadStatus.uploading || fl.uploadStatus === FileUploadStatus.uploaded} rounded className="px-0 opacity50 is-hover-dark ml-1 mt-4 mr-0 has-backgroung-transparent is-borderless" onClick={() => { changeFileUploadStatus(fl, null, null, null, true) }}>
                        {(fl.uploadStatus === FileUploadStatus.uploading || fl.uploadStatus === FileUploadStatus.uploaded) ? null : <Image size={24} src={crossBlackThickIcon} className="p-2 " />}
                    </Button>


                </td> */}
                {/* <td  className=" is-narrow">
                </td> */}
                <td className="has-text-centered">
                    {(fl.uploadStatus === FileUploadStatus.uploading || fl.uploadStatus === FileUploadStatus.uploaded) ? null : <Button className="is-small is-text is-italic no-text-decoration px-1 mt-2" onClick={() => { removeFile(fl.fileId) }} > <span className="is-size-6 has-text-grey mt-4"> <IoIosRemoveCircleOutline /> </span></Button>}

                    <img src={fl.url} alt="File thumbnail" className="ml-4 is-bordered object-fit-contain size64 ml-0 overflow-hidden" />
                </td>
                <td>
                    <p className="mb-0 text"><small className="text">{fl.name}</small></p>
                    <p className="has-text-grey-light is-size-7 mt-0">{fl.sizeString}  <span className="text">• {fl.projectName}</span> </p>
                </td>

                <td className="has-text-centered">
                    {fileStatusTag(fl.uploadStatus, fl.uploadProgress, fl)}
                </td>

            </tr>

        )

    }



    const changeFileUploadStatus = (fl, newStatus, newUploadProgress, unbatchedUpload) => {

        //TODO: CANCEL IS NOT WORKING RIGHT NOW 
        // if (thisFl.s3Object && cancelUpload) {
        //     console.log("Aborting the upload for " + fl.name)
        //     console.log("thisFl.s3Object ")
        //     console.log(thisFl.s3Object)

        //     // thisFl.s3Object.abort()

        //     setTimeout(thisFl.s3Object.abort(), 1000);


        //     // .then((data) => {
        //     //     console.log("Aborted with data")
        //     //     console.log(data)
        //     // }).catch((err) => {
        //     //     console.log("Could not abort with error")
        //     //     console.log(err.message)
        //     // })
        //     thisFl.uploadProgress = 0
        // }

        let prev = { ...uploads }
        setUploads((prev) => {
            let npp = { ...prev }


            let allFiles = [...npp.files]

            console.log("ALL FILES")
            console.log(allFiles)

            console.log("This Files")
            console.log(fl)

            let index = allFiles.findIndex((thisFile) => {
                return thisFile.fileId === fl.fileId
            })

            if (index !== null) {
                console.log("INDEX OF FILE IS ")
                console.log(index)
                let thisFl = allFiles[index]



                if (newStatus) {
                    thisFl.uploadStatus = newStatus
                }

                if (newUploadProgress) {
                    // console.log("Changing File upload progress to " + newUploadProgress)
                    thisFl.uploadProgress = newUploadProgress
                }

                if (unbatchedUpload) {
                    thisFl.isUploading = true
                    npp.filesBatch = [...npp.filesBatch, thisFl]
                }

                allFiles[index] = thisFl
            }


            npp.files = allFiles
            return npp
        })


        // setFiles(allFiles)

    }


    // const myUploadProgress = (thisFile) => (prgrss) => {
    //     let percentage = Math.floor((prgrss.loaded * 100) / prgrss.total)
    //     console.log(thisFile)
    //     console.log(percentage)
    //   }


    const removeUploadedFile = (flId) => {
        const numberOfSecondsToWait = 0.5
        setTimeout(() => {
            removeFile(flId)
        }, 1000 * numberOfSecondsToWait)
    }


    const fileUploadProgressChanged = (file, nProgress) => {
        // find this file 
        // uploadProgress: 0 to 100

        console.log("File upload progress changed event fired")
        console.log(`File = ${file.name} , Progresss = ${nProgress}`)


        let newStatus = null

        if (nProgress === 100) {
            newStatus = FileUploadStatus.uploaded
        }
        changeFileUploadStatus(file, newStatus, nProgress)
    }


    const createFileObject = (file, locationUrl) => {
        let newAsset = new Asset()
        let cuInfo = currentUserInfo()
        newAsset.byUser = cuInfo.id
        newAsset.byUserInfo = cuInfo
        

        newAsset.fileId = file.fileId
        newAsset.name = file.name
        newAsset.type = file.type
        newAsset.project = file.project
        newAsset.url = locationUrl


        // if download url can be created

        newAsset.add((succ, thisAddedAsset, errMsg) => {
            if (succ) {
                // file is added
                // console.log("File is Added")
                // props.fileIsAdded(addedAsset)
                removeUploadedFile(thisAddedAsset.fileId)

                //ADD ASSETS
                setUploads((prevVal) => {

                    // console.log("PREV ASSET IS")
                    // console.log(prevVal)

                    let nVal = { ...prevVal }

                    // console.log("CHECK 2")
                    let newAsst = { ...thisAddedAsset }
                    newAsst.createdAt = new Date()

                    // let allAssets = prevVal.assets ? [...prevVal.assets] : []
                    // allAssets.push(newAsset)
                    nVal.newAsset = newAsst
                    return nVal
                });


                if (props.assetAdded) {
                    props.assetAdded(thisAddedAsset)
                }




            } else {
                // error occured
                console.log(`Error while trying to add file - ${file.name}. Error = ` + errMsg)
            }
        })
    }


    const uploadAFile = async (file, unbatched) => {


        changeFileUploadStatus(file, FileUploadStatus.uploading, unbatched)

        // if this is unbatched put it in a batch to sync the progress and show of Uploading waiting files button




        console.log("Starting to upload file " + file.name)
        console.log(file)

        // const S3Client = new S3(awsConfig);

        const s3Object = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME },
            region: process.env.REACT_APP_AWSS3_REGION,
        })

        var opts = {
            queueSize: 1,
            partSize: 1024 * 1024 * 5
        };

        console.log("---- FILE NAME = " + file.name)
        console.log("---- FILE TYPE = " + file.type)
        console.log("---- FILE WITH TYPE = " + file.fileId + "." + file.extension)

        let newFileName = file.fileId + "." + file.extension

        // add project folder
        let projectFolder = currentProject.id
        let workspaceFolder = `workspace-${getCurrentWorkspaceId()}`

        let filePath = `workspaces/${workspaceFolder}/${projectFolder}/${newFileName}`

        var params = {
            Bucket: process.env.REACT_APP_AWSS3_BUCKET_NAME,
            Key: filePath,
            ContentType: file.type,
            Body: file.data
        };





        // var upload = s3Object.upload(params, opts)


        var upload = s3Object.upload(params)
            .on('httpUploadProgress', function (evt) {
                // console.log("Uploaded :: " + parseInt((evt.loaded * 100) / evt.total) + '%');
                // console.log("Progress event is fired")
                // console.log(evt)
                var percentCompleted = Math.round((evt.loaded * 100) / evt.total)
                if (`${percentCompleted}`.includes("0")) {
                    // only updating progress 10/20/30/...100
                    fileUploadProgressChanged(file, percentCompleted)
                }
            })
            .promise()


        await upload.then((data) => {


            if (data) {
                // console.log("Data after uploading file = " + filePath)
                // console.log(data);

                updateProgress()
                // changeFileUploadStatus(file, FileUploadStatus.uploaded, 100, null, null)


                // file is uploaded
                createFileObject(file, data.Location ?? "data-location-not-known")

            }



        }).catch((err) => {
            // if (err) {
            console.log("Error while uploading file = " + file.name)
            console.log("Error name = " + err.name)
            console.log("Error message = " + err.message)

            if (err.name === "NetworkingError") {
                changeFileUploadStatus(file, FileUploadStatus.networkError, 0)
            } else {
                changeFileUploadStatus(file, FileUploadStatus.failed, 0)
            }

            console.log(err);
            // }
            updateProgress()

        })

        // S3Client.upload({Body: file.data})
    }


    const uploadAFile_WithSignedUrl = async (file) => {
        changeFileUploadStatus(file, FileUploadStatus.uploading)
        console.log("Starting to upload file")
        console.log(file)

        const { url } = await createPresignedUrl(file.name)

        // const options = {
        //     onUploadProgress: myUploadProgress(file),
        //     headers: { 'Content-Type': file.type } ,
        //     // cancelToken: new CancelToken(
        //     //     cancel => (cancelFileUpload.current = cancel)
        //     // )
        // };


        var instance = axios.create();

        console.log("Starting Axios Upload with url = " + url)

        const config = {
            onUploadProgress: (progressEvent) => {
                console.log("Progress event is fired")
                console.log(progressEvent)
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                fileUploadProgressChanged(file, percentCompleted)
                //   console.log(percentCompleted)
            },
            headers: {
                'Content-Type': file.type,
                'x-amz-acl': 'public-read',
                // 'Content-Disposition': `attachment; filename=${file.name}`,
                'Content-Disposition': `inline`,

            }

        }


        instance.put(url, file, config)
            .then(function (result) {
                changeFileUploadStatus(file, FileUploadStatus.uploaded)

                console.log(" Axios Upload ended with result ")

                console.log(result);
            })
            .catch(function (err) {
                changeFileUploadStatus(file, FileUploadStatus.error)
                console.log(" Axios Upload ended with error ")
                console.log(err);
            });



        // const newFileName = file.name.replace(/\..+$/, "");
        // const ReactS3Client = new s3(awsConfig)

        // changeFileUploadStatus(file, FileUploadStatus.uploading)
        // ReactS3Client.uploadFile(file, newFileName).then((data) => {
        //     if (data.status === 204) {
        //         console.log("success");
        //         changeFileUploadStatus(file, FileUploadStatus.uploaded)
        //     } else {
        //         console.log("fail");
        //         changeFileUploadStatus(file, FileUploadStatus.failed)
        //     }
        //     updateProgress()
        // });
    }




    const updateProgress = () => {
        // NOT USING IN PROGERSS BAR
        let prevVal = { ...uploads }

        setUploads((prevVal) => {
            let nVal = { ...prevVal }
            let allFiles = [...nVal.files]
            let batchFiles = [...nVal.filesBatch]
            let doneFl = 0
            allFiles.map((thisFl) => {
                if (batchFiles.includes(thisFl)) {
                    if (thisFl.uploadStatus !== FileUploadStatus.uploading) {
                        doneFl += 1
                    }
                }
                return null
            })
            if (doneFl === batchFiles.length) {
                nVal.progress = 0
                nVal.isUploading = false
                nVal.filesBatch = []
                // setProgress(0)
                // setFilesBatch([])
                // setIsUploading(false)

                // if (waitingFiles().length > 0) {
                //     autoStartUpload()
                // }

            } else {
                nVal.progress = doneFl
                // setProgress(doneFl)
            }

            return nVal
        });

    }

    const getWaitingFiles = () => {
        let allWaitingFiles = [...uploads.files].filter((thisFile) => {
            return thisFile.uploadStatus === FileUploadStatus.waiting
        })
        return allWaitingFiles
    }




    const waitingFiles = () => {
        return [...uploads.files].filter((thisFile) => {
            return thisFile.uploadStatus === FileUploadStatus.waiting
        }).slice(0, 10)
    }


    const uploadWaitingFiles = () => {

        // console.log("STARTING TO UPLOAD WAITING FILES")
        let allWaitingFiles = waitingFiles()

        // console.log("Waiting files are")
        // console.log(allWaitingFiles)

        let toSetIsUploading = null
        let tpSetFilesBatch = null

        if (allWaitingFiles) {
            toSetIsUploading = true
            tpSetFilesBatch = allWaitingFiles
            // setIsUploading(true)
            // setFilesBatch(allWaitingFiles)
            allWaitingFiles.forEach((thisFile) => {
                // uploadAFile_withSignedUrl(thisFile)
                uploadAFile(thisFile)
            })
            updateProgress()
        } else {
            // let upp = { ...uploads }
            toSetIsUploading = false
            tpSetFilesBatch = []
            // setFilesBatch([])
        }


        setUploads((prevVal) => {
            let nVal = { ...prevVal }
            if (toSetIsUploading) {
                nVal.isUploading = toSetIsUploading
            }
            if (tpSetFilesBatch) {
                nVal.filesBatch = tpSetFilesBatch
            }
            return nVal
        })
    }


    const comps = () => {
        return (
            <div>
                {filesInput()}

                <ColumnAux isMiddle={true} size={12}>

                    <Table size="fullwidth" hoverable>
                        <tbody >



                            {uploads.files.map((thisFile => {
                                return oneFile(thisFile)
                            }))}
                        </tbody>
                    </Table>

                </ColumnAux>

                {
                    // (uploads.progress !== 0 || uploads.progress !== 100) ?
                    //     <Progress value={uploads.progress} max={filesBatch.length} color="info" size="small" className="is-radiusless stick-to-bottom-0 is-clearfix z-20" />
                    //     : null
                }
            </div>
        )
    }


    const uploadModal = () => {

        return (

            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className=" lightModalBg backblurOnly-light height100vc lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" ">
                    <Modal.Card.Header showClose className="has-background-white is-borderless" >
                        <Modal.Card.Title className="has-text-grey-light">

                            <span shadowless textAlign="center" className="is-cenetered has-text-weight-bold is-size-7 has-text-grey-light"> {uploads.isUploading ?
                                `Uploading ${uploads.filesBatch.length}/${uploads.files.length} files`
                                :
                                <span>
                                    {uploads.files.length ? `${uploads.files.length} file waiting` : ""}
                                </span>
                            }
                            </span>

                        </Modal.Card.Title>

                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0" >
                        {comps()}
                    </Modal.Card.Body>

                    {
                        waitingFiles().length ?
                            <Modal.Card.Footer className=" has-background-white is-borderless">
                                <Button loading={uploads.isUploading} disabled={uploads.isUploading || (getWaitingFiles().length === 0)} color="black" className="is-centered is-hcentered py-5 mx-6 " fullwidth onClick={uploadWaitingFiles}>
                                    Upload Now
                                </Button>
                            </Modal.Card.Footer>
                            :
                            null

                    }

                </Modal.Card>
            </Modal >


        )

    }



    return uploadModal()

}
export default Uploader