import React, { useEffect, useState } from 'react';
import Parse from 'parse'
import 'bulma/css/bulma.min.css';
import { Container, Heading, Section, Content, Tile, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import MainMenuAux from '../Components/MainMenuAux'
import ColumnAux from '../Containers/ColumnAux';
import Projects from '../Components/Project/Projects';
import ProjectPage from '../Components/Project/ProjectPage';
import Assets from '../Components/Assets/Assets';
import VisualiseModal from '../Components/Visualise/VisualiseModal';
import previousImage from '../assets/previous.svg'
import penIcon from '../assets/pen-icon.png'
import Entry from './Entry';
import { ProjectContext } from '../Contexts/ProjectContext';
import { UploadContext } from '../Contexts/UploadContext';
import AllContexts from '../Contexts/AllContexts';
import Cal from '../Components/Calendar/Cal';
import Tasks from '../Components/Tasks/Tasks';
import WorkspaceAux from '../Containers/WorkspaceAux';
import WorkspaceValidatorAux from '../Containers/WorkspaceValidatorAux';
import { getNotifications } from '../Components/NotificationHandler';
import { currentUserInfo, getCurrentWorkspaceId } from '../utilities/Login';
import { Notification } from '../Models/Notification';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import ProfileModal from '../Components/Sign/ProfileModal';





const Dashboard = (props) => {

  const Feature = {
    brand: "brand",
    cal: "calendar",
    tasks: "tasks",
    more: "more",
    myProjects: "myProjects",
    sharedWithMe: "sharedWithMe",
    archived: "archived"
  }

  // workspace = { wk } isPublic

  // const [wk, setWk] = useState()
  const [selectedFeature, setSelectedFeature] = useState(Feature.myProjects)
  const [showFeatures, setShowFeatures] = useState(true)

  const [notifs, setNotifs] = useState([])

  const [newNotif, setNewNotif] = useState(null)

  const [showRemindDP, setShowRemindDP] = useState(false)



  useEffect(() => {

    let isMounted = true;

    if (isMounted) {
      let cuInfo = currentUserInfo()
      if (cuInfo.imageUrl) {

      } else {
        setShowRemindDP(true)
      }
    }
    return () => (isMounted = false)
  }, [])

  useEffect(() => {
    // Get all initial notifs 
    if (props.workspace) {
      Notification.getAll((succ, results, errMsg) => {
        if (succ) {
          setNotifs(results)
        } else {
          console.log(errMsg)
        }
      })


      const subscribeToNotifs = () => {
        var client = new Parse.LiveQueryClient({
          applicationId: process.env.REACT_APP_APPKEY,
          serverURL: 'wss://testpostbeta.b4a.io', // Example: 'wss://livequerytutorial.back4app.io'
          javascriptKey: process.env.REACT_APP_JSKEY
        });
        client.open();

        const NotifyClass = Parse.Object.extend("Notification");
        let query = new Parse.Query(NotifyClass);
        query.equalTo("workspaceId", getCurrentWorkspaceId())

        // forUsers should contain currentUserId
        query.equalTo("forUsers", currentUserInfo().id)


        // query.notEqualTo("closed", true)
        // let subscription = await query.subscribe();

        var subscription = client.subscribe(query);


        // Connection is now open
        subscription.on('open', () => {
          console.log('******> Notify subscription opened');
        });


        // When new object is created
        subscription.on('create', (object) => {
          console.log('******> object created');
          let noti = Notification.initFromPFObject(object)

          //   < ToastContainer
          // position = "top-right"
          // closeOnClick
          // pauseOnFocusLoss
          // draggable
          // pauseOnHover
          //   />

          //   <ToastContainer />

          // Disabled icon from CSS Toastify__toast-icon
          toast.warn(`${noti.message}`, {
            // position: "top-right",
            // autoClose: 5000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,
          });


          // setNewNotif(noti.message)
          // setTimeout(() => {
          //   setNewNotif(null)
          // }, 3000)



          // alert("Notification : " + noti.message)
          if (noti !== null) {
            // let ntt = [...notifs]
            // ntt.unshift(noti)
            // setNotifs(ntt)
            setNotifs((curr) => { return [noti, ...curr] });

          }


        });

        // // When new object is update
        // subscription.on('update', (object) => {
        //   console.log('******> object updated');
        // });


      }

      setTimeout(() => {
        subscribeToNotifs()
      }, 3000);

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workspace])


  let markNotifAsRead = (n) => {
    let nt = Notification.copyFrom(n)
    let cu = currentUserInfo()

    if (nt.readBy.includes(cu.id)) {
      // DO nothing 
      // alert("Notification is already read")
    } else {
      nt.markRead((e) => {
        if (e) {
          alert(e)
        }
      })
      nt.readBy = [cu.id, ...nt.readBy]

      let ntfs = [...notifs]
      let index = ntfs.findIndex((nn) => {
        return nn.id === nt.id
      })
      if (index >= 0) {
        ntfs[index] = nt
        setNotifs((curr) => { return ntfs })
      }
    }
  }



  useEffect(() => {
    if (props.isPublic === true || (!props.workspace)) {
      setShowFeatures(false)
    } else {
      setShowFeatures(true)
    }

  }, [props.isPublic, props.workspace])





  const featureIsSelected = (feat) => {
    setSelectedFeature(feat)
  }

  const toggleFeaturesTab = (val) => {
    if (val) {
      setShowFeatures(val)
    } else {
      setShowFeatures(!showFeatures)
    }
  }


  const breadcrumbs = () => {
    return (
      <Box shadowless radiusless className=" bottomBorder2 has-background-transparent z-20">

        <Breadcrumb size="small" seperator="succeeds" >
          <Breadcrumb.Item>
            <a>Projects</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a>Selected Project</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a>Selected Assets</a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Box>

    )
  }

  const projects = () => {
    return (
      <Container>


        <Projects />


        {/* {breadcrumbs()} */}

        {/* <Button rounded className="px-4 ml-2 mr-2 is-borderless has-background-transparent mb-2" >
          <Image src={previousImage} className="backButtonImage is-vcentered" color="primary" />
        </Button> */}

      </Container>
    )
  }


  const featuresNavBar = () => {
    return (
      <Box className="my-0 p-0 z-5 top65 stick-to-top is-fullwidth  backblur-white  is-radiusless bottomBorderLight is-shadowless">
        <Tabs type="" radiusless align="center" className=" is-radiusless is-fullWidthAlways z-5 pt-0 pb-0 " textWeight="semibold">
          {/* <Tabs.Tab active={selectedFeature === Feature.brand} onClick={() => { featureIsSelected(Feature.brand) }}  > <span className="has-text-grey-light"> Brand </span> </Tabs.Tab> */}
          <Tabs.Tab active={selectedFeature === Feature.myProjects} onClick={() => { featureIsSelected(Feature.myProjects) }}   >Projects</Tabs.Tab>
          <Tabs.Tab active={selectedFeature === Feature.tasks} onClick={() => { featureIsSelected(Feature.tasks) }}   >Tasks</Tabs.Tab>
          <Tabs.Tab active={selectedFeature === Feature.cal} onClick={() => { featureIsSelected(Feature.cal) }}>Calendar</Tabs.Tab>
          {/* <Tabs.Tab active={selectedFeature === Feature.more} onClick={() => { featureIsSelected(Feature.more) }}><span className="has-text-grey-light"> More </span> </Tabs.Tab> */}

          {/* <Tabs.Tab active={selectedFeature === Feature.sharedWithMe}  onClick={() => { featureIsSelected(Feature.sharedWithMe) }}   >Shared With Me</Tabs.Tab> */}
          {/* <Tabs.Tab active={selectedFeature === Feature.archived}      onClick={() => { featureIsSelected(Feature.archived) }}   > <span className="has-text-grey-light">Archived</span> </Tabs.Tab> */}
        </Tabs>
      </Box>
    )
  }


  const breadcrumbsNavBar = () => {
    return (
      <Box className="my-0 p-0 z-5 top65 stick-to-top-61 is-fullwidth  backblur-white  is-radiusless bottomBorderLight is-shadowless">
        <Columns className=" is-mobile m-0 p-0">
          <Columns.Column size={1} className="m-0 p-1" >
            <Button rounded className="px-4 ml-2 mr-2 is-borderless" >
              <Image src={previousImage} className="backButtonImage is-vcentered" color="primary" />
            </Button>
          </Columns.Column>
          <Columns.Column className="mx-2 mt-0 mb-0 pl-4">

            <Breadcrumb size="small" className="is-centered pl-4 highVerticalMarginForItems" separator="succeeds" textWeight="semibold">
              <Breadcrumb.Item>
                <a>Projects</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a>Selected Project</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <a>Selected Assets</a>
              </Breadcrumb.Item>
            </Breadcrumb>

          </Columns.Column >
          <Columns.Column className="m-0 p-0" size={1}>
          </Columns.Column>
        </Columns>
      </Box>
    )
  }


  const pageNavBar = () => {
    return (
      // <Box className="my-0 p-0 z-5 top65 stick-to-top-61 is-fullwidth  backblur-white  is-radiusless bottomBorderLight is-shadowless">

      <Box className="my-0 p-0 z-5 top65  is-fullwidth  backblur-white  is-radiusless bottomBorderLight is-shadowless">
        {/* <Tabs type="" radiusless align="center" className=" is-radiusless is-fullWidthAlways z-5 pt-0 pb-0" textWeight="semibold">
        <Tabs.Tab>Cal</Tabs.Tab>
        <Tabs.Tab active>My Projects</Tabs.Tab>
        <Tabs.Tab>Shared With Me</Tabs.Tab>
        <Tabs.Tab> <span className="has-text-grey-light">Archived</span> </Tabs.Tab>
      </Tabs> */}

        <Columns className=" is-mobile m-0 p-0">
          <Columns.Column size={1} className="m-0 p-1" >
            <Button rounded className="px-4 ml-2 mr-2 is-borderless" >
              <Image src={previousImage} className="backButtonImage is-vcentered" color="primary" />
            </Button>
          </Columns.Column>
          <Columns.Column className="mx-2 mt-0 mb-0 pl-4">


            {/* <Breadcrumb size="small" className="is-centered pl-4 highVerticalMarginForItems" separator="succeeds" textWeight="semibold">
            <Breadcrumb.Item>
              <a>Projects</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a>Selected Project</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <a>Selected Assets</a>
            </Breadcrumb.Item>
          </Breadcrumb> */}

            <Heading size={5} className="has-text-centered">Really Really Really Big Project Name</Heading>

          </Columns.Column >
          <Columns.Column className="m-0 p-0" size={1}>
          </Columns.Column>
        </Columns>


      </Box>
    )
  }



  let featuresDiv = () => {
    return <div className="h-90vh overflow-hidden">
      {showFeatures ? featuresNavBar() : null}

      {(selectedFeature === Feature.myProjects) ? <Projects publicProjectid={props.publicProjectId} toggleFeaturesTab={toggleFeaturesTab} /> : null}
      {(selectedFeature === Feature.sharedWithMe) ? <Heading > Shared with Me </Heading> : null}
      {(selectedFeature === Feature.cal) ? <Cal /> : null}
      {(selectedFeature === Feature.tasks) ? <Tasks /> : null}
      {(selectedFeature === Feature.archived) ? <Heading > Archived </Heading> : null}
      <br />
      <br />
    </div>
  }


  let final = () => {

    return <>

    </>

  }




  return (
    <div className="App " >
      <AllContexts>
        <MainMenuAux className="is-fullHeight overflow-hidden" workspace={props.workspace} wkValidated={props.wkValidated} notifs={notifs} markNotif={markNotifAsRead} hasSideMenu={false} userLoggedOut={props.userLoggedOut} isBackBlur={true}>
          <WorkspaceAux changeRevalidateWSMember={props.changeRevalidateWSMember} workspace={props.workspace} activateWorkspace={props.activateWorkspace} >
            <WorkspaceValidatorAux changeRevalidateWSMember={props.changeRevalidateWSMember} changeWKValidated={props.changeWKValidated} >

              <ToastContainer className=" is-size-7 has-text-weight-semibold has-text-black" hideProgressBar={false} newestOnTop={true} toastClassName=" p-2 " draggable={true} limit={4} autoClose={4000} position={"top-right"} closeOnClick={false} />

              {showRemindDP ? <ProfileModal restrictCloseForSecs={10} heading="Your Profile Picture" closeOnUpdate={true} close={() => { setShowRemindDP(false)}} /> : null}
              {/* {newNotif ?
                < ToastContainer
                  position="top-right"
                  autoClose={5000}
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                >
                  {newNotif}
                </ToastContainer>
                : null
              } */}



              {featuresDiv()}
            </WorkspaceValidatorAux>
          </WorkspaceAux>

          {/* {pageNavBar()} */}
          {/* <VisualiseModal /> */}

          {/* <Entry /> */}
          {/* {projects()} */}
        </MainMenuAux>
      </AllContexts>

    </div>
  );
}



export default Dashboard;