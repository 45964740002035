import React, { useState } from 'react'
import { Box, Button, Message } from 'react-bulma-components'
import { Form as BulmaForm } from 'react-bulma-components'
import ColumnAux from '../../Containers/ColumnAux'
import { Formik, Form, Field } from 'formik'
import { startForgotPassword } from '../../utilities/Login'
import { validateEmail } from '../../utilities/Utilities'
import { SignType } from '../../utilities/Enums'








const ForgotPasswordForm = (props) => {


    const [showMessage, setShowMessage] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false)



    let initialVals = {
        email: null,
        password: null
    }



    const setShowMessageForTime = (thisMessage, type, secs) => {
        setShowMessage({
            type: "danger",
            message: thisMessage
        })
        setTimeout(() => {
            setShowMessage(null)
        }, (secs ?? 5) * 1000);
    }


    const tryForgotPassword = (email, callback) => {
        startForgotPassword(email, (succ, errMsg) => {
            if (succ) {
                setShowSuccess(true)
            } else {
                setShowMessageForTime(errMsg, "danger", 5)
                console.log(errMsg)
            }
            callback(succ, errMsg)
        })
    }


    const toggleSuccess = () => {
        setShowSuccess(!showSuccess)
    }


    return (
        <Formik
            initialValues={initialVals}

            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                tryForgotPassword(values.email, (succ, user, errMsg) => {
                    actions.setSubmitting(false)
                    actions.resetForm(true)
                    actions.setValues(initialVals)
                })
            }}

        >

            {
                ({ values, isSubmitting, errors, touched, isValidating }) => {


                    return (

                        <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                            <Box className="has-text-left  is-shadowless">
                                {
                                    showMessage ?
                                        <Message color={showMessage.type ?? "default"}>
                                            <Message.Body>{showMessage.message}</Message.Body>
                                        </Message>
                                        : null
                                }
                                {
                                    showSuccess ?
                                        <Box className="m-0 p-0 ">

                                            <Message color={"success"}>
                                                <Message.Body>If this email has an account. Password reset instructions will be instantly mailed to you. Please check your inbox and also spam folder if you cannot see it</Message.Body>
                                            </Message>
                                            <Button className="mb-4" type="text" textColor="grey" size="small" onClick={toggleSuccess}> Did'nt recieve. Send Email Again</Button>
                                        </Box>
                                        :
                                        <Form>
                                            <BulmaForm.Field >
                                                <BulmaForm.Label textWeight="default">Email</BulmaForm.Label>
                                                <BulmaForm.Control>
                                                    <Field name='email' type='email' value={values.email} className='input is-default' validate={validateEmail} placeholder="e.g. my@email.com" />
                                                    {/* <Form.Input color="default" type="text" placeholder="Field control - text input" /> */}
                                                </BulmaForm.Control>
                                                {errors.email && touched.email && <BulmaForm.Help color="danger">{errors.email}</BulmaForm.Help>}
                                            </BulmaForm.Field>
                                            <Button disabled={isSubmitting || isValidating} loading={isSubmitting} type="submit" className="is-fullwidth mt-4" color="black"> Reset Password</Button>
                                        </Form>


                                }


                                <br />
                                <br />
                                <Box className=" mt-2 p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light">
                                    <Button  className="  is-text has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.SignIn) }} >Try Sign In Again</Button>

                                    <div className="mt-4 has-tex-centered">
                                        <p className="">
                                            <span className="">Already have instruction? </span>
                                        </p>
                                        <Button  className=" has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.ResetPassword) }} > Use Instruction</Button>
                                    </div>

                                    <div className="mt-5 has-tex-centered">
                                        <p className="">
                                            <span className="">  Need a TestPost account? </span>
                                        </p>
                                        <Button  className=" has-background-transparent " size="small" onClick={() => { props.toggleSignType(SignType.SignUp) }} > Create a free account</Button>
                                    </div>
                                </Box>
                            </Box>
                        </ColumnAux>


                    )

                }
            }

        </Formik>
    )

}

export default ForgotPasswordForm