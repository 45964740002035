import { EntityType, UserRole } from "../Models/EnumsAndPointers"
import { getCurrentWorkspace, isCurrentUser } from "./Login"



export const emailVerificationEssential = true

const onlyAdminOrOwnerCanSendInvites = false


export const canInviteMembers = () => {
    let wk = getCurrentWorkspace()
    let c = false
    if (wk){
        if (wk.currentUserRole) {
            // if (wk.currentUserRole === UserRole.reviewer || wk.currentUserRole === UserRole.Viewer)
    
            if (onlyAdminOrOwnerCanSendInvites) {
                if (wk.currentUserRole === UserRole.admin || wk.currentUserRole === UserRole.owner) {
                    c = true
                }
    
            } else if (wk.currentUserRole === UserRole.admin
                || wk.currentUserRole === UserRole.owner
                || wk.currentUserRole === UserRole.manager
                || wk.currentUserRole === UserRole.member
            ) {
                c = true
            }
        }
    }

    return c
}

export const assignableInvitaionRoles = () => {
    let wk = getCurrentWorkspace()
    let roles = []
    if (wk){
        if (wk.currentUserRole) {

            if (wk.currentUserRole === UserRole.admin) {
                roles = [UserRole.owner, UserRole.manager, UserRole.member, UserRole.reviewer]
            }
    
            if (wk.currentUserRole === UserRole.owner) {
                roles = [UserRole.manager, UserRole.member, UserRole.reviewer]
            }
    
            if (wk.currentUserRole === UserRole.manager) {
                roles = [UserRole.member, UserRole.reviewer]
            }
    
            if (wk.currentUserRole === UserRole.member) {
                roles = [UserRole.member, UserRole.reviewer]
            }
    
            if (wk.currentUserRole === UserRole.reviewer) {
                roles = [UserRole.reviewer]
            }
        }
    }
   
    return roles
}

export const canEditWorkspace = () => {
    let wk = getCurrentWorkspace()
    let c = false
    if (wk){
        if (wk.currentUserRole) {
            // if (wk.currentUserRole === UserRole.reviewer || wk.currentUserRole === UserRole.Viewer)
            if (wk.currentUserRole === UserRole.admin
                || wk.currentUserRole === UserRole.owner
            ) {
                c = true
            }
        }
    }

    return c
}

export const canEditEntity = (entityType, byUserInfo, membersInfo) => {
    let cu = isCurrentUser()

    if (!cu){
        return false
    }
    let thisMemberInfo = null
    if (membersInfo) {
        thisMemberInfo = membersInfo.filter((m) => { return m.id === cu.id })
    }

    if (byUserInfo.id === cu.id) { // For all entities
        return true
    }

    if (entityType === EntityType.project) {
        // if any new steps are needed
    }

    return false
}

export const canDeleteEntity = (entityType, byUserInfo, membersInfo) => {
    let cu = isCurrentUser()

    if (!cu){
        return false
    }

    let thisMemberInfo = null
    if (membersInfo) {
        thisMemberInfo = membersInfo.filter((m) => { return m.id === cu.id })
    }

    if (byUserInfo.id === cu.id) { // For all entities
        return true
    }

    if (entityType === EntityType.project) {
        // if any new steps are needed
    }

    return false
}


