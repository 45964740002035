import React, { useState } from 'react'
import { Box, Columns, Hero, Heading, Container, Button, Form, Message, Image } from 'react-bulma-components'
import ColumnAux from '../Containers/ColumnAux'
// import marketingImage from '../assets/marketingImage.png'
import marketingImage from '../assets/markImage.jpg'

import logoImage from '../assets/logo.svg'
import { SignType } from '../utilities/Enums'
import SignInForm from '../Components/Sign/SignInForm'
import SignUpForm from '../Components/Sign/SignUpForm'
import ForgotPasswordForm from '../Components/Sign/ForgotPasswordForm'



const Sign = (props) => {


    let [signType, setSignType] = useState(SignType.SignIn)


    const toggleSignType = (type) => {
        setSignType(type)
    }




    const sign = () => {

        let thisForm = <SignInForm toggleSignType={toggleSignType} userSigned={props.userSigned} />

        switch (signType) {
            case SignType.SignUp:
                thisForm = <SignUpForm toggleSignType={toggleSignType} userSigned={props.userSigned} />
                break;
            case SignType.ForgotPassword:
                thisForm = <ForgotPasswordForm toggleSignType={toggleSignType} userSigned={props.userSigned} />;
                break;
            case SignType.ResetPassword:
                thisForm = resetPasswordForm();
                break;
            default:
                break;
        }


        return (
            <Box className="has-text-centered  has-background-transparent is-shadowless">
                <img src={logoImage} className="is-centered has-text-centered  centered" width={150} />
                <Heading size={4} weight="light" > {signType} </Heading>
                {thisForm}


            </Box>
        )
    }

    const signInForm = () => {

        return (
            <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                <Box className="has-text-left  is-shadowless">
                    {/* <Message color="default">
                    <Message.Body>Vertical Form</Message.Body>
                </Message> */}
                    <form>
                        <Form.Field >
                            <Form.Label textWeight="default">Email</Form.Label>
                            <Form.Control>
                                <Form.Input color="default" type="text" placeholder="Field control - text input" />
                            </Form.Control>
                            {/* <Form.Help color="danger">Help text for this field</Form.Help> */}
                        </Form.Field>
                        <Form.Field >
                            <Form.Label textWeight="default">Password</Form.Label>
                            <Form.Control fullwidth>
                                <Form.Input color="default" type="tel" placeholder="555-555-555" />
                            </Form.Control>
                            {/* <Form.Help>Help text for this field</Form.Help> */}
                        </Form.Field>
                        <Button className="is-fullwidth mt-4" color="black"> Sign In</Button>


                    </form>

                    <br />
                    <br />
                    <Box className=" mt-2 p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light">
                        <div className=" has-tex-centered">
                            <p className="">
                                <span className="">  Need a TestPost account? </span>

                            </p>
                            <Button  className=" has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.SignUp) }} > Create a free account</Button>

                        </div>

                        <Button  className=" mt-4 is-text has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.ForgotPassword) }} >Forgot Password ?</Button>
                    </Box>
                </Box>
            </ColumnAux>
        )
    }

    const signUpForm = () => {

        return (
            <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                <Box className="has-text-left  is-shadowless">
                    {/* <Message color="default">
                    <Message.Body>Vertical Form</Message.Body>
                </Message> */}
                    <form>
                        <Form.Field >
                            <Form.Label textWeight="default">Name</Form.Label>
                            <Form.Control>
                                <Form.Input color="default" type="text" placeholder="Field control - text input" />
                            </Form.Control>
                            {/* <Form.Help color="danger">Help text for this field</Form.Help> */}
                        </Form.Field>
                        <Form.Field >
                            <Form.Label textWeight="default">Email</Form.Label>
                            <Form.Control>
                                <Form.Input color="default" type="text" placeholder="Field control - text input" />
                            </Form.Control>
                            {/* <Form.Help color="danger">Help text for this field</Form.Help> */}
                        </Form.Field>
                        <Form.Field >
                            <Form.Label textWeight="default">Password</Form.Label>
                            <Form.Control >
                                <Form.Input color="default" type="tel" placeholder="555-555-555" />
                            </Form.Control>
                            {/* <Form.Help>Help text for this field</Form.Help> */}

                        </Form.Field>
                        <Form.Field >
                            <Form.Label textWeight="default">Confirm Password</Form.Label>
                            <Form.Control>
                                <Form.Input color="default" type="text" placeholder="Field control - text input" />
                            </Form.Control>
                            {/* <Form.Help color="danger">Help text for this field</Form.Help> */}
                        </Form.Field>
                        <Button className="is-fullwidth mt-4" color="black"> Create Account</Button>


                    </form>

                    <br />
                    <br />
                    <Box className=" mt-2 p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light">
                        <div className=" has-tex-centered">
                            <p className="">
                                <span className="">  Already have an account? </span>

                            </p>
                            <Button  className=" has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.SignIn) }} > Sign In Now</Button>

                        </div>

                        {/* <Button className=" " className=" mt-4 is-text has-background-transparent " size="small" >Forgot Password ?</Button> */}
                    </Box>



                </Box>
            </ColumnAux>


        )
    }

    const forgotPasswordForm = () => {

        return (
            <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                <Box className="has-text-left  is-shadowless">
                    {/* <Message color="default">
                    <Message.Body>Vertical Form</Message.Body>
                </Message> */}
                    <form>
                        <Form.Field >
                            <Form.Label textWeight="default">Your Email</Form.Label>
                            <Form.Control>
                                <Form.Input color="default" type="text" placeholder="Field control - text input" />
                            </Form.Control>
                            {/* <Form.Help color="danger">Help text for this field</Form.Help> */}
                        </Form.Field>

                        <Button className="is-fullwidth mt-4" color="black"> Send Reset Instructions</Button>

                    </form>

                    <br />
                    <br />
                    <Box className=" mt-2 p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light">
                        <Button  className="  is-text has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.SignIn) }} >Try Sign In Again</Button>

                        <div className="mt-4 has-tex-centered">
                            <p className="">
                                <span className="">Already have instruction? </span>
                            </p>
                            <Button  className=" has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.ResetPassword) }} > Use Instruction</Button>
                        </div>

                        <div className="mt-5 has-tex-centered">
                            <p className="">
                                <span className="">  Need a TestPost account? </span>
                            </p>
                            <Button  className=" has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.SignUp) }} > Create a free account</Button>
                        </div>
                    </Box>
                </Box>
            </ColumnAux>

        )
    }

    const resetPasswordForm = () => {

        return (
            <ColumnAux isMiddle={true} size={7} noPaddingSides={false}>

                <Box className="has-text-left  is-shadowless">
                    {/* <Message color="default">
                    <Message.Body>Vertical Form</Message.Body>
                </Message> */}
                    <form>
                        <Form.Field >
                            <Form.Label textWeight="default">New Password</Form.Label>
                            <Form.Control>
                                <Form.Input color="default" type="text" placeholder="Field control - text input" />
                            </Form.Control>
                            {/* <Form.Help color="danger">Help text for this field</Form.Help> */}
                        </Form.Field>
                        <Form.Field >
                            <Form.Label textWeight="default">Confirm New Password</Form.Label>
                            <Form.Control>
                                <Form.Input color="default" type="text" placeholder="Field control - text input" />
                            </Form.Control>
                            {/* <Form.Help color="danger">Help text for this field</Form.Help> */}
                        </Form.Field>

                        <Button className="is-fullwidth mt-4" color="black"> Update Password </Button>

                    </form>

                    <br />
                    <br />
                    <br />

                    <Box className=" mt-2 p-0 has-text-centered is-shadowless is-size-6 has-text-grey-light">
                        <Button  className=" has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.SignIn) }} >Try Sign In Now</Button>
                        <div className="mt-5 has-tex-centered">
                            <p className="">
                                <span className="">  Need a TestPost account? </span>
                            </p>
                            <Button  className="is-text has-background-transparent " size="small" onClick={() => { toggleSignType(SignType.SignUp) }} > Create a free account</Button>
                        </div>
                    </Box>
                </Box>
            </ColumnAux>

        )
    }


    const signPage = () => {

        return (

            <Hero size="is-fullHeight" className=" has-background-white ">


                {/* <Hero.Header renderAs="header">Header</Hero.Header> */}
                <Hero.Body className=" p-0">
                    <Container max={true} breakpoint="fluid" className="p-0 ">
                        {/* <Heading>Hero title Info</Heading>
                        <Heading subtitle size={3}>
                            Subtitle
                        </Heading> */}

                        <Columns className=" is-vcentered is-gapless">

                            <Columns.Column className="">
                                {sign()}
                            </Columns.Column>
                            <Columns.Column>

                                <Hero size="is-fullHeight" className="has-background-transparent">
                                    <Hero.Body >
                                        <Container breakpoint="default">

                                            <Box className="has-text-centered has-background-transparent is-shadowless is-radiusless m-0 p-0">
                                                <img alt="Promotion" src={marketingImage} className="is-centered  " />
                                                {/* <Heading size={1}>
                                                    Collabration made easy
                                                </Heading> */}
                                            </Box>
                                        </Container>

                                    </Hero.Body>
                                </Hero>
                            </Columns.Column>

                        </Columns>

                    </Container>
                </Hero.Body>
                {/* <Hero.Footer>Footer</Hero.Footer> */}

            </Hero>

        )

    }


    return (
        <div>
            {signPage()}
        </div>
    )


}

export default Sign