import Parse from 'parse'
import { currentUserInfo, getCurrentWorkspaceId, isCurrentUser } from '../utilities/Login';
import { removeKeysFromUserInfo_Obj } from '../utilities/Utilities';
import { Annotation } from './Annotaion';
import { appMeta, userPointer, CommentMessageType, projectPointer, assetPointer, workspacePointer, entityInfo_ForNotif, NotificationFor, EntityType } from './EnumsAndPointers';
import { Project } from './Project';

const CommentClass = Parse.Object.extend("Comment");



export class Comment {

    constructor(id, byUser, byUserInfo, message, annotations, messageType, isReply, replies, replyToId, replyToUserInfo,  upvotes, asset, assetId, assetType, assetName, assetThumbnailUrl, assetVersion, project, projectId, workspace, createdAt, assignedToUsers, assignedToUsersInfo, isMarkedDone, isMarkedDoneBy, isMarkedDoneByInfo, tags, isEdited, updatedAt, isArchived, isArchivedBy, isArchivedByName, thisAppMeta) {
        this.id = id
        this.byUser = byUser
        this.byUserInfo = byUserInfo
        this.message = message
        this.annotations = annotations ?? []
        this.messageType = messageType ?? CommentMessageType.general
        this.isReply = isReply ?? false
        this.replies = replies ?? []
        this.replyToId = replyToId
        this.replyToUserInfo = replyToUserInfo
        this.upvotes = upvotes ?? []
        this.asset = asset
        this.assetId = assetId
        this.assetType = assetType 
        this.assetName = assetName
        this.assetThumbnailUrl = assetThumbnailUrl
        this.assetVersion = assetVersion
        this.project = project
        this.projectId = projectId
        this.workspace = workspace
        this.createdAt = createdAt
        this.assignedToUsers = assignedToUsers ?? []
        this.assignedToUsersInfo = assignedToUsersInfo ?? []
        this.isMarkedDone = isMarkedDone ?? false
        this.isMarkedDoneBy = isMarkedDoneBy
        this.isMarkedDoneByInfo = isMarkedDoneByInfo
        this.tags = tags ?? []
        this.isEdited = isEdited ?? false
        this.updatedAt = updatedAt
        this.isArchived = isArchived ?? false
        this.isArchivedBy = isArchivedBy
        this.isArchivedByName = isArchivedByName
        this.appMeta = thisAppMeta ?? appMeta

    }

    static copyFrom(obj) {
        let ob = new Comment()
        ob.id                   = obj.id                 
        ob.byUser               = obj.byUser             
        ob.byUserInfo           = obj.byUserInfo         
        ob.message              = obj.message            
        ob.annotations          = obj.annotations        
        ob.messageType          = obj.messageType        
        ob.isReply              = obj.isReply            
        ob.replies              = obj.replies            
        ob.replyToId            = obj.replyToId    
        ob.replyToUserInfo      = obj.replyToUserInfo      
        ob.upvotes              = obj.upvotes            
        ob.asset                = obj.asset  
        ob.assetId              = obj.assetId  
        ob.assetType            = obj.assetType  
        ob.assetName            = obj.assetName          
        ob.assetThumbnailUrl    = obj.assetThumbnailUrl  
        ob.assetVersion         = obj.assetVersion       
        ob.project              = obj.project           
        ob.projectId            = obj.projectId 
        ob.workspace            = obj.workspace          
        ob.createdAt            = obj.createdAt          
        ob.assignedToUsers      = obj.assignedToUsers    
        ob.assignedToUsersInfo  = obj.assignedToUsersInfo
        ob.isMarkedDone         = obj.isMarkedDone       
        ob.isMarkedDoneBy       = obj.isMarkedDoneBy     
        ob.isMarkedDoneByInfo   = obj.isMarkedDoneByInfo 
        ob.tags                 = obj.tags               
        ob.isEdited             = obj.isEdited           
        ob.updatedAt            = obj.updatedAt          
        ob.isArchived           = obj.isArchived         
        ob.isArchivedBy         = obj.isArchivedBy       
        ob.isArchivedByName     = obj.isArchivedByName   
        ob.appMeta              = obj.appMeta            

        return ob
    }


    thumbnailObject = (assetId, projectId) => {
        return {
            id: this.id,
            byUserInfo: this.byUserInfo,
            message: this.message,
            annotations: this.annotations,
            messageType: this.messageType,
            isReply: this.isReply,
            assetId: this.assetId ?? assetId,
            assetUrl: this.assetThumbnailUrl,
            assetType: this.assetType,
            assetVersion: this.assetVersion,
            projectId: this.projectId ?? projectId ,
            workspace: getCurrentWorkspaceId(),
            assignedToUsersInfo: this.assignedToUsersInfo,
            tags: this.tags
        }
    }


    [
        {
          "id": "0dK1wAfPQR",
          "byUserInfo": {
            "id": "QaxhunbbV7",
            "name": "2applivin",
            "imageUrl": "https://randomuser.me/api/portraits/men/44.jpg",
            "email": "2applivin@gmail.com",
            "role": ""
          },
          "message": "This is my reply",
          "annotations": [],
          "messageType": "General",
          "isReply": false,
          "asset": {
            "__type": "Pointer",
            "className": "Asset",
            "objectId": "IqAv0j3A4p"
          },
          "project": {
            "__type": "Pointer",
            "className": "Project",
            "objectId": "wzaQ19KcEF"
          },
          "workspace": "vH8WsgaYjX",
          "assignedToUsersInfo": [],
          "tags": [],
        }
      ]



    static copyFrom = (obj) => {
        let newObj = new Comment()
        // Clones are only shallow and provide methods by reference only. Which means when u run methods on instance, they will not as expected/
        // Object.assign(newCm, obj)
        // Hence manual cloning is best
        newObj.id = obj.id
        newObj.byUser = obj.byUser
        newObj.byUserInfo = obj.byUserInfo
        newObj.message = obj.message
        newObj.annotations = obj.annotations
        newObj.messageType = obj.messageType
        newObj.isReply = obj.isReply
        newObj.replies = obj.replies
        newObj.replyToId = obj.replyToId
        newObj.replyToUserInfo = obj.replyToUserInfo
        newObj.upvotes = obj.upvotes
        newObj.asset = obj.asset
        newObj.assetId = obj.assetId
        newObj.assetType = obj.assetType
        newObj.assetName = obj.assetName
        newObj.assetThumbnailUrl = obj.assetThumbnailUrl
        newObj.assetVersion = obj.assetVersion
        newObj.project = obj.project
        newObj.projectId = obj.projectId
        newObj.workspace = obj.workspace
        newObj.createdAt = obj.createdAt
        newObj.assignedToUsers = obj.assignedToUsers
        newObj.assignedToUsersInfo = obj.assignedToUsersInfo
        newObj.isMarkedDone = obj.isMarkedDone
        newObj.isMarkedDoneBy = obj.isMarkedDoneBy
        newObj.isMarkedDoneByInfo = obj.isMarkedDoneByInfo
        newObj.tags = obj.tags
        newObj.isEdited = obj.isEdited
        newObj.updatedAt = obj.updatedAt
        newObj.isArchived = obj.isArchived
        newObj.isArchivedBy = obj.isArchivedBy
        newObj.isArchivedByInfo = obj.isArchivedByInfo
        newObj.appMeta = obj.appMeta
        return newObj
    }


    static initFromPFObject = (obj) => {
        let np = new Comment()

        if (!obj.id) {
            return null
        }

        np.id = obj.id

        let bu = obj.get("byUser")
        np.byUser = bu.id ?? isCurrentUser().id

        // console.log("byUser is")
        // console.log(bu)
        // console.log("byUser Id is")
        // console.log(bu.id)


        np.byUserInfo = obj.get("byUserInfo")
        np.message = obj.get("message")
        let anntObjs = obj.get("annotations") ?? []
        let annts = []

        anntObjs.forEach(a => {
            let an = Annotation.initFromPFObject(a)
            if (an){
                annts.push(an)
            }
        })
        np.annotations = annts

        console.log("Comment annotations are")
        console.log(np.annotations)
    
        np.messageType = obj.get("messageType") ?? CommentMessageType.general
        np.isReply = obj.get("isReply") ?? false
        np.replies = obj.get("replies") ?? []
        np.replyToId = obj.get("replyToId")
        np.replyToUserInfo = obj.get("replyToUserInfo")
        np.upvotes = obj.get("upvotes") ?? []
        np.asset = obj.get("asset")
        np.assetId = obj.get("assetId")
        np.assetType = obj.get("assetType")
        np.assetName = obj.get("assetName")
        np.assetThumbnailUrl = obj.get("assetThumbnailUrl")
        np.assetVersion = obj.get("assetVersion")
        np.project = obj.get("project")

        let wk = obj.get("workspace")
        np.workspace = wk.id

        np.createdAt = obj.get("createdAt")
        np.assignedToUsers = obj.get("assignedToUsers") ?? []
        np.assignedToUsersInfo = obj.get("assignedToUsersInfo") ?? []
        np.isMarkedDone = obj.get("isMarkedDone") ?? false
        np.isMarkedDoneBy = obj.get("isMarkedDoneBy")
        np.isMarkedDoneByInfo = obj.get("isMarkedDoneByInfo")
        np.tags = obj.get("tags") ?? []
        np.isEdited = obj.get("isEdited") ?? false
        np.updatedAt = obj.get("updatedAt")
        np.projectId = obj.get("projectId")
        np.isArchived = obj.get("isArchived") ?? false
        np.isArchivedBy = obj.get("isArchivedBy")
        np.isArchivedByInfo = obj.get("isArchivedByInfo")
        np.appMeta = obj.get("appMeta")

        return np
    }

    add = (callback) => {
        let ob = new CommentClass()

        const cu = isCurrentUser()
        ob.set("byUser", userPointer(cu.id))
        ob.set("byUserInfo", currentUserInfo())
        ob.set("message", this.message)
        ob.set("annotations", this.annotations)
        ob.set("messageType", this.messageType)
        ob.set("isReply", this.isReply)
        ob.set("replies", this.replies)
        if (this.replyToId) { ob.set("replyToId", this.replyToId) }
        if (this.replyToUserInfo) { ob.set("replyToUserInfo", this.replyToUserInfo) }
        ob.set("upvotes", this.upvotes)
        ob.set("asset", assetPointer(this.asset.id))
        ob.set("assetId", this.assetId ?? this.asset.id)
        ob.set("assetType", this.assetType)
        ob.set("assetName", this.assetName)
        ob.set("assetThumbnailUrl", this.assetThumbnailUrl)

        ob.set("assetVersion", this.assetVersion)
        ob.set("project", projectPointer(this.project.id))
        ob.set("projectId", this.projectId ?? this.project.id)

        // make workspace neceesary
        ob.set("workspace", workspacePointer(this.workspace.id))

        ob.set("assignedToUsers", this.assignedToUsers)
        ob.set("assignedToUsersInfo", this.assignedToUsersInfo)
        ob.set("isMarkedDone", this.isMarkedDone)
        if (this.isMarkedDoneBy) { ob.set("isMarkedDoneBy", this.isMarkedDoneBy) }
        if (this.isMarkedDoneByInfo) { ob.set("isMarkedDoneByInfo", this.isMarkedDoneByInfo) }
        ob.set("tags", this.tags)
        ob.set("isEdited", this.isEdited)
        // no updatedAt on creation
        ob.set("updatedAt", this.updatedAt)
        // cannot be archived on creation
        ob.set("appMeta", this.appMeta)

        ob.save()
            .then((proj) => {
                this.id = proj.id
                this.createdAt = proj.createdAt
                console.log('New Comment Created')

                if (this.isReply && this.replyToUserInfo){
                    // IF ReplyTO UserINfo is avaialbel 

                    let entityInfoObj = entityInfo_ForNotif(EntityType.comment, ob, [], null, this.replyToUserInfo)
                    Project.getProjectDetails_AndNotify(this.projectId ?? this.project.id, NotificationFor.comment_replyAdded, entityInfoObj)

                }else{
                    let entityInfoObj = entityInfo_ForNotif(EntityType.comment, ob, [], null, null)
                    Project.getProjectDetails_AndNotify(this.projectId ?? this.project.id, NotificationFor.comment_added, entityInfoObj)
                }




                callback(true, this, '')

            }, (error) => {
                console.log('Failed to create new comment, with error code: ' + error.message);
                callback(false, null, error.message)
            });
    }


    static addUniqueReply = (commentId, replyId, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        query.get(commentId)
            .then((ob) => {
                // only what can be changed after creation
                console.log("Adding unique with reply id")
                console.log(replyId)

                ob.addUnique("replies", replyId)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding comment replies =' + error.message)
            });
    }


    static removeReply = (commentId, replyId, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        query.get(commentId)
            .then((ob) => {
                // only what can be changed after creation
                ob.remove("replies", replyId)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing comment from replies =' + error.message)
            });
    }

    static deleteAll = (commentIds) => {

        console.log("Reply Ids to delete")
        console.log(commentIds)
        // let pfObjs = []

        // Alt 1  - Assurance 0
        // make PFOBJECTS OF ALL THESE COMMENTS AND PASS IT TO DESTRYO ALL


        // Alt 2 - Assurance 90
        // find them. Then pass it to destroy all as mentioned
        // var query = new Parse.Query(CommentClass);
        // query.containedIn("_id", commentIds)  // not fetching archived 
        // query.find().then((objects) => {
        //     console.log('All COMMENTS =')
        //     console.log(objects)

        //     objects.forEach(thisObj => thisObj.destroy().then(r=> console.log("reply deleted ") ).catch(e => console.log("Reply could not be deleted - " + e.message)) )

        //     // Parse.Object.destroyAll(objects).then((result) => {
        //     //     console.log("All requested comments were destroyed with result")
        //     //     console.log(result)
        //     // })
        // }).catch((error) => {
        //     errorCallback("Comments if deleteAll were not found Or were not deleted - " + error.message)
        // })


        // BETTER> NEED CLOUD CLEANUP FOR THIS 
        commentIds.forEach(cmId => {
            Comment.delete(cmId, (succ, errMsg) => {
                if (!succ) {
                    console.log("While deleteAll replies - " + errMsg)
                }
            })
        })
    }


    addAssignToUser = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                ob.addUnique("assignedToUsers", userId)
                ob.addUnique("assignedToUsersInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding assignTo user in comment =' + error.message)
            });
    }

    removeAssignToUser = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                ob.remove("assignedToUsers", userId)
                ob.remove("assignedToUsersInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing assignTo user in comment =' + error.message)
            });
    }


    addUpvote = (userInfo, comment, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        console.log("ADDING UPVOTE");
        console.log(usrInfo);

        let commentByUserInfo = null
        if (comment){
            if (comment.byUserInfo){
                commentByUserInfo = comment.byUserInfo
            }
        }


        query.get(this.id)
            .then((ob) => {
                ob.addUnique("upvotes", usrInfo)
                ob.save()

                if (commentByUserInfo){
                    let entityInfoObj = entityInfo_ForNotif(EntityType.comment, ob, [], null, commentByUserInfo)
                    Project.getProjectDetails_AndNotify(this.projectId ?? this.project.id, NotificationFor.comment_upvoteAdded, entityInfoObj)
                }


            }).catch((error) => {
                errorCallback('Error while adding upvote in comment =' + error.message)
            });
    }

    removeUpvote = (userInfo, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                ob.remove("upvotes", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing upvote in comment =' + error.message)
            });
    }

    addTag = (tag, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        query.get(this.id)
            .then((ob) => {
                ob.addUnique("tags", tag)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while adding tag in comment =' + error.message)
            });
    }

    removeTag = (tag, errorCallback) => {
        var query = new Parse.Query(CommentClass);
        query.get(this.id)
            .then((ob) => {
                ob.remove("tags", tag)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing tag in comment =' + error.message)
            });
    }




    update = (callback) => {
        if (this.id) {
        } else {
            callback(false, null, "Error : No object id to update Comment")
            return
        }

        console.log("COMMENT UPDATE WAS CALLED")

        var query = new Parse.Query(CommentClass);
        query.get(this.id)
            .then((ob) => {
                // only what can be changed after creation
                ob.set("message", this.message)
                ob.set("annotations", this.annotations)
                ob.set("messageType", this.messageType)
                // ob.set("replies", this.replies)
                // this.replies.forEach
                // ob.addUnique("replies", this.replies)

                ob.set("assetType", this.assetType)
                ob.set("assetName", this.assetName)
                ob.set("assetVersion", this.assetVersion)
                ob.set("assetThumbnailUrl", this.assetThumbnailUrl)

                // ob.set("upvotes", this.upvotes)
                // ob.set("assignedToUsers", this.assignedToUsers)
                // ob.set("assignedToUsersInfo", this.assignedToUsersInfo)
                ob.set("isMarkedDone", this.isMarkedDone)
                if (this.isMarkedDoneBy && this.isMarkedDone) { ob.set("isMarkedDoneBy", this.isMarkedDoneBy) } else { ob.unset("isMarkedDoneBy") }
                if (this.isMarkedDoneByInfo && this.isMarkedDone) { ob.set("isMarkedDoneByInfo", this.isMarkedDoneByInfo) } else { ob.unset("isMarkedDoneByInfo") }

                if (this.isMarkedDone && this.isMarkedDoneByInfo){
                    let entityInfoObj = entityInfo_ForNotif(EntityType.comment, ob, [], null, this.byUserInfo)
                    Project.getProjectDetails_AndNotify(this.projectId ?? this.project.id, NotificationFor.comment_resolved, entityInfoObj)
                }

                // ob.set("tags", this.tags)
                ob.set("isEdited", true) // marking true as the comment is being editing/updated now

                ob.set("isArchived", this.isArchived)
                if (this.isArchivedBy && this.isArchived) { ob.set("isArchivedBy", this.isArchivedBy) } else { ob.unset("isArchivedBy") }
                if (this.isArchivedByInfo && this.isArchived) { ob.set("isArchivedByInfo", this.isArchivedByInfo) } else { ob.unset("isArchivedByInfo") }

                ob.set("appMeta", this.appMeta)

                ob.save()
                this.updatedAt = new Date() // cannot change updatedAt on server as it is done itself. So just changing here
                callback(true, this, '')
            }, (error) => {
                callback(false, null, 'Error while updating comment =' + error.message)
            });
    }

    static delete = (id, callback) => {

        if (id === null) {
            callback(false, "Error : No id to delete Comment ")
        }
        let thisObj = new CommentClass()
        thisObj.id = id
        console.log(false, "Deleting comment with id = " + id)

        thisObj.destroy().then((myObject) => {
            console.log("Deleted comment after.. My Object = ")
            console.log(myObject)

            callback(true, '')

        }, (error) => {
            callback(false, 'Error while deleting comment ' + error.message)
        });
    }

    archive = (callback) => {
        const cu = isCurrentUser()
        this.isArchived = true
        this.archivedBy = userPointer(cu.id)
        this.isArchivedByInfo = currentUserInfo()
        this.update(callback)
    }

    static getAll = (forAssetId, replyToId, sort, callback) => {
        console.log('Searching For Comments ')
        var query = new Parse.Query(CommentClass);

        // Must have rules
        if (forAssetId) {
            query.equalTo("asset", assetPointer(forAssetId))
        }
        if (replyToId) {
            query.equalTo("isReply", true)
            query.equalTo("replyToId", replyToId)
        } else {
            query.notEqualTo('isReply', true)  // not fetching replies in general comments 
        }

        // if (filter) {
            // if (getMarkedDoneOnly && getMarkedUndoneOnly === false){
            //     query.equalTo("isMarkedDone", true)
            // }

            // if (getMarkedUndoneOnly  &&  getMarkedDoneOnly === false){
            //     query.notEqualTo("isMarkedDone", true)
            // }
        // }


        // Apply pagination
        query.limit(100)

        // Apply any further filters
        query.notEqualTo('isArchived', true)  // not fetching archived 


        // Apply sort
        query.descending('createdAt')


        query.find().then((objects) => {
            console.log('All COMMENTS =')
            console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Comment.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Comment found for asset ' + forAssetId)
            }

        }, (error) => {
            callback(false, [], error.message)
        })
    }
}