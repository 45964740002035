import React, { useContext, useEffect, useState } from 'react'
import 'bulma/css/bulma.min.css';
import logo from '../assets/logo.svg'
import menuOpen from '../assets/menu-open.png'
import menuClose from '../assets/menu-close.png'
import userImage from '../assets/userImage.svg'
import searchImage from '../assets/search.svg'
import uploadImage from '../assets/upload.png'
import uploadCircleImage from '../assets/upload-circle.png'
import uploadFileImage from '../assets/upload-file.png'


import notificationImage from '../assets/notification.svg'
import chatImage from '../assets/chat.svg'
import videoImage from '../assets/video.svg'
import howtoImage from '../assets/howto.svg'


import { Box, Menu, Columns, Image, Button, Navbar, Footer, Level, Table, Dropdown, Modal, Media, Content, Panel, Form, Tabs, Heading, Breadcrumb, Tag, Message } from 'react-bulma-components';
import { isCurrentUser, isCurrentUserEmailVerified, logoutNow, getOwnedWorkspacesIds, getCurrentWorkspaceId, setShowInvitationIfNeeded, getShowInvitationReminderToAdmin, setShowInvitationReminderToAdmin, getCurrentWorkspace, currentUserInfo } from '../utilities/Login';
import ColumnAux from '../Containers/ColumnAux';
import PricingPlans from './Payment/PricingPlans';
import Uploader from './Upload/Uploader';

import { createStripeCustomerPortalSession, createStripeSession } from '../utilities/Service';
import VerifyEmailModal from './Verify/VerifyEmailModal';
import { canEditWorkspace, canInviteMembers, emailVerificationEssential } from '../utilities/TpRules';
import { TiSortNumericallyOutline } from 'react-icons/ti';
import InvitationModal from './Invitation/InvitationModal';
import { UploadContext } from '../Contexts/UploadContext';
import Lottie from "lottie-react";
import Lottie_upload_black from '../assets/Lottie_upload_black.json'
import { IoNuclearOutline } from 'react-icons/io5';
import TimeAgo from 'react-timeago'
import { Notification } from '../Models/Notification'
import { VscBell, VscBellDot } from 'react-icons/vsc';
import ProfileModal from './Sign/ProfileModal';



const MainMenuAux = (props) => {


    const [showSideMenu, setShowSideMenu] = useState(props.hasSideMenu && true)
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [showBilling, setShowBilling] = useState(false)
    const [processingPlan, setProcessingPlan] = useState(null)
    const [showUploader, setShowUploader] = useState(false)

    const [showInvitations, setShowInvitations] = useState(false)
    const [isInvitationReminder, setIsInvitationReminder] = useState(false)
    const [showWKSettings, setShowWKSettings] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [showPromo, setShowPromo] = useState(false)


    const [hasSideMenu, setHasSideMenu] = useState(props.hasSideMenu)

    const [emailVerifyNeeded, setEmailVerifyNeeded] = useState(false)


    // useContext
    const { uploads } = useContext(UploadContext)


    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            if (emailVerificationEssential) {
                setEmailVerifyNeeded(!isCurrentUserEmailVerified())
            }

            setShowInvitationIfNeeded()

            if (props.workspace) {
                let ownedWKs = getOwnedWorkspacesIds() ?? []
                let wkId = getCurrentWorkspaceId() ?? ""

                if (ownedWKs.includes(wkId)) {

                    if (getShowInvitationReminderToAdmin())
                        setShowInvitations(true)
                    setIsInvitationReminder(true)
                }

            }
        }

        return () => (isMounted = false)

    }, [props.workspace])


    const concernedElement = document.querySelector(".navbar-burger");

    document.addEventListener("onClick", (event) => {

        console.log("CONCERNED ELEMENT IS")
        console.log(concernedElement)

        if (concernedElement !== null) {

            if (concernedElement.current.contains(event.target)) {
                toggleSideMainMenu()
            }
        }

    });


    const closeAllModals = () => {
        setShowSearch(false)
        setShowProfile(false)
        setShowBilling(false)
        setShowUploader(false)
    }

    let toggleUpload = () => {
        setShowUploader(!showUploader)
    }

    let toggleSearch = () => {
        setShowSearch(!showSearch)
    }

    let toggleProfile = () => {
        setShowProfile(!showProfile)
    }

    let toggleBilling = () => {
        setShowBilling(!showBilling)
    }

    let toggleInvitation = () => {
        setShowInvitations(!showInvitations)
    }

    let toggleInvitations = () => {
        setShowInvitations(!showInvitations)
    }

    const toggleSideMainMenu = () => {
        if (props.sideMenToggled) {
            props.sideMenToggled(!showSideMenu)
        }
        setShowSideMenu(!showSideMenu)
    }

    let logoImage = (isLight, addClassNames) => {

        let addedCl = addClassNames ?? ""

        return (
            <img
                src={logo}
                alt="TestPost: View, Test, Approve, Poll your posts"
                width="93"
                className={isLight ? "opacity25 " + addedCl : addedCl}
            />
        )
    }


    //     stick-to-top-minus20
    // stick-to-top-60
    // stick-to-top-61
    // stick-to-top-90
    // stick-to-top



    let openCustomerPortal = () => {

        let cu = isCurrentUser()
        let cuObjId = cu.id
        // let cuStripeCustId = cu.get("stripe_customerId")

        // let sampleCustomerId = "cus_JT1SAQPsLGAC3r"
        createStripeCustomerPortalSession(cuObjId, "http://localhost:3000", (succ, customerPortalSessionUrl, errMsg) => {
            if (succ) {
                // let stripe = window.Stripe;
                const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

                console.log("customerPortalSessionUrl is")
                console.log(customerPortalSessionUrl)

                if (customerPortalSessionUrl) {
                    if (stripe) {

                        window.location.href = customerPortalSessionUrl;

                    } else {
                        alert("There is no stripe object ")
                    }
                }


            } else {
                alert("Error creating customer portal session url - " + errMsg)

            }
        })

    }


    let billingPlanChosen = (plan) => {
        console.log("Chosen Billing Plan")
        console.log(plan)

        setProcessingPlan(plan)


        let cu = isCurrentUser()
        let cuObjId = cu.id
        let cuEmail = cu.getEmail()
        let cuExitingStripeCustId = cu.get("stripe_customerId")

        let trialDays = 1

        alert(`CURRENT USER OBjId= ${cuObjId}, Email= ${cuEmail}`)



        createStripeSession(plan.priceId, 5, cuObjId, cuEmail, cuExitingStripeCustId, trialDays, "http://localhost:3000/sub-session-cancelled", "http://localhost:3000/sub-creation-success", (succ, thisSessionId, errMsg) => {
            if (succ) {

                // let stripe = window.Stripe;
                const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

                console.log("STRIPE IS")
                console.log(stripe)

                if (stripe) {
                    stripe.redirectToCheckout({
                        sessionId: thisSessionId
                    }).then((result) => {
                        console.log("Stripe redirect to checkout result is")
                        console.log(result)

                    }).catch((error) => {
                        let thisErr = "Stripe redirect to checkout error = " + error.message;
                        console.log(thisErr)
                        alert(thisErr)
                    })

                } else {
                    alert("There is no stripe object ")
                }

            } else {
                alert("Error creating payment session - " + errMsg)
            }
            setProcessingPlan(null)

        })
    }

    let billingModal = () => {

        return (

            <Modal show={true} onClose={toggleBilling} closeOnBlur={true} showClose={false} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" h-90vh ">
                    <Modal.Card.Header showClose >
                        <Modal.Card.Title className="has-text-grey-light" >Billing</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0">


                        <Panel className=" ">
                            {/* <Panel.Header>Repositories</Panel.Header> */}
                            <Tabs size="small" backgroundColor="light" textWeight="semibold" className=" stick-to-top z-30 "  >
                                <Tabs.Tab active>Summary</Tabs.Tab>
                                <Tabs.Tab>Plans</Tabs.Tab>
                                <Tabs.Tab>Invoices</Tabs.Tab>
                                <Tabs.Tab>Setting</Tabs.Tab>
                            </Tabs>


                            <Box>
                                <Button color="black" onClick={openCustomerPortal}> Customer Portal </Button>
                            </Box>

                            <PricingPlans plansChosen={billingPlanChosen} processingPlan={processingPlan} />



                            <br />
                            <br />
                            <br />
                        </Panel>

                    </Modal.Card.Body>
                    {/* <Modal.Card.Footer renderAs={Button.Group} align="right" >
                    </Modal.Card.Footer> */}
                </Modal.Card>
            </Modal >
        )
    }




    let workspaceSettingsModal = () => {

    }

    let warningModal = () => {

    }

    let promoModal = () => {

    }


    let searchModal = () => {

        return (

            <Modal show={true} onClose={toggleSearch} closeOnBlur={true} showClose={false} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" h-90vh ">
                    <Modal.Card.Header showClose >
                        <Modal.Card.Title className="has-text-grey-light" >Search</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body className="p-0">


                        <Panel className="m-0 p-0">
                            {/* <Panel.Header>Repositories</Panel.Header> */}
                            <Panel.Block backgroundColor="light">
                                <Form.Control className=" mx-2" >
                                    <Columns className="is-mobile is-narrow" gap="gapless">
                                        <Columns.Column >
                                            <Form.Input color="dark" size="medium" type="text" placeholder="Type here..." />
                                        </Columns.Column>
                                        <Columns.Column size={3}>
                                            <Button fullwidth color="black" className="mt-0 mb-0 ml-minus-2 mr-1 p-0 is-fullHeight "> Search </Button>
                                        </Columns.Column>
                                    </Columns>
                                </Form.Control>

                            </Panel.Block>
                            <Panel.Tabs backgroundColor="light" className="panel-tabs " >
                                <Panel.Tabs.Tab active>All</Panel.Tabs.Tab>
                                <Panel.Tabs.Tab>public</Panel.Tabs.Tab>
                                <Panel.Tabs.Tab>private</Panel.Tabs.Tab>
                                <Panel.Tabs.Tab>sources</Panel.Tabs.Tab>
                                <Panel.Tabs.Tab>forks</Panel.Tabs.Tab>
                            </Panel.Tabs>

                            <Box shadowless className="mt-4">

                                <Box></Box>
                                <Box></Box>
                                <Box></Box>

                                {/* <Panel.Block renderAs="a" active>
                                    <Panel.Icon>
                                        <i className="fas fa-angle-down" />
                                    </Panel.Icon>
                            bulma
                            </Panel.Block>
                                <Panel.Block>
                                    <Panel.Icon>
                                        <i className="fas fa-angle-down" />
                                    </Panel.Icon>
                            react-bulma-components
                            </Panel.Block>
                                <Panel.Block>
                                    <Panel.Icon>
                                        <i className="fas fa-angle-down" />
                                    </Panel.Icon>
                            minireset.css
                            </Panel.Block>
                                <Panel.Block>
                                    <Panel.Icon>
                                        <i className="fas fa-angle-down" />
                                    </Panel.Icon>
                            jgthms.github.io
                            </Panel.Block>
                                <Panel.Block renderAs="label">
                                    <Form.Checkbox />
                            remember me
                            </Panel.Block>
                                <Panel.Block>
                                    <Button fullwidth outlined>
                                        reset all filters
                                </Button>
                                </Panel.Block> */}

                            </Box>


                            <br />
                            <br />
                            <br />
                        </Panel>





                        {/* <Media>
                            <Media.Item renderAs="figure" align="left">
                                <Image
                                    size={64}
                                    alt="64x64"
                                    src="http://bulma.io/images/placeholders/128x128.png"
                                />
                            </Media.Item>
                            <Media.Item>
                                <Content>
                                    <p>
                                        <strong>John Smith</strong> <small>@johnsmith</small>{' '}
                                        <small>31m</small>
                                        <br />
                            If the children of the Modal is a card, the close button
                            will be on the Card Head instead than the top-right corner
                            You can also pass showClose = false to Card.Head to hide the
                            close button
                          </p>
                                </Content>
                            </Media.Item>
                        </Media> */}









                    </Modal.Card.Body>
                    <Modal.Card.Footer align="right" >
                        {/* <Button color="success">Like</Button>
                        <Button>Share</Button> */}
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >


        )

    }



    let navBar = () => {

        return (
            <Navbar className="has-background-grey-lighter mt-2" >

                <Navbar.Brand >
                    <Navbar.Item renderAs="a" href="#" >
                        <img
                            src={logo}
                            alt="TestPost: View, Test, Approve, Poll your posts"
                            height="100"
                        />
                    </Navbar.Item>
                    <Navbar.Burger />
                </Navbar.Brand>
                <Navbar.Menu>
                    <Navbar.Container>
                        <Navbar.Item hoverable={true} active={false} href="#">
                            <Navbar.Link arrowless={false}>First</Navbar.Link>
                            <Navbar.Dropdown right={true} boxed={false}>
                                <Navbar.Item href="#">Subitem 1</Navbar.Item>
                                <Navbar.Item href="#">Subitem 2</Navbar.Item>
                                <Navbar.Divider />
                                <Navbar.Item href="#">After divider</Navbar.Item>
                            </Navbar.Dropdown>
                        </Navbar.Item>
                        <Navbar.Item href="#">Second</Navbar.Item>
                    </Navbar.Container>
                    <Navbar.Container align="end">
                        <Navbar.Container >
                            <Navbar.Item hoverable={true} active={false} href="#" className="mt-2 mr-2">
                                <Navbar.Link arrowless={true}>•••</Navbar.Link>
                                <Navbar.Dropdown right={true} boxed={false}>
                                    <Navbar.Item href="#">Subitem 1</Navbar.Item>
                                    <Navbar.Item href="#">Subitem 2</Navbar.Item>
                                    <Navbar.Divider />
                                    <Navbar.Item href="#">After divider</Navbar.Item>
                                </Navbar.Dropdown>
                            </Navbar.Item>

                            <Navbar.Item hoverable={true} active={false} href="#" className="mt-2 mr-2">
                                <Navbar.Link arrowless={true}>•••</Navbar.Link>
                                <Navbar.Dropdown right={true} boxed={false}>
                                    <Navbar.Item href="#">Subitem 1</Navbar.Item>
                                    <Navbar.Item href="#">Subitem 2</Navbar.Item>
                                    <Navbar.Divider />
                                    <Navbar.Item href="#">After divider</Navbar.Item>
                                </Navbar.Dropdown>
                            </Navbar.Item>
                        </Navbar.Container>


                    </Navbar.Container>
                </Navbar.Menu>
            </Navbar>

        )



    }

    let sideMenu = () => {

        return (
            <div className="">
                <Box className="sideMainMenu backblur z-39 animate-left-to-right mx-0">

                    <div className="pl-1 pt-1 ">
                        <Box className=" p-3 ml-minus-20 is-radiusless is-shadowless stick-to-top-minus20  has-background-transparent">
                            <div className="mt-1">
                                {logoImage()}
                                <Button className="has-background-transparent is-pulled-right p-1 is-borderless opacity35 " size="small" onClick={toggleSideMainMenu}>
                                    <Image className="is-borderless" src={menuClose} size={24} />
                                </Button>
                            </div>

                        </Box>


                        <Menu className="mr-4 pr-2 silent-active-color" textWeight="medium"  >
                            <Menu.List title="General"  >
                                <Menu.List.Item> Collections </Menu.List.Item>
                                <Menu.List.Item> Tests </Menu.List.Item>
                                <Menu.List.Item> Polls </Menu.List.Item>
                                <Menu.List.Item> Finalised </Menu.List.Item>
                            </Menu.List>

                            <Menu.List title="Teams" >
                                <Menu.List.Item active={true}> People </Menu.List.Item>
                                <Menu.List.Item> Positions </Menu.List.Item>
                                <Menu.List.Item> Groups </Menu.List.Item>
                            </Menu.List>

                            <Menu.List title="Account" >
                                <Menu.List.Item> Profile </Menu.List.Item>
                                <Menu.List.Item> Billing </Menu.List.Item>
                            </Menu.List>
                        </Menu>
                        <br />
                        <br />

                        {/* <Box className=" stick-to-bottom  backblur">
                            {logoImage()}
                        </Box> */}
                    </div>
                    <br />

                    <Footer className="has-background-transparent mt-0 p-2">

                        <Button.Group align="center">
                            <Button className="has-background-white-bis is-borderless  is-hover-dark " size="small">
                                <Image className=" opacity35 " src={howtoImage} alt="How To?" size={16} />
                                {/* <span className="is-size-7 is-family-monospace has-text-centered  ml-2 has-text-grey-light">HOW TO</span> */}
                            </Button>

                            <Button className="has-background-white-bis is-borderless  is-hover-dark  " size="small">
                                <Image className=" opacity35 " src={videoImage} alt="Video Guides" size={16} />
                                {/* <span className="is-size-7 is-family-monospace has-text-centered  ml-2 has-text-grey-light">VIDEOS</span> */}
                            </Button>
                            <Button className="has-background-white-bis is-borderless is-hover-dark  " size="small">
                                <Image className=" opacity35 " src={chatImage} alt="Help Chat" size={16} />
                                {/* <span className="is-size-7 is-family-monospace has-text-centered  ml-2 has-text-grey-light">HELP CHAT</span> */}
                            </Button>

                        </Button.Group>
                        <Box className="is-shadowless has-background-transparent m-0 p-0 has-text-centered">
                            {logoImage(true, "is-centered ")}
                        </Box>
                        <p className="is-size-7 is-family-monospace has-text-centered  ml-2 has-text-grey-light" href="https://tespost.in">testpost.app</p>
                        <p className="is-size-7 is-family-monospace has-text-centered  ml-2 has-text-grey-light" href="https://tespost.in">info@testpost.app</p>
                    </Footer>



                </Box>


            </div >
        )
    }


    let columnLayout = (children) => {
        return (
            <div>
                <Columns className="is-narrow-mobile py-0 my-0 is-gapless px-0 mx-0">
                    <Columns.Column size={showSideMenu ? 2 : 1} className="mx-0 px-0">
                        {
                            showSideMenu ?
                                sideMenu()
                                :
                                null
                        }
                    </Columns.Column>
                    <Columns.Column className="py-0 my-0">
                        {children}
                    </Columns.Column>
                    {/* <Columns.Column size={1}>
                    </Columns.Column> */}
                </Columns>

            </div>

        )
    }

    let mobileLayout = (children) => {
        return (
            <div className="is-hidden-desktop">
                {children}
            </div>
        )
    }


    const logoutUser = () => {
        setIsLoggingOut(true)
        logoutNow((succ, errMsg) => {
            setIsLoggingOut(false)
            if (succ) {
                props.userLoggedOut()
            } else {
                // COGO- TOAST
                alert(errMsg)
                console.log(errMsg)
            }
        })
    }




    let topBoxLevel = () => {

        let boxClasses = " z-30 stick-to-top is-fullwidth px-2 pt-1 pb-2 is-clearfix  is-radiusless  is-shadowless mt-0 mb-0"

        if (props.isBackBlur) {
            // boxClasses += " backblur-bis"
            boxClasses += " backblur-white"
        }


        let showBilling = false
        let showInvitation = false
        if (props.wkValidated === true) {
            showBilling = canEditWorkspace()
            showInvitation = canInviteMembers()
        }


        return (
            <Box className={showSideMenu ? `${boxClasses} is-shadowless ` : boxClasses} >
                <Level className="is-mobile mt-0">

                    <Level.Side align="left">
                        {
                            hasSideMenu ?
                                <Level.Item className=" mr-0">
                                    <Button className="has-background-transparent is-borderless opacity35 mr-0 " size="small" onClick={toggleSideMainMenu}>
                                        <Image className="" renderAs="figure" src={menuOpen} size={24} />
                                    </Button>
                                </Level.Item>
                                : null
                        }


                        {showSideMenu ? null :
                            <div>
                                <Level.Item className=" ">
                                    {logoImage(false, " ")}
                                    {
                                        props.workspace !== null ?
                                            <span className=" ml-2 mt-2px ">
                                                {
                                                    props.workspace.name ?
                                                        <Heading textWeight='medium' textColor='grey' size={5} >·&nbsp;{props.workspace.name}</Heading>
                                                        : null
                                                }
                                            </span>
                                            : null
                                    }

                                </Level.Item>

                            </div>


                        }

                    </Level.Side>
                    <Level.Side align="right">



                        {props.workspace !== null ?
                            <Level.Item className="" >
                                <Button className="has-background-transparent is-borderless  has-text-centered  p-2 is-hover-dark " onClick={toggleUpload}>


                                    <Level mobile={true}>
                                        <Level.Item className='m-0 p-0'>
                                            <img className={` ${uploads.files.length > 0 ? "hover-pulse-slow-small-infinite " : ""} is-centered opacity75 `} src={uploadCircleImage} alt="Search" width={21} />

                                        </Level.Item>
                                        {(uploads.files.length > 0) ?
                                            <Level.Item className='m-0 p-0'>
                                                <Tag size={"small"} textWeight="bold" className=" is-size-8-imp scaleDown-p96   " color={"black"} rounded > {uploads.files.length} </Tag>
                                            </Level.Item>
                                            : null
                                        }
                                    </Level>

                                </Button>
                            </Level.Item>
                            : null}



                        {/* {props.workspace !== null ?
                            <Level.Item className="" >
                                <Button className="has-background-transparent is-borderless  has-text-centered  p-2 is-hover-dark pr-1" onClick={toggleSearch}>
                                    <img className=" is-centered   opacity75 " src={searchImage} alt="Search" width={21} />
                                </Button>
                            </Level.Item>
                            : null
                        } */}


                        <Level.Item className="" >
                            {/* <Button className="has-background-transparent  is-borderless has-text-centered  p-2 is-hover-dark">
                                <Image renderAs="figure" className=" is-centered    " src={notificationImage} alt="Notifications" size={24} />
                            </Button> */}

                            <Dropdown className=" is-borderless   is-hover-dark has-background-transparent px-0  " hoverable={false} right={true} icon={
                                // <img className="    has-background-transparent " src={notificationImage} alt="Notifications" width={21} />
                                <>
                                    {
                                        (Notification.unreadNotifsCount(props.notifs) > 0) ?
                                            <VscBellDot className="  is-size-4 mt-2px  has-background-transparent " width={21} />
                                            :
                                            <VscBell className="  is-size-4 mt-2px  has-background-transparent " width={21} />
                                    }
                                </>
                            } label=" "  >

                                <Box className="is-shadowless minW300 maxH400 overflow-scroll my-1 mx-2 p-1">

                                    <Box radiusless shadowless className='has-text-left  m-0 p-1'><Heading size={7} className="mb-2 ml-2 has-text-grey "> Notifications</Heading></Box>


                                    {
                                        props.notifs.length ?

                                            <Table className="is-fullwidth overflow-scroll has-background-transparent no-border py-4" hoverable={true} striped={false} bordered={false} fullwidth>
                                                <tbody>
                                                    {
                                                        props.notifs.map((n) => {
                                                            return <tr renderAs="a" key={n.id} className="is-borderless py-0 px-0" >
                                                                <td className=" is-borderless p-1" align="left" onClick={() => { props.markNotif(n) }}>
                                                                    <Message color={Notification.isReadByCurrentUser(n) ? "light" : "link"} className="m-0 py-2 px-3 is-size-7 is-bordered" >
                                                                        <Content className='mb-0 content is-clearfix'>
                                                                            {/* <p className=' has-text-weight-medium mb-0'>{n.message}</p> */}
                                                                            {n.message}
                                                                        </Content>
                                                                        <p className=' has-text-grey is-size-8 mt-1 has-text-weight-semibold'> <TimeAgo live={false} className="  " date={n.createdAt} />  </p>
                                                                    </Message>
                                                                    {/* <Box radiusless shadowless textSize={7} className="has-background-transparent m-0 px-0 py-1 is-bordered-bottom ">
                                                                        <p>{n.message}</p>
                                                                        <p className=' has-text-grey-light'> <TimeAgo live={false} className="  " date={n.createdAt} />  </p>
                                                                    </Box> */}
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>

                                            :
                                            <Box radiusless shadowless className='has-text-centered is-size-7'> <span className=' has-text-grey-light '> No Notifications</span> </Box>
                                    }
                                    {/* <Dropdown.Item renderAs="a" value="item">
                                        Notification 1
                                    </Dropdown.Item>
                                    <Dropdown.Item renderAs="a" value="other">
                                        Notification 2
                                    </Dropdown.Item> */}
                                </Box>
                                {/* <Dropdown.Divider /> */}
                                {/* <Dropdown.Item renderAs="a" value="divider" className=" has-text-centered has-text-dark"   >
                                    View All
                                </Dropdown.Item> */}
                            </Dropdown>



                        </Level.Item>

                        <Level.Item >

                            <Dropdown hoverable={false} right={true} icon={
                                // <Button className="has-background-transparent  is-borderless has-text-centered m-0 p-0 is-hover-dark ">
                                <img className="   " src={userImage} alt="User options" width={21} />
                                // </Button>
                            } label=" " className=" is-borderless   has-background-transparent mr-1 ml-minus-10  px-0 is-hover-dark" >

                                {/* <Button className="has-background-transparent  is-borderless has-text-centered  p-2 mr-2 is-hover-dark">
                                    <Image renderAs="figure" className="   " src={userImage} alt="User Image" size={24} />
                                </Button> */}




                                <Menu className=" p-4" textWeight="medium" textAlign="left">
                                    <Menu.List  >
                                        <Menu.List.Item textWeight="light" textColor="grey" >
                                            {isCurrentUser() ? isCurrentUser().get("username") : "none"}
                                        </Menu.List.Item>


                                        <Menu.List.Item onClick={toggleProfile}> Profile </Menu.List.Item>

                                        {/* {
                                            showBilling ?
                                                <Menu.List.Item onClick={toggleBilling}> Billing </Menu.List.Item>
                                                : null
                                        } */}

                                        {
                                            showInvitation ?
                                                <Menu.List.Item onClick={toggleInvitation}> Invite Members </Menu.List.Item>
                                                : null

                                        }


                                        {/* <Menu.List.Item> Polls </Menu.List.Item> */}
                                        {/* <Menu.List.Item> Finalised </Menu.List.Item> */}
                                    </Menu.List>
                                </Menu>


                                <Dropdown.Divider />
                                <Dropdown.Item value="divider" className=" has-text-centered has-text-danger-dark"   >
                                    <Button size="small" renderAs="a" loading={isLoggingOut} disabled={isLoggingOut} className=" is-centered has-text-centered  " fullwidth={false} rounded textColor="danger" color="light" onClick={logoutUser}>
                                        Logout
                                    </Button>
                                </Dropdown.Item>
                            </Dropdown>

                        </Level.Item>
                    </Level.Side>
                </Level>
                {/* <hr className="hardHr width110Perc pl-0 ml-0"/> */}
            </Box >

        )
    }


    const emailVerifiedChecked = () => {
        // if (isCurrentUserEmailVerified()) {
        setEmailVerifyNeeded(false)
        // }
    }

    const verifyEmailModal = () => {
        return <VerifyEmailModal emailVerifiedChecked={emailVerifiedChecked} logoutUser={logoutUser} />
    }

    const newAssetIsAdded = () => {
        //Find open projects 

        //check if this asset matches to that project 

        //give this asset to it
    }

    const uploaderModal = () => {

        // Get Project from UR
        return <Uploader close={closeAllModals} assetAdded={newAssetIsAdded} />
    }


    const profileModal = () => {
        return <ProfileModal close={closeAllModals} />
    }


    return (
        <div>
            {/* {emailVerifyNeeded ? verifyEmailModal() : null} */}
            {showSearch ? searchModal() : null}
            {showProfile ? profileModal() : null}
            {showBilling ? billingModal() : null}
            {showUploader ? uploaderModal() : null}
            {showInvitations ? <InvitationModal close={toggleInvitation} isInviteReminder={isInvitationReminder} showInviteReminder={getShowInvitationReminderToAdmin()} changeInviteReminder={setShowInvitationReminderToAdmin} /> : null}
            {topBoxLevel()}
            {
                showSideMenu ?
                    columnLayout(props.children)
                    :
                    props.children
            }
        </div>
    )

}

export default MainMenuAux