import randomColor from 'randomcolor';
import React, { useState } from 'react'
import { Card, Heading, Media, Content, Tile, Box, Image, Tag, Button, Dropdown, Columns } from 'react-bulma-components';
import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
import { AvatarStackFor, NotificationFor, ProjectStatus, SelectableEntity, userPointer } from '../../Models/EnumsAndPointers';
import { Project } from '../../Models/Project';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { randomAlphabets, removeKeysFromUserInfo_Obj, getRandomColor, isPrivate, privateTag } from '../../utilities/Utilities';
import { HiOutlineDotsHorizontal, HiDotsVertical } from 'react-icons/hi';
import AvatarStack from '../Calendar/AvatarStack';
import UserAvatar from '../Calendar/UserAvatar';
import { RiContactsBookLine } from 'react-icons/ri';
import { Notification } from '../../Models/Notification';

const ProjectCard = (props) => {


    const [saving, setSaving] = useState(false)



    const dropdownChanged = (val) => {

        let proj = Project.copyFrom(props.project)
        let notifText = ""
        let cuInfo = currentUserInfo()
        let notifUserIds = proj.members
        let notifFor = NotificationFor.project_changedDetails

        console.log("CU INFO")
        console.log(cuInfo)

        if (val === "finalise") {
            // finalise tihs project
            proj.status = ProjectStatus.finalised
            proj.isArchived = false
            notifText = `${cuInfo.name} finalised the Project: ${proj.name} `
            notifFor = NotificationFor.project_finalised
        }

        if (val === "pending") {
            // finalise tihs project
            proj.status = ProjectStatus.pending
            proj.isArchived = false
            notifText = `${cuInfo.name} updated the status of project to Pending for Project: ${proj.name} `
            notifFor = NotificationFor.project_changedStatustPending
        }

        if (val === "archive") {
            // finalise tihs project
            proj.status = ProjectStatus.archived
            proj.isArchived = true
            let cu = isCurrentUser()
            proj.archivedBy = userPointer(cu.id)
            proj.archivedByInfo = removeKeysFromUserInfo_Obj(cuInfo, true, false, true, true)
            notifText = `${cuInfo.name} archived the Project: ${proj.name} `
            notifFor = NotificationFor.project_archived

        }

        setSaving(true)
        proj.update((succ, result, errMsg) => {
            if (saving !== null) {
                setSaving(false)
                if (succ) {
                    Notification.addNotification([], `Project:${result.name}`, notifText, notifUserIds, null,null, null, null, [result.id],null, null, null, null, "", null, `Project=${result.id}`,null, notifFor )
                    if (props.projectChanged) {
                        props.projectChanged(result)
                    }
                    
                } else {
                    console.log(errMsg);
                    alert("An error occured. Could not update project")
                }
            }
        })

    }


    const dropDownOpts = () => {
        let comps = []
        if (props.project.status === ProjectStatus.pending) {
            comps.push(<Dropdown.Item key={randomAlphabets(5)} renderAs="a" value="finalise">Finalise</Dropdown.Item>)
            comps.push(<Dropdown.Divider key={randomAlphabets(5)} />)
            comps.push(<Dropdown.Item key={randomAlphabets(5)} renderAs="a" value="archive">Archive</Dropdown.Item>)
        }

        if (props.project.status === ProjectStatus.finalised) {
            comps.push(<Dropdown.Item key={randomAlphabets(5)} renderAs="a" value="pending">Set Pending</Dropdown.Item>)
            comps.push(<Dropdown.Divider key={randomAlphabets(5)} />)
            comps.push(<Dropdown.Item key={randomAlphabets(5)} renderAs="a" value="archive">Archive</Dropdown.Item>)
        }

        if (props.project.status === ProjectStatus.archived) {
            comps.push(<Dropdown.Item key={randomAlphabets(5)} renderAs="a" value="pending">Set Pending</Dropdown.Item>)
            comps.push(<Dropdown.Divider key={randomAlphabets(5)} />)
            comps.push(<Dropdown.Item key={randomAlphabets(5)} renderAs="a" value="finalise">Set Finalised</Dropdown.Item>)
        }

        return comps
    }

    // return (
    //     <Card className="minH300 maxH300  " radiusless >

    //         <Card.Image
    //             onClick={() => { props.onClick(props.project) }}
    //             src="https://images.unsplash.com/photo-1620121478247-ec786b9be2fa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80"
    //             className="maxH140 object-fit-cover overflow-hidden is-clickable"
    //             radiusless={true}
    //         />

    //         {
    //             saving ?
    //                 <Button loading={saving} disabled={saving} className="is-pulled-right is-borderless mx-4 has-background-transparent "  textColor="grey"></Button>
    //                 :
    //                 <Dropdown right textColor="light" renderAs="button" className=" is-size-7 is-pulled-right  is-borderless is-arrowless has-background-transparent  has-text-grey-light is-borderless" label='•••' onChange={(val) => { dropdownChanged(val) }} >
    //                     {
    //                         dropDownOpts()
    //                     }
    //                 </Dropdown>

    //         }

    //         <Card.Content onClick={() => { props.onClick(props.project) }} className="is-clickable">
    //             {/* <Media> */}
    //             {/* <Media.Item renderAs="figure" align="left">
    //                     <Image
    //                         size={64}
    //                         alt="64x64"
    //                         src="http://bulma.io/images/placeholders/128x128.png"
    //                     />
    //                 </Media.Item> */}
    //             {/* <Media.Item> */}
    //             <Heading size={6} className="mb-4 text">{props.project.name}</Heading>

    //             {/* </Media.Item> */}
    //             {/* </Media> */}
    //             <Content className="mt-1 mb-0" textSize={7} textColor="grey">
    //                 {props.project.description ?? ""}
    //             </Content>
    //             <p className="m-0 mt-3">
    //                 <Tag color="info" className="is-light mr-2 is-capitalized">{props.project.status}</Tag>
    //                 {/* <Tag color="success" className="is-light mr-2">Approved</Tag>
    //                 <Tag color="danger" className="is-light mr-1">Rejected</Tag> */}
    //             </p>
    //         </Card.Content>
    //     </Card>







    const card = () => {



        return <Card style={{ width: 300, margin: 'auto' }} className="has-background-transparent  ">
            {/* <Card.Image
                onClick={() => { props.onClick(props.project) }}
                size="16by9"
                src="https://images.unsplash.com/photo-1512998844734-cd2cca565822?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=721&q=80"
            /> */}
            <div className=" is-fullwidth  p-10px" style={{ backgroundColor: props.project.coverImageColor }}></div>
            {/* <div className=" is-fullwidth  p-10px" style={{ backgroundColor: props.project.coverImageColor ??  getRandomColor() }}></div> */}

            <Card.Content className='px-0 pt-0 pb-2 has-background-transparent '>

                {
                    saving ?
                        <Button loading={saving} disabled={saving} className="is-pulled-right is-borderless mx-4 has-background-transparent " textColor="grey"></Button>
                        :
                        <Dropdown right renderAs="button" className=" is-size-7 is-pulled-right has-text-left  is-borderless is-arrowless has-background-transparent  has-text-grey-light is-borderless p-0" label={
                            <HiDotsVertical className="is-size-6 mt-2 mx-0 p-0 has-text-grey-light m-0" />
                        } onChange={(val) => { dropdownChanged(val) }} >
                            {
                                dropDownOpts()
                            }
                        </Dropdown>

                }
                <Box radiusless shadowless className=' m-0  has-background-light' onClick={() => { props.onClick(props.project) }}>
                    <Heading size={5} textColor="grey-dark" className="">{props.project.name}</Heading>
                    <Heading subtitle size={7} textColor="grey-light" textWeight="semibold" className=" mb-0">{props.project.description} &nbsp;</Heading>
                </Box>
                <Box shadowless radiusless onClick={() => { props.onClick(props.project) }} className="is-clickable has-background-transparent backblurWithSiteBg p-4 m-0">
                    {/* <Media> */}
                    {/* <Media.Item renderAs="figure" align="left">
                    <Image
                        size={64}
                        alt="64x64"
                        src="http://bulma.io/images/placeholders/128x128.png"
                    />
                </Media.Item> */}
                    {/* <Media.Item> */}





                    {/* </Media.Item> */}
                    {/* </Media> */}
                    {/* <Content className="mt-1 mb-0" textSize={7} textColor="grey">
                {props.project.description ?? ""}
            </Content> */}

                    <Box shadowless className="p-0 py-2 m-0 mt-2 has-background-transparent is-bordered-bottom">
                        {/* Member Avatars */}
                        {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={props.project.byUserInfo} isAddable={false} clearBg={true} noButton={true} /> */}
                        <Columns className="is-mobile">
                            <Columns.Column className="">
                                <AvatarStack className=" ml-2 " justAvatars={true} for={AvatarStackFor.projectMembers} small={true} cannotAdd={true} entity={props.project} project={props.project} users={props.project.membersInfo} />
                            </Columns.Column>
                            <Columns.Column >
                                <p className=" has-text-grey-light is-size-7 mt-1 has-text-right ">By {props.project.byUserInfo.name}</p>
                            </Columns.Column>
                        </Columns>
                    </Box>


                    <Columns gap={0} className='m-0 p-0 mt-minus-10' vCentered>
                        <Columns.Column className='m-0 p-0' narrow>
                            {
                                isPrivate(SelectableEntity.projects, props.project) ?
                                    privateTag()
                                    :
                                    null
                            }
                        </Columns.Column>
                        <Columns.Column className='m-0 p-0'>
                            <p className="m-0 mt-0 mt-minus-10">
                                {/* <span className="has-text-grey is-capitalised opcaity50 is-size-8 has-text-weight-medium">{props.project.status}</span> */}
                                {
                                    props.project.status === ProjectStatus.finalised ?
                                        <Tag color="success" rounded className="is-light  has-background-transparent ">{props.project.status}</Tag>
                                        :
                                        <Tag color="light" rounded className="is-light  has-background-transparent ">{props.project.status}</Tag>

                                }

                                {/* <Tag color="success" className="is-light mr-2">Approved</Tag>
                <Tag color="danger" className="is-light mr-1">Rejected</Tag> */}
                            </p>
                        </Columns.Column>
                    </Columns>


                </Box>
            </Card.Content>
        </Card>

    }


    return card()
}

export default ProjectCard