import React, { useEffect, useState } from 'react'
import { Form, Tabs, Box, Button, Panel, Columns, Table, Tag, Heading, Image } from 'react-bulma-components';
import { Task } from '../../Models/Task'
import { IoMdClose } from 'react-icons/io';

import { TaskType, TaskStatus, TaskSortOptions, TaskPriority, SelectableEntity } from '../../Models/EnumsAndPointers';
import Selector from '../Common/Selector';

import UserAvatar from '../Calendar/UserAvatar';
// import TaskModal from './TaskModal';

import { BiTimeFive, BiLoaderCircle } from 'react-icons/bi';
import { RiFileUserFill } from 'react-icons/ri';
import { FaCheckCircle } from 'react-icons/fa';
import { GrFormAdd } from 'react-icons/gr';


import { BsCircleFill, BsCheckCircleFill } from 'react-icons/bs'
import { IoTimeSharp, IoAddSharp, IoCheckmarkDoneOutline } from 'react-icons/io5';
import { BsFileEarmarkPlus, BsImageAlt } from 'react-icons/bs';

import { CgImage, CgRadioCheck, CgCheckO } from 'react-icons/cg';
import moment from 'moment'


import { MdDone } from 'react-icons/md';
import { GiSandsOfTime } from 'react-icons/gi';
import LoadingAux from '../../Containers/LoadingAux';
import TaskModal from '../Tasks/TaskModal';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { Asset } from '../../Models/Asset';
import { isPrivate, privateTag } from '../../utilities/Utilities';

const AssetTasks = (props) => {

    const [fetching, setFetching] = useState(false)
    const [tasks, setTasks] = useState([])

    const [addTask, setAddTask] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)



    useEffect(() => {
        let isMounted = true;
        setFetching(true)
        Task.getAllTasks(null, null, props.assetId, null, null, null, null, null, (succ, tsks, errMsg) => {
            if (isMounted) {
                setFetching(false)
            }
            if (succ) {
                console.log("GOT TASKS FOR THIS ASSET")
                if (isMounted) {
                    setTasks(tsks)
                } else {
                    console.log("NOT MOUNTED ANYMORE")
                }
                return () => (isMounted = false)
            } else {
                console.log("ERROR WHILE GETTING TASKS FOR THIS ASSET")
                console.log(errMsg)
            }

        })

        return () => (isMounted = false)

    }, [props.assetId])

    const taskSelected = (tsk) => {
        setSelectedTask(tsk)
    }


    const taskIsAdded = (tk) => {
        let tsks = [...tasks]
        tsks.unshift(tk)
        setTasks(tsks)
    }

    const taskIsUpdated = (tk) => {
        let tsks = [...tasks]
        let index = tsks.findIndex((t) => {
            return t.id === tk.id
        })
        if (index >= 0) {
            tsks[index] = tk
            setTasks(tsks)
        }
    }


    const taskStatusChanged = (tk, status) => {
        let tsks = [...tasks]
        let index = tsks.findIndex((t) => {
            return t.id === tk.id
        })
        if (index >= 0) {
            let thisTask = tsks[index]
            thisTask.isUpdating = true
            // update task in backend
            tsks[index] = thisTask
            setTasks((oldVal) => {
                return tsks
            })
            // updateTaskInDB(thisTask, status)
        }
    }

    const showAddTaskNow = () => {


        // create task 
        let tk = new Task()
        tk.byUser = isCurrentUser().id
        tk.byUserInfo = currentUserInfo()
        tk.createdAt = new Date()

        // add this asset thumbnail to it
        const thisAsset = Asset.copyFrom(props.asset)
        // console.log("Incoming Asset is")
        // console.log(props.asset)
        const thisAssetThumbnail = thisAsset.thumbnailObject(props.asset.project.id)
        tk.assets = [thisAssetThumbnail]
        tk.assetsIds = [thisAssetThumbnail.id]

        console.log("SETTING NEW TASK AS")
        console.log(tk)

        // show task in modal
        setAddTask(tk)



        // setAddTask(true)
    }

    const closeTask = () => {
        setSelectedTask(null)
        setAddTask(null)
    }



    const task_row = (thisTask) => {

        let tk = Task.copyFrom(thisTask)
        // let imageUrl = null

        // let assignedToName = ""

        // if (tk.thumbImageUrls.length > 0) {
        //     imageUrl = tk.thumbImageUrls[0]
        // }


        // if (tk.assignedToInfo.length) {
        //     assignedToName = tk.assignedToInfo[0].name
        //     if (tk.assignedToInfo.length > 1) {
        //         assignedToName += ` + ${tk.assignedToInfo.length - 1} others`
        //     }
        // }


        // return <Box shadowless radiusless className={" is-bordered-bottom-1  p-4 is-hover-dark mb-0  " + (tk.isComplete ? "opacity35" : "")}>
        return <Box shadowless radiusless className={"  p-4 px-0 mx-0 is-hover-dark mb-0 has-background-transparent backblurWithSiteBg is-size-7 " + (tk.isComplete ? "opacity35" : "")}>

            <Columns className="is-mobile mx-0 px-0 pb-2" onClick={() => { taskSelected(thisTask) }}>


                <Columns.Column className='px-0 mx-0' >
                    <p className='mb-1'>
                        {
                            tk.type === TaskType.create ?
                                <Tag rounded size={"small"} className="is-size-9 has-text-grey mb-2 mr-2"> Create</Tag>
                                :
                                null
                        }
                        {tk.priority === TaskPriority.urgent ? <span className='mr-3 has-text-danger mr-2 '> <BsCircleFill /></span> : null}
                        {tk.priority === TaskPriority.high ? <span className='mr-3 has-text-warning mr-2 '> <BsCircleFill /></span> : null}
                        <span className=" mr-4 has-text-grey-light ">  {isPrivate(SelectableEntity.tasks, tk) ? privateTag()  : null}  Added by {tk.byUserInfo.name}</span>

                    </p>

                    <span className="is-size-7 text-lines-3 "> {tk.title}  </span>
                    {/* <div className="has-text-grey-light is-size-7 ">Assigned: <span className="has-text-weight-medium ">Candida Viegas</span>  </div> */}

                    <div className="has-text-grey-light is-size-7 has-text-weight-medium ">
                        {/* <span className=" mr-4 "><FaRegUser className="pt-2 is-size-5p4" /> {tk.byUserInfo.name}</span> */}


                        {/* {
                            assignedToName !== "" ?
                                <span className=" mr-4 "><RiFileUserFill className="pt-2 is-size-5p4 mb-minus-009rem" /> {assignedToName}</span>
                                : null
                        }
                        <span className=" mr-4"><IoTimeSharp className="pt-15 is-size-5p2 mb-minus-009rem" />{moment(tk.createdAt).format('DD·MMM·YYYY')}</span> */}

                        {
                            tk.status === TaskStatus.complete ?

                                <span > <span className=" has-text-black "><IoCheckmarkDoneOutline /></span>  Completed By {tk.completedByInfo.name} </span>
                                : null
                        }


                    </div>


                    {/* <span className="is-size-6p4 has-text-grey " >  <Avatar render small imageUrl="https://randomuser.me/api/portraits/women/93.jpg" />  </span> */}
                    {/* <div className="" >
                                <span className="is-size-7  has-text-grey " > <UserAvatar infoObj={{ id: "25872573", name: "Candida Viegas", imageUrl: "https://randomuser.me/api/portraits/women/93.jpg" }} small={true} clearBg={true} /> </span>
                                <span className="is-size-7  has-text-grey mt-minus-10">Deadline</span>
                            </div> */}
                </Columns.Column>
                <Columns.Column className="py-0 px-0 mx-0" narrow>
                    <div className=" m-0 px-0 pb-0  has-text-grey">
                        {/* <div>
                                <Button size="small" className=" p-1 mt-1 is-hover-dark is-borderless has-background-transparent ">
                                    <RiMoreFill className="is-size-5  " />
                                </Button>
                            </div>       */}
                        {
                            tk.status === TaskStatus.complete ?
                                <div>
                                    <Button rounded loading={tk.isUpdating} disabled={tk.isUpdating} size="medium" className={` mt-3  is-borderless ${tk.isUpdating ? " has-background-white " : "has-background-transparent  "}`} onClick={() => { taskStatusChanged(tk, TaskStatus.pending) }}>
                                        <FaCheckCircle className="is-size-4 " />
                                    </Button>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Complete</p>
                                </div>
                                :
                                null
                        }

                        {
                            tk.status === TaskStatus.working ?
                                <div>
                                    <Button rounded loading={tk.isUpdating} disabled={tk.isUpdating} size="medium" className={` mt-3  is-borderless ${tk.isUpdating ? " has-background-white " : "has-background-transparent"}`} onClick={() => { taskStatusChanged(tk, TaskStatus.complete) }}>
                                        <BiLoaderCircle className="is-size-4 " />
                                    </Button>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Working</p>
                                </div>
                                :
                                null
                        }

                        {
                            tk.status === TaskStatus.pending ?
                                <div>
                                    <Button rounded loading={tk.isUpdating} disabled={tk.isUpdating} size="medium" className={` mt-3  is-borderless ${tk.isUpdating ? " has-background-white " : "has-background-transparent"}`} onClick={() => { taskStatusChanged(tk, TaskStatus.working) }} >
                                        <CgRadioCheck className="is-size-4 " />
                                    </Button>
                                    <p className="has-text-centered has-text-grey is-size-8 mt-minus-10 pt-0 disappearMessage ">Pending</p>
                                </div>

                                :
                                null
                        }

                    </div>
                </Columns.Column>
            </Columns>

        </Box>
    }


    let allAssetTasks = () => {
        let pendingTasks = tasks.filter((tk) => tk.status === TaskStatus.pending) ?? []
        let workingTasks = tasks.filter((tk) => tk.status === TaskStatus.working) ?? []
        let completeTasks = tasks.filter((tk) => tk.status === TaskStatus.complete) ?? []

        // console.log("PENDING TASKS ARE")
        // console.log(pendingTasks)

        return <div className=' is-clearfix is-fullwidth '>
            {addTask ? <TaskModal isNew={true} task={addTask} close={closeTask} taskIsAdded={taskIsAdded} /> : null}
            {selectedTask ? <TaskModal task={selectedTask} close={closeTask} taskIsUpdated={taskIsUpdated} /> : null}
            <Button size="small" rounded className="p-2 m-2 mr-3 mt-3 is-borderless has-text-grey" onClick={showAddTaskNow} >
                <GrFormAdd className=" has-text-warning m-0 is-size-5" />
            </Button>
            <LoadingAux className=" is-fullHeight is-clearfix  is-fullwidth m-0 p-0 mt-2 ml-2 " isLoading={fetching} isMain={true} isCentered={true} loaderHeight={600}>
                <Box shadowless className=" h-90vh is-fullwidth overflow-scroll has-background-transparent p-0 pb-6 pt-0 ">
                    <div className='overflow-scroll  is-size-7'>

                        {tasks.length === 0 ?
                            <Box shadowless radiusless className="is-fullwidth has-text-grey-light has-background-transparent is-size-7 has-text-left p-4 m-2">
                                No Tasks
                            </Box>
                            :

                            <div className=" is-fullwidth overflow-scroll ">
                                {pendingTasks.length ? <div>
                                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey py-2 mt-2 mb-0  mt-3 is-uppercase" >Pending</Box>
                                    <Table className="is-fullwidth overflow-scroll has-background-transparent no-border" hoverable={false} striped={false} bordered={false} fullwidth>
                                        <tbody>
                                            {
                                                pendingTasks.map((tk) => {
                                                    return <tr key={tk.id}>
                                                        <td className="is-borderless" align="left">
                                                            {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                            {task_row(tk)}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div> : null}

                                {workingTasks.length ? <div>
                                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey py-2 my-0 mt-3 is-uppercase" >Working</Box>
                                    <Table className=" is-fullwidth overflow-scroll has-background-transparent" hoverable={false} striped={false} bordered={false} fullwidth>
                                        <tbody>
                                            {
                                                workingTasks.map((tk) => {
                                                    return <tr key={tk.id}>
                                                        <td className="is-borderless" align="left">
                                                            {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                            {task_row(tk)}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div> : null}

                                {completeTasks.length ? <div>
                                    <Box shadowless radiusless className="has-background-transparent is-size-7 has-text-weight-light has-text-grey py-2 my-0  mt-3 is-uppercase" >Complete</Box>
                                    <Table className=" is-fullwidth overflow-scroll has-background-transparent" hoverable={false} striped={false} bordered={false} fullwidth>
                                        <tbody>
                                            {
                                                completeTasks.map((tk) => {
                                                    return <tr key={tk.id}>
                                                        <td className="is-borderless" align="left">
                                                            {/* <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={false} clearBg={true} noButton={true} /> */}
                                                            {task_row(tk)}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div> : null}
                            </div>}
                    </div>
                </Box>
            </LoadingAux>
        </div >

    }


    return allAssetTasks()
}

export default AssetTasks