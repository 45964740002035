import Parse from 'parse'
import { isCurrentUser, currentUserInfo, getCurrentWorkspaceId, getOwnedWorkspacesIds, updateWKMemberInfo } from '../utilities/Login';
import { appMeta, SubscriptionStatus, userPointer, UserRole, workspacePointer } from './EnumsAndPointers';

const WorkspaceClass = Parse.Object.extend("Workspace");


export class Workspace {

    constructor(id, name, logoUrl, teamSize, country, countryCode, phone, email, website, purpose, members, membersInfo, invitedInfo, invitationAcceptedInfo, allMembersEver, allMembersEverInfo, subsStatus, subsSeats, subsEnds, subsPlanName, subsPlanId, byUser, byUserInfo, thisAppMeta) {
        this.id = id
        this.name = name
        this.logoUrl = logoUrl
        this.teamSize = teamSize
        this.country = country
        this.countryCode = countryCode
        this.phone = phone
        this.email = email
        this.website = website
        this.purpose = purpose
        this.byUser = byUser
        this.byUserInfo = byUserInfo
        this.members = members ?? []
        this.membersInfo = membersInfo ?? []
        this.invitedInfo = invitedInfo ?? []
        this.invitationAcceptedInfo = invitationAcceptedInfo ?? []
        this.allMembersEver = allMembersEver ?? []
        this.allMembersEverInfo = allMembersEverInfo ?? []
        this.subsStatus = subsStatus ?? SubscriptionStatus.trial
        this.subsSeats = subsSeats ?? 1
        this.subsEnds = subsEnds
        this.subsPlanName = subsPlanName
        this.subsPlanId = subsPlanId
        this.appMeta = thisAppMeta ?? appMeta
        this.isOwned = false // used to show on UI only
    }

    static copyFrom = (obj) => {
        let np = new Workspace()
        np.id = obj.id
        np.name = obj.name
        np.logoUrl = obj.logoUrl
        np.teamSize = obj.teamSize
        np.country = obj.country
        np.countryCode = obj.countryCode
        np.phone = obj.phone
        np.email = obj.email
        np.website = obj.website
        np.purpose = obj.purpose
        np.byUser = obj.byUser
        np.byUserInfo = obj.byUserInfo
        np.members = obj.members
        np.membersInfo = obj.membersInfo
        np.invitedInfo = obj.invitedInfo
        np.invitationAcceptedInfo = obj.invitationAcceptedInfo
        np.allMembersEver = obj.allMembersEver
        np.allMembersEverInfo = obj.allMembersEverInfo
        np.subsStatus = obj.subsStatus
        np.subsSeats = obj.subsSeats
        np.subsEnds = obj.subsEnds
        np.subsPlanName = obj.subsPlanName
        np.subsPlanId = obj.subsPlanId
        np.appMeta = obj.appMeta
        np.isOwned = obj.isOwned
        return np
    }


    info = () => {
        return {
            id: this.id,
            name: this.name,
            logoUrl: this.logoUrl,
            byUserInfo: this.byUserInfo,
            country: this.country,
            countryCode: this.countryCode,
            phone: this.phone,
            email: this.email
        }
    }

    static initFromPFObject = (obj) => {

        let np = new Workspace()

        if (!obj.id) {
            return null
        }

        np.id = obj.id
        np.name = obj.get("name")
        np.logoUrl = obj.get("logoUrl") ?? ""
        // this.teamSize = teamSize // just for read only 

        np.country = obj.get("country")
        np.countryCode = obj.get("countryCode")
        np.phone = obj.get("phone")
        // np.email = obj.get("email") // not capturing currently
        np.purpose = obj.get("purpose")

        np.website = obj.get("website")
        np.phone = obj.get("phone")
        np.byUser = obj.get("byUser").id
        np.byUserInfo = obj.get("byUserInfo")

        np.members = obj.get("members") ?? []
        np.membersInfo = obj.get("membersInfo") ?? []

        np.invitedInfo = obj.get("invitedInfo") ?? []
        np.invitationAcceptedInfo = obj.get("invitationAcceptedInfo") ?? []
        np.allMembersEver = obj.get("allMembersEver") ?? []
        np.allMembersEverInfo = obj.get("allMembersEverInfo") ?? []
        np.subsStatus = obj.get("subsStatus") ?? SubscriptionStatus.trial
        np.subsSeats = obj.get("subsSeats") ?? 1
        np.subsEnds = obj.get("subsEnds")
        np.subsPlanName = obj.get("subsPlanName")
        np.subsPlanId = obj.get("subsPlanId")
        np.appMeta = obj.get("appMeta") ?? appMeta


        return np

    }




    add = (callback) => {
        var ob = new WorkspaceClass();

        ob.set("name", this.name);
        ob.set("country", this.country);
        ob.set("countryCode", this.countryCode);
        ob.set("phone", this.phone);
        ob.set("email", this.email);
        ob.set("website", this.website);
        ob.set("teamSize", this.teamSize); // required
        ob.set("purpose", this.purpose);
        ob.set("subsSeats", this.subsSeats); // required


        let cu = isCurrentUser()
        let cuInfo = currentUserInfo()
        cuInfo.role = UserRole.admin
        ob.set("byUser", userPointer(cu.id)); // required
        ob.set("byUserInfo", cuInfo)

        let mm = this.members
        let mmInfo = this.membersInfo
        if (mm.length === 0) {
            mm = [cu.id]
            mmInfo = [cuInfo]
        }

        ob.set("members", mm) // list of users associated with this project.. list of objects {userId, userName, position, permission}
        ob.set("allMembersEver", mm)

        ob.set("membersInfo", mmInfo)
        ob.set("allMembersEverInfo", mmInfo)


        ob.save()
            .then((wkspace) => {
                this.id = wkspace.id
                console.log('New workspace Created')


                // ADD WORKSPACE TO USER 
                let user = Parse.User.current();
                user.save().then(() => {

                    // this.ownedWorkspaceIds = ownedWorkspaceIds
                    // this.ownedWorkspaces = ownedWorkspaces

                    // this.memberWorkspaceIds = memberWorkspaceIds
                    // this.memberWorkspaces = memberWorkspaces

                    user.addUnique("ownedWorkspaceIds", wkspace.id);
                    user.addUnique("ownedWorkspaces", workspacePointer(wkspace.id));

                    // let ownedWorkspaces_Relation = user.relation("ownedWorkspaces")
                    // ownedWorkspaces_Relation.add(workspacePointer(wkspace.id))

                    user.addUnique("memberWorkspaceIds", wkspace.id);

                    // let memberWorkspaces_Relation = user.relation("memberWorkspaces")
                    // memberWorkspaces_Relation.add(workspacePointer(wkspace.id))

                    user.addUnique("memberWorkspaces", workspacePointer(wkspace.id));

                    user.save();
                }, (error) => {
                    console.log('Current user could not be saved with workspaceId: ' + error.message);

                })

                callback(true, this, '')
            }, (error) => {
                console.log('Failed to create new workspace, with error code: ' + error.message);
                callback(false, null, error.message)
            });
    }




    update = () => {
    }


    static addCurrentUserToWk = (workspaceId, role, callback) => {


        console.log("WORKSPACE ID IS = " + workspaceId);
        console.log("ROLE IS = " + role);


        if (workspaceId === null || role === null) {
            callback(false, null, "Empty values")
        }

        var thisUserInfo = currentUserInfo()
        thisUserInfo.role = role

        var query = new Parse.Query(WorkspaceClass);
        query.get(workspaceId)
            .then((ob) => {
                ob.addUnique("members", thisUserInfo.id)
                ob.addUnique("allMembersEver", thisUserInfo.id)
                ob.addUnique("membersInfo", thisUserInfo)
                ob.addUnique("allMembersEverInfo", thisUserInfo)
                ob.save()
                callback(true, this, '')
            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });
    }


    static getAllForCurrentUser = (callback) => {
        console.log('Searching For Workspaces ')
        var query = new Parse.Query(WorkspaceClass);

        let cu = isCurrentUser()

        query.equalTo("members", cu.id)
        // Apply sort
        query.descending('createdAt')
        query.find().then((objects) => {
            // console.log('All Projects =')
            // console.log(objects)
            // check if these workspaces are do not have any account suspension 
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Workspace.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }


                let owned = getOwnedWorkspacesIds()

                let objs = [...allObjects]

                objs.map(wk => {
                    if (owned.includes(wk.id)) {
                        wk.isOwned = true
                    }
                    return wk
                })



                callback(true, objs, '')
            } else {
                callback(true, [], 'No Workspaces found  ')
            }

        }, (error) => {
            callback(false, [], error.message)
        })


    }

    static getWS = (wkid, callback) => {
        var query = new Parse.Query(WorkspaceClass);
        query.get(wkid).then((ob) => {
            if (ob.id) {
                let thisConvertedObject = Workspace.initFromPFObject(ob)
                if (thisConvertedObject !== null) {
                    let thisId = getCurrentWorkspaceId()
                    if (wkid === thisId){
                        updateWKMemberInfo(thisConvertedObject.membersInfo)
                    }

                    callback(true, thisConvertedObject, '')
                } else {
                    callback(false, null, 'Workspace could not be parsed during validation.')
                }
            } else {
                callback(false, null, 'Workspace could not be validated as the result has no id.')
            }
        }, (error) => {
            callback(false, [], error.message)
        })
    }


    static getMembers = (callback) => {
        var query = new Parse.Query(WorkspaceClass);
        let thisId = getCurrentWorkspaceId()

        query.get(thisId)
            .then((ob) => {
                let membersInfo = ob.get("membersInfo") ?? []

                updateWKMemberInfo(membersInfo)

                let allMembersEverInfo = ob.get("allMembersEverInfo") ?? []
                let members = {
                    "membersInfo": membersInfo,
                    "allMembersEverInfo": allMembersEverInfo
                }

                callback(true, members, '')
            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });
    }


}