import { AnnotationType, AssetType } from "./EnumsAndPointers"



export class Annotation {
    // constructor(id, note, type, top, left, isHovered, colorHex) {
    //     this.id = id
    //     this.note = note
    //     this.type = type ?? AnnotationType.tag
    //     this.top = top
    //     this.left = left
    //     this.isHovered = isHovered
    //     this.color = colorHex
    // }


    //     addedOn: null, 
    //     id: "AQP07s"
    //     isHovered: false
    //     isHoveredFrom: null
    //     lastHovered: null
    //     note: undefined
    //     type: AnnotationType.highlight
    //     assetType: AssetType.text
    //     color: "#FFDA00",

    constructor(id, assetId, assetVersionNo, assetVersionId, addedOn, isHovered, isHoveredFrom, lastHovered, type, assetType, color, note, top, left, vidFrameTime, start, end, text, tag ) {
        this.id = id
        this.assetId = assetId
        this.assetVersionNo = assetVersionNo
        this.assetVersionId = assetVersionId
        this.addedOn = addedOn
        this.isHovered = isHovered
        this.isHoveredFrom = isHoveredFrom
        this.lastHovered = lastHovered
        this.type = type ?? AnnotationType.tag
        this.assetType = assetType ?? AssetType.image
        this.color = color
        this.note = note

        // For Image & Video
        this.top = top
        this.left = left
        this.vidFrameTime = vidFrameTime  // For Vid

        // For Text
        this.start = start
        this.end = end
        this.text = text
        this.tag = tag
    }




    static initFromPFObject = (obj) => {
        let np = new Annotation()

        if (!obj.id) {
            return null
        }

        np.id = obj.id
        np.addedOn = obj.addedOn

        if (obj.assetType){
            np.assetType = obj.assetType
        }
        if (obj.type){
            np.type = obj.type
        }

        if (np.assetId ){ np.assetId = obj.assetId }
        if (np.assetVersionId ){ np.assetVersionId = obj.assetVersionId }
        if (np.assetVersionNo ){ np.assetVersionNo = obj.assetVersionNo }


        np.isHovered = obj.isHovered
        np.isHoveredFrom = obj.isHoveredFrom
        np.lastHovered = obj.lastHovered
        np.color = obj.color ?? obj.colorHex

        np.note = obj.note
        
        if (np.assetType === AssetType.image){
            np.top = obj.top
            np.left = obj.left
        }

        if (np.assetType === AssetType.video){
            np.top = obj.top
            np.left = obj.left
            np.vidFrameTime = obj.vidFrameTime
        }

        if (np.assetType === AssetType.text){
            np.start = obj.start
            np.end = obj.end
            np.text = obj.text
            np.tag = obj.tag
        }

        return np
    } 

    // let imageAntt = {
    //     addedOn: Fri Jul 08 2022 00: 33: 08 GMT + 0530(IST)
    //     colorHex: "#a9adfc"
    //     id: "AQP07s"
    //     isHovered: false
    //     isHoveredFrom: null
    //     lastHovered: null
    //     type: "Tag" AnnotationType.tag


    //     left: 73.69020501138952
    //     note: undefined
    //     top: 27.127129974682102

    //     assetType: AssetType.image
    // }

    // let textAntt = {
    //     end: 330,
    //     start: 316,
    //     tag: HTML,
    //     text: "do not have to",

    //     addedOn: null, 
    //     id: "AQP07s"
    //     isHovered: false
    //     isHoveredFrom: null
    //     lastHovered: null
    //     note: undefined
    //     type: AnnotationType.highlight
    //     assetType: AssetType.text
    //     color: "#FFDA00",

    // }



}