import React, { useRef, useState } from 'react'
import { Form, Card, Heading, Media, Content, Tabs, Box, Image, Tag, Button, Dropdown, Modal, Panel, Columns, Hero, Container, Message, Level, Section, Navbar } from 'react-bulma-components';
import { previewBoxType, SocialMedia_PostType, AssetCaptionType, SocialMedia_Platform } from '../../Models/EnumsAndPointers';

import MainMenuAux from '../../Components/MainMenuAux'
import ColumnAux from '../../Containers/ColumnAux';
// import sample_Li_Company_Bg from './sampleAssets/li-companyPage-bg.jpeg'
import Default_BG from './sampleAssets/Default-BG.png'
import Default_DP from './sampleAssets/Default-DP.png'
import Default_Li_Blank_User from './sampleAssets/Default-Li-Blank-User.svg'
import { randomUsers, rowOfColumns_leftImage } from './VisualiseModal';

import billgates_dp from './sampleAssets/billgates_dp.jpeg'
import satyanadella_dp from './sampleAssets/satyanadella_dp.jpeg'
import reedhastings_dp from './sampleAssets/reedhastings_dp.jpeg'



import fbLike from './sampleAssets/fb-like.png'
import fbComment from './sampleAssets/fb-comment.png'
import fbShare from './sampleAssets/fb-share.png'
import fbLoved from './sampleAssets/fb-loved.svg'
import fbLiked from './sampleAssets/fb-liked.svg'
import fbDotMenu from './sampleAssets/fb-dotMenu.png'
import fb_showmore from './sampleAssets/fb_showmore.svg'
import fb_search from './sampleAssets/fb-search.svg'



import fb_adcentre_icon from './sampleAssets/fb_adcentre_icon.png'
import fb_adsmanager_icon from './sampleAssets/fb_adsmanager_icon.png'
import fb_birthday_icon from './sampleAssets/fb_birthday_icon.png'
import fb_businessmanager_icon from './sampleAssets/fb_businessmanager_icon.png'
import fb_events_icon from './sampleAssets/fb_events_icon.png'
import fb_favourites_icon from './sampleAssets/fb_favourites_icon.png'
import fb_friends_icon from './sampleAssets/fb_friends_icon.png'
import fb_groups_icon from './sampleAssets/fb_groups_icon.png'
import fb_livevideo_icon from './sampleAssets/fb_livevideo_icon.png'
import fb_marketplace_icon from './sampleAssets/fb_marketplace_icon.png'
import fb_memories_icon from './sampleAssets/fb_memories_icon.png'
import fb_mostrecent_icon from './sampleAssets/fb_mostrecent_icon.png'
import fb_page_icon from './sampleAssets/fb_page_icon.png'
import fb_recentadactivity_icon from './sampleAssets/fb_recentadactivity_icon.png'
import fb_saved_icon from './sampleAssets/fb_saved_icon.png'

import fb_nav_mobile_leftmenu from './sampleAssets/fb-nav-mobile-leftmenu.png'
import fb_nav_mobile_search from './sampleAssets/fb-nav-mobile-search.png'
import fb_nav_mobile_logo from './sampleAssets/fb-nav-mobile-logo.png'

import fb_photoVideo_icon from './sampleAssets/fb_photoVideo_icon.png'
import fb_friendTag_icon from './sampleAssets/fb_friendTag_icon.png'
import fb_feeling_icon from './sampleAssets/fb_feeling_icon.png'
import fb_education_icon from './sampleAssets/fb_education_icon.png'

import fb_info_icon from './sampleAssets/fb_info_icon.png'
import fb_tab_icon from './sampleAssets/fb_tab_icon.png'
import fb_website_icon from './sampleAssets/fb_website_icon.png'
import fb_followNumber_icon from './sampleAssets/fb_followNumber_icon.png'
import fb_like_icon from './sampleAssets/fb_like_icon.png'

import liGlobeIcon from './sampleAssets/li-globe.svg'


//Fb Nav Icons
import fb_friends_nav_icon from './sampleAssets/fb_friends_nav_icon.png'
import fb_gaming_icon from './sampleAssets/fb_gaming_icon.png'
import fb_groups_nav_icon from './sampleAssets/fb_groups_nav_icon.png'
import fb_home_icon from './sampleAssets/fb_home_icon.png'
import fb_home_nav_fill_icon from './sampleAssets/fb_home_nav_fill_icon.png'

import fb_logo_icon from './sampleAssets/fb_logo_icon.png'
import fb_marketplace_nav_icon from './sampleAssets/fb_marketplace_nav_icon.png'
import fb_menu_icon from './sampleAssets/fb_menu_icon.png'
import fb_messages_icon from './sampleAssets/fb_messages_icon.png'
import fb_more_icon from './sampleAssets/fb_more_icon.png'
import fb_notif_icon from './sampleAssets/fb_notif_icon.png'
import fb_search_icon from './sampleAssets/fb_search_icon.png'

import { AiFillPushpin } from 'react-icons/ai';
import { GrAdd } from 'react-icons/gr';
import { CgAdd } from 'react-icons/cg';
import { sampleImageUrl } from '../../utilities/Utilities';
import { FiTrendingUp } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';



export const fbNav = () => {
    return <Box shadowless radiusless className='m-0 p-0 is-bordered stick-to-top z-55' >
        <div onClick={(e) => { e.stopPropagation(); }}>
            <Columns className='m-0 p-0'>
                <Columns.Column className='m-0 p-0 px-2'>
                    <div className=" is-flex is-flex-direction-row is-mobile has-text-left py-1 " >
                        {/* <Level.Item className='m-0 p-0'> */}
                        <Image size={48} src={fb_logo_icon} alt="li_icon" className="is-clipped  ml-4 " />
                        {/* </Level.Item>
                            <Level.Item className='m-0 p-0'> */}
                        <Form.Field className=' has-text-left mt-1  ' >
                            <Form.Control className=' has-icons-left '>
                                <span class="icon  is-left">
                                    <FaSearch color='grey' className='  mr-1 ml-5 ' />
                                </span>
                                <Form.Input
                                    // color="success"
                                    size="normal"
                                    placeholder="Search Facebook"
                                    textColor='grey'
                                    rounded
                                    // color="danger"
                                    className=" has-background-grey-lightest  is-borderless ml-2  "
                                />
                            </Form.Control>
                        </Form.Field>
                        {/* </Level.Item> */}
                    </div>
                </Columns.Column>
                <Columns.Column className='m-0 p-0 px-4 mx-1' size={6} >
                    {/* <Columns className='m-0 p-0' vCentered>
                    <Columns.Column className='m-0 p-0' size={5}>
                    </Columns.Column>
                    <Columns.Column className='m-0 p-0' > */}

                    {/* <Tabs align="center" className="has-text-weight-semibold ml-4 is-size-6 ">
                    <Tabs.Tab active> <span className="pb-3">Home</span> </Tabs.Tab>
                    <Tabs.Tab> <span className="pb-3">Videos</span> </Tabs.Tab>
                    <Tabs.Tab> <span className="pb-3">Photos</span> </Tabs.Tab>
                    <Tabs.Tab> <span className="pb-3">Stores</span> </Tabs.Tab>
                    <Tabs.Tab> <span className="pb-3">More</span> </Tabs.Tab>
                </Tabs> */}

                    <Tabs fullwidth className=" px-4 mt-2 mb-0 mx-2  fbNavTab"  >
                        <Tabs.Tab active className=''><Image size={32} src={fb_home_nav_fill_icon} alt="fb_icon" className="is-clipped " />             </Tabs.Tab>
                        <Tabs.Tab className=''><Image size={32} src={fb_friends_nav_icon} alt="fb_icon" className="is-clipped " />      </Tabs.Tab>
                        <Tabs.Tab className=''><Image size={32} src={fb_marketplace_nav_icon} alt="fb_icon" className="is-clipped " />  </Tabs.Tab>
                        <Tabs.Tab className=''><Image size={32} src={fb_groups_nav_icon} alt="fb_icon" className="is-clipped  " />      </Tabs.Tab>
                        <Tabs.Tab className=''><Image size={32} src={fb_gaming_icon} alt="fb_icon" className="is-clipped " />           </Tabs.Tab>
                        {/* <Level.Item className='m-0 p-0 pr-2 is-flex-direction-column'><img src={Default_DP} rounded alt="insta_icon" className="is-clipped square-24 image-rounded is-bordered" /></Level.Item> */}
                    </Tabs>
                    {/* </Columns.Column>
                </Columns> */}
                </Columns.Column>
                <Columns.Column className='m-0 p-0 pl-6 ml-4 pr-2'>

                    <Level className="is-mobile has-text-centered m-0 mt-1 " >
                        <Level.Item className=''>
                            <img src={Default_DP} rounded alt="fb_icon" className="is-clipped square-32 image-rounded is-bordered" />
                            <span className=' is-size-7 has-text-weight-semibold ml-2'>JohnDoe</span>
                        </Level.Item>
                        <Level.Item className='m-0 p-0'>
                            <Button rounded size={"medium"} className='has-background-grey-lighter px-3 scaleDown-p81'>
                                <Image size={24} src={fb_menu_icon} alt="fb_icon" />
                            </Button>
                        </Level.Item>
                        <Level.Item className='m-0 p-0'>
                            <Button rounded size={"medium"} className='has-background-grey-lighter px-3 scaleDown-p81'>
                                <Image size={24} src={fb_messages_icon} alt="fb_icon" />
                            </Button>
                        </Level.Item>
                        <Level.Item className='m-0 p-0'>
                            <Button rounded size={"medium"} className='has-background-grey-lighter px-3 scaleDown-p81'>
                                <Image size={24} src={fb_notif_icon} alt="fb_icon" />
                            </Button>
                        </Level.Item>
                        <Level.Item className='m-0 p-0'>
                            <Button rounded size={"medium"} className='has-background-grey-lighter px-3 scaleDown-p81'>
                                <Image size={24} src={fb_more_icon} alt="fb_icon" />
                            </Button>
                        </Level.Item>
                    </Level>
                </Columns.Column>
            </Columns>
        </div>
    </Box>
}

export const fbNav_mobile = (dpImage) => {
    return <Box shadowless radiusless className='m-0 p-0 has-background-transparent stick-to-top z-55' >
        <div onClick={(e) => { e.stopPropagation(); }}>
            <Columns className='m-0 p-0'>
                <Columns.Column className='m-0 p-0' narrow>
                    <Level className='m-0 p-0 mt-4'>
                        <Level.Item> <Image size={24} src={fb_nav_mobile_leftmenu} alt="fb_icon" className="is-clipped mt-minus-4 " />  </Level.Item>
                        <Level.Item> <Image size={24} rounded src={dpImage ?? Default_DP} alt="fb_icon" className="is-clipped   " /> </Level.Item>
                        <Level.Item> <img src={fb_nav_mobile_logo} alt="fb_icon" className="is-clipped fbNavLogo-mobile ml-2   " /> </Level.Item>
                    </Level>
                </Columns.Column>
                <Columns.Column className='m-0 p-0' >
                </Columns.Column>

                <Columns.Column className='m-0 p-0' narrow>
                    <Level className="is-pulled-right mt-2" >
                        <Level.Item className='m-0 p-0'>
                            <Button rounded size={"medium"} className='has-background-grey-lighter scaleDown-p75 px-3'>
                                <Image size={24} src={fb_messages_icon} alt="fb_icon" />
                            </Button>
                        </Level.Item>
                        <Level.Item className='m-0 p-0'>
                            <Button rounded size={"medium"} className='has-background-grey-lighter scaleDown-p75 px-3 mr-1'>
                                <Image size={24} src={fb_nav_mobile_search} alt="fb_icon" />
                            </Button>
                        </Level.Item>
                    </Level>
                </Columns.Column>
            </Columns>
        </div>
    </Box>
}

export const fb_companyCard = (dpImage, bgImage, companyName, companyUserNameAndCategory) => {
    return <Card className=" overflow-hidden  has-background-transparent is-horizontal-center  " radiusless >
        {/* <Card className="fbBox-940w overflow-hidden  is-horizontal-center " radiusless > */}

        <Card.Image
            size='16/9'
            src={bgImage ?? Default_BG}
            className="figure fb-companyPage-bg overflow-hidden is-radiusless   "
        />
        <Card.Content className="has-background-transparent has-text-centered radiusless m-0 py-0">
            <Button className="is-link px-6 has-text-weight-semibold is-pulled-right mt-6 mr-3" >Learn More</Button>

            <Media className="">
                <Media.Item className="m-0 z-35  has-text-grey-light" align="left" >
                    <Image
                        src={dpImage ?? Default_DP}
                        backgroundColor="black"
                        className="fb-companyPage-dp  is-clipped ml-2"
                        rounded
                    />
                </Media.Item>
                <p className="ml-5 mt-4">
                    <Heading className="mt-0 mb-0 li-companyPage-title has-text-left" textWeight="bold" size={3}>{companyName ?? "User Name"}</Heading>
                    <p className="has-text-grey is-size-6 has-text-weight-medium mb-0 has-text-left">{companyUserNameAndCategory ?? "@username · Product/Service"} </p>
                </p>
            </Media>


            <hr className="m-3" />
            <Columns className=' my-0 py-0'>
                <Columns.Column className=' my-0 py-0'>
                    <Tabs align="left" className="has-text-weight-semibold ml-4 is-size-6 ">
                        <Tabs.Tab active> <span className="pb-3">Home</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">Videos</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">Photos</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">Stores</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">More</span> </Tabs.Tab>
                    </Tabs>
                </Columns.Column>
                <Columns.Column className=' my-0 py-0'>
                    <div className="is-pulled-right pr-4">
                        <Button.Group>
                            <Button className="is-link is-light px-6" >Like</Button>
                            <Button className="has-background-grey-lighter has-text-black has-text-weight-semibold px-2">•••</Button>
                        </Button.Group>
                    </div>
                </Columns.Column>
            </Columns>

        </Card.Content>
    </Card>

}

export const fb_companyCard_mobile = (dpImage, bgImage, companyName, companyUserNameAndCategory) => {
    return <Box className=" overflow-hidden has-background-transparent is-horizontal-center mt-2 m-0 p-0 " radiusless  >

        <Box radiusless shadowless className='m-0 p-0 has-background-transparent '>
            <Image
                size='16/9'
                src={bgImage ?? Default_BG}
                className="figure fb-companyPage-bg-mobile overflow-hidden is-radiusless  mb-0 "
            />
        </Box>

        <Box radiusless shadowless className='m-0 px-4 has-background-transparent '>
            <Columns gap={0} className='m-0 p-0' mobile={true} vCentered>
                <Columns.Column className='m-0 p-0' narrow>
                    <Image
                        src={dpImage ?? Default_DP}
                        // backgroundColor="black"
                        size={64}
                        className="  is-clipped mt-0"
                        rounded
                    />
                </Columns.Column>
                <Columns.Column className='m-0 p-0 ml-2'>
                    <p className=" has-text-weight-bold  has-text-black is-size-6" >{companyName ?? "User Name"}</p>
                    <p className=" is-size-8 has-text-left">{companyUserNameAndCategory ?? "Product/Service"} </p>
                </Columns.Column>
                <Columns.Column className='m-0 p-0'>
                </Columns.Column>
            </Columns>

        </Box>

        <Columns gap={1} className="is-mobile is-centered mt-0 mb-0 pt-0">
            <Columns.Column size={9}>
                <Button size={"small"} fullwidth className="is-link">Sign up</Button>
            </Columns.Column>
            <Columns.Column narrow>
                <Button size={"small"} className="has-background-grey-lighter has-text-black has-text-weight-semibold">•••</Button>
            </Columns.Column>
        </Columns>


        <Box shadowless className="has-background-transparent has-text-centered radiusless m-0 py-0 px-0">



            {/* <Button className="is-link px-6 has-text-weight-semibold is-pulled-right mt-6 mr-3" >Learn More</Button> */}



            <Tabs size="small" align="left" className=" has-text-weight-medium " fullwidth>
                <Tabs.Tab active> <span className="">Home</span> </Tabs.Tab>
                <Tabs.Tab> <span className="">Videos</span> </Tabs.Tab>
                <Tabs.Tab> <span className="">Photos</span> </Tabs.Tab>
                <Tabs.Tab> <span className="">Stores</span> </Tabs.Tab>
                <Tabs.Tab> <span className="">More</span> </Tabs.Tab>
            </Tabs>



        </Box>
    </Box>

}

export const fb_companyBox = (dpImage, bgImage, companyName, companyUserNameAndCategory) => {
    return <Box shadowless className=' py-0 my-0 has-background-transparent  is-centered '>
        {fb_companyCard(dpImage, bgImage, companyName, companyUserNameAndCategory)}
        <br />
        <Columns className='' >
            {/* <Columns.Column size={1}></Columns.Column> */}
            <Columns.Column size={5}>
                <Box className=' has-background-transparent '>
                    <Heading size={5} className=" mb-2">About</Heading>
                    <p>
                        {rowOfColumns_leftImage(fb_info_icon, 32, false, "About the company", "has-text-grey", "is-size-6", "")}
                    </p>
                    <p>
                        {rowOfColumns_leftImage(fb_like_icon, 32, false, "3,930,502 people like this, including 5 of your friends", "has-text-grey", "is-size-6", "")}
                    </p>
                    <p>
                        {rowOfColumns_leftImage(fb_followNumber_icon, 32, false, "4,111,039 people follow this", "has-text-grey", "is-size-6", "")}
                    </p>
                    <p>
                        {rowOfColumns_leftImage(fb_website_icon, 32, false, "https://testpost.app", "has-text-link", "is-size-6", "")}
                    </p>
                    <p>
                        {rowOfColumns_leftImage(fb_website_icon, 32, false, "Product/Services", "has-text-link", "is-size-6", "")}
                    </p>
                    <br />
                </Box>
            </Columns.Column>
            <Columns.Column>
                <Box className=' has-background-transparent '>
                    {rowOfColumns_leftImage(dpImage ?? Default_DP, 48, true,
                        <Form.Field >
                            <Form.Input
                                // color="success"
                                size="normal"
                                placeholder={`Write something here for ${companyName}  ...`}
                                textWeight="semibold"
                                // color="danger"
                                className="is-centered is-rounded has-background-light is-borderless "
                            />
                        </Form.Field>, "has-text-grey", "is-size-6", "has-text-weight-medium"

                    )}
                    <hr />
                    <Columns mobile={true} className="px-4" centered>
                        <Columns.Column className=' px-6 ' narrow> {rowOfColumns_leftImage(fb_photoVideo_icon, 24, false, "Photo/Video", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                        <Columns.Column className=' px-6 ' narrow> {rowOfColumns_leftImage(fb_friendTag_icon, 24, false, "Tag friends", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                        <Columns.Column className=' px-6 ' narrow> {rowOfColumns_leftImage(fb_feeling_icon, 24, false, "Feeling/Activity", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                    </Columns>
                </Box>
                <Box className=' has-background-transparent '>
                    <Heading size={5} className=" mb-2">Posts</Heading>
                </Box>
            </Columns.Column>
            {/* <Columns.Column size={1}></Columns.Column> */}
        </Columns>
    </Box>
}

export const fb_companyBox_mobile = (dpImage, bgImage, companyName, companyUserNameAndCategory) => {
    return <>   
            {fbNav_mobile(Default_DP)}

    <Box shadowless className=' p-0 m-0 has-background-transparent  is-centered '>
        {fb_companyCard_mobile(dpImage, bgImage, companyName, companyUserNameAndCategory)}
        <br />

        <Box className=' has-background-transparent '>
            <Heading size={6} className=" mb-2">About</Heading>
            <p>
                {rowOfColumns_leftImage(fb_info_icon, 32, false, "About the company", "has-text-grey", "is-size-7", "")}
            </p>
            <p>
                {rowOfColumns_leftImage(fb_like_icon, 32, false, "3,930,502 people like this, including 5 of your friends", "has-text-grey", "is-size-7", "")}
            </p>
            <p>
                {rowOfColumns_leftImage(fb_followNumber_icon, 32, false, "4,111,039 people follow this", "has-text-grey", "is-size-7", "")}
            </p>
            <p>
                {rowOfColumns_leftImage(fb_website_icon, 32, false, "https://testpost.app", "has-text-link", "is-size-7", "")}
            </p>
            <p>
                {rowOfColumns_leftImage(fb_website_icon, 32, false, "Product/Services", "has-text-link", "is-size-7", "")}
            </p>
            <br />
        </Box>

        <Box className=' has-background-transparent '>
            {rowOfColumns_leftImage(dpImage ?? Default_DP, 48, true,
                <Form.Field >
                    <Form.Input
                        // color="success"
                        size="normal"
                        placeholder={`Write something here for ${companyName}  ...`}
                        textWeight="semibold"
                        // color="danger"
                        className="is-centered is-rounded has-background-light is-borderless "
                    />
                </Form.Field>, "has-text-grey", "is-size-6", "has-text-weight-medium"

            )}
            <hr />
            <Columns mobile={true} className="px-4" centered>
                <Columns.Column className=' px-4 ' narrow> {rowOfColumns_leftImage(fb_photoVideo_icon, 24, false, "", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                <Columns.Column className=' px-4 ' narrow> {rowOfColumns_leftImage(fb_friendTag_icon, 24, false, "", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                <Columns.Column className=' px-4 ' narrow> {rowOfColumns_leftImage(fb_feeling_icon, 24, false, "", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
            </Columns>
        </Box>
        <Box className=' has-background-transparent '>
            <Heading size={6} className=" mb-2">Posts</Heading>
        </Box>

    </Box>
    </>
}

export const fb_profileCard = (dpImage, bgImage, userName, userBio) => {


    // <Card className="fbBox-940w overflow-hidden is-centered is-horizontal-center " radiusless>

    return <Card className=" overflow-hidden is-centered has-background-transparent is-horizontal-center m-0 pt-0"  >
        <Card.Image
            size='16/9'
            src={bgImage ?? Default_BG}
            className="figure fb-profilePage-bg overflow-hidden is-radiusless is-centered "
        />
        <Card.Content className="has-background-transparent has-text-centered radiusless mb-0 pb-0 ">

            {/* <Level className="has-text-centered mb-0 is-mobile" shadowless>
                <Level.Item className="has-text-centered">
                    <Image
                        src={dpImage ?? Default_DP}
                        backgroundColor="black"
                        className="fb-profilePage-dp is-clipped is-h-center"
                        rounded
                    />
                </Level.Item>
            </Level> */}
            {/* <Heading className="mt-0 mb-0 li-companyPage-title " textWeight="bold" size={3}>{userName ?? "User Name"}</Heading> */}
            {/* <p className="has-text-grey is-size-6 has-text-weight-medium mb-0">{userBio ?? "Bio of the user"} </p> */}

            <Media className="">
                <Media.Item className="m-0 z-35  has-text-grey-light" align="left" >
                    <Image
                        src={dpImage ?? Default_DP}
                        backgroundColor="black"
                        className="fb-profilePage-dp  is-clipped ml-2"
                        rounded
                    />
                </Media.Item>
                <p className="ml-5 mt-2">
                    <Heading className="mt-0 mb-0 li-companyPage-title  has-text-left" textWeight="bold" size={3}>{userName ?? "User Name"}</Heading>
                    <p className="has-text-grey is-size-6 has-text-weight-semibold mb-0 has-text-left">24 Mutual Friends</p>
                </p>
            </Media>

            <hr className="m-3" />
            <Columns className=' mb-0 pb-0'>
                <Columns.Column className=' mb-0 pb-0' >
                    <Tabs align="left" className="has-text-weight-semibold ml-4 mb-0 is-size-6 pb-0">
                        <Tabs.Tab active> <span className="pb-3">Posts</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">About</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">Friends</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">Photos</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">Videos</span> </Tabs.Tab>
                        <Tabs.Tab> <span className="pb-3">More</span> </Tabs.Tab>
                    </Tabs>
                </Columns.Column>
                <Columns.Column className=' mb-0'>
                    <div className="is-pulled-right pr-4">
                        <Button.Group>
                            <Button className="has-background-grey-lighter has-text-black has-text-weight-semibold">Friends</Button>
                            <Button className="is-link">Message</Button>
                            <Button className="has-background-grey-lighter has-text-black has-text-weight-semibold">•••</Button>
                        </Button.Group>
                    </div>
                </Columns.Column>
            </Columns>

        </Card.Content>
    </Card>

}

export const fb_profileCard_mobile = (dpImage, bgImage, userName, userBio) => {


    // <Card className="fbBox-940w overflow-hidden is-centered is-horizontal-center " radiusless>

    return <Box shadowless className=" overflow-hidden p-0 is-centered has-background-transparent is-horizontal-center m-0 mt-2 "  >
        <Image
            size='16/9'
            src={bgImage ?? Default_BG}
            className="figure  overflow-hidden  is-centered "
        />
        <Level className="has-text-centered mb-0 is-mobile" shadowless>
            <Level.Item className="has-text-centered">
                <Image
                    src={dpImage ?? Default_DP}
                    backgroundColor="black"
                    className="fb-profilePage-dp-mobile is-clipped is-h-center"
                    rounded
                />
            </Level.Item>
        </Level>
        <Box radiusless shadowless className="has-background-transparent has-text-centered radiusless m-0 p-0 ">
            <p className="has-text-centered mt-4 ">
                <p className=" is-size-5 has-text-weight-bold has-text-black ">{userName ?? "User Name"}</p>
                <p className="is-size-6  ">24 Mutual Friends</p>
            </p>

            {/* <Heading className="mt-0 mb-0 li-companyPage-title " textWeight="bold" size={3}>{userName ?? "User Name"}</Heading> */}
            {/* <p className="has-text-grey is-size-6 has-text-weight-medium mb-0">{userBio ?? "Bio of the user"} </p>

            {/* <Media className="">
                <Media.Item className="m-0 z-35  has-text-grey-light" align="left" >
                    <Image
                        src={dpImage ?? Default_DP}
                        backgroundColor="black"
                        className="fb-profilePage-dp  is-clipped ml-2"
                        rounded
                    />
                </Media.Item>
                <p className="ml-5 mt-2">
                    <Heading className="mt-0 mb-0 li-companyPage-title  has-text-left" textWeight="bold" size={3}>{userName ?? "User Name"}</Heading>
                    <p className="has-text-grey is-size-6 has-text-weight-semibold mb-0 has-text-left">24 Mutual Friends</p>
                </p>
            </Media> */}


            <Columns gap={1} className="is-mobile is-centered mt-2 mb-0">
                <Columns.Column size={9}>
                    <Button fullwidth className="is-link">Message</Button>
                </Columns.Column>
                <Columns.Column narrow>
                    <Button className="has-background-grey-lighter has-text-black has-text-weight-semibold">•••</Button>
                </Columns.Column>
            </Columns>
            <hr className='mt-0' />

        </Box>
    </Box>

}

export const fb_profileBox = (dpImage, bgImage, userName, userBio) => {
    return <Box shadowless className=' py-0 my-0 has-background-transparent '>
        {fb_profileCard(dpImage, bgImage, userName, userBio)}
        <br />
        <Columns className='' >
            {/* <Columns.Column size={1}></Columns.Column> */}
            <Columns.Column size={5}>
                <Box className=' has-background-transparent '>
                    <Heading size={5} className=" mb-2">Intro</Heading>
                    <p className=' has-text-centered is-size-6p2 px-6' >
                        Marketing Director at TestPost | Brand Strategost | HCI | Culture First | Red Dot Design Award Winner
                    </p>
                    <hr />
                    <p>
                        {rowOfColumns_leftImage(fb_education_icon, 14, false, <span>Went to<span className=' has-text-weight-semibold has-text-black'> Harvard Business School </span></span>, "has-text-grey", "is-size-6", "")}
                    </p>

                    <p>
                        {rowOfColumns_leftImage(fb_education_icon, 14, false, <span>Went to<span className=' has-text-weight-semibold has-text-black'> Thomas Jefferson School for Science and Technology </span></span>, "has-text-grey", "is-size-6", "")}
                    </p>
                    <br />
                </Box>
            </Columns.Column>
            <Columns.Column>
                <Box className=' has-background-transparent '>
                    {rowOfColumns_leftImage(dpImage ?? Default_DP, 48, true,
                        <Form.Field >
                            <Form.Input
                                // color="success"
                                size="normal"
                                placeholder={`Write something here for ${userName}  ...`}
                                textWeight="semibold"
                                // color="danger"
                                className="is-centered is-rounded has-background-light is-borderless "
                            />
                        </Form.Field>, "has-text-grey", "is-size-6", "has-text-weight-medium"

                    )}
                    <hr />
                    <Columns mobile={true} className="px-4" centered>
                        <Columns.Column className=' px-6 ' narrow> {rowOfColumns_leftImage(fb_photoVideo_icon, 24, false, "Photo/Video", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                        <Columns.Column className=' px-6 ' narrow> {rowOfColumns_leftImage(fb_friendTag_icon, 24, false, "Tag friends", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                        <Columns.Column className=' px-6 ' narrow> {rowOfColumns_leftImage(fb_feeling_icon, 24, false, "Feeling/Activity", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                    </Columns>
                </Box>
                <Box className=' has-background-transparent '>
                    <Heading size={5} className=" mb-2">Posts</Heading>
                </Box>
            </Columns.Column>
            {/* <Columns.Column size={1}></Columns.Column> */}
        </Columns>
    </Box>
}


export const fb_profileBox_mobile = (dpImage, bgImage, userName, userBio) => {
    return <>
        {fbNav_mobile(Default_DP)}
        <Box shadowless className=' py-0 my-0 px-2 has-background-transparent '>
            {fb_profileCard_mobile(dpImage, bgImage, userName, userBio)}

            <Box shadowless className='my-0 pb-2 has-background-transparent '>
                <Heading size={6} className=" mb-2">Intro</Heading>
                <p className=' has-text-centered is-size-7 px-2' >
                    Marketing Director at TestPost | Brand Strategost | HCI | Culture First | Red Dot Design Award Winner
                </p>
                <hr />
                <p>
                    {rowOfColumns_leftImage(fb_education_icon, 14, false, <span>Went to<span className=' has-text-weight-semibold has-text-black'> Harvard Business School </span></span>, "has-text-grey", "is-size-7", "")}
                </p>

                <p>
                    {rowOfColumns_leftImage(fb_education_icon, 14, false, <span>Went to<span className=' has-text-weight-semibold has-text-black'> Thomas Jefferson School for Science and Technology </span></span>, "has-text-grey", "is-size-7", "")}
                </p>
                <br />
            </Box>

            <Box shadowless className='my-0 pb-2 has-background-transparent '>
                <hr />
                {rowOfColumns_leftImage(dpImage ?? Default_DP, 48, true,
                    <Form.Field >
                        <Form.Input
                            // color="success"
                            size="normal"
                            placeholder={`Write something here for ${userName}  ...`}
                            textWeight="semibold"
                            // color="danger"
                            className="is-centered is-rounded has-background-light is-borderless "
                        />
                    </Form.Field>, "has-text-grey", "is-size-6", "has-text-weight-medium"

                )}
                <Columns mobile={true} className="px-4" centered>
                    <Columns.Column className=' px-5 ' narrow> {rowOfColumns_leftImage(fb_photoVideo_icon, 24, false, "", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                    <Columns.Column className=' px-5 ' narrow> {rowOfColumns_leftImage(fb_friendTag_icon, 24, false, "", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                    <Columns.Column className=' px-5 ' narrow> {rowOfColumns_leftImage(fb_feeling_icon, 24, false, "", "has-text-grey", "is-size-6", "has-text-weight-medium")}</Columns.Column>
                </Columns>
            </Box>
            <Box shadowless className='my-0 pb-2 has-background-transparent '>
                <hr />
                <Heading size={6} className=" mb-2">Posts</Heading>
            </Box>
        </Box>

    </>
}





export const fb_postCard = (dpImge, postImage, userName, position, content, isMobile) => {
    return <Card className={` overflow-hidden has-background-transparent is-centered is-h-center ${isMobile ? " " : "fbBox-680w"} `}  >
        <Card.Content className="has-background-transparent radiusless px-0">
            <Box shadowless radiusless className="has-background-transparent py-0">
                <Button size="small" className="has-background-transparent is-borderless m-0 p-0 is-pulled-right">
                    <img
                        src={fbDotMenu}
                        alt="like"
                        className="li-iconSize24  figure opacity75"
                    /></Button>
                <Columns className="is-mobile " gap={0}>
                    <Columns.Column size={1} className="" >
                        <Media className="m-0">
                            <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                                <Image
                                    src={dpImge ?? Default_DP}
                                    className="twitter-post-dp-size has-background-transparent"
                                    rounded
                                />
                            </Media.Item>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className=" py-0 pl-4">
                        <Heading className="mt-4 mb-0 li-companyPage-title " textWeight="semibold" size={6}>{userName ?? "User Name"} <span className="has-text-grey is-size-6p7 has-text-weight-normal"></span></Heading>
                        {/* <p className="has-text-grey-dark twitter-profile-handleFont is-size-7 "> {position ?? "Social Media Manager, TestPost."} </p> */}
                        <p className="has-text-grey-dark twitter-profile-handleFont is-size-7 ">
                            <Columns gap={0} className="is-mobile m-0 p-0">
                                <Columns.Column narrow className="m-0 p-0">
                                    12h ·
                                </Columns.Column>
                                <Columns.Column narrow className="m-0 p-0">
                                    <Media className="ml-1 ">
                                        <img
                                            alt="shared mt-2"
                                            src={liGlobeIcon}
                                            className="li-iconSize opacity50 figure has-text-grey-light"
                                        />
                                    </Media>
                                </Columns.Column>
                            </Columns>
                        </p>

                    </Columns.Column>
                </Columns>

                <Content className="mt-1 has-text-black fb-postFontSize  li-break-words" >{content ?? "Post content"}</Content>
            </Box>
            {
                postImage ?
                    <div className="li-imageWrapper is-bordered-y">
                        <img
                            src={postImage}
                            alt="post"
                            className="li-imageAttributes"
                        />
                    </div>
                    : null
            }


            <Box shadowless radiusless className="has-background-transparent pt-0 pb-0">
                <Columns className="m-0 is-mobile">
                    <Columns.Column className="has-text-left px-0 mx-0" size={1}>
                        {/* <Level breakpoint="mobile" alignItems="left" alignContent="left" textAlign="left" className="m-0">
                        <Level.Item className="m-0 p-0 py-1" >
                        </Level.Item>
                        <Level.Item className="m-0 p-0 has-text-left" >
                        </Level.Item>
                    </Level> */}
                        {/* <Media.Item className="m-0"  > */}

                        {/* </Media.Item> */}
                        <img
                            src={fbLiked}
                            alt="liked"
                            className="fb-iconSize18 mt-1 figure "
                        />
                        <img
                            src={fbLoved}
                            alt="loved"
                            className="fb-iconSize18 mt-1 figure"
                        />
                    </Columns.Column>
                    <Columns.Column className="has-text-left px-0 mx-0">
                        <span className=" is-size-6p4 mt-minus-2 has-text-grey has-text-weight-medium">Even Marvin Doe and 155 others</span>

                    </Columns.Column>

                    <Columns.Column className="has-text-right" narrow>
                        <span className="ml-2 is-size-6p4 mt-minus-2 has-text-grey has-text-weight-medium">108 comments &nbsp; 9 shares</span>
                    </Columns.Column>
                </Columns>
                <hr className="instaHr mb-2 mt-0" />
                <p className=" m-0">

                    <Level className="mt-4 mb-0 " breakpoint="mobile" >
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={fbLike}
                                        alt="like"
                                        className="fb-iconSize18 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2 is-size-6p4 mt-minus-2 has-text-grey has-text-weight-semibold">Like</span>
                            </Media>

                        </Level.Item>
                        <Level.Item  >
                            <Media className="mt-1">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={fbComment}
                                        alt="comment"
                                        className="fb-iconSize18 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2 is-size-6p4 mt-minus-4 has-text-grey has-text-weight-semibold">Comment</span>
                            </Media>
                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={fbShare}
                                        alt="share"
                                        className="fb-iconSize18 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2 is-size-6p4 mt-minus-2 has-text-grey has-text-weight-semibold ">Share</span>
                            </Media>
                        </Level.Item>
                    </Level>

                </p>
                <hr className="instaHr mb-0 mt-2" />

            </Box>
        </Card.Content>
    </Card>
}

export const fb_postCard_mobile = (dpImge, postImage, userName, position, content, isMobile) => {
    return <Card radiusless className={` overflow-hidden has-background-transparent is-centered is-h-center ${isMobile ? " " : "fbBox-680w"} `}  >
        <Card.Content className="has-background-transparent radiusless px-0">
            <Box shadowless radiusless className="has-background-transparent py-0 px-3 mb-2">
                <Button size="small" className="has-background-transparent is-borderless m-0 p-0 is-pulled-right">
                    <img
                        src={fbDotMenu}
                        alt="like"
                        className="li-iconSize20 mt-4 figure opacity75"
                    /></Button>
                <Columns className="is-mobile mb-1" gap={0}>
                    <Columns.Column size={1} className="" >
                        <Media className="m-0">
                            <Media.Item className=" z-35 has-background-transparent radiusless" align="left" >
                                <Image
                                    src={dpImge ?? Default_DP}
                                    size={32}
                                    className="mt-1  has-background-transparent"
                                    rounded
                                />
                            </Media.Item>
                        </Media>
                    </Columns.Column>
                    <Columns.Column className=" py-0 pl-4">
                        <Heading className="mt-4 mb-0 li-companyPage-title " textWeight="semibold" size={7}>{userName ?? "User Name"} <span className="has-text-grey is-size-6p7 has-text-weight-normal"></span></Heading>
                        {/* <p className="has-text-grey-dark twitter-profile-handleFont is-size-7 "> {position ?? "Social Media Manager, TestPost."} </p> */}
                        <p className="has-text-grey-dark twitter-profile-handleFont is-size-7 ">
                            <Columns gap={0} className="is-mobile m-0 p-0">
                                <Columns.Column narrow className="m-0 p-0">
                                    12h ·
                                </Columns.Column>
                                <Columns.Column narrow className="m-0 p-0">
                                    <Media className="ml-1 ">
                                        <img
                                            alt="shared mt-2"
                                            src={liGlobeIcon}
                                            className="li-iconSize opacity50 figure has-text-grey-light"
                                        />
                                    </Media>
                                </Columns.Column>
                            </Columns>
                        </p>

                    </Columns.Column>
                </Columns>

                <Content className="mt-1 has-text-black is-size-6p8 li-break-words" >{content ?? "Post content"}</Content>
            </Box>
            {
                postImage ?
                    <div className="li-imageWrapper is-bordered-y">
                        <img
                            src={postImage}
                            alt="post"
                            className="li-imageAttributes"
                        />
                    </div>
                    : null
            }


            <Box shadowless radiusless className="has-background-transparent pt-0 pb-0">
                <Columns className="m-0 is-mobile is-size-6p8 ">
                    <Columns.Column className="has-text-left px-0 mx-0" size={1}>
                        {/* <Level breakpoint="mobile" alignItems="left" alignContent="left" textAlign="left" className="m-0">
                        <Level.Item className="m-0 p-0 py-1" >
                        </Level.Item>
                        <Level.Item className="m-0 p-0 has-text-left" >
                        </Level.Item>
                    </Level> */}
                        {/* <Media.Item className="m-0"  > */}

                        {/* </Media.Item> */}
                        <img
                            src={fbLiked}
                            alt="liked"
                            className="fb-iconSize18  figure "
                        />
                        {/* <img
                            src={fbLoved}
                            alt="loved"
                            className="fb-iconSize18 mt-1 figure"
                        /> */}
                    </Columns.Column>
                    <Columns.Column className="has-text-left px-0 mx-0">
                        <span className="  mt-2 has-text-grey has-text-weight-medium">27</span>

                    </Columns.Column>

                    <Columns.Column className="has-text-right" narrow>
                        <span className="ml-2  mt-minus-2 has-text-grey has-text-weight-medium">108 comments &nbsp;&nbsp; 9 shares</span>
                    </Columns.Column>
                </Columns>
                <hr className="instaHr mb-2 mt-0" />
                <p className=" m-0">

                    <Level className="mt-4 mb-0 is-size-7 " breakpoint="mobile" >
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={fbLike}
                                        alt="like"
                                        className="fb-iconSize14 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2  mt-minus-2 has-text-grey has-text-weight-semibold">Like</span>
                            </Media>

                        </Level.Item>
                        <Level.Item  >
                            <Media className="mt-1">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={fbComment}
                                        alt="comment"
                                        className="fb-iconSize14 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2  mt-minus-4 has-text-grey has-text-weight-semibold">Comment</span>
                            </Media>
                        </Level.Item>
                        <Level.Item  >
                            <Media className="">
                                <Media.Item className="m-0"  >
                                    <img
                                        src={fbShare}
                                        alt="share"
                                        className="fb-iconSize14 figure opacity50"
                                    />
                                </Media.Item>
                                <span className="ml-2  mt-minus-2 has-text-grey has-text-weight-semibold ">Share</span>
                            </Media>
                        </Level.Item>
                    </Level>

                </p>
                <hr className="instaHr mb-0 mt-2" />

            </Box>
        </Card.Content>
    </Card>
}

export const fb_postBox = (dpImge, postImage, userName, position, content) => {
    return <Columns className=' mt-4'>
        <Columns.Column>

            <Box shadowless className='m-0 p-0 has-background-transparent'>
                {/* <Heading className="mt-0 mb-0  mb-2 " textColor='grey' size={5}>Recent</Heading> */}
                {rowOfColumns_leftImage(dpImge ?? Default_Li_Blank_User, 48, true, userName, "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_friends_icon, 48, false, "Friends", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_groups_icon, 48, false, "Groups", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_marketplace_icon, 48, false, "Marketplace", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_memories_icon, 48, false, "Memories", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_saved_icon, 48, false, "Saved", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_adcentre_icon, 48, false, "Ad Center", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_adsmanager_icon, 48, false, "Ads Manager", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_businessmanager_icon, 48, false, "Business Manager", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_events_icon, 48, false, "Events", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_favourites_icon, 48, false, "Favourites", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_livevideo_icon, 48, false, "Live Videos", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_mostrecent_icon, 48, false, "Most Recent", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_page_icon, 48, false, "Pages", "", "is-size-6", "has-text-weight-medium")}
                {rowOfColumns_leftImage(fb_recentadactivity_icon, 48, false, "Recent Ad Activity", "", "is-size-6", "has-text-weight-medium")}
            </Box>

        </Columns.Column>
        <Columns.Column>
            {fb_postCard(dpImge, postImage, userName, position, content)}
            <br />
            {fb_postCard(dpImge, postImage, userName, position, content)}
        </Columns.Column>
        <Columns.Column>
            <Box shadowless className='m-0 ml-4 p-0 has-background-transparent'>
                <Heading className="mt-0 mb-0  mb-4 " textColor='grey' size={6}>Birthdays</Heading>
                {rowOfColumns_leftImage(reedhastings_dp, 48, true, <span>Reed Hastings<span className=' has-text-weight-light is-size-6p6'>'s birthday is today</span></span>, "has-text-black", "is-size-6", "has-text-weight-normal")}
                {rowOfColumns_leftImage(satyanadella_dp, 48, true, <span>Satya Nadella<span className=' has-text-weight-light is-size-6p6'>'s birthday is today</span></span>, "has-text-black", "is-size-6", "has-text-weight-normal")}
                {rowOfColumns_leftImage(billgates_dp, 48, true, <span>Bill Gates<span className=' has-text-weight-light is-size-6p6'>'s birthday is tommorrow</span></span>, "has-text-black", "is-size-6", "has-text-weight-normal")}
            </Box>
            <br />
            <Box shadowless className='m-0 ml-4 p-0 has-background-transparent'>
                <Heading className="mt-0 mb-0  mb-4 " textColor='grey' size={6}>Contacts</Heading>

                {
                    randomUsers().map(u => rowOfColumns_leftImage(u.image, 48, true, u.name, "has-text-black", "is-size-6", "has-text-weight-normal"))
                }


            </Box>


        </Columns.Column>
    </Columns>
}


export const fb_postBox_mobile = (dpImge, postImage, userName, position, content) => {
    return <>
        {fbNav_mobile(Default_DP)}
        {fb_postCard_mobile(dpImge, postImage, userName, position, content, true)}
        <br />
        {fb_postCard_mobile(dpImge, postImage, userName, position, content, true)}
    </>
}

