import Parse from 'parse'
import moment from 'moment';
import { currentUserInfo, getCurrentWorkspaceId, isCurrentUser, validateMemberInfoRoles } from '../utilities/Login';
import { randomCode, removeKeysFromUserInfo_Obj, cypherNoteObjToString, di_cypherNoteObjToString } from '../utilities/Utilities';
import { appMeta, EventCompletionStatus, Event_Dynamic_Changeables, NotificationFor, projectPointer, userPointer, workspacePointer   } from './EnumsAndPointers';

const EventClass = Parse.Object.extend("Event");


export class Event {
    constructor(id, name, description, notes, channels, assets, assetsIds, thumbImageUrls, comments,   attachments, content, members, membersInfo, status, toRemind, remindAtTimes, membersToRemind, tasks, tasksInfo, projects, projectsIds, starts, ends, isAllDay, isResolved, resolvedBy, resolvedByInfo, byUser, byUserInfo, assignedTo, assignedToInfo, isShared, sharedWith, sharedWithInfo, tags, needsAprroval, needsApprovalFrom, needsApprovalFromInfo, approvedBy, approvedByInfo, createdAt, updatedAt, completedAt, completedBy, completedByInfo, isArchived, archivedBy, archivedByInfo, workspace, color, appMeta) {
        this.id = id
        this.name = name
        this.description = description ?? ""
        this.notes = notes ?? []
        this.channels = channels ?? []
        this.attachments = attachments ?? []
        this.content = content
        this.members = members ?? []
        this.membersInfo = membersInfo ?? []
        this.status = status ?? EventCompletionStatus.pending
        this.toRemind = toRemind
        this.remindAtTimes = remindAtTimes ?? []
        this.membersToRemind = membersToRemind ?? []
        this.tasks = tasks ?? []
        this.tasksInfo = tasksInfo ?? []

        this.assets = assets ?? []
        this.assetsIds = assetsIds ?? []
        this.thumbImageUrls = thumbImageUrls ?? []  // handy to display image of asset while listing the tasks without fetching them multiple times
        this.projects = projects ?? []
        this.projectsIds = projectsIds ?? []

        this.starts = starts
        this.ends = ends
        this.isAllDay = isAllDay ?? false
        this.isResolved = isResolved ?? false
        this.resolvedBy = resolvedBy
        this.resolvedByInfo = resolvedByInfo

        this.byUser = byUser
        this.byUserInfo = byUserInfo

        this.assignedTo = assignedTo ?? []
        this.assignedToInfo = assignedToInfo ?? [] 
        this.isShared = isShared ?? false
        this.sharedWith = sharedWith ?? []
        this.sharedWithInfo = sharedWithInfo ?? []


        this.tags = tags ?? []
        this.needsAprroval = needsAprroval ?? false
        this.needsApprovalFrom = needsApprovalFrom ?? []
        this.needsApprovalFromInfo = needsApprovalFromInfo ?? []
        this.approvedBy = approvedBy ?? []
        this.approvedByInfo = approvedByInfo ?? []
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.completedBy = completedBy
        this.completedByInfo = completedByInfo
        this.completedAt = completedAt

        this.isArchived = isArchived
        this.archivedBy = archivedBy
        this.archivedByInfo = archivedByInfo

        this.workspace = workspace
        this.color = color
        this.appMeta = appMeta
    }

    static copyFrom(obj) {
        let ob = new Event()
        ob.id                   = obj.id                   
        ob.name                 = obj.name                 
        ob.description          = obj.description          
        ob.notes                = obj.notes                
        ob.channels             = obj.channels             

        ob.attachments          = obj.attachments          
        ob.content              = obj.content              
        ob.members              = obj.members              
        ob.membersInfo          = obj.membersInfo          
        ob.status                = obj.status                
        ob.toRemind             = obj.toRemind             
        ob.remindAtTimes        = obj.remindAtTimes        
        ob.membersToRemind      = obj.membersToRemind      
        ob.tasks                = obj.tasks                
        ob.tasksInfo            = obj.tasksInfo    

        ob.assets               = obj.assets   
        ob.assetsIds            = obj.assetsIds   
        ob.thumbImageUrls       = obj.thumbImageUrls         
        ob.projects             = obj.projects      
        ob.projectsIds          = obj.projectsIds      

        ob.starts               = obj.starts               
        ob.ends                 = obj.ends                 
        ob.isAllDay             = obj.isAllDay             
        ob.isResolved           = obj.isResolved           
        ob.resolvedBy           = obj.resolvedBy           
        ob.resolvedByInfo       = obj.resolvedByInfo   
        
        ob.byUser               = obj.byUser
        ob.byUserInfo           = obj.byUserInfo

        ob.assignedTo           = obj.assignedTo               
        ob.assignedToInfo       = obj.assignedToInfo
        ob.isShared             = obj.isShared
        ob.sharedWith           = obj.sharedWith
        ob.sharedWithInfo       = obj.sharedWithInfo   
             
        ob.tags                 = obj.tags                 
        ob.needsAprroval        = obj.needsAprroval        
        ob.needsApprovalFrom    = obj.needsApprovalFrom    
        ob.needsApprovalFromInfo= obj.needsApprovalFromInfo
        ob.approvedBy           = obj.approvedBy           
        ob.approvedByInfo       = obj.approvedByInfo       
        ob.createdAt            = obj.createdAt            
        ob.updatedAt            = obj.updatedAt  
        ob.completedAt          = obj.completedAt
        ob.completedBy          = obj.completedBy
        ob.completedByInfo      = obj.completedByInfo     
        ob.isArchived           = obj.isArchived
        ob.archivedBy           = obj.archivedBy 
        ob.archivedByInfo       = obj.archivedByInfo
        
        ob.workspace            = obj.workspace        
        ob.color             = obj.color    
        ob.appMeta              = obj.appMeta              
        return ob
    }



    static attributeForChangeable = (changeable) => {
        switch (changeable) {
            case Event_Dynamic_Changeables.AssignedToUsers: return "assignedToInfo" ; 
            case Event_Dynamic_Changeables.SharedWithUsers: return "sharedWithInfo" ; 
            case Event_Dynamic_Changeables.Note : return "notes" ; 
            case Event_Dynamic_Changeables.AssetsAndContents : return "assets" ; 
            default:  return null;
        }
    } 

    static changeableForAttribute = (attribute) => {
        switch (attribute) {
            case "assignedToInfo" : return Event_Dynamic_Changeables.AssignedToUsers  ; 
            case "sharedWithInfo" : return Event_Dynamic_Changeables.SharedWithUsers ; 
            case "notes" : return  Event_Dynamic_Changeables.Note ; 
            case "assets" : return  Event_Dynamic_Changeables.AssetsAndContents ; 
            default:  return null;
        }
    } 


    static initFromPFObject = (obj) => {
        let np = new Event()

        if (!obj.id) {
            return null
        }

        np.id = obj.id
        np.name = obj.get("name")
        np.description = obj.get("description") ?? ""
        np.channels = obj.get("channels") ?? []


        np.attachments = obj.get("attachments") ?? []
        np.content = obj.get("content")
        np.members = obj.get("members") ?? []
        np.membersInfo = obj.get("membersInfo") ?? []

        np.status = obj.get("status") ?? EventCompletionStatus.pending
        np.toRemind = obj.get("toRemind") ?? false
        np.remindAtTimes = obj.get("remindAtTimes") ?? []
        np.membersToRemind = obj.get("membersToRemind") ?? []


        np.tasks = obj.get("tasks") ?? []
        np.tasksInfo = obj.get("tasksInfo") ?? []


        np.assets = obj.get("assets") ?? []
        np.assetsIds = obj.get("assetsIds") ?? []
        np.thumbImageUrls = obj.get("thumbImageUrls") ?? []

        np.comments = obj.get("comments") ?? []
        np.commentsIds = obj.get("commentsIds") ?? []

        np.projects = obj.get("projects") ?? []
        np.projectsIds = obj.get("projectsIds") ?? []


        np.starts = obj.get("starts")
        np.ends = obj.get("ends")
        np.isAllDay = obj.get("isAllDay") ?? false


        np.isResolved = obj.get("isResolved") ?? false
        let rb = obj.get("resolvedBy")
        if (rb) {
            if (rb.id) {
                np.resolvedBy = rb.id
                np.resolvedByInfo = obj.get("resolvedByInfo")
            }
        }


        let cipheredNotes = obj.get("notes") ?? []
        let notesObj = []
        if (cipheredNotes.length){
            cipheredNotes.forEach((c) => {
                let d = di_cypherNoteObjToString(c)
                if (d){
                    notesObj.push(d)
                }
            })
        }
        np.notes = notesObj


        let bu = obj.get("byUser")
        np.byUser = bu.id ?? isCurrentUser().id
        np.byUserInfo = obj.get("byUserInfo")

        np.assignedTo = obj.get("assignedTo") ?? []
        var assnInToInfo = obj.get("assignedToInfo") ?? []
        assnInToInfo = validateMemberInfoRoles(assnInToInfo, obj.get("byUserInfo")) ?? []
        np.assignedToInfo = assnInToInfo ?? []

        np.isShared = obj.get("isShared") ?? false
        np.sharedWith = obj.get("sharedWith") ?? []
        var sharedWInfo = obj.get("sharedWithInfo") ?? []
        sharedWInfo = validateMemberInfoRoles(sharedWInfo, obj.get("byUserInfo")) ?? []
        np.sharedWithInfo = sharedWInfo ?? []


        np.tags = obj.get("tags") ?? []
        np.needsAprroval = obj.get("needsAprroval") ?? false
        np.needsApprovalFrom = obj.get("needsApprovalFrom")
        var needsApprFrInfo = obj.get("needsApprovalFromInfo") ?? []
        needsApprFrInfo = validateMemberInfoRoles(needsApprFrInfo, obj.get("assignedToInfo")) ?? []
        np.needsApprovalFromInfo = needsApprFrInfo

        np.approvedBy = obj.get("approvedBy") ?? []
        let apprBInfo = obj.get("approvedByInfo") ?? []
        apprBInfo = validateMemberInfoRoles(apprBInfo, obj.get("assignedToInfo")) ?? []
        np.approvedByInfo = apprBInfo

        np.createdAt = obj.get("createdAt")
        np.updatedAt = obj.get("updatedAt")

        np.isArchived = obj.get("isArchived") ?? false
        np.archivedBy = obj.get("archivedBy") ?? false
        np.archivedByInfo = obj.get("archivedByInfo")
        np.completedAt = obj.get("completedAt")
        np.completedBy = obj.get("completedBy")
        np.completedByInfo = obj.get("completedByInfo")


        let wk = obj.get("workspace")
        np.workspace = wk.id
        np.color = obj.get("color") ?? obj.get("colorHex")
        np.appMeta = obj.get("appMeta")

        return np
    }





    add = (callback) => {
        let ob = new EventClass()
        if (!this.name) {
            this.name = "General Post"
        }

        ob.set("name", this.name)
        ob.set("description", this.description)
        ob.set("notes", this.notes)
        ob.set("channels", this.channels)

        ob.set("attachments", this.attachments)
        ob.set("content", this.content)
        ob.set("members", this.members)
        ob.set("membersInfo", this.membersInfo)

        ob.set("status", this.status)
        ob.set("toRemind", this.toRemind)
        ob.set("remindAtTimes", this.remindAtTimes)
        ob.set("membersToRemind", this.membersToRemind)
        ob.set("tasks", this.tasks)
        ob.set("tasksInfo", this.tasksInfo)


        ob.set("assets", this.assets)
        ob.set("assetsIds", this.assetsIds)
        ob.set("thumbImageUrls", this.thumbImageUrls)
        ob.set("projects", this.projects )
        ob.set("projectsIds", this.projectsIds )


        ob.set("starts", this.starts)
        ob.set("ends", this.ends)
        ob.set("isAllDay", this.isAllDay)
        ob.set("isResolved", this.isResolved)
        if (this.resolvedBy) { ob.set("resolvedBy", this.resolvedBy) }
        if (this.resolvedByInfo) { ob.set("resolvedByInfo", this.resolvedByInfo) }

        const cu = isCurrentUser()
        ob.set("byUser", userPointer(cu.id))
        ob.set("byUserInfo", currentUserInfo())

        ob.set("assignedTo", this.assignedTo)
        ob.set("assignedToInfo", this.assignedToInfo)

        ob.set("isShared", this.isShared)
        ob.set("sharedWith", this.sharedWith)
        ob.set("sharedWithInfo", this.sharedWithInfo)



        ob.set("tags", this.tags)
        ob.set("needsAprroval", this.needsAprroval)
        ob.set("needsApprovalFrom", this.needsApprovalFrom)
        ob.set("needsApprovalFromInfo", this.needsApprovalFromInfo)

        ob.set("approvedBy", this.approvedBy)
        ob.set("approvedByInfo", this.approvedByInfo)

        // make workspace neceesary
        ob.set("workspace", workspacePointer(getCurrentWorkspaceId()))
        ob.set("color", this.color)
        ob.set("appMeta", appMeta)

        ob.save()
            .then((evnt) => {
                this.id = evnt.id
                console.log('New Event Created')
                callback(true, this, '')
            }, (error) => {
                console.log('Failed to create new event, with error code: ' + error.message);
                callback(false, null, error.message)
            });
    }


    update_decider = (originalObj, changedAttributes, callback) => {

        // if (changedAttributes.includes())

        // AssignedToUser
        // SharedWithUser
        // Note 
        // Assets & Contents
        



    }


    update = (callback) => {
        if (this.id == null) {
            callback(false, null, "Error : No object id to update Event")
            return
        }

        var query = new Parse.Query(EventClass);
        query.get(this.id)
            .then((ob) => {
                if (!this.name) {
                    this.name = "General Post"
                }

                ob.set("name", this.name)
                if (this.description) { ob.set("description", this.description) } else { ob.unset("description") }

                ob.set("channels", this.channels)

                ob.set("attachments", this.attachments)
                if (this.content) { ob.set("content", this.content) } else { ob.unset("content") }

                ob.set("members", this.members)
                ob.set("membersInfo", this.membersInfo)

                ob.set("status", this.status)
                ob.set("toRemind", this.toRemind)
                ob.set("remindAtTimes", this.remindAtTimes)
                ob.set("membersToRemind", this.membersToRemind)
                ob.set("tasks", this.tasks)
                ob.set("tasksInfo", this.tasksInfo)
                ob.set("projects", this.projects)

                ob.set("assets", this.assets)
                ob.set("assetsIds", this.assetsIds)
                ob.set("thumbImageUrls", this.thumbImageUrls)
                ob.set("projects", this.projects )
                ob.set("projectsIds", this.projectsIds )
        
                // ob.set("assignedTo", this.assignedTo)
                // ob.set("assignedToInfo", this.assignedToInfo)
        
                ob.set("isShared", this.isShared)
                // ob.set("sharedWith", this.sharedWith)
                // ob.set("sharedWithInfo", this.sharedWithInfo)
        

                ob.set("starts", this.starts)
                ob.set("ends", this.ends)
                ob.set("isAllDay", this.isAllDay)
                ob.set("isResolved", this.isResolved)
                if (this.resolvedBy) { ob.set("resolvedBy", this.resolvedBy) }
                if (this.resolvedByInfo) { ob.set("resolvedByInfo", this.resolvedByInfo) }

                ob.set("tags", this.tags)
                ob.set("needsAprroval", this.needsAprroval)
                ob.set("needsApprovalFrom", this.needsApprovalFrom)
                ob.set("needsApprovalFromInfo", this.needsApprovalFromInfo)

                ob.set("approvedBy", this.approvedBy)
                ob.set("approvedByInfo", this.approvedByInfo)

                ob.set("isArchived", this.isArchived)
                if (this.archivedBy) { ob.set("archivedBy", this.archivedBy) } else {ob.unset("archivedBy")}
                if (this.archivedByInfo && this.isArchived) { ob.set("archivedByInfo", removeKeysFromUserInfo_Obj(this.archivedByInfo)) } else {ob.unset("archivedByInfo")}

                if (this.completedAt ) { ob.set("completedAt", this.completedAt) } else { ob.unset("completedAt") }
                if (this.completedBy ) { ob.set("completedBy", this.completedBy) } else { ob.unset("completedBy") }
                if (this.completedByInfo ) { ob.set("completedByInfo", this.completedByInfo) } else { ob.unset("completedByInfo") }

                ob.set("color", this.color)


                ob.save()
                callback(true, this, '')
            }, (error) => {
                callback(false, null, 'Error =' + error.message)
            });


    }


    createNote = (userInfo, note, existingId) => {
        let finalObj =  {
            id: existingId ?? randomCode(6),
            byUserInfo: userInfo,
            createdAt: `${moment().unix()}` ,
            note: note
        }
        // console.log("FINAL OBJ")
        // console.log(finalObj)

        return finalObj
    }

    addNote = (noteObj, errorCallback) => {
        var query = new Parse.Query(EventClass);
        let noteObjString = cypherNoteObjToString(noteObj)

        let cu = currentUserInfo()
        let notifyUsers = [...new Set( [this.byUser, ...this.assignedTo, ...this.sharedWith])]


        query.get(this.id)
            .then((ob) => {
                ob.addUnique("notes", noteObjString)
                ob.save()
                Notification.addNotification([], `Event:${this.name}`, `${cu.name} added a note to Event: ${this.name}`, notifyUsers, null, null, null, null, null, null, null, null, [this.id], "", null, `Event=${this.id}`, null, NotificationFor.event_addedNote)

            }).catch((error) => {
                errorCallback('Error while adding note in task =' + error.message)
            });
    }

    removeNote = (noteObj, errorCallback) => {
        var query = new Parse.Query(EventClass);
        let noteObjString = cypherNoteObjToString(noteObj)

        // console.log("TRYING TO DELETE THIS NOTE STRING")
        // console.log(noteObjString)

        query.get(this.id)
            .then((ob) => {
                ob.remove("notes", noteObjString)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing note from task =' + error.message)
            });
    }

    addAssignTo_multiple = (userIds, userInfos, errorCallback) => {
        var query = new Parse.Query(EventClass);
        var usrInfos =  userInfos.map((u) => removeKeysFromUserInfo_Obj(u, true, true, true)) 
        
        let cu = currentUserInfo()
        
        query.get(this.id)
            .then((ob) => {
                ob.addAllUnique("assignedTo", userIds)
                ob.addAllUnique("assignedToInfo", usrInfos)
                ob.save()

                Notification.addNotification([], `Event:${this.name}`, `${cu.name} assigned you an Event: ${this.name}`, [...userIds], null, null, null, null, null, [...userIds], null, null, [this.id], "", null, `Event=${this.id}`, null, NotificationFor.event_assignedToYou)


            }).catch((error) => {
                errorCallback('Error while adding assignTo user in task =' + error.message)
            });
    }

    removeAssignTo = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(EventClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)        

        query.get(this.id)
            .then((ob) => {
                ob.remove("assignedTo", userId)
                ob.remove("assignedToInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing assignTo user in task =' + error.message)
            });
    }

    addSharedWith_multiple = (userIds, userInfos, errorCallback) => {
        var query = new Parse.Query(EventClass);
        var usrInfos = userInfos.map((u) => removeKeysFromUserInfo_Obj(u, true, true, true))        
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        let cu = currentUserInfo()

        query.get(this.id)
            .then((ob) => {
                ob.addAllUnique("sharedWith", userIds)
                ob.addAllUnique("sharedWithInfo", usrInfos)
                ob.save()
                Notification.addNotification([], `Event:${this.name}`, `${cu.name} shared an event with you. Event: ${this.name}`, [...userIds], null, null, null, null, null, [...userIds], null, null, [this.id], "", null, `Event=${this.id}`, null, NotificationFor.event_sharedWithYou)

            }).catch((error) => {
                errorCallback('Error while adding assignTo user in task =' + error.message)
            });
    }

    removeSharedWith = (userId, userInfo, errorCallback) => {
        var query = new Parse.Query(EventClass);
        var usrInfo = removeKeysFromUserInfo_Obj(userInfo, true, true, true)        
        // var usrInfo = ({ ...userInfo })
        // if (usrInfo.hasOwnProperty("isSelected")) {
        //     delete usrInfo.isSelected;
        // }

        query.get(this.id)
            .then((ob) => {
                ob.remove("sharedWith", userId)
                ob.remove("sharedWithInfo", usrInfo)
                ob.save()
            }).catch((error) => {
                errorCallback('Error while removing assignTo user in task =' + error.message)
            });
    }





    delete = (callback) => {
        if (this.id) {
            callback(false, "Error : No id to delete Event ")
        }
        let thisObj = new EventClass()
        thisObj.id = this.id

        thisObj.destroy().then((myObject) => {
            callback(true, '')
        }, (error) => {
            callback(false, 'Error while deleting event ', error.message)
        });
    }


    static getAll = (assignedTo, withMember, forAssetId, forProjectId, fromData, toDate, filter, sort, callback) => {
        console.log('Searching For Event ')
        var query = new Parse.Query(EventClass);


        // Must have rules
        // query.equalTo("project", projectPointer(forProjectId))


        query.equalTo("workspace", workspacePointer(getCurrentWorkspaceId()))


        // Apply pagination
        query.limit(100)

        // Apply filter
        // query.notEqualTo('isArchived', true)  // not fetching archived 

        // Apply sort
        query.descending('createdAt')

        if (forAssetId) {
            // query.containedIn("assetsIds", forAssetId)
            // console.log(`ASSET ID IS ${forAssetId}`)
            query.equalTo("assetsIds", forAssetId)
        }

        if (forProjectId) {
            query.equalTo("projectsIds", forProjectId)
        }


        query.find().then((objects) => {
            // console.log('All Projects =')
            // console.log(objects)
            if (objects.length > 0) {
                let allObjects = []
                for (let i = 0; i < objects.length; i++) {
                    const thisObj = objects[i];
                    let thisConvertedObject = Event.initFromPFObject(thisObj)
                    if (thisConvertedObject !== null) {
                        allObjects.push(thisConvertedObject)
                    }
                }
                callback(true, allObjects, '')
            } else {
                callback(true, [], 'No Events found  ')
            }

        }, (error) => {
            callback(false, [], error.message)
        })
    }



}



