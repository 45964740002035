import React, { useEffect, useState, useRef } from 'react'
import { CommonEntityFilter, doSelectionsContain, folderOptions, ProjectFolderLevel, SelectableEntity } from '../../Models/EnumsAndPointers'
import { Project } from '../../Models/Project'
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';
import { FiChevronRight } from 'react-icons/fi';
import { Asset } from '../../Models/Asset';
import LoadingAux from '../../Containers/LoadingAux';
import CommentExplorer from './CommentExplorer';



const AssetExplorer = (props) => {

    const [assets, setAssets] = useState([])
    const [selectedAsset, setSelectedAsset] = useState(null)
    const [isFetching, setIsFetching] = useState(false)

    const { projectId, currentFolder } = props

    const headerRef = useRef(null)

    const scrollToTop = () => {

        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.scrollIntoView({ behavior: 'instant', block: 'start' })
            } else {
                const element = document.getElementById('assetsExpTop')
                if (element) {
                    element.scrollIntoView({ behavior: 'instant', block: 'start' });
                }
            }
        }, 500)
    };


    useEffect(() => {
        if (currentFolder === ProjectFolderLevel.assets && selectedAsset !== null) {
            setSelectedAsset(null)
        }
    }, [currentFolder, selectedAsset])

    // list folders
    // go into semifolders
    // show select on last screens

    useEffect(() => {
        let isMounted = true;
        setIsFetching(true)

        console.log("GETTING ASSETS OF PROJECT ID = " + projectId)

        Asset.getAll(projectId, null, null, (succ, results, errMsg) => {
            if (isMounted) {
                setIsFetching(false)
            }

            if (succ) {
                if (isMounted) {
                    setAssets(results)
                    scrollToTop()
                }
            } else {
                console.log(errMsg)
            }
        })

        return () => (isMounted = false)
    }, [projectId])






    const clearSelections = () => {
        setSelectedAsset(null)
    }


    const assetSelected = (ast) => {
        setSelectedAsset(ast)
        props.folderLevelChanged(ProjectFolderLevel.comments, "Comments of - " + ast.name)
    }




    const row = (opt, hasImage, isSelectable) => {

        let selectedInfo = doSelectionsContain(props.selectedEntities, ProjectFolderLevel.assets, opt)


        // return <tr key={opt.name} className=" my-0  " onClick={() => { optSelectionToggled(opt) }} >
        return <tr key={opt.id} className=" my-0  is-fullwidth " >

            {
                hasImage ?


                    <td align="left" className=" w10Perc " >
                        <Box shadowless className='m-0 p-0 is-clearfix'>
                            {/* <Image className="ml-2 mt-2">{opt.name}</Image> */}
                            <Image size={48} src={opt.url ?? "https://images.unsplash.com/photo-1627482965115-e8e29bbcd451?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"} className=" ml-0 mt-1 overflow-hidden is-bordered border-radius-6 " />

                        </Box>
                    </td>

                    :
                    null
            }


            <td align="left" className="minW200  " >
                <p className="ml-2 mt-2 is-size-7 ">{opt.name === " " ? "Un-nanmed" : opt.name} </p>
            </td>


            <td align="right  " className='m-0 pr-0 is-narrow '   >
                {
                    selectedInfo.isSelected ?
                        <Button size="small" color='black' rounded className='m-0 mt-2 is-right' onClick={() => props.entityRemoved(ProjectFolderLevel.assets, opt)} >Added</Button>
                        :
                        <>
                            {
                                isSelectable ?
                                    <Button size="small" rounded className='m-0 mt-2 is-right' onClick={() => props.entitySelected(ProjectFolderLevel.assets, opt, projectId)} >Add</Button>
                                    :
                                    null
                            }
                        </>

                }
            </td>

            <td align="right " className='m-0 pl-0 pr-1 is-narrow' >
                <Button size="small" type="text" className=" mt-2  has-background-transparent is-borderless has-text-grey" onClick={() => assetSelected(opt)}  >
                    Comments
                    <span className=' is-size-5 has-text-black has-text-weight-bold mx-2'>{selectedInfo.hasSelectedChildren ? "•" : null}</span>
                    <FiChevronRight className=" is-size-6 " />
                </Button>
            </td>



        </tr>
    }


    const isSelectable = () => {
        let slable = { allowed: false, multiple: false }
        if (props.selectables) {
            let entities = props.selectables.entities ?? []
            let multiSelection = props.selectables.multiSelection
            if (entities.includes(SelectableEntity.assets)) {
                slable.allowed = true
            }
            if (multiSelection) {
                slable.multiple = true
            }
        }
        return slable
    }

    const up = () => {

        let iSLA = isSelectable()
        let allowSelection = isSelectable().allowed

        if (iSLA.multiple === false) {
            if (props.selectedEntities) {
                if (props.selectedEntities.assets) {
                    if (props.selectedEntities.assets.length) {
                        allowSelection = false
                    }
                }
            }
        }

        let showAssets = assets

        if (props.showSelectedOnly){
            let selectedAssts = props.selectedEntities.assets ?? []
            let selectedAsstIds = selectedAssts.map(p => p.id)
            showAssets = assets.filter((p) => selectedAsstIds.includes(p.id))
        } else{
            if (props.statusFilter){
                if (props.statusFilter !== CommonEntityFilter.all){
                    showAssets = showAssets.filter((p) => p.status === props.statusFilter)
                }
            }
        }

        return <div>

            <Panel className="m-0 is-shadowless ">
                <Table className="minW300 is-fullwidth h-100vh" hoverable={false} striped={false} bordered={false}  >
                    <tbody className='is-fullheight' >
                        {
                            showAssets.length && assets.length > 0 ?
                            showAssets.map((opt) => {
                                    return row(opt, true, allowSelection)
                                })
                                :
                                <p className=' has-text-centered my-6 has-text-grey-light' > No {props.showSelectedOnly ? "Selected " : null}Assets </p>

                        }
                    </tbody>
                </Table>
            </Panel>
        </div>
    }

    const beforeClose = () => {
        props.close()
    }


    const modal = () => {

        //  
        return selectedAsset ? <CommentExplorer {...props} assetId={selectedAsset.id} projectId={projectId} close={props.close} /> :
            <LoadingAux isLoading={isFetching} fullHeight={true} >
                <div ref={headerRef} id="assetsExpTop"></div>
                {up()}
            </LoadingAux>
    }




    return modal()
}

export default AssetExplorer