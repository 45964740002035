import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Container, Heading, Section, Content, Tile, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon, Dropdown } from 'react-bulma-components';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import moment from 'moment'
import { randomCode } from '../../utilities/Utilities';
import { GrCaretPrevious, GrCaretNext } from 'react-icons/gr';
import EventModal from './EventModal';
import { Event } from '../../Models/Event';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { EventCompletionStatus, EventFilterType } from '../../Models/EnumsAndPointers';


var randomColor = require('randomcolor'); // import the script




const Cal = (props) => {

  const [events, setEvents] = useState([])
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  // const allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k])
  // const DnDCalendar = withDragAndDrop(Calendar);
  // const [eventModal, setEventModal] = useState(false)
  const [newEvent, setNewEvent] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [currentCalView, setcurrentCalView] = useState('month')
  const [eventFilter, setEventFilter] = useState(EventFilterType.all)

  useEffect(() => {

    let isMounted = true;

    console.log("Getting events")
    Event.getAll(null, null, null, null, null, null, null, null, (succ, results, errMsg) => {
      if (succ) {
        console.log("Got Events")
        console.log(results)
        if (isMounted) {
          setEvents(results)

        }
      } else {
        console.log(errMsg)
      }
    })
    return () => (isMounted = false)

  }, [])

  const eventsForTheDate = (allEvents, selectedDate) => {
    let selectedDateFormated = moment(selectedDate, "DD-MM-YYYY")
    let thisDayEvents = allEvents.filter((e) => {
      return selectedDateFormated.isSame(e.starts, 'day')
    })
    return thisDayEvents ?? []
  }

  const findFirstFreeTime = (eventsOfTheDay, bufferMinutes) => {

    let sortedArr = eventsOfTheDay.sort((a, b) => {
      return moment(a.starts).valueOf - moment(b.starts).valueOf
    })

    // let firstElement = sortedArr[0]
    let lastElement = sortedArr[sortedArr.length - 1]

    // console.log("FIRST ELEMENT IS")
    // console.log(firstElement)

    // console.log("LAST ELEMENT IS")
    // console.log(lastElement)

    let lastTime = lastElement.starts
    let newTime = moment(lastTime).add(bufferMinutes, 'minutes')
    return newTime.toDate()
  }


  const slotSelected = (pl) => {

    // alert("EVENT IS CREATED")
    let { start, end, action } = pl
    // console.log("Slot is selected")
    // console.log(pl)
    // action can be click or select
    // if (action !== "select") {
    //   alert("Select & drag on timeline to create an event slot.")
    //   return
    // }

    let ev = new Event()
    ev.byUser = isCurrentUser().id
    ev.byUserInfo = currentUserInfo()
    ev.createdAt = new Date()

    // If it is month view, and there are already other events on this day. Make the default time 45 min after the last existing event starts to prevent overlap in week & dat view
    if (currentCalView === 'month') {
      let existingEventsOfDay = eventsForTheDate(events, start)
      // console.log("EVENTS FOR THIS DAY")
      // console.log(existingEventsOfDay)
      if (existingEventsOfDay.length) {
        let firstFreeTime = findFirstFreeTime(existingEventsOfDay, 45)
        // console.log("START was")
        // console.log(start)
        start = firstFreeTime
      }
    }

    ev.starts = start
    ev.ends = end
    ev.color = randomColor({ luminosity: 'light' })


    setNewEvent(ev)



    // let newEv = {
    //   id: randomCode(6),
    //   starts: starts,
    //   ends: ends,
    //   allDay: false,
    //   name: "Untitled"
    // }

    // let existingEv = [...events]
    // existingEv.push(newEv)
    // setEvents(existingEv)
    // console.log("Event is added")
    // setEventModal(!eventModal)
  }


  const clearSelections = () => {
    setNewEvent(null)
    setSelectedEvent(null)
  }

  const newEventAdded = (ev) => {
    let evs = [...events]
    evs.push(ev)
    setEvents(evs)
  }

  const eventIsUpdated = (ev) => {
    let evs = [...events]

    let index = evs.findIndex((e) => {
      return e.id === ev.id
    })

    if (index >= 0) {
      evs[index] = ev
      setEvents(evs)
    }

  }


  const eventSelected = (pl) => {
    console.log("Slot is selected")
    console.log(pl)
    setSelectedEvent(pl)

  }


  const navigationChanged = (pl) => {
    console.log("Navigation is changed")
    console.log(pl)
  }

  const viewChanged = (pl) => {
    console.log("View is changed")
    console.log(pl)
    setcurrentCalView(pl)
  }

  const showMorePressed = (pl) => {
    console.log("Show more pressed")
    console.log(pl)
    alert("Show more pressed")
  }



  const elementProps = date => {
    var style = {
      fontSize: '16px',
      fontWeight: '500px',
      // marginTop:'4px',
      // backgroundColor: '#f9f9f9',
      // borderRadius: '0px',
      // opacity: 0.8,
      // color: 'black',
      // border: '0px',
      // display: 'block'
      height: '60px',
      // width:'140px'
    }
    return {
      style: style
    }
  }

  const customDayPropGetter = date => {
    // if (date.getDate() === 7 || date.getDate() === 15)
    //   return {
    //     // className: 'special-day',
    //     style: {
    //       border: 'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa'),
    //     },
    //   }
    // else 
    return {}
  }


  // const customSlotPropGetter = date => {
  //   if (date.getDate() === 7 || date.getDate() === 15)
  //     return {
  //       className: 'special-day',
  //     }
  //   else return {}
  // }

  const customSlotPropGetter = date => {
    var style = {
      fontSize: '12px',
      // marginTop:'4px',
      // backgroundColor: '#f9f9f9',
      // borderRadius: '0px',
      // opacity: 0.8,
      // color: 'black',
      // border: '0px',
      // display: 'block'
      height: '50px',
      // width:'140px'
    }
    return {
      style: style
    }
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    console.log(event);
    // const backgroundColor = '#' + event.hexColor;

    // event: Object,
    // start: Date,
    // end: Date,
    // isSelected: boolean


    const backgroundColor = event.color ?? (event.colorHex ?? randomColor({ luminosity: 'light' }))


    var style = {
      fontSize: '12px',
      fontWeight: "500",
      // paddingBottom:'4px',
      backgroundColor: backgroundColor,
      opacity: 0.69,
      color: 'black',
      border: '0px',
      // display: 'block'
    }
    return {
      style: style
    }
  }


  const provideTitleAccessor = ({ allDay, starts, ends, name }) => {

    // console.log("Provide Title Accessor")
    // console.log(pl)

    return <div>
      <p className="  ">
        {name}
      </p>
    </div>
  }

  const provideResourceTitleAccessor = ({ allDay, starts, ends, title }) => {

    // console.log("Provide Title Accessor")
    // console.log(pl)

    return <div>
      <span className=" has-text-info ">
        {title}
      </span>
    </div>
  }


  const eventFilterDropdownChange = (val) => {
    setEventFilter(val)
    // Filter All Events now

  }



  const myCalendar = (filteredEvents) => (
    <Calendar
      className="is-fullheight mt-0 pt-0"

      step={30}
      selectable={true}

      localizer={localizer}
      events={filteredEvents}
      showAllEvents
      // views={['month', 'week', 'work_week', 'day', 'agenda']}
      views={['month', 'week', 'day']}
      drilldownView='day'
      getDrilldownView={(targetDate, currentViewName, configuredViewNames) => {
        if (currentViewName === 'month' && configuredViewNames.includes('week'))
          return 'week'

        return null;
      }}



      defaultView={Views.MONTH}
      // scrollToTime={new Date(1970, 1, 1, 6)}
      defaultDate={new Date()}
      onSelectEvent={event => eventSelected(event)}
      onSelectSlot={pl => slotSelected(pl)}
      onNavigate={pl => navigationChanged(pl)}
      onView={pl => viewChanged(pl)}
      // onShowMore={pl => showMorePressed(pl)}
      dayPropGetter={customDayPropGetter}
      slotPropGetter={customSlotPropGetter}
      eventPropGetter={eventStyleGetter}
      elementProps={elementProps()}

      titleAccessor={pl => provideTitleAccessor(pl)}
      tooltipAccessor="name"


      startAccessor="starts"
      endAccessor="ends"
    // popup={true}
    // popupOffset={{x: 30, y: 20}}
    // resizable
    // tooltipAccessor={(e) => e.title}
    //   components={
    //     {
    //        toolbar:  (props) => { <CustomToolBar {...props} /> } 
    //     }
    // }



    />


  )



  const getFilteredEvents = () => {

    let finalEvents = events

    if (eventFilter === EventFilterType.pending) {
      finalEvents = events.filter((e) => e.status === EventCompletionStatus.pending)
    }

    if (eventFilter === EventFilterType.complete) {
      finalEvents = events.filter((e) => e.status === EventCompletionStatus.complete)
    }

    if (eventFilter === EventFilterType.archived) {
      finalEvents = events.filter((e) => e.isArchived === true)
    }

    return finalEvents

  }


  return <div>
    <Container className="mt-2 overflow-scroll">
      <Columns gap={0} className="p-0 m-0">
        <Columns.Column size={1} className="is-hidden-touch">
        </Columns.Column>
        <Columns.Column className=" h-85vh pb-5">
          {/* <Heading > Calendar Component </Heading> */}
          {newEvent ? <EventModal isNewEvent={true} event={newEvent} newEventAdded={newEventAdded} close={clearSelections} /> : null}
          {selectedEvent ? <EventModal isNewEvent={false} event={selectedEvent} eventIsUpdated={eventIsUpdated} close={clearSelections} /> : null}

          <Columns vCentered>
            <Columns.Column size={4} className="">
              <Form.Field>
                {/* <Form.Label className="is-size-7 mb-0 ml-2 " textWeight="semibold"  >Events</Form.Label> */}
                <Form.Control>
                  <Form.Select
                    size='small'
                    value={eventFilter}
                    className=" mt-0  pt-0 "
                    onChange={(e) => {
                      eventFilterDropdownChange(e.target.value)
                    }}
                  >
                    <option value={EventFilterType.all}>{EventFilterType.all} Events</option>
                    <option value={EventFilterType.pending}>{EventFilterType.pending} Events</option>
                    <option value={EventFilterType.complete}>{EventFilterType.complete} Events</option>
                    <option value={EventFilterType.archived}>{EventFilterType.archived} Events</option>
                  </Form.Select>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column size={4} className="has-text-centered">
              <p className='has-text-grey-light is-size-7'> Tap anywhere to create event </p>
            </Columns.Column>
            <Columns.Column size={4} className="">
            </Columns.Column>
          </Columns>

          {myCalendar(getFilteredEvents())}
        </Columns.Column>
        <Columns.Column size={1} className="is-hidden-touch">
        </Columns.Column>
      </Columns>
    </Container>
  </div>

}


export default Cal;




const CustomToolBar = (props) => {

  return <div className="rbc-btn-group">
    <Button
      className="toolbar-navigation-button"
      type="button"
      onClick={() => props.onNavigate ** ('PREV')}
    >
      <Icon
        className="navigate-icon"
        icon={this.props.isRTL ? <GrCaretNext /> : <GrCaretPrevious />}
      />
    </Button>
    <span
      className="today-label"
      onClick={() => props.onNavigate('TODAY')}
    >
      {/* {i18n('calendar-today')} */}
    </span>
    <Button
      className="toolbar-navigation-button"
      type="button"
      onClick={() => props.onNavigate('NEXT')}
    >
      <Icon
        className="navigate-icon"
        icon={this.props.isRTL ? <GrCaretNext /> : <GrCaretPrevious />}
      />
    </Button>
  </div>


}