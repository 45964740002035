import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Modal, Panel, Columns, Button, Container, Table, Box, Form, Tabs, Heading, Image, Tag } from 'react-bulma-components';
import { Invitation } from '../../Models/Invitation'
import { randomCode, replaceUnderscoreWith, validateEmail } from '../../utilities/Utilities'

import { FcInvite } from 'react-icons/fc';
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'
import { BiMailSend } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';
import { getCurrentWorkspaceId, getShowInvitationReminderToAdmin, setShowInvitationIfNeeded } from '../../utilities/Login';
import { filterTabs, previewFilter, previewComponents, getPreviewFilter, previewType, SocialMedia_Platform, thumbnailArt_for_SocailMedia_Platform } from '../../Models/EnumsAndPointers';
import ColumnAux from '../../Containers/ColumnAux';
import VisualiseModal from '../Visualise/VisualiseModal';




// Thumbnail Images
import Instagram_User_DP from '../../assets/Preview/Instagram_User_DP.png'
import Facebook_User_DP from '../../assets/Preview/Facebook_User_DP.png'
import Facebook_Company_DP from '../../assets/Preview/Facebook_Company_DP.png'
import LinkedIn_User_DP from '../../assets/Preview/LinkedIn_User_DP.png'
import LinkedIn_Company_DP from '../../assets/Preview/LinkedIn_Company_DP.png'
import LinkedIn_Event_DP from '../../assets/Preview/LinkedIn_Event_DP.png'
import Twitter_User_DP from '../../assets/Preview/Twitter_User_DP.png'

import Facebook_User_Cover from '../../assets/Preview/Facebook_User_Cover.png'
import Facebook_Company_Cover from '../../assets/Preview/Facebook_Company_Cover.png'
import LinkedIn_User_Cover from '../../assets/Preview/LinkedIn_User_Cover.png'
import LinkedIn_Company_Cover from '../../assets/Preview/LinkedIn_Company_Cover.png'
import LinkedIn_Event_Cover from '../../assets/Preview/LinkedIn_Event_Cover.png'
import Twitter_User_Cover from '../../assets/Preview/Twitter_User_Cover.png'

import Youtube_Profile_Cover from '../../assets/Preview/Youtube_Profile_Cover.png'
import Youtube_Profile_DP from '../../assets/Preview/Youtube_Profile_DP.png'
import Youtube_Video_Thumbnail from '../../assets/Preview/Youtube_Video_Thumbnail.png'

import Instagram_Post from '../../assets/Preview/Instagram_Post.png'
import Facebook_Post from '../../assets/Preview/Facebook_Post.png'
import LinkedIn_Post from '../../assets/Preview/LinkedIn_Post.png'
import Twitter_Post from '../../assets/Preview/Twitter_Post.png'

import Common_Story from '../../assets/Preview/Common_Story.png'




const PreviewModal = (props) => {


    // Pinned, All, Posts, LinkedIn, Facebook, Instagram, Twitter, Profile, Company, Event, ProfilePic, CoverPic


    const [currentTab, setCurrentTab] = useState(props.pinned ? previewFilter.pinned : previewFilter.all)
    const [selectedPreviewComp, setSelectedPreviewComp] = useState(null)


    const toggleTab = (tb) => {
        setCurrentTab(tb)
    }

    const allPreviewComps = () => {
        let comps = previewComponents().map((p) => {
            p.displayName = replaceUnderscoreWith(p.type, " ")
            return p
        })
        return comps
    }

    const clearChildModal = () => {
        setSelectedPreviewComp(null)
    }

    const selectPreviewComp = (comp) => {
        setSelectedPreviewComp(comp)
    }



    const imageSrcForPreviewType = (type) => {
        let src = ""

        if (type === previewType.instagram_Profle_Image) {
            src = Instagram_User_DP
        }
        if (type === previewType.facebook_User_Profle_Image) {
            src = Facebook_User_DP
        }
        if (type === previewType.facebook_Page_Profle_Image) {
            src = Facebook_Company_DP
        }
        if (type === previewType.linkedIn_User_Profle_Image) {
            src = LinkedIn_User_DP
        }
        if (type === previewType.linkedIn_Company_Profle_Image) {
            src = LinkedIn_Company_DP
        }
        if (type === previewType.linkedIn_Event_Profle_Image) {
            src = LinkedIn_Event_DP
        }
        if (type === previewType.twitter_Profle_Image) {
            src = Twitter_User_DP
        }


        if (type === previewType.facebook_User_Cover_Image) {
            src = Facebook_User_Cover
        }
        if (type === previewType.facebook_Page_Cover_Image) {
            src = Facebook_Company_Cover
        }
        if (type === previewType.linkedIn_User_Cover_Image) {
            src = LinkedIn_User_Cover
        }
        if (type === previewType.linkedIn_Company_Cover_Image) {
            src = LinkedIn_Company_Cover
        }
        if (type === previewType.linkedIn_Event_Cover_Image) {
            src = LinkedIn_Event_Cover
        }
        if (type === previewType.twitter_Cover_Image) {
            src = Twitter_User_Cover
        }


        if (type === previewType.instagram_Post) {
            src = Instagram_Post
        }
        if (type === previewType.facebook_Post) {
            src = Facebook_Post
        }
        if (type === previewType.linkedIn_Post) {
            src = LinkedIn_Post
        }
        if (type === previewType.twitter_Post) {
            src = Twitter_Post
        }

        if (type === previewType.youtube_Cover_Image) {
            src = Youtube_Profile_Cover
        }
        if (type === previewType.youtube_Profile_Image) {
            src = Youtube_Profile_DP
        }
        if (type === previewType.youtube_Post) {
            src = Youtube_Video_Thumbnail
        }


        let allStores = [previewType.linkedIn_Story, previewType.facebook_Story, previewType.instagram_Story, previewType.twitter_Story]
        if (allStores.includes(type)) {
            src = Common_Story
        }

        return src

        // instagram_Profle_Image: "Instagram_Profle_Image",
        // instagram_Post: "Instagram_Post",
        // instagram_Story: "Instagram_Story",
        // facebook_User_Profle_Image: "Facebook_User_Profle_Image",
        // facebook_User_Cover_Image: "Facebook_User_Cover_Image",
        // facebook_Page_Profle_Image: "Facebook_Page_Profle_Image",
        // facebook_Page_Cover_Image: "Facebook_Page_Cover_Image",
        // facebook_Group_Profle_Image: "Facebook_Group_Profle_Image",
        // facebook_Group_Cover_Image: "Facebook_Group_Cover_Image",
        // facebook_Post: "Facebook_Post",
        // facebook_Story: "Facebook_Story",
        // linkedIn_User_Profle_Image: "LinkedIn_User_Profle_Image",
        // linkedIn_User_Cover_Image: "LinkedIn_User_Cover_Image ",
        // linkedIn_Company_Profle_Image: "LinkedIn_Company_Profle_Image",
        // linkedIn_Company_Cover_Image: "LinkedIn_Company_Cover_Image",
        // linkedIn_Event_Profle_Image: "LinkedIn_Event_Profle_Image",
        // linkedIn_Event_Cover_Image: "LinkedIn_Event_Cover_Image",
        // linkedIn_Post: "LinkedIn_Post",
        // linkedIn_Story: "LinkedIn_Story",
        // twitter_Profle_Image: "Twitter_Profle_Image",
        // twitter_Cover_Image: "Twitter_Cover_Image ",
        // twitter_Post: "Twitter_Post",
        // twitter_Story: "Twitter_Story",

    }

    const tabContent = () => {
        // let tabContent = []

        let allComps = allPreviewComps()

        let filterObjForCurrenTab = getPreviewFilter(currentTab)

        let filteredComps = allComps.filter((p) => {
            // selectedPreview
            // var filterObj = {
            //     "type": null,
            //     "boxtype": null,
            //     "category": null,
            //     "pageCategory": null,
            //     "channel": null
            // }
            if (filterObjForCurrenTab.type) {
                return p.type === filterObjForCurrenTab.type
            }
            if (filterObjForCurrenTab.boxtype) {
                return p.boxtype === filterObjForCurrenTab.boxtype
            }
            if (filterObjForCurrenTab.category) {
                return p.category === filterObjForCurrenTab.category
            }
            if (filterObjForCurrenTab.pageCategory) {
                return p.pageCategory === filterObjForCurrenTab.pageCategory
            }
            if (filterObjForCurrenTab.channel) {
                return p.channel === filterObjForCurrenTab.channel
            }

            return true
        })



        // group by channels 

        let allChannels = []

        // linkedIn
        let li = { "name": SocialMedia_Platform.linkedIn, comps: [] }
        let liObjs = filteredComps.filter((c) => {
            return c.channel === SocialMedia_Platform.linkedIn
        })
        if (liObjs.length) {
            li.comps = liObjs
            allChannels.push(li)
        }

        // facebook
        let fb = { "name": SocialMedia_Platform.facebook, comps: [] }
        let fbObjs = filteredComps.filter((c) => {
            return c.channel === SocialMedia_Platform.facebook
        })
        if (fbObjs.length) {
            fb.comps = fbObjs
            allChannels.push(fb)
        }

        // instagram
        let inst = { "name": SocialMedia_Platform.instagram, comps: [] }
        let instObjs = filteredComps.filter((c) => {
            return c.channel === SocialMedia_Platform.instagram
        })
        if (instObjs.length) {
            inst.comps = instObjs
            allChannels.push(inst)
        }

        // twitter
        let twt = { name: SocialMedia_Platform.twitter, comps: [] }
        let twtObjs = filteredComps.filter((c) => {
            return c.channel === SocialMedia_Platform.twitter
        })
        if (twtObjs.length) {
            twt.comps = twtObjs
            allChannels.push(twt)
        }


        // youtube
        let ytb = { name: SocialMedia_Platform.youtube, comps: [] }
        let ytbObjs = filteredComps.filter((c) => {
            return c.channel === SocialMedia_Platform.youtube
        })
        if (ytbObjs.length) {
            ytb.comps = ytbObjs
            allChannels.push(ytb)
        }





        return allChannels.map((c) => {


            return <Box shadowless className=" mx-0 mb-4 px-0 pb-0 has-background-transparent ">

                <Columns className=" ml-0 pl-0  mb-0 has-text-weight-normal is-mobile" >
                    <Columns.Column size={1} className="px-0 has-text-right">
                        <span className=" "> {thumbnailArt_for_SocailMedia_Platform(c.name, true, " ")} </span>
                    </Columns.Column>
                    <Columns.Column className="px-0">
                        <Heading size={6} className="ml-2  has-text-grey-light mt-1">{c.name}</Heading>
                    </Columns.Column>
                </Columns>

                <Box shadowless className="mt-0 pt-0 px-2 has-background-transparent">
                    <Tag.Group >
                        {
                            c.comps.map((p) => {
                                return <Box key={p.type} className=" preview-thumbnail-item boxLast0 has-text-centered my-2 has-background-transparent" onClick={() => { selectPreviewComp(p) }}>
                                    <img alt="Preview thumbnail" size={64} src={imageSrcForPreviewType(p.type)} />
                                    <p className=" is-size-8 has-text-grey has-text-weight-semibold "> {p.displayName} </p>
                                </Box>
                            })
                        }
                    </Tag.Group>
                </Box>


                {/* <Box shadowless  className=" preview-thumbnail-container pl-4 py-2 m-0 overflow-scroll">
                    {
                        c.comps.map((p) => {
                            return <Box key={p.type} className="preview-thumbnail-item boxLast0" onClick={() => { selectPreviewComp(p) }}>
                                <Image size={64} src={imageSrcForPreviewType(p.type)} />
                                <Heading subtitle size={7}> {p.displayName} </Heading>
                            </Box>
                        })
                    }
                </Box> */}
            </Box>


            // filteredComps.map((p) => {
            //     return <Box key={p.type} className="has-text-left" onClick={() => { selectPreviewComp(p) }}>
            //         <Image size={64} src={imageSrcForPreviewType(p.type)} />
            //         <Heading size={7}> {p.displayName} </Heading>

            //     </Box>
            // })

        })





        // return tabContent
    }


    // LinkedIn
    // Post, Profile, Company, Event

    // Facebook
    // Post, Profile, Company

    // Instagram
    // Post, Profile

    // Twitter
    // Post, Profile

    const tabForFiltertab = (filterTab) => {
        return <Tabs.Tab active={currentTab === filterTab} onClick={() => { toggleTab(filterTab) }}> {filterTab}</Tabs.Tab>
    }

    const modal = () => {

        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className=" h-90vh  m-0 p-0" radiusless>
                    <Modal.Card.Header showClose className="    ">
                        <Modal.Card.Title className="has-text-grey-light" >Choose Preview</Modal.Card.Title>
                    </Modal.Card.Header>
                    {
                        selectedPreviewComp ?
                            <VisualiseModal asset={props.asset} selectedPreview={selectedPreviewComp} close={clearChildModal} />
                            : null
                    }
                    <Modal.Card.Body className="p-0 overflow-x-hidden has-background-grey-lightest">
                        <Panel className=" stick-to-top z-30">
                            {/* <Panel.Header>Repositories</Panel.Header> */}
                            <Tabs size="small" backgroundColor="light" textWeight="semibold" className=" is-size-6p7  " >

                                <Tabs.Tab active={currentTab === previewFilter.all} onClick={() => { toggleTab(previewFilter.all) }}> {previewFilter.all}</Tabs.Tab>



                                {
                                    props.pinned ?
                                        tabForFiltertab(previewFilter.pinned)
                                        : null
                                }
                                {/* {tabForFiltertab(previewFilter.all)} */}
                                {tabForFiltertab(previewFilter.posts)}
                                {tabForFiltertab(previewFilter.linkedIn)}
                                {tabForFiltertab(previewFilter.facebook)}
                                {tabForFiltertab(previewFilter.instagram)}
                                {tabForFiltertab(previewFilter.twitter)}
                                {tabForFiltertab(previewFilter.youtube)}
                                {tabForFiltertab(previewFilter.profile)}
                                {tabForFiltertab(previewFilter.company)}
                                {tabForFiltertab(previewFilter.event)}
                                {tabForFiltertab(previewFilter.profilePic)}
                                {tabForFiltertab(previewFilter.cover)}
                            </Tabs>
                        </Panel>

                        <div className="is-fullwidth ">
                            {
                                tabContent()
                            }
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>


                    </Modal.Card.Body>
                    <Modal.Card.Footer className="py-1 px-4 has-background-grey-lighter " >
                        <Box shadowless className="has-background-transparent p-0">
                            <Image
                                size={64}
                                alt="picked asset"
                                src={props.asset.url}
                                className="squarePic80 is-borderless has-background-transparent is-centered ml-2"
                            />
                        </Box>

                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }



    return modal()

}

export default PreviewModal