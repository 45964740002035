import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Modal, Table, Section, Panel, Dropdown, Box, Breadcrumb, Tabs, Button, Columns, Image, Form, Icon } from 'react-bulma-components';
import UserAvatar from './UserAvatar';
import { CgRadioCheck, CgCheckO, CgCloseO } from 'react-icons/cg';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { UserPickerFor } from '../../Models/EnumsAndPointers'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';
import { randomCode } from '../../utilities/Utilities';
import { Workspace } from '../../Models/Workspace';
import LoadingAux from '../../Containers/LoadingAux';
import InvitationModal from '../Invitation/InvitationModal'
import { Project } from '../../Models/Project';
import { UserRole } from '../../Models/EnumsAndPointers'
import { canInviteMembers } from '../../utilities/TpRules'
import { MdMail } from 'react-icons/md';



// ImCheckboxUnchecked
// ImCheckboxChecked

// CgCloseO





const UserPicker = (props) => {

    const sampleUsers = [
        {
            name: "Beth Castillo",
            imageUrl: "https://randomuser.me/api/portraits/women/24.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Avery Schmidt",
            imageUrl: "https://randomuser.me/api/portraits/women/35.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Maxine Gray",
            imageUrl: "https://randomuser.me/api/portraits/women/81.jpg",
            id: randomCode(6),
            isSelected: false,

        },
        {
            name: "Brooklyn Nelson",
            imageUrl: "https://randomuser.me/api/portraits/women/34.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Andre Steward",
            imageUrl: "https://randomuser.me/api/portraits/men/77.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Marcus Patterson Marcus Patterson Marcus Patterson Marcus Patterson Marcus Patterson",
            imageUrl: "https://randomuser.me/api/portraits/men/50.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Maxine Gray",
            imageUrl: "https://randomuser.me/api/portraits/women/81.jpg",
            id: randomCode(6),
            isSelected: false,

        },
        {
            name: "Savannah White",
            imageUrl: "https://randomuser.me/api/portraits/women/6.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Bertha Smith",
            imageUrl: "https://randomuser.me/api/portraits/women/92.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Ben Gutierrez",
            imageUrl: "https://randomuser.me/api/portraits/men/6.jpg",
            id: randomCode(6),
            isSelected: false,
        },
        {
            name: "Cory Prescott",
            imageUrl: "https://randomuser.me/api/portraits/men/9.jpg",
            id: randomCode(6),
            isSelected: false,

        },
        {
            name: "Eugene Day",
            imageUrl: "https://randomuser.me/api/portraits/men/37.jpg",
            id: randomCode(6),
            isSelected: false,
        }
    ]


    const selectedUsers = () => {
        let users = []
        if (props.for === UserPickerFor.projectMember) {
            if (props.project) {
                users = props.project.membersInfo ?? []
            }
        }else if (props.selectedUsers){
            users = props.selectedUsers
        }

        return users
    }


    const markSelectedIfAllMembers = () => {

        if (props.allMembers && props.selectedUsers) {
            let selectedUsersIds = props.selectedUsers.map((u) => { return u.id })
            let mm = [...props.allMembers]
            mm = mm.map((m) => {
                if (selectedUsersIds.includes(m.id)) {
                    // console.log(m.id + " .Id exists.");
                    m.isSelected = true
                } else {
                    m.isSelected = false
                }
                return m
            })

            return mm
        }

        return  props.allMembers
    }


    const [originalSelectedUsers, setOriginalSelectedUsers] = useState(selectedUsers())
    const [allUsers, setAllUsers] = useState(props.allMembers ? markSelectedIfAllMembers() : [])
    const [originalAllUsers, setOriginalAllUsers] = useState(props.allMembers ? markSelectedIfAllMembers() : [])

    const [savingId, setSavingId] = useState(null)
    const [showInvitationModal, setShowInvitationModal] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {

        let isMounted = true;
        if (!props.allMembers) {
            setLoading(true)
            Workspace.getMembers((succ, membersObj, errMsg) => {
                if (isMounted) {
                    setLoading(false)
                    if (succ && membersObj) {
                        console.log("MEMBERS OBJ IS");
                        console.log(membersObj);
                        let mm = membersObj.membersInfo ?? []

                        let selectedUsersId = originalSelectedUsers.map(m => { return m.id })

                        console.log("Selected Users Id is")
                        console.log(selectedUsersId);

                        mm = mm.map((m) => {
                            if (selectedUsersId.includes(m.id)) {
                                console.log(m.id + " .Id exists.");
                                m.isSelected = true
                            } else {
                                m.isSelected = false
                            }
                            return m
                        })

                        console.log("After updating is selected")
                        console.log(mm);

                        setAllUsers(mm)
                        setOriginalAllUsers(mm)
                    } else {
                        console.log(errMsg);
                    }
                }
            })
        }

        return () => (isMounted = false)

    }, [originalSelectedUsers, props.allMembers])


    const [filterString, setFilterString] = useState("")


    const toggleInvitation = (val) => {
        console.log("Toggling Invitation Modal to " + !showInvitationModal);
        setShowInvitationModal(() => {
            return val ?? !showInvitationModal
        })
    }


    const addPressedOnUser = () => {

    }



    const clearSearch = () => {
        searchStringChanged("")
    }

    const searchStringChanged = (str) => {
        setFilterString(str)

        console.log("STRING CHANGED HAPPEND");
        console.log(str);

        if (str.length > 1 && str !== "") {
            console.log("Filtering users");

            let filtered = [...allUsers].filter((tu) => {
                return tu.name.toLowerCase().includes(str.toLowerCase())
            })
            setAllUsers(filtered)
        } else {
            console.log("SETTING USERS TO ALL");
            console.log(str);
            setAllUsers([...originalAllUsers])
        }
    }


    const userSelectionToggled = (usr) => {


        if (savingId === usr.id) {
            return
        }

        let allUsersCopy = [...allUsers]
        // let orginalUsersCopy = [...originalSelectedUsers]
        // let index_o = orginalUsersCopy.findIndex((tu) => {
        //     return tu.id === usr.id
        // })
        // if (index_o >= 0) {
        //     let thisUser = { ...orginalUsersCopy[index_o] }
        //     thisUser.isSelected = !thisUser.isSelected
        //     orginalUsersCopy[index_o] = thisUser
        //     setOriginalSelectedUsers(orginalUsersCopy)
        // }

        console.log("TOGGLED USER");
        console.log(usr);

        let index_a = allUsersCopy.findIndex((tu) => {
            return tu.id === usr.id
        })

        console.log("INDEX_A");
        console.log(index_a);


        if (index_a >= 0) {
            let thisUser = { ...allUsersCopy[index_a] }
            let updatedVal = !thisUser.isSelected

            console.log("THIS USER");
            console.log(thisUser);

            console.log("Updated Val");
            console.log(updatedVal);



            if (props.for === UserPickerFor.projectMember) {
                console.log("COPYING PROJECT MEMBER");
                let proj = Project.copyFrom(props.project)

                if (proj.byUserInfo.id === usr.id) {
                    alert("Project creator cannot be removed")
                    return
                }

                setSavingId(usr.id)
                setTimeout(() => {
                    setSavingId(null)
                }, 1000 * 1.2)

                if (updatedVal === true) {
                    //add 

                    console.log("ADDING");

                    proj.addMember(thisUser.id, thisUser, (err) => {
                        if (err) {
                            alert("Member " + thisUser.name + " could not be added to the project. Reload and retry. Error = " + err)
                        }
                    })

                    // console.log("EXISTING PROJECT MEMBERS ARE");
                    // console.log(proj.members);

                    // let updatedMembers = [...proj.members, thisUser.id]
                    // console.log("UPDATED PROJECT MEMBERS ARE");
                    // console.log(updatedMembers);


                    // on frontend
                    proj.members = [...proj.members, thisUser.id]
                    proj.membersInfo = [...proj.membersInfo, thisUser]

                } else {
                    //remove 
                    console.log("REMOVING");

                    proj.removeMember(thisUser.id, thisUser, (err) => {
                        if (err) {
                            alert("Member " + thisUser.name + " could not be removed from the project. Reload and retry. Error = " + err)
                        }
                    })

                    // on frontend
                    proj.members = [...proj.members].filter((id) => { return id !== thisUser.id })
                    proj.membersInfo = [...proj.membersInfo].filter((m) => { return m.id !== thisUser.id })
                }

                console.log("UPDATING PROJECT");
                console.log(proj);


                props.projectUpdated(proj)
            }

            console.log("UPDATING ALLUSERS IN PICKER");

            thisUser.isSelected = updatedVal
            allUsersCopy[index_a] = thisUser

            console.log(allUsersCopy);

            setAllUsers(allUsersCopy)
            setOriginalAllUsers(allUsersCopy)
        }


        const forFilters = [
            UserPickerFor.assetAddedByFilter, 
            UserPickerFor.assetApprovedByFilter, 
            UserPickerFor.assetRejectedByFilter, 
            UserPickerFor.projectMemberFilter, 
            UserPickerFor.projectAddedByFilter,
            UserPickerFor.taskAddedBy,
            UserPickerFor.taskAssign,
            UserPickerFor.taskShareWith,
            UserPickerFor.eventAssign,
            UserPickerFor.eventSharedWith
        ]


        if (forFilters.includes(props.for)) {
            // console.log("User picker is for");
            // console.log(props.for);
            userAreSelected(allUsersCopy)
            // return
        }
    }

    const userAreSelected = (allUsr) => {
        let selectedUsers = allUsr.filter((u) => {
            return u.isSelected === true
        })
        if (props.usersAreSelected) {
            props.usersAreSelected(selectedUsers)
        }
    }


    const userRow = (usr) => {

        // let usrRole = usr.role ?? ""

        return <tr key={usr.id} className="" onClick={() => { userSelectionToggled(usr) }} >
            <td align="left" className=" " >
                <UserAvatar className=" my-0 " isList={true} showRole={true} infoObj={usr} isAddable={true} clearBg={true} noButton={true} addPressed={addPressedOnUser} />
            </td>
            <td align="center " >

                <Button disabled={usr.id === savingId} loading={usr.id === savingId} className="has-background-transparent is-borderless"  >
                    {
                        usr.isSelected ?
                            <RiCheckboxCircleFill className="mt-1 is-size-4 " />
                            :
                            <RiCheckboxBlankCircleLine className="mt-1 is-size-4 " />
                    }
                </Button>
            </td>
        </tr>
    }

    const userRowBox = (usr) => {
        return <Box shadowless className=" is-fullWidth is-borderedBottom" onClick={() => { userSelectionToggled(usr) }} >
            <Columns className="is-mobile">
                <Columns.Column narrow>
                    <UserAvatar className=" my-0 " showRole={true} infoObj={usr} isAddable={true} clearBg={true} noButton={true} addPressed={addPressedOnUser} />
                </Columns.Column>
                <Columns.Column narrow>
                    <Button className="has-background-transparent is-borderless"  >
                        {
                            usr.isSelected ?
                                <RiCheckboxCircleFill className="mt-1 is-size-4 " />
                                :
                                <RiCheckboxBlankCircleLine className="mt-1 is-size-4 " />
                        }
                    </Button>
                </Columns.Column>
            </Columns>

        </Box>
    }

    const panel = () => {





    }


    const up = () => {
        return <div>

            {/* <Form.Field>
                <Form.Label>Subject</Form.Label>
                <Form.Field kind="group">
                    <Form.Control>
                        <Form.Select
                            value={subject}
                            onChange={(e) => {
                                return setSubject(e.target.value);
                            }}
                        >
                            <option value="select-dropdown">Select dropdown</option>
                            <option value="with-options">With options</option>
                        </Form.Select>
                    </Form.Control>
                    <Form.Control fullwidth loading>
                        <Form.Input placeholder="With loading state" />
                    </Form.Control>
                </Form.Field>
            </Form.Field> */}


            {/* <Panel.Block backgroundColor="light" className="minW300 stick-to-top has-background-light z-35 ">
                <Form.Control className=" mx-2" >
                    <Columns className="  is-mobile is-narrow" gap="gapless">
                        <Columns.Column >
                            <Form.Input color="black" textWeight="semibold" size="small" type="text" placeholder="search" className="py-4" value={filterString} onChange={(e) => { searchStringChanged(e.target.value) }} />
                        </Columns.Column>
                        <Columns.Column size={2}>
                            <Button fullwidth size="small" color="light" className="mt-0 mb-0 mr-1 p-0 is-fullHeight ml-1 has-text-grey-light " onClick={() => { clearSearch() }} > Clear </Button>
                        </Columns.Column>
                    </Columns>
                </Form.Control>
            </Panel.Block> */}

            <Box shadowless className="m-0 p-0 is-clearfix">
                <Box shadowless className=" overflow-scroll is-clipped p-0 px-4 m-0">
                    <LoadingAux isLoading={loading} loaderHeight={400} isMain={true} >
                        <Table className="minW300" hoverable={false} striped={false} bordered={false} fullwidth >
                            {/* <Box shadowless className="is-fullWidth" >
                        {
                            allUsers.map((thisUser) => {
                                return userRowBox(thisUser)
                            })
                        }
                    </Box> */}
                            <tbody>
                                {
                                    allUsers.map((thisUser) => {
                                        return userRow(thisUser)
                                    })
                                }
                            </tbody>
                        </Table>
                    </LoadingAux>
                </Box>
            </Box>





        </div>
    }


    const showInvite = () => {

        let showInviteBtn = true

        let notAllowedFors = [UserPickerFor.assetApprovedByFilter, 
            UserPickerFor.assetAddedByFilter, 
            UserPickerFor.assetRejectedByFilter,
            UserPickerFor.projectAddedByFilter,
            UserPickerFor.projectMemberFilter,
        ]
        // assetApprovedByFilter: "Asset Approved By",
        // assetRejectedByFilter: "Asset Rejected By",
        // assetAddedByFilter: "Asset Added By",

        if (notAllowedFors.includes(props.for)) {
            showInviteBtn = false
        }
        //As per current users role: check on the basis of roles of current user
        if (!canInviteMembers()) {
            showInviteBtn = false
        }

        return showInviteBtn
    }


    const pickerTitle = () => {

        let title = "Users"
        let filters = [UserPickerFor.assetApprovedByFilter, UserPickerFor.assetAddedByFilter, UserPickerFor.assetRejectedByFilter]
        if (filters.includes(props.for)) {
            title = "Project Members"
        }

        return title
    }

    const modal = () => {
        return <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className=" almostNoModalBg backblurOnly lightDeleteButton animate-bottom " >
            <Modal.Card className=" h-80vh maxW330 minW330 ">
                {showInvitationModal ? <InvitationModal close={() => { toggleInvitation(false) }} /> : null}

                <Modal.Card.Header showClose={false} >
                    <Modal.Card.Title textSize={6} className="has-text-grey-light" >{pickerTitle()}</Modal.Card.Title>
                    {showInvite() ? <Button size="small" className=" is-light has-text-weight-medium mr-4" onClick={toggleInvitation} > + Invite </Button> : null}
                    <Button rounded className='is-pulled-right  ' size="small" color={"black"} onClick={props.close}>Done</Button>

                </Modal.Card.Header>
                <Modal.Card.Body className="p-0">
                    <Box shadowless className=" is-fullwidth z-1000 has-text-left has-background-white p-0 " >
                        {up()}
                    </Box>
                </Modal.Card.Body>
                <Modal.Card.Footer renderAs={Button.Group} align="center" className="p-0 py-2" >
                    <Box shadowless className="minW330 px-6 py-0 m-0 has-text-centered has-background-transparent ">
                        {/* <p> */}
                        <p className="is-size-7 has-text-centered has-text-grey-light mb-3" > {allUsers.filter((u) => { return u.isSelected }).length} Selected </p>
                        {/* </p> */}
                        {/* <p> */}
                        {/* <Button size="normal" color="dark" textWeight="semibold" className=" mb-3" fullwidth onClick={beforeClose} >Save</Button> */}
                        {/* <Button size="normal" className=" is-grey-light ml-1 mt-2">Save</Button> */}
                        {/* </p> */}
                    </Box>


                </Modal.Card.Footer>
            </Modal.Card>
        </Modal >

    }


    return modal()

}

export default UserPicker