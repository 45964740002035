import Parse from 'parse'
import { removeSpecialChars, sampleImageUrl } from './Utilities';
import { Workspace } from '../Models/Workspace';


export const initialiseParse = () => {
    Parse.initialize(process.env.REACT_APP_APPKEY, process.env.REACT_APP_JSKEY);
    Parse.serverURL = process.env.REACT_APP_SERVERURL;
    console.log("Running Initialise Parse. AppKey=" + process.env.REACT_APP_APPKEY + " • JSKey=" + process.env.REACT_APP_JSKEY)

}


export const isCurrentUser = () => {
    // console.log("Running IsCurrentUser")
    const currentUser = Parse.User.current();
    if (currentUser) {

        // check if user should be logged out explicitly
        let loginTimeSTR = localStorage.getItem("loginTime")
        let explicitEmailVerified = localStorage.getItem("explicitEmailVerified")


        if (loginTimeSTR && explicitEmailVerified) {
            // in case explicit email verified had happened. We logout user earlier to make them login again.
            // lets make this time 4 hours
            const loginTime = new Date(loginTimeSTR)
            let difference_In_Time = new Date().getTime() - loginTime.getTime();
            let difference_In_Hours = difference_In_Time / (1000 * 3600);
            let difference_In_Minutes = difference_In_Time / (1000 * 60);

            if (difference_In_Hours > 4) {
                logoutNow((succ, errMsg) => {
                    if (succ) {
                        console.log("User logged out at 4 hours threshhold : ExplicitEmailVerified flag.")
                    } else {
                        console.log("Cannot logout user at 4 hours threshhold : ExplicitEmailVerified flag. Error - " + errMsg)
                    }
                })
                return null
            }
        }


        return currentUser
    }

    return null
}

export const currentUserInfo = (toSingleString) => {
    const cu = isCurrentUser()
    if (cu) {

        const userInfo = {
            id: cu.id,
            name: cu.get("name"),
            imageUrl: cu.get("imageUrl"),
            email: cu.getEmail(),
            workspaceId: cu.get('workspaceId'),
            jobTitle: cu.get('jobTitle'),
            memberWorkspaceIds: cu.get("memberWorkspaceIds"),
            bio: cu.get("bio"),
        }


        // if (toSingleString === true){
        //     let str = `id:${removeSpecialChars(userInfo.id)};name:${removeSpecialChars(userInfo.name)};email:${removeSpecialChars(userInfo.email)};imageUrl: ${removeSpecialChars(userInfo.imageUrl)};workspaceId: ${removeSpecialChars(userInfo.workspaceId)};role: ${removeSpecialChars(userInfo.role)}`
        //     return str
        // }

        return userInfo

    }
    return cu
}


export const currentFullUserInfo = () => {
    const cu = isCurrentUser()
    if (cu) {

        const userInfo = {
            id: cu.id,
            name: cu.get("name"),
            imageUrl: cu.get("imageUrl"),
            hasImage: cu.get("hasImage"),
            email: cu.getEmail(),
            workspaceId: cu.get('workspaceId'),
            jobTitle: cu.get('jobTitle'),
            via: cu.get("via"),
            ownedWorkspaceIds: cu.get("ownedWorkspaceIds"),
            memberWorkspaceIds: cu.get("memberWorkspaceIds"),
            bio: cu.get("bio"),
            role: ""
        }


        // if (toSingleString === true){
        //     let str = `id:${removeSpecialChars(userInfo.id)};name:${removeSpecialChars(userInfo.name)};email:${removeSpecialChars(userInfo.email)};imageUrl: ${removeSpecialChars(userInfo.imageUrl)};workspaceId: ${removeSpecialChars(userInfo.workspaceId)};role: ${removeSpecialChars(userInfo.role)}`
        //     return str
        // }

        return userInfo

    }
    return cu
}



export const setShowInvitationReminderToAdmin = (val) => {
    localStorage.setItem("ShowInvitationReminderToAdmin", val)
}

export const removeShowInvitationReminderToAdmin = () => {
    localStorage.removeItem("ShowInvitationReminderToAdmin")
}

export const toggleShowInvitationReminderToAdmin = () => {
    localStorage.setItem("ShowInvitationReminderToAdmin", getShowInvitationReminderToAdmin() ?? true)
}

export const getShowInvitationReminderToAdmin = () => {
    let showInvitationReminderrToAdmin = localStorage.getItem("ShowInvitationReminderToAdmin")
    if (showInvitationReminderrToAdmin) {
        return showInvitationReminderrToAdmin
    }
    return null
}

export const setShowInvitationIfNeeded = () => {
    let isShow = getShowInvitationReminderToAdmin()
    if (isShow === null) {
        setShowInvitationReminderToAdmin(true)
    }
}

export const getCurrentWorkspaceId = () => {
    let workspaceId = localStorage.getItem("workspaceId")
    if (workspaceId) {
        return workspaceId
    }
    return null
}



export const setCurrentWorkspace = (workspaceObj) => {
    let wkInfo = Workspace.copyFrom(workspaceObj).info()
    let cw = JSON.stringify(wkInfo)
    localStorage.setItem("currentWorkspace", cw)
    setCurrentWorkspaceId(workspaceObj.id)
}

export const expandCurrentWorkspace = (wkInfo) => {
    let cw = JSON.stringify(wkInfo)
    localStorage.setItem("currentWorkspace", cw)
}

export const getCurrentWorkspace = () => {
    let cw = localStorage.getItem("currentWorkspace")
    if (cw) {
        return JSON.parse(cw)
    }
    return null
}

export const updateWKMemberInfo = (upMembersInfo) => {
    var wkInfo = getCurrentWorkspace()
    wkInfo.memberInfos = upMembersInfo
    expandCurrentWorkspace(wkInfo)
}

export const getWKMembersInfo = () => {
    let cw = getCurrentWorkspace()
    console.log("CW");
    console.log(cw);
    if (cw) {
        console.log("GOT CW");

        if (cw.membersInfo) {
            console.log("GOT CW membersInfo");

            return cw.membersInfo
        }
    }
    return null
}
 

export const validateMemberInfoRoles = (memberInfos, byUserForEntity) => {
    if (!memberInfos ) {
        return []
    }

    if (memberInfos.length === 0){
        return []
    }

    let lastMMInfo = getWKMembersInfo()
    var mmInfo = [...memberInfos]

    console.log("MM INFO IS ");
    console.log(mmInfo);

    console.log("LAST MM INFO IS ");
    console.log(lastMMInfo);

    if (lastMMInfo) {
       mmInfo =  mmInfo.map((m) => {
            let index = lastMMInfo.findIndex((thism) => {
                return thism.id === m.id
            })
            if (index >= 0) {
                let mInfo = lastMMInfo[index]
                if (mInfo.role) {

                    console.log("M INFO ");
                    console.log(mInfo);


                    console.log("mInfo.role ");
                    console.log(mInfo.role);

                    m.role = mInfo.role
                }else{
                    m.role = "-"
                }
            }

            if (byUserForEntity){
                if (byUserForEntity.id === m.id){
                    m.isEntityCreator = true
                }
            }

            return m
        })
    }

    console.log("AFTER VALIDATION MM INFO IS ");
    console.log(mmInfo);

    return mmInfo
}

export const removeCurrentWorkspace = () => {
    localStorage.removeItem("currentWorkspace")
    removeCurrentWorkspaceId()
}



export const setRevalidateWSMembership = (val) => {
    localStorage.setItem("RevalidateWSMembership", val)
}

export const getRevalidateWSMembership = () => {
    let reval = false
    let isReval = localStorage.getItem("RevalidateWSMembership")
    if (isReval) {
        reval = isReval
    }
    return reval
}


export const removeRevalidateWSMembership = () => {
    localStorage.removeItem("RevalidateWSMembership")
}


export const saveNotifications_storage = (nts) => {
    let notifs = JSON.stringify(nts)
    localStorage.setItem("notifications", notifs)
}

export const addNotifications_storage = (newNotif) => {
    let notifs = localStorage.getItem("notifications")
    if (notifs) {
        let allNotifs =  JSON.parse(notifs) ?? []
        allNotifs.unshift(newNotif)
        saveNotifications_storage(allNotifs)
    }
}

export const removeAllNotifications_storage = () => {
    localStorage.removeItem("notifications")
}

export const getNotifications_storage = () => {
    let notifs = localStorage.getItem("notifications")
    if (notifs) {
        return JSON.parse(notifs)
    }
    return []
}


export const setOwnedWorkspacesIds = (ownedWorkspaceIds) => {
    let ownedWks = JSON.stringify(ownedWorkspaceIds)
    localStorage.setItem("ownedWorkspaceIds", ownedWks)
}

export const getOwnedWorkspacesIds = () => {
    let ownedWorkspaceIds = localStorage.getItem("ownedWorkspaceIds")
    if (ownedWorkspaceIds) {
        return JSON.parse(ownedWorkspaceIds)
    }
    return []
}

export const removeOwnedWorkspacesIds = () => {
    localStorage.removeItem("ownedWorkspaceIds")
}


export const setMemberWorkspaces = (memberWorkspaces) => {
    let memberWks = JSON.stringify(memberWorkspaces)
    localStorage.setItem("memberWorkspaces", memberWks)
}

export const getMemberWorkspaces = () => {
    let memberWorkspaces = localStorage.getItem("memberWorkspaces")
    if (memberWorkspaces) {
        return JSON.parse(memberWorkspaces)
    }
    return memberWorkspaces ?? null
}

export const removeMemberWorkspaces = () => {
    localStorage.removeItem("memberWorkspaces")
}


export const setCurrentWorkspaceId = (wsId) => {
    localStorage.setItem("workspaceId", wsId)
}

export const removeCurrentWorkspaceId = () => {
    localStorage.removeItem("workspaceId")
}

export const isCurrentUserEmailVerified = () => {
    let currentuser = isCurrentUser()
    if (currentuser) {
        let isEmailVerified = currentuser.get("emailVerified")
        console.log("Email Verified = " + isEmailVerified)

        // check if explicit user is verified
        let explicitEmailVerified = localStorage.getItem("explicitEmailVerified")
        if (explicitEmailVerified) {
            console.log("Explicit Email Verified = " + explicitEmailVerified)

            return explicitEmailVerified
        }

        return isEmailVerified
    }
    return false
}


export const isUserWithIdAdmin = (id) => {

    console.log("CHECKING IS ADMIN FOR USER - " + id);

    let wkInfo = getCurrentWorkspace()
    if (wkInfo) {
        if (wkInfo.byUserInfo) {
            if (id === wkInfo.byUserInfo.id) {
                return true
            }
        }
    }
    return false
}



export const signUpNow = (name, email, password, jobTitle, via, callback) => {
    var user = new Parse.User();
    user.set("name", name);

    user.set("email", email);
    user.set("username", email);
    user.set("password", password);


    user.set("jobTitle", jobTitle);
    user.set("via", via);


    // user.set("phone", "415-392-0202");

    user.signUp().then(function (user) {
        console.log('User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email"));
        // alert(name + " is signed up")
        localStorage.setItem("loginTime", new Date())
        callback(true, user, "")
    }).catch(function (error) {
        console.log("Error: " + error.code + " " + error.message);
        callback(false, null, error.message)
    });

}



export const signInNow = (email, password, callback) => {

    Parse.User.logIn(email, password).then(function (user) {
        console.log('User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email"));
        // alert(email + " is logged in")
        localStorage.setItem("loginTime", new Date())

        // let wk = user.get("workspace")
        let wks = user.get("ownedWorkspaceIds")
        if (wks) {
            if (wks.length) {
                setOwnedWorkspacesIds(wks)
                // if (wks.length === 1){
                //     let wk = wks[0]
                //     if (wk){
                //         setCurrentWorkspaceId(wk.id)
                //     }
                // }
            }
        }

        // if (wk){
        //     setCurrentWorkspaceId(wk.id)
        // }


        callback(true, user, "")
    }).catch(function (error) {
        console.log("Error: " + error.code + " " + error.message);
        callback(false, null, false, error.message)
    });
}


export const logoutNow = (callback) => {
    // if (isCurrentUser()) {
    Parse.User.logOut().then(() => {
        // if (!isCurrentUser()) {
        // alert("User is logged out");
        removeCurrentWorkspace()
        removeMemberWorkspaces()
        removeOwnedWorkspacesIds()
        localStorage.removeItem("loginTime")
        localStorage.removeItem("explicitEmailVerified")
        removeShowInvitationReminderToAdmin()
        removeRevalidateWSMembership()

        callback(true, "")
        // }
    }).catch(function (error) {
        console.log("Error: " + error.code + " " + error.message);
        callback(false, error.message)
    });
    // }
}



export const startForgotPassword = (email, callback) => {
    Parse.User.requestPasswordReset(email).then(function () {
        console.log("Password reset request was sent successfully");
        // alert("Reset Instructions are sent. Please check your email inbox (also spam folder).")
        callback(true, "")
    }).catch(function (error) {
        console.log("The login failed with error: " + error.code + " " + error.message);
        callback(false, error.message)
    });
}

export const requestEmailVerificationAgain = (email, callback) => {
    Parse.User.requestEmailVerification(email).then(function () {
        console.log("Email verification request was sent again successfully");
        callback(true, "")
    }).catch(function (error) {
        console.log("Email verification request again failed with error: " + error.code + " " + error.message);
        callback(false, error.message)
    });
}

// isUserEmailVerified, userObjId
export const confirmIfEmailVerified = (userObjId, callback) => {
    var params = {
        "userObjId": userObjId,
    }


    Parse.Cloud.run('isUserEmailVerified', params)
        .then((result) => {
            console.log("isUserEmailVerified Result is")
            console.log(result)
            // errorOccurred, isVerified
            if (!result.errorOccurred) {
                callback(true, result.isVerified, result.message)
            } else {
                callback(false, false, result.message)
            }

        }).catch((error) => {
            let err = `Stripe Session Response Func Error is : ${error.message}`
            console.log(err)
            callback(false, null, err)
        })

}

export const confirmResetPasswordCode = (code, email) => {



}


export const resetPasswordWithResetCode = (newPassword, code, email) => {




}