import React, { useEffect, useState } from 'react'
import {  Box,  Button, Dropdown, Modal, Columns, Container, } from 'react-bulma-components';

// Import Swiper React components
import SwiperCore, { Lazy, Navigation, Pagination, Scrollbar, A11y, Virtual, Keyboard, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import 'swiper/swiper.scss';
import "swiper/swiper-bundle.min.css";

import AssetAttributeBox from './AssetAttributesBox';
import AnnotatableImage from './AnnotatableImage';
import { approveAsset, Asset, rejectAsset, resetAssetApproval } from '../../Models/Asset';


import { ImCheckmark, ImCheckmark2, ImCross } from 'react-icons/im';
import { BsLayoutSidebarInsetReverse, BsLayoutSidebarReverse } from 'react-icons/bs';
import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from 'react-icons/hi';

import {  GrView } from 'react-icons/gr';
import { AnnotationShowType,  AvatarStackFor, EntityType, CRUDAction, EntityLogType, AssetStatus, userPointer, AnimationType, AssetType, AnnotationType, AnnotationHoveredFrom, filterAnnotationsByAssetType, PlatformType, SubPlatform, entityInfo_ForNotif, NotificationFor } from '../../Models/EnumsAndPointers';
import PreviewModal from '../Preview/PreviewModal';
import { currentUserInfo, isCurrentUser } from '../../utilities/Login';
import { assetfor_description, randomCode, removeKeysFromUserInfo_Obj } from '../../utilities/Utilities';
import AssetCaptionEditorModal from './AssetCaptionEditorModal';
import AvatarStack from '../Calendar/AvatarStack';
import TextEditor from '../Tasks/TextEditor';
import { Timeline } from '../../Models/Timeline';
// import moment from 'moment';
import TimelineExplorer from '../Common/TimelineExplorer';
import ColumnAux from '../../Containers/ColumnAux';
import ShowAnimation from '../Common/ShowAnimation';
import LottieView from '../Common/LottieView';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { TextAnnotateBlend } from 'react-text-annotate-blend'
import { Annotation } from '../../Models/Annotaion';
import randomColor from 'randomcolor';
import AssetForSelector from '../Common/AssetForSelector';
import { Project } from '../../Models/Project';



// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";







// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';





const AssetsModal = (props, ref) => {

    SwiperCore.use([Lazy, Navigation, Pagination, Scrollbar, A11y, Virtual, Keyboard, Zoom]);

    const [showMemberBox, setShowMemberBox] = useState(true)
    const [currentAsset, setCurrentAsset] = useState(props.currentAsset ?? null)

    const [savingApprovalChoice, setSavingApprovalChoice] = useState(false)

    const [composeAnntActive, setComposeAnntActive] = useState(false)
    const [commentAnnotations, setCommentAnnotations] = useState([])
    const [composeAnnotations, setComposeAnnotations] = useState([])

    const [showPostCaptionEditor, setShowPostCaptionEditor] = useState(false)

    const [swiper, setSwiper] = useState(null);

    const [showPreview, setShowPreview] = useState(false)

    const [showTextEditorFor, setShowTextEditorFor] = useState(null)
    // const [changesMade, setChangesMade] = useState([])

    const [showTimeline, setShowTimeline] = useState(false)

    const [showAnim, setShowAnim] = useState(null)

    const [showLottie, setShowLottie] = useState(null)

    const [showAssetFor, setShowAssetFor] = useState(false)




    const toggleLottie = (val) => {
        setShowLottie(val)
        setTimeout(() => {
            setShowLottie(null)
        }, 2000)
    }


    const toggleShowAssetForSelector = (val) => {
        setShowAssetFor(val ?? !showAssetFor)
    }

    // const slideTo = (index) => {
    //     swiper.slideTo(index)
    // }



    useEffect(() => {



        console.log("SWIPER IS ")
        console.log(swiper)

        console.log("CURRENT SLIDE INDEX IS ")
        console.log(props.currentSlideIndex)

        if (swiper && props.currentSlideIndex) {
            setTimeout(() => {

                console.log("SLIDING TO " + props.currentSlideIndex)

                swiper.slideTo(props.currentSlideIndex)
            }, 300)
        }
    }, [swiper, props.currentSlideIndex])


    useEffect(() => {
        setSavingApprovalChoice(false)

    }, [props.assets])




    const swiperEvent = (swpr) => {
        console.log('Swiper Event is =')
        console.log(swpr)
        if (!swiper) {
            setSwiper(swpr)
        }
        setTimeout(() => {
            swpr.update()
        }, (100));
    }



    // This is just for 
    const toggleComposeAnntActive = (val, FROM) => {
        let thisVal = null
        if (typeof val === "boolean") {
            thisVal = val
        }
        console.log("TOGGLING COMPOSE ANNT ACTIVE TO " + thisVal + " . From - " + FROM)

        if (thisVal === false) {
            setComposeAnnotations([])
        }
        setComposeAnntActive(thisVal ?? !composeAnntActive)
    }


    const composeAnnotationsChanged = (newAnnts) => {
        setComposeAnnotations(newAnnts)
    }


    const slideIsChanged = (swp) => {

        // console.log('Swp is')
        // console.log(swp)

        // console.log('Swp index is')
        // console.log(swp.activeIndex)

        annotationRemove(null, true)
        setCurrentAsset(props.assets[swp.activeIndex])
        toggleComposeAnntActive(false)
    }

    // const onSlideChange = useCallback(() => {
    //     // list stays the same as the intial value
    //     console.log(JSON.stringify(listRef.current));
    //     setListInCb(listRef.current);
    //   }, []);


    const togglePreview = (val) => {
        setShowPreview(val ?? !showPreview)
    }


    const changeAsset = (asset) => {
        let allAssets = [...props.assets]
        let index = allAssets.findIndex((a) => { return a.id === asset.id })

        // console.log("INDEX IS");
        // console.log(index);

        if (index >= 0) {
            allAssets[index] = asset


            // console.log("CHANGED ASSETS ARE");
            // console.log(allAssets);

            if (props.assetsChanged) {
                props.assetsChanged(allAssets)
            }
        }
    }

    const togglePostCaptionEditor = (val) => {
        setShowPostCaptionEditor(val ?? !showPostCaptionEditor)
    }

    const changeApproval = (val) => {
        if (savingApprovalChoice) {
            return
        }

        let ca = null
        if (val === "approve") {
            ca = approveAsset(currentAsset)
            toggleLottie(AnimationType.assetApproved)
        }

        if (val === "reject") {
            ca = rejectAsset(currentAsset)
            toggleLottie(AnimationType.assetRejected)
        }

        if (val === "unapprove" || val === "unreject") {
            ca = resetAssetApproval(currentAsset)
        }

        setCurrentAsset((prev) => {
            return ca
        })

        setSavingApprovalChoice(true)
        setTimeout(() => {
            setSavingApprovalChoice(false)
            changeAsset(ca)
        }, 1000 * 1.2)
    }


    const assetChanged = (changedAsset) => {
        setCurrentAsset((prev) => {
            return changedAsset
        })

        setTimeout(() => {
            changeAsset(changedAsset)
        }, 1000)
    }




    const handleTextAnnotateChange = (val) => {
        console.log("INCOMING VALUE IS")
        console.log(val)
        if (val.length) {
            let value = val[val.length - 1]
            // setTextAnnotateValue(val)


            let newAnn = new Annotation()
            // const randomCode = randomCode(6)
            newAnn.id = randomCode(6)
            newAnn.type = AnnotationType.highlight
            newAnn.assetType = AssetType.text
            newAnn.assetId = currentAsset.id
            newAnn.assetVersionId = currentAsset.currentVersionId ?? ""

            newAnn.start = value.start
            newAnn.end = value.end
            newAnn.text = value.text
            // newAnn.tag = { ...value.tag }
            newAnn.tag = value.tag

            newAnn.isHovered = true  // it starts from hovering automatically
            newAnn.isHoveredFrom = AnnotationHoveredFrom.asset
            newAnn.lastHovered = new Date()
            newAnn.addedOn = new Date()
            newAnn.color = randomColor({ luminosity: 'light' })
            // {...marker, id: randomCode(4)}

            // console.log("New Marker is")
            // console.log(newAnn)

            // TO make initial entry animation work in chrome 
            // setTimeout(() => {
            //     let nnt = document.createElement(noteIdPreString + randomCode);
            //     if ()


            // }, 250)

            // create Annotation from marker
            // setAnnotations([...props.annotations, newMark])
            annotationAdd(newAnn)
        }



    }





    // const testContent = () => {
    //     return <>
    //         <h1>
    //             Hello World
    //         </h1>
    //         <p>
    //             Lorem ipsum
    //             <sup>
    //                 <a>
    //                     [1]
    //                 </a>
    //             </sup>
    //             {' '}dolor sit amet, consectetur adipiscing elit. Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque. Sub
    //             <sub>
    //                 script
    //             </sub>
    //             {' '}works as well!
    //         </p>
    //         <h2>
    //             Second level
    //         </h2>
    //         <p>
    //             Curabitur accumsan turpis pharetra{' '}
    //             <strong>
    //                 augue tincidunt
    //             </strong>
    //             {' '}blandit. Quisque condimentum maximus mi, sit amet commodo arcu rutrum id. Proin pretium urna vel cursus venenatis. Suspendisse potenti. Etiam mattis sem rhoncus lacus dapibus facilisis. Donec at dignissim dui. Ut et neque nisl.
    //         </p>

    //     </>
    // }







    const assetBox = () => {

        let cu = currentUserInfo()

        let isApproved = false
        let isRejected = false

        let allApprovedBy = currentAsset.approvedByInfo
        let allRejectedBy = currentAsset.rejectedByInfo



        if (currentAsset.approvedBy) {
            if (currentAsset.approvedBy.includes(cu.id)) {
                isApproved = true
            }
        }
        if (currentAsset.rejectedBy) {
            if (currentAsset.rejectedBy.includes(cu.id)) {
                isRejected = true
            }
        }

        // let sampleCaption = "Cum sociis natoque penatibus et magnis dis parturient. Unam incolunt Belgae, aliam Aquitani, tertiam. Ullamco laboris nisi ut aliquid ex ea commodi consequat. Ambitioni dedisse scripsisse iudicaretur."


        let commentAnnts = filterAnnotationsByAssetType(commentAnnotations)
        let commentAnnts_image = commentAnnts.imageAnnotations
        let commentAnnts_text = commentAnnts.textAnnotations.map(a => { return { ...a } })


        let composeAnnts = filterAnnotationsByAssetType(composeAnnotations)
        let composeAnnts_image = composeAnnts.imageAnnotations
        let composeAnnts_text = composeAnnts.textAnnotations.map(a => { return { ...a } })




        return (
            <Box radiusless shadowless className="m-0 p-0 has-background-transparent  hw100 maxH100p overflow-hidden" >

                {/* <Box radiusless shadowless className=" has-background-transparent  h-80vh maxH80p overflow-hidden my-0" > */}
                <Box radiusless shadowless className=" has-background-transparent  h-81vh  overflow-hidden m-0 pb-0 scrollGradient" >

                    <Swiper
                        // id="assetSwiperModal"
                        // virtual={true}
                        observer={true}
                        observeParents={true}
                        className=" hw100 has-background-transparent asserSwiper "
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        onSwiper={(swiper) => { swiperEvent(swiper) }}
                        onSlideChange={(sw) => { slideIsChanged(sw) }}
                        centeredSlides={true}
                        preloadImages={false}
                        lazy={true}
                        watchSlidesVisibility={false}
                        watchSlidesProgress={false}
                        keyboard={{ enabled: true }}
                        zoom={{ enabled: false }}
                        allowTouchMove={false}
                        modules={[Virtual]}
                        virtual={true}
                    >

                        {props.assets.map((thisAsset) => {
                            return <SwiperSlide key={thisAsset.id} virtualIndex={thisAsset.id} className="  has-text-centered hw100 has-background-transparent " onL >
                                {/* <img
                                    alt={thisAsset.name}
                                    // src="https://images.unsplash.com/photo-1622526837751-6fef15cf5fc3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=626&q=80"
                                    data-src={thisAsset.url}
                                    className="swiper-lazy maxH90p fadeIn object-fit-contain is-centered object-position-center-xyntered "
                                /> */}
                                <div className='hw100 overflow-scroll'>
                                    <AnnotatableImage
                                        project={props.project}
                                        asset={thisAsset}
                                        shouldComposeAnnotations={composeAnntActive}
                                        annotations={commentAnnts_image.length ? commentAnnts_image : composeAnnts_image}
                                        annotationEditable={!(commentAnnts.length)}
                                        annotationNoteChanged={annotationNoteChanged}
                                        annotationHovered={annotationHovered}
                                        annotationAdd={annotationAdd}
                                        annotationRemove={annotationRemove}
                                    />
                                    <ColumnAux isMiddle={true} size={8}>
                                        <Box className='has-text-left has-background-transparent mb-6' suppressContentEditableWarning={true} >

                                            <div>
                                                <Columns className=''>
                                                    <Columns.Column narrow>
                                                        <div className='is-size-7 has-text-grey is-uppercase is-underlined'>{currentAsset.captionType} </div>
                                                    </Columns.Column>
                                                    <Columns.Column>
                                                    </Columns.Column>
                                                    <Columns.Column narrow>
                                                        <div className="is-pulled-right">
                                                            <Button className='is-pulled-right px-5 py-0 has-background-grey-lighter' size="small" textColor="grey" textWeight='medium' onClick={togglePostCaptionEditor}> EDIT </Button>
                                                        </div>
                                                    </Columns.Column>
                                                </Columns>

                                            </div>
                                            <br />

                                            <div>
                                                {/* <Content textAlign={"left"} className=""> 
                                          {testContent()}
                                        </Content> */}

                                                {/* {currentAsset.caption} */}

                                                {/* <Form.Field className="m-0 p-0">
                                            <Form.Control>
                                                <Form.Textarea
                                                    className="m-0 py-2 px-3 has-background-transparent is-borderless"
                                                    size="normal"
                                                    color="text"
                                                    rows={3}
                                                    // fixedSize={true}
                                                    readOnly={true}
                                                    placeholder="Add text here..."
                                                    value={currentAsset.caption ?? ""}
                                                    onClick={togglePostCaptionEditor}
                                                >
                                                </Form.Textarea>
                                            </Form.Control>
                                        </Form.Field> */}

                                                {
                                                    currentAsset.caption ?
                                                        <div className="textContentBox" >
                                                            <TextAnnotateBlend
                                                                // style={{
                                                                //     fontSize: "1.2rem",
                                                                // }}
                                                                id=''

                                                                renderAs="span"
                                                                className=' myTextAnner '
                                                                content={currentAsset.caption}
                                                                onChange={handleTextAnnotateChange}
                                                                value={commentAnnts_text.length ? commentAnnts_text : composeAnnts_text}
                                                                getSpan={(span) => (
                                                                    {
                                                                        ...span,
                                                                        // tag: <> {composeAnntActive ? <IoCloseCircleSharp className='is-size-6' /> : <> </>} </>,
                                                                        // tag:<IoCloseCircleSharp className='is-size-6' /> ,
                                                                        tag: "",
                                                                        // tag:<span> <IoCloseCircleSharp className='is-size-6' /> </span> ,

                                                                        // color: "#FFE800",
                                                                        color: "#FFDA00"
                                                                    })}
                                                            />
                                                        </div>

                                                        :
                                                        <div>
                                                            <p className='has-text-grey-light is-size-6' onClick={togglePostCaptionEditor}>Add text content...</p>
                                                        </div>
                                                }



                                                {/* <ColumnAux isMiddle={true} size={8}>
                                                    <Button className='mt-6' size={"small"} color={"light"} textColor="grey" textWeight='medium' fullwidth onClick={togglePostCaptionEditor}> EDIT </Button>
                                                </ColumnAux> */}

                                            </div>



                                        </Box>
                                    </ColumnAux>
                                </div>



                                {showLottie ?
                                    <LottieView size="large" type={showLottie} />
                                    :
                                    null
                                }

                                <br />
                                <br />


                            </SwiperSlide>
                        })}
                    </Swiper>


                </Box>

                <Box radiusless shadowless className=" m-0 p-0 mt-minus-40-imp has-background-transparent">

                    <Columns gap={0} className="p-0">
                        <Columns.Column className="m-0 is-hidden-touch " size={2}>
                        </Columns.Column>
                        <Columns.Column className="p-0">
                            <Box shadowless className=" mx-4 has-background-transparent p-0 px-4">

                                <Box shadowless className="has-background-transparent  m-0 p-0 mt-4  has-text-centered ">
                                    {
                                        currentAsset.isFinalised ?
                                            <div className='has-text-centered p-0 m-0 '> <RiCheckboxCircleFill className='  is-centered fadeIn_1p2   p-0 m-0 is-size-2  has-text-success ' /> </div>
                                            : <Box shadowless radiusless className='has-background-transparent mb-1'>

                                            </Box>
                                    }
                                </Box>


                                {/* <Box shadowless radiusless className='m-0 p-0 pt-1 px-1' >
                                    <div className="is-size-8 has-text-left ml-3 has-text-grey-light has-text-weight-semibold mt-0 mb-0 p-0">{currentAsset.captionType ?? AssetCaptionType.caption}</div>
                                    <Box className="has-background-transparent  is-size-7 has-text-weight-medium p-0 mt-0 mb-3 mx-0 " >
                                    <Form.Field className="m-0 p-0">
                                        <Form.Control>
                                            <Form.Textarea
                                                className="m-0 py-2 px-3 is-size-7 has-background-transparent is-borderless"
                                                size="small"
                                                color="text"
                                                rows={3}
                                                fixedSize={true}
                                                readOnly={true}
                                                placeholder="Add text here..."
                                                value={currentAsset.caption ?? ""}
                                                onClick={togglePostCaptionEditor}
                                            >
                                            </Form.Textarea>
                                        </Form.Control>
                                    </Form.Field>
                                    </Box>
                                </Box> */}


                                <Box shadowless radiusless className='m-0 p-0 has-background-transparent mt-minus-10-imp'>
                                    <Columns gap={2} className="is-mobile mt-0 ">
                                        <Columns.Column className="is-mobile mt-0" narrow>
                                            <Button.Group size="small" className=" " >
                                                <Button className="is-light is-bordered has-text-grey" onClick={togglePreview}  >
                                                    <GrView className=" has-text-grey mr-2 m" />
                                                    <span> Preview </span>
                                                </Button>
                                            </Button.Group>
                                        </Columns.Column>

                                        <Columns.Column className=' pl-3 pr-0'>


                                            <Button size={"small"} fullwidth className="is-light is-bordered has-text-grey" onClick={toggleShowAssetForSelector}  >
                                                {/* <GrView className=" has-text-grey mr-2 m" /> */}
                                                <span> {assetfor_description(currentAsset.assetForInfo)} </span>
                                            </Button>


                                        </Columns.Column>


                                        <Columns.Column className="is-mobile  px-0" narrow>

                                            {
                                                (isApproved || isRejected) ?
                                                    <>
                                                        {
                                                            isApproved ?

                                                                <Dropdown up renderAs="button" className="   has-text-left mx-0 mt-minus-2  is-small is-arrowless has-background-transparent is-borderless " label={
                                                                    // <Button size="small" color="success" className="  has-background-success-light has-text-success-dark  pl-1 pr-0 mt-minus-8"  >
                                                                    <Button size="small" color="success" className=" has-background-light  has-text-black   pl-1 pr-0 mt-minus-8"  >

                                                                        <HiOutlineDotsVertical className="is-size-6  mr-2 m" />
                                                                        <span> Approved by me &nbsp; </span>
                                                                        <ImCheckmark className="is-size-6 m-0 p-0 mr-2 " />
                                                                    </Button>

                                                                } onChange={(val) => { changeApproval(val) }} >
                                                                    {/* <Dropdown.Item  renderAs="a" value="cancelOrder" className=" is-disabled has-text-grey-light ">Version 2 </Dropdown.Item> */}
                                                                    <Dropdown.Item renderAs="a" value="unapprove">
                                                                        <p>Remove my approval</p>
                                                                        <p> {allApprovedBy.length ?
                                                                            <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allApprovedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                                                                            : null
                                                                        } </p>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Divider />
                                                                    {/* <Dropdown.Item renderAs="a" value="reject" className="has-text-danger">Reject</Dropdown.Item> */}
                                                                    <Dropdown.Item renderAs="a" value="reject" className="">Reject</Dropdown.Item>

                                                                </Dropdown>





                                                                :
                                                                <Dropdown up renderAs="button" className=" has-text-left mx-0 mt-minus-2  is-small is-arrowless has-background-transparent  is-borderless " label={
                                                                    // <Button size="small" color="danger" className="  has-background-danger-light has-text-danger-dark  pl-1 pr-0 mt-minus-8"  >
                                                                    <Button size="small" color="danger" className=" has-background-light  has-text-black   pl-1 pr-0 mt-minus-8"  >

                                                                        <HiOutlineDotsVertical className="is-size-6  mr-2 m" />
                                                                        <span> Rejected by me &nbsp; </span>
                                                                        <ImCross className="is-size-6 m-0 p-0 mr-2 " />
                                                                    </Button>
                                                                } onChange={(val) => { changeApproval(val) }}>
                                                                    {/* <Dropdown.Item  renderAs="a" value="cancelOrder" className=" is-disabled has-text-grey-light ">Version 2 </Dropdown.Item> */}
                                                                    <Dropdown.Item renderAs="a" value="unreject">
                                                                        <p>Remove my rejection</p>
                                                                        <p> {allRejectedBy.length ?
                                                                            <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allRejectedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                                                                            : null
                                                                        } </p>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    {/* <Dropdown.Item renderAs="a" value="approve" className="has-text-success">Approve</Dropdown.Item> */}
                                                                    <Dropdown.Item renderAs="a" value="approve" className="">Approve</Dropdown.Item>

                                                                </Dropdown>



                                                        }
                                                    </>

                                                    :

                                                    <Dropdown up renderAs="button" className=" has-text-left mx-0 mt-minus-2 has-background-transparent  is-small  is-borderless " label={
                                                        // <HiOutlineDotsVertical className="is-size-5 has-text-grey-light mt-1 p-0" />
                                                        // <Button loading={savingApprovalChoice} disabled={savingApprovalChoice} className="is-light is-hover-shadow success has-text-grey p-3" onClick={() => { changeApproval("approve") }}>
                                                        // <Button loading={savingApprovalChoice} disabled={savingApprovalChoice}  className=" mt-2 has-background-white-ter " >
                                                        //  <HiOutlineDotsVertical className="is-size-5 has-text-grey-light ml-0 mr-1 p-0" />

                                                        //     Approve?&nbsp; <ImCheckmark2 className="is-size-5 is-light has-text-success m-0 p-0 ml-1 " />
                                                        // </Button>
                                                        <Button size="small" className="is-light is-bordered has-text-grey has-background-white-ter  pl-1 pr-2 mt-minus-8 mx-0"  >
                                                            <HiOutlineDotsVertical className="is-size-6 has-text-grey-dark mr-2 m" />
                                                            <span> Approve?&nbsp; </span>
                                                            {/* <ImCheckmark2 className="is-size-6 is-light has-text-success m-0 p-0 ml-1 " /> */}
                                                            <ImCheckmark2 className="is-size-6 is-light m-0 p-0 ml-1 " />
                                                        </Button>
                                                    } onChange={(val) => { changeApproval(val) }}>
                                                        {/* <Dropdown.Item renderAs="a" value="approve" className="has-text-success"> */}
                                                        <Dropdown.Item renderAs="a" value="approve" className="">

                                                            <p>Approve</p>
                                                            <p> {allApprovedBy.length ?
                                                                <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allApprovedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                                                                : null
                                                            } </p>
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        {/* <Dropdown.Item renderAs="a" value="reject" className="has-text-danger"> */}
                                                        <Dropdown.Item renderAs="a" value="reject" className="">

                                                            <p>Reject</p>
                                                            <p> {allRejectedBy.length ?
                                                                <AvatarStack for={AvatarStackFor.assetApprovalBrief} entity={EntityType.asset} users={allRejectedBy} justAvatars={true} small={true} nonEditableAvatars={true} />
                                                                : null
                                                            } </p>
                                                        </Dropdown.Item>
                                                    </Dropdown>

                                            }

                                        </Columns.Column>
                                    </Columns>
                                </Box>

                            </Box>
                        </Columns.Column>
                        <Columns.Column className="m-0 is-hidden-touch " size={2}>
                        </Columns.Column>
                    </Columns>

                    {/* <Columns className="is-mobile has-background-info has-text-centered">
                        <Columns.Column className="is-mobile">
                            One
                        </Columns.Column>
                        <Columns.Column className="is-mobile">
                            Two
                        </Columns.Column>
                        <Columns.Column className="is-mobile">
                            Three
                        </Columns.Column>
                    </Columns> */}

                </Box>

            </Box >
        )
    }

    const renameAsset = () => {
        setShowTextEditorFor({ text: currentAsset.name, payload: currentAsset, crud: CRUDAction.update })
    }

    const clearSelections = () => {
        setShowTextEditorFor(null)
        setShowAssetFor(false)
    }

    const textChangeDone = (text, payload, crud) => {

        // props.textChangeDone(text, props.payload, props.crud)
        if (!text) {
            clearSelections()
            return
        }

        // To rename asset
        let clearText = text.trim()
        if (!clearText || clearText === "") {
            clearSelections()
            return
        }


        // let description = changesMade.map((c) => c.attrDesc + " " + c.operation + " : " + c.value).join(" >n>· ")
        // entityChanged("plannedOn", "PlannedOn", crudType, value, isCleared ? null : completeDateTime(value), new Date())

        let description = `Name updated : ${clearText}`


        if (currentAsset.name !== clearText) {
            // update the asset
            let assetCopy = Asset.copyFrom(currentAsset)
            assetCopy.name = clearText

            console.log("Updating Asset name")
            assetCopy.update((succ, result, errMsg) => {
                if (succ) {
                    assetChanged(result)
                    console.log("Asset name updated")

                    Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", description)

                    let entityInfoObj = entityInfo_ForNotif(EntityType.asset, result, [], null)
                    Project.getProjectDetails_AndNotify(result.project.id, NotificationFor.asset_changedDetails, entityInfoObj)

                } else {
                    alert("Asset could not be renamed. Something went wrong.")
                    console.log(errMsg)
                }
            })


        }
        clearSelections()


    }


    // const entityChanged = (attr, attributeDesc, opr, valObj, val, time) => {

    //     // console.log("NEW ENTITY CHANGED ENTRY")
    //     console.log(`ATTR = ${attr}, VAL = ${val}, TIME = ${time}`)

    //     let changes = [...changesMade]
    //     let matchingChangeIndex = changes.findIndex((c) => c.attribute === attr)

    //     // Event_Dynamic_Changeables



    //     // console.log("COMPARING LASTUPLOADEVENT")
    //     // console.log(lastUploadedTask)
    //     // console.log("WITH NEW")
    //     // console.log(val)


    //     // DOES NOT WORK FOR NOTE.. AS A PERSON CAN ADD MULTIPLE NOTES IN ONE GO



    //     // if (lastUploadedTask[attrLiteral] !== val){
    //     if (matchingChangeIndex >= 0) {
    //         let matchingChange = changes[matchingChangeIndex]

    //         if (moment(time).isAfter(moment(matchingChange.time))) {
    //             console.log("CHANGE REPLACED")
    //             changes[matchingChangeIndex] = { attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() }
    //         }
    //         // console.log("The time of the change ")
    //         // console.log(`ATTR-${attr} VAL-${val} TIME-${time}`)
    //         // console.log("COMPARED WITH EARLIER")
    //         // console.log(matchingChange)

    //     } else {
    //         console.log("CHANGE ADDED")
    //         changes.push({ attribute: attr, attrDesc: attributeDesc, operation: opr, value: val, time: new Date() })
    //     }

    //     // }else{
    //     //     //If this attribute was changed earlier but now is being reversed back without saving
    //     //     if (matchingChangeIndex >= 0){
    //     //         console.log("CHANGE REMOVED")
    //     //         delete changes[matchingChangeIndex]

    //     //     }
    //     // }


    //     setChangesMade((old) => {
    //         // console.log("NOW THE CHNAGE ARE")
    //         // console.log(changes)
    //         return changes
    //     })
    // }



    const dropdownValueChanged = (val) => {

        if (val === "renameAsset") {
            renameAsset()
        }
        if (val === "uploadVersion") {

        }
        if (val === "showTimeline") {
            setShowTimeline(true)
        }


        if (val === "finaliseAsset" || val === "pending" || val === "archive" || val === "unArchive") {
            let assetCopy = Asset.copyFrom(currentAsset)

            let cu = isCurrentUser()
            let cuInfo = currentUserInfo()

            if (val === "finaliseAsset") {

                assetCopy.status = AssetStatus.finalised
                assetCopy.isFinalised = true
                assetCopy.isArchived = false
                assetCopy.finalisedBy = userPointer(cu.id)
                assetCopy.finalisedByInfo = removeKeysFromUserInfo_Obj(cuInfo, true, false, true, true)
                assetCopy.finalisedAt = new Date()

                toggleLottie(AnimationType.assetFinalised)

            }
            if (val === "pending") {
                assetCopy.status = AssetStatus.pending
                assetCopy.isFinalised = false
                assetCopy.isArchived = false
            }

            if (val === "archive") {
                // assetCopy.status = AssetStatus.archived
                assetCopy.isArchived = true
                assetCopy.archivedBy = userPointer(cu.id)
                assetCopy.archivedByInfo = removeKeysFromUserInfo_Obj(cuInfo, true, false, true, true)
            }

            if (val === "unArchive") {
                // assetCopy.status = AssetStatus.pending
                assetCopy.isArchived = false
                assetCopy.archivedBy = null
                assetCopy.archivedByInfo = null
            }




            assetCopy.update((succ, result, errMsg) => {
                if (succ) {
                    let aCopy = Asset.copyFrom(result)

                    // changeAsset(aCopy)
                    assetChanged(aCopy)

                    

                    let entityInfoObj = entityInfo_ForNotif(EntityType.asset, result, [], null)

                    if (val === "archive") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.archived, "Updated", "Is Archived")
                        Project.getProjectDetails_AndNotify(result.project.id, NotificationFor.asset_archived, entityInfoObj)

                        alert("Asset has been deleted. Can be found in the Archived tab until deleted permanently.")
                        props.close()
                    }
                    if (val === "pending") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", "Is Un-Finalised. Now Pending.")
                        alert("Asset has been Un-Finalised. Is now pending.")
                        props.close()
                    }

                    if (val === "finaliseAsset") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", "Is Finalised")
                        Project.getProjectDetails_AndNotify(result.project.id, NotificationFor.asset_finalised, entityInfoObj)
                        // alert("Asset has been finalised. Can be found in the Finalised tab.")
                        // props.close()
                        // SHOW FINALISED ANIMATION
                    }

                    if (val === "unArchive") {
                        Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", "Is Un-Archived.")
                        alert("Asset has been Un-Archived.")
                        props.close()
                    }



                } else {
                    console.log(errMsg);
                    alert("An error occured. Could not update asset")
                }


            })


        }


    }


    const assetsContainer = () => {
        return (
            <div className=" is-fullHeight maxH100p">
                <Columns className=" has-background-transparent z-30 is-mobile p-0 pt-0  mt-3 m-0 mb-2  ">
                    <Columns.Column size={2} className="p-0 " narrow>
                        <Button className="delete is-black ml-4 " onClick={props.close} />
                    </Columns.Column>
                    <Columns.Column className="p-1  "  >
                        <p className={`has-text-centered is-size-7 ` + (currentAsset.isArchived ? "has-text-danger" : (currentAsset.isFinalised ? " has-text-success " : " has-text-grey-light "))}> {currentAsset.name} </p>
                    </Columns.Column>
                    <Columns.Column size={2} className="p-0 has-text-right " narrow>
                        <Dropdown right renderAs="button" className=" is-aligned-right has-text-left  mx-0 mb-0 p-0 mt-minus-10 is-small is-arrowless has-background-transparent  is-borderless " label={
                            <HiOutlineDotsHorizontal className="is-size-4 mt-2" />
                        }
                            onChange={(val) => { dropdownValueChanged(val) }}
                        >
                            {/* <Dropdown.Item  renderAs="a" value="cancelOrder" className=" is-disabled has-text-grey-light ">Version 2 </Dropdown.Item> */}
                            <Dropdown.Item renderAs="a" value="renameAsset"> Rename</Dropdown.Item>

                            <Dropdown.Item renderAs="a" value="uploadVersion"> Upload Version</Dropdown.Item>
                            <Dropdown.Item renderAs="a" value="showTimeline">  Timeline</Dropdown.Item>
                            <Dropdown.Divider />
                            {
                                currentAsset.isFinalised ?
                                    <Dropdown.Item renderAs="a" value="pending">Un-Finalise </Dropdown.Item>
                                    :
                                    <Dropdown.Item renderAs="a" value="finaliseAsset">Finalise </Dropdown.Item>
                            }
                            <Dropdown.Divider />
                            {
                                currentAsset.isArchived ?
                                    <Dropdown.Item renderAs="a" value="unArchive">Un-Archive </Dropdown.Item>
                                    :
                                    <Dropdown.Item renderAs="a" textColor='danger' value="archive">Archive </Dropdown.Item>
                            }
                        </Dropdown>
                        <Button size="small" className=" is-hidden-mobile is-borderless has-background-transparent" onClick={() => { setShowMemberBox(!showMemberBox) }}> <span className=" is-size-6 mt-minus-10 pt-1 px-0 opacity75 "> {showMemberBox ? <BsLayoutSidebarInsetReverse /> : <BsLayoutSidebarReverse />}  </span>  </Button>
                    </Columns.Column>
                </Columns>
                <Container className="is-fullHeight maxH100p  pt-0 mt-minus-10 " breakpoint="fullhd" >
                    {assetBox()}
                </Container>

            </div>



        )
    }


    const annotationAdd = (ann) => {
        if (composeAnntActive) {
            let currentAnnts = [...composeAnnotations]
            currentAnnts.map((thisAn) => {
                thisAn.isHovered = false
                return null
            })
            ann.isHovered = true

            console.log("\n\n NEW ANNOTATION ADDED IS")
            console.log(ann)

            currentAnnts.push(ann)
            setComposeAnnotations(currentAnnts)
        }
    }


    const annotationNoteChanged = (anntId, nt) => {
        // let currentAnnts = [...composeAnnotations]
        // let index = currentAnnts.findIndex((thisAn) => {
        //     return thisAn.id = annId
        // })
        // console.log("finding ant id = " + annId + ". For note = " + nt  + ". Index = " + index)

        // if (index >= 0){
        //     let changedAnnt = {...currentAnnts[index]}
        //     changedAnnt.note = nt
        //     currentAnnts[index] = changedAnnt
        //     setComposeAnnotations(currentAnnts)
        // }

        if (composeAnntActive) {

            if (anntId) {
                let currentAnnts = [...composeAnnotations]
                let anIndex = null
                currentAnnts.map((thisAn, index) => {
                    thisAn.isHovered = false
                    if (thisAn.id === anntId) {
                        anIndex = index
                    }
                    return null
                })

                // console.log(`ANNOTATION INDEX = ${anIndex}`)
                if (anIndex >= 0) {
                    // console.log(`ANNOTATION INDEX = ${anIndex}, HOVERED = ${isHovered}`)
                    let thisAnnt = { ...currentAnnts[anIndex] }
                    thisAnnt.note = nt
                    currentAnnts[anIndex] = thisAnnt
                }
                setComposeAnnotations(currentAnnts)
            }
        }
    }



    const annotationHovered = (isHovered, anntId, from, showType, annObject, lastHovered) => {
        // console.log("ANNOTATION HOVERED EVENT")
        // console.log(`ANNOTATION HOVERED = ${isHovered}, Id = ${anntId}, from = ${from}, showtype = ${showType}, annObject = ${annObject}`)

        if (showType === AnnotationShowType.existingComment) {


            // console.log("##### ANNOTATION HOVERED FROM " + from)
            // console.log("Annt Object")
            // console.log(annObject)

            if (annObject.assetType) {
                //TODO:
                // CURRENTLY CREATING UI CHANGE ISSUES
                // if (annObject.assetType === AssetType.image) {
                //     scrollToImage()
                // }
                // if (annObject.assetType === AssetType.text) {
                //     scrollToText()
                // }
            }


            // FOR COMMENTS - SHOWING ONLY 1 ANNOTATION AT A TIME, for Simplicity and non-fininished testing for all cases
            if (annObject && isHovered) {
                let annObjCopy = { ...annObject }
                annObjCopy.isHovered = true

                // let currentAnnts = [...commentAnnotations]
                // if (currentAnnts.length) {
                //     currentAnnts.map((thisAn) => {
                //         thisAn.isHovered = false
                //         thisAn.isHoveredFrom = null
                //         return null
                //     })
                // }

                // show annotation when the cursor is on the icon
                setCommentAnnotations((prev) => {
                    return [annObjCopy]
                })
            } else {
                // make blank when the cursor is not on the icon
                setCommentAnnotations((prev) => {
                    return []
                })
            }

            return
            // setAnnotationShowType(showType)
        } else {
            setCommentAnnotations((prev) => {
                return []
            })
        }



        // only for compose annotations
        if (anntId) {



            let currentAnnts = [...composeAnnotations]
            let anIndex = null
            currentAnnts.map((thisAn, index) => {
                thisAn.isHovered = false
                thisAn.isHoveredFrom = null
                thisAn.lastHovered = null
                if (thisAn.id === anntId) {
                    anIndex = index
                }
                return null
            })


            // when the annotation is just created. The mouse is already over it and as the mouse moves, it should not animate again.
            if (from === AnnotationHoveredFrom.asset) {
                // check last hovered within milliseconds. then return from here
                if (lastHovered) {
                    let timeDiff = new Date() - lastHovered
                    console.log("Time difference is = " + timeDiff)
                    if (timeDiff <= 1000) {
                        return
                    }
                }
            }


            // console.log(`ANNOTATION INDEX = ${anIndex}`)
            if ((anIndex >= 0) && isHovered) {
                // console.log(`ANNOTATION INDEX = ${anIndex}, HOVERED = ${isHovered}`)
                let thisAnnt = { ...currentAnnts[anIndex] }
                thisAnnt.isHovered = true
                thisAnnt.isHoveredFrom = from
                currentAnnts[anIndex] = thisAnnt
            }
            setComposeAnnotations(currentAnnts)
        }
    }


    const annotationRemove = (anntId, clearAll) => {
        console.log("Annotation remove is called for id " + anntId)
        if (clearAll) {
            setComposeAnnotations([])
        }

        if (anntId !== null) {
            let currentAnnts = [...composeAnnotations]
            let allOtherAnnts = currentAnnts.filter((thisAn) => {
                return thisAn.id !== anntId
            })
            setComposeAnnotations(allOtherAnnts)
        }
    }

    const assetForSelected = (dict) => {

        console.log("INCOMING ASSETFOR DICT")
        console.log(dict)

        let assetCopy = Asset.copyFrom(currentAsset)
        assetCopy.assetForInfo = dict
        console.log("Updating Asset For Info")
        assetCopy.update((succ, result, errMsg) => {
            if (succ) {
                assetChanged(result)
                console.log("Asset For Info updated")

                Timeline.updateTimline(EntityType.asset, result.id, result, EntityLogType.updated, "Updated", "Changed AssetForInfo")


                let entityInfoObj = entityInfo_ForNotif(EntityType.asset, result, [], null)
                Project.getProjectDetails_AndNotify(result.project.id, NotificationFor.asset_changedDetails, entityInfoObj)

            } else {
                alert("Asset ForInfo could not be changed. Something went wrong.")
                console.log(errMsg)
            }
        })
        clearSelections()
    }


    const new_modal = () => {
        return (
            <Modal id="assetsModal" show={true} closeOnBlur={true} showClose={false} onClose={props.close} className=" assetsModalRef backblurOnly  animate-bottom p-0 m-0" >
                <Modal.Card className=" is-fullWidthAlways is-fullHeightAlways-vh has-background-grey-lightest o m-0" radiusless>
                    {/* <Modal.Card.Header showClose radiusless className="m-0   is-borderless">
                        <Modal.Card.Title className=" text-wrap30p " textColor="" textSize="6" >{currentAsset.name}</Modal.Card.Title>
                    </Modal.Card.Header> */}

                    {showAnim ? <ShowAnimation name={showAnim} close={() => { setShowAnim(null) }} /> : null}
                    {showTextEditorFor ? <TextEditor crud={showTextEditorFor.crud} payload={showTextEditorFor.payload} text={showTextEditorFor.text} footerLine={` Renaming ${showTextEditorFor.text}`} textChangeDone={textChangeDone} showDeleteOption={false} onDelete={null} close={clearSelections} /> : null}
                    {showTimeline && currentAsset.id ? <TimelineExplorer entity={EntityType.asset} entityId={currentAsset.id} close={() => { setShowTimeline(false) }} /> : null}

                    {showAssetFor ? <AssetForSelector for={currentAsset.assetForInfo} selectionMade={assetForSelected} close={clearSelections} /> : null}

                    
                    {showPreview ?
                        <PreviewModal asset={currentAsset} close={() => { togglePreview(false) }} />
                        : null}
                    {
                        showPostCaptionEditor ? <AssetCaptionEditorModal asset={currentAsset} assetChanged={assetChanged} close={() => { togglePostCaptionEditor(false) }} /> : null
                    }

                    <Modal.Card.Body className="p-0 m-0 has-background-transparent  ">
                        <Columns className="h-100vh m-0" gap={0} >
                            <Columns.Column className="p-0 m-0 h-100vh inset-right-shadow  overflow-hidden p-0"  >
                                {assetsContainer()}
                            </Columns.Column>

                            {
                                showMemberBox ?
                                    <Columns.Column id="assetAttributeColumn" size={3} className="  h-100vh  overflow-hidden p-0  is-bordered-left ">
                                        <AssetAttributeBox
                                            project={props.project}
                                            composeAnntActive={composeAnntActive}
                                            toggleComposeAnntActive={toggleComposeAnntActive}
                                            currentAsset={currentAsset}
                                            commentAnnotaions={commentAnnotations}
                                            composeAnnotations={composeAnnotations}
                                            composeAnnotationsChanged={composeAnnotationsChanged}
                                            annotationNoteChanged={annotationNoteChanged}
                                            annotationHovered={annotationHovered}
                                            annotationRemove={annotationRemove}
                                            clearAnnotations={() => { annotationRemove(null, true) }}
                                        />
                                    </Columns.Column>
                                    :
                                    null
                            }


                        </Columns>
                    </Modal.Card.Body>
                    {/* <Modal.Card.Footer renderAs={Button.Group} align="right" >

                    </Modal.Card.Footer> */}
                </Modal.Card>
            </Modal >
        )
    }


    // const modal = () => {
    //     return (
    //         <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="backblurOnly height100vc animate-bottom p-0 m-0" >
    //             <Modal.Card className=" is-fullWidthAlways height100vc m-0" radiusless>
    //                 {/* <Modal.Card.Header showClose radiusless className="m-0  has-background-white is-borderless">
    //                     <Modal.Card.Title textColor="" textSize="6" >{currentAsset.name}</Modal.Card.Title>
    //                 </Modal.Card.Header> */}
    //                 <Modal.Card.Body className="p-0 m-0 height100vc">
    //                     <Columns className="" gap={0}  >
    //                         <Columns.Column className="p-0 m-0 " >
    //                             {/* {assetsContainer()} */}
    //                         </Columns.Column>
    //                         <Columns.Column size={4} className="">

    //                             {/* //NEED FROM PROPS -existingComment, currentAsset, annotations, annotationRemoved, clearAnnotations */}

    //                             {/* <AssetAttributeBox composeAnntActive={composeAnntActive}  toggleComposeAnntActive={toggleComposeAnntActive} existingComment={!(commentAnnotations.length)} currentAsset={currentAsset} commentAnnotaions={commentAnnotations} composeAnnotations={composeAnnotations} annotationNoteChanged={annotationNoteChanged} annotationHovered={annotationHovered} annotationRemove={annotationRemove} clearAnnotations={() => { annotationRemove(null, true) }} /> */}
    //                         </Columns.Column>
    //                     </Columns>
    //                 </Modal.Card.Body>
    //                 {/* <Modal.Card.Footer renderAs={Button.Group} align="right" >

    //                 </Modal.Card.Footer> */}
    //             </Modal.Card>
    //         </Modal >
    //     )
    // }


    return new_modal()

}

export default AssetsModal