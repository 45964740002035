import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Modal, Panel, Columns, Button, Container, Table, Box, Form, Tabs, Heading, Image } from 'react-bulma-components';
import { FaLeaf } from 'react-icons/fa';
import ColumnAux from '../../Containers/ColumnAux';
import { Asset } from '../../Models/Asset';
import { Comment } from '../../Models/Comment';

import { allAssetCaptionTypes, AssetCaptionType, AssetCreateStatusType, create_AssetForInfo, allAssetTypes, AssetType } from '../../Models/EnumsAndPointers';
import { currentUserInfo, getCurrentWorkspace } from '../../utilities/Login';
import { assetfor_description, randomAlphabets, randomCode } from '../../utilities/Utilities';
import AssetForSelector from '../Common/AssetForSelector';



const AssetRequestModal = (props) => {




    let [comment, setComment] = useState("")

    let [captionType, setCaptionType] = useState(AssetCaptionType.caption)
    let [caption, setCaption] = useState("")

    let [assetName, setAssetName] = useState("")
    let [assetType, setAssetType] = useState(AssetType.text)

    let [saving, setSaving] = useState(false)
    let [assetForInfo, setAssetForInfo] = useState(create_AssetForInfo())
    const [showAssetFor, setShowAssetFor] = useState(false)


    const toggleShowAssetForSelector = (val) => {
        setShowAssetFor(val ?? !showAssetFor)
    }


    const createAssetName = () => {
        let cuInfo = currentUserInfo()
        let nm = `Untitled ${randomCode(3)} ${(needsIdeaDesc(assetType) ? "request" : caption ?  "text content" :  " text content request"  )} by ${cuInfo.name}`
        return nm
    }

    const assetForSelected = (val) => {
        setAssetForInfo(val)
        setShowAssetFor(false)
    }

    let captionTypeChanged = (val) => {
        console.log("Changing caption type to = ");
        console.log(val);
        setCaptionType(val)
    }

    let assetTypeChanged = (val) => {
        setAssetType(val)
    }

    let needsIdeaDesc = (assetType) => {
        return assetType !== AssetType.text
    }


    let commentChanged = (val) => {
        setComment(val)
    }



    let captionChanged = (val) => {
        setCaption(val)
    }




    let saveAssetRequest = () => {

        const wk = getCurrentWorkspace()
        let cuInfo = currentUserInfo()

        // create asset 
        let asst = new Asset()

        // give it a name
        if (assetName){
            asst.name = assetName
        }else{
            asst.name = createAssetName()
        }

        asst.byUser = cuInfo.id
        asst.byUserInfo = cuInfo
        asst.project = props.project
        asst.type = assetType
        asst.caption = caption
        asst.assetForInfo = assetForInfo
        asst.captionType = captionType
        asst.createStatus = AssetCreateStatusType.createRequest
        asst.createRequestDesc = comment
        asst.workspace = wk

        setSaving(true)
        asst.add((succ, result, errMsg) => {
            setSaving(false)
            if (succ) {
                let createdAsst = result

                if (comment && comment !== "") {
                    let comm = new Comment()

                    comm.byUser = cuInfo.id
                    comm.byUserInfo = cuInfo

                    comm.asset = createdAsst
                    comm.project = props.project
                    comm.message = comment
                    comm.messageType = captionType
                    comm.workspace = wk
                    comm.add((succ, result, errMsg) => {
                        if (succ) {
                            console.log(" Comment Added ");
                        } else {
                            alert("Request Comment could not be added")
                        }
                    })
                }


                if (props.assetRequestAdded) {
                    props.assetRequestAdded(createdAsst)
                }

            } else {
                alert("Asset request could not be added")
            }
        })


        // create comment for this asset if comment is non nil




    }




    const modal = () => {

        // let sampleCaption = "Cum sociis natoque penatibus et magnis dis parturient. Unam incolunt Belgae, aliam Aquitani, tertiam. Ullamco laboris nisi ut aliquid ex ea commodi consequat. Ambitioni dedisse scripsisse iudicaretur."



        return (
            <Modal show={true} closeOnBlur={true} showClose={false} onClose={props.close} className="lightModalBg backblurOnly-light  lightDeleteButton animate-bottom p-0" >
                <Modal.Card className="" radiusless>
                    {showAssetFor ? <AssetForSelector for={assetForInfo} selectionMade={assetForSelected} close={() => { setShowAssetFor(false) }} /> : null}

                    <Modal.Card.Header showClose className="  has-background-grey-lightest is-borderless pb-0 ">
                        {/* <Modal.Card.Title className="has-text-grey-light is-size-6"> Elaborate request </Modal.Card.Title> */}
                        <Modal.Card.Title className="has-text-grey-light">Create / Request</Modal.Card.Title>

                    </Modal.Card.Header>

                    <Modal.Card.Body className="has-background-grey-lightest pb-0 pt-2">
                        <ColumnAux size={12} isMiddle={true}>
                            <Box shadowless className="has-background-transparent mt-4 pt-0 mb-0" >


                                <br />
                                <Form.Field className=' ' >
                                    <Form.Control>
                                        <Form.Input value={assetName} className=' has-background-white-bis is-bordered-bottom ' max={40} placeholder="Name this asset... (40 characters max)" onChange={(e) => { setAssetName(e.target.value) }} />
                                    </Form.Control>
                                </Form.Field>


                                <Button size={"small"} fullwidth className="is-light is-bordered is-size-6 has-text-grey mb-5" onClick={toggleShowAssetForSelector}  >
                                    {/* <GrView className=" has-text-grey mr-2 m" /> */}
                                    <span> {assetfor_description(assetForInfo)} </span>
                                </Button>


                                <Form.Field>
                                    <Form.Label size="small">Type of Content</Form.Label>
                                    <Form.Control>
                                        <Form.Select size="small"
                                            disabled={saving}
                                            value={assetType}
                                            onChange={(e) => { assetTypeChanged(e.target.value) }}
                                        >
                                            {
                                                allAssetTypes.map((t) => {
                                                    return <option value={t}>{t}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Control>
                                </Form.Field>


                                {needsIdeaDesc(assetType) ?
                                    <Form.Field className="">
                                        <Form.Label size="small">Idea / Requirement / Comment</Form.Label>
                                        <Form.Control>
                                            <Form.Textarea
                                                disabled={saving}
                                                className="h-240px "
                                                size="normal"
                                                color="text"
                                                // rows={3}
                                                fixedSize={false}
                                                placeholder={`Mention how you want it to be... (it will be posted as the first comment)`}
                                                value={comment}
                                                onChange={(e) => { commentChanged(e.target.value) }}
                                            // onClick={togglePostCaptionEditor}
                                            />
                                        </Form.Control>
                                    </Form.Field>
                                    : null}

                                <br />

                                <Form.Field>
                                    {/* <Form.Label size="small">Select type</Form.Label> */}
                                    <Form.Control>
                                        <Form.Select size="small"
                                            disabled={saving}
                                            value={captionType}
                                            onChange={(e) => { captionTypeChanged(e.target.value) }}
                                        >
                                            {
                                                allAssetCaptionTypes.map((t) => {
                                                    return <option value={t}>{t}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Control>
                                </Form.Field>


                                <Form.Field className="">
                                    {/* <Form.Label size="small">Content</Form.Label> */}
                                    <Form.Control>
                                        <Form.Textarea
                                            disabled={saving}
                                            className="h-240px "
                                            size="normal"
                                            color="text"
                                            // rows={3}
                                            fixedSize={false}
                                            placeholder={`Add ${captionType.toLowerCase()} here...`}
                                            onChange={(e) => { captionChanged(e.target.value) }}
                                        // onClick={togglePostCaptionEditor}
                                        />
                                    </Form.Control>
                                </Form.Field>

                            </Box>
                        </ColumnAux>
                        {/* <Button size="normal" color="text" className="is-pulled-left is-text ml-4 noDecoration " onClick={props.close}> Cancel </Button>
                        <Button size="normal" color="black" className="is-pulled-right mx-4 px-5" onClick={close}> Save </Button> */}
                    </Modal.Card.Body>
                    <Modal.Card.Footer className="pt-0 pb-6 has-text-right has-background-grey-lightest is-borderless " >
                        <Button fullwidth disabled={saving} loading={saving} size="normal" color="black" className="mx-5" onClick={saveAssetRequest}> Save </Button>
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal >
        )
    }



    return modal()

}

export default AssetRequestModal